import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SelectController } from "components/inputs/SelectController";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import React from "react";
import { ConfigurationFormProps } from "./types";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useLanguagesOptions } from "hooks/useLanguagesOptions";

const ConfigurationForm = ({
  form: { control },
  mode,
  onEdit,
}: ConfigurationFormProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "LANGUAGE",
    "USER_TIME_FORMAT",
    "USER_DATE_FORMAT",
    "USER_DECIMAL_SEPARATOR",
    "USER_DECIMAL_PLACES"
  ]);

  const { dataElementsChoices } = useDataElementChoices([
    "USER_TIME_FORMAT",
    "USER_DATE_FORMAT",
    "USER_DECIMAL_SEPARATOR",
    "USER_DECIMAL_PLACES"
  ]);

  const languages = useLanguagesOptions();
  const readOnly = mode === "view";
  const { appMessage } = useAppMessages("USERS");

  if (dataElementsChoices === undefined) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
          <SelectController
              label={dataElementsDescriptions["LANGUAGE"]}
              controllerProps={{
                name: "language",
                control: control,
                rules: {
                  required: appMessage["ENTER_LANGUAGE"] ?? "Please select the user language", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
              options={languages.languagesOptions}
            />
          </Grid>

          <Grid item xs={3}>
          <SelectController
              label={dataElementsDescriptions["USER_DATE_FORMAT"]}
              controllerProps={{
                name: "dateFormat",
                control: control,
                rules: {
                  required: appMessage["ENTER_DATE_FTM"] ?? "Please select the user date format", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
              options={dataElementsChoices["USER_DATE_FORMAT"]}
            />
          </Grid>

          <Grid item xs={3}>
          <SelectController
              label={dataElementsDescriptions["USER_TIME_FORMAT"]}
              controllerProps={{
                name: "timeFormat",
                control: control,
                rules: {
                  required: appMessage["ENTER_TIME_FTM"] ?? "Please select the user time format", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
              options={dataElementsChoices["USER_TIME_FORMAT"]}
            />
          </Grid>

          <Grid item xs={3}>
          <SelectController
              label={dataElementsDescriptions["USER_DECIMAL_SEPARATOR"]}
              controllerProps={{
                name: "decimalSeparator",
                control: control,
                rules: {
                  required: appMessage["ENTER_DEC_SEP"] ?? "Please select the decimal separator type", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
              options={dataElementsChoices["USER_DECIMAL_SEPARATOR"]}
            />
          </Grid>

          <Grid item xs={3}>
          <SelectController
              label={dataElementsDescriptions["USER_DECIMAL_PLACES"]}
              controllerProps={{
                name: "numberDecimalsPlaces",
                control: control,
                rules: {
                  required: appMessage["ENTER_DEC_NUM"] ?? "Please select the decimal places", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
              options={dataElementsChoices["USER_DECIMAL_PLACES"]}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default ConfigurationForm;
