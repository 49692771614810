import { TableCellProps } from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  PlayArrow as PlayArrowIcon,
  Search as SearchIcon,
  Stop as StopIcon,
  Storage as StorageIcon,
} from "@mui/icons-material";
import {
  TableColumnType,
  TableRowType,
} from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppActions } from "hooks/useAppActions";
import { useSnackbar } from "hooks/useSnackbar";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { TrafficLightStatus } from "apps/BusinessObject/ViewAllPage/components/Table/TrafficLight/template";
import { TableType } from "apps/TablesPage/CreatePage/Form/BasicDataForm/types";
import { TablesRoutes } from "apps/TablesPage/routes";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { push } from "utils/push";
import { generatePath } from "utils/typedRoutesUtils";
import {
  GetTablesForViewPageQuery,
  useActivateTableMutation,
  useDeactivateTableMutation,
  useDeleteTableMutation,
  useGetTablesForViewPageQuery,
} from "__generated__/graphql/types";
import {
  checkAllTableRows,
  checkTableRow,
  selectAreAllTableRowsChecked,
  selectIsTableRowChecked,
} from "../../reducers/checkedRowsSlice";
import {
  nextPageTable,
  prevPageTable,
  resetTablePage,
  selectTableFilters,
  selectTablePagination,
  tableFiltersLabels,
} from "../../reducers/filtersAndPaginationSlice";
import {
  selectTableVisibleColumnsKeysInOrder,
  TableColumnsKeys,
  TABLE_COLUMNS_KEYS,
} from "../../reducers/visibleColumnsSlice";
import { TrafficLight } from "./TrafficLight";

export const useTablesTable = () => {
  const pagination = useAppSelector(selectTablePagination);

  const errorSnackbar = useSnackbar();

  const { actions } = useAppActions("TABLES");

  const { language } = useSystemLanguage();

  const filters = useAppSelector(selectTableFilters);

  const {
    data: { tables, tablesCount: total } = {},
    refetch: findTables,
  } = useGetTablesForViewPageQuery({
    language,
    pagination,
    filters: {
      description: filters.description,
      id: filters.name,
      tableType: filters.tableType as any, // FIXME:
      status: filters.status as any, // FIXME:
    },
  });

  const { mutateAsync: deleteTable } = useDeleteTableMutation();
  const { mutateAsync: activateTable } = useActivateTableMutation();
  const { mutateAsync: deactivateTable } = useDeactivateTableMutation();

  const dispatch = useAppDispatch();

  const orderedColumnsKeys = useAppSelector(
    selectTableVisibleColumnsKeysInOrder
  );

  const areAllDataElementsRowsChecked = useAppSelector(
    selectAreAllTableRowsChecked
  );

  const isDataElementsRowChecked = useAppSelector(selectIsTableRowChecked);

  const navigate = useNavigate();

  const columns: TableColumnType<TableColumnsKeys>[] = useMemo(
    () => orderedColumnsKeys.map((colKey) => header[colKey]),
    [orderedColumnsKeys]
  );

  const rows = useMemo(() => tables?.map(mapRowDataToRowCells), [tables]);

  const menuItems = useMemo(
    () => [
      {
        key: "display",
        Icon: SearchIcon,
        text: actions["READ"],
        onClick: (id: string) => {
          navigate(generatePath(TablesRoutes.VIEW, { tableId: id }));
        },
      },
      {
        key: "edit",
        Icon: EditIcon,
        text: actions["UPDATE"],
        onClick: (id: string) => {
          navigate(generatePath(TablesRoutes.EDIT, { tableId: id }));
        },
      },
      {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: actions["DELETE"],
        onClick: (id: string) =>
          deleteTable({ id })
            .then(() => dispatch(resetTablePage()))
            .then(() => findTables())
            .catch(() => errorSnackbar.open()),
      },
      {
        key: "activate", // FIXME: mostrar boton activa o deactive, pero no ambos
        Icon: PlayArrowIcon,
        text: actions["ACTIVATE"],
        onClick: (id: string) => activateTable({ id }).then(() => findTables()),
      },
      {
        key: "deactivate", // FIXME: mostrar boton activa o deactive, pero no ambos
        Icon: StopIcon,
        text: actions["DEACTIVATE"],
        onClick: (id: string) =>
          deactivateTable({ id }).then(() => findTables()),
      },
      {
        key: "migrations",
        Icon: StorageIcon,
        text: actions["VIEW_MIGRATIONS"],
        onClick: (id: string) =>
          navigate(generatePath(TablesRoutes.MIGRATIONS, { tableId: id })),
      },
    ],
    [
      actions,
      activateTable,
      deactivateTable,
      deleteTable,
      dispatch,
      errorSnackbar,
      findTables,
      navigate,
    ]
  );

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkTableRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllTableRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPageTable());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPageTable());
  }, [dispatch]);

  return {
    rows,
    total,
    errorSnackbar,
    columns,
    menuItems,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    pagination,
  };
};

export const tableTypeMap: Record<TableType, string> = {
  INTERNAL_SYSTEM_TABLE: "Tabla interna de sistema",
  CONFIGURATION_TABLE: "Tabla de configuración",
  BUSINESS_DATA_TABLE: "Tabla de datos de negocio",
};

const mapRowDataToRowCells = (
  dto: NonNullable<GetTablesForViewPageQuery["tables"]>[number]
): TableRowType<TableColumnsKeys> => {
  return {
    rowKey: dto.id,
    name: dto.id.toUpperCase(),
    description: dto.translation.description,
    tableType: tableTypeMap[dto.tableType as TableType], // FIXME:
    status: <TrafficLight status={dto.status as TrafficLightStatus} />,
    businessObject: "-", // TODO:
  };
};

const columnAlign: Partial<
  Record<TableColumnsKeys, TableCellProps["align"]>
> = {
  status: "center",
};

const header = TABLE_COLUMNS_KEYS.reduce((acc, curr) => {
  return push(acc, curr, {
    key: curr,
    content: tableFiltersLabels[curr].toUpperCase(),
    align: columnAlign[curr],
  });
}, {} as Record<TableColumnsKeys, TableColumnType<TableColumnsKeys>>);
