import { AccountingEntityInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): AccountingEntityInput => {
  return {
    id: form.basicData.accountingEntity,
    name: form.basicData.name,
    currencyId: form.basicData.currency,
    companyId: form.basicData.company,
    address: {
      street: form.addressData.street,
      number: form.addressData.number,
      floor: form.addressData.floor,
      department: form.addressData.department,
      city: form.addressData.city,
      postalCode: form.addressData.postalCode,
      state: form.addressData.state,
      country: form.addressData.country,
    },
  };
};
