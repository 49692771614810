import { DevTool } from "@hookform/devtools";
import { InvoiceStatus } from "__generated__/graphql/types";
import { InvoicesRoutes, InvoicesRoutesState } from "apps/InvoicesPage/routes";
import { MenuItemsProps } from "components/datadisplay/GenericTable/template";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { TableCollapsePIC } from "components/datadisplay/TableCollapsePIC";
import { SelectFilter } from "components/filters/SelectFilter";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDialog } from "hooks/useDialog";
import { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ExcelSheetData } from "types/ExcelDataSet";
import { useLocationState } from "utils/typedRoutesUtils";
import { useInvoicesLabels } from "../../../useInvoicesLabels";
import {
  Filters,
  FiltersKeys,
  selectFilters,
  selectHiddenFiltersKeys,
  selectVisibleFiltersKeys,
  setFilters
} from "../../reducers/filtersAndPaginationSlice";
import { ColumnsKeys } from "../../reducers/visibleColumnsSlice";
import { ColumnsVisibilityDialog } from "./ColumnsVisibilityDialog";
import { FiltersVisibilityDialog } from "./FiltersVisibilityDialog";

type CollapseProps = {
  menuItems: MenuItemsProps[]
  sheet?: ExcelSheetData<ColumnsKeys> | undefined;
  isCreatedVisible: boolean;
};

const Collapse = ({
  sheet,
  menuItems,
  isCreatedVisible,
}: CollapseProps) => {
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useLocationState<InvoicesRoutesState>();

  const filtersDialog = useDialog();
  const columnsDialog = useDialog();
  const { columnsLabels } = useInvoicesLabels();

  const filters = useAppSelector(selectFilters);
  const visibleFiltersKeys = useAppSelector(selectVisibleFiltersKeys);
  const hiddenFiltersKeys = useAppSelector(selectHiddenFiltersKeys);

  const { control, handleSubmit, unregister } = useForm<Filters>({
    defaultValues: filters,
  });

  const onCreate = () => {
    navigate(InvoicesRoutes.PATH_INVOICES_CREATE, {
      state: {
        toBack: state?.toBack,
        purchaseOrderId: state?.purchaseOrderId,
        purchaseOrderItemsIds: state?.purchaseOrderItemsIds,
      }
    });
  };

  const onSearch = handleSubmit((form) => {
    dispatch(setFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  const { dataElementsChoices } = useDataElementChoices(["INVOICE_STATUS"]);
  if (dataElementsChoices === undefined) return <BeatLoadingPage />;

  const statusTranslations: Record<InvoiceStatus, string> = {
    CREATED: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "CREATED")?.text ?? "Created",
    ACCOUNTING: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "ACCOUNTING")?.text ?? "Accounting",
    REJECTED: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "REJECTED")?.text ?? "Reject",
    TRANSFERRED: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "TRANSFERRED")?.text ?? "Transferred",
    PAID: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "PAID")?.text ?? "Paid",
    PARTIAL_PAYMENT: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "PARTIAL_PAYMENT")?.text ?? "Partial payment",
    CANCELLED: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "CANCELLED")?.text ?? "Cancelled",
  };

  const filtersComponents: Record<
    FiltersKeys,
    ComponentType<FilterComponentProps<Filters, FiltersKeys>>
  > = {
    id: TextFilter,
    invoiceVendorNumber: TextFilter,
    invoiceBackendNumber: TextFilter,
    headerText: TextFilter,
    fiscalYear: TextFilter,
    currency: TextFilter,
    invoiceDate: TextFilter,
    invoiceStatus: (props) => (
      <SelectFilter
        {...props}
        options={[
          { value: "CREATED", text: statusTranslations.CREATED},
          { value: "TRANSFERRED", text: statusTranslations.TRANSFERRED },
          { value: "ACCOUNTING", text: statusTranslations.ACCOUNTING },
          { value: "REJECTED", text: statusTranslations.REJECTED },
          { value: "PAID", text: statusTranslations.PAID },
          { value: "PARTIAL_PAYMENT", text: statusTranslations.PARTIAL_PAYMENT },
          { value: "CANCELLED", text: statusTranslations.CANCELLED },
        ]}
      />
    ),
  };

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: columnsLabels,
    control,
  });

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <ColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapsePIC<ColumnsKeys> 
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={onCreate}
        onExpand={() => {}}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
        menuItems={menuItems}
        sheet={sheet}
        isCreatedVisible={isCreatedVisible}
      />
    </>
  );
};

export default Collapse;
