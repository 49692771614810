import { CreatePage } from "./CreatePage";
import { EditPage } from "./EditPage";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";
import { Route, Routes } from "react-router";
import { LocationTypeRoutes } from "./routes";

const LocationTypePage = () => {
  return (
    <Routes>
      <Route path={LocationTypeRoutes.VIEW_ALL} element={<ViewAllPage />} />

      <Route path={LocationTypeRoutes.VIEW} element={<ViewPage />} />

      <Route path={LocationTypeRoutes.CREATE} element={<CreatePage />} />

      <Route path={LocationTypeRoutes.EDIT} element={<EditPage />} />
    </Routes>
  );
};

export default LocationTypePage;
