import { selectMuiTheme } from 'components/providers/ReduxMuiThemeProvider/reducer';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAppMessages } from 'hooks/useAppMessages';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { targetToElement } from 'utils/tutorialsConversors';
import { emptyStep, getInstructionsState, setCurrentStep, setInstructions, setPetState } from './reducer/instructionsReducer';
import { clickElement } from './scripts/js-scroll';

export const Instructions = () => {

  const dispatch = useAppDispatch();

  const instructions = useAppSelector(getInstructionsState);
  const theme = useAppSelector(selectMuiTheme);
  const { appMessage } = useAppMessages("MESSAGES");

  // Callback
  const handleJoyrideCallback = (data: any) => {
    
    const { action, index, status, type, step } = data;

    if (ACTIONS.CLOSE.includes(action)) {
      dispatch(
        setInstructions({
          open: false,
          stepIndex: 0,
          steps: emptyStep
        })
      );
      dispatch(
        setPetState({
          petState: "SLEEP"
        })
      );
    } else if ([EVENTS.STEP_AFTER].includes(type) && step.title === true) {

      // FIXME: Click by JS
      const element = targetToElement(step.target); 
      clickElement(element);

      dispatch(
        setCurrentStep({ 
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) 
        })
      );

    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      dispatch(
        setCurrentStep({ 
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) 
        })
      );

    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
    ) {
      dispatch(
        setInstructions({
          open: false,
          stepIndex: 0,
          steps: emptyStep
        })
      );
    }
  };

  return (
    <>
      <Joyride
        run={instructions.open}
        continuous={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        disableScrollParentFix={true}
        locale={locale(appMessage)}
        styles={{
          options: {
            primaryColor: theme?.palette.primary.main,
            textColor: "#000000DE", //FIXME: 
            backgroundColor: "rgba(255, 255, 255, .55)", // FIXME:
            arrowColor: "rgba(255, 255, 255, .55)", // FIXME:
            width: "auto",
            zIndex: 2000
          }
        }}
        steps={instructions.steps}
        stepIndex={instructions.stepIndex}
        callback={handleJoyrideCallback}
      />
    </>
  );
};

export default Instructions;

export const locale = (
  appMessage: { [x: string]: string; }
) => ({
  next: (<> {appMessage["NEXT"]?? "Next"} </>),
  back: (<> {appMessage["BACK"] ?? "Back"} </>),
  last: (<> {appMessage["LAST"] ?? "Last"} </>),
  close: (<> {appMessage["CLOSE"] ?? "Close"} </>),
});
