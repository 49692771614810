import {
  Check as CheckIcon,
  InfoOutlined as InfoOutlinedIcon,
  Save as SaveIcon
} from "@mui/icons-material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ContactsIcon from "@mui/icons-material/Contacts";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import RoomIcon from "@mui/icons-material/Room";
import SettingsInputHdmiIcon from "@mui/icons-material/SettingsInputHdmi";
import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { AlertUnsavedDataInput } from "components/feedback/AlertUnsavedDataInput";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { SuccessMessage } from "components/feedback/SuccessMessage";
import { SaveCloseButtons } from "components/inputs/SaveCloseButtons";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { FormStepper } from "components/navigation/FormStepper";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useAppSectionsTranslations } from "hooks/useAppSectionsTranslations";
import { useFormSteps } from "hooks/useFormSteps";
import { StepBehavior } from "hooks/useFormSteps/hook";
import { useSnackbar } from "hooks/useSnackbarV2";
import { FormProps } from "types/Form";
import { FormStep } from "utils/FormStep";
import { assertUnreachable } from "utils/assert";
import { AddressForm } from "./AddressForm";
import { BackendDataForm } from "./BackendDataForm";
import { BasicDataForm } from "./BasicDataForm";
import { ContactsForm } from "./ContactForm";
import { IdDataForm } from "./IdDataForm";
import { TypesDataForm } from "./TypesDataForm";
import { FormFields, FormStepsKeys, NonFormStepKeys } from "./types";
import { useAddressDescriptionsForm } from "./useAddressDescriptionsForm";
import { useContactsDescriptionsForm } from "./useContactsDescriptionsForm";
import { useForms } from "./useForms";

const Form = ({
  initialValue,
  initialStep,
  mode,
  errorMessage = "", // FIXME:
  onSave = assertUnreachable, // FIXME:
  onCancel = assertUnreachable, // FIXME:
  onEdit,
}: FormProps<FormFields>) => {
  const readOnly = mode === "view";
  const noLinearStepper = mode ==="view" || mode ==="edit";

  const { appMessage } = useAppMessages("MESSAGES");
  const errorSnackbar = useSnackbar();

  const forms = useForms(initialValue);

  const addressDescriptions = useAddressDescriptionsForm(forms.locations);
  const contactsDescriptions = useContactsDescriptionsForm(forms.contacts);

  const {
    appSectionsTranslations,
    isLoadingSections,
  } = useAppSectionsTranslations("BUSINESS_PARTNERS");

  const stepsKeysInOrder: StepBehavior<FormStepsKeys, NonFormStepKeys>[] = [
    { key: "basicData", hasForm: true },
    {
      key: "locations",
      hasForm: true,
      isNestedPage: addressDescriptions.areDescriptionsVisible,
      onClickPrevButton: addressDescriptions.onClickPageButton,
    },
    { key: "idsData", hasForm: true },
    { key: "businessType", hasForm: true },
    {
      key: "contacts",
      hasForm: true,
      isNestedPage: contactsDescriptions.areDescriptionsVisible,
      onClickPrevButton: contactsDescriptions.onClickPageButton,
    },
    { key: "backend", hasForm: true },
    { key: "save", isHidden: readOnly },
    { key: "success", isHidden: readOnly },
  ];

  const stepsNavigation = useFormSteps({
    stepsKeysInOrder,
    errorSnackbar,
    forms,
    initialStep,
    errorMessage,
    readOnly,
    onSave,
    onEdit,
  });

  const unorderedSteps: Record<FormStepsKeys | NonFormStepKeys, FormStep> = {
    basicData: {
      ...appSectionsTranslations("BASIC_DATA"),
      icon: <InfoOutlinedIcon />,
      body: (
        <BasicDataForm
          form={forms.basicData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },

    locations: {
      ...appSectionsTranslations("ADDRESS_DATA"),
      icon: <RoomIcon />,
      leftButtonText: addressDescriptions.name,
      body: (
        <AddressForm
          form={forms.locations}
          externalIndex={addressDescriptions.index}
          readOnly={readOnly}
          areDescriptionsVisible={addressDescriptions.areDescriptionsVisible}
          onEnterDescriptions={addressDescriptions.onEnterDescriptions}
          onExitDescriptions={addressDescriptions.onExitDescriptions}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    idsData: {
      ...appSectionsTranslations("IDS_DATA"),
      icon: <FingerprintIcon />,
      body: (
        <IdDataForm
          form={forms.idsData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    businessType: {
      ...appSectionsTranslations("BUSINESS_TYPE_DATA"),
      icon: <BusinessCenterIcon />,
      body: (
        <TypesDataForm
          form={forms.businessType}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },

    contacts: {
      ...appSectionsTranslations("CONTACTS_DATA"),
      icon: <ContactsIcon />,
      leftButtonText: contactsDescriptions.name,
      body: (
        <ContactsForm
          form={forms.contacts}
          externalIndex={contactsDescriptions.index}
          readOnly={readOnly}
          areDescriptionsVisible={contactsDescriptions.areDescriptionsVisible}
          onEnterDescriptions={contactsDescriptions.onEnterDescriptions}
          onExitDescriptions={contactsDescriptions.onExitDescriptions}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    backend: {
      ...appSectionsTranslations("BACKEND_DATA"),
      icon: <SettingsInputHdmiIcon />,
      body: (
        <BackendDataForm
          form={forms.backend}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    save: {
      title:  appMessage["SAVE"],
      subtitle: appMessage["SAVE_CONFIRMATION"],
      icon: <SaveIcon />,
      isSaveButtonVisible: true,
    },
    success: {
      title: appMessage["END"],
      icon: <CheckIcon />,
      body: <SuccessMessage />,
      isHeaderHidden: true,
    },
  };
  const currentStep = unorderedSteps[stepsNavigation.activeStepKey];

  const visibleSteps = stepsNavigation.visibleStepsKeysInOrder.map(
    (key) => unorderedSteps[key]
  );

  if (isLoadingSections) return <BeatLoadingPage/>;

  return (
    <>
      <AlertUnsavedDataInput
        isBlocked={stepsNavigation.isRouteBlocked}
        unblock={stepsNavigation.unblockRoute}
      />

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <FormStepper
        steps={visibleSteps}
        activeStepIndex={stepsNavigation.stepper.activeStepIndex}
        isNonLinear={noLinearStepper}
        onChangeActiveStepIndex={stepsNavigation.stepper.setActiveStepIndex}
      />

      {!currentStep.isHeaderHidden && (
        <FormHeader
          title={currentStep.title}
          subtitle={currentStep.subtitle}
          step={currentStep.step}
          leftButtonText={currentStep.leftButtonText}
          isPreviousButtonVisible={stepsNavigation.isPreviousButtonVisible}
          isNextButtonVisible={stepsNavigation.isNextButtonVisible}
          isUpButtonVisible={stepsNavigation.isUpButtonVisible}
          onPrevious={stepsNavigation.onPrevious}
          onNext={stepsNavigation.onNext}
          onUp={stepsNavigation.onUp}
        />
      )}

      <FormBodyWrapper>{currentStep.body}</FormBodyWrapper>

      {!readOnly && (
        <SaveCloseButtons
          isCancelButtonVisible
          isSaveButtonVisible={currentStep.isSaveButtonVisible ?? false} // FIXME:
          onCancel={onCancel}
          onSave={stepsNavigation.onClickSave}
        />
      )}
    </>
  );
};

export default Form;
