import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetBusinessUnitViewPageQuery,
  useUpdateBusinessUnitMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { BusinessUnitsRoutes, BusinessUnitsRoutesState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

const EditPage = () => {
  const { businessUnitsId } = useRouteParams<BusinessUnitsRoutes.VIEW>();

  const { activeStep } =
    useLocationState<BusinessUnitsRoutesState[BusinessUnitsRoutes.EDIT]>() ?? {};

  const navigate = useNavigate();

  const { mutateAsync: editTable } = useUpdateBusinessUnitMutation();

  const { data: { businessUnit } = {}, isFetchedAfterMount } = useGetBusinessUnitViewPageQuery({
    id: businessUnitsId,
  });

  const initialValue =
    businessUnit != null && isFetchedAfterMount ? mapDtoToForm(businessUnit) : undefined;

  const onSave = async (form: FormFields) => {
    await editTable({ id: businessUnitsId, businessUnit: mapFormToDto(form) });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Editar unidad de negocio" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValue}
          initialStep={activeStep}
          errorMessage="Error al editar el unidad de negocio. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
