import { DevTool } from "@hookform/devtools";
import React from "react";
import { IdsNamesForm } from "./IdsNamesForm";
import { IdsFormProps } from "./types";

const IdsForm = ({ form, readOnly, onEdit }: IdsFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />
      <IdsNamesForm form={form} readOnly={readOnly} onEdit={onEdit} />
    </>
  );
};

export default IdsForm;
