import { combineReducers } from "@reduxjs/toolkit";
import viewTableColumnsSlice from "./ViewAllPage/reducers/visibleColumnsSlice";
import viewTableFiltersAndPaginationSlice from "./ViewAllPage/reducers/filtersAndPaginationSlice";
import checkedRowsSlice from "./ViewAllPage/reducers/checkedRowsSlice";

const tablesReducer = combineReducers({
  columns: viewTableColumnsSlice,
  filtersAndPagination: viewTableFiltersAndPaginationSlice,
  rowsChecked: checkedRowsSlice,
});

export default tablesReducer;
