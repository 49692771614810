import { Box, styled, Tooltip } from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import React from "react";

export type TrafficLightStatus = "ACTIVE" | "INACTIVE" | "TREATMENT";

type TrafficLightProps = {
  status: TrafficLightStatus;
};

const TrafficLight = ({ status }: TrafficLightProps) => {
  return (
    <Tooltip title={statusTextAndElement[status].text} placement="top">
      <Box display="flex" justifyContent="center">
        {statusTextAndElement[status].element}
      </Box>
    </Tooltip>
  );
};

export default TrafficLight;

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: green[200],
});

const StyledErrorIcon = styled(ErrorIcon)({
  color: yellow[600],
});

const StyledCancelIcon = styled(CancelIcon)({
  color: red[200],
});

const statusTextAndElement = {
  ACTIVE: {
    text: "ACTIVO", // FIXME: traduccion
    element: <StyledCheckCircleIcon />,
  },
  INACTIVE: {
    text: "INACTIVO", // FIXME: traduccion
    element: <StyledErrorIcon />,
  },
  TREATMENT: {
    text: "TRATAMIENTO", // FIXME: traduccion
    element: <StyledCancelIcon />,
  },
};
