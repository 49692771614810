import { useCreateMigrationPackageInputMutation } from "__generated__/graphql/types";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";

const CreatePage = () => {

  const navigate = useNavigate();

  const { mutateAsync: createMigrationPackage } 
  = useCreateMigrationPackageInputMutation();

  const onSave = async (form: FormFields) => {
    const migrationPackage = mapFormToDto(form);
    await createMigrationPackage({migrationPackage: migrationPackage});
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title="Crear migración de datos" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage="Error al crear la migración de paquete. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
