import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Edit as EditCircleIcon,
} from "@mui/icons-material";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import React from "react";
import { HeaderProps } from "./types";
import useAppMessages from 'hooks/useAppMessages/hook';


const Header = ({ readOnly = false, onEdit, onFinish }: HeaderProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "APP_ACTION",
    "DESCRIPTION",
  ]);

  const { appMessage } = useAppMessages("MESSAGES");
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="left">
          {/* FIXME: ¿cual data element usar? */}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["APP_ACTION"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["DESCRIPTION"]}
        </StyledHeadTableCell>

        <TableCell align="right">
          {readOnly ? (
            <Tooltip
              title={appMessage["EDIT"]}
            >
              <IconButton aria-label="edit" size="small" onClick={onEdit}>
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip
                title={appMessage["SAVE"]}
              >
                <IconButton aria-label="edit" size="small" onClick={onFinish}>
                  <StyledCheckCircleIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
