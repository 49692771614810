import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { contactDescriptionEmptyRow } from "../ContactsDescriptionsForm/template";
import { Contacts, ContactsFormFields } from "../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { ContactsNamesFormProps } from "./types";

export const contactsEmptyRow: Contacts = {
  userId: contactDescriptionEmptyRow.userId,
  firstName: contactDescriptionEmptyRow.firstName,
  lastName: contactDescriptionEmptyRow.firstName,
  email: contactDescriptionEmptyRow.email,
  phoneNumber: contactDescriptionEmptyRow.phoneNumber,
  position: contactDescriptionEmptyRow.position,
  birthDate: contactDescriptionEmptyRow.birthDate,
};

const ContactsNamesForm = ({
  readOnly = false,
  onEdit,
  onEnterDescriptions,
  form,
}: ContactsNamesFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray<ContactsFormFields>({
    control,
    name: "contact",
  });

  const appendRow = () => {
    append(contactsEmptyRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header
          onAppend={() => appendRow()}
          onEdit={() => onEdit?.()}
          readOnly={readOnly}
        />

        <StyledTableBody>
          {fields.map((item, index) => (
            <Row
              key={item.id}
              index={index}
              remove={onRemove(index)}
              item={item}
              readOnly={readOnly}
              onEnterDescriptions={onEnterDescriptions}
              form={form}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default ContactsNamesForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
