import { DevTool } from "@hookform/devtools";
import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { useGetPositionsForCreateEmployeeQuery } from "__generated__/graphql/types";
import { Header } from "./Header";
import { Row } from "./Row";
import { PositionsFormFields, PositionsProps } from "./types";

export const emptyTableRow = {
  tableId: "",
  startDate: new Date(),
};

const PositionHistoryForm = ({
  form,
  basicDataForm,
  readOnly = false,
  onEdit,
}: PositionsProps) => {
  const { control } = form;

  const { language } = useSystemLanguage();

  const { data: { positions } = {} } = useGetPositionsForCreateEmployeeQuery({
    language, // TODO: dataElementId: selectedDataElementId,
  });

  const { fields, append, remove } = useFieldArray<PositionsFormFields>({
    control,
    name: "positions",
  });

  const appendRow = () => {
    append(emptyTableRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  if (positions == null) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <TableContainer>
        <StyledTable aria-label="simple table" size="small">
          <Header
            onAppend={() => appendRow()}
            onEdit={() => onEdit?.()}
            readOnly={readOnly}
          />

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                form={form}
                key={item.id}
                index={index}
                remove={onRemove(index)}
                item={item}
                readOnly={readOnly}
                positions={positions}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

export default PositionHistoryForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
