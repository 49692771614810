import { Dialog } from "@mui/material";
import { getPetState } from "components/Instructor/reducer/instructionsReducer";
import { useAppSelector } from "hooks/reduxHooks";
import { useAppMessages } from "hooks/useAppMessages";
import { useTutorials } from "hooks/useTutorials";
import React, { useEffect } from "react";
import InstructionsDialogMenu from '../../dialogs/instructions-dialog-menu/template';

export type MenuInstructionsProps = {
  tutorialsIds: string[];
}

export const MenuInstructions = ({
  tutorialsIds,
}: MenuInstructionsProps) => {

  const [openMenuDialog, isMenuDialogOpen] = React.useState(false);
  const petState = useAppSelector(getPetState);
  
  // TODO: Buscar tutorial por APP
  const tutorials = useTutorials(tutorialsIds)
  const { appMessage } = useAppMessages("TUTORIALS");

  const closeMenuDialog = () => {
    isMenuDialogOpen(false);
  }

  useEffect(() => {
    if(petState === "AWAKE") isMenuDialogOpen(true);
  }, [petState]);

  useEffect(() => {
    if(tutorials && tutorials.length === 1 && openMenuDialog) {
      isMenuDialogOpen(false);
      tutorials[0].navigateInstructions();
    }

  }, [openMenuDialog, tutorials]);

  return (
    <>
      {
        (tutorials && tutorials.length > 1 && openMenuDialog) && (
          <Dialog  
            onClose={closeMenuDialog}
            open={openMenuDialog} 
            PaperProps={{
              style: {
                backgroundColor: "rgba(255, 255, 255, .55)",
                borderRadius: "5px"
              }, // FIXME:
            }}
          >
            <InstructionsDialogMenu 
              text={appMessage["MENU_DESCRIPTION"]}
              options={tutorials}
              onClose={closeMenuDialog}
            />
          </Dialog>
        )
      }
    </>
  );
};

export default MenuInstructions;
