export enum BusinessPartnerTypeRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":businessPartnerTypeId/view",
  EDIT = ":businessPartnerTypeId/edit",
}

export type BusinessPartnerTypeRoutesState = {
  [BusinessPartnerTypeRoutes.EDIT]: { activeStep?: number };
};
