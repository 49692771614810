import { GetTableForViewQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetTableForViewQuery["table"]>
): FormFields => {
  if (dto.translations === undefined) throw Error();
  if (dto.columns === undefined) throw Error();

  return {
    basicData: { name: dto.id, tableType: dto.tableType },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
    characteristics: {
      characteristics: dto.columns
        .filter((e) => e.type === "CHARACTERISTIC")
        .map((e) => ({
          dataElementId: e.dataElement.id,
          isPrimaryKey: e.isPrimaryKey,
          name: e.id,
          translationTable: {} as any,
        })),
    },
    ratios: {
      ratios: dto.columns
        .filter((e) => e.type === "RATIO")
        .map((e) => ({
          dataElementId: e.dataElement.id,
          name: e.id,
        })),
    },
  };
};
