import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import { ListItemIcon, styled, Typography } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { pipe } from "ramda";

type SettingsMenuProps = {
  anchorEl?: MenuProps["anchorEl"];
  open: MenuProps["open"];
  onClose: () => void;
  onView: () => void;
  onEdit: () => void;
  onSet: () => void;
  onDelete: () => void;
};

const SettingsMenu = ({
  anchorEl,
  open,
  onClose,
  onView,
  onEdit,
  onSet,
  onDelete,
}: SettingsMenuProps) => {
  const items = [
    {
      Icon: SearchIcon,
      text: "Display", // FIXME: traduccion
      onClick: onView,
    },
    {
      Icon: PowerSettingsNewIcon,
      text: "Set", // FIXME: traduccion
      onClick: onSet,
    },
    {
      Icon: EditIcon,
      text: "Edit", // FIXME: traduccion
      onClick: onEdit,
    },
    {
      Icon: DeleteForeverIcon,
      text: "Delete", // FIXME: traduccion
      onClick: onDelete,
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorReference="anchorEl"
      keepMounted
      open={open}
      onClose={onClose}
      disableScrollLock={true}
      autoFocus={false}
    >
      <div id={open ? "simple-menu" : undefined}>
        {items.map(({ text, Icon, onClick }) => (
          <StyledMenuItem
            key={text} // FIXME:
            onClick={pipe(onClick, onClose)}
          >
            <StyledListItemIcon>
              <Icon fontSize="small" />
            </StyledListItemIcon>
            <Typography variant="inherit">{text}</Typography>
          </StyledMenuItem>
        ))}
      </div>
    </Menu>
  );
};

export default SettingsMenu;

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 30,
  ".MuiMenuItem-root:hover &": {
    color: theme.palette.primary.contrastText,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-focusVisible": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));
