import {
  IconButton,
  MenuItem,
  styled,
  TableCell,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Clear as ClearIcon } from "@mui/icons-material";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { useLanguagesOptions } from "hooks/useLanguagesOptions";
import React from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { isRepeatedLanguage } from "utils/translations";
import { TitlesFormFields } from "../types";

type RowProps = {
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<TitlesFormFields>["fields"][number];
  form: UseFormReturn<TitlesFormFields>;
};

const Row = ({ index, remove, item, readOnly, form }: RowProps) => {
  const { control, getValues, trigger } = form;
  const { languagesOptions } = useLanguagesOptions();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `descriptions.${index}.language` as "descriptions.0.language",
            control,
            rules: {
              required: "Seleccione un idioma.", // FIXME: traduccion
              validate: (language) => {
                const descriptions = getValues("descriptions");

                if (isRepeatedLanguage(descriptions, language))
                  return "El idioma ya ha sido seleccionado."; // FIXME: traduccion

                return true;
              },
            },
            defaultValue: item.language,
          }}
          select
        >
          {languagesOptions.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </FormTableTextFieldController>
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `descriptions.${index}.title` as "descriptions.0.title",
            control,
            rules: { required: "Ingrese el título de la aplicación." }, // FIXME: traduccion
            defaultValue: item.title,
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `descriptions.${index}.subtitle` as "descriptions.0.subtitle",
            control,
            rules: { required: "Ingrese el subtítulo de la aplicación." }, // FIXME: traduccion
            defaultValue: item.subtitle,
          }}
        />
      </TableCell>

      <TableCell align="left">
        {!readOnly && (
          <IconButton
            aria-label="remove"
            size="small"
            onClick={() => {
              remove();
              trigger();
            }}
          >
            <StyledClearIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
