import { useCallback, useMemo, useState } from "react";

export const useBlockedRoute = (
  hasChangedStep: boolean,
  isFormDirty: boolean
) => {
  const [isRouteBlockerEnabled, setIsRouteBlockerEnabled] = useState(true);

  const isRouteBlocked = useMemo(
    () => isRouteBlockerEnabled && (hasChangedStep || isFormDirty),
    [isRouteBlockerEnabled, hasChangedStep, isFormDirty]
  );

  const unblockRoute = useCallback(() => {
    setIsRouteBlockerEnabled(false);
  }, []);

  return {
    isRouteBlocked,
    unblockRoute,
  };
};
