import { BackendInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): BackendInput => {
  return {
    id: form.basicData.id,
    url: form.basicData.url,
    ip: form.basicData.ip,
    domain: form.basicData.domain
  };
};
