export enum ExportMigrationPackageRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":packageMigrationId/view",
  EDIT = ":packageMigrationId/edit",
}

export type ExportMigrationPackageRoutesState = {
  [ExportMigrationPackageRoutes.EDIT]: { activeStep?: number };
};
