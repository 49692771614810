import { GetCountryIdsTypes_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetCountryIdsTypes_ViewPageQuery["countryIdsTypes"]>
): FormFields => {
  return {
    basicData: {
      country: dto.country.id,
    },
    idsTypes: {
      idsTypes: dto.idsTypes.map((e) => ({
        id: e.id,
        isTaxId: e.isTaxId,
      })),
    },
  };
};
