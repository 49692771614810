import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { OptionsFormFields } from "./OptionsForm/types";

export const useOptionsDescriptionsForm = (
  optionsForm: UseFormReturn<OptionsFormFields>
) => {
  const [index, setIndex] = useState<number>();
  const [name, setName] = useState<string>();
  const [areDescriptionsVisible, setAreDescriptionsVisible] = useState(false);

  const onEnterDescriptions = async (index: number) => {
    const name = optionsForm.getValues(
      `options.${index}.name` as "options.0.name"
    );

    setName(name);
    setIndex(index);
    setAreDescriptionsVisible(true);
  };

  const exitDescriptions = () => {
    setName(undefined);
    setAreDescriptionsVisible(false);
  };

  const onExitDescriptions = optionsForm.handleSubmit(() => {
    exitDescriptions();
  });

  const onClickPageButton = () => {
    exitDescriptions();
  };

  return {
    onEnterDescriptions,
    onExitDescriptions,
    areDescriptionsVisible,
    onClickPageButton,
    name,
    index,
  };
};
