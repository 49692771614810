import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateDataElementMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";

const CreatePage = () => {
  const { mutateAsync: createDataElement } = useCreateDataElementMutation();
  const navigate = useNavigate();

  const onSave = (form: FormFields) =>
    createDataElement({ dataElement: mapFormToDto(form) });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title="Crear elemento de dato" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage="Error al crear el elemento de dato. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
