import { GetBusinessPartnerViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetBusinessPartnerViewPageQuery["businessPartner"]>
): FormFields => {
  return {
    basicData: {
      name: dto.name,
      lastname: dto.lastName ? dto.lastName : "",
      identification: dto.identification,
      taxname:  dto.taxName ? dto.taxName : "",
    },
    locations: {
      address: dto.locations.map((e) => ({
        descriptions: {
          street: e.address.street,
          number: e.address.number,
          floor: e.address.floor ? e.address.floor : "",
          department: e.address.department ? e.address.department : "",
          city: e.address.city,
          postalCode: e.address.postalCode,
          state: e.address.state,
          country: e.address.country,
        },
        isFiscalAddress: e.isFiscalAddress,
      }))
    },
    idsData: {
      country: dto.taxId?.countryId ? dto.taxId.countryId : "",
      countryId: dto.taxId?.typeId.id ? dto.taxId.typeId.id : "",
      noId: dto.taxId?.id ? dto.taxId?.id : "",
    },
    businessType: {
      types: dto.businessPartnerType.map((e) => ({
        businessType: e.id
      }))
    },
    contacts: {
      contact: dto.contacts.map((e) => ({
        userId: e.userId ? e.userId : "",
        email: e.email ? e.email : "",
        firstName: e.name ? e.name : "",
        lastName: e.lastName ? e.lastName : "",
        phoneNumber: e.phoneNumber ? e.phoneNumber : "",
        position: e.position ? e.position : "",
        birthDate: e.birthDate ? new Date(e.birthDate) : new Date(1900, 1, 1),
      }))
    },
    backend: {
      backends: dto.backend.map((e) => ({
        idBackend: e.backendId,
        idBackendExt: e.externalId
      }))
    }
  };
};
