import React from "react";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetCostCollector_ViewPageQuery,
  useUpdateCostCollectorMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { CostCollectorRoutesState, CostCollectorRoutes } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

type Route = CostCollectorRoutes.EDIT;
type LocationState = CostCollectorRoutesState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { costCollectorId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const {
    data: { costCollector } = {},
    isFetchedAfterMount,
  } = useGetCostCollector_ViewPageQuery({
    id: costCollectorId,
  });

  const initialValue =
    costCollector != null && isFetchedAfterMount
      ? mapDtoToForm(costCollector)
      : undefined;

  const { mutateAsync: replace } = useUpdateCostCollectorMutation();

  const onSave = (form: FormFields) =>
    replace({
      id: costCollectorId,
      costCollector: mapFormToDto(form),
    });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header title="Editar Recaudador de Costos" toBack={ TO_BACK_ROUTER } />

      <Form
        mode="edit"
        onSave={onSave}
        onCancel={onCancel}
        errorMessage="Error al editar el recaudador de costos. Vuelva a intentar."
        initialValue={initialValue}
        initialStep={activeStep}
      />
    </Layout>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
