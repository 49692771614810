import { DevTool } from "@hookform/devtools";
import { TableCollapse } from "components/datadisplay/TableCollapse";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useDialog } from "hooks/useDialog";
import { CurrencyRoutes } from "../../../routes";
import React, { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generatePath } from "utils/typedRoutesUtils";
import { columnsLabels } from "../../reducers/columnsSlice";
import {
  Filters,
  FiltersKeys,
  selecFilters,
  selectHiddenFiltersKeys,
  selectVisibleFiltersKeys,
  setFilters,
} from "../../reducers/filtersAndPaginationSlice";
import { ColumnsVisibilityDialog } from "./ColumnsVisibilityDialog";
import { FiltersVisibilityDialog } from "./FiltersVisibilityDialog";

const Collapse = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const filtersDialog = useDialog();

  const columnsDialog = useDialog();

  const filters = useAppSelector(selecFilters);

  const visibleFiltersKeys = useAppSelector(selectVisibleFiltersKeys);
  const hiddenFiltersKeys = useAppSelector(selectHiddenFiltersKeys);

  const { control, handleSubmit, unregister } = useForm<Filters>({
    defaultValues: filters,
  });

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: columnsLabels,
    control,
  });

  const onCreate = () => {
    navigate(generatePath(CurrencyRoutes.CREATE));
  };

  const onSearch = handleSubmit((form) => {
    dispatch(setFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <ColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapse
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={onCreate}
        onDownload={() => {}}
        onExpand={() => {}}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
      />
    </>
  );
};

export default Collapse;

const filtersComponents: Record<
  FiltersKeys,
  ComponentType<FilterComponentProps<Filters, FiltersKeys>>
> = {
  name: TextFilter,
  description: TextFilter,
};
