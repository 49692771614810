import React from "react";
import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { BusinessPartnerTypeRoutes } from "./routes";
import { EditPage } from "./EditPage";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";

const BusinessPartnerTypePage = () => {
  return (
    <Routes>
      <Route
        path={BusinessPartnerTypeRoutes.VIEW_ALL}
        element={<ViewAllPage />}
      />

      <Route path={BusinessPartnerTypeRoutes.VIEW} element={<ViewPage />} />

      <Route path={BusinessPartnerTypeRoutes.CREATE} element={<CreatePage />} />

      <Route path={BusinessPartnerTypeRoutes.EDIT} element={<EditPage />} />
    </Routes>
  );
};

export default BusinessPartnerTypePage;
