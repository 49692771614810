import {
  Add as AddIcon,
  AspectRatio as AspectRatioIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  ViewColumn as ViewColumnIcon
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  PopoverPosition,
  Tooltip,
  styled
} from "@mui/material";
import { CollapseButton } from "components/datadisplay/StyledCollapse/CollapseButton";
import { useAppMessages } from "hooks/useAppMessages";
import React, { useState } from "react";
import { ExcelSheetData } from "types/ExcelDataSet";
import TableRowMenu from "../GenericTable/components/TableRow/TableRowMenu/template";
import { MenuItemsProps } from "../GenericTable/template";
import ExcelComponent from "./js/export-excel";
import { getRowCoordinates } from "./js/table-rows-scripts";

// Se crea nuevo Table Collapse para no afectar el funcionamiento de los otros componenetes
type TableCollapseProps<T extends string> = {
  filters: JSX.Element[];
  isCreatedVisible: boolean;
  menuItems: MenuItemsProps[];
  sheet?: ExcelSheetData<T> | undefined;
  onSearch: () => void;
  onFilters: () => void;
  onExpand: () => void;
  onColumns: () => void;
  onCreate: () => void;
};

// Este nuevo table collapse recibe los items del menu
const TableCollapsePIC = <T extends string>({
  filters,
  isCreatedVisible,
  menuItems,
  sheet,
  onSearch,
  onFilters,
  onExpand,
  onColumns,
  onCreate,
}: TableCollapseProps<T>) => {
  
  const [anchorEl, setAnchorEl] = React.useState<PopoverPosition>();
  const [show, setShow] = useState(true);

  const { appMessage } = useAppMessages("MESSAGES");

  const onSettings = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {

    // Instructor coordinates 
    if(event.clientX === 0 && event.clientY === 0) {
      const coordinates = getRowCoordinates('last-row');
      setAnchorEl({
        left: coordinates.left,
        top: coordinates.left,
      });
    } else {
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });
    }
  };

  return (
    <>
      <TableRowMenu
        items={menuItems.map(({ key, text, Icon, onClick }) => ({
          key,
          text,
          Icon,
          onClick: () => onClick(key),
        }))}
        anchorPosition={anchorEl}
        onClose={() => {
          setAnchorEl(undefined);
        }}
      />

      <Wrapper>
        <Collapse in={show}>
          <Grid
            container
            spacing={2}
            alignContent="flex-end"
            justifyContent="flex-start"
          >
            {filters.map((filter) => (
              <Grid key={filter.key} item xs={3}>
                {filter}
              </Grid>
            ))}
          </Grid>
        </Collapse>

        <CollapseButton
          show={show}
          toggleShow={() => setShow((show) => !show)}
        />

        <Box marginTop={1}>
          <Grid
            container
            spacing={1}
            alignContent="flex-end"
            justifyContent="flex-end"
            wrap="nowrap"
          >
            <Grid
              item
              style={{ marginRight: "auto" }} // FIXME:
            >
              <StyledIconButton aria-label="search" onClick={onSearch}>
                <Tooltip
                  title={appMessage["FIND"] ?? "Find"}
                >
                  <SearchIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton 
                id="collapse-filter" 
                aria-label="filters" 
                onClick={onFilters}
              >
                <Tooltip
                  title={appMessage["FILTERS"] ?? "Filters"}
                >
                  <FilterListIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid 
              item
              display={menuItems.length === 0 ? "none" : ""} // TODO: Update all
            >
              <StyledIconButton 
                id="collapse-actions"
                aria-label="settings" 
                onClick={onSettings}
              >
                <Tooltip
                  title={appMessage["ACTIONS"] ?? "Actions"}
                >
                  <SettingsIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid 
              item
              display={sheet ? "" : "none"} // TODO: Update all apps
            >
              <ExcelComponent 
                sheet={sheet} 
              />
            </Grid>

            <Grid 
              item
              display={"none"} //FIXME: Agregar funcionalidad
            >
              <StyledIconButton aria-label="expand" onClick={onExpand}>
                <Tooltip
                  title={appMessage["EXPAND"] ?? "Expand"}
                >
                  <AspectRatioIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton 
                id="collapse-columns"
                aria-label="columns" 
                onClick={onColumns}
              >
                <Tooltip
                  title={appMessage["COLUMNS"] ?? "Columns"}
                >
                  <ViewColumnIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>
            
            { isCreatedVisible && (
              <Grid item>
                <StyledIconButton 
                  id="collapse-create"
                  aria-label="create" 
                  onClick={onCreate}
                >
                  <Tooltip
                    title={appMessage["CREATE"] ?? "Create"}
                  >
                    <AddIcon />
                  </Tooltip>
                </StyledIconButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </Wrapper>
    </>
  );
};

export default TableCollapsePIC;

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.apps,
  borderRadius: theme.shape.wrapperBorderRadius,
  padding: theme.spacing(2),
}));

const StyledIconButton = styled((props) => (
  <IconButton size="small" {...props} />
))<IconButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));
