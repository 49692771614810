import { TableCellProps } from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { PositionsRoutes } from "apps/PositionsPage/routes";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath } from "utils/typedRoutesUtils";
import {
  GetPositions_ViewAllPageQuery,
  useDeletePositionMutation,
  useGetPositions_ViewAllPageQuery,
} from "__generated__/graphql/types";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked,
} from "../../reducers/checkedRowsSlice";
import {
  ColumnKeys,
  columnsLabels,
  COLUMNS_KEYS,
  selectVisibleColumnsKeysInOrder,
} from "../../reducers/columnsSlice";
import {
  nextPage,
  prevPage,
  resetPage,
  selecFilters,
  selectPagination,
} from "../../reducers/filtersAndPaginationSlice";

export const useTable = () => {
  const errorSnackbar = useSnackbar();

  const { language } = useSystemLanguage();

  const filters = useAppSelector(selecFilters);

  const { mutateAsync: deletePosition } = useDeletePositionMutation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const orderedColumns = useAppSelector(selectVisibleColumnsKeysInOrder);

  const pagination = useAppSelector(selectPagination);

  const areAllRowsChecked = useAppSelector(selectAreAllRowsChecked);

  const isRowChecked = useAppSelector(selectIsRowChecked);

  const {
    data: { positions, positionsCount: total } = {},
    refetch: findPositions,
  } = useGetPositions_ViewAllPageQuery({
    language,
    filters: { ids: filters.name === undefined ? undefined : [filters.name] }, //FIXME: REFACTOR
    pagination,
  });

  const items = useMemo(
    () => [
      {
        key: "display",
        Icon: SearchIcon,
        text: "Display",
        onClick: (id: string) => {
          navigate(
            generatePath(PositionsRoutes.VIEW, {
              positionId: id,
            })
          );
        },
      },
      {
        key: "edit",
        Icon: EditIcon,
        text: "Edit",
        onClick: (id: string) => {
          navigate(
            generatePath(PositionsRoutes.EDIT, {
              positionId: id,
            })
          );
        },
      },
      {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: "Delete",
        onClick: (id: string) => {
          deletePosition({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findPositions())
            .catch(() =>
              errorSnackbar.open(
                "Error al eliminar el elemento de dato. Vuelva a intentar."
              )
            );
        },
      },
    ],
    [deletePosition, dispatch, errorSnackbar, findPositions, navigate]
  );

  const columns = useMemo(
    () => orderedColumns.map((colKey) => header[colKey]),
    [orderedColumns]
  );

  const rows = useMemo(() => positions?.map(mapRowDataToRowCells), [
    positions,
  ]);

  const onPrevPage = useCallback(() => {
    dispatch(prevPage());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  const onChangeCheckedRow = useCallback(
    (id: string, checked: boolean) => {
      dispatch(checkRow({ id, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  return {
    columns,
    rows,
    items,
    isRowChecked,
    onChangeCheckedRow,
    areAllRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    total,
    pagination,
    errorSnackbar,
  };
};

const mapRowDataToRowCells = (
  dto: NonNullable<GetPositions_ViewAllPageQuery["positions"]>[number]
): TableRowType<ColumnKeys> => {
  return {
    rowKey: dto.id,
    name: dto.id.toUpperCase(),
    description: dto.translation.description,
  };
};

const columnAlign: Partial<Record<ColumnKeys, TableCellProps["align"]>> = {};

const header = Object.fromEntries(
  COLUMNS_KEYS.map((key) => [
    key,
    {
      key,
      content: columnsLabels[key].toUpperCase(),
      align: columnAlign[key],
    },
  ])
);
