import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import React from "react";
import { ImageFormProps } from "./types";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ImagePicker } from "components/inputs/ImagePicker";

const IconForm = ({ form, readOnly = false, onEdit }: ImageFormProps) => {
  const { control } = form;

  const { dataElementsDescriptions } = useDataElementDescription(["TILE_ICON"]);

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool placement="top-left" control={control} />

      <Grid container wrap="nowrap" justifyContent="center">
        <Grid item>
          <ImagePicker
            tooltipTitle="Subir ícono de mosaico" // FIXME: traduccion
            label={dataElementsDescriptions["TILE_ICON"]}
            controllerProps={{
              name: "icon",
              control,
              rules: { required: "Suba una imagen." }, // FIXME: traduccion
            }}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default IconForm;
