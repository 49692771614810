import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { useAppMessages } from "hooks/useAppMessages";
import { useLanguagesOptions } from "hooks/useLanguagesOptions";
import {
  containsDefaultLanguage,
  isRepeatedLanguage
} from "utils/translations";
import { RowProps } from "./types";

const Row = ({
  index,
  remove,
  item,
  readOnly,
  formMethods,
  externalIndex,
}: RowProps) => {

  const { control, trigger, getValues } = formMethods;
  
  const { languagesOptions } = useLanguagesOptions();
  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `instructions.${externalIndex}.descriptions.${index}.language` as "instructions.0.descriptions.0.language",
            control,
            rules: {
              required: appMessage["CHOOSE_ONE"] ?? "Choose one",
              validate: (language) => {
                const descriptions = getValues(
                  `instructions.${externalIndex}.descriptions` as "instructions.0.descriptions"
                );

                if (isRepeatedLanguage(descriptions, language))
                  return appMessage["DATA_REPEATED"] ?? "Data repeated";

                if (!containsDefaultLanguage(descriptions))
                  return appMessage["EN_LANGUAGE"] ?? "Must include default language (EN)";

                return true;
              },
            },
            defaultValue: item.language,
          }}
          select
        >
          {languagesOptions.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </FormTableTextFieldController>
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `instructions.${externalIndex}.descriptions.${index}.description` as "instructions.0.descriptions.0.description",
            control,
            rules: {
              required: appMessage["FIELD_REQUIRED"] ?? "Field required",
            },
            defaultValue: item.description,
          }}
        />
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip
            title={appMessage["DELETE_ROW"] ?? "Delete row"}
          >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
