import {
  ListItemIcon,
  PopoverPosition,
  styled,
  SvgIconTypeMap,
  Typography
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { pipe } from "ramda";

export type TableRowMenuItem = {
  key: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  text: string;
  onClick: () => void;
};

export type TableRowMenuProps = {
  items: TableRowMenuItem[];
  anchorPosition?: PopoverPosition;
  onClose: () => void;
};

const TableRowMenu = ({
  items,
  anchorPosition,
  onClose,
}: TableRowMenuProps) => {

  const open = typeof anchorPosition !== "undefined";

  return (
    <Menu
      anchorPosition={anchorPosition}
      anchorReference="anchorPosition"
      keepMounted
      open={open}
      onClose={onClose}
      disableScrollLock={true}
      autoFocus={false}
    >
      <div id={open ? "simple-menu" : undefined}>
        {items.map(({ key, text, Icon, onClick }) => (
          <StyledMenuItem key={key} onClick={pipe(onClick, onClose)}>
            <StyledListItemIcon>
              <Icon fontSize="small" />
            </StyledListItemIcon>
            <Typography variant="inherit">{text}</Typography>
          </StyledMenuItem>
        ))}
      </div>
    </Menu>
  );
};

export default TableRowMenu;

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 30,
  ".MuiMenuItem-root:hover &": {
    color: theme.palette.primary.contrastText,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-focusVisible": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));
