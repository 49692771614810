import {
  DeprecatedThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { LoadingPage } from "components/layout/LoadingPage";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import React, { useEffect } from "react";
import {
  selectIsThemeFromBackend,
  selectMuiTheme,
  setBackgroundImage,
  setCompanyLogo,
  setCurrentThemeId,
  setThemeOptions,
} from "./reducer";
import { useGetMyInformationQuery } from "__generated__/graphql/types";
import { notNullUndefined } from '../../../utils/notNullUndefined';
import { getMyInformation, setMyInformation } from "components/providers/ReduxMyProvider/reducer";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { GetMyInformationQuery } from '../../../__generated__/graphql/types';

const ReduxMuiThemeProvider = ({ children }: { children: React.ReactNode }) => {
  
  const dispatch = useAppDispatch();
  const { language } = useSystemLanguage();

  const theme = useAppSelector(selectMuiTheme);
  const isThemeFromBackend = useAppSelector(selectIsThemeFromBackend);
  const { me: myHook } = useAppSelector(getMyInformation);

  const { 
    data: { me } = { refetchOnWindowsFocus: false },
    isFetchedAfterMount, 
  } = useGetMyInformationQuery(
    { language },
    { enabled: !notNullUndefined(myHook) }
  );

  useEffect(() => {

    if(isFetchedAfterMount && myHook === undefined) {
      dispatch(setMyInformation(me));
    }

    if(isThemeFromBackend && me !== undefined) {
      dispatch(setCurrentThemeId(me?.effectiveTheme.id));
      dispatch(setThemeOptions(mapSystemThemeToThemeOptions(me?.effectiveTheme)));
      dispatch(setBackgroundImage(me?.effectiveTheme.backgroundImage.url));
      dispatch(setCompanyLogo(me?.effectiveTheme.companyLogo?.url));
    }

  // FIXME: Al cerrar sesión eliminar eliminar cache de los hooks
  // eslint-disable-next-line 
  }, [me, isThemeFromBackend]);

  if (theme === undefined) return <LoadingPage />;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default ReduxMuiThemeProvider;

export const mapSystemThemeToThemeOptions = (
  theme: NonNullable<GetMyInformationQuery["me"]["effectiveTheme"]>
): DeprecatedThemeOptions => {
  return {
    palette: {
      primary: {
        main: theme.primaryColor,
        contrastText: theme.primaryColorContrast,
      },
      username: theme.userColor,
      background: {
        apps: theme.appsColor,
      },
      appsTitle: theme.appsTitleColor,
      stepper: {
        color1: theme.stepperColor1,
        color2: theme.stepperColor2,
        color3: theme.stepperColor3,
        internal: theme.stepperInternalColor,
        text: theme.stepperTextColor,
      },
    },
  };
};
