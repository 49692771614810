export enum PurchaseOrdersDirectRoutes {
  PATH = "/",
  PATH_ITEMS = "items",
  PATH_INVOICES = "items/invoices",
  PATH_INVOICES_VIEW = "items/invoices/view",
  PATH_INVOICES_EDIT = "items/invoices/edit",
  PATH_INVOICES_CREATE = "items/invoices/create",
}

export type PurchaseOrdersDirectRoutesState = {
  purchaseOrderId: string
};

