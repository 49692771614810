import { EmployeeInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): EmployeeInput => {
  return {
    type: form.basicData.employeeType,
    accountingEntityId: form.basicData.employeeType === "INTERNAL" ? form.basicData.accountingEntity : null,
    businessPartnerId: form.basicData.employeeType === "EXTERNAL" ? form.basicData.businessPartner : null,
    contact: {
      userId: !form.contactData._hasUser ? null : form.contactData.user.id,
      email:  form.contactData._hasUser ? null : form.contactData.contact.email,
      lastName:  form.contactData._hasUser ? null : form.contactData.contact.lastName,
      name: form.contactData._hasUser ? null : form.contactData.contact.firstName,
      phoneNumber: form.contactData._hasUser ? null : form.contactData.contact.phone,
    },
    positionHistory: form.positionHistory.positions.map(x => ({
      positionId: x.positionId,
      startDate: x.startDate.toJSON().slice(0,10)
    }))
  };
};
