import { DevTool } from "@hookform/devtools";
import {
  Box,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { FiltersDialog } from "components/filters/FiltersDialogV2";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { SelectController } from "components/inputs/SelectController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useDialog } from "hooks/useDialog";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { useImmer } from "use-immer";
import { useGetBusinessObjectForCreatingKpiQuery } from "__generated__/graphql/types";
import { ConfigureKpiFormProps } from "./types";

const ConfigureKpiForm = ({
  form,
  businessObjectId,
  readOnly = false,
  onEdit,
}: ConfigureKpiFormProps) => {
  const dialog = useDialog();

  const { control, watch } = form;
  const tableId = watch("tableId");
  const summarization = watch("summarization");

  const { dataElementsChoices } = useDataElementChoices([
    "KPI_AGGREGATION_TYPE",
  ]);

  const { dataElementsDescriptions } = useDataElementDescription([
    "TABLE",
    "KPI_AGGREGATION_TYPE",
  ]);

  const { language } = useSystemLanguage();

  const {
    data: { businessObject } = {},
  } = useGetBusinessObjectForCreatingKpiQuery({
    businessObjectId,
    language,
  });

  const tablesOptions = businessObject?.tables.map((e) => ({
    id: e.table.id,
    name: e.table.id,
    description: e.table.translation.description,
  }));

  const columns = businessObject?.tables.find(
    (table) => table.table.id === tableId
  )?.table.columns;

  const columnsOptions = columns
    ?.filter((e) => e.type === "RATIO")
    ?.map((e) => ({
      id: e.id,
      name: e.id.toUpperCase(),
      description: e.id,
    }));

  const [filtersColumnsIds, setFiltersColumnsIds] = useImmer<string[]>([]);

  const filterColumns = columns?.filter((e) =>
    filtersColumnsIds.includes(e.id)
  );

  if (tablesOptions == null) return null; // FIXME: loading spinner
  if (dataElementsChoices == null) return null; // FIXME: loading spinner
  if (dataElementsDescriptions == null) return null; // FIXME: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool placement="top-left" control={control} />

      <FiltersDialog
        open={dialog.isOpen}
        handleClose={dialog.close}
        filters={columns?.map((e) => ({ key: e.id, label: e.id })) ?? []}
        isChecked={(key) => filtersColumnsIds.includes(key)}
        onChangeChecked={(key, checked) => {
          if (checked) {
            setFiltersColumnsIds((draft) => {
              draft.push(key);
            });
          } else {
            setFiltersColumnsIds((draft) => {
              draft.splice(draft.indexOf(key));
            });
          }
        }}
      />

      <Grid container spacing={2} wrap="nowrap" alignItems="center">
        <>
          <Grid item xs={3}>
            <MatchcodeInputController
              label={dataElementsDescriptions["TABLE"]}
              controllerProps={{
                name: "tableId",
                control,
                rules: { required: "Seleccione el objeto de negocio." }, // FIXME: traduccion
              }}
              column={[
                { key: "name", text: "NOMBRE" }, // FIXME: traduccion
                { key: "description", text: "DESCRIPTION" }, // FIXME: traduccion
              ]}
              displayColumn="name"
              options={tablesOptions}
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={3}>
            <SelectController
              label={dataElementsDescriptions["KPI_AGGREGATION_TYPE"]}
              controllerProps={{
                name: "summarization",
                control,
                rules: { required: "Seleccione el objeto de negocio." }, // FIXME: traduccion
              }}
              options={dataElementsChoices["KPI_AGGREGATION_TYPE"]}
            />
          </Grid>

          {summarization &&
            summarization !== "COUNT" && ( // FIXME: tipar
              <Grid item xs={3}>
                <MatchcodeInputController
                  label="Columna" // FIXME: traduccion
                  controllerProps={{
                    name: "columnName",
                    control,
                    rules: { required: "Seleccione el objeto de negocio." }, // FIXME: traduccion
                  }}
                  column={[
                    { key: "name", text: "NOMBRE" }, // FIXME: traduccion
                    { key: "description", text: "DESCRIPTION" }, // FIXME: traduccion
                  ]}
                  displayColumn="name"
                  options={columnsOptions ?? []}
                  readOnly={readOnly}
                />
              </Grid>
            )}

          {summarization === "AVERAGE" && ( // FIXME: tipar
            <Grid item xs={3}>
              <MatchcodeInputController
                label="Criterio" // FIXME: traduccion
                controllerProps={{
                  name: "criteria",
                  control,
                  rules: { required: "Seleccione el objeto de negocio." }, // FIXME: traduccion
                }}
                column={[
                  { key: "name", text: "NOMBRE" }, // FIXME: traduccion
                  { key: "description", text: "DESCRIPTION" }, // FIXME: traduccion
                ]}
                displayColumn="name"
                options={[
                  // TODO: columnas hardcodeadas
                  { id: "1", name: "COL_1", description: "Columna 1" }, // FIXME: traduccion
                  { id: "2", name: "COL_2", description: "Columna 2" }, // FIXME: traduccion
                ]}
                readOnly={readOnly}
              />
            </Grid>
          )}

          <Grid item xs={10}>
            <StyledIconButton
              aria-label="filters"
              size="small"
              onClick={dialog.open}
            >
              <Tooltip
                title="Filtros" // FIXME: traduccion
              >
                <FilterListIcon />
              </Tooltip>
            </StyledIconButton>
          </Grid>
        </>
      </Grid>

      {filterColumns && filterColumns.length > 0 && (
        <Box marginTop={2}>
          <Typography>
            {/* // FIXME: traduccion */}
            Filtros
          </Typography>

          <Grid container spacing={2} wrap="nowrap">
            {filterColumns?.map((e) => (
              <Grid item xs={2}>
                <TextFieldController
                  inputProps={{ disabled: readOnly }}
                  label={e.id.toUpperCase()}
                  controllerProps={{
                    name: `filters.${e.id}` as any,
                    control,
                    rules: { required: "Ingrese la url del indicador." }, // FIXME: traduccion
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </FormNonTableBodyWrapper>
  );
};

export default ConfigureKpiForm;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));
