import {
  StepButton,
  StepIconProps,
  Stepper as MuiStepper,
} from "@mui/material";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import React from "react";

type FormStepperProps = {
  activeStepIndex: number;
  isNonLinear?: boolean;
  onChangeActiveStepIndex?: (step: number) => void;
  steps: {
    title: string;
    icon: JSX.Element;
  }[];
};

const FormStepper = ({
  activeStepIndex,
  isNonLinear = false,
  steps,
  onChangeActiveStepIndex,
}: FormStepperProps) => {
  return (
    <StepperWrapper>
      <MuiStepper
        alternativeLabel
        activeStep={activeStepIndex}
        connector={
          isNonLinear ? (
            <NonLinearStyledStepConnector />
          ) : (
            <LinearStyledStepConnector />
          )
        }
        nonLinear={isNonLinear}
      >
        {steps.map(({ title, icon }, index) => (
          <Step
            key={title} // FIXME:
            {...(isNonLinear && index === activeStepIndex
              ? { completed: true }
              : {})}
          >
            <StepButton
              disableRipple
              onClick={() => onChangeActiveStepIndex?.(index)}
              icon={
                <StyledStepLabel
                  StepIconComponent={StepLastIcon}
                  StepIconProps={{ icon }}
                >
                  {title}
                </StyledStepLabel>
              }
            />
          </Step>
        ))}
      </MuiStepper>
    </StepperWrapper>
  );
};

export default FormStepper;

const StepperWrapper = styled("div")({
  width: "100%",
  "& .MuiStepper-root": {
    padding: "24px 0",
  },
});

const NonLinearStyledStepConnector = styled(StepConnector)({
  "&.MuiStepConnector-alternativeLabel": {
    top: 22,
  },
  "& .MuiStepConnector-line": {
    height: 3,
    border: 0,
    backgroundColor: "hsl(240, 17%, 93%)",
    borderRadius: 1,
  },
});

const LinearStyledStepConnector = styled(NonLinearStyledStepConnector)(
  ({ theme }) => ({
    "&.Mui-active .MuiStepConnector-line": {
      backgroundImage: `linear-gradient( 95deg,
      ${theme.palette.stepper.color3} 0%,
      ${theme.palette.stepper.color2} 50%,
      ${theme.palette.stepper.color1} 100%)`,
    },
    "&.Mui-completed .MuiStepConnector-line": {
      backgroundImage: `linear-gradient( 95deg,
      ${theme.palette.stepper.color3} 0%,
      ${theme.palette.stepper.color2} 50%,
      ${theme.palette.stepper.color1} 100%)`,
    },
  })
);

const StepLastIcon = ({
  active,
  completed,
  icon,
  className,
}: StepIconProps) => {
  return (
    <StyledStepLastIcon
      active={active}
      completed={completed}
      className={className}
    >
      {icon}
    </StyledStepLastIcon>
  );
};

const StyledStepLastIcon = styled("div")<{
  active?: boolean;
  completed?: boolean;
}>(({ active, completed, theme }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: theme.palette.stepper.internal,
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: active ? "0 4px 10px 0 rgba(0,0,0,.25)" : "none",
  backgroundImage:
    active || completed
      ? (`linear-gradient(136deg, ${theme.palette.stepper.color1} 0%, ${theme.palette.stepper.color2} 50%, ${theme.palette.stepper.color3} 100%)` as string)
      : "none",
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    color: theme.palette.stepper.text,
  },
}));
