import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import {
  Box,
  Button, Grid, styled
} from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { MenuInstructions } from 'components/Instructor/steps/menu';
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from 'components/layout/LoadingLayoutPage';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSnackbar } from "hooks/useSnackbarV2";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetPurchaseOrdersForPurchaseOrdersItemsReportsQuery
} from "__generated__/graphql/types";
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { PurchaseOrdersDirectRoutes } from '../routes';
import { GroupsMatchcode } from "./components/GroupsMatchcode";


const PurchaseOrderSelectPage = () => {

  const navigate = useNavigate();
  
  const [PO, setPO] = React.useState<string | undefined>(undefined);
  const [errorMessage, setMessage] = React.useState<string|undefined>(undefined);

  const { data: { purchaseOrders } = {} } = useGetPurchaseOrdersForPurchaseOrdersItemsReportsQuery({});

  const { appMessage } = useAppMessages("MESSAGES");
  const { appMessage: appPurchaseOrdersItems } = useAppMessages("PURCHASE_ORDERS");
  const { dataElementsDescriptions } = useDataElementDescription(["PURCHASE_ORDER_ITEM_ID"]);
  const errorSnackbar = useSnackbar();

  const changePO = (value?:string) => {
    if(value) {
      setMessage(undefined);
      setPO(value);
    } else {
      setPO(undefined);
    }
  }

  const navigateInvoices = () => {

    if(!PO) {
      setMessage(appMessage["CHOOSE_ONE"])
      return;
    }

    navigate(PurchaseOrdersDirectRoutes.PATH_ITEMS, {
      state: {
        purchaseOrderId: PO
      }
    })
  }
  
  if (purchaseOrders == null) return <LoadingLayoutPage />;
  if (dataElementsDescriptions == null) return <LoadingLayoutPage />;

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      {/* Instructor */}
      <MenuInstructions tutorialsIds={["PO_ITEMS_DISPLAY"]} />

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appPurchaseOrdersItems["PURCHASE_ORDER_TITLE"]}
          toBack={TO_BACK_ROUTER}
        />

        <BodyWrapper readOnly={false} onEdit={() => {}}>
          <Grid container direction="column" spacing={2} xs={12} alignItems="center" justifyContent="center">
            <GridGroupFields item id="purchase-order-group" xs={10}>
              <GroupsMatchcode
                purchaseOrders={purchaseOrders}
                value={PO}
                onChange={(value) => { changePO(value) }}
                text={PO}
                invalid={false}
                errorMessage={errorMessage}
                readOnly={false}

              />
            </GridGroupFields>
            <GridGroupFields item>
              <ButtonStyled 
                id="find-button"
                aria-label="remove" 
                size="medium" 
                endIcon={ <AssignmentOutlinedIcon/> } 
                onClick={() => navigateInvoices()}
              >                
                {appMessage["FIND"] ?? "Find"}
              </ButtonStyled>
            </GridGroupFields>
          </Grid>
        </BodyWrapper>
      </Box>
    </Layout>
  );
};

export default PurchaseOrderSelectPage;

const TO_BACK_ROUTER = "../../";

const ButtonStyled = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const BodyWrapper = styled(FormNonTableBodyWrapper)(({theme}) => ({
  marginTop: theme.spacing(1)
}));

const GridGroupFields = styled(Grid)(({theme}) => ({
  minWidth: '300px', // FIXME:
  maxWidth: '300px', // FIXME:
  textAlign:'center',
  alignItems: 'center'
}));
