import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const usePurchaseOrderItemsLabels = () => {

    const purchaseOrderItem = "PURCHASE_ORDER_ITEM_ID";
    const productId = "PRODUCT_ID";
    const productDescription = "PRODUCT_DESCRIPTION";
    const hasInvoice = "HAS_INVOICE";
    const location = "LOCATION"; 
    const locationDesc = "LOCATION_DESC"; 
    const unit  = "UNIT";
    const poQuantity = "PO_QUANTITY";
    const netUnitPrice  = "NET_UNIT_PRICE";
    const netTotalPrice  = "NET_TOTAL_PRICE";
    const grQuantity  = "GR_QUANTITY";
    const grAmount = "GR_AMOUNT";
    const picInvoicedQuantity = "PIC_INVOICED_QUANTITY";
    const picInvoicedAmount = "PIC_INVOICED_AMOUNT";
    const invoiceApprovedQuantity = "INVOICE_APPROVED_QUANTITY";
    const invoiceApprovedAmount = "INVOICE_APPROVED_AMOUNT";
    const needGr = "NEED_GR";
    const completeInvoice  = "COMPLETE_INVOICE";
    
    const dataElementsIds = [
        purchaseOrderItem, productId, productDescription, hasInvoice, location, locationDesc, unit, poQuantity, netUnitPrice, netTotalPrice, 
        grQuantity, grAmount, picInvoicedQuantity, picInvoicedAmount, invoiceApprovedQuantity, invoiceApprovedAmount, needGr, completeInvoice
    ];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels : NonNullable<Record<ColumnsKeys, string>> = {
        purchaseOrderItem: dataElementsDescriptions === undefined ? purchaseOrderItem : (dataElementsDescriptions[purchaseOrderItem] ?? purchaseOrderItem),  
        productId: dataElementsDescriptions === undefined ? productId : (dataElementsDescriptions[productId] ?? productId),
        productDescription: dataElementsDescriptions === undefined ? productDescription : (dataElementsDescriptions[productDescription] ?? productDescription),
        hasInvoice: dataElementsDescriptions === undefined ? hasInvoice : (dataElementsDescriptions[hasInvoice] ?? hasInvoice),
        locationDesc: dataElementsDescriptions === undefined ? locationDesc : (dataElementsDescriptions[locationDesc] ?? locationDesc),
        unit: dataElementsDescriptions === undefined ? unit : (dataElementsDescriptions[unit] ?? unit),  
        poQuantity: dataElementsDescriptions === undefined ? poQuantity : (dataElementsDescriptions[poQuantity] ?? poQuantity),  
        netUnitPrice: dataElementsDescriptions === undefined ? netUnitPrice : (dataElementsDescriptions[netUnitPrice] ?? netUnitPrice),  
        netTotalPrice: dataElementsDescriptions === undefined ? netTotalPrice : (dataElementsDescriptions[netTotalPrice] ?? netTotalPrice),  
        grQuantity: dataElementsDescriptions === undefined ? grQuantity : (dataElementsDescriptions[grQuantity] ?? grQuantity),  
        grAmount: dataElementsDescriptions === undefined ? grAmount : (dataElementsDescriptions[grAmount] ?? grAmount),
        picInvoicedQuantity: dataElementsDescriptions === undefined ? picInvoicedQuantity : (dataElementsDescriptions[picInvoicedQuantity] ?? picInvoicedQuantity),
        picInvoicedAmount: dataElementsDescriptions === undefined ? picInvoicedAmount : (dataElementsDescriptions[picInvoicedAmount] ?? picInvoicedAmount),
        invoiceApprovedQuantity: dataElementsDescriptions === undefined ? invoiceApprovedQuantity : (dataElementsDescriptions[invoiceApprovedQuantity] ?? invoiceApprovedQuantity),
        invoiceApprovedAmount: dataElementsDescriptions === undefined ? invoiceApprovedAmount : (dataElementsDescriptions[invoiceApprovedAmount] ?? invoiceApprovedAmount),
        needGr: dataElementsDescriptions === undefined ? needGr : (dataElementsDescriptions[needGr] ?? needGr),
        completeInvoice: dataElementsDescriptions === undefined ? completeInvoice : (dataElementsDescriptions[completeInvoice] ?? completeInvoice),  
    };

    return { columnsLabels }
}