import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import { MatchcodeDisplayInputInTableRow } from "components/inputs/matchcode/MatchcodeDisplayInputInTableRow";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import React from "react";
import { GetDataElementsForCreateTableQuery } from "__generated__/graphql/types";
import { generateDataElementDescription } from "../../../utils";

export type DataElementMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  dataElementsOptions: NonNullable<
    GetDataElementsForCreateTableQuery["dataElements"]
  >;
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
};

export const DataElementMatchcode = ({
  value,
  onChange,
  dataElementsOptions,
  invalid,
  errorMessage,
  readOnly = false,
}: DataElementMatchcodeProps) => {
  const options = dataElementsOptions?.map((e) => ({
    id: e.id,
    name: e.id,
    description: e.translation.description,
    dataTypeDescription: generateDataElementDescription(e),
  }));

  const {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    value,
    onChange,
    searchKeys: ["name", "description", "dataTypeDescription"],
  });

  return (
    <>
      <KeyInputWrapper>
        <MatchcodeDisplayInputInTableRow
          text={text?.name ?? ""}
          isDeleteButtonVisible={isDeleteButtonVisible}
          onClearValue={onClearValue}
          onOpenDialog={dialog.open}
          error={invalid}
          helperText={errorMessage}
          readOnly={readOnly}
        />
      </KeyInputWrapper>

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "name", text: "NOMBRE" }, // FIXME: traduccion
              { key: "description", text: "DESCRIPCION" }, // FIXME: traduccion
              { key: "dataTypeDescription", text: "TIPO DE DATO" }, // FIXME: traduccion
            ]}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default DataElementMatchcode;
