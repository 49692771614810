import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { urlToFile } from "utils/urlToFile";
import { ThemeInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";
import { GetMyInformationQuery } from '../../../__generated__/graphql/types';

export const mapFormToDto = (
  formFields: FormFields,
  backgroundImageId: string,
  companyLogoId: string | undefined,
): ThemeInput => {
  return {
    id: formFields.basicData.name,
    primaryColor: formFields.primaryColor.primaryColor,
    primaryColorContrast: formFields.primaryColor.primaryColorContrast,
    backgroundImageId: backgroundImageId,
    companyLogoId: companyLogoId,
    userColor: formFields.primaryColor.userColor,
    appsColor: formFields.appsColor.appsColor,
    appsTitleColor: formFields.appsColor.appsTitleColor,
    stepperColor1: formFields.stepperColor.color1,
    stepperColor2: formFields.stepperColor.color2,
    stepperColor3: formFields.stepperColor.color3,
    stepperInternalColor: formFields.stepperColor.internalColor,
    stepperTextColor: formFields.stepperColor.textColor,
    translations: formFields.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
  };
};

export const mapDtoToForm = async ({
  theme,
}: {
  theme: NonNullable<GetMyInformationQuery["me"]["effectiveTheme"]>;
}): Promise<FormFields> => {
  return {
    basicData: { name: "" },
    descriptions: { descriptions: [emptyDescriptionRow] },
    primaryColor: {
      primaryColor: theme.primaryColor,
      primaryColorContrast: theme.primaryColorContrast,
      userColor: theme.userColor,
    },
    backgroundImage: {
      backgroundImage: await urlToFile(
        theme.backgroundImage.url,
        theme.backgroundImage.name
      ),
    },
    appsColor: {
      appsColor: theme.appsColor,
      appsTitleColor: theme.appsTitleColor,
    },
    stepperColor: {
      color1: theme.stepperColor1,
      color2: theme.stepperColor2,
      color3: theme.stepperColor3,
      internalColor: theme.stepperInternalColor,
      textColor: theme.stepperTextColor,
    },
    companyLogo: {
      companyLogo: theme.companyLogo != null 
        ? await urlToFile(
          theme.companyLogo!.url,
          theme.companyLogo!.name
        )
        : null,
    },
  };
};
