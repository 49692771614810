import { DevTool } from "@hookform/devtools";
import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSystemLanguage } from 'hooks/useSystemLanguage';
import { useFieldArray } from "react-hook-form";
import { useGetAppsForCreateTutorialFormQuery } from '../../../../../__generated__/graphql/types';
import { useApps } from "../hooks";
import { Header } from "./Header";
import { Row } from "./Row";
import { AppIdsFormFields, AppsFormFields, AppsFormProps } from './types';

export const emptyAppId: AppIdsFormFields = {
  id: ""
}

const AppsForm = ({
  forms,
  readOnly,
  onEdit,
}: AppsFormProps) => {

  const { control } = forms;
  const { language } = useSystemLanguage();
  
  const {
    data: { apps } = {},
  } = useGetAppsForCreateTutorialFormQuery( { language:language } );
  const { mapApps } = useApps(apps!);

  const {
    fields,
    append,
    remove,
  } = useFieldArray<AppsFormFields>({
    control,
    name: "apps"
  });

  const onAppendRow = () => {
    append(emptyAppId);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <StyledTableContainer id="table-form">
        <StyledTable aria-label="simple table" size="small">
          <Header 
            appendRow={onAppendRow} 
            onEdit={onEdit} 
            readOnly={readOnly}
          />

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                key={item.id}
                index={index}
                remove={onRemove(index)}
                readOnly={readOnly!}
                appsByIdMap={mapApps}
                form={forms}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </>
  );
};

export default AppsForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});

const StyledTableContainer = styled(TableContainer)({
  overflowX: "auto !important" as any, //FIXME:
});

