import { Box } from "@mui/material";
import { Layout } from "components/layout/Layout";
import React from "react";
import { BeatLoader } from "react-spinners";

const LoadingLayoutPage = () => {
  return (
    <Layout>
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <BeatLoader margin={15} size={20} color="white" />
      </Box>
    </Layout>
  );
};

export default LoadingLayoutPage;
