import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateBusinessPartnerTypeMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";
import useAppMessages from 'hooks/useAppMessages/hook';

const CreatePage = () => {
  const { mutateAsync: create } = useCreateBusinessPartnerTypeMutation();
  const navigate = useNavigate();

  const { appMessage } = useAppMessages("BUSINESS_PARTNER_TYPES");
  const onSave = (form: FormFields) =>
    create({ businessPartnerType: mapFormToDto(form) });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title={appMessage["CREATE_BPTYPE"]}
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage={appMessage["ERROR_CREATE"]}
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
