import { styled } from "@mui/material";
import { FabBot } from "components/inputs/FabBot";
import { Instructions } from "components/Instructor";
import NavbarSide from "components/layout/NavbarSide/template";
import { selectBackgroundImage } from "components/providers/ReduxMuiThemeProvider/reducer";
import { useAppSelector } from "hooks/reduxHooks";
import React from "react";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const backgroundImage = useAppSelector(selectBackgroundImage);
  return (
    <BackgroundImageWrapper backgroundImage={backgroundImage}>
      <NavBarWrapper>
        <NavbarSide />
      </NavBarWrapper>
      
      <Instructions/>
      <BodyWrapper>
        {children}
      </BodyWrapper>

      <FabBot />
      {/* <FabChat /> TODO: Agregar chat */} 
    </BackgroundImageWrapper>
  );
};

export default Layout;

const BackgroundImageWrapper = styled("div")<{ backgroundImage?: string }>(
  ({ backgroundImage }) => ({
    backgroundImage: backgroundImage && `url(${backgroundImage})`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    overflowY: "hidden",
    overflowX: "hidden",
    display: "flex",
  })
);

const NavBarWrapper = styled("div")(({ theme }) => ({
  minWidth: 120,
  paddingTop: theme.spacing(3),
  overflowY: "hidden",
}));

const BodyWrapper = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  width: "100vw",
  display: "inline",
  overflowY: "scroll !important" as any, //FIXME:
  overflowX: "hidden !important" as any, //FIXME:
  zIndex: 2
}));
