import { DevTool } from "@hookform/devtools";
import { Box, Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ColorPicker } from "../PrimaryColorForm/ColorPicker";
import useAppMessages from 'hooks/useAppMessages/hook';

export type StepperColorFormFields = {
  color1: string;
  color2: string;
  color3: string;
  internalColor: string;
  textColor: string;
};

export type StepperColorFormProps = {
  form: UseFormReturn<StepperColorFormFields>;
  readOnly?: boolean;
};

const StepperColorForm = ({
  form,
  readOnly = false,
}: StepperColorFormProps) => {
  const { control } = form;
  const { appMessage } = useAppMessages("THEMES");

  return (
    <FormNonTableBodyWrapper>
      <DevTool control={control} placement="top-left" />

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={3}>
          <ColorPicker
            label={appMessage["COLOR_1"]}
            disabled={readOnly}
            controllerProps={{
              name: "color1",
              control,
              rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <ColorPicker
            label={appMessage["COLOR_2"]}
            disabled={readOnly}
            controllerProps={{
              name: "color2",
              control,
              rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <ColorPicker
            label={appMessage["COLOR_3"]}
            disabled={readOnly}
            controllerProps={{
              name: "color3",
              control,
              rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
            }}
          />
        </Grid>
      </Grid>

      <Box marginTop={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={3}>
            <ColorPicker
              label={appMessage["INTERNAL_COLOR"]}
              disabled={readOnly}
              controllerProps={{
                name: "internalColor",
                control,
                rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <ColorPicker
              label={appMessage["TEXT_COLOR"]}
              disabled={readOnly}
              controllerProps={{
                name: "textColor",
                control,
                rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormNonTableBodyWrapper>
  );
};

export default StepperColorForm;
