import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { Collapse } from "./components/Collapse";
import { UpdatePasswordForm } from "./components/PasswordForm";
import { Table } from "./components/Table";
import { useViewAllPage } from './useViewAllPage';

const ViewAllPage = () => {

  const {
    errorSnackbar,
    appMessage,
    menuItems,
    menuItemsRow,
    rows,
    total,
    pagination,
    sheet,
    userId,
    open,
    handleClose,
  } = useViewAllPage();

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <Dialog onClose={handleClose} open={open}>
        <UpdatePasswordForm user={userId} />
      </Dialog>

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appMessage["USER_TITLE"]}
          toBack="../"
        />

        <Box marginY={2}>
          <Collapse 
            isCreatedVisible={true}
            menuItems={menuItems}
            sheet={sheet}
          />
        </Box>

        <Table 
          menuItems={menuItems}
          menuItemsRow={menuItemsRow}
          rows={rows}
          total={total}
          pagination={pagination}
        />
      </Box>
    </Layout>
  );
};

export default ViewAllPage;

