import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { Pagination } from "utils/Pagination";
import { push } from "utils/push";
import {
  TableStatus,
  TableType,
} from "../../CreatePage/Form/BasicDataForm/types";
import { dataElementsColumnsLabels } from "./visibleColumnsSlice";

export const TABLE_FILTERS_KEYS = [
  "name",
  "description",
  "status",
  "tableType",
] as const;

export type TableFiltersKeys = typeof TABLE_FILTERS_KEYS[number];

export const tableFiltersLabels = dataElementsColumnsLabels;

export type TableFilters = Partial<{
  name: string;
  status: TableStatus[]; // TODO: ENUM
  tableType: TableType[]; // TODO: ENUM
  description: string;
}>;

type FiltersState = {
  filtersVisibility: Record<TableFiltersKeys, boolean>;
  filters: TableFilters;
  pagination: Pagination;
};

const initialState: FiltersState = {
  filtersVisibility: TABLE_FILTERS_KEYS.reduce(
    (prev, curr) => push(prev, curr, false),
    {} as Record<TableFiltersKeys, boolean>
  ),
  filters: {},
  pagination: { page: 0, pageSize: 5 },
};

const viewTableFiltersAndPaginationSlice = createSlice({
  name: "tablesFiltersAndPaginationSlice",
  initialState,
  reducers: {
    setTableFilterVisibility: (
      state,
      action: PayloadAction<{
        key: TableFiltersKeys;
        isVisible: boolean;
      }>
    ) => {
      const { key, isVisible } = action.payload;
      state.filtersVisibility[key] = isVisible;
      state.filters[key] = undefined;
    },
    setTableFilters: (state, action: PayloadAction<TableFilters>) => {
      state.pagination = initialState.pagination;
      state.filters = action.payload;
    },
    prevPageTable: (state) => {
      state.pagination.page--;
    },
    nextPageTable: (state) => {
      state.pagination.page++;
    },
    resetTablePage: (state) => {
      state.pagination.page = 0;
    },
  },
});

export const {
  setTableFilters,
  setTableFilterVisibility,
  resetTablePage,
  prevPageTable,
  nextPageTable,
} = viewTableFiltersAndPaginationSlice.actions;

export const selectIsTableFilterVisible = (state: RootState) =>
  state.tables.filtersAndPagination.filtersVisibility;

export const selectTableFilters = (state: RootState) =>
  state.tables.filtersAndPagination.filters;

export const selectTableVisibleFiltersKeys = (state: RootState) =>
  TABLE_FILTERS_KEYS.filter(
    (key) => state.tables.filtersAndPagination.filtersVisibility[key]
  );

export const selectTableHiddenFiltersKeys = (state: RootState) =>
  TABLE_FILTERS_KEYS.filter(
    (key) => !state.tables.filtersAndPagination.filtersVisibility[key]
  );

export const selectTablePagination = (state: RootState) =>
  state.tables.filtersAndPagination.pagination;

export default viewTableFiltersAndPaginationSlice.reducer;
