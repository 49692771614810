import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  BusinessPartnerTypeExistsDocument,
  BusinessPartnerTypeExistsQuery,
  BusinessPartnerTypeExistsQueryVariables,
} from "__generated__/graphql/types";
import useAppMessages from 'hooks/useAppMessages/hook';

export type BasicDataFormFields = {
  name: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;

  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "BUSINESS_PARTNER_TYPE",
  ]);

  const { appMessage } = useAppMessages("BUSINESS_PARTNER_TYPES");
  const {appMessage:appMsg} = useAppMessages("MESSAGES");
  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["BUSINESS_PARTNER_TYPE"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: appMessage["ENTER_NAME"]  ?? "Enter the business partner type", ///FIXPRD QUITAR
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const {
                      businessPartnerTypeExists,
                    } = await businessPartnerTypeExistsFn({
                      id: value,
                    });
                    return businessPartnerTypeExists
                      ? appMessage["NAME_EXIST"]  ?? "Enter the business partner type"///FIXPRD QUITAR
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: appMsg["ALLOWED_CHARACTERS"] ?? "The allowed characters are A-Z, 0-9 y _.",///FIXPRD QUITAR
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const businessPartnerTypeExistsFn = pDebounce(
  (args: BusinessPartnerTypeExistsQueryVariables) =>
    fetcher<
      BusinessPartnerTypeExistsQuery,
      BusinessPartnerTypeExistsQueryVariables
    >(BusinessPartnerTypeExistsDocument, args)(),
  DEBOUNCE_TIME_ID
);
