import {
  ArrowDownward as ArrowDownwardIcon,
  Clear as ClearIcon
} from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { CheckboxController } from "components/inputs/CheckboxController";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import useAppMessages from 'hooks/useAppMessages/hook';
import { Controller } from "react-hook-form";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { RowProps } from "./types";

const Row = ({
  index,
  remove,
  item,
  readOnly,
  onEnterDescriptions,
  form,
}: RowProps) => {
  const { control, trigger, watch, getValues } = form;

  const descriptions = watch(
    `address.${index}.descriptions` as "address.0.descriptions"
  );

  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");
  const {appMessage:appMsg} = useAppMessages("MESSAGES");
  const onRemoveRow = () => {
    remove();
    trigger();
  };
  if (appMsg == null) return <BeatLoadingPage/>;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`address.${index}._virtualField` as "address.0._virtualField"}
          rules={{
            validate: () => {
              if (readOnly) return true;
              if (descriptions.city === "" || descriptions.country === "" || descriptions.number === "" || descriptions.postalCode === "" || descriptions.state === "" || descriptions.street === "")
                return appMessage["ADDRESS_INCOMPLETE"] ?? "Address incomplete";// FIXPRD: QUITAR 

              ////only one fiscal address selected
              const fa = getValues("address").map((e) => e.isFiscalAddress.toString());
              const fiscalAddres = form.getValues(
                `address.${index}.isFiscalAddress` as "address.0.isFiscalAddress"
              );
              if (fiscalAddres === true && isRepeatedValue(fiscalAddres.toString(), fa))
                return appMessage["ONE_FISCAL_ADDRESS"]?? "Select only one fiscal address"; // FIXPRD: QUITAR 
                
              //// one fiscal address selected
              if(!fa.includes('true'))
              return appMessage["AT_LEAST_ONE_FA"] ?? "Select at least one fiscal address";

              return true;
            },
          }}
          render={({ fieldState: { invalid, error } }) => (
            <FormTableTextField
              value={descriptions?.street ? descriptions?.street + ", " + descriptions?.state + ", " + descriptions?.country + "." : ""}
              helperText={error?.message}
              error={invalid}
              inputProps={{ disabled: readOnly }}
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <CheckboxController
          controllerProps={{
            name: `address.${index}.isFiscalAddress` as "address.0.isFiscalAddress",
            control,
            defaultValue: item.isFiscalAddress,
          }}
          readOnly={readOnly}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip
          title={appMessage["ADD_ADDRESS"]}
        >
          <StyledIconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={() => onEnterDescriptions(index)}
          >
            <ArrowDownwardIcon />
          </StyledIconButton>
        </Tooltip>

        {!readOnly && (
          <Tooltip
            title={appMsg["DELETE_ROW"]} 
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});

// FIXME: commons
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
