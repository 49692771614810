import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useGetAppActionsQuery } from "__generated__/graphql/types";

const useAppActions = (id: string) => {
  const { language } = useSystemLanguage();

  const { data: { apps } = {} } = useGetAppActionsQuery({ language, id });

  const actions = Object.fromEntries(
    apps
      ?.flatMap((e) => e.actions)
      .map((e) => [e.id, e.translation.description]) ?? []
  );

  return { actions };
};

export default useAppActions;
