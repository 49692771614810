import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  GetBackends_ViewAllPageQuery, useGetBusinessPartnersBackendSavedFormPageQuery
} from "__generated__/graphql/types";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { useGetBackendsNamesForBusinessPartnersFormPageQuery } from '../../../../../../../__generated__/graphql/types';
import BeatLoadingPage from '../../../../../../../components/layout/BeatLoadingPage/template';
import { RowProps } from "./types";

const Row = ({ index, remove, mode, form }: RowProps) => {
  
  const { control, trigger, getValues } = form;

  const onRemoveRow = () => {
    remove();
    trigger();
  };

  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");
  const {appMessage:appMsg} = useAppMessages("MESSAGES");

  const { dataElementsDescriptions } = useDataElementDescription([
    "BACKEND",
    "BUSINESS_PARTNER_EXT_ID",
    "URL",
  ]);

  const { data: { backends } = {} } = useGetBackendsNamesForBusinessPartnersFormPageQuery({});
  const { 
    data: { businessPartners } = {}, 
    refetch,
    status
  } = useGetBusinessPartnersBackendSavedFormPageQuery({
    filters: {
      backend: {
        backendId: getValues(`backends.${index}.idBackend` as "backends.0.idBackend"),
        externalId: getValues(`backends.${index}.idBackendExt` as "backends.0.idBackendExt"),
      }
    }
  });

  if (backends == null) return <BeatLoadingPage/>
  if (dataElementsDescriptions === undefined) return <BeatLoadingPage/>

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <MatchcodeInputController
          label={dataElementsDescriptions["BACKEND"]}
          controllerProps={{
            name: `backends.${index}.idBackend` as "backends.0.idBackend",
            control,
            rules: {
              required: appMessage["FILL_BACKEND"] ?? "Select a backend",// FIXME: QUITAR 
              validate: (back) => {
                if (mode === 'readOnly') return true;
                const id = getValues("backends");
                if (
                  isRepeatedValue(
                    back.toUpperCase(),
                    id.map(({ idBackend }) => idBackend.toUpperCase())
                  )
                )
                  return appMessage["BACKEND_SELECTED"] ?? "The backend has already been selected"; // FIXME: QUITAR 

                return true;
              },
            },
          }}
          readOnly={mode === 'readOnly' ? true : false}
          column={[
            {
              key: "name",
              text: dataElementsDescriptions["BACKEND"],
            },
            {
              key: "description",
              text: dataElementsDescriptions["URL"],
            },
          ]}
          displayColumn="name"
          options={backends.map(mapDataElementToOption)}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <TextFieldController
          inputProps={{ disabled: mode === 'readOnly' ? true : false }}
          label={dataElementsDescriptions["BUSINESS_PARTNER_EXT_ID"]}
          controllerProps={{
            name: `backends.${index}.idBackendExt` as "backends.0.idBackendExt",
            control,
            rules: {
              validate: (externalId) => {
                if(mode === 'create'){
                  // FIXME: Validar rendimiento se hace una consulta cada que el usuario escribe un valor
                  if(externalId.length > 2) {
                    refetch();
                    if(businessPartners != null && businessPartners?.length > 0 && status === 'success') {
                      return appMessage["BACKEND_EXTERNAL_ID_EXISTS"] ?? "A business partner with the same backend and externalId already exists" //FIXME: Quitar
                    }
                  }
                }
              },
              required: appMessage["BACKEND_EXTERNAL_ID_EXISTS"] ?? "External Id is required",
            },
          }}
        />
      </TableCell>

      <TableCell align="right">
        {mode === 'create' && (
          <Tooltip
            title={appMsg["DELETE_ROW"]}
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});

const mapDataElementToOption = (
  dto: NonNullable<GetBackends_ViewAllPageQuery["backends"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.url,
});
