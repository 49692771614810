import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import { MatchcodeDisplayInputInTableRow } from "components/inputs/matchcode/MatchcodeDisplayInputInTableRow";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { AppsDtoForCreateTutorial } from "../../types";

export type PositionMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  appsOptions: AppsDtoForCreateTutorial;
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
};

export const DataElementMatchcode = ({
  value,
  onChange,
  appsOptions,
  invalid,
  errorMessage,
  readOnly,
}: PositionMatchcodeProps) => {

  const options = appsOptions.map((e) => ({
    id: e.id,
    app: e.id,
    description: e.translation.description
  }));

  const {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    value,
    onChange,
    searchKeys: ["app", "description"],
  });

  const { dataElementsDescriptions } = useDataElementDescription([
    "APP",
    "DESCRIPTION",
  ]);

  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <>
      <KeyInputWrapper>
        <MatchcodeDisplayInputInTableRow
          text={text?.id ?? ""}
          isDeleteButtonVisible={isDeleteButtonVisible}
          onClearValue={onClearValue}
          onOpenDialog={dialog.open}
          error={invalid}
          helperText={errorMessage}
          readOnly={readOnly}
        />
      </KeyInputWrapper>

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "app", text: dataElementsDescriptions["APP"]},
              { key: "description", text: dataElementsDescriptions["DESCRIPTION"] }
            ]}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default DataElementMatchcode;
