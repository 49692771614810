import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { Form } from "apps/LocationTypePage/CreatePage/Form";
import { FormFields } from "apps/LocationTypePage/CreatePage/Form/types";
import { mapFormToDto } from "apps/LocationTypePage/CreatePage/utils";
import { mapDtoToForm } from "apps/LocationTypePage/ViewPage/utils";
import { useNavigate } from "react-router";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetLocationType_ViewPageQuery,
  useUpdateLocationTypeMutation,
} from "__generated__/graphql/types";
import { LocationTypeRoutes, LocationTypeRoutesState } from "../routes";

type Route = LocationTypeRoutes.EDIT;
type LocationTypeState = LocationTypeRoutesState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { locationTypeId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationTypeState>() ?? {};

  const {
    data: { locationType } = {},
    isFetchedAfterMount,
  } = useGetLocationType_ViewPageQuery({
    id: locationTypeId,
  });

  const initialValue =
    locationType != null && isFetchedAfterMount
      ? mapDtoToForm(locationType)
      : undefined;

  const { mutateAsync: replace } = useUpdateLocationTypeMutation();

  const onSave = (form: FormFields) =>
    replace({
      id: locationTypeId,
      locationType: mapFormToDto(form),
    });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header title="Editar moneda" toBack={TO_BACK_ROUTER} />

      <Form
        mode="edit"
        onSave={onSave}
        onCancel={onCancel}
        errorMessage="Error al editar la moneda. Vuelva a intentar."
        initialValue={initialValue}
        initialStep={activeStep}
      />
    </Layout>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
