import { DevTool } from "@hookform/devtools";
import React from "react";
import { OptionsDescriptionsForm } from "./OptionsDescriptionsForm";
import { OptionsNamesForm } from "./OptionsNamesForm";
import { OptionsFormProps } from "./types";

const OptionsForm = ({
  areDescriptionsVisible,
  onEnterDescriptions,
  onExitDescriptions,
  form,
  externalIndex,
  mode,
  onEdit,
}: OptionsFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <OptionsDescriptionsForm
          onFinish={onExitDescriptions}
          formMethods={form}
          externalIndex={externalIndex}
          mode={mode}
          onEdit={onEdit}
        />
      ) : (
        <OptionsNamesForm
          form={form}
          onEnterDescriptions={onEnterDescriptions}
          mode={mode}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default OptionsForm;
