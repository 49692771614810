import { DevTool } from "@hookform/devtools";
import React from "react";
import { RegionsDescriptionsForm } from "./RegionsDescriptionsForm";
import { RegionsNamesForm } from "./RegionsNamesForm";
import { RegionsFormProps } from "./types";

const RegionsForm = ({
  areDescriptionsVisible,
  form,
  externalIndex,
  readOnly,
  onEdit,
  onEnterDescriptions,
  onExitDescriptions,
}: RegionsFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <RegionsDescriptionsForm
          onFinish={onExitDescriptions}
          formMethods={form}
          externalIndex={externalIndex}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      ) : (
        <RegionsNamesForm
          form={form}
          onEnterDescriptions={onEnterDescriptions}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default RegionsForm;
