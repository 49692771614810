import { combineReducers } from "@reduxjs/toolkit";
import viewDataElementsColumnsSlice from "./ViewAllPage/reducers/visibleColumnsSlice";
import viewDataElementsFiltersSlice from "./ViewAllPage/reducers/filtersAndPaginationSlice";
import viewDataElementsRowsSelectedSlice from "./ViewAllPage/reducers/checkedRowsSlice";

const dataElementsReducer = combineReducers({
  columns: viewDataElementsColumnsSlice,
  filtersAndPagination: viewDataElementsFiltersSlice,
  rowsChecked: viewDataElementsRowsSelectedSlice,
});

export default dataElementsReducer;
