import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Header } from "./Header";
import { Row } from "./Row";
import { IdsFormProps } from "./types";
import { useGetIdsInBusinessObjectForCreateMigrationPackageQuery } from "__generated__/graphql/types";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

const IdsForm = ({
  readOnly = false,
  onEdit,
  onFinish,
  formMethods,
  externalIndex,
  businessObjectId,
}: IdsFormProps) => {

  businessObjectId = businessObjectId || '';

  const {
    data: { idsInBusinessObject } = {},
  } = useGetIdsInBusinessObjectForCreateMigrationPackageQuery({
    businessObjectId
  });

  if (idsInBusinessObject === undefined) return <BeatLoadingPage />;

  return (
    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header
          onEdit={() => onEdit?.()}
          readOnly={readOnly}
          onFinish={onFinish}
        />

        <StyledTableBody>
          {idsInBusinessObject.map((item, index) => (
            <Row
              key={item}
              index={index}
              item={item}
              readOnly={readOnly}
              formMethods={formMethods}
              externalIndex={externalIndex}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default IdsForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
