import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const usePurchaseOrderItemsLabels = () => {

    const purchaseOrderItem = "PURCHASE_ORDER_ITEM";
    const productId = "PRODUCT_ID";
    const productDescription = "PRODUCT_DESCRIPTION";
    const hasInvoice = "HAS_INVOICE";
    const location = "LOCATION";
    const locationDesc = "LOCATION_DESC";
    const storage = "STORAGE";
    const poQuantity = "PO_QUANTITY";
    const unit  = "UNIT";
    const netUnitPrice  = "NET_UNIT_PRICE";
    const netTotalPrice  = "NET_TOTAL_PRICE";
    const grQuantity  = "GR_QUANTITY";
    const grAmount = "GR_AMOUNT";
    const picInvoicedQuantity = "PIC_INVOICED_QUANTITY";
    const picInvoicedAmount = "PIC_INVOICED_AMOUNT";
    const invoiceApprovedQuantity = "INVOICE_APPROVED_QUANTITY";
    const invoiceApprovedAmount = "INVOICE_APPROVED_AMOUNT";
    const completeInvoice  = "COMPLETE_INVOICE";
    const purchaseOrder = "PURCHASE_ORDER";
    const accountingEntity = "ACCOUNTING_ENTITY";
    const accountingEntityDesc = "ACCOUNTING_ENTITY_DESC";
    const vendor = "VENDOR";
    const purchaseEntity = "PURCHASE_ENTITY";
    const creationDate = "CREATION_DATE";
    const currency = "CURRENCY";
    const vendorId = "VENDOR_ID";
    const total = "TOTAL";
    
    const dataElementsIds = [
        purchaseOrderItem, productId, productDescription, hasInvoice, location, locationDesc, storage, poQuantity, unit, netUnitPrice, netTotalPrice,
        grQuantity, grAmount, picInvoicedQuantity, picInvoicedAmount, invoiceApprovedQuantity, invoiceApprovedAmount, completeInvoice,
        accountingEntity, accountingEntityDesc, purchaseOrder, vendor, purchaseEntity, creationDate, currency, vendorId, total
    ];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels : NonNullable<Record<ColumnsKeys, string>> = {
        purchaseOrderItem: dataElementsDescriptions === undefined ? purchaseOrderItem : (dataElementsDescriptions[purchaseOrderItem] ?? purchaseOrderItem),  
        productId: dataElementsDescriptions === undefined ? productId : (dataElementsDescriptions[productId] ?? productId),
        productDescription: dataElementsDescriptions === undefined ? productDescription : (dataElementsDescriptions[productDescription] ?? productDescription),
        hasInvoice: dataElementsDescriptions === undefined ? hasInvoice : (dataElementsDescriptions[hasInvoice] ?? hasInvoice),
        locationDesc: dataElementsDescriptions === undefined ? locationDesc : (dataElementsDescriptions[locationDesc] ?? locationDesc),
        unit: dataElementsDescriptions === undefined ? unit : (dataElementsDescriptions[unit] ?? unit),  
        poQuantity: dataElementsDescriptions === undefined ? poQuantity : (dataElementsDescriptions[poQuantity] ?? poQuantity),  
        netUnitPrice: dataElementsDescriptions === undefined ? netUnitPrice : (dataElementsDescriptions[netUnitPrice] ?? netUnitPrice),  
        netTotalPrice: dataElementsDescriptions === undefined ? netTotalPrice : (dataElementsDescriptions[netTotalPrice] ?? netTotalPrice),  
        grQuantity: dataElementsDescriptions === undefined ? grQuantity : (dataElementsDescriptions[grQuantity] ?? grQuantity),  
        grAmount: dataElementsDescriptions === undefined ? grAmount : (dataElementsDescriptions[grAmount] ?? grAmount),
        picInvoicedQuantity: dataElementsDescriptions === undefined ? picInvoicedQuantity : (dataElementsDescriptions[picInvoicedQuantity] ?? picInvoicedQuantity),
        picInvoicedAmount: dataElementsDescriptions === undefined ? picInvoicedAmount : (dataElementsDescriptions[picInvoicedAmount] ?? picInvoicedAmount),
        invoiceApprovedQuantity: dataElementsDescriptions === undefined ? invoiceApprovedQuantity : (dataElementsDescriptions[invoiceApprovedQuantity] ?? invoiceApprovedQuantity),
        invoiceApprovedAmount: dataElementsDescriptions === undefined ? invoiceApprovedAmount : (dataElementsDescriptions[invoiceApprovedAmount] ?? invoiceApprovedAmount),
        completeInvoice: dataElementsDescriptions === undefined ? completeInvoice : (dataElementsDescriptions[completeInvoice] ?? completeInvoice),  
        purchaseOrder: dataElementsDescriptions === undefined ? purchaseOrder : (dataElementsDescriptions[purchaseOrder] ?? purchaseOrder),  
        vendor: dataElementsDescriptions === undefined ? vendor : (dataElementsDescriptions[vendor] ?? vendor),  
        accountingEntityDesc: dataElementsDescriptions === undefined ? accountingEntityDesc : (dataElementsDescriptions[accountingEntityDesc] ?? accountingEntityDesc),
        vendorId: dataElementsDescriptions === undefined ? vendorId : (dataElementsDescriptions[vendorId] ?? vendorId),
        total: dataElementsDescriptions === undefined ? total : (dataElementsDescriptions[total] ?? total),
        currency: dataElementsDescriptions === undefined ? currency : (dataElementsDescriptions[currency] ?? currency),
        purchaseEntity: dataElementsDescriptions === undefined ? purchaseEntity : (dataElementsDescriptions[purchaseEntity] ?? purchaseEntity),
        creationDate: dataElementsDescriptions === undefined ? creationDate : (dataElementsDescriptions[creationDate] ?? creationDate),  
    };

    return { columnsLabels }
}