import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { ExportMigrationPackageRoutes } from "../routes";
import { Form } from "../CreatePage/Form";
import { useGetOwnMigrationPackageForPageViewQuery } from "__generated__/graphql/types";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {

  const navigate = useNavigate();
  const { packageMigrationId } = useRouteParams<ExportMigrationPackageRoutes.VIEW>();

  const {
    data: { ownMigrationPackage } = {},
    isFetchedAfterMount,
  } = useGetOwnMigrationPackageForPageViewQuery({
    id: packageMigrationId,
  });

  const initialValues =
  ownMigrationPackage != null && isFetchedAfterMount
      ? mapDtoToForm(ownMigrationPackage)
      : undefined;

  const onEdit = (activeStep: number) => {
    navigate(
      `../../${generatePath(ExportMigrationPackageRoutes.EDIT, {
        packageMigrationId,
      })}`,
      { state: { activeStep } }
    );
  };

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Visualizar los datos de migración entre sistemas" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValues} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;
const TO_BACK_ROUTER = "../../";
