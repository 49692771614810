import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetCountryViewPageQuery,
  useUpdateCountryMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { CountriesRoutes, CountriesRoutesState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

const EditPage = () => {
  const { countryId } = useRouteParams<CountriesRoutes.VIEW>();

  const { activeStep } =
    useLocationState<CountriesRoutesState[CountriesRoutes.EDIT]>() ?? {};

  const navigate = useNavigate();

  const { mutateAsync: editTable } = useUpdateCountryMutation();

  const {
    data: { country } = {},
    isFetchedAfterMount,
  } = useGetCountryViewPageQuery({
    id: countryId,
  });

  const initialValue =
    country != null && isFetchedAfterMount ? mapDtoToForm(country) : undefined;

  const onSave = async (form: FormFields) => {
    await editTable({ id: countryId, countryInput: mapFormToDto(form) });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Crear país" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValue}
          initialStep={activeStep}
          errorMessage="Error al editar país. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
