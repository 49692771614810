import CreateInvoicePage from "apps/InvoicesPage/CreatePage/template";
import { EditInvoicePage } from "apps/InvoicesPage/EditPage";
import { ViewAllInvoicesPage } from "apps/InvoicesPage/ViewAllPage";
import { ViewInvoicePage } from "apps/InvoicesPage/ViewPage";
import { ViewPurchaseOrdersItemsPage } from "apps/PurchaseOrdersItemsPage/ViewPage";
import { PurchaseOrdersRoutes } from "apps/PurchaseOrdersPage/routes";
import { Route, Routes } from "react-router-dom";
import { PurchaseOrdersDirectRoutes } from "./routes";
import { PurchaseOrderSelectPage } from "./SelectForm";

const PurchaseOrdersDirectPage = () => {
  return (
    <Routes>
      <Route
        path={PurchaseOrdersDirectRoutes.PATH}
        element={<PurchaseOrderSelectPage />}
      />
      <Route 
        path={PurchaseOrdersRoutes.PATH_ITEMS} 
        element={<ViewPurchaseOrdersItemsPage />} 
      />
      <Route 
        path={PurchaseOrdersDirectRoutes.PATH_INVOICES} 
        element={<ViewAllInvoicesPage />} 
      />
      <Route 
        path={PurchaseOrdersDirectRoutes.PATH_INVOICES_VIEW} 
        element={<ViewInvoicePage />} 
      />
      <Route 
        path={PurchaseOrdersDirectRoutes.PATH_INVOICES_EDIT} 
        element={<EditInvoicePage />} 
      />
      <Route 
        path={PurchaseOrdersDirectRoutes.PATH_INVOICES_CREATE} 
        element={<CreateInvoicePage />} 
      />
    </Routes>
  );
};

export default PurchaseOrdersDirectPage;
