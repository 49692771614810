import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import {
  Box,
  Button, Grid, styled
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from 'components/layout/LoadingLayoutPage';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSnackbar } from "hooks/useSnackbarV2";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  GetPurchaseOrdersItemsForInvoicesReportsQuery, useGetPurchaseOrdersForInvoicesReportsQuery,
  useGetPurchaseOrdersItemsForInvoicesReportsQuery
} from "__generated__/graphql/types";
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { PurchaseOrdersItemsDirectRoutes } from '../routes';
import { GroupsMatchcode } from "./components/GroupsMatchcode";
import { notNullUndefined } from '../../../utils/notNullUndefined';

type purchaseOrdersItemsDirect = {
  purchaseOrder: string | undefined,
  itemSelect: { label: string; } | undefined,
  items: { label: string; }[],
  errorPO: string | undefined,
  errorItem: string | undefined 
}

const emptyPurchaseOrderItemDirect = {
  purchaseOrder: undefined,
  itemSelect: undefined,
  items: [],
  errorPO: undefined,
  errorItem: undefined
}

const PurchaseOrderItemsSelectFormPage = () => {

  const [direct, setDirect] = React.useState<purchaseOrdersItemsDirect>(emptyPurchaseOrderItemDirect);

  const navigate = useNavigate();
  const errorSnackbar = useSnackbar();
  const { appMessage } = useAppMessages("MESSAGES");
  const { appMessage: appPurchaseOrdersItems } = useAppMessages("PURCHASE_ORDERS_ITEMS");
  const { dataElementsDescriptions } = useDataElementDescription(["PURCHASE_ORDER_ITEM_ID"]);

  const { data: { purchaseOrders } = {} } = useGetPurchaseOrdersForInvoicesReportsQuery({});
  var { refetch: getItems} = useGetPurchaseOrdersItemsForInvoicesReportsQuery({ 
    purchaseOrder: direct.purchaseOrder ?? "0"
  }, {
    enabled: notNullUndefined(direct.purchaseOrder)
  });
  
  const changePO = (value?:string) => {

    if(value) {
      setDirect(prevState => ({
        ...prevState,
        purchaseOrder: value,
        errorPo: undefined,
        errorItem: undefined
      }));
      getItemsOptions();
    } else {
      setDirect(prevState => ({
        ...prevState,
        purchaseOrder: undefined,
        itemSelect: undefined,
        items: [],
      }));
    }
  }

  const changeItem = (value?:string) => {

    if(value) {
      setDirect(prevState => ({
        ...prevState,
        itemSelect: { label: value },
        errorItem: undefined
      }));
    } else {
      setDirect(prevState => ({
        ...prevState,
        itemSelect: undefined,
      }));
    }
  }

  const getItemsOptions = () => {
    getItems().then(e => {
      if(e.data) {
        setDirect(prevState => ({
          ...prevState,
          items: e.data.purchaseOrderItems.map(mapDataElementToOption),
        }));
      }
    })
  }

  const navigateInvoices = () => {

    if(!direct.purchaseOrder) {
      setDirect(prevState => ({
        ...prevState,
        errorPO: appMessage["CHOOSE_ONE"],
      }));
      return;

    } else if(!direct.itemSelect) {
      setDirect(prevState => ({
        ...prevState,
        errorItem: appMessage["CHOOSE_ONE"],
      }));
      return;
    }

    navigate(PurchaseOrdersItemsDirectRoutes.PATH_INVOICES, {
      state: {
        toBack: TO_BACK,
        purchaseOrderId: direct.purchaseOrder,
        purchaseOrderItemsIds: [direct.itemSelect.label]
      }
    });
  }
  
  if (purchaseOrders == null) return <LoadingLayoutPage />;
  if (dataElementsDescriptions == null) return <LoadingLayoutPage />;

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appPurchaseOrdersItems["PURCHASE_ORDERS_ITEMS_TITLE"]}
          toBack={TO_BACK_ROUTER}
        />

        {/* FIXME: Agregar a un formulario que controle los eventos y validaciones */}
        {/* Esto genera un error en consola */}
        <BodyWrapper readOnly={false} onEdit={() => {}}>
          <Grid container direction="column" spacing={2} xs={12} alignItems="center" justifyContent="center">
            <GridGroupFields item xs={10}>
              <GroupsMatchcode
                purchaseOrders={purchaseOrders}
                value={direct.purchaseOrder}
                onChange={(value) => { changePO(value) }}
                text={direct.purchaseOrder}
                invalid={false}
                errorMessage={direct.errorPO}
                readOnly={false}

              />
            </GridGroupFields>
            <GridGroupFields item xs={10}>
              <Autocomplete
                id="combo-box-items"
                value={direct.itemSelect}
                options={direct.items}
                defaultValue={direct.itemSelect}
                disabled={!notNullUndefined(direct.purchaseOrder)}
                renderInput={(params) => 
                  <TextField 
                    {...params} 
                    label={dataElementsDescriptions["PURCHASE_ORDER_ITEM_ID"] ?? "Purchase order item"}
                    error={!notNullUndefined(direct.itemSelect) && notNullUndefined(direct.purchaseOrder)}
                    helperText={direct.errorItem}
                  />
                }
                onChange={(e, value) => { changeItem(value?.label) }}
              />
            </GridGroupFields>
            <GridGroupFields item>
              <ButtonStyled 
                aria-label="remove" 
                size="medium" 
                endIcon={ <AssignmentOutlinedIcon /> } 
                onClick={() => navigateInvoices()}
              >                
                {appMessage["FIND"] ?? "FIND"}
              </ButtonStyled>
            </GridGroupFields>
          </Grid>
        </BodyWrapper>
      </Box>
    </Layout>
  );
};

export default PurchaseOrderItemsSelectFormPage;

const TO_BACK = "../";
const TO_BACK_ROUTER = "../../";

const mapDataElementToOption = (
  dto: NonNullable<GetPurchaseOrdersItemsForInvoicesReportsQuery["purchaseOrderItems"]>[number]
) => ({
  label: dto.purchaseOrderItem,
});

const ButtonStyled = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const GridGroupFields = styled(Grid)(({theme}) => ({
  minWidth: '300px', // FIXME:
  maxWidth: '300px', // FIXME:
  textAlign:'center',
  alignItems: 'center'
}));

const BodyWrapper = styled(FormNonTableBodyWrapper)(({theme}) => ({
  marginTop: theme.spacing(1)
}));
