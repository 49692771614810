import { Box } from "@mui/material";
import { BeatLoader } from "react-spinners";

const BeatLoadingPage = () => {
  return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <BeatLoader margin={15} size={20} color="white" />
      </Box>
  );
};

export default BeatLoadingPage;
