import { DevTool } from "@hookform/devtools";
import { MenuItemsProps } from "components/datadisplay/GenericTable/template";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { TableCollapsePIC } from "components/datadisplay/TableCollapsePIC";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useDialog } from "hooks/useDialog";
import { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ExcelSheetData } from "types/ExcelDataSet";
import {
  Filters,
  FiltersKeys,
  selectFilters,
  selectHiddenFiltersKeys,
  selectVisibleFiltersKeys,
  setFilters
} from "../../reducers/filtersAndPaginationSlice";
import { ColumnsKeys } from "../../reducers/visibleColumnsSlice";
import { usePurchaseOrderItemsLabels } from "../../usePurchaseOrderItemsReportsLabels";
import { ColumnsVisibilityDialog } from "./ColumnsVisibilityDialog";
import { FiltersVisibilityDialog } from "./FiltersVisibilityDialog";

type CollapseProps = {
  menuItems: MenuItemsProps[]
  sheet?: ExcelSheetData<ColumnsKeys> | undefined;
  isCreatedVisible: boolean;
};

const Collapse = ({
  sheet,
  menuItems,
  isCreatedVisible,
}: CollapseProps) => {

  const dispatch = useAppDispatch();

  const filtersDialog = useDialog();
  const columnsDialog = useDialog();

  const filters = useAppSelector(selectFilters);
  const visibleFiltersKeys = useAppSelector(selectVisibleFiltersKeys);
  const hiddenFiltersKeys = useAppSelector(selectHiddenFiltersKeys);

  const { columnsLabels } = usePurchaseOrderItemsLabels();
  const { control, handleSubmit, unregister } = useForm<Filters>({
    defaultValues: filters,
  });

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: columnsLabels,
    control,
  });

  const onSearch = handleSubmit((form) => {
    dispatch(setFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <ColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapsePIC<ColumnsKeys> 
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={() => {}}
        onExpand={() => {}}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
        menuItems={menuItems}
        sheet={sheet}
        isCreatedVisible={isCreatedVisible}
      />
    </>
  );
};

export default Collapse;

const filtersComponents: Record<
  FiltersKeys,
  ComponentType<FilterComponentProps<Filters, FiltersKeys>>
> = {
  purchaseOrderItem: TextFilter,
  productId: TextFilter,
  productDescription: TextFilter,
  locationDesc: TextFilter,
  poQuantity: TextFilter,
  unit: TextFilter,
  netUnitPrice: TextFilter,
  netTotalPrice: TextFilter,
};
