import { Box } from "@mui/material";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { Collapse } from "./components/Collapse";
import { Table } from "./components/Table";

const ViewAllPage = () => {
  return (
    <Layout>
      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title="Entradas contables" // FIXME: traduccion
          toBack="../"
        />

        <Box marginY={2}>
          <Collapse />
        </Box>

        <Table />
      </Box>
    </Layout>
  );
};

export default ViewAllPage;
