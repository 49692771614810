import { DataElementType } from "apps/DataElementsPage/CreatePage/Form/BasicDataForm/template";
import { useMemo } from "react";
import { GetDataElementsForCreateTableQuery } from "__generated__/graphql/types";
import { NUMERIC_DATA_ELEMENT_TYPE } from "../utils";

export const useCharacteristicsDataElements = (
  dataElements?: GetDataElementsForCreateTableQuery["dataElements"]
) => {
  const characteristicsDataElements = useMemo(() => {
    if (dataElements === undefined) return undefined;
    return new Map(
      dataElements
        ?.filter(
          (dataElement) =>
            !NUMERIC_DATA_ELEMENT_TYPE[dataElement.dataType as DataElementType] // FIXME:
        )
        .map((dataElement) => [dataElement.id, dataElement])
    );
  }, [dataElements]);
  return { characteristicsDataElements };
};
