import { DevTool } from "@hookform/devtools";
import { InstructionsDataForm } from "./InstructionsDataForm";
import { InstructionsDescriptionsForm } from "./InstructionsDescriptionsForm";
import { InstructionsFormProps } from "./types";

const InstructionsForm = ({ 
  mode,
  form,
  externalIndex,
  areDescriptionsVisible,
  onEnterDescriptions,
  onExitDescriptions,
  onEdit,
}: InstructionsFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <InstructionsDescriptionsForm
          onFinish={onExitDescriptions}
          formMethods={form}
          externalIndex={externalIndex}
          mode={mode}
          onEdit={onEdit}
        />
      ) : (
        <InstructionsDataForm
          form={form}
          onEnterDescriptions={onEnterDescriptions}
          mode={mode}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default InstructionsForm;
