import React from "react";
import { Box, styled, Tooltip } from "@mui/material";
import { green, yellow, grey } from "@mui/material/colors";
import {
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  Block as BlockIcon
} from "@mui/icons-material";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

export type TrafficLightStatus = "OPEN" | "CLOSE" | "VOID";

type TrafficLightElementProps = {
  text: string;
  element: any;
};

type TrafficLightProps = {
  status: TrafficLightStatus;
};

const TrafficLight = ({ status }: TrafficLightProps) => {

  const { dataElementsChoices } = useDataElementChoices(["PURCHASE_ORDER_STATUS"]);
  if (dataElementsChoices === undefined) return <BeatLoadingPage />;
  
  const statusTextAndElement: Record<TrafficLightStatus, TrafficLightElementProps> = {
    OPEN: {
      text: dataElementsChoices["PURCHASE_ORDER_STATUS"].find( e => e.value === "OPEN")?.text ?? "Open",
      element: <StyledIncompleteIcon />,
    },
    CLOSE: {
      text: dataElementsChoices["PURCHASE_ORDER_STATUS"].find( e => e.value === "CLOSE")?.text ?? "Close",
      element: <StyledCheckCircleIcon />,      
    },
    VOID: {
      text: dataElementsChoices["PURCHASE_ORDER_STATUS"].find( e => e.value === "VOID")?.text ?? "Void",
      element: <StyledVoidIcon />,      
    },
  };

  return (
    <Tooltip title={statusTextAndElement[status].text} placement="top-start">
      <Box display="flex" justifyContent="left">
        {statusTextAndElement[status].element}
      </Box>
    </Tooltip>
  );
};

export default TrafficLight;

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: green[200],
});

const StyledIncompleteIcon = styled(PendingIcon)({
  color: yellow[600],
});

const StyledVoidIcon = styled(BlockIcon)({
  color: grey[600],
});
