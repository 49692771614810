import React, { useRef } from "react";
import { FilePresent as FilePresentIcon } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  styled,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

type FilePickerProps<
  TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> 
  = Pick<TextFieldProps, "label"> & {
  controllerProps: UseControllerProps<TFieldValues, TName>;
} & {
  tooltipTitle: string;
  readOnly: boolean;
  documentType: string;
};

// Nuevo componente para seleccionar archivos (con una extension en especifico)
const FilePicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  tooltipTitle,
  controllerProps,
  readOnly,
  documentType
}: FilePickerProps<TFieldValues, TName>) => {

  const backgroundInputRef = useRef<HTMLInputElement>(null);

  const {
    field: { name, value, onChange },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>(controllerProps);

  // TODO: Generar ENUM y agregar word
  if(documentType.toLocaleLowerCase() === "pdf") {
    documentType = "application/pdf";
  } else if (documentType.toLocaleLowerCase() === "excel") {
    documentType = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
  } else {
    documentType = "";
  }

  return (
    <Grid container spacing={2} wrap="nowrap" alignItems="center">
      <Grid item>
        <StyledTextFieldImagenBackground
          label={label}
          // @ts-ignore FIXME:
          value={value?.name ?? ""}
          error={invalid}
          helperText={error?.message}
          // @ts-ignore FIXME:
          InputLabelProps={{ shrink: value?.name !== undefined }}
          size="small"
          variant="outlined"
        />
      </Grid>

      {!readOnly && (
        <Grid item>
          <Tooltip title={tooltipTitle}>
            <StyledIconButton
              id="file-picker-button"
              onClick={() => backgroundInputRef.current?.click()}
            >
              <FilePresentIcon />
              <input
                ref={backgroundInputRef}
                name={name}
                onChange={(event) => onChange(event.target.files?.item(0))}
                type="file"
                hidden
                accept={documentType}
              />
            </StyledIconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default FilePicker;

const StyledTextFieldImagenBackground = styled((props) => (
  <TextField disabled {...props} />
))<TextFieldProps>(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: theme.palette.text.secondary,
  },
  "& .MuiInputBase-input": {
    color: theme.palette.text.primary,
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: theme.palette.text.primary,
    textFillColor: theme.palette.text.primary,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
