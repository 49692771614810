import { uploadFileS3 } from "apis/uploadFileS3Api";
import { useMutation } from "react-query";
import { useRequestPermissionUploadInvoiceFileToS3Mutation } from '../../../__generated__/graphql/types';
import {
  useRegisterFileUploadedToS3Mutation
} from "__generated__/graphql/types";

export const useUploadInvoiceToS3 = () => {
  const {
    mutateAsync: requestPermissionUploadFileToS3,
  } = useRequestPermissionUploadInvoiceFileToS3Mutation();

  const {
    mutateAsync: registerFileUploadedToS3Api,
  } = useRegisterFileUploadedToS3Mutation();

  return useMutation(async (invoice: File) => {
    const {
      requestPermissionUploadInvoiceFileToS3: permission,
    } = await requestPermissionUploadFileToS3({});

    await uploadFileS3({
      file: invoice,
      url: permission.url,
    });

    const {
      registerFileUploadedToS3: { fileId },
    } = await registerFileUploadedToS3Api({
      s3File: {
        key: permission.key,
        bucket: permission.bucket,
      },
    });

    return { fileId };
  });
};
