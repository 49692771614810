import { Box, Grid, styled } from "@mui/material";
import logo from "assets/img/logo.png";

const FabBot = () => {
  return (
    <Wrapper>
      <Grid container alignItems="center">
        <Grid item>
          <Box width="150px" position="relative">
            <a href="https://appliqations.com" target="_blank" rel="noreferrer">
              <StyledImg alt="logo" src={logo} />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FabBot;

const StyledImg = styled("img")({
  width: "100%",
});

const Wrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  zIndex: 0,
  bottom: theme.spacing(4),
  left: theme.spacing(4),
  background: "transparent",
  width: "130px",
}));
