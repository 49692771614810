import { DataElementInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): DataElementInput => {
  return {
    id: form.basicData.name,
    dataType: form.basicData.dataType,
    length: form.basicData.length,
    decimals: form.basicData.decimals,
    isLanguageDependent: form.basicData.isLanguageDependent,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
    choices: form.options?.options?.map((e) => ({
      id: e.name,
      translations: e.descriptions.map((e) => ({
        language: e.language,
        description: e.text,
      })),
    })),
  };
};
