import { Box, Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";

type CollapseButtonProps = {
  show: boolean;
  toggleShow: () => void;
};

const CollapseButton = ({ show, toggleShow }: CollapseButtonProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      pt={show ? 3 : undefined}
    >
      <Divider />

      <StyledButton variant="contained" onClick={toggleShow}>
        {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </StyledButton>

      <Divider />
    </Box>
  );
};

const StyledButton = styled((props: ButtonProps) => {
  return <Button color="primary" {...props} />;
})({
  marginRight: 20,
  marginLeft: 20,
  height: 20,
});

const Divider = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: 0,
  height: 2,
  flexGrow: 1,
}));

export default CollapseButton;
