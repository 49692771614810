import { useDialog } from "hooks/useDialog";
import produce from "immer";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  FilterParameterType,
  Filters,
  FilterType,
  Range,
} from "utils/FilterTypes";

type FormType<T> = Partial<{
  filterType: "PARAMETERS" | "RANGE";
  parameter: Partial<{
    type: FilterParameterType;
    value: T;
  }>;
  range: Partial<Range<T>>;
}>;

type UseRangeParameterFilterProps<T> = {
  value?: Filters<T>;
  onChange: (value?: Filters<T>) => void;
};

const useRangeParameterFilter = <T>({
  value,
  onChange,
}: UseRangeParameterFilterProps<T>) => {
  const form = useForm<FormType<T>>({ defaultValues });

  const { getValues, watch, unregister, reset, handleSubmit } = form;

  const dialog = useDialog();

  const parameterType = watch("parameter.type");
  const filterType = watch("filterType");

  const onSubmit = handleSubmit((form) => {
    // @ts-ignore FIXME:
    onChange(mapFormToFilter(form));
    dialog.close();
  });

  useEffect(() => {
    unregister(["parameter", "range"]);
  }, [unregister, filterType]);

  useEffect(() => {
    const next = produce(getValues(), (state) => {
      if (state?.parameter?.value === undefined) return;
      state.parameter.value = undefined;
    });
    // @ts-ignore FIXME:
    reset(next);
  }, [unregister, parameterType, getValues, reset]);

  useEffect(() => {
    // @ts-ignore FIXME:
    reset(mapFilterToForm(value));
  }, [reset, value, dialog.isOpen]);

  return {
    dialog,
    onSubmit,
    form,
    filterType,
  };
};

export default useRangeParameterFilter;

const mapFormToFilter = <T>(form: FormType<T>): Filters<T> | undefined => {
  if (form.filterType !== "PARAMETERS") return form.range;

  if (
    form?.parameter?.type === undefined ||
    form?.parameter?.value === undefined
  )
    return undefined;

  return { [form.parameter.type]: form.parameter.value };
};

const mapFilterToForm = <T>(filter?: Filters<T>): FormType<T> | undefined => {
  if (filter === undefined) return defaultValues;

  const { filterType, parameterType } =
    detectFilterAndParameterType(filter) ?? {};

  switch (filterType) {
    case "PARAMETERS": {
      if (parameterType === undefined) return undefined;
      const value = filter[parameterType];
      if (value === undefined) return;
      return {
        filterType,
        parameter: {
          type: parameterType,
          value: filter[parameterType],
        },
      };
    }
    case "RANGE": {
      const { gte, lte } = filter ?? {};
      if (gte === undefined || lte === undefined) return;
      return {
        filterType,
        range: { gte, lte },
      };
    }
  }
};

export const detectFilterAndParameterType = <T>(
  value: Filters<T>
):
  | { filterType: "RANGE"; parameterType?: never }
  | { filterType: "PARAMETERS"; parameterType: FilterParameterType }
  | undefined => {
  const { eq, neq, gt, gte, lt, lte } = value;

  if (gte !== undefined && lte !== undefined) return { filterType: "RANGE" };

  if (eq !== undefined)
    return { filterType: "PARAMETERS", parameterType: "eq" };

  if (neq !== undefined)
    return { filterType: "PARAMETERS", parameterType: "neq" };

  if (gt !== undefined)
    return { filterType: "PARAMETERS", parameterType: "gt" };

  if (gte !== undefined)
    return { filterType: "PARAMETERS", parameterType: "gte" };

  if (lt !== undefined)
    return { filterType: "PARAMETERS", parameterType: "lt" };

  if (lte !== undefined)
    return { filterType: "PARAMETERS", parameterType: "lte" };
};

export const mapFilterToInputPlaceholder = <T>({
  form,
  filterType,
  parameterType,
  transform,
}: {
  form: Filters<T>;
  filterType: FilterType;
  parameterType?: FilterParameterType;
  transform: (value: unknown) => string;
}) => {
  if (filterType === "RANGE") {
    return `Entre ${transform(form.gte)} y ${transform(form.lte)}`;
  }

  switch (parameterType) {
    case "eq":
      return `Igual a ${transform(form.eq)}`;
    case "neq":
      return `Distinto a ${transform(form.neq)}`;
    case "gt":
      return `Mayor a ${transform(form.gt)}`;
    case "gte":
      return `Mayor o igual a ${transform(form.gte)}`;
    case "lt":
      return `Menor a ${transform(form.lt)}`;
    case "lte":
      return `Menor o igual a ${transform(form.lte)}`;
  }
};

export const filterTypesOptions: { value: FilterType; text: string }[] = [
  { value: "PARAMETERS", text: "Parametros" }, // FIXME: traduccion
  { value: "RANGE", text: "Rango" }, // FIXME: traduccion
];

export const filterParametersOptions: {
  value: FilterParameterType;
  text: string;
}[] = [
  { value: "eq", text: "=" }, // FIXME: traduccion
  { value: "neq", text: "≠" }, // FIXME: traduccion
  { value: "gt", text: ">" }, // FIXME: traduccion
  { value: "gte", text: "≥" }, // FIXME: traduccion
  { value: "lt", text: "<" }, // FIXME: traduccion
  { value: "lte", text: "≤" }, // FIXME: traduccion
];

const defaultValues = {
  filterType: undefined,
  parameter: {
    type: undefined,
    value: undefined,
  },
  range: {
    gte: undefined,
    lte: undefined,
  },
};
