import { Box, Button, Grid, Typography } from "@mui/material";
import { ImagePicker } from "components/inputs/ImagePicker";
import { useUploadUserImageToS3 } from "apps/UsersPage/CreatePage/useUploadUserImageToS3";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { fileToBase64 } from "utils/fileToBase64";
import { urlToFile } from "utils/urlToFile";
import {
  useGetMyInformationQuery,
  useUpdateMyImageMutation,
} from "__generated__/graphql/types";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useGetMyProfileImageQuery } from '../../../../__generated__/graphql/types';
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

export type ImageFormFields = {
  image: File;
};

const ImageForm = () => {
  const form = useForm<ImageFormFields>();

  const { appMessage } = useAppMessages("MAIN");
  const { language } = useSystemLanguage();

  const { mutateAsync: updateMyImage, isSuccess } = useUpdateMyImageMutation();
  const { mutateAsync: uploadUserImageToS3 } = useUploadUserImageToS3();

  const image = useWatch({ control: form.control, name: "image" });
  const { setValue } = form;

  const { data: { me } = {}} = useGetMyProfileImageQuery();
  const { refetch: getMyRefetchInformation } = useGetMyInformationQuery(
    { language },
    { enabled: isSuccess }
  );

  const isSaveButtonVisible = form.formState.isDirty;
  const [iconBase64, setIconBase64] = useState<string>();

  const handleSubmit = form.handleSubmit(async (formValue) => {
    try {
      const { fileId } = await uploadUserImageToS3(formValue.image);
      await updateMyImage({ imageId: fileId });
      await getMyRefetchInformation();
    } catch {
      // TODO: mostrar mensaje de error
    }
  });

  useEffect(() => {
    if (!(image instanceof File)) return;
    fileToBase64(image).then((e) => setIconBase64(e as string));
  }, [image]);

  useEffect(() => {
    if (me == null) return;
    urlToFile(me.image.url, me.image.name).then((file) =>
      setValue("image", file)
    );
  }, [me, setValue]);

  if (me == null) return <BeatLoadingPage />;

  return (
    <Box>
      <Typography variant="h6">
        {appMessage["PRF_IMG"]}
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          {isSuccess ? (
            <Box>
              {appMessage["IMG_SUCCESS"]}
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Box marginTop={2}>
                <ImagePicker
                  label={appMessage["PRF_IMG"]}
                  tooltipTitle={appMessage["SELECT_IMG"]}
                  controllerProps={{
                    name: "image", // react hook form no infiere que name tiene que ser keyof Step1FormFields
                    control: form.control,
                  }}
                  readOnly={false} // FIXME:
                />
              </Box>

              {isSaveButtonVisible && (
                <Box marginTop={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    {appMessage["SAVE"]}
                  </Button>
                </Box>
              )}
            </form>
          )}
        </Grid>

        <Grid item xs={6}>
          {iconBase64 && (
            <Grid container justifyContent="center">
              <Grid item>
                <img
                  src={iconBase64}
                  alt=""
                  style={{
                    height: "140px",
                    width: "140px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageForm;
