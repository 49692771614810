import React from "react";
import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { AccountingEntityRoutes } from "./routes";
import { EditPage } from "./EditPage";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";

const AccountingEntityPage = () => {
  return (
    <Routes>
      <Route path={AccountingEntityRoutes.VIEW_ALL} element={<ViewAllPage />} />

      <Route path={AccountingEntityRoutes.VIEW} element={<ViewPage />} />

      <Route path={AccountingEntityRoutes.CREATE} element={<CreatePage />} />

      <Route path={AccountingEntityRoutes.EDIT} element={<EditPage />} />
    </Routes>
  );
};

export default AccountingEntityPage;
