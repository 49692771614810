import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetAccountingEntity_ViewPageQuery,
  useUpdateAccountingEntityMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { AccountingEntityRoutesState, AccountingEntityRoutes } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

type Route = AccountingEntityRoutes.EDIT;
type LocationState = AccountingEntityRoutesState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { accountingEntityId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const {
    data: { accountingEntity } = {},
    isFetchedAfterMount,
  } = useGetAccountingEntity_ViewPageQuery({
    id: accountingEntityId,
  });

  const initialValue =
    accountingEntity != null && isFetchedAfterMount
      ? mapDtoToForm(accountingEntity)
      : undefined;

  const { mutateAsync: replace } = useUpdateAccountingEntityMutation();

  const onSave = (form: FormFields) =>
    replace({
      id: accountingEntityId,
      accountingEntity: mapFormToDto(form),
    });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title="Editar entradas contables" // FIXME: traduccion
        toBack={TO_BACK_ROUTER}
      />

      <Form
        mode="edit"
        onSave={onSave}
        onCancel={onCancel}
        errorMessage="Error al editar el entradas contables. Vuelva a intentar." // FIXME: traduccion
        initialValue={initialValue}
        initialStep={activeStep}
      />
    </Layout>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
