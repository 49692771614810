import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  options: { text: string; value: string }[];
} & Omit<
  TextFieldProps,
  "value" | "onChange" | "onBlur" | "select" | "helperTexts"
>;

const ParameterRangeSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  controllerProps,
  options,
  ...textFieldProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>({
    // @ts-ignore FIXME:
    defaultValue: "",
    ...controllerProps,
  });

  return (
    <TextField
      margin="dense"
      id={name}
      name={name}
      fullWidth
      select
      variant="outlined"
      error={invalid}
      helperText={error?.message}
      value={value}
      onChange={(valor) => onChange(valor.target.value)}
      onBlur={onBlur}
      {...textFieldProps}
    >
      {options.map(({ value, text }) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ParameterRangeSelect;
