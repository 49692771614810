import { DescriptionFormFields } from "components/inputs/DescriptionFormControl/types";
import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { actionEmptyRow } from "./ActionsForm/ActionsNamesForm/template";
import { ActionsFormFields } from "./ActionsForm/types";
import { BasicDataFormFields } from "./BasicDataForm/types";
import { messageEmptyRow } from "./MessagesForm/MessagesCodesForm/template";
import { MessagesFormFields } from "./MessagesForm/types";
import { sectionEmptyRow } from "./SectionsForm/SectionsPositionsForm/template";
import { SectionsFormFields } from "./SectionsForm/types";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<BasicDataFormFields>({
    defaultValues: initialValue?.basicData,
  });

  const descriptions = useForm<DescriptionFormFields>({
    defaultValues: initialValue?.descriptions ?? {
      descriptions: [emptyDescriptionRow],
    },
  });

  const sections = useForm<SectionsFormFields>({
    defaultValues: initialValue?.sections ?? { sections: [sectionEmptyRow] },
  });

  const actions = useForm<ActionsFormFields>({
    defaultValues: initialValue?.actions ?? { actions: [actionEmptyRow] },
  });

  const messages = useForm<MessagesFormFields>({
    defaultValues: initialValue?.messages ?? { messages: [messageEmptyRow] },
  });

  return { basicData, descriptions, sections, actions, messages };
};
