import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { BasicDataFormFields } from "./BasicDataForm/types";
import { FormFields } from "./types";
import { RevenueFormFields } from "./RevenueDataForm/types";
import { revenueEmptyRow } from "./RevenueDataForm/RevenueNamesForm/template";
import { CostFormFields } from "./CostDataForm/types";
import { costEmptyRow } from "./CostDataForm/CostNamesForm/template";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<BasicDataFormFields>({
    defaultValues: initialValue?.basicData,
  });

  const descriptions = useForm<FormFields["descriptions"]>({
    defaultValues: initialValue?.["descriptions"] ?? {
      descriptions: [emptyDescriptionRow],
    },
  });

  const costData = useForm<CostFormFields>({
    defaultValues: initialValue?.costData ?? {
      collectors: [costEmptyRow],
    },
  });

  const revenueData = useForm<RevenueFormFields>({
    defaultValues: initialValue?.revenueData ?? {
      collectors: [revenueEmptyRow],
    },
  });

  return { basicData,descriptions, costData, revenueData};
};
