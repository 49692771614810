import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

type DataElementsCheckedRowsState = {
  areAllRowsChecked: boolean;
  individualRowsChecked: Record<string, boolean>;
};

const initialState: DataElementsCheckedRowsState = {
  areAllRowsChecked: false,
  individualRowsChecked: {},
};

const viewDataElementsRowsCheckedSlice = createSlice({
  name: "dataElementsCheckedRowsSlice",
  initialState,
  reducers: {
    checkAllDataElementsRows: (state, action: PayloadAction<boolean>) => {
      const checked = action.payload;
      if (checked) {
        state.individualRowsChecked = {};
      }
      state.areAllRowsChecked = checked;
    },
    checkDataElementsRow: (
      state,
      action: PayloadAction<{ id: string; checked: boolean }>
    ) => {
      const { id, checked } = action.payload;
      state.individualRowsChecked[id] = checked;
    },
  },
});

export const {
  checkDataElementsRow,
  checkAllDataElementsRows,
} = viewDataElementsRowsCheckedSlice.actions;

export const selectIsDataElementsRowChecked = (state: RootState) => (
  id: string
) =>
  state.dataElements.rowsChecked.individualRowsChecked[id] ||
  (state.dataElements.rowsChecked.individualRowsChecked[id] === undefined &&
    state.dataElements.rowsChecked.areAllRowsChecked);

export const selectAreAllDataElementsRowsChecked = (state: RootState) =>
  state.dataElements.rowsChecked.areAllRowsChecked;

export default viewDataElementsRowsCheckedSlice.reducer;
