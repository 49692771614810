import { GetCountryViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetCountryViewPageQuery["country"]>
): FormFields => {
  return {
    basicData: {
      name: dto.id,
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
    regions: {
      regions: dto.regions.map((e) => ({
        name: e.id,
        descriptions: e.translations.map((e) => ({
          language: e.language,
          text: e.description,
        })),
      })),
    },
  };
};
