import {
  ArrowDownward as ArrowDownwardIcon,
  Clear as ClearIcon
} from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { CheckboxController } from "components/inputs/CheckboxController";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import useAppMessages from 'hooks/useAppMessages/hook';
import { Controller } from "react-hook-form";
import { RowProps } from "./types";

const Row = ({
  index,
  remove,
  item,
  readOnly,
  onEnterDescriptions,
  form,
}: RowProps) => {
  const { control, trigger, watch } = form;

  const contact = watch(
    `contact.${index}` as "contact.0"
  );
  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");
  const {appMessage:appMsg} = useAppMessages("MESSAGES");
  
  if (appMsg == null) return <BeatLoadingPage/>;

  const onRemoveRow = () => {
    remove();
    trigger();
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`contact.${index}._virtualField` as "contact.0._virtualField"}
          rules={{
            validate: () => {
              if (contact?._isUser && contact.userId === "")
                return appMessage["SELECT_USER"] ?? "Select user"; ///FIXPRD QUITAR
              if (!contact?._isUser && (contact.firstName === "" || contact.lastName === "" || contact.email === "" || contact.phoneNumber === ""))
              return appMessage["USER_INCORRECT_DATA"] ?? "Fill all the user data"; ///FIXPRD QUITAR
                return true;
            },
          }}
          render={({ fieldState: { invalid, error } }) => (
            <FormTableTextField
              value={contact?._isUser ? contact?.userId : contact?.firstName + " " + contact?.lastName}
              helperText={error?.message}
              error={invalid}
              inputProps={{ disabled: readOnly }}
            />
          )}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <CheckboxController
          controllerProps={{
            name: `contact.${index}._isUser` as "contact.0._isUser",
            control,
            defaultValue: contact?.userId ? true : false,
          }}
          readOnly={readOnly}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip
          title={contact?._isUser ? appMessage["SELECT_USER"]: appMessage["ADD_CONTACT"]}
        >
          <StyledIconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={() => onEnterDescriptions(index)}
          >
            <ArrowDownwardIcon />
          </StyledIconButton>
        </Tooltip>

        {!readOnly && (
          <Tooltip
            title={appMsg["ADD_ROW"]}
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});

// FIXME: commons
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
