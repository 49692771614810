import React from "react";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import { MatchcodeDisplayInputInTableRow } from "components/inputs/matchcode/MatchcodeDisplayInputInTableRow";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import { GetPurchaseOrdersItemsForCreateInvoiceQuery } from '../../../../../../../__generated__/graphql/types';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

export type PositionMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  itemsOptions: NonNullable<
    GetPurchaseOrdersItemsForCreateInvoiceQuery["purchaseOrderItems"]
  >;
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
};

export const DataElementMatchcode = ({
  value,
  onChange,
  itemsOptions,
  invalid,
  errorMessage,
  readOnly,
}: PositionMatchcodeProps) => {

  const options = itemsOptions.map((e) => ({
    id: e.purchaseOrderItem,
    purchaseOrderItem: e.purchaseOrderItem,
    productId: e.productId,
    description: e.productDescription
  }));

  const {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    value,
    onChange,
    searchKeys: ["purchaseOrderItem", "productId", "description"],
  });

  const { dataElementsDescriptions } = useDataElementDescription([
    "PURCHASE_ORDER_ITEM_ID",
    "PRODUCT_ID",
    "PRODUCT_DESCRIPTION"
  ]);

  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <>
      <KeyInputWrapper>
        <MatchcodeDisplayInputInTableRow
          text={text?.productId ?? ""}
          isDeleteButtonVisible={isDeleteButtonVisible}
          onClearValue={onClearValue}
          onOpenDialog={dialog.open}
          error={invalid}
          helperText={errorMessage}
          readOnly={readOnly}
        />
      </KeyInputWrapper>

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "purchaseOrderItem", text: dataElementsDescriptions["PURCHASE_ORDER_ITEM_ID"]},
              { key: "productId", text: dataElementsDescriptions["PRODUCT_ID"]},
              { key: "description", text: dataElementsDescriptions["PRODUCT_DESCRIPTION"] }
            ]}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default DataElementMatchcode;
