import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { ParameterRangeTextField } from "components/filters/ParameterRangeTextField";
import {
  filterParametersOptions,
  filterTypesOptions,
  useRangeParameterFilter,
} from "hooks/useRangeParameterFilter";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { convertStringIntToNumberOrUndefined } from "utils/convertStringInputToNumber";
import { Filters } from "utils/FilterTypes";
import { ParameterRangeSelect } from "../../ParameterRangeSelect";

type NumberFilterInputProps = {
  label: string;
  value?: Filters<number>;
  onChange: (value?: Filters<number>) => void;
};

const NumberFilterInput = ({
  label,
  value,
  onChange,
}: NumberFilterInputProps) => {
  const {
    dialog,
    form: { control, getValues },
    filterType,
    onSubmit,
  } = useRangeParameterFilter({ value, onChange });

  return (
    <>
      <ParameterRangeTextField
        label={label}
        value={value}
        transform={transformNumber}
        isDeleteButtonVisible={value !== undefined}
        onDeleteValue={() => onChange(undefined)}
        onOpenDialog={dialog.open}
      />

      <Dialog
        open={dialog.isOpen}
        onClose={dialog.close}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {/* // FIXME: traduccion */}
          Filtrar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* // FIXME: traduccion */}
            Seleccionar tipo de filtrado
          </DialogContentText>

          <ParameterRangeSelect
            controllerProps={{
              name: "filterType",
              control: control,
              rules: { required: "Seleccione una opción." }, // FIXME: traduccion
            }}
            options={filterTypesOptions}
          />

          {filterType === "PARAMETERS" && (
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <ParameterRangeSelect
                  controllerProps={{
                    name: "parameter.type",
                    control: control,
                    rules: { required: true }, // FIXME: traduccion
                  }}
                  options={filterParametersOptions}
                />
              </Grid>

              <Grid item xs={10}>
                <NumberInput
                  controllerProps={{
                    name: "parameter.value",
                    control: control,
                    rules: { required: "Ingrese un valor." }, // FIXME: traduccion
                  }}
                />
              </Grid>
            </Grid>
          )}

          {filterType === "RANGE" && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NumberInput
                  controllerProps={{
                    name: "range.gte",
                    control: control,
                    rules: { required: "Ingrese un valor." }, // FIXME: traduccion
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <NumberInput
                  controllerProps={{
                    name: "range.lte",
                    control: control,
                    rules: {
                      required: "Ingrese un valor.", // FIXME: traduccion
                      validate: (value) => {
                        const lte = getValues("range.gte");
                        if (lte === undefined) return true;
                        if (value === undefined) return false;
                        return value > lte
                          ? true
                          : "El número de fin debe ser mayor al número de inicio.";
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={dialog.close} color="primary">
            {/* // FIXME: traduccion */}
            Cerrar
          </Button>

          <Button onClick={() => onSubmit()} color="primary">
            {/* // FIXME: traduccion */}
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NumberFilterInput;

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  MuiTextFieldProps,
  "name" | "error" | "value" | "onChange" | "onBlur"
> & {
  controllerProps: UseControllerProps<TFieldValues, TName>;
};

const NumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  controllerProps,
  ...textFieldProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>(controllerProps);

  return (
    <TextField
      id={name}
      name={name}
      variant="outlined"
      type="number"
      fullWidth
      margin="dense"
      error={invalid}
      helperText={error?.message}
      value={value ?? ""}
      onBlur={onBlur}
      onChange={(event) => {
        onChange(convertStringIntToNumberOrUndefined(event.target.value));
      }}
      {...textFieldProps}
    />
  );
};

const transformNumber = (value: unknown) =>
  typeof value === "number" ? value.toString() : "";
