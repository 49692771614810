import { TileInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (
  form: FormFields,
  iconFileId?: string
): TileInput => {
  return {
    appId: form.basicData.appId,
    path: form.basicData.path,
    shape: form.tileType.tileType.shape,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      title: e.title,
      subtitle: e.subtitle,
    })),
    kpiId: form.kpi?.kpiId,
    iconId: iconFileId,
  };
};
