import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import React from "react";
import { DataElementMatchcodeInput } from "./DataElementMatchcodeInput";

export type TableOption = {
  id: string;
  name: string;
  description: string;
};

export type DataElementMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  tablesOptions: TableOption[];
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
};

export const DataElementMatchcode = ({
  value,
  onChange,
  tablesOptions,
  invalid,
  errorMessage,
  readOnly = false,
}: DataElementMatchcodeProps) => {
  const {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options: tablesOptions,
    value,
    onChange,
    searchKeys: ["name", "description"],
  });

  return (
    <>
      <DataElementMatchcodeInput
        text={text?.name ?? ""}
        isDeleteButtonVisible={isDeleteButtonVisible}
        onClearValue={onClearValue}
        onOpenDialog={dialog.open}
        error={invalid}
        helperText={errorMessage}
        readOnly={readOnly}
      />

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "name", text: "NOMBRE" }, // FIXME: traduccion
              { key: "description", text: "DESCRIPTION" }, // FIXME: traduccion
            ]}
            rows={visibleOptions ?? []} // FIXME:
            rowIdSelected={selectedRowId ?? ""} // FIXME:
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default DataElementMatchcode;
