import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

export type TextFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  MuiTextFieldProps,
  "name" | "defaultValue" | "value" | "helperText"
> & {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  transform?: (v: string) => unknown;
};

const TextFieldController = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  controllerProps,
  transform = (v) => v,
  ...textFieldProps
}: TextFieldControllerProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController(controllerProps);

  return (
    <StyledMuiTextField
      name={name}
      disabledInput={textFieldProps.inputProps?.disabled}
      value={value ?? ""}
      onChange={(event) => {
        onChange(transform(event.target.value));
      }}
      onBlur={onBlur}
      error={invalid}
      helperText={error?.message}
      size="small"
      variant="outlined"
      fullWidth
      {...textFieldProps}
    />
  );
};

export default TextFieldController;

const StyledMuiTextField = styled(MuiTextField)<
  MuiTextFieldProps & { disabledInput: boolean }
>(({ disabledInput, theme }) => ({
  "& .MuiInputBase-root:hover fieldset": {
    borderColor: disabledInput
      ? "rgba(0, 0, 0, 0.23)" // FIXME: hardcoded
      : undefined,
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: theme.palette.text.primary,
    textFillColor: theme.palette.text.primary,
  },
}));
