import {
  MoveToInboxOutlined as MoveToInboxOutlinedIcon,
  Undo as UndoIcon
} from "@mui/icons-material";
import {
  Fab,
  FabProps,
  Grid,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FabCompanyLogo } from "components/inputs/FabCompanyLogo";
import { State } from "history";
import { Link } from "react-router-dom";

type HeaderProps = {
  title: string;
  toBack?: string;
  state?: State;
};

const Header = ({ 
  title, 
  toBack,
  state,
}: HeaderProps) => {
  return (
    <StyledGrid container>
      <Grid item id="header-back-button" paddingLeft="40px" paddingRight="40px">
        {/* FIXME: <div> en <a> no es valido */}
        {toBack && (
          <Link to={toBack} state={state}>
            <StyledFab>
              <UndoIcon />
            </StyledFab>
          </Link>
        )}
      </Grid>

      <Grid 
        item 
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
      >
        <StyledMoveToInboxOutlinedIcon fontSize="large" />
        <StyledTypography id="header-app-title" variant="h4">{title}</StyledTypography>
      </Grid>

      <Grid item>
        <FabCompanyLogo /> 
      </Grid>
    </StyledGrid>
  );
};

export default Header;

const StyledGrid = styled(Grid)({
  flexDirection:"column",
  alignContent:"space-between",
  height:"84px",
  justifyContent:"center"
});

const StyledFab = styled((props: FabProps) => {
  return <Fab color="primary" {...props} />;
})(() => ({
  zIndex: 1,
  height: "60px",
  width: "60px"
}));

const StyledMoveToInboxOutlinedIcon = styled(MoveToInboxOutlinedIcon)(
  ({ theme }) => ({
    color: theme.palette.appsTitle,
    marginRight: 10,
  })
);

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.appsTitle,
}));
