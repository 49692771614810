import { urlToFile } from "utils/urlToFile";
import { GetGroupForViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = async (
  dto: NonNullable<GetGroupForViewPageQuery["group"]>
): Promise<FormFields> => {
  return {
    basicData: {
      name: dto.id,
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
    icon: {
      icon:
        dto.icon?.url === undefined
          ? (null as any) // FIXME:
          : await urlToFile(dto.icon.url, dto.icon?.name),
    },
    tiles: {
      tilesIds: dto.tiles.map((e) => e.id),
      businessObjectId: undefined as any, // FIXME:
    },
  };
};
