import { FiltersDialog } from "components/filters/FiltersDialog";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import React from "react";
import { dataElementsColumnsLabels } from "../../../reducers/visibleColumnsSlice";
import {
  DataElementsFiltersKeys,
  DATA_ELEMENTS_FILTERS_KEYS,
  selectIsDataElementsFilterVisible,
  setDataElementsFilterVisibility,
} from "../../../reducers/filtersAndPaginationSlice";

type DataElementsFiltersVisibilityDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const DataElementsFiltersVisibilityDialog = ({
  open,
  handleClose,
}: DataElementsFiltersVisibilityDialogProps) => {
  const dispatch = useAppDispatch();

  const isFilterVisible = useAppSelector(selectIsDataElementsFilterVisible);

  const onChangeFilters = (key: DataElementsFiltersKeys) => (
    isVisible: boolean
  ) => {
    dispatch(setDataElementsFilterVisibility({ key, isVisible }));
  };

  const filters = DATA_ELEMENTS_FILTERS_KEYS.map((key) => ({
    key,
    label: dataElementsColumnsLabels[key],
    checked: isFilterVisible[key],
    onChange: onChangeFilters(key),
  }));

  return (
    <FiltersDialog open={open} handleClose={handleClose} filters={filters} />
  );
};

export default DataElementsFiltersVisibilityDialog;
