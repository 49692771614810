import React from "react";
import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { BusinessObjectsRoutes } from "./routes";
// import { EditTablePage } from "./EditTablePage";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";
import { EditPage } from "./EditPage";
// import { ViewTablePage } from "./ViewTablePage";

const BusinessObjectPage = () => {
  return (
    <Routes>
      <Route path={BusinessObjectsRoutes.VIEW_ALL} element={<ViewAllPage />} />

      <Route path={BusinessObjectsRoutes.VIEW} element={<ViewPage />} />

      <Route path={BusinessObjectsRoutes.CREATE} element={<CreatePage />} />

      <Route path={BusinessObjectsRoutes.EDIT} element={<EditPage />} />
    </Routes>
  );
};

export default BusinessObjectPage;
