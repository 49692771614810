import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import React from "react";
import { PositionMatchcodeInput } from "./PositionMatchcodeInput";

export type PositionOption = {
  id: string;
  position: string;
  description: string;
};

export type PositionMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  positionsOptions: PositionOption[];
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
};

export const PositionMatchcode = ({
  value,
  onChange,
  positionsOptions,
  invalid,
  errorMessage,
  readOnly = false,
}: PositionMatchcodeProps) => {
  const {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options: positionsOptions,
    value,
    onChange,
    searchKeys: ["position", "description"],
  });

  return (
    <>
      <PositionMatchcodeInput
        text={text?.id ?? ""}
        isDeleteButtonVisible={isDeleteButtonVisible}
        onClearValue={onClearValue}
        onOpenDialog={dialog.open}
        error={invalid}
        helperText={errorMessage}
        readOnly={readOnly}
      />

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "position", text: "ID" }, // FIXME: traduccion
              { key: "description", text: "DESCRIPTION" }, // FIXME: traduccion
            ]}
            rows={visibleOptions ?? []} // FIXME:
            rowIdSelected={selectedRowId ?? ""} // FIXME:
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default PositionMatchcode;
