import { URL_BASE } from "apis/const";
import axios from "axiosAuth";
import { useMutation } from "react-query";
import { useGetAmIAuthenticatedQuery } from "__generated__/graphql/types";

const useLogMeOut = () => {
  const { refetch } = useGetAmIAuthenticatedQuery();

  return useMutation(
    () =>
      axios.request({
        url: URL_BASE + "/api/auth/logout",
        method: "POST",
        xsrfHeaderName: "X-CSRF-TOKEN",
      }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );
};

export default useLogMeOut;
