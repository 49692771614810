import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import {
  GetDataElementsForCreateBusinessObjectPageQuery,
  useGetDataElementsForCreateBusinessObjectPageQuery,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

const BasicDataForm = ({
  form,
  readOnly = false,
  onEdit,
}: BasicDataFormProps) => {
  const { control } = form;

  const { language } = useSystemLanguage();

  const {
    dataElementsDescriptions: dataElementsDescription,
  } = useDataElementDescription(["DATA_ELEMENT", "DESCRIPTION"]);

  const {
    data: { dataElements } = {},
  } = useGetDataElementsForCreateBusinessObjectPageQuery({
    language,
  });

  if (dataElements == null) return <BeatLoadingPage />; // TODO: loading spinner
  if (dataElementsDescription == null) return <BeatLoadingPage />; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <MatchcodeInputController
              label={dataElementsDescription["DATA_ELEMENT"]}
              controllerProps={{
                name: "dataElementId",
                control,
                rules: { required: "Seleccione el tipo de tabla." }, // FIXME: traduccion
              }}
              readOnly={readOnly}
              column={[
                {
                  key: "name",
                  text: dataElementsDescription["DATA_ELEMENT"],
                },
                {
                  key: "description",
                  text: dataElementsDescription["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={dataElements.map(mapDataElementToOption)}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BasicDataForm;

const mapDataElementToOption = (
  dto: NonNullable<
    GetDataElementsForCreateBusinessObjectPageQuery["dataElements"]
  >[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});
