import { Box, Button, styled } from "@mui/material";
import { TextFieldController } from "components/inputs/TextFieldController";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useUpdateMyPasswordMutation, useUpdatePasswordMutation } from "__generated__/graphql/types";
import useAppMessages from 'hooks/useAppMessages/hook';
import { notNullUndefined } from '../../../../../utils/notNullUndefined';
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from "@mui/icons-material";
import { green } from "@mui/material/colors";

export type UpdatePasswordFormFields = {
  password: string;
  repeatedPassword: string;
};

type UpdatePasswordFormFieldsProps = {
  user?: string;
  setPasswordUpdated?: (passwordUpdated: boolean) => void;
}

const UpdatePasswordForm = ({ 
  user,
  setPasswordUpdated
}: UpdatePasswordFormFieldsProps) => {

  const { appMessage } = useAppMessages("MAIN");
  const form = useForm<UpdatePasswordFormFields>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { 
    mutateAsync: updatePassword, 
    isSuccess: successUpdate 
  } = useUpdatePasswordMutation();

  const { 
    mutateAsync: updateMyPassword, 
    isSuccess: successMyUpdate 
  } = useUpdateMyPasswordMutation();

  const onSubmit = form.handleSubmit(async (formValue) => {
    try {
      if(notNullUndefined(user)) {
        await updatePassword({ id: user, password: formValue.password });
        setIsSuccess(successUpdate)
      } else {
        await updateMyPassword({ password: formValue.password });
        setIsSuccess(successMyUpdate)
      }
      
      if(notNullUndefined(setPasswordUpdated)) setPasswordUpdated(true);
    } catch {
      // TODO: Dialogs con codigo de error
    }
  });

  return (
    <Box component="span" sx={{ p: 2 }}>
      {isSuccess ? (
        <SuccessMessageBox margin={2}>
          <SuccessfullyIcon />
          {appMessage["PASS_SUCCESS"]}
        </SuccessMessageBox>
      ) : (
        <Box minWidth={"300px"}>
          <form onSubmit={onSubmit}>
            <Box marginTop={2}>
              <TextFieldController
                label={appMessage["NEW_PASS"]}
                controllerProps={{
                  name: "password",
                  control: form.control,
                  rules: {
                    required: appMessage["PASSWORD"] ?? "Please enter the password",///FIXPRD QUITAR
                    minLength: {
                      value: 8,
                      message:
                        appMessage["PASS_AT_LEAST_8C"] ?? "The password must have at least 8 characters",///FIXPRD QUITAR
                    },
                  },
                }}
                type="password"
              />
            </Box>

            <Box marginTop={2}>
              <TextFieldController
                label={appMessage["PASS_REPEAT"]}
                controllerProps={{
                  name: "repeatedPassword",
                  control: form.control,
                  rules: {
                    required: appMessage["PASS_REPEAT_VLD"] ?? "Write the password again", ///FIXPRD QUITAR
                    validate: (value: string) => {
                      return value === form.getValues("password")
                        ? true
                        : appMessage["PASS_NOEQUAL"] ?? "Passwords do not match"; ///FIXPRD QUITAR
                    },
                  },
                }}
                type="password"
              />
            </Box>

            <Box marginTop={2} minWidth={"300px"}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {appMessage["SAVE"]}
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default UpdatePasswordForm;

const SuccessfullyIcon = styled(CheckCircleOutlinedIcon)({
  fontSize: "6rem",
  color: green[200],
  marginRight: "10px"
})

const SuccessMessageBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  fontSize: "1.3rem"
});
