import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { EditPage } from "./EditPage";
import { BusinessPartnersRoutes } from "./routes";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";

const BusinessPartnersPage = () => {
  return (
    <Routes>
      <Route
        path={BusinessPartnersRoutes.VIEW_ALL}
        element={<ViewAllPage />}
      />
      <Route 
        path={BusinessPartnersRoutes.VIEW} 
        element={<ViewPage />} 
      />
      <Route 
        path={BusinessPartnersRoutes.CREATE} 
        element={<CreatePage />} 
      />
      <Route 
        path={BusinessPartnersRoutes.EDIT} 
        element={<EditPage />} 
      />
    </Routes>
  );
};

export default BusinessPartnersPage;
