import { GetOwnMigrationPackageForPageViewQuery } from "__generated__/graphql/types";
import { BusinessObjectRow, BusinessObjectsFormFields } from "../CreatePage/Form/BusinessObjectsForm/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (dto: OwnMigrationDto): FormFields => {
  return {
    description: {
        text: dto.description,
    },
    businessObjects: {
        businessObjects: mapBusinessObjectsIdsToFormBusinessObjects(dto.definition) 
    }
  };
};

export const mapBusinessObjectsIdsToFormBusinessObjects = (
    businessObjects: BusinessObjectIdDto[]
  ): BusinessObjectsFormFields["businessObjects"] => {
    var rows: BusinessObjectRow[] = [];
    businessObjects.map(bo => {
        var businessObject: BusinessObjectRow = {
            id: bo.businessObjectId,
            idsBusinessObject: bo.ids.reduce(
                (prevId, idCurr) => ({
                    ...prevId,
                    [idCurr]: {
                        isAllowed: true,
                    }
                }),
                {}
            )
        }
        rows.push(businessObject);
        return businessObject;
    });

    return rows;
  };

type OwnMigrationDto = NonNullable<
  NonNullable<GetOwnMigrationPackageForPageViewQuery["ownMigrationPackage"]>
>;

type BusinessObjectIdDto = NonNullable<OwnMigrationDto["definition"][number]>;