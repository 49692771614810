import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice"
export const useBusinessProfilesLabels = () => {

    const name = "BUSINESS_PROFILE";
    const description = "DESCRIPTION"

    const dataElementsIds = [
        name, description,
    ];

    type DetailsKeys = typeof dataElementsIds[number];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels: NonNullable<Record<ColumnsKeys, string>> = {
        name: dataElementsDescriptions === undefined ? name : (dataElementsDescriptions[name] ?? name),
        description: dataElementsDescriptions === undefined ? description : (dataElementsDescriptions[description] ?? description),
    };

    const detailsLabels: NonNullable<Record<DetailsKeys, string>> = {
        name: dataElementsDescriptions === undefined ? name : (dataElementsDescriptions[name] ?? name),
        description: dataElementsDescriptions === undefined ? description : (dataElementsDescriptions[description] ?? description),
    };

    return { columnsLabels, detailsLabels }
}