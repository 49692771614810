import { Box, Grid, styled } from "@mui/material";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { base64toBlob } from "utils/base64ToUrlBlob";
import { fileToBase64 } from "utils/fileToBase64";
import { InvoicePreviewFormProps } from "./types";

const InvoicePreview = ({ 
  forms,
  readOnly
}: InvoicePreviewFormProps) => {

  const [fileBase64, setImageBase64] = useState<string>();
  const invoice = useWatch({ control: forms.file.control, name: "file" });

  useEffect(() => {
    if (!(invoice instanceof File)) return;
    fileToBase64(invoice).then((base64) => {
      if(base64 === null) return;
      const blob = base64toBlob(base64.toString());
      setImageBase64(URL.createObjectURL(blob))
    });
  }, [invoice]);

  return (
    <>

      {/* Instructor */}
      <MenuInstructions tutorialsIds={
        readOnly 
          ? ["INVOICE_FORM_VIEW_FILE"]
          : ["INVOICES_FORM_PREVIEW"]
      }/>
      
      <Box marginTop={1}>
        <Grid
          id="pdf-preview"
          spacing={1}
          alignContent="flex-end"
          justifyContent="flex-end"
          height="550px" //TODO: Validar tamaño (responsive)
        >
          <SelectWrapper>
            <object
              data={fileBase64}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              {/* FIXME: No se muestra pdf en dispositivos moviles */}
            </object>
          </SelectWrapper>
        </Grid>
      </Box>
    </>
  );
};

export default InvoicePreview;

const SelectWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.apps,
  borderRadius: theme.shape.wrapperBorderRadius,
  padding: theme.spacing(2),
  height: "inherit"
}));
