import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useGetAppMessagesQuery } from '../../__generated__/graphql/types';

const useAppMessages = (id: string) => {
  const { language } = useSystemLanguage();

  const { data: { app } = {} } = useGetAppMessagesQuery({
    language,
    id
  });

  const appMessage = Object.fromEntries(
    app?.messages
      .map((e) => [e.id, e.translation.description]) ?? []
  );

  return { appMessage };
};

export default useAppMessages;
