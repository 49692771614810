import { Search as SearchIcon } from "@mui/icons-material";
import { Box, Checkbox, Tooltip, styled } from "@mui/material";
import { useGetPurchaseOrdersViewAllPageQuery } from "__generated__/graphql/types";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppSelector } from "hooks/reduxHooks";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { notNullUndefined } from "utils/notNullUndefined";
import useAllowedUserAppActions from '../../../hooks/useAlloweduserAppActions/template';
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { PurchaseOrdersRoutes } from "../routes";
import { TrafficLight } from "./components/Table/TrafficLight";
import { TrafficLightStatus } from "./components/Table/TrafficLight/template";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useViewAllPage = () => {
    
  const navigate = useNavigate();

  const { appMessage: appPurchaseOrders } = useAppMessages("PURCHASE_ORDERS");
  const { appMessage: appMessages } = useAppMessages("MESSAGES");
  const { actions } = useAllowedUserAppActions("PURCHASE_ORDERS");
  const { formatConfig } = useLanguageAndRegionConfig();
  
  const errorSnackbar = useSnackbar();
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);
  const rowsChecked = useAppSelector(selectRowsChecked);

  const {
    data: { purchaseOrders, purchaseOrdersCount: total } = {},
  } = useGetPurchaseOrdersViewAllPageQuery({
    pagination,
    filters: {
      id: filters.id,
      accountingEntityDescription: filters.accountingEntityDesc,
      vendorId: filters.vendorId,
      currency: filters.currency,
      createdAt: filters.creationDate
    },
  });

  const rows = useMemo(() => purchaseOrders?.map( dto => {
    const res: TableRowType<ColumnsKeys> = {
      rowKey: dto.id,
      id: dto.id,
      accountingEntityDesc: dto.accountingEntityDescription,
      vendorId: dto.vendorId,
      vendorName: dto.vendorName,
      total: formatConfig.amountMapper(dto.netTotalPrice),
      currency: dto.currency,
      extraCharges: (
        <Tooltip
          title={dto?.extraCharges?.toString() ?? "false"}
          placement="top"
        >
          <Box display="flex" justifyContent="center">
            <StyledCheckbox
              size="small"
              checked={dto.extraCharges ?? false} //FIXME: Asegurar en backend siempre true o false
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Tooltip>
      ),
      creationDate: formatConfig.dateMapper(dto.createdAt),
      purchaseOrderStatus: <TrafficLight 
        status={dto.purchaseOrderStatus as TrafficLightStatus} 
      />
    }
    return res;
  }), [purchaseOrders, formatConfig]);

  const {
    data: { purchaseOrders: exportToExcel } = {},
  } = useGetPurchaseOrdersViewAllPageQuery({
    filters: {
      id: filters.id,
      accountingEntityDescription: filters.accountingEntityDesc,
      vendorId: filters.vendorId,
      currency: filters.currency,
      createdAt: filters.creationDate
    },
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              id: dto.id,
              accountingEntityDesc: dto.accountingEntityDescription ?? "",
              vendorId: dto.vendorId,
              vendorName: dto.vendorName ?? "",
              total: formatConfig.amountMapper(dto.netTotalPrice),
              currency: dto.currency,
              extraCharges: dto.extraCharges.toString(),
              creationDate: formatConfig.dateMapper(dto.createdAt),
              purchaseOrderStatus: dto.purchaseOrderStatus ?? ""
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel, formatConfig]
  );

  const read = actions.find(e => e.id === "READ");

  const menuItems = useMemo(() => [
      read && {
        key: "display",
        Icon: SearchIcon,
        text: read.translation.description,
        onClick: (id: string) => {
          if(id === "display") {
            if(rowsChecked.length === 0) {
              errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
              return;
            } else if(rowsChecked.length > 1) {
              errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
              return;
            }
            id = rowsChecked.toString()
          }

          navigate(PurchaseOrdersRoutes.PATH_ITEMS, {
            state: {
              purchaseOrderId: id
            }
          })
        },
      }
    ].filter(notNullUndefined), 
    [appMessages, errorSnackbar, navigate, read, rowsChecked]);

  return {
    errorSnackbar,
    appPurchaseOrders,
    menuItems,
    rows,
    total,
    pagination,
    sheet
  }
}

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
});