import { getMyInformation } from "components/providers/ReduxMyProvider/reducer";
import { useAppSelector } from "hooks/reduxHooks";

const useAllowedUserAppActions = (appId: string) => {

  const { me } = useAppSelector(getMyInformation);
  const actions = me?.businessProfile.appsActions.filter(e => e.app.id === appId) ?? [];

  return { actions };
};

export default useAllowedUserAppActions;
