import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { useGetAppActionsForCreateBusinessProfilesQuery } from "__generated__/graphql/types";
import { Header } from "./Header";
import { Row } from "./Row";
import { ActionsFormProps } from "./types";

const ActionsForm = ({
  readOnly = false,
  onEdit,
  onFinish,
  forms,
  appId,
}: ActionsFormProps) => {
  const { language } = useSystemLanguage();

  const {
    data: { app } = {},
  } = useGetAppActionsForCreateBusinessProfilesQuery({ language, id: appId });

  const actions = app?.actions;

  if (actions === undefined) return null; // TODO: loading spinner

  return (
    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header
          onEdit={() => onEdit?.()}
          readOnly={readOnly}
          onFinish={onFinish}
        />

        <StyledTableBody>
          {actions.map((action, index) => (
            <Row
              key={action.id}
              index={index}
              readOnly={readOnly}
              appId={appId}
              action={action}
              form={forms.permissions}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default ActionsForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
