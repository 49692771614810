import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { ImagePicker } from "components/inputs/ImagePicker";
import { useAppMessages } from "hooks/useAppMessages";
import { UseFormReturn } from "react-hook-form";

export type CompanyLogoFormFields = {
  companyLogo: File | null;
};

export type CompanyLogoFormProps = {
  form: UseFormReturn<CompanyLogoFormFields>;
  readOnly?: boolean;
};

const CompanyLogoForm = ({
  form,
  readOnly = false,
}: CompanyLogoFormProps) => {
  
  const { control } = form;
  const { appMessage } = useAppMessages("MESSAGES");
  const { appMessage: themesMessages } = useAppMessages("THEMES");

  return (
    <FormNonTableBodyWrapper>
      <DevTool control={control} placement="top-left" />

      <Grid container justifyContent="center">
        <Grid item>
          <ImagePicker
            label= {themesMessages["COMPANY_LOGO"]}
            tooltipTitle={themesMessages["CHOOSE_COMPANY_LOGO"]}
            controllerProps={{
              name: "companyLogo", // react hook form no infiere que name tiene que ser keyof Step1FormFields
              control,
              rules: { required: appMessage["FIELD_REQUIRED"] ?? "Field required",}
            }}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default CompanyLogoForm;
