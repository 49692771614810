import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateUserMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { useUploadUserImageToS3 } from "./useUploadUserImageToS3";
import { mapFormToDto } from "./utils";
import useAppMessages from 'hooks/useAppMessages/hook';
import { SuccessMessageParamsProps } from "components/feedback/SuccessMessageParams/template";

const CreatePage = () => {

  const navigate = useNavigate();
  const [response, setResponse] = useState<SuccessMessageParamsProps>();

  const { mutateAsync: createUser } = useCreateUserMutation();
  const { mutateAsync: uploadUserImage } = useUploadUserImageToS3();
  const { appMessage } = useAppMessages("USERS");

  const onSave = async (form: FormFields) => {
    const { fileId } = await uploadUserImage(form.image.image);
    const user = mapFormToDto(form, fileId);
    await createUser({ user })
    .then((data) => {
      //FIXME: Traduccion
      setResponse({
        message: 'El usuario se ha creado correctamente con la contraseña: ',
        params: data.createUser
      })
    });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title={appMessage["CREATE_USER"]} 
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage={appMessage["ERROR_CREATE"]}
          onSave={onSave}
          onCancel={onCancel}
          saveMessage={response}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
