import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const addressData = useForm<FormFields["addressData"]>({
    defaultValues: initialValue?.["addressData"],
  });

  return { basicData, addressData };
};
