import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import { Box, Checkbox, Tooltip, styled } from "@mui/material";
import { GetPurchaseOrderDetailViewPageQuery, useGetPurchaseOrderDetailViewPageQuery, useGetPurchaseOrderItemsViewAllPageQuery } from "__generated__/graphql/types";
import { PurchaseOrdersItemsDirectRoutes } from "apps/PurchaseOrdersItemsDirectPage/routes";
import { usePurchaseOrderLabels } from "apps/PurchaseOrdersPage/ViewAllPage/usePurchaseOrderLabels";
import { PurchaseOrdersRoutesState } from "apps/PurchaseOrdersPage/routes";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppSelector } from "hooks/reduxHooks";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { PicConstants } from "utils/constants";
import { notNullUndefined } from "utils/notNullUndefined";
import { useLocationState } from "utils/typedRoutesUtils";
import useAllowedUserAppActions from '../../../hooks/useAlloweduserAppActions/template';
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { TrafficLight } from "./components/Table/TrafficLight";
import { TrafficLightStatus } from "./components/Table/TrafficLight/template";
import { PurchaseOrdersItemsCheckedRowsType, selectRowsChecked } from "./reducers/checkedRowsSlice";
import { selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";
import { DetailsValues } from "./template";


export const useViewAllPage = () => {
    
  const navigate = useNavigate();
  const state = useLocationState<PurchaseOrdersRoutesState>();

  // FIXME: Agregar validacion si el state es null
  const purchaseOrderId = state!.purchaseOrderId;
  
  const { detailsLabels } = usePurchaseOrderLabels();
  const { formatConfig } = useLanguageAndRegionConfig();
  const { appMessage: appMessages } = useAppMessages("MESSAGES");
  const { actions } = useAllowedUserAppActions("PURCHASE_ORDERS_ITEMS");

  const errorSnackbar = useSnackbar();
  const rowsChecked = useAppSelector(selectRowsChecked);
  const filters = useAppSelector(selectFilters);
  const pagination = useAppSelector(selectPagination);

  const { data: { purchaseOrder } = {} } 
  = useGetPurchaseOrderDetailViewPageQuery({
    id: purchaseOrderId
  });

  const { data: { purchaseOrderItems, purchaseOrderItemsCount: total  } = {} } 
  = useGetPurchaseOrderItemsViewAllPageQuery({
    purchaseOrder: purchaseOrderId,
    pagination,
    filters: {
      purchaseOrderItem: filters.purchaseOrderItem,
      productId: filters.productId,
      productDescription: filters.productDescription,
      locationDescription: filters.locationDesc,
      unit: filters.unit,
      poQuantity: filters.poQuantity,
      netUnitPrice: filters.netUnitPrice,
      netTotalPrice: filters.netTotalPrice
    }
  });

  const rows = useMemo(() => purchaseOrderItems?.map( dto => {
    const res: TableRowType<ColumnsKeys> = {
      rowKey: JSON.stringify({id: dto.purchaseOrderItem, purchaseOrder: purchaseOrderId}), 
      purchaseOrderItem: dto?.purchaseOrderItem,
      productId: dto?.productId,
      productDescription: dto?.productDescription,
      hasInvoice: (
        <Tooltip
          title={dto.hasInvoice.toString()}
          placement="top"
        >
          <Box display="flex" justifyContent="center">
            <StyledCheckbox
              size="small"
              checked={dto.hasInvoice}
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Tooltip>
      ),
      locationDesc: dto?.locationDescription,
      unit: dto.unit,
      poQuantity:  formatConfig.numberMapper(dto.poQuantity),
      netUnitPrice: formatConfig.amountDecimalsMapper(dto.netUnitPrice, PicConstants.NET_UNT_PRICE_DECIMALS),
      netTotalPrice: formatConfig.amountMapper(dto.netTotalPrice),
      grQuantity: formatConfig.numberMapper(dto.grQuantity),
      grAmount: formatConfig.amountMapper(dto.grAmount),
      picInvoicedQuantity: formatConfig.numberMapper(dto.picInvoicedQuantity),
      picInvoicedAmount: formatConfig.amountMapper(dto.picInvoicedAmount),
      invoiceApprovedQuantity: formatConfig.numberMapper(dto.invoiceApprovedQuantity),
      invoiceApprovedAmount: formatConfig.amountMapper(dto.invoiceApprovedAmount),
      needGr: (
        <Tooltip
          title={dto?.needGr?.toString() ?? "false"}
          placement="top"
        >
          <Box display="flex" justifyContent="center">
            <StyledCheckbox
              size="small"
              checked={dto.needGr ?? false} //FIXME: Asegurar en backend siempre true o false
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Tooltip>
      ),
      completeInvoice: <TrafficLight 
        status={dto!.completeInvoice! !== "COMPLETE" ? "INCOMPLETE" : "COMPLETE" as TrafficLightStatus} 
      />
    };
    return res;
  }), [purchaseOrderItems, formatConfig, purchaseOrderId]);

  const { data: { purchaseOrderItems: exportToExcel  } = {} } 
  = useGetPurchaseOrderItemsViewAllPageQuery({
    purchaseOrder: purchaseOrderId,
    filters: {
      purchaseOrderItem: filters.purchaseOrderItem,
      productId: filters.productId,
      productDescription: filters.productDescription,
      locationDescription: filters.locationDesc,
      unit: filters.unit,
      poQuantity: filters.poQuantity,
      netUnitPrice: filters.netUnitPrice,
      netTotalPrice: filters.netTotalPrice
    }
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              purchaseOrderItem: dto?.purchaseOrderItem,
              productId: dto?.productId,
              productDescription: dto?.productDescription,
              hasInvoice: dto.hasInvoice.toString(),
              locationDesc: dto?.locationDescription ?? "",
              unit: dto.unit,
              poQuantity:  formatConfig.numberMapper(dto.poQuantity),
              netUnitPrice: formatConfig.amountDecimalsMapper(dto.netUnitPrice, PicConstants.NET_UNT_PRICE_DECIMALS),
              netTotalPrice: formatConfig.amountMapper(dto.netTotalPrice),
              grQuantity: formatConfig.numberMapper(dto.grQuantity),
              grAmount: formatConfig.amountMapper(dto.grAmount),
              picInvoicedQuantity: formatConfig.numberMapper(dto.picInvoicedQuantity),
              picInvoicedAmount: formatConfig.amountMapper(dto.picInvoicedAmount),
              invoiceApprovedQuantity: formatConfig.numberMapper(dto.invoiceApprovedQuantity),
              invoiceApprovedAmount: formatConfig.amountMapper(dto.invoiceApprovedAmount),
              needGr: dto?.needGr?.toString() ?? "",
              completeInvoice: dto!.completeInvoice!
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel, formatConfig]
  );

  const read = actions.find(e => e.id === "READ");
  const createInvoice = actions.find(e => e.id === "CREATE_INVOICE");
  const { dataElementsChoices } = useDataElementChoices(["BOOLEAN"]);

  const menuItems = useMemo(
    () => [
      read && {
        key: "display",
        Icon: SearchIcon,
        text: read.translation.description,
        onClick: (rowKey: string) => {

          const itemsFiltered = getSelectRowsTable(rowsChecked, purchaseOrderId);
          let items: string[];

          if(rowKey === "display") {

            if(!itemsFiltered || itemsFiltered?.length === 0) {
              errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
              return;
            } else if(itemsFiltered?.length > 1) {
              errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
              return;
            }

            items = itemsFiltered;
          } else {

            if(itemsFiltered && itemsFiltered.length > 1) {
              errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
              return;
            }

            const { id } = JSON.parse(rowKey);
            items = [id];
          }
          
          navigate(PurchaseOrdersItemsDirectRoutes.PATH_INVOICES, {
            state: {
              toBack: TO_BACK_ROUTER,
              purchaseOrderId: purchaseOrderId,
              purchaseOrderItemsIds: items
            }
          })
        },
      },
      createInvoice && {
        key: "createInvoice",
        Icon: AddIcon,
        text: createInvoice.translation.description,
        onClick: (rowKey: string) => {

          const itemsFiltered = getSelectRowsTable(rowsChecked, purchaseOrderId);
          let items: string[];

          if(rowKey === "createInvoice") {
            if(rowsChecked.length === 0) {
              errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
              return;
            }
            items = itemsFiltered;
          } else {
            if(itemsFiltered && itemsFiltered.length > 0) {
              items = itemsFiltered;
            } else {
              const { id } = JSON.parse(rowKey);
              items = [id];
            }
          }

          navigate(PurchaseOrdersItemsDirectRoutes.PATH_INVOICES_CREATE, {
            state: {
              toBack: TO_BACK_ROUTER,
              purchaseOrderId: purchaseOrderId,
              purchaseOrderItemsIds: items
            }
          })
        },
      }
    ]
  .filter(notNullUndefined), 
  [navigate, rowsChecked, errorSnackbar, appMessages, createInvoice, read, purchaseOrderId]);  

  const mapPurchaseOrderDetail = (
    dl: Record<string, string>,
    dto: GetPurchaseOrderDetailViewPageQuery["purchaseOrder"]
  ): DetailsValues[] => {
    let ans: DetailsValues[] = [
      { 
        name: dl["vendor"], 
        value: dto?.vendorName ?? ""
      },
      { 
        name: dl["accountingEntity"], 
        value: dto?.accountingEntity
      },
      { 
        name: dl["accountingEntityDesc"], 
        value: dto?.accountingEntityDescription ?? ""
      },
      { 
        name: dl["vendorId"], 
        value: dto?.vendorId
      },
      { 
        name: dl["total"], 
        value: formatConfig.amountMapper(dto?.netTotalPrice)
      },
      { 
        name: dl["currency"], 
        value: dto?.currency
      },
      { 
        name: dl["extraCharges"], 
        value: dataElementsChoices !== undefined 
          ? dataElementsChoices["BOOLEAN"].find(e => e.value === dto?.extraCharges.toString().toLocaleUpperCase())?.text
          : dto?.extraCharges.toString()
      },
      { 
        name: dl["creationDate"], 
        value: formatConfig.dateMapper(dto?.createdAt)
      },
      { 
        name: dl["purchaseEntity"], 
        value: dto?.purchaseEntity
      }
    ];
  
    return ans;
  }

  return {
    errorSnackbar,
    purchaseOrder,
    menuItems,
    rows,
    total,
    pagination,
    sheet,
    state,
    detailsLabels,
    mapPurchaseOrderDetail
  }
}

const TO_BACK_ROUTER = "../";

const getSelectRowsTable = (
  rowsChecked: PurchaseOrdersItemsCheckedRowsType[], 
  purchaseOrder: string, 
) => {
  const rowsFiltered = rowsChecked.filter(e => e.purchaseOrder === purchaseOrder);
  return rowsFiltered.map(e => e.id);
}

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
});

