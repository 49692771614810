import { CountryIdsTypeInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): CountryIdsTypeInput => {
  return {
    countryId: form.basicData.country,
    idsTypes: form.idsTypes.idsTypes.map((id) => ({
      id: id.id,
      isTaxId: id.isTaxId,
    })),
  };
};
