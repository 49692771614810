import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateTileMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { useUploadTileIconToS3 } from "./useUploadTileIconToS3";
import { mapFormToDto } from "./utils";

const CreatePage = () => {
  const navigate = useNavigate();

  const { mutateAsync: createTable } = useCreateTileMutation();
  const { mutateAsync: uploadTileIconToS3 } = useUploadTileIconToS3();

  const onSave = async (form: FormFields) => {
    const uploadedFile =
      form.tileType.tileType.hasImage && form.icon.icon instanceof File // TODO: refactor
        ? await uploadTileIconToS3(form.icon.icon)
        : undefined;
    await createTable({ tile: mapFormToDto(form, uploadedFile?.fileId) });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title="Crear mosaico de app" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage="Error al crear app. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
