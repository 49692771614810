export enum CostCollectorRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":costCollectorId/view",
  EDIT = ":costCollectorId/edit",
}

export type CostCollectorRoutesState = {
  [CostCollectorRoutes.EDIT]: { activeStep?: number };
};
