import { Grid, MenuItem } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { CheckboxWithLabelController } from "components/inputs/CheckboxWithLabelController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { FormMode } from "types/Form";
import { convertStringIntToNumberOrUndefined } from "utils/convertStringInputToNumber";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  DataElementExistsDocument,
  DataElementExistsQuery,
  DataElementExistsQueryVariables,
} from "__generated__/graphql/types";

export const DATA_ELEMENTS_TYPES = [
  "VARCHAR",
  "INTEGER",
  "DECIMAL",
  "BOOLEAN",
  "AMOUNT",
  "QUANTITY",
  "CURRENCY",
  "UNIT",
  "LANGUAGE",
  "TIMESTAMP",
  "DATE",
  "TIME",
  "UUID",
  "CHOICE",
] as const;

export type DataElementType = typeof DATA_ELEMENTS_TYPES[number];

export type BasicDataFormFields = {
  name: string;
  dataType: DataElementType;
  isLanguageDependent?: boolean;
  length?: number;
  decimals?: number;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;

  const dataType = useWatch({ control: form.control, name: "dataType" });

  const readOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "DATA_ELEMENT",
    "DATA_ELEMENT_DATA_TYPE",
    "DATA_ELEMENT_LENGTH",
    "DATA_ELEMENT_DECIMALS",
    "DATA_ELEMENT_IS_LANGUAGE_DEPENDENT",
  ]);

  const { dataElementsChoices } = useDataElementChoices([
    "DATA_ELEMENT_DATA_TYPE",
  ]);

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner
  if (dataElementsChoices === undefined) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: readOnly }}
              label={dataElementsDescriptions["DATA_ELEMENT"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre.", // FIXME: traduccion
                  validate: async (value) => {
                    if (readOnly) return true;
                    const { dataElementExists } = await dataElementExistsFn({
                      id: value,
                    });
                    return dataElementExists
                      ? "El nombre ya existe." // FIXME: traduccion
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{
              disabled,
            }}
            label={dataElementsDescriptions["DATA_ELEMENT_DATA_TYPE"]}
            select
            controllerProps={{
              name: "dataType",
              control,
              rules: { required: "Seleccione un tipo de dato." }, // FIXME: traduccion
            }}
          >
            {dataElementsChoices["DATA_ELEMENT_DATA_TYPE"].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextFieldController>
        </Grid>

        {["VARCHAR", "CHOICE"].includes(dataType) && (
          <Grid item xs={3}>
            <TextFieldController
              inputProps={{
                disabled,
              }}
              label={dataElementsDescriptions["DATA_ELEMENT_LENGTH"]}
              type="number"
              transform={convertStringIntToNumberOrUndefined}
              controllerProps={{
                name: "length",
                control,
                rules: {
                  min: {
                    value: 1,
                    message: "El valor debe ser mayor a 0.", // FIXME: traduccion
                  },
                  max: {
                    value: 256,
                    message: "El valor debe ser menor o igual a 256.", // FIXME: traduccion
                  },
                  required: "Ingrese la longitud.", // FIXME: traduccion
                },
              }}
            />
          </Grid>
        )}

        {dataType === "VARCHAR" && (
          <Grid item xs={3}>
            <CheckboxWithLabelController
              FormControlLabelProps={{
                label:
                  dataElementsDescriptions[
                    "DATA_ELEMENT_IS_LANGUAGE_DEPENDENT"
                  ],
              }}
              controllerProps={{
                name: "isLanguageDependent",
                control,
                defaultValue: false,
              }}
              readOnly={disabled}
            />
          </Grid>
        )}

        {["CURRENCY", "UNIT"].includes(dataType) && (
          <Grid item xs={3}>
            <TextFieldController
              inputProps={{
                disabled,
              }}
              label={dataElementsDescriptions["DATA_ELEMENT_LENGTH"]}
              type="number"
              transform={convertStringIntToNumberOrUndefined}
              controllerProps={{
                name: "length",
                control,
                rules: { required: true }, // FIXME: traduccion
                defaultValue: ("5" as unknown) as number, // FIXME:
              }}
            />
          </Grid>
        )}

        {["DECIMAL", "AMOUNT", "QUANTITY"].includes(dataType) && (
          <Grid item xs={3}>
            <TextFieldController
              inputProps={{
                disabled,
              }}
              label={dataElementsDescriptions["DATA_ELEMENT_DECIMALS"]}
              type="number"
              transform={convertStringIntToNumberOrUndefined}
              controllerProps={{
                name: "decimals",
                control,
                rules: {
                  min: {
                    value: 1,
                    message: "El valor debe ser mayor a 0.", // FIXME: traduccion
                  },
                  max: {
                    value: 20,
                    message: "El valor debe ser menor o igual a 20.", // FIXME: traduccion
                  },
                  required: "Ingrese la cantidad de decimales.", // FIXME: traduccion
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const dataElementExistsFn = pDebounce(
  (args: DataElementExistsQueryVariables) =>
    fetcher<DataElementExistsQuery, DataElementExistsQueryVariables>(
      DataElementExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
