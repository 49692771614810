import { DevTool } from "@hookform/devtools";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import React from "react";
import { DevelopKpiFormProps } from "./types";

const DevelopKpiForm = ({
  form,
  readOnly = false,
  onEdit,
}: DevelopKpiFormProps) => {
  const { control } = form;

  const { dataElementsDescriptions } = useDataElementDescription([]);

  form.setError("develop", { message: "TODO: implementar" });

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool placement="top-left" control={control} />
      TODO: implementar
    </FormNonTableBodyWrapper>
  );
};

export default DevelopKpiForm;
