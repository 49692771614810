import { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { FormTableTextField } from "./FormTableField";

type FormTableTextFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<MuiTextFieldProps, "value" | "onChange"> & {
  controllerProps: UseControllerProps<TFieldValues, TName>;
};

const FormTableTextFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  controllerProps,
  ...textFieldProps
}: FormTableTextFieldControllerProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>(controllerProps);

  return (
    <FormTableTextField
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      error={invalid}
      helperText={error?.message}
      {...textFieldProps}
    />
  );
};

export default FormTableTextFieldController;
