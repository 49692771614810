import { styled, TableCell, TableRow } from "@mui/material";
import React from "react";

const TableHeader = () => {
  return (
    <>
      <StyledTableRow>
        <StyledHeaderTableCell
          align="center"
          style={{
            width: 1, // FIXME: esta es la unica manera que encontrar para hacer que el ancho de la columna sea igual al icono
          }}
        >
          # {/* FIXME: // TRADUCCION  */}
        </StyledHeaderTableCell>

        <StyledHeaderTableCell>
          TIMESTAMP {/* FIXME: // TRADUCCION  */}
        </StyledHeaderTableCell>
      </StyledTableRow>
    </>
  );
};

export default TableHeader;

const StyledTableRow = styled(TableRow)({
  "& th:not(:first-child):not(:last-child)": {
    width: "1%",
  },
});

const StyledHeaderTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
});
