import {
  AddCircle as AddCircleIcon,
  Edit as EditCircleIcon
} from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { HeaderProps } from "./types";

const Header = ({ 
  readOnly = false, 
  onEdit, 
  onAppend,
}: HeaderProps) => {

  const { dataElementsDescriptions } = useDataElementDescription([
    "STEP_NUMBER",
    "STEP_NAME",
    "TARGET",
    "INSTRUCTION_STYLE",
    "ENABLED_CLICK",
    "DESCRIPTION",
  ]);

  const { appMessage } = useAppMessages("MESSAGES");

  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <StyledTableHead>
      <TableRow>

        {/* FIXME: Style */}
        <StyledHeadTableCell align="left" style={{maxWidth: "100px"}}>
          {dataElementsDescriptions["STEP_NUMBER"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["STEP_NAME"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["TARGET"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["INSTRUCTION_STYLE"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["ENABLED_CLICK"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["DESCRIPTION"]}
        </StyledHeadTableCell>

        {/* FIXME: Style */}
        <TableCell align="right" style={{minWidth: "100px"}}>
          {readOnly ? (
            <Tooltip
              title={appMessage["EDIT"] ?? "Edit"}
            >
              <IconButton aria-label="edit" size="small" onClick={onEdit}>
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip
                title={appMessage["ADD_ROW"] ?? "Add row"}
              >
                <IconButton aria-label="add" size="small" onClick={onAppend}>
                  <StyledAddCircleIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
