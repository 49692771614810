export function scrollElement(element) {
    let elementToScroll = window.document.getElementById(element);
    elementToScroll.setAttribute('tabindex', '-1');
    elementToScroll.focus();
    elementToScroll.removeAttribute('tabindex');
}

export function clickElement(element) {
    let elementToScroll = window.document.getElementById(element);
    elementToScroll.click();
}

export default scrollElement;