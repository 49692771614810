import CreateInvoicePage from "apps/InvoicesPage/CreatePage/template";
import { EditInvoicePage } from "apps/InvoicesPage/EditPage";
import { ViewAllInvoicesPage } from "apps/InvoicesPage/ViewAllPage";
import { ViewInvoicePage } from "apps/InvoicesPage/ViewPage";
import { Route, Routes } from "react-router-dom";
import { InvoicesFreightRoutes } from './routes';
import { ViewAllPage } from "./ViewAllPage";

const InvoicesFreightPage = () => {
  return (
    <Routes>
      <Route
        path={InvoicesFreightRoutes.PATH}
        element={<ViewAllPage />}
      />
      <Route 
        path={InvoicesFreightRoutes.PATH_FREIGHT} 
        element={<ViewAllPage />} 
      />
      <Route 
        path={InvoicesFreightRoutes.PATH_INVOICES} 
        element={<ViewAllInvoicesPage />} 
      />
      <Route 
        path={InvoicesFreightRoutes.PATH_INVOICES_VIEW} 
        element={<ViewInvoicePage />} 
      />
      <Route 
        path={InvoicesFreightRoutes.PATH_INVOICES_EDIT} 
        element={<EditInvoicePage />} 
      />
      <Route 
        path={InvoicesFreightRoutes.PATH_INVOICES_CREATE} 
        element={<CreateInvoicePage />} 
      />
    </Routes>
  );
};

export default InvoicesFreightPage;
