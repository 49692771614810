import { DevTool } from "@hookform/devtools";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  AddCircle as AddCircleIcon,
  Clear as ClearIcon,
  Edit as EditCircleIcon,
} from "@mui/icons-material";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { SelectController } from "components/inputs/SelectController";
import { useForceUpdate } from "hooks/useForceUpdate";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { DataElementType } from "apps/DataElementsPage/CreatePage/Form/BasicDataForm/template";
import React, { useEffect } from "react";
import {
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  GetDataElementsForCreateTableQuery,
  useGetDataElementsForCreateTableQuery,
} from "__generated__/graphql/types";
import { DataElementMatchcode } from "../CharacteristicsColumnsForm/Row/DataElementMatchcode/template";
import { generateDataElementDescription } from "../utils";
import {
  useCharacteristicColumnsNames,
  useCharacteristicsColumnsOptions,
  useRatiosDataElements,
} from "./hooks";
import {
  Option,
  RatioColumn,
  RatiosColumnsFormFields,
  RatiosColumnsTableFormProps,
} from "./types";
import { useDataElementDescription } from "hooks/useDataElementDescription";

export const emptyRatioRow: RatioColumn = {
  name: "",
  dataElementId: "",
};

const RatiosColumnsForm = ({
  form,
  readOnly = false,
  onEdit,
  characteristicsForm,
}: RatiosColumnsTableFormProps) => {
  const { language } = useSystemLanguage();

  const {
    data: { dataElements } = {},
  } = useGetDataElementsForCreateTableQuery({ language }); // TODO: filtro ratios

  const { dataElementsDescriptions } = useDataElementDescription([]);

  const { ratiosDataElements } = useRatiosDataElements(dataElements);

  const characteristicsColumns = useWatch({
    control: characteristicsForm.control,
    name: "characteristics",
  });

  const { characteristicsColumnsNames } = useCharacteristicColumnsNames(
    characteristicsColumns
  );

  const {
    amountCharacteristicsOptions,
    quantityCharacteristicsOptions,
  } = useCharacteristicsColumnsOptions(characteristicsColumns, dataElements);

  const { control } = form;

  const { fields, append, remove } = useFieldArray<RatiosColumnsFormFields>({
    control,
    name: "ratios",
  });

  const appendRow = () => {
    append(emptyRatioRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  if (ratiosDataElements === undefined) return null; // TODO: loading spinner
  if (amountCharacteristicsOptions === undefined) return null; // TODO: loading spinner
  if (quantityCharacteristicsOptions === undefined) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <TableContainer>
        <StyledTable aria-label="simple table" size="small">
          <StyledTableHead>
            <TableRow>
              <StyledHeadTableCell align="left">
                {dataElementsDescriptions["TABLE_COLUMN_NAME"]}
              </StyledHeadTableCell>

              <StyledHeadTableCell align="left">
                {dataElementsDescriptions["DATA_ELEMENT"]}
              </StyledHeadTableCell>

              <StyledHeadTableCell align="left">
                {dataElementsDescriptions["DESCRIPTION"]}
              </StyledHeadTableCell>

              <StyledHeadTableCell align="left">
                {dataElementsDescriptions["DATA_ELEMENT_DATA_TYPE"]}
              </StyledHeadTableCell>

              <StyledHeadTableCell align="left">
                {dataElementsDescriptions["TABLE_COLUMN_REFERENCE"]}
              </StyledHeadTableCell>

              <TableCell align="right">
                {readOnly ? (
                  <Tooltip
                    title="Editar" // FIXME: traduccion
                  >
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => onEdit?.()}
                    >
                      <StyledEditCircleIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Agregar fila" // FIXME: traduccion
                  >
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={appendRow}
                    >
                      <StyledAddCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </StyledTableHead>

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                key={item.id}
                index={index}
                remove={onRemove(index)}
                item={item}
                readOnly={readOnly}
                dataElementsMap={ratiosDataElements}
                amountCharacteristicsOptions={amountCharacteristicsOptions}
                quantityCharacteristicsOptions={quantityCharacteristicsOptions}
                characteristicsColumnsNames={characteristicsColumnsNames}
                form={form}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

export default RatiosColumnsForm;

type RowProps = {
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<RatiosColumnsFormFields>["fields"][number];
  dataElementsMap: Map<
    string,
    NonNullable<
      NonNullable<GetDataElementsForCreateTableQuery["dataElements"]>[number]
    >
  >;
  amountCharacteristicsOptions: Option[];
  quantityCharacteristicsOptions: Option[];
  characteristicsColumnsNames: string[];
  form: UseFormReturn<RatiosColumnsFormFields>;
};

const Row = ({
  index,
  remove,
  item,
  readOnly,
  dataElementsMap,
  amountCharacteristicsOptions,
  quantityCharacteristicsOptions,
  characteristicsColumnsNames,
  form,
}: RowProps) => {
  const { control, watch, trigger, unregister, getValues } = form;

  const { forceUpdate } = useForceUpdate();

  const dataElementId = watch(
    `ratios.${index}.dataElementId` as "ratios.0.dataElementId"
  );

  const dataElement = dataElementsMap.get(dataElementId);

  const dataElements = Array.from(dataElementsMap.values());

  const isReferenceVisible =
    dataElement?.dataType !== undefined &&
    ["QUANTITY", "AMOUNT"].includes(dataElement?.dataType as DataElementType);

  useEffect(() => {
    if (isReferenceVisible) return;
    unregister(`ratios.${index}.reference` as "ratios.0.reference");
  }, [index, isReferenceVisible, unregister]);

  useEffect(() => {
    forceUpdate(); // FIXME: dataElement no se actualiza sin esto
  }, [dataElementId, forceUpdate]);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <KeyInputWrapper>
          <FormTableTextFieldController
            inputProps={{ disabled: readOnly }}
            controllerProps={{
              name: `ratios.${index}.name` as "ratios.0.name",
              control,
              rules: {
                required: "Ingrese el nombre de la columna.", // FIXME: traduccion
                pattern: {
                  value: keyNameInputRegexPattern,
                  message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                },
                validate: (value) => {
                  const ratiosColumnsNames = getValues("ratios")
                    .filter((_, idx) => idx !== index)
                    .map((e) => e.name);
                  return (
                    [...ratiosColumnsNames, ...characteristicsColumnsNames]
                      // TODO: usar hashmap en vez de lista
                      .includes(value)
                      ? "El nombre ya ha sido utilizado." // FIXME: traduccion
                      : true
                  );
                },
              },
              defaultValue: item.name,
            }}
          />
        </KeyInputWrapper>
      </TableCell>

      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`ratios.${index}.dataElementId` as "ratios.0.dataElementId"}
          rules={{ required: "Seleccione un elemento de dato." }} // FIXME: traduccion
          defaultValue={item.dataElementId}
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <DataElementMatchcode
              dataElementsOptions={dataElements}
              value={value}
              onChange={onChange}
              invalid={invalid}
              errorMessage={error?.message}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {dataElement?.translation?.description}
      </TableCell>

      <TableCell component="th" scope="row">
        {dataElement && generateDataElementDescription(dataElement)}
      </TableCell>

      <TableCell component="th" scope="row">
        {isReferenceVisible ? (
          <SelectController
            inputProps={{ disabled: readOnly }}
            controllerProps={{
              name: `ratios.${index}.reference` as "ratios.0.reference",
              control,
              rules: { required: "Ingrese la referencia." }, // FIXME: traduccion
              defaultValue: item.reference,
            }}
            options={
              dataElement?.dataType === "AMOUNT"
                ? amountCharacteristicsOptions
                : dataElement?.dataType === "QUANTITY"
                ? quantityCharacteristicsOptions
                : []
            }
          />
        ) : (
          "-"
        )}
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
