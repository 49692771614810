import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetTutorialByIdQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { TutorialsRoutes } from '../routes';
import { mapTutorialDtoToForm } from './utils';

const ViewPage = () => {

  const navigate = useNavigate();

  const { tutorialId } = useRouteParams<TutorialsRoutes.PATH_VIEW>();
  const { appMessage } = useAppMessages("TUTORIALS");

  const {
    data: { tutorial } = {},
    isFetchedAfterMount,
  } = useGetTutorialByIdQuery({
    id: tutorialId,
  });

  const initialValue =
    tutorial != null && isFetchedAfterMount
      ? mapTutorialDtoToForm(tutorial)
      : undefined;

  const onEdit = (activeStep: number) => {
    navigate(
      `../../${generatePath(TutorialsRoutes.PATH_EDIT, {
        tutorialId,
      })}`,
      { state: { activeStep } }
    );
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["VIEW_TUTORIAL"]}
          toBack="../../"
        />

        <Form mode="view" initialValue={initialValue} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;
