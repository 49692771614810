import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  ChartOfAccountExistsDocument,
  ChartOfAccountExistsQuery,
  ChartOfAccountExistsQueryVariables,
} from "__generated__/graphql/types";

export type BasicDataFormFields = {
  name: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;

  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "CHARTOFACCOUNT",
  ]);

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["CHARTOFACCOUNT"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre.", // FIXME: traduccion
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const {
                      chartOfAccountExists,
                    } = await chartOfAccountExistsFn({
                      id: value,
                    });
                    return chartOfAccountExists
                      ? "El nombre ya existe." // FIXME: traduccion
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const chartOfAccountExistsFn = pDebounce(
  (args: ChartOfAccountExistsQueryVariables) =>
    fetcher<ChartOfAccountExistsQuery, ChartOfAccountExistsQueryVariables>(
      ChartOfAccountExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
