import { DevTool } from "@hookform/devtools";
import { FormControlLabel, Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { CheckboxController } from "components/inputs/CheckboxController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import {
  GetUsersForCreatingEmployeeQuery,
  useGetUserForCreatingEmployeeQuery,
  useGetUsersForCreatingEmployeeQuery,
} from "__generated__/graphql/types";

export type ContactDataFormFields = {
  _hasUser?: boolean;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  contact: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
};

export type ContactDataFormProps = {
  form: UseFormReturn<ContactDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const ContactDataForm = ({
  form,
  mode,
  isUsed,
  onEdit,
}: ContactDataFormProps) => {
  const { control, watch } = form;

  const [initialId] = useState(form.getValues().user?.id);

  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const hasUser = watch("_hasUser");

  const { data: { users } = {} } = useGetUsersForCreatingEmployeeQuery({
    filters: {
      uniqueUserForEmployee: true,
    },
  });

  const { data: { user } = {} } = useGetUserForCreatingEmployeeQuery({
    id: initialId,
  });

  const selectedUserId = watch("user", form.getValues().user);

  const { dataElementsDescriptions } = useDataElementDescription([
    "USER_ID",
    "USER_FIRSTNAME",
    "USER_LASTNAME",
    "USER_PHONE_NUMBER",
    "USER_EMAIL",
  ]);

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner
  if (users === undefined) return null;

  const usersOptions = user
    ? [...users.map(mapUsersToOption), mapUsersToOption(user)]
    : users.map(mapUsersToOption);

  const selectedUser = usersOptions?.find(
    (user) => user?.id === selectedUserId?.id
  );

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <DevTool control={control} placement="top-left" />
     
        <Grid item xs={3}   >
          <FormControlLabel
            checked={form.getValues()._hasUser}
            control={
              <CheckboxController
                controllerProps={{
                  name: "_hasUser",
                  control,
                  defaultValue: form.getValues()._hasUser,
                }}
                readOnly={disabled}
              />
            }
            label="Ya tiene usuario"
          />
        </Grid>

      <Grid container spacing={2} pb={1} pt={2}>
        {hasUser && (
      
          <Grid item xs={2}>
            <MatchcodeInputController
              label={dataElementsDescriptions["USER_ID"]}
              controllerProps={{
                name: "user.id",
                control,
                rules: { required: "Select User." },
              }}
              readOnly={disabled}
              column={[
                {
                  key: "user",
                  text: dataElementsDescriptions["USER_ID"],
                },
                {
                  key: "email",
                  text: dataElementsDescriptions["USER_EMAIL"],
                },
              ]}
              displayColumn="user"
              options={usersOptions}
            />
          </Grid>
        )}

        {hasUser && (
          <>
            <Grid item xs={2}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{
                    disabled: true,
                    value: selectedUser?.firstName ?? "",
                  }}
                  label={dataElementsDescriptions["USER_FIRSTNAME"]}
                  controllerProps={{
                    name: "user.firstName",
                    control,
                  }}
                />
              </KeyInputWrapper>
            </Grid>
            <Grid item xs={2}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{
                    disabled: true,
                    value: selectedUser?.lastName ?? "",
                  }}
                  label={dataElementsDescriptions["USER_LASTNAME"]}
                  controllerProps={{
                    name: "user.lastName",
                    control,
                  }}
                />
              </KeyInputWrapper>
            </Grid>
            <Grid item xs={3}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{
                    disabled: true,
                    value: selectedUser?.email ?? "",
                  }}
                  label={dataElementsDescriptions["USER_EMAIL"]}
                  controllerProps={{
                    name: "user.email",
                    control,
                  }}
                />
              </KeyInputWrapper>
            </Grid>
            <Grid item xs={3}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{
                    disabled: true,
                    value: selectedUser?.phoneNumber ?? "",
                  }}
                  label={dataElementsDescriptions["USER_PHONE_NUMBER"]}
                  controllerProps={{
                    name: "user.phone",
                    control,
                  }}
                />
              </KeyInputWrapper>
            </Grid>
          </>
        )}

        {!hasUser && (
          <>
            <Grid item xs={3}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{ disabled: disabled }}
                  label={dataElementsDescriptions["USER_FIRSTNAME"]}
                  controllerProps={{
                    name: "contact.firstName",
                    control,
                    rules: {
                      required: "Ingrese el nombre.", // FIXME: traduccion
                    },
                  }}
                />
              </KeyInputWrapper>
            </Grid>
            <Grid item xs={3}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{ disabled: disabled }}
                  label={dataElementsDescriptions["USER_LASTNAME"]}
                  controllerProps={{
                    name: "contact.lastName",
                    control,
                    rules: {
                      required: "Ingrese el apellido.", // FIXME: traduccion
                    },
                  }}
                />
              </KeyInputWrapper>
            </Grid>
            <Grid item xs={3}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{ disabled: disabled }}
                  label={dataElementsDescriptions["USER_EMAIL"]}
                  controllerProps={{
                    name: "contact.email",
                    control,
                    rules: {
                      required: "Ingrese el email.", // FIXME: traduccion
                    },
                  }}
                />
              </KeyInputWrapper>
            </Grid>
            <Grid item xs={3}>
              <KeyInputWrapper>
                <TextFieldController
                  inputProps={{ disabled: disabled }}
                  label={dataElementsDescriptions["USER_PHONE_NUMBER"]}
                  controllerProps={{
                    name: "contact.phone",
                    control,
                    rules: {
                      required: "Ingrese el telefono.", // FIXME: traduccion
                    },
                  }}
                />
              </KeyInputWrapper>
            </Grid>
          </>
        )}
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

const mapUsersToOption = (
  dto: NonNullable<GetUsersForCreatingEmployeeQuery["users"]>[number]
) => ({
  id: dto.id.toUpperCase(),
  user: dto.id.toUpperCase(),
  email: dto.email,
  firstName: dto.firstName,
  lastName: dto.lastName,
  phoneNumber: dto.phoneNumber
});

export default ContactDataForm;
