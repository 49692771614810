import { isDefined } from "utils/isDefined";
import { BusinessProfileInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): BusinessProfileInput => {
  return {
    id: form.basicData.name,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
    groupsIds: form.groups.groups.map(({ groupId }) => groupId),
    appsActionsIds: Object.entries(form.permissions.apps)
      .map(([appId, { actions }]) => {
        const actionsIds = Object.entries(actions)
          .filter(([, { isAllowed }]) => isAllowed)
          .reduce(
            (accActionsIds, [actionId]) => [...accActionsIds, actionId],
            [] as string[]
          );
        if (actionsIds.length === 0) return undefined;
        return { appId, actionsIds };
      })
      .filter(isDefined),
  };
};
