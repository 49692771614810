import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import { MatchcodeDisplayInputInTableRow } from "components/inputs/matchcode/MatchcodeDisplayInputInTableRow";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import { BusinessObjectDto } from "../types";

export type GroupsMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  bussinesGroup: BusinessObjectDto[];
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
};

export const GroupsMatchcode = ({
  value,
  onChange,
  bussinesGroup,
  invalid,
  errorMessage,
  readOnly = false,
}: GroupsMatchcodeProps) => {
  const options = bussinesGroup?.map((e) => ({
    id: e.id,
    name: e.id,
  }));

  const {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    value,
    onChange,
    searchKeys: ["name"],
  });

  return (
    <>
      <KeyInputWrapper>
        <MatchcodeDisplayInputInTableRow
          text={text?.name ?? ""}
          isDeleteButtonVisible={isDeleteButtonVisible}
          onClearValue={onClearValue}
          onOpenDialog={dialog.open}
          error={invalid}
          helperText={errorMessage}
          readOnly={readOnly}
        />
      </KeyInputWrapper>

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "name", text: "NOMBRE" }, // FIXME: traduccion
            ]}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default GroupsMatchcode;
