import {
  Box,
  styled,
  Table as MuiTable,
  TableBody,
  TableHead,
} from "@mui/material";
import React from "react";
import { TableHeader } from "./TableHeader";
import { TablePagination } from "./TablePagination";
import { TablePaginationProps } from "./TablePagination/template";
import { TableRow } from "./TableRow";

export type MigrationTableRow = {
  key: string;
  version: number;
  timestamp: Date;
  downloadUrl: string;
  fileName: string;
};

export type TableProps = {
  rows: MigrationTableRow[];
  rowsPerPage: number;
  totalRows: number;
} & Pick<TablePaginationProps, "onPrevPage" | "onNextPage" | "page">;

const MigrationsTable = ({
  rows,
  page,
  rowsPerPage,
  totalRows,
  onPrevPage,
  onNextPage,
}: TableProps) => {
  const isPrevButtonDisabled = page === 0;
  const isNextButtonDisabled = totalRows <= (page + 1) * rowsPerPage;

  return (
    <>
      <TableWrapper>
        <MuiTable aria-label="table" size="medium" stickyHeader>
          <StyledTableHead>
            <TableHeader />
          </StyledTableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.key}
                downloadUrl={row.downloadUrl}
                fileName={row.fileName}
                timestamp={row.timestamp}
                version={row.version}
              />
            ))}
          </TableBody>
        </MuiTable>
      </TableWrapper>

      <Box mt={1}>
        <TablePagination
          page={page}
          isPrevButtonDisabled={isPrevButtonDisabled}
          isNextButtonDisabled={isNextButtonDisabled}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
        />
      </Box>
    </>
  );
};

export default MigrationsTable;

export const TableWrapper = styled(Box)(({ theme }) => ({
  // borderTopLeftRadius: theme.shape.wrapperBorderRadius,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-thumb": {
    height: "6px",
    border: "4px solid transparent",
    backgroundClip: "padding-box",
    borderRadius: "7px",
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  "&::-webkit-scrollbar-corner": {
    backgroundColor: "transparent",
  },
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-stickyHeader": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));
