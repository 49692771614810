import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetGroupForViewPageQuery,
  useUpdateGroupMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { useUploadGroupIconToS3 } from "../CreatePage/useUploadTileIconToS3";
import { mapFormToDto } from "../CreatePage/utils";
import { GroupsRoutes } from "../routes";
import { mapDtoToForm } from "../ViewPage/util";

const EditPage = () => {
  const [initialValues, setInitialValues] = useState<FormFields>();

  const navigate = useNavigate();

  const { groupId } = useRouteParams<GroupsRoutes.EDIT>();

  const {
    data: { group } = {},
    isFetchedAfterMount,
  } = useGetGroupForViewPageQuery({
    id: groupId,
  });

  const { mutateAsync: updateTile } = useUpdateGroupMutation();

  const { mutateAsync: uploadGroupIconToS3 } = useUploadGroupIconToS3();

  const onSave = async (form: FormFields) => {
    const { fileId } = await uploadGroupIconToS3(form.icon.icon);
    const group = mapFormToDto(form, fileId);
    await updateTile({ id: groupId, group });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  useEffect(() => {
    if (group === undefined) return;
    if (group === null) return;
    if (!isFetchedAfterMount) return;
    mapDtoToForm(group).then((v) => setInitialValues(v));
  }, [group, isFetchedAfterMount]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Editar grupo de mosaicos" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          initialValue={initialValues}
          errorMessage="Error al editar grupo de mosaicos. Vuelva a intentar." // FIXME: traduccion
          mode="edit"
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
