import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetBusinessPartnerViewPageQuery,
  useUpdateBusinessPartnerMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { BusinessPartnersRoutes, BusinessPartnersRoutesState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";
import useAppMessages from 'hooks/useAppMessages/hook';

const EditPage = () => {
  const { businessPartnerId } = useRouteParams<BusinessPartnersRoutes.VIEW>();

  const { activeStep } =
    useLocationState<BusinessPartnersRoutesState[BusinessPartnersRoutes.EDIT]>() ?? {};

  const navigate = useNavigate();

  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");
  const { mutateAsync: editTable } = useUpdateBusinessPartnerMutation();

  const { data: { businessPartner } = {}, isFetchedAfterMount } = useGetBusinessPartnerViewPageQuery({
    id: businessPartnerId,
  });

  const initialValue =
    businessPartner != null && isFetchedAfterMount ? mapDtoToForm(businessPartner) : undefined;

  const onSave = async (form: FormFields) => {
    await editTable({ id: businessPartnerId, businessPartner: mapFormToDto(form) });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title= {appMessage["CREATE_BP"] ??" Create business partner"}///FIXPRD QUITAR
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValue}
          initialStep={activeStep}
          errorMessage= {appMessage["ERROR_EDIT"] ??"Error editing trading partner. Please try again."} ///FIXPRD QUITAR
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
