import { DevTool } from "@hookform/devtools";
import React from "react";
import { CostNamesForm } from "./CostNamesForm";
import { CostFormProps } from "./types";

const CostForm = ({ form, readOnly, onEdit }: CostFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />
      <CostNamesForm form={form} readOnly={readOnly} onEdit={onEdit} />
    </>
  );
};

export default CostForm;
