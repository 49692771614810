export enum MessagesRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":messageId/view",
  EDIT = ":messageId/edit",
}

export type MessagesRoutesState = {
  [MessagesRoutes.EDIT]: { activeStep?: number };
};
