import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import { MatchcodeDisplayInput } from "components/inputs/matchcode/MatchcodeInputController/MatchcodeDisplayInput";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import { PurchaseOrderInformation } from "../../template";

export type GroupsMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  invoices: PurchaseOrderInformation[];
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  text?:string;
  dataElementsDescriptions: Record<string, string>;
};

export const GroupsMatchcode = ({
  value,
  onChange,
  invoices,
  invalid,
  errorMessage,
  readOnly = false,
  text,
  dataElementsDescriptions
}: GroupsMatchcodeProps) => {
  
  const options = invoices
  .map((e) => ({
    id: JSON.stringify({ 
      purchaseOrder: e.purchaseOrder, 
      purchaseOrderItem: e.purchaseOrderItem
    }),
    purchaseOrder: e.purchaseOrder,
    purchaseOrderItem: e.purchaseOrderItem,
  }));

  const {
    dialog,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    value,
    onChange,
    searchKeys: ["purchaseOrder", "purchaseOrderItem"],
  });

  return (
    <>
      <MatchcodeDisplayInput
        label={dataElementsDescriptions["DELIVERY"] ?? "DELIVERY"}
        name={dataElementsDescriptions["DELIVERY"] ?? "DELIVERY"}
        text={text ?? ""}
        isDeleteButtonVisible={isDeleteButtonVisible}
        onClearValue={onClearValue}
        onOpenDialog={dialog.open}
        error={invalid}
        helperText={errorMessage}
        readOnly={readOnly}
      />

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "purchaseOrderItem", text: dataElementsDescriptions["DELIVERY"] ?? "DELIVERY" },
              { key: "purchaseOrder", text: dataElementsDescriptions["PURCHASE_ORDER"] ?? "PURCHASE" },
            ]}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default GroupsMatchcode;
