import { Checkbox, CheckboxProps } from "@mui/material";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

export type CheckboxControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  CheckboxProps?: Omit<CheckboxProps, "name" | "checked" | "onChange">; // FIXME:
  controllerProps: UseControllerProps<TFieldValues, TName>;
  transform?: (v: string) => unknown;
  readOnly?: boolean;
};

const CheckboxController = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  controllerProps,
  transform = (v) => v,
  CheckboxProps,
  readOnly,
}: CheckboxControllerProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange },
  } = useController(controllerProps);

  // TODO: disabled
  return (
    <Checkbox
      name={name}
      // @ts-ignore FIXME:
      checked={value ?? false}
      onChange={onChange}
      color="primary"
      inputProps={{ disabled: readOnly }}
      {...CheckboxProps}
    />
  );
};

export default CheckboxController;
