import { LoadingPage } from "components/layout/LoadingPage";
import { AppRoutes } from "pages/AppRoutes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAmIAuthenticatedQuery, useGetMyPasswordUpdatedQuery } from "__generated__/graphql/types";

type Props = {
  children: JSX.Element;
};

const PageProtectedUserLoggedIn = ({ children }: Props) => {

  const { data: { amIAuthenticated } = {} } = useGetAmIAuthenticatedQuery();
  const { data: { me } = {} } = useGetMyPasswordUpdatedQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (amIAuthenticated === undefined) return;
    if (!amIAuthenticated) {
      navigate(AppRoutes.LOGIN);
    }
    if (me === undefined) return;
    if (!me?.isPasswordUpdated) {
      navigate(AppRoutes.LOGIN);
    }
  }, [amIAuthenticated, me, navigate]);

  return amIAuthenticated ? children : <LoadingPage />;
};

export default PageProtectedUserLoggedIn;
