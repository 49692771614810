import { TableCell, TableRow } from "@mui/material";
import { CheckboxController } from "components/inputs/CheckboxController";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import { UseFormReturn, useWatch } from "react-hook-form";
import { BusinessObjectsFormFields } from "../../types";

export type RowProps = {
  readOnly: boolean;
  index: number;
  item: any;
  formMethods: UseFormReturn<BusinessObjectsFormFields>;
  externalIndex: number;
};

const Row = ({
  index,
  item,
  readOnly,
  formMethods,
  externalIndex,
}: RowProps) => {;

  const { control } = formMethods;
  const businnessObjectId = item;

  const isAllowed =
  useWatch({
    control: formMethods.control,
    name: `businessObjects.${externalIndex}.idsBusinessObject.${businnessObjectId}.isAllowed` as "businessObjects.0.idsBusinessObject.0.isAllowed",
  }) ?? false;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <CheckboxController
          controllerProps={{
            name: `businessObjects.${externalIndex}.idsBusinessObject.${businnessObjectId}.isAllowed` as "businessObjects.0.idsBusinessObject.0.isAllowed",
            defaultValue: isAllowed,
            control,
          }}
          CheckboxProps={{
            inputProps: { disabled: readOnly },
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextField
          value={item}
          inputProps={{ disabled: true }}
        />
      </TableCell>
      <TableCell component="th" scope="row"></TableCell>
    </TableRow>
  );
};

export default Row;
