import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useFieldArray } from "react-hook-form";
import { instructionDescriptionEmptyRow } from "../InstructionsDescriptionsForm/template";
import { Instruction, InstructionsFormFields } from "../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { InstructionsDataFormProps } from "./types";

export const instructionEmptyRow: Instruction = {
  stepNumber: 0,
  stepName: "",
  enabledClick: false,
  style: "CENTER",
  target: "",
  descriptions: [instructionDescriptionEmptyRow],
};

const InstructionsDataForm = ({
  onEdit,
  onEnterDescriptions,
  form,
  mode
}: InstructionsDataFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray<InstructionsFormFields>({
    control,
    name: "instructions",
  });

  const appendRow = () => {
    append(instructionEmptyRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header
          onAppend={() => appendRow()}
          onEdit={() => onEdit?.()}
          readOnly={mode === "view"}
        />

        <StyledTableBody>
          {fields.map((item, index) => (
            <Row
              key={item.id}
              index={index}
              remove={onRemove(index)}
              // @ts-ignore FIXME:
              item={item}
              readOnly={mode === "view"}
              onEnterDescriptions={onEnterDescriptions}
              form={form}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default InstructionsDataForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
