import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState } from "utils/typedRoutesUtils";
import { useGetInvoiceForViewPageQuery, useGetInvoiceItemsForViewPageQuery, useUpdateInvoiceMutation } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { useUploadInvoiceToS3 } from "../CreatePage/useUploadInvoiceToS3";
import { mapFormToUpdateDto } from "../CreatePage/utils";
import { InvoicesRoutesState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";


const EditInvoicePage = () => {

  const navigate = useNavigate();
  const state = useLocationState<InvoicesRoutesState>();

  const [initialValues, setInitialValues] = useState<FormFields>();
  const { appMessage } = useAppMessages("INVOICES");

  // FIXME: Agregar validacion si el state es null
  const activeStep = state!.activeStep ?? 0; //FIXME: Validación 0
  const toBack = state!.toBack;
  const invoiceId = state!.invoiceNumber;
  const purchaseOrderId = state!.purchaseOrderId;

  const { mutateAsync: editInvoice } = useUpdateInvoiceMutation();
  const { mutateAsync: uploadInvoiceFile } = useUploadInvoiceToS3();
  
  const {
    data: { invoice } = {},
    isFetchedAfterMount,
  } = useGetInvoiceForViewPageQuery({
    id: invoiceId,
  });

  const {
    data: { invoiceItem } = {},
  } = useGetInvoiceItemsForViewPageQuery({
    invoiceNumber: invoiceId,
  });

  useEffect(() => {

    if (invoice == null) return;
    if (invoiceItem == null) return;
    if (!isFetchedAfterMount) return;

    mapDtoToForm(
      invoice, 
      invoiceItem, 
      purchaseOrderId, 
    ).then((v) => setInitialValues(v));
        
  }, [invoice, invoiceItem, purchaseOrderId, isFetchedAfterMount]);

  const onSave = async (form: FormFields) => {
    const uploadedFile =
    form.file.file instanceof File // TODO: refactor
      ? await uploadInvoiceFile(form.file.file)
      : undefined;
    await editInvoice({
      id: invoiceId,
      invoice: mapFormToUpdateDto(form, uploadedFile!.fileId),
    });
  };

  const onCancel = () => {
    navigate(toBack, {
      state: state
    });
  };

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["EDIT_INVOICE_TITLE"]}
          toBack={toBack}
          state={state}
        />

        <Form
          mode="edit"
          initialValue ={initialValues}
          initialStep={activeStep}
          errorMessage={appMessage["ERROR"]} // TODO: Especificar detalle del error
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditInvoicePage;
