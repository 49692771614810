import { insert, remove } from "ramda";

export const moveElementInListToPosition = <T>(
  initialPosition: number,
  finalPosition: number,
  list: T[]
): T[] => {
  const element = list[initialPosition];

  // in case of not finding element, return original list
  if (element === undefined) return list;

  const listWithoutElementInInitialPosition = remove(initialPosition, 1, list);

  return insert(finalPosition, element, listWithoutElementInInitialPosition);
};
