import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import pDebounce from "p-debounce";
import React from "react";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  AppExistsDocument,
  AppExistsQuery,
  AppExistsQueryVariables,
  GetBusinessObjectsForCreateAppQuery,
  useGetBusinessObjectsForCreateAppQuery,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";

const BasicDataForm = ({
  form: { control },
  mode,
  onEdit,
}: BasicDataFormProps) => {
  const isIdReadOnly = mode !== "create";
  const readOnly = mode === "view";

  const { language } = useSystemLanguage();

  const { dataElementsDescriptions } = useDataElementDescription([
    "BUSINESS_OBJECT",
    "APP",
    "DESCRIPTION",
  ]);

  const {
    data: { businessObjects } = {},
  } = useGetBusinessObjectsForCreateAppQuery({
    // TODO: filtrar solo activos
    language,
  });

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner
  if (businessObjects == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool control={control} placement="top-left" />

      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              label={dataElementsDescriptions["APP"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre de la app.", // FIXME: traduccion
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { appExists } = await appExistsFn({
                      appId: value,
                    });
                    return appExists ? "El nombre ya existe." : true; // FIXME: traduccion
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
              inputProps={{ disabled: isIdReadOnly }}
            />
          </KeyInputWrapper>
        </Grid>

        <Grid item xs={3}>
          <MatchcodeInputController
            label={dataElementsDescriptions["BUSINESS_OBJECT"]}
            controllerProps={{
              name: "businessObjectId",
              control,
              rules: { required: "Seleccione el objeto de negocio." }, // FIXME: traduccion
            }}
            column={[
              {
                key: "name",
                text: dataElementsDescriptions["BUSINESS_OBJECT"],
              },
              {
                key: "description",
                text: dataElementsDescriptions["DESCRIPTION"],
              },
            ]}
            displayColumn="name"
            options={businessObjects.map(mapDtoToMatchcodeOptions)}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

export const mapDtoToMatchcodeOptions = (
  dto: NonNullable<
    GetBusinessObjectsForCreateAppQuery["businessObjects"]
  >[number]
) => ({
  id: dto.dataElement.id,
  name: dto.dataElement.id.toUpperCase(),
  description: dto.dataElement.translation.description,
});

const appExistsFn = pDebounce(
  (args: AppExistsQueryVariables) =>
    fetcher<AppExistsQuery, AppExistsQueryVariables>(AppExistsDocument, args)(),
  DEBOUNCE_TIME_ID
);
