import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { Pagination } from "utils/Pagination";
import { push } from "utils/push";
import { InvoiceStatus } from "__generated__/graphql/types";

export const FILTERS_KEYS = ["id", "purchaseOrder", "purchaseOrderItem", "invoiceBackendNumber", 
  "invoiceVendorNumber", "headerText", "fiscalYear", "currency", "invoiceDate", "invoiceStatus"] as const;
export type FiltersKeys = typeof FILTERS_KEYS[number];

export type Filters = Partial<{
  id: string;
  purchaseOrder: string,
  purchaseOrderItem: string;
  invoiceBackendNumber: string;
  invoiceVendorNumber: string;
  headerText: string;
  fiscalYear: string;
  currency: string;
  invoiceDate: string;
  invoiceStatus: InvoiceStatus[];
}>;

type FiltersState = {
  filtersVisibility: Record<FiltersKeys, boolean>;
  filters: Filters;
  pagination: Pagination;
};

const initialState: FiltersState = {
  filtersVisibility: FILTERS_KEYS.reduce(
    (prev, curr) => push(prev, curr, false),
    {} as Record<FiltersKeys, boolean>
  ),
  filters: {},
  pagination: { page: 0, pageSize: 5 },
};

const filtersAndPaginationSlice = createSlice({
  name: "invoicesReportsFiltersAndPaginationSlice",
  initialState,
  reducers: {
    setFilterVisibility: (
      state,
      action: PayloadAction<{
        key: FiltersKeys;
        isVisible: boolean;
      }>
    ) => {
      const { key, isVisible } = action.payload;
      state.filtersVisibility[key] = isVisible;
      state.filters[key] = undefined;
    },
    setFilters: (state, action: PayloadAction<Filters>) => {
      state.pagination = initialState.pagination;
      state.filters = action.payload;
    },
    prevPage: (state) => {
      state.pagination.page--;
    },
    nextPage: (state) => {
      state.pagination.page++;
    },
    resetPage: (state) => {
      state.pagination.page = 0;
    },
  },
});

export const {
  setFilters,
  setFilterVisibility,
  resetPage,
  prevPage,
  nextPage,
} = filtersAndPaginationSlice.actions;

export const selectIsFilterVisible = (state: RootState) =>
  state.invoicesReports.filtersAndPagination.filtersVisibility;

export const selectFilters = (state: RootState) =>
  state.invoicesReports.filtersAndPagination.filters;

export const selectVisibleFiltersKeys = (state: RootState) =>
  FILTERS_KEYS.filter(
    (key) => state.invoicesReports.filtersAndPagination.filtersVisibility[key]
  );

export const selectHiddenFiltersKeys = (state: RootState) =>
  FILTERS_KEYS.filter(
    (key) => !state.invoicesReports.filtersAndPagination.filtersVisibility[key]
  );

export const selectPagination = (state: RootState) =>
  state.invoicesReports.filtersAndPagination.pagination;

export default filtersAndPaginationSlice.reducer;
