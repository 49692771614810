import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ascend, sortWith } from "ramda";
import { RootState } from "store";
import {
  isColumnVisible,
  isPairColumnHidden,
  isPairColumnVisible,
} from "utils/columnTypesUtils";
import { push } from "utils/push";

type HiddenColumn = { isVisible: false };
type VisibleColumn = { isVisible: true; position: number };

export type Column = VisibleColumn | HiddenColumn;
export const COLUMNS_KEYS = ["id", "purchaseOrder", "purchaseOrderItem", "invoiceVendorNumber", 
  "invoiceBackendNumber", "headerText", "fiscalYear", "total", "currency", "invoiceDate", 
  "plannedPaymentDate", "lastPaymentDate", "rejectionReason", "invoiceStatus"] as const;
export type ColumnsKeys = typeof COLUMNS_KEYS[number];

const columnsInitialValues = COLUMNS_KEYS.reduce(
  (prev, curr, index) => push(prev, curr, { isVisible: true, position: index }),
  {} as Record<ColumnsKeys, Column>
);

type ColumnsState = Record<ColumnsKeys, Column>;

const initialState: ColumnsState = columnsInitialValues;

const visibleColumnsSlice = createSlice({
  name: "invoicesReportsVisibleColumnsSlice",
  initialState,
  reducers: {
    hideColumn: (state, action: PayloadAction<{ id: ColumnsKeys }>) => {
      const { id } = action.payload;
      state[id] = { isVisible: false };
    },
    showColumn: (state, action: PayloadAction<{ id: ColumnsKeys }>) => {
      const { id } = action.payload;
      const positions = Object.values(state)
        .filter(isColumnVisible<Column, VisibleColumn>())
        .map((e) => e.position);
      const maxPosition = Math.max(...positions);
      state[id] = { isVisible: true, position: maxPosition + 1 };
    },
    orderColumns: (state, action: PayloadAction<{ ids: ColumnsKeys[] }>) => {
      action.payload.ids.forEach((value, index) => {
        const column = state[value];
        if (!isColumnVisible<Column, VisibleColumn>()(column)) return;
        column.position = index;
      });
    },
  },
});

export const {
  hideColumn,
  showColumn,
  orderColumns,
} = visibleColumnsSlice.actions;

const sorter = sortWith<[ColumnsKeys, VisibleColumn]>([
  ascend(([, value]) => value.position),
]);

export const selectVisibleColumnsKeysInOrder = (
  state: RootState
): ColumnsKeys[] => {
  const visibleColumns = Object.entries(state.invoicesReports.columns).filter(
    isPairColumnVisible<Column, VisibleColumn, ColumnsKeys>(COLUMNS_KEYS)
  );

  return sorter(visibleColumns).map(([key]) => key);
};

export const selectHiddenColumnsKeys = (state: RootState): ColumnsKeys[] => {
  return Object.entries(state.invoicesReports.columns)
    .filter(isPairColumnHidden<Column, HiddenColumn, ColumnsKeys>(COLUMNS_KEYS))
    .map(([key]) => key);
};

export default visibleColumnsSlice.reducer;
