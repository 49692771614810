import { DevTool } from "@hookform/devtools";
import { MenuItemsProps } from "components/datadisplay/GenericTable/template";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { TableCollapsePIC } from "components/datadisplay/TableCollapsePIC";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAllowedUserAppActions } from "hooks/useAlloweduserAppActions";
import { useDialog } from "hooks/useDialog";
import { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generatePath } from "utils/typedRoutesUtils";
import { BusinessPartnerTypeRoutes } from "../../../routes";
import {
  Filters,
  FiltersKeys,
  selectFilters,
  selectHiddenFiltersKeys,
  selectVisibleFiltersKeys,
  setFilters,
} from "../../reducers/filtersAndPaginationSlice";
import { useBusinessPartnersTypesLabels } from "../../useBusinessPartnersTypesLabels";
import { ColumnsVisibilityDialog } from "./ColumnsVisibilityDialog";
import { FiltersVisibilityDialog } from "./FiltersVisibilityDialog";

type CollapseProps = {
  menuItems: MenuItemsProps[]
};

const Collapse = ({
  menuItems
}: CollapseProps) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const filtersDialog = useDialog();
  const columnsDialog = useDialog();
  const { columnsLabels } = useBusinessPartnersTypesLabels();

  const { actions } = useAllowedUserAppActions("BUSINESS_PARTNER_TYPES");

  const createAction = actions.find(e => e.id === "CREATE");
  const create = createAction === undefined ? false : true ;

  const filters = useAppSelector(selectFilters);
  const visibleFiltersKeys = useAppSelector(selectVisibleFiltersKeys);
  const hiddenFiltersKeys = useAppSelector(selectHiddenFiltersKeys);

  const { control, handleSubmit, unregister } = useForm<Filters>({
    defaultValues: filters,
  });

  const onCreate = () => {
    navigate(generatePath(BusinessPartnerTypeRoutes.CREATE));
  };

  const onSearch = handleSubmit((form) => {
    dispatch(setFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  const filtersComponents: Record<
    FiltersKeys,
    ComponentType<FilterComponentProps<Filters, FiltersKeys>>
  > = {
    name: TextFilter,
    description: TextFilter,
  };

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: columnsLabels,
    control,
  });

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <ColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapsePIC
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={onCreate}
        onExpand={() => { }}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
        menuItems={menuItems}
        isCreatedVisible={create}
      />
    </>
  );
};

export default Collapse;
