import { combineReducers } from "@reduxjs/toolkit";
import checkedRowsSlice from "./reducers/checkedRowsSlice";
import filtersAndPaginationSlice from "./reducers/filtersAndPaginationSlice";
import visibleColumnsSlice from "./reducers/visibleColumnsSlice";

const invoicesReportsReducer = combineReducers({
  columns: visibleColumnsSlice,
  filtersAndPagination: filtersAndPaginationSlice,
  rowsChecked: checkedRowsSlice,
});

export default invoicesReportsReducer;
