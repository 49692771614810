import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetTileForViewPageQuery,
  useUpdateTileMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { useUploadTileIconToS3 } from "../CreatePage/useUploadTileIconToS3";
import { mapFormToDto } from "../CreatePage/utils";
import { TilesRoutes, TilesRoutesState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

const EditPage = () => {
  const [initialValues, setInitialValues] = useState<FormFields>();

  const navigate = useNavigate();

  const { tileId } = useRouteParams<TilesRoutes.EDIT>();

  const { activeStep } =
    useLocationState<TilesRoutesState[TilesRoutes.EDIT]>() ?? {};

  const {
    data: { tile } = {},
    isFetchedAfterMount,
  } = useGetTileForViewPageQuery({
    id: tileId,
  });

  const { mutateAsync: updateTile } = useUpdateTileMutation();
  const { mutateAsync: uploadTileIconToS3 } = useUploadTileIconToS3();

  const onSave = async (form: FormFields) => {
    const uploadedFile =
      form.tileType.tileType.hasImage && form.icon.icon instanceof File // TODO: refactor
        ? await uploadTileIconToS3(form.icon.icon)
        : undefined;
    await updateTile({
      id: tileId,
      tile: mapFormToDto(form, uploadedFile?.fileId),
    });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  useEffect(() => {
    if (tile == null) return;
    if (!isFetchedAfterMount) return;
    mapDtoToForm(tile).then((v) => setInitialValues(v));
  }, [tile, isFetchedAfterMount]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Editar mosaico de app" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValues}
          initialStep={activeStep}
          errorMessage="Error al editar mosaico de app. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
