export enum RevenueCollectorRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":revenueCollectorId/view",
  EDIT = ":revenueCollectorId/edit",
}

export type RevenueCollectorRoutesState = {
  [RevenueCollectorRoutes.EDIT]: { activeStep?: number };
};
