import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import {
  GetCountriesForCreatingBusinessPartnerQuery,
  GetCountriesIdsTypesForCreatingBusinessPartnerQuery,
  useGetCountriesForCreatingBusinessPartnerQuery,
  useGetCountriesIdsTypesForCreatingBusinessPartnerQuery
} from "__generated__/graphql/types";

export type IdDataFormFields = {
  country: string;
  countryId: string;
  noId: string;
};

export type IdDataFormProps = {
  form: UseFormReturn<IdDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const IdDataForm = ({ form, mode, isUsed, onEdit }: IdDataFormProps) => {
  const { control, watch } = form;
  const { language } = useSystemLanguage();

  const countrySelected = watch("country");
  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");

  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "COUNTRIES",
    "COUNTRY_DOCUMENT",
    "COUNTRY_DOCUMENT_ID",
    "DESCRIPTION",
  ]);

  const {
    data: { countries } = {},
  } = useGetCountriesForCreatingBusinessPartnerQuery({ language });

  const {
    data: { countryIdsTypes } = {},
  } = useGetCountriesIdsTypesForCreatingBusinessPartnerQuery(
    { id: countrySelected },
    { enabled: countrySelected !== undefined }
  );

  if (countries == null) return <BeatLoadingPage/>;
  if (dataElementsDescriptions === undefined) return <BeatLoadingPage/>;

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MatchcodeInputController
            label={dataElementsDescriptions["COUNTRIES"]}
            controllerProps={{
              name: "country",
              control,
              rules: { required:appMessage["SELECT_COUNTRY"] ?? "Select the country" }, ///FIXPRD QUITAR
            }}
            readOnly={disabled}
            column={[
              {
                key: "name",
                text: dataElementsDescriptions["COUNTRIES"],
              },
              {
                key: "description",
                text: dataElementsDescriptions["DESCRIPTION"],
              },
            ]}
            displayColumn="name"
            options={countries.map(mapDataElementToOption)}
          />
        </Grid>
        <Grid item xs={3}>
          <MatchcodeInputController
            label={dataElementsDescriptions["COUNTRY_DOCUMENT"]}
            controllerProps={{
              name: "countryId",
              control,
              rules: { required: appMessage["SELECT_DOC_TYPE"] ?? "Select the document type"},//FIXPRD QUITAR
            }}
            readOnly={disabled}
            column={[
              {
                key: "name",
                text: dataElementsDescriptions["COUNTRY_DOCUMENT"],
              },
            ]}
            displayColumn="name"
            options={
              countryIdsTypes?.idsTypes?.map(mapDataElementToOptionId) ?? []
            }
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: disabled }}
            label={dataElementsDescriptions["COUNTRY_DOCUMENT_ID"]}
            controllerProps={{
              name: "noId",
              control,
              rules: {
                required: appMessage["ENTER_DOC_ID"] ?? "Enter the document id",//FIXPRD QUITAR
              },
            }}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default IdDataForm;

const mapDataElementToOption = (
  dto: NonNullable<
    GetCountriesForCreatingBusinessPartnerQuery["countries"]
  >[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});

const mapDataElementToOptionId = (
  dto: NonNullable<
    GetCountriesIdsTypesForCreatingBusinessPartnerQuery["countryIdsTypes"]
  >["idsTypes"][number]
) => ({
  id: dto.id,
  name: dto.id,
});
