import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { FilterDto } from "utils/FilterDto";
import { Pagination } from "utils/Pagination";
import { push } from "utils/push";
import { DATA_ELEMENTS_COLUMNS_KEYS } from "./visibleColumnsSlice";

export const DATA_ELEMENTS_FILTERS_KEYS = DATA_ELEMENTS_COLUMNS_KEYS;

export type DataElementsFiltersKeys = typeof DATA_ELEMENTS_FILTERS_KEYS[number];

export type DataElementsFilters = Partial<{
  name: string;
  used: boolean;
  status: string;
  dataType: string;
  description: string;
  length: FilterDto<number>;
  decimals: FilterDto<number>;
  isLanguageDependent: boolean;
}>;

type FiltersState = {
  filtersVisibility: Record<DataElementsFiltersKeys, boolean>;
  filters: DataElementsFilters;
  pagination: Pagination;
};

const initialState: FiltersState = {
  filtersVisibility: DATA_ELEMENTS_FILTERS_KEYS.reduce(
    (prev, curr) => push(prev, curr, false),
    {} as Record<DataElementsFiltersKeys, boolean>
  ),
  filters: {},
  pagination: { page: 0, pageSize: 5 },
};

const filtersAndPaginationSlice = createSlice({
  name: "dataElementsFiltersAndPaginationSlice",
  initialState,
  reducers: {
    setDataElementsFilterVisibility: (
      state,
      action: PayloadAction<{
        key: DataElementsFiltersKeys;
        isVisible: boolean;
      }>
    ) => {
      const { key, isVisible } = action.payload;
      state.filtersVisibility[key] = isVisible;
      state.filters[key] = undefined;
    },
    setDataElementsFilters: (
      state,
      action: PayloadAction<DataElementsFilters>
    ) => {
      state.pagination = initialState.pagination;
      state.filters = action.payload;
    },
    prevPageDataElements: (state) => {
      state.pagination.page--;
    },
    nextPageDataElements: (state) => {
      state.pagination.page++;
    },
    resetDataElementsPage: (state) => {
      state.pagination.page = 0;
    },
  },
});

export const {
  setDataElementsFilters,
  setDataElementsFilterVisibility,
  resetDataElementsPage,
  prevPageDataElements,
  nextPageDataElements,
} = filtersAndPaginationSlice.actions;

export const selectIsDataElementsFilterVisible = (state: RootState) =>
  state.dataElements.filtersAndPagination.filtersVisibility;

export const selectDataElementsVisibleFiltersKeys = (state: RootState) => {
  const { filtersVisibility } = state.dataElements.filtersAndPagination;
  return DATA_ELEMENTS_FILTERS_KEYS.filter(
    (filterKey) => filtersVisibility[filterKey]
  );
};

export const selectDataElementsHiddenFiltersKeys = (state: RootState) => {
  const { filtersVisibility } = state.dataElements.filtersAndPagination;
  return DATA_ELEMENTS_FILTERS_KEYS.filter(
    (filterKey) => !filtersVisibility[filterKey]
  );
};

export const selectDataElementsFilters = (state: RootState) =>
  state.dataElements.filtersAndPagination.filters;

export const selectDataElementsPagination = (state: RootState) =>
  state.dataElements.filtersAndPagination.pagination;

export default filtersAndPaginationSlice.reducer;
