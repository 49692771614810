import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { GenericTable } from "components/datadisplay/GenericTable";
import React from "react";
import { useTable } from "./useTable";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

const Table = () => {
  const {
    rows,
    total,
    errorSnackbar,
    columns,
    menuItems,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    pagination,
  } = useTable();

  if (rows === undefined) return <BeatLoadingPage />; // TODO: loading spinner
  if (total === undefined) return <BeatLoadingPage />; // TODO: loading spinner

  return (
    <>
      <SnackbarAlert
        severity="error"
        onClose={errorSnackbar.close}
        open={errorSnackbar.isOpen}
      >
        {/* // FIXME: traduccion */}
        Error al eliminar el elemento de dato. Vuelva a intentar.
      </SnackbarAlert>

      <GenericTable
        columns={columns}
        rows={rows}
        menuItems={menuItems}
        isRowChecked={isDataElementsRowChecked}
        onChangeCheckedRow={onChangeCheckedRow}
        areAllRowsChecked={areAllDataElementsRowsChecked}
        onChangeAllRowsChecked={onChangeAllRowsChecked}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        totalRows={total ?? 0}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
      />
    </>
  );
};

export default Table;
