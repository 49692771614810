import { Box, Button, Grid, styled, Tooltip, Typography } from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export type SuccessMessageParamsProps = {
  message?: string;
  params?: string; // Params in JSON
}

const SuccessMessageParams = ({
  message,
  params
}: SuccessMessageParamsProps )=> {
  return (
    <SuccessMessageWrapper>
      {
        (message && params) ? (
          <>
          <StyledTypography>
            {message}
          </StyledTypography>
          
          {/* FIXME: Traducción */}
          <Tooltip title="Copy">
            <GridCenter>
              <CopyToClipboard
                text={params}
              >
                <Button 
                  variant="contained" 
                  style={{textTransform: 'none'}} 
                  endIcon={<ContentCopyIcon />}
                >
                  {params}
                </Button>
              </CopyToClipboard>
            </GridCenter>
          </Tooltip>
          </>
        ) : (
          <StyledTypography>
            The data has been successfully saved.
          </StyledTypography>
        ) 
      }
    </SuccessMessageWrapper>
  );
};

export default SuccessMessageParams;

const SuccessMessageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "auto",
  borderRadius: theme.shape.wrapperBorderRadius,
  backgroundColor: theme.palette.background.apps,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "center",
  fontWeight: 400,
  color: theme.palette.primary.dark,
  width: "fit-content",
  marginRight: "auto",
  marginLeft: "auto",
}));

const GridCenter = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
}));
