import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SelectController } from "components/inputs/SelectController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { BasicDataFormProps } from "./types";

const BasicDataForm = ({
  form: { control, watch },
  mode,
  onEdit,
}: BasicDataFormProps) => {
  const readOnly = mode === "view";

  const { dataElementsChoices } = useDataElementChoices(["IDENTIFICATION"]);
  const bptype = watch("identification");
  const { dataElementsDescriptions } = useDataElementDescription([
    "BUSINESS_PARTNER_NAME",
    "IDENTIFICATION",
    "USER_LASTNAME",
    "TAX_NAME"
  ]);
  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");

  if (dataElementsDescriptions == null) return <BeatLoadingPage/>;
  if (dataElementsChoices === undefined) return <BeatLoadingPage/>;

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool control={control} placement="top-left" />

      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <SelectController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["IDENTIFICATION"]}
            select
            controllerProps={{
              name: "identification",
              control,
              rules: { required: appMessage["SELECT_BP_TYPE"] ?? "Select the business partner type"}, ///FIXPRD QUITAR
            }}
            options={dataElementsChoices["IDENTIFICATION"]}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldController
            label={dataElementsDescriptions["BUSINESS_PARTNER_NAME"]}
            controllerProps={{
              name: "name",
              control,
              rules: {
                required: appMessage["ENTER_BP_NAME"] ?? "Enter the business partner name",///FIXPRD QUITAR
              },
            }}
            inputProps={{ disabled: readOnly }}
          />
        </Grid>
        {bptype === "PERSON" && <Grid item xs={3}>
          <TextFieldController
            label={dataElementsDescriptions["USER_LASTNAME"]}
            controllerProps={{
              name: "lastname",
              control,
              rules: {
                required: appMessage["ENTER_BP_LASTN"] ?? "Enter the business partner last name", ///FIXPRD QUITAR
              },
            }}
            inputProps={{ disabled: readOnly }}
          />
        </Grid>}
        {bptype === "COMPANY" && <Grid item xs={3}>
          <TextFieldController
            label={dataElementsDescriptions["TAX_NAME"]}
            controllerProps={{
              name: "taxname",
              control,
              rules: {
                required: appMessage["ENTER_BP_FNAME"] ?? "Enter the business partner fiscal name", ///FIXPRD QUITAR
              },
            }}
            inputProps={{ disabled: readOnly }}
          />
        </Grid>}
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;
