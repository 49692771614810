import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import { MatchcodeDisplayInput } from "components/inputs/matchcode/MatchcodeInputController/MatchcodeDisplayInput";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { GetPurchaseOrdersForPurchaseOrdersItemsReportsQuery } from "__generated__/graphql/types";

type PurchaseOrdersDto = NonNullable<
  NonNullable<GetPurchaseOrdersForPurchaseOrdersItemsReportsQuery["purchaseOrders"]>[number]
>;
export type GroupsMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  purchaseOrders: PurchaseOrdersDto[];
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  text?:string;
};

export const GroupsMatchcode = ({
  value,
  onChange,
  purchaseOrders,
  invalid,
  errorMessage,
  readOnly = false,
  text,
}: GroupsMatchcodeProps) => {

  const { dataElementsDescriptions } = useDataElementDescription(["PURCHASE_ORDER_ID"]);

  const options = purchaseOrders?.map((e) => ({
    id: e.id,
    name: e.id,
  }));

  const {
    dialog,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    value,
    onChange,
    searchKeys: ["name"],
  });

  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <>
      <MatchcodeDisplayInput
        label={dataElementsDescriptions["PURCHASE_ORDER_ID"] ?? "PURCHASE_ORDER_ID"}
        name={dataElementsDescriptions["PURCHASE_ORDER_ID"] ?? "PURCHASE_ORDER_ID"}
        text={text ?? ""}
        isDeleteButtonVisible={isDeleteButtonVisible}
        onClearValue={onClearValue}
        onOpenDialog={dialog.open}
        error={invalid}
        helperText={errorMessage}
        readOnly={readOnly}
      />

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "name", text: dataElementsDescriptions["PURCHASE_ORDER_ID"] ?? "PURCHASE_ORDER_ID"},
            ]}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default GroupsMatchcode;
