import {
  Image as ImageIcon,
  Palette as PaletteIcon,
  Person as PersonIcon,
  Translate as TranslateIcon,
  VpnKey as VpnKeyIcon
} from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";

export type SiteNavigationOptions =
  | "PERSONAL_INFORMATION"
  | "THEME"
  | "IMAGE"
  | "LANGUAGE_REGION"
  | "PASSWORD";

export type SideNavigationProps = {
  selected: SiteNavigationOptions | undefined;
  onChangeSelected: (value: SiteNavigationOptions) => void;
  errors: SiteNavigationOptions[] | undefined;
};

const SideNavigation = ({
  selected,
  errors,
  onChangeSelected,
}: SideNavigationProps) => {

  const { appMessage } = useAppMessages("MESSAGES");

  const listItems: {
    key: SiteNavigationOptions;
    text: string;
    icon: JSX.Element;
  }[] = [
    {
      key: "PERSONAL_INFORMATION",
      text: appMessage["PERSONAL_INFORMATION"] ?? "Personal information",
      icon: <PersonIcon />,
    },
    {
      key: "LANGUAGE_REGION",
      text: appMessage["LANGUAGE_REGION"] ?? "Language and region",
      icon: <TranslateIcon />,
    },
    {
      key: "THEME",
      text: appMessage["THEME"] ?? "Theme",
      icon: <PaletteIcon />,
    },
    {
      key: "IMAGE",
      text: appMessage["PROFILE_IMAGE"] ?? "Profile image",
      icon: <ImageIcon />,
    },
    {
      key: "PASSWORD",
      text: appMessage["PASSWORD"] ?? "Password",
      icon: <VpnKeyIcon />,
    },
  ];

  return (
    <List component="nav" aria-label="main mailbox folders">
      {listItems.map(({ text, key, icon }) => (
        <ListItem
          button
          selected={selected === key}
          onClick={() => onChangeSelected(key)}
          style={{ borderColor: "red", borderWidth: "10px" }}
        >
          <ListItemIcon color="error">{icon}</ListItemIcon>
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              color: errors?.includes(key) ? "error" : undefined,
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SideNavigation;
