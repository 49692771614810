// FIXME: solo usar para ambiente de dev

const LOCAL_STORAGE_SESSION_TOKEN = "session-token";

let sessionToken: string | undefined = undefined;

const sessionHeaderName = "x-auth-token";

export const getSessionTokenHeader = () => {
  return {
    [sessionHeaderName]:
      sessionToken ?? localStorage.getItem(LOCAL_STORAGE_SESSION_TOKEN),
  };
};

export const setSessionTokenHeader = (headers: any) => {
  sessionToken = headers[sessionHeaderName];
  if (sessionToken !== undefined) {
    localStorage.setItem(LOCAL_STORAGE_SESSION_TOKEN, sessionToken);
  }
};
