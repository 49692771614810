import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  CompaniesExistsDocument,
  CompaniesExistsQuery,
  CompaniesExistsQueryVariables,
  useGetCurrenciesForCompaniesFormQuery,
  useGetChartOfAccountsForCompaniesFormQuery,
  GetChartOfAccountsForCompaniesFormQuery,
  GetCurrenciesForCompaniesFormQuery,
} from "__generated__/graphql/types";

export type BasicDataFormFields = {
  company: string;
  name: string;
  currency: string;
  chartOfAccount: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;
  const { language } = useSystemLanguage();

  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "COMPANY",
    "DESCRIPTION",
    "CURRENCY",
    "CHART_OF_ACCOUNT",
  ]);
  const { data: { currencies } = {} } = useGetCurrenciesForCompaniesFormQuery({
    language,
  });

  const {
    data: { chartOfAccounts } = {},
  } = useGetChartOfAccountsForCompaniesFormQuery({
    language,
  });

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  if (currencies == null) return null;

  if (chartOfAccounts == null) return null;

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["COMPANY"]}
              controllerProps={{
                name: "company",
                control,
                rules: {
                  required: "Ingrese el Id.", // FIXME: traduccion
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { companiesExists } = await companiesExistsFn({
                      id: value,
                    });
                    return companiesExists
                      ? "El Id ya existe." // FIXME: traduccion
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["DESCRIPTION"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["CURRENCY"]}
              controllerProps={{
                name: "currency",
                control,
                rules: { required: "Selecciona la moneda." }, // FIXME: traduccion
              }}
              readOnly={disabled}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["CURRENCY"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={currencies.map(mapDataElementToOption)}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["CHART_OF_ACCOUNT"]}
              controllerProps={{
                name: "chartOfAccount",
                control,
                rules: { required: "Selecciona el plan de cuentas" }, // FIXME: traduccion
              }}
              readOnly={disabled}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["CHART_OF_ACCOUNT"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={chartOfAccounts.map(
                mapDataElementToOptionChartOfAccount
              )}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const companiesExistsFn = pDebounce(
  (args: CompaniesExistsQueryVariables) =>
    fetcher<CompaniesExistsQuery, CompaniesExistsQueryVariables>(
      CompaniesExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);

const mapDataElementToOption = (
  dto: NonNullable<GetCurrenciesForCompaniesFormQuery["currencies"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});

const mapDataElementToOptionChartOfAccount = (
  dto: NonNullable<
    GetChartOfAccountsForCompaniesFormQuery["chartOfAccounts"]
  >[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});
