import { Grid, MenuItem, styled, TextField } from "@mui/material";
import { Tile } from "components/navigation/Tile";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useLanguagesOptions } from "hooks/useLanguagesOptions";
import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { fileToBase64 } from "utils/fileToBase64";
import { TileTypeFormProps } from "./types";

const TilePreview = (props: TileTypeFormProps) => {
  const {
    tileDescription,
    previewLanguage,
    setPreviewLanguage,
  } = useTylePreview(props);

  const { languagesOptions } = useLanguagesOptions();
  const { dataElementsDescriptions } = useDataElementDescription(["LANGUAGE"]);

  if (languagesOptions == null) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <>
      <Grid container spacing={4} justifyContent="center">
        <Grid item>
          {tileDescription !== undefined && <Tile {...tileDescription} />}

          <SelectWrapper>
            <TextField
              label={dataElementsDescriptions["LANGUAGE"]}
              size="small"
              variant="outlined"
              fullWidth
              select
              value={previewLanguage}
              onChange={(event) => setPreviewLanguage(event.target.value)}
            >
              {languagesOptions.map(({ value, text }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </TextField>
          </SelectWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default TilePreview;

const useTylePreview = ({ forms }: TileTypeFormProps) => {
  const [previewLanguage, setPreviewLanguage] = useState<string>("ES"); // FIXME:

  const [imageBase64, setImageBase64] = useState<string>();

  const descriptions = useWatch({
    control: forms.descriptions.control,
    name: "descriptions",
  });

  const tileType = useWatch({
    control: forms.tileType.control,
    name: "tileType",
  });

  const icon = useWatch({ control: forms.icon.control, name: "icon" });

  const previewLanguageDescription = descriptions.find(
    (e) => e.language === previewLanguage
  );

  const tileDescription = {
    title: previewLanguageDescription?.title ?? "",
    subtitle: previewLanguageDescription?.subtitle ?? "",
    shape: tileType.shape,
    imageUrl: tileType.hasImage ? imageBase64 : undefined,
    indicator: tileType.hasKpi ? "12.3" : undefined, // FIXME: numero de prueba
  };

  useEffect(() => {
    if (!(icon instanceof File)) return;
    fileToBase64(icon).then((base64) => setImageBase64(base64 as string));
  }, [icon]);

  return {
    tileDescription,
    previewLanguage,
    setPreviewLanguage,
  };
};

const SelectWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(4),
  borderRadius: theme.shape.wrapperBorderRadius,
  backgroundColor: theme.palette.background.apps,
}));
