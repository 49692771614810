import { FormFields } from "apps/LocationTypePage/CreatePage/Form/types";
import { LocationTypeInput } from "__generated__/graphql/types";

export const mapFormToDto = (form: FormFields): LocationTypeInput => {
  return {
    id: form.basicData.name,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
  };
};
