import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useMemo } from "react";
import { useGetDataElementDescriptionQuery } from "__generated__/graphql/types";

const useDataElementDescription = (names: string[]) => {
  const { language } = useSystemLanguage();

  const { data: { dataElements } = {} } = useGetDataElementDescriptionQuery({
    language,
    ids: names,
  });

  const dataElementsMap: Record<string, string> | undefined = useMemo(
    () =>
      dataElements === undefined
        ? undefined
        : Object.fromEntries(
            dataElements.map((e) => [e.id, e.translation.description])
          ),
    [dataElements]
  );

  return { dataElementsDescriptions: dataElementsMap };
};

export default useDataElementDescription;
