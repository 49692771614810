import { GetApp as GetAppIcon } from "@mui/icons-material";
import {
  PopoverPosition,
  styled,
  TableCell,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { downloadFile } from "utils/downloadFile";
import { TableRowMenu } from "./TableRowMenu";

export type TableRowProps = {
  version: number;
  timestamp: Date;
  downloadUrl: string;
  fileName: string;
};

const TableRow = ({
  version,
  timestamp,
  downloadUrl,
  fileName,
}: TableRowProps) => {
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition>();
  const [active, setActive] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
    setActive(true);
    setAnchorPosition({
      left: event.clientX,
      top: event.clientY,
    });
  };

  const onClose = () => {
    setAnchorPosition(undefined);
    setActive(false);
  };

  return (
    <>
      <TableRowMenu
        items={[
          {
            Icon: GetAppIcon,
            key: "download",
            onClick: () => {
              downloadFile(downloadUrl, fileName);
            },
            text: "Download", // FIXME: traduccion
          },
        ]}
        anchorPosition={anchorPosition}
        onClose={onClose}
      />

      <StyledMuiTableRow active={active}>
        <TableCell
          scope="row"
          style={{ whiteSpace: "nowrap" }} // FIXME:
          onClick={handleClick}
          align="center"
        >
          {version}
        </TableCell>

        <TableCell
          scope="row"
          style={{ whiteSpace: "nowrap" }} // FIXME:
          onClick={handleClick}
        >
          {/* TODO: formatear segun lenguaje */}
          {format(timestamp, "MMMM d, yyyy, HH:mm:ss")}
        </TableCell>
      </StyledMuiTableRow>
    </>
  );
};

export default TableRow;

const StyledMuiTableRow = styled(MuiTableRow)<
  { active: boolean } & MuiTableRowProps
>(({ active }) => ({
  backgroundColor: active ? "white" : "rgb(245, 245, 245)",
  "&:focus": {
    backgroundColor: "white",
  },
}));
