import { CountryInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): CountryInput => {
  return {
    id: form.basicData.name,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
    regions: form.regions.regions.map((region) => ({
      id: region.name,
      translations: region.descriptions.map((description) => ({
        language: description.language,
        description: description.text,
      })),
    })),
  };
};
