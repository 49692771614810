import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { Revenue, RevenueFormFields } from "../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { RevenueNamesFormProps } from "./types";

export const revenueEmptyRow: Revenue = {
  id: "",
};

const RevenueNamesForm = ({
  readOnly = false,
  onEdit,
  form,
}: RevenueNamesFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray<RevenueFormFields>({
    control,
    name: "collectors",
  });

  const appendRow = () => {
    append(revenueEmptyRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <>
      <TableContainer>
        <StyledTable aria-label="simple table" size="small">
          <Header
            onAppend={() => appendRow()}
            onEdit={() => onEdit?.()}
            readOnly={readOnly}
          />

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                index={index}
                form={form}
                key={item.id}
                remove={onRemove(index)}
                item={item}
                readOnly={readOnly}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

export default RevenueNamesForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
