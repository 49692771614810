import { Box, styled, Tooltip } from "@mui/material";
import { green, yellow } from "@mui/material/colors";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { Maybe } from "__generated__/graphql/types";
import { format } from "date-fns";

type TrafficLightProps = {
  status: boolean;
  appliedAt: Maybe<string> | undefined;
};

const TrafficLight = ({ status, appliedAt }: TrafficLightProps) => {
  return (
    <Tooltip title={appliedAt !== null && appliedAt !== undefined && appliedAt !== 'null' 
              ? format(new Date(appliedAt!), "MMMM d, yyyy, HH:mm:ss") 
              : "No se ha aplicado la migración"}  placement="top">
      <Box display="flex" justifyContent="center">
        {status ? <StyledCheckCircleIcon /> : <StyledDraftIcon />}
      </Box>
    </Tooltip>
  );
};

export default TrafficLight;

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: green[200],
});

const StyledDraftIcon = styled(ErrorIcon)({
  color: yellow[600],
});
