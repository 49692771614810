export enum TilesRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":tileId/view",
  EDIT = ":tileId/edit",
}

export type TilesRoutesState = {
  [TilesRoutes.EDIT]: { activeStep?: number };
};
