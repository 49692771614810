import { Box, BoxProps, IconButton, styled, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import React from "react";
import { useAppMessages } from "hooks/useAppMessages";

export const FormNonTableBodyWrapper = styled(
  ({
    children,
    readOnly = false,
    onEdit,
    ...rest
  }: BoxProps & {
    readOnly?: boolean;
    onEdit?: () => void;
  }) => {
    
    const { appMessage } = useAppMessages("MESSAGES");
    const padding = readOnly ? { p: 2 } : { pt: 6, px: 2, pb: 2 };

    return (
      <Box {...padding} {...rest}>
        {readOnly && (
          <Box display="flex" marginBottom={1} justifyContent="flex-end">
            <Tooltip
              title={appMessage["EDIT"] ?? "Edit"}
            >
              <StyledIconButton size="small" onClick={onEdit}>
                <EditIcon fontSize="small" />
              </StyledIconButton>
            </Tooltip>
          </Box>
        )}

        {children}
      </Box>
    );
  }
)(({ theme }) => ({
  backgroundColor: theme.palette.background.apps,
  borderRadius: theme.shape.wrapperBorderRadius,
}));

export default FormNonTableBodyWrapper;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
