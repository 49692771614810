import { Box } from "@mui/material";
import { MoonLoader } from "react-spinners";

const LoadingPage = () => (
  <Box
    display="flex"
    height="100vh"
    justifyContent="center"
    alignItems="center"
    bgcolor="rgb(221, 219, 209)"
  >
    <MoonLoader color="black" />
  </Box>
);

export default LoadingPage;
