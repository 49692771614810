import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Box } from '@mui/material';
import { useDeleteBusinessPartnerMutation, useGetBusinessPartners_ViewAllPageQuery } from "__generated__/graphql/types";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAllowedUserAppActions } from "hooks/useAlloweduserAppActions";
import { useAppMessages } from "hooks/useAppMessages";
import { useSnackbar } from "hooks/useSnackbarV2";
import { UseSnackbarReturn } from "hooks/useSnackbarV2/hook";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { notNullUndefined } from "utils/notNullUndefined";
import { BusinessPartnersRoutes } from "../routes";
import { Collapse } from "./components/Collapse";
import { Table } from "./components/Table";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { resetPage, selectFilters, selectPagination, } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

const ViewAllPage = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");
  const { appMessage: appMessages } = useAppMessages("MESSAGES");

  const { actions } = useAllowedUserAppActions("BUSINESS_PARTNERS");

  const errorSnackbar = useSnackbar();
  const rowsChecked = useAppSelector(selectRowsChecked);
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);

  const {
    data: { businessPartners, businessPartnersCount } = {},
    refetch: findApps,
  } = useGetBusinessPartners_ViewAllPageQuery({
    pagination, // TODO: filtros
    filters: { 
      id: filters.id ?? undefined,
      name: filters.name ?? undefined,
      lastName: filters.lastName ?? undefined,
      taxName: filters.taxName ?? undefined,
      identification: filters.identification ?? undefined,
    }, //FIXME: REFACTOR
  });

  const {
    data: { businessPartners:exportToExcel } = {},
  } = useGetBusinessPartners_ViewAllPageQuery({
    filters: { 
      id: filters.id ?? undefined,
      name: filters.name ?? undefined,
      lastName: filters.lastName ?? undefined,
      taxName: filters.taxName ?? undefined,
      identification: filters.identification ?? undefined,
    }, //FIXME: REFACTOR
  })

  const {
    mutateAsync: deleteBusinessPartnerType,
  } = useDeleteBusinessPartnerMutation();

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              id: dto.id,
              name: dto.name,
              lastName: dto.lastName ?? "",
              taxName: dto.taxName ?? "",
              identification: dto.identification,
              backend: dto.backend.map(e => e.externalId).join(", ")
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel]
  );

  const readAction = actions.find(e => e.id === "READ");
  const updateAction = actions.find(e => e.id === "UPDATE");
  const deleteAction = actions.find(e => e.id === "DELETE");
  
  const menuItems = useMemo(
    () => [
      readAction && {
        key: "display",
        Icon: SearchIcon,
        text: readAction.translation.description,
        onClick: (id: string) => {
          if (id === "display") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          navigate(generatePath(BusinessPartnersRoutes.VIEW, { businessPartnerId: id }));
        },
      },
      updateAction && {
        key: "edit",
        Icon: EditIcon,
        text: updateAction.translation.description,
        onClick: (id: string) => {
          if (id === "update") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          navigate(generatePath(BusinessPartnersRoutes.EDIT, { businessPartnerId: id }));
        },
      },
      deleteAction && {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: deleteAction.translation.description,
        onClick: (id: string) => {
          if (id === "delete") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          deleteBusinessPartnerType({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open(appMessage["ERROR_DELETE"]))
          }
      },
    ].filter(notNullUndefined), [ deleteBusinessPartnerType, dispatch, errorSnackbar, findApps, navigate,
      appMessage, appMessages, rowsChecked, readAction, updateAction, deleteAction])

      const total = businessPartnersCount;

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appMessage["BUSINESS_PARTNERS_TITLE"]}
          toBack="../"
        />

        <Box marginY={2}>
          <Collapse
            menuItems={menuItems}
            sheet={sheet}
          />
        </Box>

        <Table
          menuItems={menuItems}
          businessPartners={businessPartners ?? []}
          total={total}
          pagination={pagination}
        />
      </Box>
    </Layout>
  );
};

export default ViewAllPage;

const validateSelectRows: any = (rowsChecked: string[], appMessages: any, errorSnackbar: UseSnackbarReturn) => {
  if(rowsChecked.length === 0) {
    errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
    return true;
  } else if(rowsChecked.length > 1) {
    errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
    return true;
  }
  return false;
}