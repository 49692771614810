import { DescriptionFormControl } from "components/inputs/DescriptionFormControl";
import { DescriptionFormFields } from "components/inputs/DescriptionFormControl/types";
import React from "react";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { notImplemented } from "utils/assert";
import { useGetDataElementByIdForCreateBusinessObjectPageQuery } from "__generated__/graphql/types";
import { BasicDataFormFields } from "../BasicDataForm/types";

type DescriptionsProps = {
  basicDataForm: UseFormReturn<BasicDataFormFields>;
};

const Descriptions = ({ basicDataForm }: DescriptionsProps) => {
  const dataElementId = useWatch({
    control: basicDataForm.control,
    name: "dataElementId",
  });

  const {
    data: { dataElement } = {},
  } = useGetDataElementByIdForCreateBusinessObjectPageQuery({
    id: dataElementId,
  });

  const initialValue: DescriptionFormFields | undefined =
    dataElement == null
      ? undefined
      : {
          descriptions: dataElement.translations.map((e) => ({
            language: e.language,
            text: e.description,
          })),
        };

  if (initialValue === undefined) return null; // TODO: loading spinner

  return (
    <DescriptionsWrapped
      initialValue={initialValue}
      readOnly
      onEdit={
        () => notImplemented() // TODO:
      }
    />
  );
};

type DescriptionsWrappedProps = {
  initialValue: DescriptionFormFields;
  readOnly?: boolean;
  onEdit?: () => void;
};

const DescriptionsWrapped = ({
  initialValue,
  onEdit,
  readOnly,
}: DescriptionsWrappedProps) => {
  const form = useForm<DescriptionFormFields>({ defaultValues: initialValue });
  return (
    <DescriptionFormControl form={form} readOnly={readOnly} onEdit={onEdit} />
  );
};

export default Descriptions;
