import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { EditPage } from "./EditPage";
import { TutorialsRoutes } from "./routes";
import { ViewAllPage } from "./ViewAllPage";
import ViewPage from './ViewPage/template';

const TutorialsPage = () => {
  return (
    <Routes>
      <Route
        path={TutorialsRoutes.PATH}
        element={<ViewAllPage />}
      />
      <Route
        path={TutorialsRoutes.PATH_CREATE}
        element={<CreatePage />}
      />
      <Route
        path={TutorialsRoutes.PATH_EDIT}
        element={<EditPage />}
      />
      <Route
        path={TutorialsRoutes.PATH_VIEW}
        element={<ViewPage />}
      />
    </Routes>
  );
};

export default TutorialsPage;
