import { DatePicker } from '@mui/lab';
import { Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import TextField from '@mui/material/TextField';
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { BeatLoadingPage } from 'components/layout/BeatLoadingPage';
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { Controller } from "react-hook-form";
import {
  GetAllPositionsForBusinessPartnersFormQuery, GetUsersForBusinessPartnersFormQuery, useGetAllPositionsForBusinessPartnersFormQuery, useGetUsersForBusinessPartnersFormQuery
} from "__generated__/graphql/types";
import { Contacts } from "../types";
import { ContactsDescriptionsFormProps } from "./types";

export const contactDescriptionEmptyRow: Contacts = {
  userId: "",
  firstName: "",
  lastName: "",
  position: "",
  email: "",
  phoneNumber: "",
  birthDate: new Date(),
};

const ContactsDescriptionsForm = ({
  readOnly = false,
  formMethods,
  externalIndex,
  onEdit,
}: ContactsDescriptionsFormProps) => {
  const { control, watch } = formMethods;

  const { language } = useSystemLanguage();
  const pagination = { page: 0, pageSize: 5 };
  const { data: { users } = {} } = useGetUsersForBusinessPartnersFormQuery({});
  const { data: { positions } = {} } = useGetAllPositionsForBusinessPartnersFormQuery({
    language, pagination,
    filters: {
      ids: undefined, // FIXME: list
      // TODO: other filters
    },
  });

  const { dataElementsDescriptions } = useDataElementDescription([
    "USER_ID",
    "USER_FIRSTNAME",
    "USER_LASTNAME",
    "USER_EMAIL",
    "USER_PHONE_NUMBER",
    "POSITION",
    "DESCRIPTION"
  ]);
  const isUser = watch(
    `contact.${externalIndex}._isUser` as "contact.0._isUser"
  );
  const user = watch(
    `contact.${externalIndex}.userId` as "contact.0.userId"
  );
  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");

  if (dataElementsDescriptions === undefined) return <BeatLoadingPage/>;
  if (users == null) return <BeatLoadingPage/>;
  if (positions == null) return <BeatLoadingPage/>;

  const userData = users.find(e => e.id === user);
  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <Grid container spacing={2}>
        {isUser && (
          <Fade in={isUser}>
            <Grid item xs={3}>
              <MatchcodeInputController
                label={dataElementsDescriptions["USER_ID"]}
                controllerProps={{
                  name: `contact.${externalIndex}.userId` as "contact.0.userId",
                  control,
                  rules: { required:appMessage["ENTER_USER"] ?? "Enter a user"}, ///FIXPRD QUITAR }, 
                }}
                readOnly={readOnly}
                column={[
                  {
                    key: "name",
                    text: dataElementsDescriptions["USER_ID"],
                  },
                  {
                    key: "description",
                    text: dataElementsDescriptions["USER_FIRSTNAME"],
                  },
                ]}
                displayColumn="name"
                options={users.map(mapDataElementToOption)}
              />
            </Grid>
          </Fade>
        )}
        {isUser && (
          <Fade in={isUser}>
            <Grid item xs={4} spacing={2}>
              <TextFieldController
                inputProps={{ disabled: true }}
                label={dataElementsDescriptions["USER_FIRSTNAME"]}
                InputProps={{
                  value:userData?.firstName ?? ''
                }}
                controllerProps={{
                  name: `contact.${externalIndex}._ufirstName` as "contact.0._ufirstName",
                  control,
                }}
              />
            </Grid>
          </Fade>
        )}
        {isUser && (
          <Fade in={isUser}>
            <Grid item xs={4} spacing={2}>
              <TextFieldController
                inputProps={{ disabled: true }}
                label={dataElementsDescriptions["USER_LASTNAME"]}
                InputProps={{
                  value:userData?.lastName ?? ''
                }}
                controllerProps={{
                  name: `contact.${externalIndex}._ulastName` as "contact.0._ulastName",
                  control,
                }}
              />
            </Grid>
          </Fade>
        )}
        {isUser && (
          <Fade in={isUser}>

            <Grid item xs={4} spacing={2}>
              <TextFieldController
                inputProps={{ disabled: true }}
                label={dataElementsDescriptions["USER_EMAIL"]}
                InputProps={{
                  value:userData?.email ?? ''
                }}
                controllerProps={{
                  name: `contact.${externalIndex}._uemail` as "contact.0._uemail",
                  control,
                }}
              />
            </Grid>
          </Fade>
        )}
        {!isUser && (
          <>
            <Fade in={!isUser}>
              <Grid item xs={4} spacing={2}>
                <TextFieldController
                  inputProps={{ disabled: readOnly }}
                  label={dataElementsDescriptions["USER_FIRSTNAME"]}
                  controllerProps={{
                    name: `contact.${externalIndex}.firstName` as "contact.0.firstName",
                    control,
                  }}
                />
              </Grid>
            </Fade>

            <Fade in={!isUser}>
              <Grid item xs={4} spacing={2}>
                <TextFieldController
                  inputProps={{ disabled: readOnly }}
                  label={dataElementsDescriptions["USER_LASTNAME"]}
                  controllerProps={{
                    name: `contact.${externalIndex}.lastName` as "contact.0.lastName",
                    control,
                  }}
                />
              </Grid>
            </Fade>
            <Fade in={!isUser}>
              <Grid item xs={4}>
                <MatchcodeInputController
                  label={dataElementsDescriptions["POSITION"]}
                  controllerProps={{
                    name: `contact.${externalIndex}.position` as "contact.0.position",
                    control,
                    rules: { required:appMessage["ENTER_USER"] ?? "Enter a user"}, ///FIXPRD QUITAR
                  }}
                  readOnly={readOnly}
                  column={[
                    {
                      key: "name",
                      text: dataElementsDescriptions["POSITION"],
                    },
                    {
                      key: "description",
                      text: dataElementsDescriptions["DESCRIPTION"],
                    },
                  ]}
                  displayColumn="name"
                  options={positions.map(mapDataElementToOptionPosition)}
                />
              </Grid>
            </Fade>

            <Fade in={!isUser}>
              <Grid item xs={4} spacing={2}>
                <TextFieldController
                  inputProps={{ disabled: readOnly }}
                  label={dataElementsDescriptions["USER_EMAIL"]}
                  controllerProps={{
                    name: `contact.${externalIndex}.email` as "contact.0.email",
                    control,
                  }}
                />
              </Grid>
            </Fade>

            <Fade in={!isUser}>
              <Grid item xs={4} spacing={2}>
                <TextFieldController
                  inputProps={{ disabled: readOnly }}
                  label={dataElementsDescriptions["USER_PHONE_NUMBER"]}
                  controllerProps={{
                    name: `contact.${externalIndex}.phoneNumber` as "contact.0.phoneNumber",
                    control,
                  }}
                />
              </Grid>
            </Fade>
            <Fade in={!isUser}>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name={`contact.${externalIndex}.birthDate` as "contact.0.birthDate"}
                  rules={{ required: appMessage["ENTER_BD"] ??"Enter the birthday date" }} ///FIXPRD QUITAR
                  render={({
                    field: { value, onChange },
                  }) => (
                    <DatePicker
                      views={["month", "day"]}
                      label="Fecha de cumpleaños"
                      inputFormat='MMMM - dd'
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => <TextField {...params} />}
                      readOnly={readOnly}
                    />
                  )}
                />
              </Grid>
            </Fade>
          </>
        )}
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default ContactsDescriptionsForm;

const mapDataElementToOption = (
  dto: NonNullable<GetUsersForBusinessPartnersFormQuery["users"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.firstName + " " + dto.lastName,
  firstName: dto.firstName,
  lastName: dto.lastName,
  email: dto.email
});

const mapDataElementToOptionPosition = (
  dto: NonNullable<GetAllPositionsForBusinessPartnersFormQuery["positions"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});