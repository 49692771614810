import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon
} from "@mui/icons-material";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  PopoverPosition,
  TableCell,
  TableCellProps,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { TableRowMenu } from "./TableRowMenu";
import { TableRowMenuProps } from "./TableRowMenu/template";

export type TableRowProps = {
  idRow: string;
  columns: {
    key: string;
    content: React.ReactNode;
    align?: TableCellProps["align"];
  }[];
  isSelected: boolean;
  menuItems: TableRowMenuProps["items"];
  onChangeChecked: (check: boolean) => void;
};

const TableRow = ({
  idRow,
  columns,
  menuItems,
  isSelected,
  onChangeChecked,
}: TableRowProps) => {

  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition>();
  const [active, setActive] = useState(false);
  
  const handleClick = (event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
    setActive(true);
    setAnchorPosition({
      left: event.clientX,
      top: event.clientY,
    });
  };

  const onClose = () => {
    setAnchorPosition(undefined);
    setActive(false);
  };

  return (
    <>
      <TableRowMenu
        items={menuItems}
        anchorPosition={anchorPosition}
        onClose={onClose}
      />

      <StyledMuiTableRow 
        id={idRow} 
        active={active}
      >
        <TableCell scope="row">
          <Checkbox
            id={`${idRow}-check`} 
            color="primary"
            checked={isSelected}
            onChange={(event) => onChangeChecked(event.target.checked)}
          />
        </TableCell>

        {columns.map(({ key, content, align }) => (
          <TableCell
            key={key}
            scope="row"
            style={{ whiteSpace: "nowrap" }} // FIXME:
            onClick={handleClick}
            align={align}
          >
            {content}
          </TableCell>
        ))}
      </StyledMuiTableRow>
    </>
  );
};

export default TableRow;

const StyledMuiTableRow = styled(MuiTableRow)<
  { active: boolean } & MuiTableRowProps
>(({ active }) => ({
  backgroundColor: active ? "white" : "rgb(245, 245, 245)",
  "&:focus": {
    backgroundColor: "white",
  },
}));

const Checkbox = (props: CheckboxProps) => (
  <MuiCheckbox
    {...props}
    icon={<RadioButtonUncheckedOutlinedIcon />}
    checkedIcon={<CheckCircleOutlinedIcon />}
  />
);
