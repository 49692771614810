import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

type MyGroupState = {
    groupId: string;
    app: string | null;
};

const initialState: MyGroupState = {
    groupId: "",
    app: null,
};

const groupSelectedReducer = createSlice({
    name: "groupSelected",
    initialState,
    reducers: {
        setGroupSelected: (state, action: PayloadAction<any>) => {
            state.groupId = action.payload;
        },
        setAppSelected: (state, action: PayloadAction<any>) => {
            state.app = action.payload;
        },
    },
});

export const {
    setGroupSelected, 
    setAppSelected
} = groupSelectedReducer.actions;

export const getGroupSelected = (state: RootState) => state;
export default groupSelectedReducer.reducer;
