import { Box } from '@mui/material';
import { MenuInstructions } from "components/Instructor/steps/menu";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { Collapse } from "./components/Collapse";
import { Table } from "./components/Table";
import { useViewAllPage } from './useViewAllPage';

// FIXME: Crear una aplicación de reportes de facturas (que no tenga permiso de editar, crear, eliminar)
// Solo visualizar
const ViewAllInvoicesPage = () => {
  
  const {
    errorSnackbar,
    appInvoices,
    menuItems,
    menuItemsRow,
    rows,
    total,
    pagination,
    sheet,
    state
  } = useViewAllPage();

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      {/* Instructor */}
      <MenuInstructions tutorialsIds={["INVOICES"]} />

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appInvoices["INVOICE_TITLE"]}
          toBack="../"
          state={state}
        />

        <Box marginY={2}>
          <Collapse 
            isCreatedVisible={true}
            menuItems={menuItems}
            sheet={sheet}
          />
        </Box>

        <Table 
          menuItems={menuItems}
          menuItemsRow={menuItemsRow}
          rows={rows}
          total={total}
          pagination={pagination}
        />
      </Box>
    </Layout>
  );
};
  
export default ViewAllInvoicesPage;