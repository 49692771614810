import { Box, styled, Tooltip } from "@mui/material";
import { green, yellow } from "@mui/material/colors";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { Maybe, MigrationPackageStatus } from "__generated__/graphql/types";
import { format } from "date-fns";

export type TrafficLightStatus = MigrationPackageStatus;

type TrafficLightProps = {
  status: TrafficLightStatus;
  publishedAt: Maybe<string>;
};

const TrafficLight = ({ status, publishedAt }: TrafficLightProps) => {
  return (
    <Tooltip title={publishedAt !== null && publishedAt !== undefined && publishedAt !== 'null' 
    ? format(new Date(publishedAt!), "MMMM d, yyyy, HH:mm:ss") 
    : "No se ha publicado la migración"}  placement="top">
      <Box display="flex" justifyContent="center">
        {statusTextAndElement[status].element}
      </Box>
    </Tooltip>
  );
};

export default TrafficLight;

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: green[200],
});

const StyledDraftIcon = styled(ErrorIcon)({
  color: yellow[600],
});

const statusTextAndElement = {
  DRAFT: {
    text: "Borrador", // FIXME: traduccion
    element: <StyledDraftIcon />,
  },
  PUBLISHED: {
    text: "Publicado", // FIXME: traduccion
    element: <StyledCheckCircleIcon />,
  }
};
