import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { State } from "history";
import { useAppMessages } from "hooks/useAppMessages";
import { useDialog } from "hooks/useDialog";
import { useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

type AlertUnsavedDataInputProps = {
  isBlocked: boolean;
  unblock: () => void;
  toBack: string,
  state?: State;
};

const AlertUnsavedDataStateInput = ({
  isBlocked,
  unblock,
  toBack,
  state
}: AlertUnsavedDataInputProps) => {

  const { isOpen, open, close } = useDialog();
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  const navigate = useNavigate();
  const { appMessage } = useAppMessages("MESSAGES");

  useBlocker(() => {
    open();
  }, isBlocked);

  const onUnblock = () => {
    unblock();
    setIsExitConfirmed(true);
  };

  useEffect(() => {
    if (!isExitConfirmed) return;
    navigate(toBack, {
      state: state
    });
  }, [navigate, isExitConfirmed, toBack, state]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {appMessage["EXIT_PAGE"] ?? "Exit page?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {appMessage["UNSAVED_DATA"] ?? "There is unsaved data. Are you sure you want to leave?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {appMessage["KEEP_EDITING"] ?? "Keep editing"}
        </Button>
        <Button
          variant="contained"
          onClick={onUnblock}
          color="primary"
          autoFocus
        >
          {appMessage["EXIT"] ?? "Exit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertUnsavedDataStateInput;
