import { Box, IconButton, IconButtonProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { useMatch } from "react-router-dom";

export type NavbarButtonProps = {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  path: string;
  exact?: boolean;
  isLast: boolean;
  onClick: () => void;
};

const NavbarButton = ({
  path,
  Icon,
  isLast,
  exact = false,
  onClick,
}: NavbarButtonProps) => {
  const theme = useTheme();
  const isSelected = useMatch(exact ? path : `${path}/*`);
  const Wrapper = isSelected ? SelectedButtonWrapper : ButtonWrapper;

  return (
    <>
      <Wrapper>
        <StyledIconButton onClick={onClick}>
          <Icon
            fill={
              isSelected
                ? theme.palette.primary.main
                : theme.palette.primary.contrastText
            }
          />
        </StyledIconButton>
      </Wrapper>

      {isLast && <FillBox />}
    </>
  );
};

export default NavbarButton;

const ButtonWrapper = styled(Box)(({ theme }) => ({
  "&::before": {
    content: "''",
    height: "50px",
    display: "block",
    paddingTop: "10px",
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover": {
    "& svg": {
      transition: `transform ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
      transform: "scale(1.25)",
    },
  },
}));

const SelectedButtonWrapper = styled(ButtonWrapper)(({ theme }) => ({
  "&::before": {
    backgroundColor: "rgba(255,255,255,0.9)",
    content: "''",
    height: "50px",
    display: "block",
    borderRadius: "10px",
    transition: `transform ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    transform: "scaleX(1.25)",
  },
  "& div": {
    transition: `transform ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    transform: "scale(1.25)",
  },
  "&:hover svg": {
    transform: "initial",
  },
}));

const StyledIconButton = styled((props: IconButtonProps) => (
  // @ts-ignore FIXME:
  <IconButton component="div" disableRipple {...props} size="large" />
))({
  height: "50px",
  boxSizing: "border-box",
  marginTop: "-50px",
  display: "block",
  "& svg": {
    width: "70%",
    height: "auto",
    margin: "0 auto",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  fontSize: 0, // FIXME: migracion mui v5, posicion icono
});

const FillBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.primary.main,
}));
