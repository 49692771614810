import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useMemo } from "react";
import { useGetLanguagesOptionsQuery } from "__generated__/graphql/types";

type Props = {
  generateMap: boolean;
};

const useLanguagesOptions = (
  { generateMap }: Props = { generateMap: true }
) => {
  const { language } = useSystemLanguage();
  const { data: { languages } = {} } = useGetLanguagesOptionsQuery(
    { language },
    { refetchOnMount: false }
  );

  const languagesOptions = useMemo(
    () =>
      languages?.map((language) => ({
        value: language.id,
        text: language.translation.description,
      })) ?? [],
    [languages]
  );

  const languagesOptionsMap: Record<string, string> = useMemo(
    () =>
      generateMap
        ? languages?.map((language) => ({
            value: language.id,
            text: language.translation.description,
          })) ?? {}
        : {},
    [generateMap, languages]
  );
  return {
    languagesOptions,
    languagesOptionsMap,
  };
};

export default useLanguagesOptions;
