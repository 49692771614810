import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { ExportMigrationPackageRoutes } from "./routes";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";
import { EditPage } from "./EditPage";

const ExportMigrationsPackagePage = () => {
  return (
    <Routes>
      <Route
        path={ExportMigrationPackageRoutes.VIEW_ALL}
        element={<ViewAllPage />}
      />

      <Route 
        path={ExportMigrationPackageRoutes.VIEW} 
        element={<ViewPage />} />

      <Route
        path={ExportMigrationPackageRoutes.CREATE}
        element={<CreatePage />}
      />

      <Route 
        path={ExportMigrationPackageRoutes.EDIT} 
        element={<EditPage />} />
    </Routes>
  );
};

export default ExportMigrationsPackagePage;
