import { GetLocation_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetLocation_ViewPageQuery["location"]>
): FormFields => {
  return {
    basicData: {
      location: dto.id,
      locationType: dto.locationType.id,
    },
    addressData: {
      street: dto.address.street,
      number: dto.address.number,
      floor: dto.address.floor ? dto.address.floor : "",
      department: dto.address.department ? dto.address.department : "",
      city: dto.address.city,
      postalCode: dto.address.postalCode,
      state: dto.address.state,
      country: dto.address.country,
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
    gates: {
      gates: dto.gates.map((e) => ({
        name: e.id,
        isInput: e.isInput,
        isOutput: e.isOutput,
        descriptions: e.translations.map((e) => ({
          language: e.language,
          text: e.description,
        })),
      })),
    },
  };
};
