export type UploadFileS3 = {
  url: string;
  file: File;
};

export const uploadFileS3 = async ({ url, file }: UploadFileS3) => {
  await fetch(url, {
    method: "PUT",
    body: file,
  });
};
