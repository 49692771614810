import {
  IconButton,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Clear as ClearIcon } from "@mui/icons-material";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { useLanguagesOptions } from "hooks/useLanguagesOptions";
import React from "react";
import {
  containsDefaultLanguage,
  isRepeatedLanguage,
} from "utils/translations";
import { RowProps } from "./types";

const Row = ({
  index,
  remove,
  item,
  readOnly,
  formMethods,
  externalIndex,
}: RowProps) => {
  const { control, trigger, getValues } = formMethods;
  const { languagesOptions } = useLanguagesOptions();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `sections.${externalIndex}.descriptions.${index}.language` as "sections.0.descriptions.0.language",
            control,
            rules: {
              required: "Seleccione un idioma.", // FIXME: traduccion
              validate: (language) => {
                const descriptions = getValues(
                  `sections.${externalIndex}.descriptions` as "sections.0.descriptions"
                );

                if (isRepeatedLanguage(descriptions, language))
                  return "El idioma ya ha sido seleccionado."; // FIXME: traduccion

                if (!containsDefaultLanguage(descriptions))
                  return "Debe incluir idioma default (EN)"; // FIXME: traduccion

                return true;
              },
            },
            defaultValue: item.language,
          }}
          select
        >
          {languagesOptions.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </FormTableTextFieldController>
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `sections.${externalIndex}.descriptions.${index}.step` as "sections.0.descriptions.0.step",
            control,
            defaultValue: item.step,
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `sections.${externalIndex}.descriptions.${index}.title` as "sections.0.descriptions.0.title",
            control,
            rules: { required: "Ingrese el título." }, // FIXME: traduccion
            defaultValue: item.title,
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `sections.${externalIndex}.descriptions.${index}.subtitle` as "sections.0.descriptions.0.subtitle",
            control,
            rules: { required: "Ingrese el subtítulo." }, // FIXME: traduccion
            defaultValue: item.subtitle,
          }}
        />
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
