import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { emptyCharacteristicsRow } from "./CharacteristicsColumnsForm/template";
import { emptyRatioRow } from "./RatiosColumnsForm/template";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const descriptions = useForm<FormFields["descriptions"]>({
    defaultValues: initialValue?.["descriptions"] ?? {
      descriptions: [emptyDescriptionRow],
    },
  });

  const characteristics = useForm<FormFields["characteristics"]>({
    defaultValues: initialValue?.["characteristics"] ?? {
      characteristics: [emptyCharacteristicsRow],
    },
  });

  const ratios = useForm<FormFields["ratios"]>({
    defaultValues: initialValue?.["ratios"] ?? {
      ratios: [emptyRatioRow],
    },
  });

  return { basicData, descriptions, characteristics, ratios };
};
