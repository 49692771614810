import { Clear as ClearIcon } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { Controller } from "react-hook-form";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { RowProps } from "./types";

const Row = ({ index, remove, item, readOnly, form }: RowProps) => {
  const { control, trigger, getValues } = form;

  const onRemoveRow = () => {
    remove();
    trigger();
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <KeyInputWrapper>
          <FormTableTextFieldController
            inputProps={{ disabled: readOnly }}
            controllerProps={{
              name: `idsTypes.${index}.id` as "idsTypes.0.id",
              control,
              rules: {
                required: "Ingrese el documento.", // FIXME: traduccion
                validate: (doc) => {
                  if (readOnly) return true;
                  const id = getValues("idsTypes");
                  if (
                    isRepeatedValue(
                      doc.toUpperCase(),
                      id.map(({ id }) => id.toUpperCase())
                    )
                  )
                    return "El docuento ya ha sido creado."; // FIXME: traduccion

                  return true;
                },
              },
              defaultValue: item.id,
            }}
          />
        </KeyInputWrapper>
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`idsTypes.${index}.isTaxId` as "idsTypes.0.isTaxId"}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              color="primary"
              checked={value}
              onChange={(event) => onChange(event.target.checked)}
              disabled={readOnly}
            />
          )}
        />
      </TableCell>
      <TableCell>
        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
