export enum LocationRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":locationId/view",
  EDIT = ":locationId/edit",
}

export type LocationRoutesState = {
  [LocationRoutes.EDIT]: { activeStep?: number };
};
