import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice"
export const useThemesLabels = () => {

    const name = "THEME";
    const description = "DESCRIPTION";
    const isDefault = "IS_DEFAULT_THEME";

    const dataElementsIds = [
        name, description, isDefault
    ];

    type DetailsKeys = typeof dataElementsIds[number];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels: NonNullable<Record<ColumnsKeys, string>> = {
        name: dataElementsDescriptions === undefined ? name : (dataElementsDescriptions[name] ?? name),
        description: dataElementsDescriptions === undefined ? description : (dataElementsDescriptions[description] ?? description),
        isDefault: dataElementsDescriptions === undefined ? isDefault : (dataElementsDescriptions[isDefault] ?? isDefault),
    };

    const detailsLabels: NonNullable<Record<DetailsKeys, string>> = {
        name: dataElementsDescriptions === undefined ? name : (dataElementsDescriptions[name] ?? name),
        description: dataElementsDescriptions === undefined ? description : (dataElementsDescriptions[description] ?? description),
        isDefault: dataElementsDescriptions === undefined ? isDefault : (dataElementsDescriptions[isDefault] ?? isDefault),
    };

    return { columnsLabels, detailsLabels }
}