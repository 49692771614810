import { FilterComponentProps } from "components/filters/types";
import { ascend, sortWith } from "ramda";
import React, { ComponentType } from "react";
import { Control, FieldValues, Path } from "react-hook-form";

type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> = {
  visibleFiltersKeys: TName[];
  filtersLabels: Record<TName, string>;
  control: Control<TFieldValues>;
  filtersComponents: Record<
    TName,
    ComponentType<FilterComponentProps<TFieldValues, TName>>
  >;
};

export const generateFiltersElements = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  visibleFiltersKeys,
  filtersLabels,
  control,
  filtersComponents,
}: Props<TFieldValues, TName>): JSX.Element[] => {
  return sortByLabelAsc<TName>(
    visibleFiltersKeys.map((key) => [key, filtersLabels[key]])
  ).map(([key, label]) => {
    const Component = filtersComponents[key];
    return (
      // @ts-ignore
      <Component
        key={key}
        label={label}
        controllerProps={{ name: key, control }}
      />
    );
  });
};

const sortByLabelAsc = <
  T extends string,
  V extends [key: T, label: string] = [key: T, label: string]
>(
  list: V[]
) => {
  return sortWith<V>([ascend(([, label]) => label)])(list);
};
