import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  LocationExistsDocument,
  LocationExistsQuery,
  LocationExistsQueryVariables,
  GetLocationTypesForLocationsFormQuery,
  useGetLocationTypesForLocationsFormQuery,
} from "__generated__/graphql/types";

export type BasicDataFormFields = {
  location: string;
  locationType: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;
  const { language } = useSystemLanguage();
  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "LOCATION",
    "LOCATION_TYPE",
    "DESCRIPTION",
  ]);
  const {
    data: { locationTypes } = {},
  } = useGetLocationTypesForLocationsFormQuery({ language });

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  if (locationTypes == null) return null;

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["LOCATION"]}
              controllerProps={{
                name: "location",
                control,
                rules: {
                  required: "Ingrese el Id.",
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { locationExists } = await locationExistsFn({
                      id: value,
                    });
                    return locationExists ? "El Id ya existe." : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.",
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["LOCATION_TYPE"]}
              controllerProps={{
                name: "locationType",
                control,
                rules: { required: "Selecciona el tipo de ubicación. " }, // FIXME: traduccion
              }}
              readOnly={disabled}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["LOCATION_TYPE"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={locationTypes.map(mapDataElementToOption)}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const locationExistsFn = pDebounce(
  (args: LocationExistsQueryVariables) =>
    fetcher<LocationExistsQuery, LocationExistsQueryVariables>(
      LocationExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
const mapDataElementToOption = (
  dto: NonNullable<GetLocationTypesForLocationsFormQuery["locationTypes"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});
