import { UserInput, UserType } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields, imageId: string): UserInput => {
  return {
    id: form.basicData.userName,
    firstName: form.basicData.firstName,
    lastName: form.basicData.lastName,
    email: form.basicData.email,
    phoneNumber: form.basicData.phoneNumber,
    userType: form.basicData.userType as UserType, // FIXME:
    language: form.configurationData.language,
    dateFormat: form.configurationData.dateFormat,
    timeFormat: form.configurationData.timeFormat,
    decimalSeparator: form.configurationData.decimalSeparator,
    numberDecimalsPlaces: form.configurationData.numberDecimalsPlaces,
    imageId,
    businessProfileId: form.businessProfile.businessProfileId,
  };
};
