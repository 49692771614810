import { LocationInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): LocationInput => {
  return {
    id: form.basicData.location,
    address: {
      street: form.addressData.street,
      number: form.addressData.number,
      floor: form.addressData.floor,
      department: form.addressData.department,
      city: form.addressData.city,
      postalCode: form.addressData.postalCode,
      state: form.addressData.state,
      country: form.addressData.country,
    },
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
    locationType: form.basicData.locationType,
    gates: form.gates.gates.map((gate) => ({
      id: gate.name,
      isInput: gate.isInput,
      isOutput: gate.isOutput,
      translations: gate.descriptions.map((description) => ({
        language: description.language,
        description: description.text,
      })),
    })),
    storages: [], // TODO:
  };
};
