import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import React from "react";
import { KpiProps } from "./types";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useGetKpisForCreateTileQuery } from "__generated__/graphql/types";

const KpiForm = ({ form, readOnly = false, onEdit }: KpiProps) => {
  const { control } = form;

  const { data: { kpis } = {} } = useGetKpisForCreateTileQuery();

  const { dataElementsDescriptions } = useDataElementDescription([
    "KPI",
    "DESCRIPTION",
  ]);

  if (kpis === undefined) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool placement="top-left" control={control} />

      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <MatchcodeInputController
            label={dataElementsDescriptions["KPI"]}
            controllerProps={{
              name: "kpiId",
              control,
              rules: { required: "Seleccione el KPI." }, // FIXME: traduccion
            }}
            column={[{ key: "kpi", text: dataElementsDescriptions["KPI"] }]}
            displayColumn="kpi"
            options={kpis.map((e) => ({ id: e.id, kpi: e.id }))}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default KpiForm;
