import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { BusinessObjectRow } from "./BusinessObjectsForm/types";
import { FormFields } from "./types";

export const emptyBusinessObjRow: BusinessObjectRow = {
  id: "",
  idsBusinessObject: {}
};

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  
  const description = useForm<FormFields["description"]>({
    defaultValues: initialValue?.["description"] ?? {
      text : ""
    },
  });

  const businessObjects = useForm<FormFields["businessObjects"]>({
    defaultValues: initialValue?.["businessObjects"] ?? {
      businessObjects: [emptyBusinessObjRow],
    },
  });

  return { description, businessObjects };
};
