import React from "react";
import { StyledCollapse } from "../StyledCollapse";
import { StepInfo } from "../StepInfo";
import { StepInfoProps } from "../StepInfo/template";

const FormHeader = (props: StepInfoProps) => {
  return (
    <StyledCollapse>
      <StepInfo {...props} />
    </StyledCollapse>
  );
};

export default FormHeader;
