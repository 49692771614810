import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import { useGetOwnMigrationPackageForPageViewQuery } from "__generated__/graphql/types";
import { ExportMigrationPackageRoutes, ExportMigrationPackageRoutesState } from "../routes";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { useUpdateMigrationPackageInputMutation } from '../../../__generated__/graphql/types';
import { mapFormToDto } from "../CreatePage/utils";
import { mapDtoToForm } from "../ViewPage/utils";

type Route = ExportMigrationPackageRoutes.EDIT;
type LocationState = ExportMigrationPackageRoutesState[Route];

const EditPage = () => {

  const navigate = useNavigate();
  const { packageMigrationId } = useRouteParams<ExportMigrationPackageRoutes.EDIT>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const {
    data: { ownMigrationPackage } = {},
    isFetchedAfterMount,
  } = useGetOwnMigrationPackageForPageViewQuery({
    id: packageMigrationId,
  });

  const {
    mutateAsync: updateMigrationPackage,
  } = useUpdateMigrationPackageInputMutation();

  const onSave = async (form: FormFields) => {
    const ownMigrationPackageUpdate = mapFormToDto(form);
    await updateMigrationPackage({ id: packageMigrationId, migrationPackage: ownMigrationPackageUpdate });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  const initialValues =
  ownMigrationPackage != null && isFetchedAfterMount
    ? mapDtoToForm(ownMigrationPackage)
    : undefined;

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Visualizar los datos de la migración" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValues}
          initialStep={activeStep}
          errorMessage="Error al editar la información de los paquetes de migración" // FIXME:
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
