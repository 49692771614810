import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ArrowDownward as ArrowDownwardIcon } from "@mui/icons-material";
import { Controller, UseFieldArrayReturn, UseFormReturn, } from "react-hook-form";
import { grey } from "@material-ui/core/colors";
import { Clear as ClearIcon } from "@mui/icons-material";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { GroupsMatchcode } from "../GroupsMatchcode";
import { BusinessObjectsFormFields } from "../../types";
import { BusinessObjectDto } from "../types";

export type RowProps = {
  readOnly: boolean;
  index: number;
  form: UseFormReturn<BusinessObjectsFormFields>; 
  item: UseFieldArrayReturn<BusinessObjectsFormFields>["fields"][number];
  businessObjectsByIdMap: Map<string, BusinessObjectDto>;
  remove: () => void;
  onEnterBusinessActions: (index: number) => void;
};

const Row = ({ 
  readOnly, 
  index,
  item,
  form, 
  businessObjectsByIdMap,
  remove,
  onEnterBusinessActions,
}: RowProps) => {
    
  const { control, trigger, getValues } = form;

  const onRemoveRow = () => {
    remove();
    trigger();
  };

  const bussinesOptions = Array.from(businessObjectsByIdMap.values());

  return (
    <TableRow>
      <TableCell component="th" scope="row">
         <Controller
          control={control}
          name={`businessObjects.${index}.id` as "businessObjects.0.id"}
          rules={{
            required: "Seleccione un objeto negocio.", // FIXME: traduccion

            validate: (value: string) => {
              const businessIds = getValues("businessObjects").map((e) => e.id);
              return isRepeatedValue(value, businessIds)
                ? "El objeto negocio ya ha sido seleccionado." // FIXME: traduccion
                : true;
            },
          }}
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <GroupsMatchcode
              bussinesGroup={bussinesOptions}
              value={value}
              onChange={onChange}
              invalid={invalid}
              errorMessage={error?.message}
              readOnly={readOnly}
            />
          )}
          defaultValue={item.id}
        />
      </TableCell>

      <TableCell align="right">
        
        <Tooltip
          title="Seleccionar ID's" // FIXME: traduccion
        >
          <StyledIconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={() => {
              onEnterBusinessActions(index)
            }}
          >
            <ArrowDownwardIcon />
          </StyledIconButton>
        </Tooltip>

        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

// FIXME: commons
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});