import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Edit as EditCircleIcon,
} from "@mui/icons-material";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import React from "react";
import { HeaderProps } from "./types";

const Header = ({ readOnly = false, onEdit, onAppend }: HeaderProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "ID",
    "IS_TAX_ID",
  ]);

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["ID"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["IS_TAX_ID"]}
        </StyledHeadTableCell>

        <TableCell align="right">
          {readOnly ? (
            <Tooltip
              title="Editar" // FIXME: traduccion
            >
              <IconButton aria-label="edit" size="small" onClick={onEdit}>
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Agregar fila" // FIXME: traduccion
            >
              <IconButton aria-label="add" size="small" onClick={onAppend}>
                <StyledAddCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
