import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetBusinessProfileForViewPageQuery,
  useUpdateBusinessProfileMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { BusinessProfileRoutes, BusinessProfileState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";
import useAppMessages from 'hooks/useAppMessages/hook';

type Route = BusinessProfileRoutes.EDIT;
type LocationState = BusinessProfileState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { businessProfileId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const {
    data: { businessProfile } = {},
    isFetchedAfterMount,
  } = useGetBusinessProfileForViewPageQuery({
    id: businessProfileId,
  });
  const { appMessage } = useAppMessages("BUSINESS_PROFILES");

  const {
    mutateAsync: updateBusinessProfile,
  } = useUpdateBusinessProfileMutation();

  const onSave = async (form: FormFields) => {
    const businessProfile = mapFormToDto(form);
    await updateBusinessProfile({ id: businessProfileId, businessProfile });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  const initialValues =
    businessProfile != null && isFetchedAfterMount
      ? mapDtoToForm(businessProfile)
      : undefined;

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["EDIT_BP"]}
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValues}
          initialStep={activeStep}
          errorMessage={appMessage["ERROR_EDIT"]} 
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
