import { GetLocationType_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetLocationType_ViewPageQuery["locationType"]>
): FormFields => {
  return {
    basicData: {
      name: dto.id,
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
  };
};
