import { BusinessUnitInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): BusinessUnitInput => {
  return {
    companyId: form.basicData.companyId,
    type: form.basicData.type,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
    revenueCollectors: form.revenueData?.collectors.map(({ id }) => id) ?? [],
    costCollectors: form.costData?.collectors.map(({ id }) => id)?? [],
  };
};