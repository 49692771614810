export enum BusinessPartnersRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":businessPartnerId/view",
  EDIT = ":businessPartnerId/edit",
}

export type BusinessPartnersRoutesState = {
  [BusinessPartnersRoutes.EDIT]: { activeStep?: number };
};
