import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ascend, sortWith } from "ramda";
import { RootState } from "store";
import {
  isColumnVisible,
  isPairColumnHidden,
  isPairColumnVisible,
} from "utils/columnTypesUtils";
import { push } from "utils/push";

type HiddenColumn = { isVisible: false };

type VisibleColumn = { isVisible: true; position: number };

export type Column = VisibleColumn | HiddenColumn;

export const COLUMNS_KEYS = ["name", "description"] as const;

export const columnsLabels: Record<ColumnKeys, string> = {
  name: "Nombre",
  description: "Descripción",
};

export type ColumnKeys = typeof COLUMNS_KEYS[number];

const columnsInitialValues = COLUMNS_KEYS.reduce(
  (acc, curr, index) => push(acc, curr, { isVisible: true, position: index }),
  {} as Record<ColumnKeys, Column>
);

type ColumnsState = Record<ColumnKeys, Column>;

const initialState: ColumnsState = columnsInitialValues;

const viewColumnsSlice = createSlice({
  name: "locationTypesColumns",
  initialState,
  reducers: {
    hideColumn: (state, action: PayloadAction<{ id: ColumnKeys }>) => {
      const { id } = action.payload;
      state[id] = { isVisible: false };
    },
    showColumn: (state, action: PayloadAction<{ id: ColumnKeys }>) => {
      const { id } = action.payload;
      const positions = Object.values(state)
        .filter(isColumnVisible<Column, VisibleColumn>())
        .map((e) => e.position);
      const maxPosition = Math.max(...positions);
      state[id] = { isVisible: true, position: maxPosition + 1 };
    },
    orderColumns: (state, action: PayloadAction<{ ids: ColumnKeys[] }>) => {
      action.payload.ids.forEach((value, index) => {
        const themeColumn = state[value];
        if (!isColumnVisible<Column, VisibleColumn>()(themeColumn)) return;
        themeColumn.position = index;
      });
    },
  },
});

export const {
  hideColumn,
  showColumn,
  orderColumns,
} = viewColumnsSlice.actions;

export const selectColumnsValues = (state: RootState) =>
  state.locationType.columns;

const sorter = sortWith<[ColumnKeys, VisibleColumn]>([
  ascend(([, value]) => value.position),
]);

export const selectVisibleColumnsKeysInOrder = (
  state: RootState
): ColumnKeys[] => {
  const visibleColumns = Object.entries(state.locationType.columns).filter(
    isPairColumnVisible<Column, VisibleColumn, ColumnKeys>(COLUMNS_KEYS)
  );

  return sorter(visibleColumns).map(([key]) => key);
};

export const selectHiddenColumnsKeys = (state: RootState): ColumnKeys[] => {
  return Object.entries(state.locationType.columns)
    .filter(isPairColumnHidden<Column, HiddenColumn, ColumnKeys>(COLUMNS_KEYS))
    .map(([key]) => key);
};

export default viewColumnsSlice.reducer;
