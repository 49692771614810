export enum CommercialEntityRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":commercialEntityId/view",
  EDIT = ":commercialEntityId/edit",
}

export type CommercialEntityRoutesState = {
  [CommercialEntityRoutes.EDIT]: { activeStep?: number };
};
