import { combineReducers } from "@reduxjs/toolkit";
import visibleColumnsSlice from "./ViewAllPage/reducers/visibleColumnsSlice";
import filtersAndPaginationSlice from "./ViewAllPage/reducers/filtersAndPaginationSlice";
import checkedRowsSlice from "./ViewAllPage/reducers/checkedRowsSlice";

const usersReducer = combineReducers({
  columns: visibleColumnsSlice,
  filtersAndPagination: filtersAndPaginationSlice,
  rowsChecked: checkedRowsSlice,
});

export default usersReducer;
