import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  GroupExistsQueryVariables,
  GroupExistsQuery,
  GroupExistsDocument,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";

const BasicDataForm = ({
  form: { control },
  mode,
  onEdit,
}: BasicDataFormProps) => {
  const isIdReadOnly = mode !== "create";
  const isReadOnly = mode === "view";

  const { dataElementsDescriptions } = useDataElementDescription(["GROUP"]);

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={isReadOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <KeyInputWrapper>
              <TextFieldController
                label={dataElementsDescriptions["GROUP"]}
                controllerProps={{
                  name: "name",
                  control,
                  rules: {
                    required: "Ingrese el nombre del grupo.", // FIXME: traduccion
                    validate: async (value) => {
                      if (isIdReadOnly) return true;
                      const { groupExists } = await groupExistsFn({
                        groupId: value,
                      });
                      return groupExists
                        ? "El nombre ya existe." // FIXME: traduccion
                        : true;
                    },
                    pattern: {
                      value: keyNameInputRegexPattern,
                      message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                    },
                  },
                }}
                inputProps={{ disabled: isIdReadOnly }}
              />
            </KeyInputWrapper>
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BasicDataForm;

const groupExistsFn = pDebounce(
  (args: GroupExistsQueryVariables) =>
    fetcher<GroupExistsQuery, GroupExistsQueryVariables>(
      GroupExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
