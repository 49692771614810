import { DevTool } from "@hookform/devtools";
import { BackendsNamesForm } from "./BackendsNamesForm";
import { BackendsFormProps } from "./types";

const BackendsForm = ({
  form,
  mode,
  onEdit,
}: BackendsFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />
        <BackendsNamesForm
          form={form}
          mode={mode}
          onEdit={onEdit}
        />
    </>
  );
};

export default BackendsForm;