import { TableCellProps } from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import {
  TableColumnType,
  TableRowType,
} from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useSnackbar } from "hooks/useSnackbar";
import React, { useCallback, useMemo } from "react";
import { push } from "utils/push";
import {
  GetExternalMigrationPackagesForTableQuery,
  useApplyMigrationPackageMutation,
  useGetExternalMigrationPackagesForTableQuery
} from "__generated__/graphql/types";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked,
} from "../../reducers/checkedRowsSlice";
import {
  filtersLabels,
  nextPage,
  prevPageDataMigrations,
  selectPagination,
} from "../../reducers/filtersAndPaginationSlice";
import {
  ColumnsKeys,
  COLUMNS_KEYS,
  selectVisibleColumnsKeysInOrder,
} from "../../reducers/visibleColumnsSlice";
import { TrafficLight } from "./TrafficLight";
import { useAppActions } from "hooks/useAppActions";

export const useTable = () => {

  const pagination = useAppSelector(selectPagination);
  const errorSnackbar = useSnackbar();
  const { actions } = useAppActions("IMPORT_MIGRATIONS_PACKAGES");
  
  const {
    mutateAsync: applyMigrationPackage,
  } = useApplyMigrationPackageMutation();

  const dispatch = useAppDispatch();
  const orderedColumnsKeys = useAppSelector(selectVisibleColumnsKeysInOrder);
  const areAllDataElementsRowsChecked = useAppSelector(selectAreAllRowsChecked);
  const isDataElementsRowChecked = useAppSelector(selectIsRowChecked);

  const columns: TableColumnType<ColumnsKeys>[] = useMemo(
    () => orderedColumnsKeys.map((colKey) => header[colKey]),
    [orderedColumnsKeys]
  );

  const mapRowDataToRowCells = (
    dto: NonNullable<
    GetExternalMigrationPackagesForTableQuery["externalMigrationPackages"]
    >[number]
  ): TableRowType<ColumnsKeys> => {
    return {
      rowKey: JSON.stringify({packageId: dto.packageId, systemId: dto.systemId, status: dto.isApplied}),
      systemId: dto.systemId,
      packageId: dto.packageId,
      status: <TrafficLight status={dto.isApplied} appliedAt={dto.appliedAt} />
    };
  };

  const {
    data: { externalMigrationPackages,  externalMigrationPackagesCount: total  } = {},
    refetch: findMigrations,
  } = useGetExternalMigrationPackagesForTableQuery({pagination});

  const rows = useMemo(() => externalMigrationPackages?.map(mapRowDataToRowCells), [
    externalMigrationPackages,
  ]);

  const menuItems = useMemo(
    () => [
      {
        key: "apply",
        Icon: PlayArrowIcon,
        text: actions["APPLY"],
        onClick: (id: string) => {
          const { packageId, systemId, status } = JSON.parse(id);
          if(!status) {
            applyMigrationPackage({ systemId, packageId })
            .then(() => findMigrations())
            .catch(() => errorSnackbar.open());
          } else {
            errorSnackbar.open()
          }
        }
      },
    ],
    [
      actions,
      errorSnackbar,
      findMigrations,
      applyMigrationPackage
    ]
  );

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPageDataMigrations());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  return {
    rows,
    total ,
    errorSnackbar,
    columns,
    menuItems,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    pagination,
  };
};

const columnAlign: Partial<Record<ColumnsKeys, TableCellProps["align"]>> = {
  status: "center",
};

const header = COLUMNS_KEYS.reduce((acc, curr) => {
  return push(acc, curr, {
    key: curr,
    content: filtersLabels[curr].toUpperCase(),
    align: columnAlign[curr],
  });
}, {} as Record<ColumnsKeys, TableColumnType<ColumnsKeys>>);

