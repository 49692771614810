import { DevTool } from "@hookform/devtools";
import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { useFieldArray } from "react-hook-form";
import {
  useGetDataElementsForCreateTableQuery,
  useGetTablesForCreateTableQuery,
} from "__generated__/graphql/types";
import { Header } from "./Header";
import { useCharacteristicsDataElements } from "./hooks";
import { Row } from "./Row";
import {
  CharacteristicColumn,
  CharacteristicsColumnsFormFields,
  CharacteristicsColumnsTableFormProps,
} from "./types";

export const emptyCharacteristicsRow: CharacteristicColumn = {
  name: "",
  dataElementId: "",
  isPrimaryKey: false,
};

const CharacteristicsColumnsForm = ({
  form,
  readOnly = false,
  onEdit,
}: CharacteristicsColumnsTableFormProps) => {
  const { control } = form;

  const { language } = useSystemLanguage();

  const { data: { tables } = {} } = useGetTablesForCreateTableQuery();

  const {
    data: { dataElements } = {},
  } = useGetDataElementsForCreateTableQuery({ language });

  const { characteristicsDataElements } = useCharacteristicsDataElements(
    dataElements
  );

  const {
    fields,
    append,
    remove,
  } = useFieldArray<CharacteristicsColumnsFormFields>({
    control,
    name: "characteristics",
  });

  const onAppendRow = () => {
    append(emptyCharacteristicsRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  if (tables == null) return null; // TODO: loading spinner
  if (characteristicsDataElements === undefined) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <TableContainer>
        <StyledTable aria-label="simple table" size="small">
          <Header appendRow={onAppendRow} onEdit={onEdit} readOnly={readOnly} />

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                key={item.id}
                index={index}
                remove={onRemove(index)}
                item={item}
                readOnly={readOnly}
                characteristicsDataElementsByIdMap={characteristicsDataElements}
                form={form}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

export default CharacteristicsColumnsForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
