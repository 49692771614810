export enum BusinessUnitsRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":businessUnitsId/view",
  EDIT = ":businessUnitsId/edit",
}

export type BusinessUnitsRoutesState = {
  [BusinessUnitsRoutes.EDIT]: { activeStep?: number };
};
