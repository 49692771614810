import { DevTool } from "@hookform/devtools";
import { InvoiceStatus } from "__generated__/graphql/types";
import { MenuItemsProps } from "components/datadisplay/GenericTable/template";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { TableCollapsePIC } from "components/datadisplay/TableCollapsePIC";
import { SelectFilter } from "components/filters/SelectFilter";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDialog } from "hooks/useDialog";
import { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ExcelSheetData } from "types/ExcelDataSet";
import {
  Filters,
  FiltersKeys,
  selectFilters,
  selectHiddenFiltersKeys,
  selectVisibleFiltersKeys,
  setFilters
} from "../../reducers/filtersAndPaginationSlice";
import { ColumnsKeys } from "../../reducers/visibleColumnsSlice";
import { useInvoicesReportsLabels } from '../../useInvoicesReportsLabels';
import { ColumnsVisibilityDialog } from "./ColumnsVisibilityDialog";
import { FiltersVisibilityDialog } from "./FiltersVisibilityDialog";

type TrafficLightElementProps = {
  text: string;
};

type CollapseProps = {
  menuItems: MenuItemsProps[]
  sheet?: ExcelSheetData<ColumnsKeys> | undefined;
  isCreatedVisible: boolean;
};

const Collapse = ({
  sheet,
  menuItems,
  isCreatedVisible,
}: CollapseProps) => {
  
  const dispatch = useAppDispatch();

  const filtersDialog = useDialog();
  const columnsDialog = useDialog();

  const filters = useAppSelector(selectFilters);
  const visibleFiltersKeys = useAppSelector(selectVisibleFiltersKeys);
  const hiddenFiltersKeys = useAppSelector(selectHiddenFiltersKeys);

  const { columnsLabels } = useInvoicesReportsLabels();
  const { control, handleSubmit, unregister } = useForm<Filters>({
    defaultValues: filters,
  });

  const onSearch = handleSubmit((form) => {
    dispatch(setFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  const { dataElementsChoices } = useDataElementChoices(["INVOICE_STATUS"]);
  if (dataElementsChoices === undefined) return <BeatLoadingPage />;

  const statusTextAndElement: Record<InvoiceStatus, TrafficLightElementProps> = {
    CREATED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "CREATED")?.text ?? "Created",
    },
    ACCOUNTING: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "ACCOUNTING")?.text ?? "Accounting",
    },
    REJECTED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "REJECT")?.text ?? "Reject",
    },
    TRANSFERRED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "TRANSFERRED")?.text ?? "Trasnferred",
    },
    PAID: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "PAID")?.text ?? "Paid",
    },
    PARTIAL_PAYMENT: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "PARTIAL_PAYMENT")?.text ?? "Partial Payment",
    },
    CANCELLED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "CANCELLED")?.text ?? "Cancelled",
    },
  };

  const filtersComponents: Record<
    FiltersKeys,
    ComponentType<FilterComponentProps<Filters, FiltersKeys>>
  > = {
    id: TextFilter,
    purchaseOrder: TextFilter,
    purchaseOrderItem: TextFilter,
    invoiceBackendNumber: TextFilter,
    invoiceVendorNumber: TextFilter,
    headerText: TextFilter,
    fiscalYear: TextFilter,
    currency: TextFilter,
    invoiceDate: TextFilter,
    invoiceStatus: (props) => (
      <SelectFilter
        {...props}
        options={[
          { value: "CREATED", text: statusTextAndElement.CREATED.text},
          { value: "TRANSFERRED", text: statusTextAndElement.TRANSFERRED.text },
          { value: "ACCOUNTING", text: statusTextAndElement.ACCOUNTING.text },
          { value: "REJECTED", text: statusTextAndElement.REJECTED.text },
          { value: "PAID", text: statusTextAndElement.PAID.text },
          { value: "PARTIAL_PAYMENT", text: statusTextAndElement.PARTIAL_PAYMENT.text },
          { value: "CANCELLED", text: statusTextAndElement.CANCELLED.text },
        ]}
      />
    ),
  };

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: columnsLabels,
    control,
  });

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <ColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapsePIC<ColumnsKeys> 
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={() => {}}
        onExpand={() => {}}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
        menuItems={menuItems}
        sheet={sheet}
        isCreatedVisible={isCreatedVisible}
      />
    </>
  );
};

export default Collapse;