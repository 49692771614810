import {
  IconButton,
  InputAdornment,
  styled,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import React from "react";
import { FieldPath, FieldValues, useController } from "react-hook-form";
import { mapEmptyStringToUndefined } from "utils/mapEmptyStringToUndefined";
import { FilterComponentProps } from "../types";

const TextFilter = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  controllerProps,
}: FilterComponentProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange, onBlur },
  } = useController<TFieldValues, TName>(controllerProps);

  return (
    <TextField
      name={name}
      label={label}
      // @ts-ignore FIXME:
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default TextFilter;

type TextFieldProps = {
  value?: string;
  onChange: (value?: string) => void;
} & Omit<MuiTextFieldProps, "value" | "onChange">;

const TextField = ({
  name,
  label,
  value,
  onChange,
  ...rest
}: TextFieldProps) => {
  return (
    <StyledTextField
      name={name}
      label={label}
      // @ts-ignore
      variant="outlined"
      color="primary"
      size="small"
      margin="none"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              onClick={() => onChange(undefined)}
              edge="end"
              size="large"
            >
              {value !== undefined && <CloseIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value ?? ""}
      onChange={(event) => {
        onChange(mapEmptyStringToUndefined(event.target.value));
      }}
      {...rest}
    />
  );
};

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  "& fieldset": {
    borderColor: theme.palette.primary.dark,
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-root.Mui-focused fieldset": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
    borderColor: theme.palette.primary.main,
  },
}));
