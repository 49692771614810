import { Box, Grid, styled } from "@mui/material";
import petBot from "assets/img/instructor/pet-menu.png";

export type MenuInstructorProps = {
  name: string;
  navigateInstructions: () => void;
}

export type InstructionsDialogMenuProps = {
  text: string;
  options: MenuInstructorProps[];
  onClose: () => void;
}

export const InstructionsDialogMenu = ({
  text, 
  options,
  onClose
}: InstructionsDialogMenuProps) => {

  return (
    <>
      <StyledGrid container >
        <Grid container alignItems="baseline" padding={"30px 30px 0px 30px"}> 
          <Bubble>
            <TextWrapper>
              {text}
            </TextWrapper>
          </Bubble>
        </Grid>
        <Grid container padding={"10px"} margin={"20px"}> 
          {
            options.map(option => (
              <BubbleItem onClick={() => {
                onClose();
                option.navigateInstructions();
              }}>
                <TextWrapper>
                  {option.name}
                </TextWrapper>
              </BubbleItem>
            ))
          }
        </Grid>
        <Grid item>
          <Box position="relative" >
            <StyledImg alt="help" src={petBot} />
          </Box>
        </Grid>
      </StyledGrid>
    </>
  );
};

export default InstructionsDialogMenu;

const StyledGrid = styled(Grid)({
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "initial",
  flexWrap: "wrap",
  marginTop: "20px",
})

const StyledImg = styled("img")({
  height: "200px",
});

const Bubble = styled("div")(({ theme }) => ({
  minHeight: "40px",
  minWidth: "200px",
  overflow: "hidden",
  background: theme.palette.primary.main,
  display: "flex",
  margin: "0 auto",
  borderRadius: "20px",
  textAlign: "center",
  marginTop: "-60px",
  zIndex: 2
}));

const BubbleItem = styled("div")(({ theme }) => ({
  minHeight: "40px",
  minWidth: "100px",
  overflow: "hidden",
  background: theme.palette.primary.dark,
  display: "flex",
  margin: "0 auto",
  borderRadius: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "5px",
  marginBottom: "5px",
  zIndex: 2,
}));

const TextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "-webkit-fill-available",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  padding: "10px",
  textAlign: "center",
  zIndex: 3
}));

