import { DevTool } from "@hookform/devtools";
import { IdsForm } from "./IdsForm";
import BusinessObjForm from './BusinessObjForm/template';
import {
  BusinessObjectRow,

  BusinessObjectsFormProps,
} from "./types";

export const businessObjectsEmptyRow: BusinessObjectRow = {
  id: "",
  idsBusinessObject: {},
};

const BusinessObjectsForm = ({
  areDescriptionsVisible,
  form,
  externalIndex,
  businessObjectId,
  readOnly,
  onEnterBusinessActions,
  onExitBusinessActions,
  onEdit,
}: BusinessObjectsFormProps) => {
    
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <IdsForm
          onFinish={onExitBusinessActions}
          formMethods={form}
          externalIndex={externalIndex}
          readOnly={readOnly}
          onEdit={onEdit}
          businessObjectId={businessObjectId}
        />
      ) : (
        <BusinessObjForm
          form={form}
          onEnterBusinessActions={onEnterBusinessActions}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default BusinessObjectsForm;