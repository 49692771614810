import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  CommercialEntityExistsDocument,
  CommercialEntityExistsQuery,
  CommercialEntityExistsQueryVariables,
  GetCompaniesForCommercialEntitiesFormQuery,
  useGetCompaniesForCommercialEntitiesFormQuery,
} from "__generated__/graphql/types";

export type BasicDataFormFields = {
  name: string;
  company: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;

  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "COMMERCIAL_ENTITY",
    "COMPANY",
    "DESCRIPTION",
  ]);

  const { data: { companies } = {} } = useGetCompaniesForCommercialEntitiesFormQuery({});

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner
  if (companies == null) return null;

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["COMMERCIAL_ENTITY"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre.",
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const {
                      commercialEntityExists,
                    } = await commercialEntityExistsFn({
                      id: value,
                    });
                    return commercialEntityExists
                      ? "La entrada comercial ya existe."
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.",
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["COMPANY"]}
              controllerProps={{
                name: "company",
                control,
                rules: { required: "Selecciona la empresa." }, // FIXME: traduccion
              }}
              readOnly={disabled}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["COMPANY"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={companies.map(mapDataElementToOption)}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const commercialEntityExistsFn = pDebounce(
  (args: CommercialEntityExistsQueryVariables) =>
    fetcher<CommercialEntityExistsQuery, CommercialEntityExistsQueryVariables>(
      CommercialEntityExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);

const mapDataElementToOption = (
  dto: NonNullable<GetCompaniesForCommercialEntitiesFormQuery["companies"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.name,
});
