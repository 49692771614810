import { combineReducers } from "@reduxjs/toolkit";
import visibleColumnsSlice from "./ViewAllThemesPage/reducers/visibleColumnsSlice";
import themesFiltersAndPaginationSlice from "./ViewAllThemesPage/reducers/filtersAndPaginationSlice";
import checkedRowsSlice from "./ViewAllThemesPage/reducers/checkedRowsSlice";

const themesReducer = combineReducers({
  columns: visibleColumnsSlice,
  filtersAndPagination: themesFiltersAndPaginationSlice,
  rowsChecked: checkedRowsSlice,
});

export default themesReducer;
