import { DevTool } from "@hookform/devtools";
import { Grid, MenuItem } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { FormMode } from "types/Form";
import {
  FindBusinessPartnerForCreatingEmployeeQuery,
  GetAccountingEntitiesForEmployessFormQuery,
  useFindBusinessPartnerForCreatingEmployeeQuery, 
  useGetAccountingEntitiesForEmployessFormQuery
} from "__generated__/graphql/types";

export const EMPLOYEE_TYPES = ["INTERNAL", "EXTERNAL"] as const;

export type EmployeeType = typeof EMPLOYEE_TYPES[number];

export type BasicDataFormFields = {
  employeeType: EmployeeType;
  accountingEntity: string;
  businessPartner: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;

  const employeeType = useWatch({
    control: form.control,
    name: "employeeType",
  });

  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "EMPLOYEE_TYPE",
    "ACCOUNTING_ENTITY",
    "BUSINESS_PARTNERS",
    "DESCRIPTION",
    "BUSINESS_PARTNER_NAME"
  ]);

  const { dataElementsChoices } = useDataElementChoices(["EMPLOYEE_TYPE"]);

  const {
    data: { accountingEntities } = {},
  } = useGetAccountingEntitiesForEmployessFormQuery({
  });

  const { data: { businessPartners } = {},
  } = useFindBusinessPartnerForCreatingEmployeeQuery();

  if (dataElementsChoices === undefined) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner
  if (accountingEntities == null) return null;
  if (businessPartners == null) return null;

  return (
    <>
      <DevTool placement="top-right" control={control} />
      <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <TextFieldController
              inputProps={{
                disabled,
              }}
              select
              label={dataElementsDescriptions["EMPLOYEE_TYPE"]}
              controllerProps={{
                name: "employeeType",
                control,
                rules: { required: "Seleccione un tipo de Empleado." }, // FIXME: traduccion
              }}
            >
              {dataElementsChoices["EMPLOYEE_TYPE"].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
            </TextFieldController>
          </Grid>
          {employeeType === "INTERNAL" && (
            <Grid item xs={3}>
              <KeyInputWrapper>
                <MatchcodeInputController
                  label={dataElementsDescriptions["ACCOUNTING_ENTITY"]}
                  controllerProps={{
                    name: "accountingEntity",
                    control,
                    rules: { required: "Select accounting entity." }, // FIXME: traduccion
                  }}
                  readOnly={disabled}
                  column={[
                    {
                      key: "name",
                      text: dataElementsDescriptions["ACCOUNTING_ENTITY"],
                    },
                    {
                      key: "description",
                      text: dataElementsDescriptions["DESCRIPTION"],
                    },
                  ]}
                  displayColumn="name"
                  options={accountingEntities.map(mapDataElementToOption)}
                />
              </KeyInputWrapper>
            </Grid>
          )}

          {employeeType === "EXTERNAL" && (
            <Grid item xs={3}>
              <MatchcodeInputController
                label={dataElementsDescriptions["BUSINESS_PARTNERS"]}
                controllerProps={{
                  name: "businessPartner",
                  control,
                  rules: { required: "Select business partner." }
                }}
                readOnly={disabled}
                column={[
                  {
                    key: "name",
                    text: dataElementsDescriptions["BUSINESS_PARTNERS"],
                  },
                  {
                    key: "businessPartner",
                    text: dataElementsDescriptions["BUSINESS_PARTNER_NAME"],
                  },
                ]}
                displayColumn="businessPartner"
                options={businessPartners.map(mapBusinessPartnerToOption)}
              />
            </Grid>
          )}

        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BasicDataForm;


const mapDataElementToOption = (
  dto: NonNullable<
    GetAccountingEntitiesForEmployessFormQuery["accountingEntities"]
  >[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.name,
});

const mapBusinessPartnerToOption = (
  dto: NonNullable<
    FindBusinessPartnerForCreatingEmployeeQuery["businessPartners"]
  >[number]
) => ({
  id: dto.id.toUpperCase(),
  name:dto.id.toUpperCase(),
  businessPartner: dto.name.toUpperCase(),
});
