import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useNavigate } from "react-router-dom";
import { useCreateTutorialMutation } from '../../../__generated__/graphql/types';
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";

const CreatePage = () => {

  const navigate = useNavigate();

  const { mutateAsync: createTutorial } = useCreateTutorialMutation();
  const { appMessage } = useAppMessages("TUTORIALS");

  const onSave = async (form: FormFields) => {
    const tutorial = mapFormToDto(form);
    await createTutorial({ tutorial });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title={appMessage["CREATE_TUTORIAL"]} 
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage={appMessage["ERROR_CREATE"]}
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
