import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditCircleIcon,
} from "@mui/icons-material";
import { HeaderProps } from "./types";

const Header = ({ readOnly = false, onEdit }: HeaderProps) => {

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="left">
          {/* FIXME: ¿cual data element usar? */}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {
            "IDS" // FIXME: Cambiar nombre 
          }
        </StyledHeadTableCell>

        <TableCell align="right">
          {readOnly ? (
            <Tooltip
              title="Editar" // FIXME: traduccion
            >
              <IconButton aria-label="edit" size="small" onClick={onEdit}>
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <> </>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
