import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { ColDef, RowDef } from "../MatchcodeTable/types";
import { MatchcodeDisplayInput } from "./MatchcodeDisplayInput";

export type MatchcodeInputControllerProps<
  TColKeys extends string,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  readOnly?: boolean;
  column: ColDef<TColKeys>[];
  options: RowDef<TColKeys>[];
  displayColumn: TColKeys;
  label?: string;
  controllerProps: UseControllerProps<TFieldValues, TName>;
};

export const MatchcodeInputController = <
  TColKeys extends string,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  controllerProps,
  readOnly = false,
  column,
  displayColumn,
  options,
  label,
}: MatchcodeInputControllerProps<TColKeys, TFieldValues, TName>) => {
  const {
    field: { name, value, onChange },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>(controllerProps);

  const {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    // @ts-ignore
    value,
    onChange,
    searchKeys: column.map((e) => e.key),
  });

  return (
    <>
      <MatchcodeDisplayInput
        label={label}
        name={name}
        text={text?.[displayColumn] ?? ""}
        isDeleteButtonVisible={isDeleteButtonVisible}
        onClearValue={onClearValue}
        onOpenDialog={dialog.open}
        error={invalid}
        helperText={error?.message}
        readOnly={readOnly}
      />

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={column}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default MatchcodeInputController;
