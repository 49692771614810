import {
  Box,
  styled,
  SvgIconTypeMap,
  Table as MuiTable,
  TableBody,
  TableCellProps,
  TableHead
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { useMemo } from "react";
import { TableHeader } from "./components/TableHeader";
import { TableHeaderProps } from "./components/TableHeader/template";
import { TablePagination } from "./components/TablePagination";
import { TablePaginationProps } from "./components/TablePagination/template";
import { TableRow } from "./components/TableRow";

export type TableColumnType<T> = {
  key: T;
  content: React.ReactNode;
  align?: TableCellProps["align"];
};

export type RowMenuItemsProps = {
  key: string;
  menuItems: MenuItemsProps[];
}

export type MenuItemsProps = {
  key: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  text: string;
  onClick: (id: string) => void;
};

export type TableRowType<T extends string> = { rowKey: string } & Record<
  T,
  React.ReactNode
>;

export type TableProps<TKeys extends string> = {
  columns: TableColumnType<TKeys>[];
  rows: TableRowType<TKeys>[];
  menuItemsRow?: RowMenuItemsProps[] | undefined;
  areAllRowsChecked: TableHeaderProps["isChecked"];
  onChangeAllRowsChecked: TableHeaderProps["onChangeChecked"];
  isRowChecked: (rowId: string) => boolean;
  onChangeCheckedRow: (rowId: string, checked: boolean) => void;
  menuItems: MenuItemsProps[];
  rowsPerPage: number;
  totalRows: number;
} & Pick<TablePaginationProps, "onPrevPage" | "onNextPage" | "page">;

const GenericTable = <TKeys extends string>({
  columns,
  rows,
  menuItemsRow,
  areAllRowsChecked,
  menuItems,
  onChangeAllRowsChecked,
  isRowChecked,
  onChangeCheckedRow,
  onPrevPage,
  onNextPage,
  page,
  rowsPerPage,
  totalRows,
}: TableProps<TKeys>) => {
  const columnKeys = columns.map(({ key }) => key);

  const columnAlign = useMemo(
    () => new Map(columns.map(({ key, align }) => [key, align])),
    [columns]
  );

  const isPrevButtonDisabled = page === 0;
  const isNextButtonDisabled = totalRows <= (page + 1) * rowsPerPage;

  const getIdRow = (index: number) => {
    if(index === (rows.length-1)) return 'last-row'
    if(index === 0) return 'first-row'
    return `row-${index}`
  }

  return (
    <>
      <TableWrapper id="generic-table">
        <MuiTable aria-label="table" size="medium" stickyHeader>
          <StyledTableHead>
            <TableHeader
              columns={columns}
              isChecked={areAllRowsChecked}
              onChangeChecked={onChangeAllRowsChecked}
            />
          </StyledTableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                idRow={getIdRow(index)}
                key={row.rowKey}
                columns={columnKeys.map((columnKey) => ({
                  key: columnKey,
                  content: row[columnKey],
                  align: columnAlign.get(columnKey),
                }))}
                isSelected={isRowChecked(row.rowKey)}
                menuItems={
                  menuItemsRow
                    ? menuItemsRow.find(item => item.key === row.rowKey)!.menuItems
                        .map(({ key, text, Icon, onClick }) => ({
                          key,
                          text,
                          Icon,
                          onClick: () => onClick(row.rowKey),
                        }))
                    : menuItems.map(({ key, text, Icon, onClick }) => ({
                        key,
                        text,
                        Icon,
                        onClick: () => onClick(row.rowKey),
                      }))
                }
                onChangeChecked={(checked) => {
                  onChangeCheckedRow(row.rowKey, checked);
                }}
              />
            ))}
          </TableBody>
        </MuiTable>
      </TableWrapper>

      <Box mt={1}>
        <TablePagination
          page={page}
          isPrevButtonDisabled={isPrevButtonDisabled}
          isNextButtonDisabled={isNextButtonDisabled}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
        />
      </Box>
    </>
  );
};

export default GenericTable;

export const TableWrapper = styled(Box)(({ theme }) => ({
  // borderTopLeftRadius: theme.shape.wrapperBorderRadius, // FIXME: Agregar bordes redondeados sin perder efecto de scroll
  overflowY: "scroll !important" as any, //FIXME:
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-thumb": {
    height: "6px",
    border: "4px solid transparent",
    backgroundClip: "padding-box",
    borderRadius: "7px",
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  "&::-webkit-scrollbar-corner": {
    backgroundColor: "transparent",
  },
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableCell-stickyHeader": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));