import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetDataElementViewPageQuery,
  useUpdateDataElementMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { DataElementsRoutes, DataElementsRoutesState } from "../routes";
import { mapDataElementsDtoToForm } from "../ViewPage/utils";

type Route = DataElementsRoutes.EDIT;
type LocationState = DataElementsRoutesState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { dataElementId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const {
    data: { dataElement } = {},
    isFetchedAfterMount,
  } = useGetDataElementViewPageQuery({
    id: dataElementId,
  });

  const initialValue =
    dataElement != null && isFetchedAfterMount
      ? mapDataElementsDtoToForm(dataElement)
      : undefined;

  const { mutateAsync: replaceDataElement } = useUpdateDataElementMutation();

  const onSave = (form: FormFields) =>
    replaceDataElement({
      id: dataElementId,
      dataElement: mapFormToDto(form),
    });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title="Editar elemento de dato" // FIXME: traduccion
        toBack={TO_BACK_ROUTER}
      />

      <Form
        mode="edit"
        isUsed={dataElement?.isUsed ?? false}
        onSave={onSave}
        onCancel={onCancel}
        errorMessage="Error al editar el elemento de dato. Vuelva a intentar."
        initialValue={initialValue}
        initialStep={activeStep}
      />
    </Layout>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
