export enum KpiRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":kpiId/view",
  EDIT = ":kpiId/edit",
}

export type KpiRoutesState = {
  [KpiRoutes.EDIT]: { activeStep?: number };
};
