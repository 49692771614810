import { AddressFormFields } from "./AddressForm/types";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { addressEmptyRow } from "./AddressForm/AddressNamesForm/template";
import { BasicDataFormFields } from "./BasicDataForm/types";
import { IdDataFormFields } from "./IdDataForm/template";
import { FormFields } from "./types";
import { BackendsFormFields } from "./BackendDataForm/types";
import { backendsEmptyRow } from "./BackendDataForm/BackendsNamesForm/template";
import { ContactsFormFields } from "./ContactForm/types";
import { contactsEmptyRow } from "./ContactForm/ContactsNamesForm/template";
import { TypesFormFields } from "./TypesDataForm/types";
import { typesEmptyRow } from "./TypesDataForm/TypesNamesForm/template";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<BasicDataFormFields>({
    defaultValues: initialValue?.basicData,
  });

  const locations = useForm<AddressFormFields>({
    defaultValues: initialValue?.locations ?? { address: [addressEmptyRow] },
  });

  const idsData = useForm<IdDataFormFields>({
    defaultValues: initialValue?.idsData,
  });

  const businessType = useForm<TypesFormFields>({
    defaultValues: initialValue?.businessType ?? {
      types: [typesEmptyRow],
    },
  });

  const contacts = useForm<ContactsFormFields>({
    defaultValues: initialValue?.contacts ?? {
      contact: [contactsEmptyRow],
    },
  });

  const backend = useForm<BackendsFormFields>({
    defaultValues: initialValue?.backend ?? {
      backends: [backendsEmptyRow],
    },
  });

  return { basicData, locations, idsData, businessType, contacts, backend };
};
