import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetKpiForViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { KpiRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {
  const navigate = useNavigate();
  const { kpiId } = useRouteParams<KpiRoutes.VIEW>();

  const { data: { kpi } = {} } = useGetKpiForViewPageQuery({ id: kpiId });

  const initialValues = kpi && mapDtoToForm(kpi);

  const onEdit = (activeStep: number) => {
    navigate(`../../${generatePath(KpiRoutes.EDIT, { kpiId })}`, {
      state: { activeStep },
    });
  };

  if (initialValues == null) return null; // FIXME: loading spinner

  return (
    <>
      <Layout>
        <Header
          title="Ver KPI" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValues} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
