import {
  Check as CheckIcon,
  InfoOutlined as InfoOutlinedIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { AlertUnsavedDataInput } from "components/feedback/AlertUnsavedDataInput";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { SuccessMessage } from "components/feedback/SuccessMessage";
import { SaveCloseButtons } from "components/inputs/SaveCloseButtons";
import { FormStepper } from "components/navigation/FormStepper";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useFormSteps } from "hooks/useFormSteps";
import { StepBehavior } from "hooks/useFormSteps/hook";
import React from "react";
import { FormProps } from "types/Form";
import { assertUnreachable } from "utils/assert";
import { FormStep } from "utils/FormStep";
import { BasicDataForm } from "./BasicDataForm";
import { PositionHistoryForm } from "./PositionHistoryForm";
import { ContactDataForm } from "./ContactDataForm";
import { FormFields, FormStepsKeys, NonFormStepsKeys } from "./types";
import { useForms } from "./useForms";

const Form = ({
  initialValue,
  initialStep,
  errorMessage,
  onSave,
  onCancel,
  onEdit,
  mode,
}: FormProps<FormFields>) => {
  const forms = useForms(initialValue);
  const errorSnackbar = useSnackbar();

  const readOnly = mode === "view";

  const stepsKeysInOrder: StepBehavior<FormStepsKeys, NonFormStepsKeys>[] = [
    { key: "basicData", hasForm: true },
    { key: "contactData", hasForm: true },
    { key: "positionHistory", hasForm: true },
    { key: "save", isHidden: readOnly },
    { key: "success", isHidden: readOnly },
  ];

  const stepsNavigation = useFormSteps({
    forms,
    initialStep,
    errorSnackbar,
    errorMessage: errorMessage ?? "", // FIXME:,
    onSave: onSave ?? assertUnreachable, // FIXME:
    stepsKeysInOrder,
    onEdit,
    readOnly,
  });

  const unorderedSteps: Record<FormStepsKeys | NonFormStepsKeys, FormStep> = {
    basicData: {
      title: "Datos básicos", // FIXME: traduccion
      icon: <InfoOutlinedIcon />,
      subtitle: "Ingrese los datos básicos", // FIXME: traduccion
      body: (
        <BasicDataForm
          form={forms.basicData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    contactData: {
      title: "Contacto", // FIXME: traduccion
      icon: <ContactPageIcon />,
      subtitle: "Ingrese los datos de contacto", // FIXME: traduccion
      body: (
        <ContactDataForm
          form={forms.contactData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    positionHistory: {
      title: "Historial de posiciones", // FIXME: traduccion
      icon: <RoomIcon />,
      subtitle: "Ingrese el historial de posiciones", // FIXME: traduccion
      body: (
        <PositionHistoryForm
          form={forms.positionHistory}
          mode={mode}
          basicDataForm={forms.basicData} 
          onEdit={stepsNavigation.onEditActiveStep}
          readOnly={readOnly}
        />
      ),
    },
    save: {
      title: "Guardar", // FIXME: traduccion
      icon: <SaveIcon />,
      subtitle: "¿Desea guardar los valores ingresados?", // FIXME: traduccion
      isSaveButtonVisible: true,
    },
    success: {
      title: "Fin", // FIXME: traduccion
      icon: <CheckIcon />,
      body: <SuccessMessage />,
      isHeaderHidden: true,
    },
  };

  const step = unorderedSteps[stepsNavigation.activeStepKey];

  const steps = stepsNavigation.visibleStepsKeysInOrder.map(
    (key) => unorderedSteps[key]
  );

  return (
    <>
      <AlertUnsavedDataInput
        isBlocked={stepsNavigation.isRouteBlocked}
        unblock={stepsNavigation.unblockRoute}
      />

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <FormStepper
        steps={steps}
        activeStepIndex={stepsNavigation.stepper.activeStepIndex}
        isNonLinear={readOnly}
        onChangeActiveStepIndex={stepsNavigation.stepper.setActiveStepIndex}
      />

      {!step.isHeaderHidden && (
        <FormHeader
          title={step.title}
          subtitle={step.subtitle}
          leftButtonText={step.leftButtonText}
          isPreviousButtonVisible={stepsNavigation.isPreviousButtonVisible}
          isNextButtonVisible={stepsNavigation.isNextButtonVisible}
          isUpButtonVisible={stepsNavigation.isUpButtonVisible}
          onPrevious={stepsNavigation.onPrevious}
          onNext={stepsNavigation.onNext}
          onUp={stepsNavigation.onUp}
        />
      )}

      <FormBodyWrapper>{step.body}</FormBodyWrapper>

      {!readOnly && (
        <SaveCloseButtons
          isCancelButtonVisible={stepsNavigation.activeStepKey !== "success"}
          isSaveButtonVisible={step.isSaveButtonVisible ?? false}
          onCancel={onCancel ?? assertUnreachable} // FIXME:
          onSave={stepsNavigation.onClickSave}
        />
      )}
    </>
  );
};

export default Form;
