import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { AddressFields } from "../types";
import { AddressDescriptionsFormProps } from "./types";

export const addressDescriptionEmptyRow: AddressFields = {
  street: "",
  number: "",
  floor: "",
  department: "",
  city: "",
  postalCode: "",
  state: "",
  country: "",
};

const AddressDescriptionsForm = ({
  readOnly = false,
  formMethods,
  externalIndex,
  onEdit,
  onFinish,
}: AddressDescriptionsFormProps) => {
  const { control } = formMethods;

  const { dataElementsDescriptions } = useDataElementDescription([
    "STREET",
    "NUMBER",
    "FLOOR",
    "DEPARTMENT",
    "CITY",
    "POSTAL_CODE",
    "STATE",
    "COUNTRIES",
  ]);
  if (dataElementsDescriptions === undefined) return <BeatLoadingPage/>;

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["STREET"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.street` as "address.0.descriptions.street",
              control,
              rules: {
                required: "Ingrese calle.",
              },
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["NUMBER"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.number` as "address.0.descriptions.number",
              control,
              rules: {
                required: "Ingrese el numero de casa.",
              },
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["FLOOR"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.floor` as "address.0.descriptions.floor",
              control,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["DEPARTMENT"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.department` as "address.0.descriptions.department",
              control,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["CITY"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.city` as "address.0.descriptions.city",
              control,
              rules: {
                required: "Ingrese la ciudad.",
              },
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["POSTAL_CODE"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.postalCode` as "address.0.descriptions.postalCode",
              control,
              rules: {
                required: "Ingrese el cp.",
              },
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["STATE"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.state` as "address.0.descriptions.state",
              control,
              rules: {
                required: "Ingrese el estado.",
              },
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["COUNTRIES"]}
            controllerProps={{
              name: `address.${externalIndex}.descriptions.country` as "address.0.descriptions.country",
              control,
              rules: {
                required: "Ingrese el pais.",
              },
            }}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default AddressDescriptionsForm;
