import { DevTool } from "@hookform/devtools";
import { DatePicker } from "@mui/lab";
import { MuiTextFieldProps } from "@mui/lab/internal/pickers/PureDateInput";
import { Grid, styled, TextField as MuiTextField, TextField, Typography } from "@mui/material";
import { useInvoicesLabels } from "apps/InvoicesPage/useInvoicesLabels";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useGetInvoiceForExtraInformationFormQuery, useGetPurchaseOrderDetailViewPageQuery } from "__generated__/graphql/types";
import { InvoicesRoutesState } from '../../../routes';
import { InvoiceFormProps } from "./types";

const InvoiceForm = ({ 
  form, 
  readOnly = false, 
  onEdit 
}: InvoiceFormProps) => {

  const { control, setValue } = form.invoice;
  const { appMessage } = useAppMessages("MESSAGES");
  const { dataElementsDescriptions } = useDataElementDescription([
    "INVOICE_DATE",
    "INVOICE_VENDOR_NUMBER",
    "DESCRIPTION",
    "EXTRA_INFORMATION",
    "PURCHASE_ORDER",
    "REJECTION_DESC"
  ]);
  
  const location = useLocation();

  const { dataElementsChoices } = useDataElementChoices(["INVOICE_STATUS"]);

  const state = location.state as InvoicesRoutesState;
  const purchaseOrderId = state.purchaseOrderId;
  const invoiceId = state.invoiceNumber;
  
  const { formatConfig } = useLanguageAndRegionConfig();
  const { columnsLabels } = useInvoicesLabels();

  const { data: { purchaseOrder } = {} } 
  = useGetPurchaseOrderDetailViewPageQuery({
    id: purchaseOrderId
  });

  const {
    data: { invoice } = {},
  } = useGetInvoiceForExtraInformationFormQuery({ 
    id: invoiceId 
  }, {
    enabled: readOnly
  });

  useEffect(() => {
    if(purchaseOrder) {
      setValue("purchaseOrder", purchaseOrder.id)
      setValue("backend", purchaseOrder.backend)
      setValue("accountingEntity", purchaseOrder.accountingEntity)
      setValue("vendorId", purchaseOrder.vendorId)
      setValue("currency", purchaseOrder.currency)
    }
  }, [purchaseOrder, setValue]);

  if (purchaseOrder === undefined) return <BeatLoadingPage />;
  if (dataElementsDescriptions === undefined) return <BeatLoadingPage />;
  if (dataElementsChoices === undefined) return <BeatLoadingPage />;

  return (
    <>

      {/* Instructor */}
      <MenuInstructions tutorialsIds={
        readOnly 
          ? ["INVOICES_FORM_EXTRA_INFORMATION"]
          : ["INVOICES_FORM_BASIC_DATA"]
      } />

      <FormNonTableBodyWrapper>
        <DevTool placement="top-left" control={control} />

        {readOnly && (
          <StyledInfoGrid justifyContent="center">
            {/* Info extra title */}
            <Grid container wrap="nowrap" justifyContent="center" paddingBottom={"20px"}>
              <Grid item xs={9}>
                <StyledTypography variant="h6">{dataElementsDescriptions["EXTRA_INFORMATION"]}</StyledTypography>
              </Grid>
            </Grid>

            {/* Info extra line 1 */}
            <Grid container wrap="nowrap" justifyContent="center" paddingBottom={"20px"}>
              <Grid id="po" item xs={3}>
                <StyledMuiTextField
                  disabledInput={true}
                  label={dataElementsDescriptions["PURCHASE_ORDER"]}
                  value={purchaseOrderId ?? ""}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid id="invoice-backend-number" item xs={3}>
                <StyledMuiTextField
                  disabledInput={true}
                  label={columnsLabels["invoiceBackendNumber"]}
                  value={invoice?.invoiceBackendNumber ?? ""}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid id="currency" item xs={3}>
                <StyledMuiTextField
                  disabledInput={true}
                  label={columnsLabels["currency"]}
                  value={invoice?.currency ?? ""}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Info extra line 2 */}
            <Grid container wrap="nowrap" justifyContent="center"  paddingBottom={"20px"}>
              <Grid id="fiscal-year" item xs={3}>
                <StyledMuiTextField
                  disabledInput={true}
                  label={columnsLabels["fiscalYear"]}
                  value={invoice?.fiscalYear ?? ""}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid id="planned-payment-date" item xs={3}>
                <StyledMuiTextField
                  disabledInput={true}
                  label={columnsLabels["plannedPaymentDate"]}
                  value={formatConfig.dateMapper(invoice?.plannedPaymentDate)}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid id="last-payment-date" item xs={3}>
                <StyledMuiTextField
                  disabledInput={true}
                  label={columnsLabels["lastPaymentDate"]}
                  value={formatConfig.dateMapper(invoice?.lastPaymentDate)}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Info extra line 3 */}
            <Grid container wrap="nowrap" justifyContent="center" paddingBottom={"20px"}>
              <Grid id="invoice-status" item xs={3}>
                <StyledMuiTextField
                  disabledInput={true}
                  label={columnsLabels["invoiceStatus"]}
                  value={
                    dataElementsChoices["INVOICE_STATUS"]
                    .find((e: any) =>  e.value === invoice?.invoiceStatus)?.text ?? ""
                  }
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {
                invoice?.rejectionReason &&
                <Grid item xs={3}>
                  <StyledMuiTextField
                    disabledInput={true}
                    label={dataElementsDescriptions["REJECTION_DESC"]}
                    value={invoice?.rejectionReason ?? ""}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                </Grid> 
              }
            </Grid>
          </StyledInfoGrid>
        )}

        <Grid container wrap="nowrap" justifyContent="center">
          <Grid item id="invoice_vendor_number" xs={3}>
            <TextFieldController
              inputProps={{ disabled: readOnly, style: {height: "40px"}}} //FIXME: Estilo del Input
              label={dataElementsDescriptions["INVOICE_VENDOR_NUMBER"]}
              controllerProps={{
                name: "invoiceVendorNumber",
                control,
                rules: {
                  required: appMessage["FIELD_REQUIRED"] ?? "Field required",
                },
              }}
            />
            </Grid>
            <Grid item id="description" xs={3}>
              <TextFieldController
                inputProps={{ disabled: readOnly, style: {height: "40px"}}} //FIXME: Estilo del Input
                label={dataElementsDescriptions["DESCRIPTION"]}
                controllerProps={{
                  name: "headerText",
                  control,
                  rules: {
                    required: !readOnly && (appMessage["FIELD_REQUIRED"] ?? "Field required"),
                  },
                }}
              />
            </Grid>
            <Grid item id="invoice_date" xs={3}>
            <Controller
              control={ control }
              name={"invoiceDate"}
              rules={{ required: appMessage["FIELD_REQUIRED"] ?? "Field required" }} 
              render={({
                field: { value, onChange },
                fieldState: { invalid, error },
              }) => (
                <DatePicker
                  views={["year", "month", "day"]}
                  label={dataElementsDescriptions["INVOICE_DATE"]}
                  inputFormat={formatConfig.formats.date}
                  value={ value }
                  onChange={ onChange }
                  renderInput={(params) => 
                    <TextField 
                      fullWidth={true}
                      {...params} 
                    />
                  }
                  readOnly={readOnly}
                  mask={formatConfig.formats.dateMask}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default InvoiceForm;

const StyledMuiTextField = styled(MuiTextField)<
  MuiTextFieldProps & { disabledInput: boolean }
>(({ disabledInput, theme }) => ({
  "& .MuiInputBase-root:hover fieldset": {
    borderColor: disabledInput
      ? "rgba(0, 0, 0, 0.23)" // FIXME: hardcoded
      : undefined,
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: theme.palette.text.primary,
    textFillColor: theme.palette.text.primary,
  },
}));

const StyledInfoGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(6),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));
