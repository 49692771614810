import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { ImagePicker } from "components/inputs/ImagePicker";
import useAppMessages from 'hooks/useAppMessages/hook';
import { UseFormReturn } from "react-hook-form";

export type BackgroundImageFormFields = {
  backgroundImage: File;
};

export type BackgroundImageFormProps = {
  form: UseFormReturn<BackgroundImageFormFields>;
  readOnly?: boolean;
};

const BackgroundImageForm = ({
  form,
  readOnly = false,
}: BackgroundImageFormProps) => {
  
  const { control } = form;
  const { appMessage } = useAppMessages("THEMES");

  return (
    <FormNonTableBodyWrapper readOnly={readOnly}>
      <DevTool control={control} placement="top-left" />

      <Grid container justifyContent="center">
        <Grid item>
          <ImagePicker
            label={appMessage["BACKG_IMAGE"]}
            tooltipTitle={appMessage["SELECT_BG_IMG"]}
            controllerProps={{
              name: "backgroundImage", // react hook form no infiere que name tiene que ser keyof Step1FormFields
              control,
            }}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BackgroundImageForm;
