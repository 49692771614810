import { useLocation, useParams } from "react-router-dom";

type ExtractRouteParams<T> = T extends `${string}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  : T extends `${string}:${infer Param}`
  ? { [k in Param]: string }
  : {};

export function generatePath<T extends string>(
  path: T,
  ...[params]: {} extends ExtractRouteParams<T> ? [] : [ExtractRouteParams<T>]
): string {
  return params === undefined
    ? path
    : Object.entries(params).reduce(
        (previousValue: string, [param, value]) =>
          previousValue.replace(`:${param}`, `${value}`),
        path
      );
}

export const joinPaths = (...paths: string[]) => {
  return paths.filter((e) => e !== "/").join("/");
};

export const useRouteParams = <T>() => {
  return useParams() as unknown extends T ? undefined : ExtractRouteParams<T>;
};

export const useLocationState = <TState>() => {
  const { state } = useLocation();
  return state === null ? undefined : ((state as unknown) as TState);
};
