import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import {
  Box,
  Button, Grid, styled
} from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { MenuInstructions } from 'components/Instructor/steps/menu';
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from 'components/layout/LoadingLayoutPage';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSnackbar } from "hooks/useSnackbarV2";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetInvoiceItemsForViewPageQuery
} from "__generated__/graphql/types";
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { useGetAllInvoicesQuery } from '../../../__generated__/graphql/types';
import { InvoiceVendorNumberDirectRoutes } from '../routes';
import { GroupsMatchcode } from "./components/GroupsMatchcode";

export type VendorInvoiceInformation = {
  invoiceNumber: string,
  invoiceVendorNumber: string,
}

const InvoiceVendorNumberSelectPage = () => {

  const [invoiceVendorInfo, setInvoiceVendorInfo] = React.useState<VendorInvoiceInformation | null>(null);
  const [validationMessage, setMessage] = React.useState<string | undefined>(undefined);

  const navigate = useNavigate();
  
  const errorSnackbar = useSnackbar();

  const { data: { invoices } = {} } = useGetAllInvoicesQuery();
  const { refetch: findInvoiceItem } = useGetInvoiceItemsForViewPageQuery({
    invoiceNumber: invoiceVendorInfo?.invoiceNumber ?? "0"
  });
  
  const { appMessage } = useAppMessages("MESSAGES");
  const { appMessage: appPurchaseOrdersItems } = useAppMessages("INVOICES");
  const { dataElementsDescriptions } = useDataElementDescription(
    ["INVOICE_ID", "INVOICE_BACKEND_NUMBER", "INVOICE_VENDOR_NUMBER"]
  );

  if (invoices == null) return <LoadingLayoutPage />;
  if (dataElementsDescriptions == null) return <LoadingLayoutPage />;

  const changeInvoice = (value:string | undefined) => {

    if(!value) {
      setInvoiceVendorInfo(null);
      return;
    }

    const {invoiceVendorNumber, id} = JSON.parse(value);

    setMessage(undefined);
    setInvoiceVendorInfo({
      invoiceNumber: id,
      invoiceVendorNumber: invoiceVendorNumber
    });
  }

  const navigateInvoices = () => {

    if(!invoiceVendorInfo) {
      setMessage(appMessage["CHOOSE_ONE"])
      return;
    }

    findInvoiceItem().then(e => {
      if(e.data?.invoiceItem) {
        const item = e.data?.invoiceItem
        .find(e => e?.invoiceNumber === invoiceVendorInfo?.invoiceNumber);
    
        navigate(InvoiceVendorNumberDirectRoutes.PATH_INVOICES_VIEW, {
          state: {
            invoiceNumber: invoiceVendorInfo?.invoiceNumber,
            purchaseOrderId: item?.purchaseOrder,
            purchaseOrderItemsIds: [item?.purchaseOrderItem],
            toBack: TO_BACK_ROUTER
          }
        });
      }
    });
  }

  const invoicesFiltered = invoices
    .filter(e => e && e.invoiceVendorNumber)
    .map((e): VendorInvoiceInformation => ({
      invoiceNumber: e!.id,
      invoiceVendorNumber: e!.invoiceVendorNumber!!
    }))

  return (
    <Layout>
      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      {/* Instructor */}
      <MenuInstructions tutorialsIds={["INVOICES_DISPLAY"]} />

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appPurchaseOrdersItems["INVOICE_TITLE"]}
          toBack={TO_BACK_ROUTER}
        />

        <BodyWrapper readOnly={false} >
          <Grid container  direction="column" spacing={2} xs={12} alignItems="center" justifyContent="center">
            <GridGroupFields item id="invoice-vendor-number-group" xs={10}>
              <GroupsMatchcode
                invoices={invoicesFiltered}
                dataElementsDescriptions={dataElementsDescriptions}
                value={ 
                  invoiceVendorInfo
                  ? JSON.stringify({
                    id: invoiceVendorInfo?.invoiceVendorNumber, 
                    invoiceVendorNumber: invoiceVendorInfo?.invoiceVendorNumber
                  })
                  : undefined
                 }
                onChange={(value) => changeInvoice(value)}
                text={invoiceVendorInfo?.invoiceVendorNumber}
                invalid={false}
                errorMessage={validationMessage}
                readOnly={false}
              />
            </GridGroupFields>
            <Grid item>
              <ButtonStyled 
                id="find-button"
                aria-label="remove" 
                size="medium" 
                endIcon={<AssignmentOutlinedIcon/>} 
                onClick={() => navigateInvoices()}
              >                
                {appMessage["FIND"] ?? "Find"}
              </ButtonStyled>
            </Grid>
          </Grid>
        </BodyWrapper>

      </Box>
    </Layout>
  );
};

export default InvoiceVendorNumberSelectPage;

const TO_BACK_ROUTER = "../../";

const ButtonStyled = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const BodyWrapper = styled(FormNonTableBodyWrapper)(({theme}) => ({
  marginTop: theme.spacing(1)
}));

const GridGroupFields = styled(Grid)(({theme}) => ({
  minWidth: '300px', // FIXME:
  maxWidth: '300px', // FIXME:
  textAlign:'center',
  alignItems: 'center'
}));

