import { InvoiceInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";
import { format } from "date-fns";
import { isoLocalDateFormat } from "utils/GenericFormats";

export const mapFormToCreateDto = (
  form: FormFields,
  invoiceFileId: string
): InvoiceInput => {
  return {
    accountingEntity: form.invoice.accountingEntity,
    backend: form.invoice.backend,
    vendorId: form.invoice.vendorId,
    currency: form.invoice.currency,
    invoiceDate: isoLocalDateFormat(form.invoice.invoiceDate),
    invoiceVendorNumber: form.invoice.invoiceVendorNumber,
    fiscalYear: format(form.invoice.invoiceDate, "yyyy"),
    headerText: form.invoice.headerText,
    toTransfer: form.send.send,
    physicalInvoice: invoiceFileId,
    items: form.positions.positions.map((e) => ({
      id: null,
      purchaseOrder: form.invoice.purchaseOrder,
      purchaseOrderItem: e.purchaseOrderItemId,
      backend: form.invoice.backend,
      productId: e.productId,
      quantity: e.quantity,
      unit: e.unit,
      netUnitPrice: e.netUnitPrice,
      taxesAmount: e.taxesAmount,
      itemText: e.itemText,
      extraChargesAmount: e.extraChargesAmount
    })),
  };
};

export const mapFormToUpdateDto = (
  form: FormFields,
  invoiceFileId: string
): InvoiceInput => {
  return {
    accountingEntity: form.invoice.accountingEntity,
    backend: form.invoice.backend,
    vendorId: form.invoice.vendorId,
    currency: form.invoice.currency,
    invoiceDate: isoLocalDateFormat(form.invoice.invoiceDate),
    invoiceVendorNumber: form.invoice.invoiceVendorNumber,
    fiscalYear: format(form.invoice.invoiceDate, "yyyy"),
    headerText: form.invoice.headerText,
    toTransfer: form.send.send,
    physicalInvoice: invoiceFileId,
    items: form.positions.positions.map((e) => ({
      id: e.id,
      purchaseOrder: form.invoice.purchaseOrder,
      purchaseOrderItem: e.purchaseOrderItemId,
      backend: form.invoice.backend,
      productId: e.productId,
      quantity: e.quantity,
      unit: e.unit,
      netUnitPrice: e.netUnitPrice,
      taxesAmount: e.taxesAmount,
      itemText: e.itemText,
      extraChargesAmount: e.extraChargesAmount
    })),
  };
};
