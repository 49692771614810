import { Box, Typography } from "@mui/material";
import { TextFieldController } from "components/inputs/TextFieldController";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useAppSelector } from "hooks/reduxHooks";
import { getMyInformation } from "components/providers/ReduxMyProvider/reducer";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

export type PersonalInformationFormFields = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  businessProfile: string;
};

export type PersonalInformationFormProps = {
  form: UseFormReturn<PersonalInformationFormFields>;
};

const PersonalInformationForm = ({ form }: PersonalInformationFormProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "USER_ID",
    "USER_FIRSTNAME",
    "USER_LASTNAME",
    "USER_EMAIL",
    "BUSINESS_PROFILE",
  ]);
  
  const { appMessage } = useAppMessages("USERS");
  const { appMessage: main } = useAppMessages("MAIN");
  const { me } = useAppSelector(getMyInformation);

  if (me == null) return <BeatLoadingPage />;
  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <Box>
      <Typography variant="h6">
        {main["PERSONAL_DATA"]}
      </Typography>

      <Box maxWidth={"300px"}>
        <Box marginTop={2}>
          <TextFieldController
            label={dataElementsDescriptions["USER_ID"]}
            controllerProps={{
              name: "userId",
              control: form.control,
              defaultValue: me?.id,
            }}
            disabled
          />
        </Box>

        <Box marginTop={2}>
          <TextFieldController
            label={dataElementsDescriptions["USER_FIRSTNAME"]}
            controllerProps={{
              name: "firstName",
              control: form.control,
              defaultValue: me?.firstName,
              rules: {
                required: appMessage["ENTER_NAME"] ?? "Please enter a name", ///FIXPRD QUITAR
              },
            }}
          />
        </Box>

        <Box marginTop={2}>
          <TextFieldController
            label={dataElementsDescriptions["USER_LASTNAME"]}
            controllerProps={{
              name: "lastName",
              defaultValue: me?.lastName,
              control: form.control,
              rules: {
                required: appMessage["ENTER_LAST_NAME"] ?? "Please enter the last name", ///FIXPRD QUITAR
              },
            }}
          />
        </Box>

        <Box marginTop={2}>
          <TextFieldController
            label={dataElementsDescriptions["USER_EMAIL"]}
            controllerProps={{
              name: "email",
              defaultValue: me?.email,
              control: form.control,
              rules: {
                required: appMessage["ENTER_MAIL"] ?? "Please enter the mail", ///FIXPRD QUITAR
                // TODO: agregar validacion de email
              },
            }}
          />
        </Box>

        <Box marginTop={2}>
          <TextFieldController
            label={dataElementsDescriptions["BUSINESS_PROFILE"]}
            controllerProps={{
              name: "businessProfile",
              control: form.control,
              defaultValue: me?.businessProfile.translation.description,
            }}
            disabled
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInformationForm;
