import {
  ArrowUpward as ArrowUpwardIcon, Error as ErrorIcon, Paid as PaidIcon, HourglassTop as HourglassTopIcon, Cancel as CancelIcon
} from "@mui/icons-material";
import { Box, styled, Tooltip } from "@mui/material";
import { green, grey, red, yellow } from "@mui/material/colors";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import React from "react";
import { InvoiceStatus } from '../../../../../../__generated__/graphql/types';

type TrafficLightElementProps = {
  text: string;
  element: any;
};

type TrafficLightProps = {
  status: InvoiceStatus;
};

const TrafficLight = ({ status }: TrafficLightProps) => {

  const { dataElementsChoices } = useDataElementChoices(["INVOICE_STATUS"]);
  if (dataElementsChoices === undefined) return <BeatLoadingPage />;

  const statusTextAndElement: Record<InvoiceStatus, TrafficLightElementProps> = {
    CREATED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "CREATED")?.text ?? "Created",
      element: <StyledCreatedIcon />,
    },
    ACCOUNTING: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "ACCOUNTING")?.text ?? "Accounting",
      element: <StyledAccountingCircleIcon />,
    },
    REJECTED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "REJECT")?.text ?? "Reject",
      element: <StyledRejectIcon />,
    },
    TRANSFERRED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "TRANSFERRED")?.text ?? "Transferred",
      element: <StyledTransferedIcon />,
    },
    PAID: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "PAID")?.text ?? "Paid",
      element: <StyledPaidIcon />,
    },
    PARTIAL_PAYMENT: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "PARTIAL_PAYMENT")?.text ?? "Partial Payment",
      element: <StyledPartialPaymentIcon />,
    },
    CANCELLED: {
      text: dataElementsChoices["INVOICE_STATUS"].find( e => e.value === "CANCELLED")?.text ?? "Cancelled",
      element: <StyledCancelledIcon />,
    },
  };

  return (
    <Tooltip title={statusTextAndElement[status].text} placement="top-start" >
      <Box display="flex">
        {statusTextAndElement[status].element}
      </Box>
    </Tooltip>
  );
};

export default TrafficLight;

const StyledAccountingCircleIcon = styled(PaidIcon)({
  color: grey[600],
});

const StyledRejectIcon = styled(ErrorIcon)({
  color: red[200],
});

const StyledCancelledIcon = styled(CancelIcon)({
  color: red[200],
});

const StyledTransferedIcon = styled(HourglassTopIcon)({
  color: "white",
  background: yellow[600],
  borderRadius: "99999px",
  padding: "2px"
});

const StyledPaidIcon = styled(PaidIcon)({
  color: green[400],
});

const StyledPartialPaymentIcon = styled(PaidIcon)({
  color: yellow[600],
});

const StyledCreatedIcon = styled(ArrowUpwardIcon)({
  color: "white",
  background: grey[600],
  borderRadius: "99999px",
  padding: "2px"
});