import {
  styled,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import React from "react";

const FormTableTextField = styled((props) => (
  <MuiTextField size="small" margin="none" fullWidth {...props} />
))<MuiTextFieldProps>(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiInputBase-root:hover fieldset": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: theme.palette.text.primary,
    textFillColor: theme.palette.text.primary,
  },
}));

export default FormTableTextField;
