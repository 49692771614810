import { DevTool } from "@hookform/devtools";
import React from "react";
import { TypesNamesForm } from "./TypesNamesForm";
import { TypesFormProps } from "./types";

const TypesForm = ({
  form,
  readOnly,
  onEdit,
}: TypesFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />
        <TypesNamesForm
          form={form}
          readOnly={readOnly}
          onEdit={onEdit}
        />
    </>
  );
};

export default TypesForm;
