import { Grid, styled } from "@mui/material";
import petBot from "assets/img/instructor/pet-front.png";
import { useEffect } from "react";
import { notNullUndefined } from '../../../../utils/notNullUndefined';
import scrollScript from '../../scripts/js-scroll';

type InstructionsDialogCenterProps = {
  text: string;
  element?: string;
  maxWidth?: string;
}

export const InstructionsDialogCenter = ({
  text,
  element,
  maxWidth,
}: InstructionsDialogCenterProps) => {

  if(!notNullUndefined(maxWidth)) maxWidth = "500px";

  // FIXME: No ejecutar con javascript
  useEffect(() => {
    if(element) scrollScript(element);
  }, [element]);

  return (
    <>
    <Grid alignItems="center" justifyContent="center" flexWrap={"wrap-reverse"} maxWidth={maxWidth} container>
      <Grid item>
        <StyledImg alt="help" src={petBot} />
      </Grid>
      <Grid item flexWrap={"wrap-reverse"}>
        <Bubble>
          <TextWrapper>
            {text}
          </TextWrapper>
        </Bubble>
      </Grid>
    </Grid>
    </>
  );
};

export default InstructionsDialogCenter;

const StyledImg = styled("img")({
  height: "200px",
});

const Bubble = styled("div")(({ theme }) => ({
  minHeight: "40px",
  minWidth: "50px",
  overflow: "hidden",
  background: theme.palette.primary.main,
  display: "flex",
  margin: "20px auto",
  borderRadius: "20px",
  textAlign: "center",
  zIndex: 2
}));

const TextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "-webkit-fill-available",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  padding: "10px",
  textAlign: "center",
  zIndex: 3
}));

