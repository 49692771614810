import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

type CheckedRowsState = {
  areAllRowsChecked: boolean;
  individualRowsChecked: Record<string, boolean>;
};

const initialState: CheckedRowsState = {
  areAllRowsChecked: false,
  individualRowsChecked: {},
};

const checkedRowsSlice = createSlice({
  name: "messagesCheckedRowsSlice",
  initialState,
  reducers: {
    checkAllRows: (state, action: PayloadAction<boolean>) => {
      const checked = action.payload;
      if (checked) {
        state.individualRowsChecked = {};
      }
      state.areAllRowsChecked = checked;
    },
    checkRow: (
      state,
      action: PayloadAction<{ id: string; checked: boolean }>
    ) => {
      const { id, checked } = action.payload;
      state.individualRowsChecked[id] = checked;
    },
  },
});

export const { checkRow, checkAllRows } = checkedRowsSlice.actions;

export const selectIsRowChecked = (state: RootState) => (id: string) =>
  state.message.rowsChecked.individualRowsChecked[id] ||
  (state.message.rowsChecked.individualRowsChecked[id] === undefined &&
    state.message.rowsChecked.areAllRowsChecked);

export const selectAreAllRowsChecked = (state: RootState) =>
  state.message.rowsChecked.areAllRowsChecked;

export default checkedRowsSlice.reducer;
