import { DevTool } from "@hookform/devtools";
import { SelectFilter } from "components/filters/SelectFilter";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { TableCollapse } from "components/datadisplay/TableCollapse";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useDialog } from "hooks/useDialog";
import { TablesRoutes } from "../../../routes";
import React, { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generatePath } from "utils/typedRoutesUtils";
import {
  selectTableFilters,
  selectTableHiddenFiltersKeys,
  selectTableVisibleFiltersKeys,
  setTableFilters,
  TableFilters,
  TableFiltersKeys,
  tableFiltersLabels,
} from "../../reducers/filtersAndPaginationSlice";
import { tableTypeMap } from "../TablesTable/useTablesTable";
import { TablesColumnsVisibilityDialog } from "./TablesColumnsVisibilityDialog";
import { TablesFiltersVisibilityDialog } from "./TablesFiltersVisibilityDialog";

const DataElementsCollapse = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const filtersDialog = useDialog();

  const columnsDialog = useDialog();

  const filters = useAppSelector(selectTableFilters);
  const visibleFiltersKeys = useAppSelector(selectTableVisibleFiltersKeys);
  const hiddenFiltersKeys = useAppSelector(selectTableHiddenFiltersKeys);

  const { control, handleSubmit, unregister } = useForm<TableFilters>({
    defaultValues: filters,
  });

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: tableFiltersLabels,
    control,
  });

  const onCreate = () => {
    navigate(generatePath(TablesRoutes.CREATE));
  };

  const onSearch = handleSubmit((form) => {
    dispatch(setTableFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <TablesFiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <TablesColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapse
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={onCreate}
        onDownload={() => {}}
        onExpand={() => {}}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
      />
    </>
  );
};

export default DataElementsCollapse;

const filtersComponents: Record<
  TableFiltersKeys,
  ComponentType<FilterComponentProps<TableFilters, TableFiltersKeys>>
> = {
  name: TextFilter,
  description: TextFilter,
  tableType: (props) => (
    <SelectFilter
      {...props}
      options={[
        {
          value: "INTERNAL_SYSTEM_TABLE",
          text: tableTypeMap.INTERNAL_SYSTEM_TABLE,
        },
        {
          value: "CONFIGURATION_TABLE",
          text: tableTypeMap.CONFIGURATION_TABLE,
        },
        {
          value: "BUSINESS_DATA_TABLE",
          text: tableTypeMap.BUSINESS_DATA_TABLE,
        },
      ]}
    />
  ),
  status: (props) => (
    <SelectFilter
      {...props}
      options={[
        { value: "ACTIVE", text: "Activo" }, // FIXME: traduccion
        { value: "INACTIVE", text: "Inactivo" }, // FIXME: traduccion
      ]}
    />
  ),
};
