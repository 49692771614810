import {
  DownloadRounded as DownloadRoundedIcon, Search as SearchIcon
} from "@mui/icons-material";
import { InvoiceStatus, useGetInvoicesReportPageQuery } from "__generated__/graphql/types";
import { TrafficLight } from "apps/InvoicesPage/ViewAllPage/components/Table/TrafficLight";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppSelector } from "hooks/reduxHooks";
import { useAllowedUserAppActions } from "hooks/useAlloweduserAppActions";
import { useAppMessages } from "hooks/useAppMessages";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useSnackbar } from "hooks/useSnackbarV2";
import { UseSnackbarReturn } from "hooks/useSnackbarV2/hook";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { downloadFile } from "utils/downloadFile";
import { notNullUndefined } from "utils/notNullUndefined";
import { InvoicesOverviewRoutes } from "../routes";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useReportPage = () => {

  const navigate = useNavigate();
  
  const { appMessage } = useAppMessages("INVOICES_REPORTS");
  const { actions } = useAllowedUserAppActions("INVOICES_REPORTS");
  const { formatConfig } = useLanguageAndRegionConfig();
  
  const errorSnackbar = useSnackbar();
  const rowsChecked = useAppSelector(selectRowsChecked);
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);

  const {
    data: { invoices, invoicesCount: total } = {},
  } = useGetInvoicesReportPageQuery({
    pagination,
    filters: {
      id: filters.id,
      purchaseOrder: filters.purchaseOrder,
      purchaseOrderItem: filters.purchaseOrderItem,
      invoiceVendorNumber: filters.invoiceVendorNumber,
      headerText: filters.headerText,
      fiscalYear: filters.fiscalYear,
      currency: filters.currency,
      invoiceDate: filters.invoiceDate,
      invoiceStatus: filters.invoiceStatus as any, // FIXME:
    }
  });

  const rows = useMemo(() => invoices?.map( dto => {
    const res: TableRowType<ColumnsKeys> = {
      rowKey: JSON.stringify({ id: dto?.id, purchaseOrder: dto?.purchaseOrder, item: dto?.purchaseOrderItem }),
      id: dto!.id,
      purchaseOrder: dto?.purchaseOrder,
      purchaseOrderItem: dto?.purchaseOrderItem,
      invoiceVendorNumber: dto?.invoiceVendorNumber,
      invoiceBackendNumber: dto?.invoiceBackendNumber,
      headerText: dto?.headerText,
      fiscalYear: dto?.fiscalYear,
      total: formatConfig.amountMapper(dto?.total),
      currency: dto?.currency,
      invoiceDate: formatConfig.dateMapper(dto?.invoiceDate),
      plannedPaymentDate: formatConfig.dateMapper(dto?.plannedPaymentDate),
      lastPaymentDate: formatConfig.dateMapper(dto?.lastPaymentDate),
      rejectionReason: dto?.rejectionReason,
      invoiceStatus: <TrafficLight status={dto!.invoiceStatus as InvoiceStatus} />,
    };
    return res;
  }) ,[invoices, formatConfig])

  const {
    data: { invoices: exportToExcel } = {},
  } = useGetInvoicesReportPageQuery({
    filters: {
      id: filters.id,
      purchaseOrder: filters.purchaseOrder,
      purchaseOrderItem: filters.purchaseOrderItem,
      invoiceVendorNumber: filters.invoiceVendorNumber,
      headerText: filters.headerText,
      fiscalYear: filters.fiscalYear,
      currency: filters.currency,
      invoiceDate: filters.invoiceDate,
      invoiceStatus: filters.invoiceStatus as any, // FIXME:
    }
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              id: dto?.id ?? "",
              purchaseOrder: dto?.purchaseOrder ?? "",
              purchaseOrderItem: dto?.purchaseOrderItem ?? "",
              invoiceVendorNumber: dto?.invoiceVendorNumber ?? "",
              invoiceBackendNumber: dto?.invoiceBackendNumber ?? "",
              headerText: dto?.headerText ?? "",
              fiscalYear: dto?.fiscalYear ?? "",
              total: formatConfig.amountMapper(dto?.total),
              currency: dto?.currency ?? "",
              invoiceDate: formatConfig.dateMapper(dto?.invoiceDate),
              plannedPaymentDate: formatConfig.dateMapper(dto?.plannedPaymentDate),
              lastPaymentDate: formatConfig.dateMapper(dto?.lastPaymentDate),
              rejectionReason: dto?.rejectionReason ?? "",
              invoiceStatus: dto?.invoiceStatus ?? ""
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel, formatConfig]
  );


  const readAction = actions.find(e => e.id === "READ");
  const downloadAction = actions.find(e => e.id === "DOWNLOAD");

  const menuItems = useMemo(
    () => [
      readAction && {
        key: "display",
        Icon: SearchIcon,
        text: readAction.translation.description,
        onClick: (row: string) => {

          if(row === "display") {
            if(validateSelectRows(rowsChecked, appMessage, errorSnackbar)) return;
            row = rowsChecked[0];
          }
          
          const { id, item, purchaseOrder} = JSON.parse(row);

          navigate(InvoicesOverviewRoutes.PATH_INVOICES_VIEW, {
            state: {
              toBack: TO_BACK,
              invoiceNumber: id,
              purchaseOrderId: purchaseOrder,
              purchaseOrderItemsIds: [item]
            }
          })
        },
      },
      downloadAction && {
        key: "download",
        Icon: DownloadRoundedIcon,
        text: downloadAction.translation.description,
        onClick: (row: string) => {

          if(row === "download") {
            if(validateSelectRows(rowsChecked, appMessage, errorSnackbar)) return;
            row = rowsChecked[0];
          }
          
          const { id } = JSON.parse(row); 
          const invoiceFile = invoices?.find( e => e?.id === id)
          if(invoiceFile) downloadFile(invoiceFile.file.url, invoiceFile.file.id, undefined, "pdf")
        },
      }
    ].filter(notNullUndefined),
    [errorSnackbar, navigate, rowsChecked, invoices, appMessage, readAction, downloadAction]
  );

  return {
    errorSnackbar,
    appMessage,
    menuItems,
    rows,
    total,
    pagination,
    sheet
  }

}

const TO_BACK = "../";

const validateSelectRows: any = (rowsChecked: string[], appMessages: any, errorSnackbar: UseSnackbarReturn) => {
  if(rowsChecked.length === 0) {
    errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
    return true;
  } else if(rowsChecked.length > 1) {
    errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
    return true;
  }
  return false;
}
