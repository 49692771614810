import { Box } from "@mui/material";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { Collapse } from "./components/Collapse";
import { Table } from "./components/Table";
import { useViewAllPage } from "./useViewAllPage";

const ViewAllPage = () => {

  const {
    errorSnackbar,
    appPurchaseOrders,
    menuItems,
    rows,
    total,
    pagination,
    sheet
  } = useViewAllPage();

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      {/* Instructor */}
      <MenuInstructions tutorialsIds={["FEIGHT_INVOICES"]} />

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appPurchaseOrders["FREIGHT_INVOICE_TITLE"]}
          toBack="../"
        />

        <Box marginY={2}>
          <Collapse 
            isCreatedVisible={false}
            menuItems={menuItems}
            sheet={sheet}
          />
        </Box>

        <Table 
          menuItems={menuItems}
          rows={rows}
          total={total}
          pagination={pagination}
        />
      </Box>
    </Layout>
  );
};
  
export default ViewAllPage;