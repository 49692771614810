import { URL_BASE } from "apis/const";
import axios from "axiosAuth";

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const res = await axios.request({
      url: URL_BASE + "/graphql",
      method: "POST",
      xsrfHeaderName: "X-CSRF-TOKEN",
      data: {
        query,
        variables,
      },
    });

    const json = res.data;

    if (json.errors) {
      const { message } = json.errors[0] || "Error..";
      throw new Error(message);
    }

    return json.data;
  };
};
