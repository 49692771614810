import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { PermissionsFormFields } from "./PermissionsForm/types";

export const usePermissionsForm = (
  actionsForm: UseFormReturn<PermissionsFormFields>
) => {
  const [areAppActionsVisible, setAreAppActionsVisible] = useState(false);
  const [appId, setAppId] = useState<string>();

  const onEnterAppActions = async (appId: string) => {
    setAppId(appId);
    setAreAppActionsVisible(true);
  };

  const exitAppActions = () => {
    setAppId(undefined);
    setAreAppActionsVisible(false);
  };

  const onExitAppActions = actionsForm.handleSubmit(() => {
    exitAppActions();
  });

  const onClickPageButton = () => {
    exitAppActions();
  };

  return {
    onEnterAppActions,
    onExitAppActions,
    areAppActionsVisible,
    onClickPageButton,
    appId,
  };
};
