import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState } from "utils/typedRoutesUtils";
import { useGetInvoiceForViewPageQuery, useGetInvoiceItemsForViewPageQuery } from '../../../__generated__/graphql/types';
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { InvoicesRoutes, InvoicesRoutesState } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewInvoicePage = () => {

  const navigate = useNavigate();
  
  const { appMessage } = useAppMessages("INVOICES");
  const state = useLocationState<InvoicesRoutesState>();

  const invoiceId = state!.invoiceNumber;
  const purchaseOrderId = state!.purchaseOrderId;
  const purchaseOrdersItemsId = state!.purchaseOrderItemsIds;
  const toBack = state!.toBack;

  const [initialValues, setInitialValues] = useState<FormFields>();

  const {
    data: { invoice } = {},
    isFetchedAfterMount,
  } = useGetInvoiceForViewPageQuery({ 
    id: invoiceId 
  });

  const {
    data: { invoiceItem } = {},
  } = useGetInvoiceItemsForViewPageQuery({ 
    invoiceNumber: invoiceId 
  });

  const onEdit = (activeStep: number) => {
    navigate("../"+InvoicesRoutes.PATH_INVOICES_EDIT, {
      state: { 
        toBack: toBack,
        invoiceNumber: invoiceId,
        activeStep: activeStep,
        purchaseOrderId: purchaseOrderId,
        purchaseOrdersItemsId: purchaseOrdersItemsId
      },
    });
  };

  useEffect(() => {
    if (invoice == null) return;
    if (invoiceItem == null) return;
    if (!isFetchedAfterMount) return;

    mapDtoToForm(
      invoice, 
      invoiceItem, 
      purchaseOrderId, 
    ).then((v) => setInitialValues(v));

  }, [invoice, invoiceItem, isFetchedAfterMount, purchaseOrderId]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["VIEW_INVOICE_TITLE"]}
          toBack={toBack}
          state={state}
        />

        <Form mode="view" initialValue={initialValues} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewInvoicePage;
