import { DevTool } from "@hookform/devtools";
import React from "react";
import { ActionsForm } from "./ActionsForm";
import { AppsForm } from "./AppsForm";
import { PermissionsFormProps } from "./types";

const PermissionsForm = ({
  areDescriptionsVisible,
  onEnterAppActions,
  onExitAppActions,
  forms,
  appId,
  readOnly,
  onEdit,
}: PermissionsFormProps) => {
  return (
    <>
      <DevTool control={forms.permissions.control} placement="top-left" />

      {areDescriptionsVisible && appId !== undefined ? (
        <ActionsForm
          onFinish={onExitAppActions}
          forms={forms}
          appId={appId}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      ) : (
        <AppsForm
          forms={forms}
          onEnterAppActions={onEnterAppActions}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default PermissionsForm;
