import {
  Check as CheckIcon,
  InfoOutlined as InfoOutlinedIcon,
  ListRounded as ListRoundedIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { AlertUnsavedDataInput } from "components/feedback/AlertUnsavedDataInput";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { SuccessMessage } from "components/feedback/SuccessMessage";
import { DescriptionFormControl } from "components/inputs/DescriptionFormControl";
import { SaveCloseButtons } from "components/inputs/SaveCloseButtons";
import { FormStepper } from "components/navigation/FormStepper";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useFormSteps } from "hooks/useFormSteps";
import { StepBehavior } from "hooks/useFormSteps/hook";
import React from "react";
import { FormProps } from "types/Form";
import { assertUnreachable } from "utils/assert";
import { FormStep } from "utils/FormStep";
import { BasicDataForm } from "./BasicDataForm";
import { AddressDataForm } from "./AddressDataForm";
import { GatesForm } from "./GatesForm";
import { useGatesDescriptionsForm } from "./useGatesDescriptionsForm";
import { FormFields, FormStepsKeys, NonFormStepsKeys } from "./types";
import { useForms } from "./useForms";

const Form = ({
  initialValue,
  initialStep,
  errorMessage,
  onSave,
  onCancel,
  onEdit,
  mode,
}: FormProps<FormFields>) => {
  const forms = useForms(initialValue);
  const errorSnackbar = useSnackbar();

  const readOnly = mode === "view";

  const gatesDescriptions = useGatesDescriptionsForm(forms.gates);

  const stepsKeysInOrder: StepBehavior<FormStepsKeys, NonFormStepsKeys>[] = [
    { key: "basicData", hasForm: true },
    { key: "descriptions", hasForm: true },
    { key: "addressData", hasForm: true },
    {
      key: "gates",
      hasForm: true,
      isNestedPage: gatesDescriptions.areDescriptionsVisible,
      onClickPrevButton: gatesDescriptions.onClickPageButton,
    },
    { key: "save", isHidden: readOnly },
    { key: "success", isHidden: readOnly },
  ];

  const stepsNavigation = useFormSteps({
    forms,
    initialStep,
    errorSnackbar,
    errorMessage: errorMessage ?? "", // FIXME:,
    onSave: onSave ?? assertUnreachable, // FIXME:
    stepsKeysInOrder,
    onEdit,
    readOnly,
  });

  const unorderedSteps: Record<FormStepsKeys | NonFormStepsKeys, FormStep> = {
    basicData: {
      title: "Datos básicos",
      icon: <InfoOutlinedIcon />,
      subtitle: "Ingrese los datos de la ubicación",
      body: (
        <BasicDataForm
          form={forms.basicData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    descriptions: {
      title: "Descripciones",
      icon: <ListRoundedIcon />,
      subtitle: "Ingrese las descripciones de la ubicación",
      body: (
        <DescriptionFormControl
          form={forms.descriptions}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    addressData: {
      title: "Dirección",
      icon: <RoomIcon />,
      subtitle: "Ingrese los datos de dirección",
      body: (
        <AddressDataForm
          form={forms.addressData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    gates: {
      title: "Puertas",
      icon: <InfoOutlinedIcon />,
      subtitle: "Ingrese los datos de las puertas",
      leftButtonText: gatesDescriptions.name,
      body: (
        <GatesForm
          form={forms.gates}
          externalIndex={gatesDescriptions.index}
          readOnly={readOnly}
          areDescriptionsVisible={gatesDescriptions.areDescriptionsVisible}
          onEnterDescriptions={gatesDescriptions.onEnterDescriptions}
          onExitDescriptions={gatesDescriptions.onExitDescriptions}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    save: {
      title: "Guardar",
      icon: <SaveIcon />,
      subtitle: "¿Desea guardar los valores ingresados?",
      isSaveButtonVisible: true,
    },
    success: {
      title: "Fin",
      icon: <CheckIcon />,
      body: <SuccessMessage />,
      isHeaderHidden: true,
    },
  };

  const step = unorderedSteps[stepsNavigation.activeStepKey];

  const steps = stepsNavigation.visibleStepsKeysInOrder.map(
    (key) => unorderedSteps[key]
  );

  return (
    <>
      <AlertUnsavedDataInput
        isBlocked={stepsNavigation.isRouteBlocked}
        unblock={stepsNavigation.unblockRoute}
      />

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <FormStepper
        steps={steps}
        activeStepIndex={stepsNavigation.stepper.activeStepIndex}
        isNonLinear={readOnly}
        onChangeActiveStepIndex={stepsNavigation.stepper.setActiveStepIndex}
      />

      {!step.isHeaderHidden && (
        <FormHeader
          title={step.title}
          subtitle={step.subtitle}
          leftButtonText={step.leftButtonText}
          isPreviousButtonVisible={stepsNavigation.isPreviousButtonVisible}
          isNextButtonVisible={stepsNavigation.isNextButtonVisible}
          isUpButtonVisible={stepsNavigation.isUpButtonVisible}
          onPrevious={stepsNavigation.onPrevious}
          onNext={stepsNavigation.onNext}
          onUp={stepsNavigation.onUp}
        />
      )}

      <FormBodyWrapper>{step.body}</FormBodyWrapper>

      {!readOnly && (
        <SaveCloseButtons
          isCancelButtonVisible={stepsNavigation.activeStepKey !== "success"}
          isSaveButtonVisible={step.isSaveButtonVisible ?? false}
          onCancel={onCancel ?? assertUnreachable} // FIXME:
          onSave={stepsNavigation.onClickSave}
        />
      )}
    </>
  );
};

export default Form;
