import { ViewInvoicePage } from "apps/InvoicesPage/ViewPage";
import { Route, Routes } from "react-router-dom";
import { InvoicesReportsPage } from "./report";
import { InvoicesOverviewRoutes } from "./routes";

const InvoicesOverviewPage = () => {
  return (
    <Routes>
      <Route
        path={InvoicesOverviewRoutes.PATH}
        element={<InvoicesReportsPage />}
      />
      <Route 
        path={InvoicesOverviewRoutes.PATH_INVOICES_VIEW} 
        element={<ViewInvoicePage />} 
      />
    </Routes>
  );
};

export default InvoicesOverviewPage;
