import { BusinessGroupInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): BusinessGroupInput => {
  return {
    id: form.basicData.businessGroup,
    name: form.basicData.name,
    currencyId: form.basicData.groupCurrency,
    chartOfAccountId: form.basicData.chartOfAccount,
  };
};
