import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import pDebounce from "p-debounce";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import {
  GetCountriesForCountriesIdsTypesFormQuery,
  useGetCountriesForCountriesIdsTypesFormQuery,
  CountryIdsTypesExistDocument,
  CountryIdsTypesExistQuery,
  CountryIdsTypesExistQueryVariables,
} from "__generated__/graphql/types";

export type BasicDataFormFields = {
  country: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;
  const { language } = useSystemLanguage();
  const disabled = mode === "view" || (mode === "edit" && isUsed);
  const dis = mode === "view" || mode === "edit";

  const { dataElementsDescriptions } = useDataElementDescription([
    "COUNTRIES",
    "DESCRIPTION",
  ]);
  const { data: { countries } = {} } = useGetCountriesForCountriesIdsTypesFormQuery({ language });

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  if (countries == null) return null;

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["COUNTRIES"]}
              controllerProps={{
                name: "country",
                control,
                rules: {
                  required: "Ingrese el  pais.", // FIXME: traduccion
                  validate: async (value) => {
                    if (dis) return true;
                    const { countryIdsTypesExist } = await countryExistsFn({
                      countryId: value,
                    });
                    return countryIdsTypesExist
                      ? "El pais ya tiene documentos creados." // FIXME: traduccion
                      : true;
                  },
                },
              }}
              readOnly={dis}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["COUNTRIES"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={countries.map(mapDataElementToOption)}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const mapDataElementToOption = (
  dto: NonNullable<GetCountriesForCountriesIdsTypesFormQuery["countries"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});

const countryExistsFn = pDebounce(
  (args: CountryIdsTypesExistQueryVariables) =>
    fetcher<CountryIdsTypesExistQuery, CountryIdsTypesExistQueryVariables>(
      CountryIdsTypesExistDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
