import { Box } from "@mui/material";
import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { parseISO } from "date-fns";
import { usePagination } from "hooks/usePagination";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useRouteParams } from "utils/typedRoutesUtils";
import {
  GetTableMigrationsQuery,
  useGetTableMigrationsQuery,
} from "__generated__/graphql/types";
import { TablesRoutes } from "../routes";
import { MigrationsTable } from "./MigrationsTable";
import { MigrationTableRow } from "./MigrationsTable/template";

type Route = TablesRoutes.EDIT;

const MigrationsPage = () => {
  const { language } = useSystemLanguage();

  const { tableId } = useRouteParams<Route>();

  const { data: { tableMigrations, table } = {} } = useGetTableMigrationsQuery({
    id: tableId,
    language,
  });

  const {
    page,
    rowsPerPage,
    totalRows,
    visibleRows,
    onNextPage,
    onPrevPage,
  } = usePagination(tableMigrations);

  if (visibleRows == null) return <LoadingLayoutPage />;
  if (table == null) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title="Migraciones de tabla" // FIXME: traduccion
        toBack={TO_BACK_ROUTER}
      />

      <Box marginTop={2}>
        <FormHeader
          title={tableId}
          subtitle={table.translation.description}
          isPreviousButtonVisible={false}
          isNextButtonVisible={false}
          onPrevious={() => {}} // FIXME:
          onNext={() => {}} // FIXME:
        />
      </Box>

      <FormBodyWrapper>
        <MigrationsTable
          rows={visibleRows.map(mapDtoToTable)}
          page={page}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
        />
      </FormBodyWrapper>
    </Layout>
  );
};

export default MigrationsPage;

const TO_BACK_ROUTER = "../../";

const mapDtoToTable = (
  dto: GetTableMigrationsQuery["tableMigrations"][number]
): MigrationTableRow => {
  return {
    key: dto.version.toString(),
    fileName: dto.fileName,
    version: dto.version,
    timestamp: parseISO(dto.timestamp),
    downloadUrl: dto.downloadUrl,
  };
};
