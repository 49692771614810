import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetMessage_ViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { MessagesRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {
  const navigate = useNavigate();

  const { messageId } = useRouteParams<MessagesRoutes.VIEW>();

  const {
    data: { message } = {},
    isFetchedAfterMount,
  } = useGetMessage_ViewPageQuery({
    id: messageId,
  });

  const initialValue =
    message != null && isFetchedAfterMount ? mapDtoToForm(message) : undefined;

  const onEdit = (activeStep: number) => {
    navigate(
      `../../${generatePath(MessagesRoutes.EDIT, {
        messageId,
      })}`,
      { state: { activeStep } }
    );
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Visualizar mensaje" // FIXME: traduccion
          toBack="../../"
        />

        <Form mode="view" initialValue={initialValue} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;
