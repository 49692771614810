import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";
import { useNavigate } from "react-router";
import { useCreateLocationTypeMutation } from "__generated__/graphql/types";
import React from "react";

const CreatePage = () => {
  const { mutateAsync: create } = useCreateLocationTypeMutation();

  const onSave = (form: FormFields) =>
    create({ locationType: mapFormToDto(form) });

  const navigate = useNavigate();

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header title="Make Location type" toBack={TO_BACK_ROUTER} />

        <Form
          mode="create"
          errorMessage="There has been an error creating location Type."
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
