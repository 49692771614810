import { TableCellProps } from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Search as SearchIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import {
  TableColumnType,
  TableRowType,
} from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useSnackbar } from "hooks/useSnackbar";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { push } from "utils/push";
import { generatePath } from "utils/typedRoutesUtils";
import {
  GetOwnMigrationPackagesForTableQuery,
  useGetOwnMigrationPackagesForTableQuery,
  usePublishMigrationPackageMutation,
} from "__generated__/graphql/types";
import { ExportMigrationPackageRoutes } from "../../../routes";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked,
} from "../../reducers/checkedRowsSlice";
import {
  filtersLabels,
  nextPage,
  prevPageDataMigrations,
  resetPage,
  selectPagination,
} from "../../reducers/filtersAndPaginationSlice";
import {
  ColumnsKeys,
  COLUMNS_KEYS,
  selectVisibleColumnsKeysInOrder,
} from "../../reducers/visibleColumnsSlice";
import { TrafficLight } from "./TrafficLight";
import { useDeleteMigrationPackageMutation } from '../../../../../__generated__/graphql/types';
import { useAppActions } from "hooks/useAppActions";

export const useTable = () => {

  const pagination = useAppSelector(selectPagination);
  const errorSnackbar = useSnackbar();
  const { actions } = useAppActions("EXPORT_MIGRATIONS_PACKAGES");

  const {
    mutateAsync: deleteMigrationPackage,
  } = useDeleteMigrationPackageMutation();

  const {
    mutateAsync: publishMigrationPackage,
  } = usePublishMigrationPackageMutation();

  const dispatch = useAppDispatch();
  const orderedColumnsKeys = useAppSelector(selectVisibleColumnsKeysInOrder);
  const areAllDataElementsRowsChecked = useAppSelector(selectAreAllRowsChecked);
  const isDataElementsRowChecked = useAppSelector(selectIsRowChecked);
  const navigate = useNavigate();

  const columns: TableColumnType<ColumnsKeys>[] = useMemo(
    () => orderedColumnsKeys.map((colKey) => header[colKey]),
    [orderedColumnsKeys]
  );

  const mapRowDataToRowCells = (
    dto: NonNullable<
    GetOwnMigrationPackagesForTableQuery["ownMigrationPackages"]
    >[number]
  ): TableRowType<ColumnsKeys> => {
    return {
      rowKey: dto.packageId,
      id: dto.packageId,
      description: dto.description,
      status: <TrafficLight status={dto.status} publishedAt={dto.publishedAt} />
    };
  };

  const {
    data: { ownMigrationPackages,  ownMigrationPackagesCount: total  } = {},
    refetch: findMigrations,
  } = useGetOwnMigrationPackagesForTableQuery({pagination});

  const rows = useMemo(() => ownMigrationPackages?.map(mapRowDataToRowCells), [
    ownMigrationPackages,
  ]);

  const menuItems = useMemo(
    () => [
      {
        key: "display",
        Icon: SearchIcon,
        text: actions["READ"],
        onClick: (id: string) => {
          navigate(
            generatePath(ExportMigrationPackageRoutes.VIEW, { packageMigrationId: id })
          );
        },
      },
      {
        key: "edit",
        Icon: EditIcon,
        text: actions["UPDATE"],
        onClick: (id: string) => {
          navigate(
            generatePath(ExportMigrationPackageRoutes.EDIT, { packageMigrationId: id })
          );
        },
      },
      {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: actions["DELETE"],
        onClick: (id: string) => 
          deleteMigrationPackage({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findMigrations())
            .catch(() => errorSnackbar.open()),
      },
      {
        key: "publish",
        Icon: PlayArrowIcon,
        text: actions["PUBLISH"],
        onClick: (id: string) => 
          publishMigrationPackage({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findMigrations())
            .catch(() => errorSnackbar.open()),
      },
    ],
    [
      actions,
      dispatch,
      errorSnackbar,
      navigate,
      deleteMigrationPackage,
      findMigrations,
      publishMigrationPackage
    ]
  );

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPageDataMigrations());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  return {
    rows,
    total ,
    errorSnackbar,
    columns,
    menuItems,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    pagination,
  };
};

const columnAlign: Partial<Record<ColumnsKeys, TableCellProps["align"]>> = {
  status: "center",
};

const header = COLUMNS_KEYS.reduce((acc, curr) => {
  return push(acc, curr, {
    key: curr,
    content: filtersLabels[curr].toUpperCase(),
    align: columnAlign[curr],
  });
}, {} as Record<ColumnsKeys, TableColumnType<ColumnsKeys>>);

