import { styled, Typography, useTheme } from "@mui/material";
import { hexToCSSFilter } from "hex-to-css-filter";
import React from "react";
import { colorToHex } from "utils/colorsToHex";

type IconTabProps = {
  label: string;
  src: string;
};

const IconTab = ({ 
  label,
  src
}: IconTabProps) => {

  const theme = useTheme();
  const hexPalette = colorToHex(theme.palette.username!!);
  const cssFilter = hexToCSSFilter(hexPalette); 

  const IconFilter = styled('img')({
    filter: cssFilter.filter,
  });

  return (
    <div>
      <IconFilter
        src={src}
        alt=""
      />
      <StyledTypography variant="body2">{label} </StyledTypography>
    </div>
  );
};
export default IconTab;

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.username,
}));


