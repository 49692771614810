import { TableCellProps } from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  TableColumnType,
  TableRowType,
} from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppActions } from "hooks/useAppActions";
import { useSnackbar } from "hooks/useSnackbar";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { push } from "utils/push";
import { generatePath } from "utils/typedRoutesUtils";
import {
  GetBusinessObjectsForViewPageQuery,
  useDeleteBusinessObjectMutation,
  useGetBusinessObjectsForViewPageQuery,
} from "__generated__/graphql/types";
import { BusinessObjectsRoutes } from "../../../routes";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked,
} from "../../reducers/checkedRowsSlice";
import {
  filtersLabels,
  nextPage,
  prevPageBusinessObjects,
  resetPage,
  selectFilters,
  selectPagination,
} from "../../reducers/filtersAndPaginationSlice";
import {
  ColumnsKeys,
  COLUMNS_KEYS,
  selectVisibleColumnsKeysInOrder,
} from "../../reducers/visibleColumnsSlice";
import { TrafficLight } from "./TrafficLight";
import { TrafficLightStatus } from "./TrafficLight/template";

export const useTable = () => {
  const pagination = useAppSelector(selectPagination);

  const filters = useAppSelector(selectFilters);

  const errorSnackbar = useSnackbar();

  const { actions } = useAppActions("BUSINESS_OBJECTS");

  const { language } = useSystemLanguage();

  const {
    data: { businessObjects, businessObjectsCount: total } = {},
    refetch: findBusinessObjects,
  } = useGetBusinessObjectsForViewPageQuery(
    {
      language,
      pagination,
      filters: {
        status: filters.status as any, // FIXME:
        id: filters.name,
        description: filters.description,
      },
    } // TODO: filtros
  );

  const { mutateAsync: deleteTable } = useDeleteBusinessObjectMutation();

  const dispatch = useAppDispatch();

  const orderedColumnsKeys = useAppSelector(selectVisibleColumnsKeysInOrder);

  const areAllDataElementsRowsChecked = useAppSelector(selectAreAllRowsChecked);

  const isDataElementsRowChecked = useAppSelector(selectIsRowChecked);

  const navigate = useNavigate();

  const columns: TableColumnType<ColumnsKeys>[] = useMemo(
    () => orderedColumnsKeys.map((colKey) => header[colKey]),
    [orderedColumnsKeys]
  );

  const rows = useMemo(() => businessObjects?.map(mapRowDataToRowCells), [
    businessObjects,
  ]);

  const menuItems = useMemo(
    () => [
      {
        key: "display",
        Icon: SearchIcon,
        text: actions["READ"],
        onClick: (id: string) => {
          navigate(
            generatePath(BusinessObjectsRoutes.VIEW, { businessObjectId: id })
          );
        },
      },
      {
        key: "edit",
        Icon: EditIcon,
        text: actions["UPDATE"],
        onClick: (id: string) => {
          navigate(
            generatePath(BusinessObjectsRoutes.EDIT, { businessObjectId: id })
          );
        },
      },
      {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: actions["DELETE"],
        onClick: (id: string) =>
          deleteTable({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findBusinessObjects())
            .catch(() => errorSnackbar.open()),
      },
    ],
    [
      actions,
      deleteTable,
      dispatch,
      errorSnackbar,
      findBusinessObjects,
      navigate,
    ]
  );

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPageBusinessObjects());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  return {
    rows,
    total,
    errorSnackbar,
    columns,
    menuItems,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    pagination,
  };
};

const mapRowDataToRowCells = (
  dto: NonNullable<
    GetBusinessObjectsForViewPageQuery["businessObjects"]
  >[number]
): TableRowType<ColumnsKeys> => {
  return {
    rowKey: dto.dataElement.id,
    name: dto.dataElement.id,
    description: dto.dataElement.translation.description,
    status: <TrafficLight status={dto.status as TrafficLightStatus} />, // FIXME:
  };
};

const columnAlign: Partial<Record<ColumnsKeys, TableCellProps["align"]>> = {
  status: "center",
};

const header = COLUMNS_KEYS.reduce((acc, curr) => {
  return push(acc, curr, {
    key: curr,
    content: filtersLabels[curr].toUpperCase(),
    align: columnAlign[curr],
  });
}, {} as Record<ColumnsKeys, TableColumnType<ColumnsKeys>>);
