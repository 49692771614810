import {
  Box,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  styled,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  AspectRatio as AspectRatioIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  ViewColumn as ViewColumnIcon,
} from "@mui/icons-material";
import { CollapseButton } from "components/datadisplay/StyledCollapse/CollapseButton";
import { SettingsMenu } from "components/inputs/SettingsMenu";
import React, { useState } from "react";

type TableCollapseProps = {
  filters: JSX.Element[];
  onSearch: () => void;
  onFilters: () => void;
  onDownload: () => void;
  onExpand: () => void;
  onColumns: () => void;
  onCreate: () => void;
};

const TableCollapse = ({
  filters,
  onSearch,
  onFilters,
  onDownload,
  onExpand,
  onColumns,
  onCreate,
}: TableCollapseProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [show, setShow] = useState(true);

  const onSettings = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <SettingsMenu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => {
          setAnchorEl(null);
        }}
        onView={() => {}} // TODO:
        onDelete={() => {}} // TODO:
        onEdit={() => {}} // TODO:
        onSet={() => {}} // TODO:
      />

      <Wrapper>
        <Collapse in={show}>
          <Grid
            container
            spacing={2}
            alignContent="flex-end"
            justifyContent="flex-start"
          >
            {filters.map((filter) => (
              <Grid key={filter.key} item xs={3}>
                {filter}
              </Grid>
            ))}
          </Grid>
        </Collapse>

        <CollapseButton
          show={show}
          toggleShow={() => setShow((show) => !show)}
        />

        <Box marginTop={1}>
          <Grid
            container
            spacing={1}
            alignContent="flex-end"
            justifyContent="flex-end"
            wrap="nowrap"
          >
            <Grid
              item
              style={{ marginRight: "auto" }} // FIXME:
            >
              <StyledIconButton aria-label="search" onClick={onSearch}>
                <Tooltip
                  title="Buscar" // FIXME: traduccion
                >
                  <SearchIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton aria-label="filters" onClick={onFilters}>
                <Tooltip
                  title="Filtros" // FIXME: traduccion
                >
                  <FilterListIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton aria-label="settings" onClick={onSettings}>
                <Tooltip
                  title="Configuración" // FIXME: traduccion
                >
                  <SettingsIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton aria-label="download" onClick={onDownload}>
                <Tooltip
                  title="Download" // FIXME: traduccion
                >
                  <GetAppIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton aria-label="expand" onClick={onExpand}>
                <Tooltip
                  title="Expandir" // FIXME: traduccion
                >
                  <AspectRatioIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton aria-label="columns" onClick={onColumns}>
                <Tooltip
                  title="Columnas" // FIXME: traduccion
                >
                  <ViewColumnIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>

            <Grid item>
              <StyledIconButton aria-label="create" onClick={onCreate}>
                <Tooltip
                  title="Crear" // FIXME: traduccion
                >
                  <AddIcon />
                </Tooltip>
              </StyledIconButton>
            </Grid>
          </Grid>
        </Box>
      </Wrapper>
    </>
  );
};

export default TableCollapse;

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.apps,
  borderRadius: theme.shape.wrapperBorderRadius,
  padding: theme.spacing(2),
}));

const StyledIconButton = styled((props) => (
  <IconButton size="small" {...props} />
))<IconButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));
