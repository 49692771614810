import { useMemo } from "react";
import { GetPurchaseOrdersItemsForCreateInvoiceQuery } from '../../../../__generated__/graphql/types';

export const usePurchaseOrderItems = (
  items: NonNullable<GetPurchaseOrdersItemsForCreateInvoiceQuery["purchaseOrderItems"]>,
) => {
  const mapPurchaseOrderItems = useMemo(() => {
    if(!items) return undefined 
    return new Map(
      items.map((item) => [item.purchaseOrderItem, item])
    );
  }, [items]);
  return { mapPurchaseOrderItems };
};
