import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateBusinessProfileMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";
import useAppMessages from 'hooks/useAppMessages/hook';

const CreatePage = () => {
  const navigate = useNavigate();

  const { appMessage } = useAppMessages("BUSINESS_PROFILES");
  const {
    mutateAsync: createBusinessProfile,
  } = useCreateBusinessProfileMutation();

  const onSave = async (form: FormFields) => {
    const businessProfile = mapFormToDto(form);
    await createBusinessProfile({ businessProfile });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title={appMessage["CREATE_BPROF"]}
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage={appMessage["ERROR_CREATE"]}
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
