import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useBusinessPartnersLabels = () => {

    const id = "BUSINESS_PARTNER_ID";
    const name = "BUSINESS_PARTNER_NAME";
    const lastName = "USER_LASTNAME";
    const taxName = "TAX_NAME";
    const identification = "IDENTIFICATION";
    const backend = "BACKEND";
    
    const dataElementsIds = [ id, name, lastName, taxName, identification, backend];
    
    type DetailsKeys = typeof dataElementsIds[number];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels : NonNullable<Record<ColumnsKeys, string>> = {
        id: dataElementsDescriptions === undefined ? id : (dataElementsDescriptions[id] ?? id),  
        name: dataElementsDescriptions === undefined ? name : (dataElementsDescriptions[name] ?? name),
        lastName: dataElementsDescriptions === undefined ? lastName : (dataElementsDescriptions[lastName] ?? lastName),
        taxName: dataElementsDescriptions === undefined ? taxName : (dataElementsDescriptions[taxName] ?? taxName),
        identification: dataElementsDescriptions === undefined ? identification : (dataElementsDescriptions[identification] ?? identification),
        backend: dataElementsDescriptions === undefined ? backend : (dataElementsDescriptions[backend] ?? backend),
    };

    const detailsLabels : NonNullable<Record<DetailsKeys, string>> = {
        id: dataElementsDescriptions === undefined ? id : (dataElementsDescriptions[id] ?? id),  
        name: dataElementsDescriptions === undefined ? name : (dataElementsDescriptions[name] ?? name),
        lastName: dataElementsDescriptions === undefined ? lastName : (dataElementsDescriptions[lastName] ?? lastName),
        taxName: dataElementsDescriptions === undefined ? taxName : (dataElementsDescriptions[taxName] ?? taxName),  
        identification: dataElementsDescriptions === undefined ? identification : (dataElementsDescriptions[identification] ?? identification),
        backend: dataElementsDescriptions === undefined ? backend : (dataElementsDescriptions[backend] ?? backend),
    };

    return { columnsLabels, detailsLabels }
}