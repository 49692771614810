import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DevTool } from "@hookform/devtools";
import { Box, Grid, GridProps } from "@mui/material";
import { Tile } from "components/navigation/Tile";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { isDefined } from "utils/isDefined";
import { useGetTilesForGroupFormQuery } from "__generated__/graphql/types";
import { AddDeleteTileWrapper } from "../TilesForm/template";
import { TilesOrderFormProps } from "./types";

const TilesOrderForm = ({
  form: { control, setValue, getValues, reset },
  readOnly,
  onEdit, // TODO:
}: TilesOrderFormProps) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const tilesIds = useWatch({ control, name: "tilesIds" });

  const { tiles } = useTiles(tilesIds);

  const onDelete = (tileId: string) => () => {
    // no funciona con setState
    reset({
      businessObjectId: getValues("businessObjectId"),
      tilesIds: getValues("tilesIds").filter((e) => e !== tileId),
    });
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over === null) return;
    if (active.id === over.id) return;

    const oldArray = getValues("tilesIds");

    const oldIndex = oldArray.indexOf(active.id);
    const newIndex = oldArray.indexOf(over.id);

    const newOrder = arrayMove(oldArray, oldIndex, newIndex);

    setValue("tilesIds", newOrder);
  };

  return (
    <>
      <DevTool placement="top-left" control={control} />
      {readOnly ?
        <Grid container spacing={2}>
          {tiles.map((e) => (
            <SortableGridItemView key={e.id} id={e.id}>
              <Tile
                shape={e.shape}
                title={e.translation.title}
                subtitle={e.translation.subtitle}
                indicator={""} // FIXME:
              />
            </SortableGridItemView>
          ))}
        </Grid>
        :
        <Box marginTop={2}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
          >
            <SortableContext items={tilesIds} strategy={rectSortingStrategy}>
              <Grid container spacing={2}>
                {tiles.map((e) => (
                  <SortableGridItem key={e.id} id={e.id}>
                    <AddDeleteTileWrapper type="DELETE" onDelete={onDelete(e.id)}>
                      <Tile
                        shape={e.shape}
                        title={e.translation.title}
                        subtitle={e.translation.subtitle}
                        indicator={""} // FIXME:
                      />
                    </AddDeleteTileWrapper>
                  </SortableGridItem>
                ))}
              </Grid>
            </SortableContext>
          </DndContext>
        </Box>
      }

    </>
  );
};

export default TilesOrderForm;

const useTiles = (tilesIds: string[]) => {
  const { language } = useSystemLanguage();

  const { data: { tiles } = {} } = useGetTilesForGroupFormQuery(
    {
      language,
      filters: { ids: tilesIds },
    },
    { keepPreviousData: true }
  );

  const tilesMap = useMemo(
    () => new Map(tiles?.map((tile) => [tile.id, tile]) ?? []),
    [tiles]
  );

  return {
    tiles: tilesIds.map((tileId) => tilesMap.get(tileId)).filter(isDefined),
  };
};

const SortableGridItemView = ({
  id,
  children,
  style,
  ...props
}: SortableTileProps) => {
  const sortable = useSortable({ id });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  // TODO: makeStyles
  const inlineStyle = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition: transition ?? undefined, // FIXME:
  };

  return (
    <Grid
      {...props}
      item
      ref={setNodeRef}
      style={inlineStyle}
      {...attributes}
      {...listeners}
    >
      {children}
    </Grid>
  );
};

type SortableTileProps = GridProps & { id: string };

const SortableGridItem = ({
  id,
  children,
  style,
  ...props
}: SortableTileProps) => {
  const sortable = useSortable({ id });

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  // TODO: makeStyles
  const inlineStyle = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition: transition ?? undefined, // FIXME:
    cursor: isDragging ? "grabbing" : "grab",
  };

  return (
    <Grid
      {...props}
      item
      ref={setNodeRef}
      style={inlineStyle}
      {...attributes}
      {...listeners}
    >
      {children}
    </Grid>
  );
};
