export const isValidKey = <T>(list: readonly T[]) => (
  value: unknown
): value is T => (list as unknown[]).includes(value);

export const isColumnVisible = <
  TColumn extends { isVisible: boolean },
  TVisibleColumn extends TColumn
>() => (value: TColumn): value is TVisibleColumn => value.isVisible;

export const isPairColumnVisible = <
  TColumn extends { isVisible: boolean },
  TVisibleColumn extends TColumn,
  TKey extends string
>(
  list: readonly TKey[]
) => (value: [string, TColumn]): value is [TKey, TVisibleColumn] =>
  isColumnVisible()(value[1]) && isValidKey(list)(value[0]);

export const isPairColumnHidden = <
  TColumn extends { isVisible: boolean },
  THiddenColumn extends TColumn,
  TKey extends string
>(
  list: readonly TKey[]
) => (value: [string, TColumn]): value is [TKey, THiddenColumn] =>
  isColumnVisible()(value[1]) === false && isValidKey(list)(value[0]);
