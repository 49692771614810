import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import FormNonTableBodyWrapper from "components/datadisplay/FormNonTableBodyWrapper/template";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ColorPicker } from "../PrimaryColorForm/ColorPicker";
import useAppMessages from 'hooks/useAppMessages/hook';

export type AppsColorFormFields = {
  appsColor: string;
  appsTitleColor: string;
};

export type AppsColorFormProps = {
  form: UseFormReturn<AppsColorFormFields>;
  readOnly?: boolean;
};

const AppsColorForm = ({ form, readOnly = false }: AppsColorFormProps) => {
  const { control } = form;
const { appMessage } = useAppMessages("THEMES");
  return (
    <FormNonTableBodyWrapper>
      <DevTool control={control} placement="top-left" />

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={3}>
          <ColorPicker
            label={appMessage["APPS"]} 
            disabled={readOnly}
            controllerProps={{
              name: "appsColor",
              control,
              rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },  ///FIXPRD QUITAR
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <ColorPicker
            label={appMessage["APPS_TITTLE"]} 
            disabled={readOnly}
            controllerProps={{
              name: "appsTitleColor",
              control,
              rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
            }}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default AppsColorForm;
