import { Box, Grid, Typography, styled } from "@mui/material";
import { DetailsValues } from "apps/PurchaseOrdersItemsPage/ViewPage/template";

export type DetialTitleProps = {
  subtitles: DetailsValues[];
};

const DetailTitle = ({
  subtitles
}: DetialTitleProps) => {

  return (
    <Box>
      <Grid container justifyContent="center" textAlign={"center"} marginTop={"30px"} marginBottom={"10px"}>
        <Grid>
            {subtitles.map((item, index) => {
              return <SubtitleText key={index}> { item.name+": " }
                <SubtitleDesc>
                  {item.value}
                </SubtitleDesc>
              </SubtitleText>
            })}
        </Grid>
      </Grid>
    </Box>
  );
};

const SubtitleText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  textAlign: "center",
  marginLeft: "20px",
  fontWeight: "bold",
  display: "inline",
  color: theme.palette.username
}));

const SubtitleDesc = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  textAlign: "center",
  display: "inline-block",
  color: theme.palette.username
}));

export default DetailTitle;