import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetTileForViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { TilesRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<FormFields>();

  const { tileId } = useRouteParams<TilesRoutes.VIEW>();

  const {
    data: { tile } = {},
    isFetchedAfterMount,
  } = useGetTileForViewPageQuery({
    id: tileId,
  });

  const onEdit = (activeStep: number) => {
    navigate(`../../${generatePath(TilesRoutes.EDIT, { tileId })}`, {
      state: { activeStep },
    });
  };

  useEffect(() => {
    if (tile == null) return;
    if (!isFetchedAfterMount) return;
    mapDtoToForm(tile).then((v) => setInitialValues(v));
  }, [tile, isFetchedAfterMount]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Mosaico" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValues} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
