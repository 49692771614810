import { GetAppViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetAppViewPageQuery["app"]>
): FormFields => {
  return {
    basicData: {
      name: dto.id,
      businessObjectId: dto.businessObject.dataElement.id,
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
    sections: {
      sections: dto.sections.map((e) => ({
        name: e.id,
        descriptions: e.translations.map((e) => ({
          language: e.language,
          title: e.title,
          subtitle: e.subtitle,
          step: e.step,
        })),
      })),
    },
    actions: {
      actions: dto.actions.map((e) => ({
        name: e.id,
        descriptions: e.translations.map((e) => ({
          language: e.language,
          text: e.description,
        })),
      })),
    },
    messages: {
      messages: dto.messages.map((e) => ({
        code: e.id,
        descriptions: e.translations.map((e) => ({
          language: e.language,
          text: e.description,
        })),
      })),
    },
  };
};
