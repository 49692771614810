import { DevTool } from "@hookform/devtools";
import React from "react";
import { ContactsDescriptionsForm } from "./ContactsDescriptionsForm";
import { ContactsNamesForm } from "./ContactsNamesForm";
import { ContactsFormProps } from "./types";

const ContactsForm = ({
  areDescriptionsVisible,
  form,
  externalIndex,
  readOnly,
  onEdit,
  onEnterDescriptions,
  onExitDescriptions,
}: ContactsFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <ContactsDescriptionsForm
          onFinish={onExitDescriptions}
          formMethods={form}
          externalIndex={externalIndex}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      ) : (
        <ContactsNamesForm
          form={form}
          onEnterDescriptions={onEnterDescriptions}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default ContactsForm;
