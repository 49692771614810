import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetBusinessGroup_ViewPageQuery,
  useUpdateBusinessGroupMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { BusinessGroupRoutesState, BusinessGroupRoutes } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

type Route = BusinessGroupRoutes.EDIT;
type LocationState = BusinessGroupRoutesState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { businessGroupId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const {
    data: { businessGroup } = {},
    isFetchedAfterMount,
  } = useGetBusinessGroup_ViewPageQuery({
    id: businessGroupId,
  });

  const initialValue =
    businessGroup != null && isFetchedAfterMount
      ? mapDtoToForm(businessGroup)
      : undefined;

  const { mutateAsync: replace } = useUpdateBusinessGroupMutation();

  const onSave = (form: FormFields) =>
    replace({
      id: businessGroupId,
      businessGroup: mapFormToDto(form),
    });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title="Editar grupo empresarial" // FIXME: traduccion
        toBack={TO_BACK_ROUTER}
      />

      <Form
        mode="edit"
        onSave={onSave}
        onCancel={onCancel}
        errorMessage="Error al editar el grupo empresarial. Vuelva a intentar." // FIXME: traduccion
        initialValue={initialValue}
        initialStep={activeStep}
      />
    </Layout>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
