import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export type TileShape = "SQUARE" | "RECTANGULAR" | "CIRCULAR";

export type TileProps = {
  title: string;
  subtitle: string;
  indicator?: string;
  shape: TileShape;
  imageUrl?: string;
};

const Tile = ({ title, subtitle, indicator, shape, imageUrl }: TileProps) => {
  const { height, width, borderRadius } = mapShapeToProps(shape);

  return (
    <StyledCard height={height} width={width} borderRadius={borderRadius}>
      <StyledCardHeader title={title} />
      <StyledCardContent>
        <Typography variant="body2" style={{ textAlign: "center" }}>
          {subtitle}
        </Typography>
        <Grid
          container
          justifyContent="space-around"
          alignItems="flex-end"
          style={{ marginTop: 10 }}
        >
          {imageUrl && (
            <Grid item>
              <img height={50} width={50} src={imageUrl} alt="img" />
            </Grid>
          )}

          {indicator !== undefined && (
            <Grid item>
              <Typography variant="h4">{indicator}</Typography>
            </Grid>
          )}
        </Grid>
      </StyledCardContent>
    </StyledCard>
  );
};

export default Tile;

const mapShapeToProps = (shape: TileShape) => {
  switch (shape) {
    case "RECTANGULAR":
      return { height: 150, width: 250 };
    case "SQUARE":
      return { height: 150, width: 150 };
    case "CIRCULAR":
      return { height: 150, width: 150, borderRadius: "50%" };
  }
};

const StyledCard = styled(Card)<{
  height: number;
  width: number;
  borderRadius?: string;
}>(({ height, width, borderRadius, theme }) => ({
  height,
  width,
  background: theme.palette.background.apps,
  borderRadius: borderRadius ?? theme.shape.wrapperBorderRadius,
  display: "flex",
  flexDirection: "column",
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  height: 50,
  overflow: "hidden",
  borderBottom: "2px solid",
  paddingBottom: "0",
  "& .MuiTypography-root": {
    color: theme.palette.primary.dark,
    textAlign: "center",
    fontSize: 20,
  },
}));

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: 1,
  textAlign: "right",
  padding: "0 10px",
  "& a:link": {
    textDecoration: "none",
  },
  "& a:active": {
    color: "black",
  },
  "& a:visited": {
    color: "black",
  },
});
