import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { isRepeatedValue } from "utils/isRepeatedValue";
import {
  GetBusinessPartnerTypesForBusinessPartnersFormQuery,
  useGetBusinessPartnerTypesForBusinessPartnersFormQuery
} from "__generated__/graphql/types";
import { RowProps } from "./types";

const Row = ({ index, remove, readOnly, form }: RowProps) => {
  const { control, trigger, getValues, watch } = form;

  const { language } = useSystemLanguage();

  const filters = {};

  const onRemoveRow = () => {
    remove();
    trigger();
  };
  const { dataElementsDescriptions } = useDataElementDescription([
    "BUSINESS_PARTNER_TYPE",
    "DESCRIPTION",
  ]);
  const btype = watch(`types.${index}.businessType` as "types.0.businessType");
  const {
    data: { businessPartnerTypes } = {},
  } = useGetBusinessPartnerTypesForBusinessPartnersFormQuery({
    language,
    filters,
  });
  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");
  const {appMessage:appMsg} = useAppMessages("MESSAGES");

  if (appMsg == null) return <BeatLoadingPage/>;
  if (businessPartnerTypes == null) return <BeatLoadingPage/>;
  if (dataElementsDescriptions === undefined) return <BeatLoadingPage/>;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <MatchcodeInputController
          label={dataElementsDescriptions["BUSINESS_PARTNER_TYPE"]}
          controllerProps={{
            name: `types.${index}.businessType` as "types.0.businessType",
            control,
            rules: {
              required: appMessage["SELECT_BP_TYPE"] ?? "SELECT_BP_TYPE",
              validate: (back) => {
                if (readOnly) return true;
                const id = getValues("types");
                if (
                  isRepeatedValue(
                    back.toUpperCase(),
                    id.map(({ businessType }) => businessType.toUpperCase())
                  )
                )
                  return appMessage["BPTYPE_ALREADY_SELECTED"] ?? "The business partner type has already been selected" ;///FIXPRD QUITAR
                return true;
              },
            }, 
          }}
          readOnly={readOnly}
          column={[
            {
              key: "name",
              text: dataElementsDescriptions["BUSINESS_PARTNER_TYPE"],
            },
            {
              key: "description",
              text: dataElementsDescriptions["DESCRIPTION"],
            },
          ]}
          displayColumn="name"
          options={businessPartnerTypes.map(mapDataElementToOption)}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <TextFieldController
          inputProps={{ disabled: true }}
          label={dataElementsDescriptions["USER_LASTNAME"]}
          InputProps={{
            value: businessPartnerTypes.find((e) => e.id === btype)?.translation
              .description,
          }}
          controllerProps={{
            name: `types.${index}._tdescription` as "types.0._tdescription",
            control,
          }}
        />
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip
            title={appMsg["DELETE_ROW"]} 
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});

const mapDataElementToOption = (
  dto: NonNullable<
    GetBusinessPartnerTypesForBusinessPartnersFormQuery["businessPartnerTypes"]
  >[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});
