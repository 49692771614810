import { MigrationPackageInput } from '__generated__/graphql/types';
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): MigrationPackageInput => {
  return {
    description: form.description.text,
    data: form.businessObjects.businessObjects.map((bo) => ({
      businessObjectId: bo.id,
      ids: Object.entries(bo.idsBusinessObject)
            .filter(([, { isAllowed }]) => isAllowed)
            .reduce((accActionsIds, [actionId]) => [...accActionsIds, actionId], [] as string[])
    }))
  };
};