export enum ReportsRoutes {
  VIEW_REPORTS = "/",
  THEMES = "themes",
  DATA_ELEMENTS = "data-elements",
  TABLES = "tables",
  BUSINESS_OBJECTS = "business-objects",
  APPS = "apps",
  TILES = "tiles",
  KPI = "kpis",
  GROUPS = "groups",
  BUSINESS_PROFILES = "business-profiles",
  USERS = "users",
  LANGUAGES = "languages",
  MESSAGES = "messages",
  CURRENCY = "currencies",
  COUNTRY = "countries",
  CHART_OF_ACCOUNT = "chart-of-accounts",
  BUSINESS_GROUP = "business-groups",
  ACCOUNTING_ENTITY = "accounting-entities",
  COMPANY = "companies",
  PURCHASE_ENTITY = "purchase-entities",
  COMMERCIAL_ENTITY = "commercial-entities",
  LOCATION = "locations",
  LOCATION_TYPE = "location-types",
  BUSINESS_PARTNER_TYPE = "business-partner-types",
  COUNTRY_IDS_TYPE = "country-ids-types",
  BACKEND = "backends",
  MIGRATIONS_PACKAGES = "migrations-packages",
  IMPORT_MIGRATIONS_PACKAGES = "import-migrations-packages",
  EMPLOYEE = "employees",
  POSITIONS = "positions",
  BUSINESS_PARTNER = "business-partners",
  REVENUE_COLLECTORS = "revenue-collectors",
  COST_COLLECTORS = "cost-collectors",
  BUSINESS_UNITS = "business-units",
  PURCHASE_ORDERS = "purchase-orders",
  PURCHASE_ORDERS_DIRECT = "direct/purchase-orders",
  PURCHASE_ORDERS_ITEMS_DIRECT = "direct/purchase-order-items",
  PURCHASE_ORDERS_ITEMS_OVERVIEW = "overview/purchase-order-items",
  INVOICES_OVERVIEW = "overview/purchase-orders/items/invoices",
  INVOICES_VENDOR_NUMBER_DIRECT = "direct/invoice-vendor-number",
  INVOICES_FREIGHT_DIRECT = "direct/invoice-freight",
  INVOICES_FREIGHT = "invoices-freight",
  TUTORIALS = "tutorials",
}
