import React from "react";
import { CreateThemePage } from "./CreateThemePage";
import { EditThemePage } from "./EditThemePage";
import { ThemeRoutes } from "./routes";
import { ViewAllThemesPage } from "./ViewAllThemesPage";
import { Route, Routes } from "react-router-dom";
import { ViewThemePage } from "./ViewThemePage";

const ThemePage = () => {
  return (
    <Routes>
      <Route path={ThemeRoutes.VIEW_ALL} element={<ViewAllThemesPage />} />

      <Route path={ThemeRoutes.VIEW} element={<ViewThemePage />} />

      <Route path={ThemeRoutes.EDIT} element={<EditThemePage />} />

      <Route path={ThemeRoutes.CREATE} element={<CreateThemePage />} />
    </Routes>
  );
};

export default ThemePage;
