import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Home } from "assets/img/svg/menu_1.svg";
import { ReactComponent as Out } from "assets/img/svg/menu_9.svg";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLogMeOut } from "hooks/useLogMeOut";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { AvatarWithLabel } from "./AvatarWithLabel";
import { NavbarButton } from "./NavbarButton";
import useAppMessages from 'hooks/useAppMessages/hook';
import { clearMyInformation, getMyInformation } from '../../providers/ReduxMyProvider/reducer';

const buttons = [
  { Icon: Home, path: "/reports/", exact: false },
];

const NavbarSide = () => {

  const [open, setOpen] = React.useState(false);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const { me } = useAppSelector(getMyInformation);
  const { mutateAsync: logout } = useLogMeOut();
  const { appMessage } = useAppMessages("MAIN");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AvatarWithLabel
        iconSrc={me?.image.url ?? ""} // FIXME:
        userName={me?.firstName ?? ""} // FIXME:
      />

      <Wrapper id="main-menu">
        {buttons.map(({ Icon, path, exact }, index) => (
          <NavbarButton
            key={index} // FIXME:
            Icon={Icon}
            path={path}
            isLast={index < buttons.length - 1}
            exact={exact}
            onClick={() => navigate(path)}
          />
        ))}

        <NavbarButton
          Icon={Out}
          path={"out"}
          isLast={true}
          exact={false}
          onClick={() => handleClickOpen()}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogTitle>
            {appMessage["CLOSE_CMF"]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {appMessage["CLOSE_QST"]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{appMessage["CANCEL"]}</Button>
            <Button onClick={() => logout()
                .then(() => {
                  localStorage.clear();
                  dispatch(clearMyInformation());
                })
              } 
              autoFocus 
              color={"primary"} 
              variant="contained"
            >
              {appMessage["CLOSE"]}
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </>
  );
};

export default NavbarSide;

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection:"column",
  justifyContent: "center",
  height: "auto",
  width: "60px",
  margin: "0 auto",
  paddingTop: "20px",
  "&::before, &&::after": {
    content: "''",
    height: "50px",
    display: "block",
    backgroundColor: theme.palette.primary.main,
  },
  "&::before": {
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
  },
  "&::after": {
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
  },
}));
