import { DevTool } from "@hookform/devtools";
import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { InvoicesRoutesState } from "apps/InvoicesPage/routes";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useFieldArray } from "react-hook-form";
import { useLocationState } from "utils/typedRoutesUtils";
import { useGetPurchaseOrderExtraChargesQuery, useGetPurchaseOrdersItemsForCreateInvoiceQuery } from '../../../../../__generated__/graphql/types';
import { usePurchaseOrderItems } from "../hooks";
import { Header } from "./Header";
import { Row } from "./Row";
import {
  PositionsColumn,
  PositionsColumnsFormFields,
  PositionsColumnsTableFormProps
} from "./types";

export const emptyPositionsRow: PositionsColumn = {
  id: "",
  backend: "",
  purchaseOrderItemId: "",
  taxesAmount: 0,
  amountToBill: 0,
  itemText: "",
  productId: "",
  quantity: 0,
  unit: "",
  netUnitPrice: 0,
  netTotalPrice: 0,
  extraChargesAmount: 0
};

const PositionsColumnsForm = ({
  form,
  readOnly,
  onEdit,
}: PositionsColumnsTableFormProps) => {

  const { control } = form;
  const state = useLocationState<InvoicesRoutesState>();

  // FIXME: Agregar validacion si el state es null
  const purchaseOrderId = state!.purchaseOrderId;

  const {
    data: { purchaseOrderItems } = {},
  } = useGetPurchaseOrdersItemsForCreateInvoiceQuery( { purchaseOrder:purchaseOrderId } );
  const { mapPurchaseOrderItems } = usePurchaseOrderItems( purchaseOrderItems! );

  const {
    data: { purchaseOrder } = {},
  } = useGetPurchaseOrderExtraChargesQuery( { purchaseOrder:purchaseOrderId } );

  const {
    fields,
    append,
    remove,
  } = useFieldArray<PositionsColumnsFormFields>({
    control,
    name: "positions"
  });

  const onAppendRow = () => {
    append(emptyPositionsRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  if (purchaseOrderItems === undefined) return <BeatLoadingPage />;

  return (
    <>
      <DevTool placement="top-left" control={control} />

      {/* Instructor */}
      <MenuInstructions tutorialsIds={
        readOnly 
          ? ["INVOICE_FORM_VIEW_ITEMS"]
          : ["INVOICES_FORM_PO_ITEMS"]
      }/>

      <StyledTableContainer id="table-form">
        <StyledTable aria-label="simple table" size="small">
          <Header 
            appendRow={onAppendRow} 
            onEdit={onEdit} 
            readOnly={readOnly}
            showColumns={purchaseOrder?.extraCharges ?? false } //FIXME: No regresar null de backend
          />

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                key={item.id}
                index={index}
                remove={onRemove(index)}
                item={item}
                readOnly={readOnly!}
                positionsByIdMap={mapPurchaseOrderItems}
                form={form}
                showColumns={purchaseOrder?.extraCharges ?? false} //FIXME: No regresar null de backend
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </>
  );
};

export default PositionsColumnsForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});

const StyledTableContainer = styled(TableContainer)({
  overflowX: "auto !important" as any, //FIXME:
});
