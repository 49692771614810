import {
  IconButton,
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  detectFilterAndParameterType,
  mapFilterToInputPlaceholder,
} from "hooks/useRangeParameterFilter";
import React, { useMemo } from "react";
import { Filters } from "utils/FilterTypes";

type ParameterRangeTextFieldProps<T> = {
  label: string;
  value?: Filters<T>;
  isDeleteButtonVisible: boolean;
  transform: (value: unknown) => string;
  onDeleteValue: () => void;
  onOpenDialog: () => void;
};

const ParameterRangeTextField = <T extends unknown>({
  label,
  value,
  isDeleteButtonVisible,
  transform,
  onDeleteValue,
  onOpenDialog,
}: ParameterRangeTextFieldProps<T>) => {
  const text = useMemo(() => {
    if (value === undefined) return;

    const type = detectFilterAndParameterType(value);

    if (type === undefined) return;

    const { filterType, parameterType } = type;

    return mapFilterToInputPlaceholder({
      form: value,
      filterType,
      parameterType,
      transform,
    });
  }, [value, transform]);

  return (
    <StyledTextField
      label={label}
      variant="outlined"
      color="primary"
      size="small"
      margin="none"
      value={text ?? ""}
      fullWidth
      InputLabelProps={{ shrink: text !== undefined }}
      InputProps={{
        startAdornment: isDeleteButtonVisible && (
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onDeleteValue}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onOpenDialog}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ParameterRangeTextField;

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& fieldset": {
    borderColor: theme.palette.primary.dark,
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-root.Mui-focused fieldset": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
    borderColor: theme.palette.primary.main,
  },
}));
