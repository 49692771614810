import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { Pagination } from "utils/Pagination";
import { push } from "utils/push";

export const FILTERS_KEYS = ["id", "name"] as const;
export type FiltersKeys = typeof FILTERS_KEYS[number];

export type Filters = Partial<{
  id: string;
  name: string;
}>;

type FiltersState = {
  filtersVisibility: Record<FiltersKeys, boolean>;
  filters: Filters;
  pagination: Pagination;
};

const initialState: FiltersState = {
  filtersVisibility: FILTERS_KEYS.reduce(
    (prev, curr) => push(prev, curr, false),
    {} as Record<FiltersKeys, boolean>
  ),
  filters: {},
  pagination: { page: 0, pageSize: 5 },
};

const filtersAndPaginationSlice = createSlice({
  name: "tutorialsFiltersAndPaginationSlice",
  initialState,
  reducers: {
    setFilterVisibility: (
      state: any,
      action: PayloadAction<{
        key: FiltersKeys;
        isVisible: boolean;
      }>
    ) => {
      const { key, isVisible } = action.payload;
      state.filtersVisibility[key] = isVisible;
      state.filters[key] = undefined;
    },
    setFilters: (state: any, action: PayloadAction<Filters>) => {
      state.pagination = initialState.pagination;
      state.filters = action.payload;
    },
    prevPage: (state: any) => {
      state.pagination.page--;
    },
    nextPage: (state: any) => {
      state.pagination.page++;
    },
    resetPage: (state: any) => {
      state.pagination.page = 0;
    },
  },
});

export const {
  setFilters,
  setFilterVisibility,
  resetPage,
  prevPage,
  nextPage,
} = filtersAndPaginationSlice.actions;

export const selectIsFilterVisible = (state: RootState) =>
  state.tutorials.filtersAndPagination.filtersVisibility;

export const selectFilters = (state: RootState) =>
  state.tutorials.filtersAndPagination.filters;

export const selectVisibleFiltersKeys = (state: RootState) =>
  FILTERS_KEYS.filter(
    (key) => state.tutorials.filtersAndPagination.filtersVisibility[key]
  );

export const selectHiddenFiltersKeys = (state: RootState) =>
  FILTERS_KEYS.filter(
    (key) => !state.tutorials.filtersAndPagination.filtersVisibility[key]
  );

export const selectPagination = (state: RootState) =>
  state.tutorials.filtersAndPagination.pagination;

export default filtersAndPaginationSlice.reducer;
