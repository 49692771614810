export function getRowCoordinates(id) {
    let element = window.document.getElementById(id);
    const top = element.getBoundingClientRect().top 
        + window.document.getElementById('generic-table').getBoundingClientRect().top + 100;
    const left = element.getBoundingClientRect().left
        + window.document.getElementById('generic-table').getBoundingClientRect().left;
    return { top, left }
}

export function rowChecked(id) {
    let element = window.document.getElementById(id);
    if(!element.checked) { element.click(); }
}