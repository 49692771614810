import { GetBusinessObjectForViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetBusinessObjectForViewPageQuery["businessObject"]>
): FormFields => {
  return {
    basicData: { dataElementId: dto.dataElement.id },
    tables: {
      tables: dto.tables.map((e) => ({
        tableId: e.table.id,
        isHeader: e.isHeaderTable,
      })),
    },
  };
};
