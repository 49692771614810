import { TableCellProps } from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  TableColumnType,
  TableRowType,
} from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppActions } from "hooks/useAppActions";
import { useSnackbar } from "hooks/useSnackbar";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { push } from "utils/push";
import { generatePath } from "utils/typedRoutesUtils";
import {
  GetTilesForTablePageQuery,
  useDeleteTileMutation,
  useGetTilesForTablePageQuery,
} from "__generated__/graphql/types";
import { TilesRoutes } from "../../../routes";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked,
} from "../../reducers/checkedRowsSlice";
import {
  filtersLabels,
  nextPage,
  prevPage,
  resetPage,
  selectPagination,
} from "../../reducers/filtersAndPaginationSlice";
import {
  ColumnsKeys,
  COLUMNS_KEYS,
  selectVisibleColumnsKeysInOrder,
} from "../../reducers/visibleColumnsSlice";

export const useTable = () => {
  const pagination = useAppSelector(selectPagination);

  const errorSnackbar = useSnackbar();

  const { language } = useSystemLanguage();

  const { actions } = useAppActions("TILES");

  const {
    data: { tiles, tilesCount: total } = {},
    refetch: findApps,
  } = useGetTilesForTablePageQuery({
    language,
    pagination,
    // TODO: filters
  });

  const { mutateAsync: deleteTile } = useDeleteTileMutation();

  const dispatch = useAppDispatch();

  const orderedColumnsKeys = useAppSelector(selectVisibleColumnsKeysInOrder);

  const areAllDataElementsRowsChecked = useAppSelector(selectAreAllRowsChecked);

  const isDataElementsRowChecked = useAppSelector(selectIsRowChecked);

  const navigate = useNavigate();

  const columns: TableColumnType<ColumnsKeys>[] = useMemo(
    () => orderedColumnsKeys.map((colKey) => header[colKey]),
    [orderedColumnsKeys]
  );

  const rows = useMemo(() => tiles?.map(mapRowDataToRowCells), [tiles]);

  const menuItems = useMemo(
    () => [
      {
        key: "display",
        Icon: SearchIcon,
        text: actions["READ"],
        onClick: (id: string) => {
          navigate(generatePath(TilesRoutes.VIEW, { tileId: id }));
        },
      },
      {
        key: "edit",
        Icon: EditIcon,
        text: actions["UPDATE"],
        onClick: (id: string) => {
          navigate(generatePath(TilesRoutes.EDIT, { tileId: id }));
        },
      },
      {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: actions["DELETE"],
        onClick: (id: string) =>
          deleteTile({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open()),
      },
    ],
    [actions, deleteTile, dispatch, errorSnackbar, findApps, navigate]
  );

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPage());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  return {
    rows,
    total,
    errorSnackbar,
    columns,
    menuItems,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    pagination,
  };
};

const mapRowDataToRowCells = (
  dto: NonNullable<GetTilesForTablePageQuery["tiles"]>[number]
): TableRowType<ColumnsKeys> => {
  return {
    rowKey: dto.id,
    app: dto.app.id,
    tileTitle: dto.translation.title,
    tileSubtitle: dto.translation.subtitle,
  };
};

const columnAlign: Partial<Record<ColumnsKeys, TableCellProps["align"]>> = {};

const header = COLUMNS_KEYS.reduce((acc, curr) => {
  return push(acc, curr, {
    key: curr,
    content: filtersLabels[curr].toUpperCase(),
    align: columnAlign[curr],
  });
}, {} as Record<ColumnsKeys, TableColumnType<ColumnsKeys>>);
