import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Edit as EditCircleIcon,
} from "@mui/icons-material";
import React from "react";
import { useDataElementDescription } from "hooks/useDataElementDescription";

type HeaderProps = {
  appendRow: () => void;
  onEdit?: () => void;
  readOnly?: boolean;
};

const Header = ({ appendRow, readOnly, onEdit }: HeaderProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([]);

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["TABLE_COLUMN_NAME"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["TABLE_COLUMN_IS_PRIMARY_KEY"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["DATA_ELEMENT"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["DESCRIPTION"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["DATA_ELEMENT_DATA_TYPE"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["DATA_ELEMENT_IS_LANGUAGE_DEPENDENT"]}
        </StyledHeadTableCell>

        <TableCell align="right">
          {readOnly ? (
            <Tooltip
              title="Editar" // FIXME: traduccion
            >
              <IconButton
                aria-label="edit"
                size="small"
                onClick={() => onEdit?.()}
              >
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Agregar fila" // FIXME: traduccion
            >
              <IconButton aria-label="add" size="small" onClick={appendRow}>
                <StyledAddCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
