import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { TextFieldController } from "components/inputs/TextFieldController";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { FormMode } from "types/Form";
import {
  GetCompanies_ForRevenueCollectorsPageQuery,
  useGetCompanies_ForRevenueCollectorsPageQuery,
} from "__generated__/graphql/types";

export type BasicDataFormFields = {
  name: string;
  companyId: string;
  companyName: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control, watch } = form;

  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "REVENUE_COLLECTOR",
    "COMPANY",
    "DESCRIPTION",
  ]);

  const {
    data: { companies } = {},
  } = useGetCompanies_ForRevenueCollectorsPageQuery({});

  if (dataElementsDescriptions === undefined) return <BeatLoadingPage />; // TODO: loading spinner
  if (companies == null) return <BeatLoadingPage />;

  const selectedCompanyId = watch("companyId");
  const selectedCompany = companies.find(
    (company) => company.id === selectedCompanyId
  );

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        {isIdReadOnly && (
          <Grid item xs={3}>
            <KeyInputWrapper>
              <TextFieldController
                inputProps={{ disabled: isIdReadOnly }}
                label={dataElementsDescriptions["REVENUE_COLLECTOR"]}
                controllerProps={{
                  name: "name",
                  control,
                  rules: {
                    required: "Ingrese el identificador.",
                  },
                }}
              />
            </KeyInputWrapper>
          </Grid>
        )}
        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["COMPANY"]}
              controllerProps={{
                name: "companyId",
                control,
                rules: { required: "Selecciona la compañia." }, // FIXME: traduccion
              }}
              readOnly={disabled}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["COMPANY"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={companies.map(mapDataElementToOption)}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{
                disabled: true,
                value: selectedCompany?.name ?? "",
              }}
              label={dataElementsDescriptions["DESCRIPTION"]}
              controllerProps={{
                name: "companyName",
                control,
              }}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const mapDataElementToOption = (
  dto: NonNullable<
    GetCompanies_ForRevenueCollectorsPageQuery["companies"]
  >[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.name,
});
