import { DevTool } from "@hookform/devtools";
import { Box, Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { FilePicker } from "components/inputs/FilePicker";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { fileToBase64 } from "utils/fileToBase64";
import { InvoiceFileFormProps } from "./types";

const InvoiceFileForm = ({ 
  form, 
  readOnly = false, 
  onEdit 
}: InvoiceFileFormProps) => {

  const { control } = form.file;
  const [invoiceBase64, setInvoiceBase64] = useState<string>();
  const { appMessage } = useAppMessages("INVOICES");

  const invoice = useWatch({
    control: form.file.control,
    name: "file",
  });

  const { dataElementsDescriptions } = useDataElementDescription(["PDF_FILE"]);

  useEffect(() => {
    if (!(invoice instanceof File)) return;
    fileToBase64(invoice).then((e) => setInvoiceBase64(e as string));
  }, [invoice]);

  if (dataElementsDescriptions === undefined) return <BeatLoadingPage />;

  return (
    <>
      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>

        <DevTool placement="top-left" control={control} />

         {/* Instructor */}
        <MenuInstructions tutorialsIds={["INVOICES_FORM_UPLOAD_INVOICES"]} />

        <Grid container wrap="nowrap" justifyContent="center">
          <Grid item>
            <FilePicker
              tooltipTitle={appMessage["UPLOAD_INVOICE"]}
              label={dataElementsDescriptions["PDF_FILE"]}
              controllerProps={{
                name: "file",
                control,
                rules: { required: appMessage["UPLOAD_INVOICE"] },
              }}
              readOnly={readOnly}
              documentType="pdf"
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>

      {invoiceBase64 && (
        <Box padding={2} marginTop={2}>
          <Grid
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
          </Grid>
        </Box>
      )}
    </>
  );
};

export default InvoiceFileForm;
