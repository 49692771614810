import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import BeatLoadingPage from '../../../../../components/layout/BeatLoadingPage/template';
import { TutorialExistsDocument, TutorialExistsQuery, TutorialExistsQueryVariables } from '../../../../../__generated__/graphql/types';
import { BasicDataFormProps } from "./types";

const BasicDataForm = ({
  form: { control },
  mode,
  onEdit,
}: BasicDataFormProps) => {

  const { dataElementsDescriptions } = useDataElementDescription([
    "TUTORIALS",
  ]);
  
  const isIdReadOnly = mode !== "create";
  const readOnly = mode === "view";
  const { appMessage: tutorialsMessage } = useAppMessages("TUTORIALS");
  const { appMessage } = useAppMessages("MESSAGES");
  
  if (dataElementsDescriptions == null) return <BeatLoadingPage/>;
  
  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <KeyInputWrapper>
              <TextFieldController
                label={dataElementsDescriptions["TUTORIALS"]}
                controllerProps={{
                  name: "tutorialId",
                  control,
                  rules: {
                    validate: async (value) => {
                      if (isIdReadOnly) return true;
                      const { tutorialExists } = await tutorialExistsFn({
                        tutorialId: value,
                      });
                      return tutorialExists ? tutorialsMessage["ID_REPEATED"] : true;
                    },
                    required: appMessage["FIELD_REQUIRED"] ?? "Field required",
                  },
                }}
                inputProps={{ disabled: isIdReadOnly }}
              />
            </KeyInputWrapper>
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BasicDataForm;

const tutorialExistsFn = pDebounce(
  (args: TutorialExistsQueryVariables) =>
    fetcher<TutorialExistsQuery, TutorialExistsQueryVariables>(
      TutorialExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
