import { combineReducers } from "@reduxjs/toolkit";
import checkedRowsSlice from "./ViewAllPage/reducers/checkedRowsSlice";
import filtersAndPaginationSlice from "./ViewAllPage/reducers/filtersAndPaginationSlice";
import visibleColumnsSlice from "./ViewAllPage/reducers/visibleColumnsSlice";

const tutorialsReducer = combineReducers({
  columns: visibleColumnsSlice,
  filtersAndPagination: filtersAndPaginationSlice,
  rowsChecked: checkedRowsSlice
});

export default tutorialsReducer;
