import { useMemo } from "react";
import { AppsDtoForCreateTutorial } from "./AppsForm/types";

export const useApps = (
  apps: AppsDtoForCreateTutorial,
) => {
  const mapApps = useMemo(() => {
    if(!apps) return undefined 
    return new Map(
      apps.map((e) => [e.id, e])
    );
  }, [apps]);
  return { mapApps };
};
