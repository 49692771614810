import {
  ArrowDownward as ArrowDownwardIcon,
  Clear as ClearIcon
} from "@mui/icons-material";
import {
  IconButton,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { CheckboxController } from "components/inputs/CheckboxController";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import { TextFieldController } from "components/inputs/TextFieldController";
import { useAppMessages } from "hooks/useAppMessages";
import { useTranslation } from "hooks/useTranslation";
import { Controller } from "react-hook-form";
import {
  containsDefaultLanguage,
  containsTranslations,
  containsUniqueLanguages,
  translationsFieldsAreNotEmpty
} from "utils/translations";
import { RowProps } from "./types";

export const STYLES_TYPES = [
  'TOP',
  'BOTTOM',
  'LEFT',
  'RIGHT',
  'CENTER'
] as const;

const Row = ({
  index,
  remove,
  item,
  readOnly,
  onEnterDescriptions,
  form,
}: RowProps) => {

  const { control, trigger, watch } = form;

  const descriptions = watch(
    `instructions.${index}.descriptions` as "instructions.0.descriptions"
  );

  const description = useTranslation(descriptions);
  const { appMessage } = useAppMessages("MESSAGES");

  const onRemoveRow = () => {
    remove();
    trigger();
  };

  return (
    <TableRow>
      {/* TODO: Únicamente aceptar numeros */}
      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `instructions.${index}.stepNumber` as "instructions.0.stepNumber",
            control,
            rules: { required: appMessage["FIELD_REQUIRED"] ?? "Field required" },
            defaultValue: item.stepNumber
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `instructions.${index}.stepName` as "instructions.0.stepName",
            control,
            rules: { required: appMessage["FIELD_REQUIRED"] ?? "Field required" },
            defaultValue: item.stepName,
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `instructions.${index}.target` as "instructions.0.target",
            control,
            rules: { required: appMessage["FIELD_REQUIRED"] ?? "Field required" },
            defaultValue: item.target,
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <TextFieldController
          inputProps={{
            readOnly,
          }}
          label={""} // FIXME:
          select
          controllerProps={{
            name: `instructions.${index}.style` as "instructions.0.style",
            control,
            rules: { required: appMessage["FIELD_REQUIRED"] ?? "Field required" },
            defaultValue: item.style,
          }}
        >
          {STYLES_TYPES.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextFieldController>
      </TableCell>

      <TableCell component="th" scope="row" align="center">
      <CheckboxController
          controllerProps={{
            name: `instructions.${index}.enabledClick` as "instructions.0.enabledClick",
            control,
            defaultValue: item.enabledClick,
          }}
          readOnly={readOnly}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`instructions.${index}._virtualField` as "instructions.0._virtualField"}
          rules={{
            validate: () => {

              const translations = form.getValues(
                `instructions.${index}.descriptions` as "instructions.0.descriptions"
              );

              if (!containsTranslations(translations))
                return appMessage["FIELD_REQUIRED"] ?? "Field required";

              if (!containsDefaultLanguage(translations))
                return appMessage["EN_LANGUAGE"] ?? "Must include default language (EN)";

              if (!containsUniqueLanguages(translations))
                return appMessage["DATA_REPEATED"] ?? "Data repeated";

              if (
                !translationsFieldsAreNotEmpty(translations, [
                  "description",
                  "language",
                ])
              ) {
                return appMessage["FIELDS_INCOMPLETE"] ?? "Fields incomplete";
              }

              return true;
            },
          }}
          render={({ fieldState: { invalid, error } }) => (
            <FormTableTextField
              value={description?.description}
              helperText={error?.message}
              error={invalid}
              inputProps={{ disabled: readOnly }}
            />
          )}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip
          title={appMessage["ADD_ROW"] ?? "Add row"}
        >
          <StyledIconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={() => onEnterDescriptions(index)}
          >
            <ArrowDownwardIcon />
          </StyledIconButton>
        </Tooltip>

        {!readOnly && (
          <Tooltip
            title={appMessage["DELETE_ROW"] ?? "Delete row"}
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});

// FIXME: commons
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
