export enum BusinessObjectsRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":businessObjectId/view",
  EDIT = ":businessObjectId/edit",
}

export type BusinessObjectsRoutesState = {
  [BusinessObjectsRoutes.EDIT]: { activeStep?: number };
};
