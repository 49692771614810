import React from "react";
import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { EditPage } from "./EditPage";
import { TilesRoutes } from "./routes";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";

const TilesPage = () => {
  return (
    <Routes>
      <Route path={TilesRoutes.VIEW_ALL} element={<ViewAllPage />} />

      <Route path={TilesRoutes.VIEW} element={<ViewPage />} />

      <Route path={TilesRoutes.CREATE} element={<CreatePage />} />

      <Route path={TilesRoutes.EDIT} element={<EditPage />} />
    </Routes>
  );
};

export default TilesPage;
