import { Box } from "@mui/material";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { Collapse } from "./components/Collapse";
import { Table } from "./components/Table";
import { useViewAllPage } from "./useViewAllPage";

const ViewAllPage = () => {

  const {
    errorSnackbar,
    appTutorials,
    menuItems,
    rows,
    total,
    pagination,
    sheet
  } = useViewAllPage();

  // TODO: Convertir tutorials a objeto de excel

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      {/* App */}
      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appTutorials["TUTORIALS_TITLE"]}
          toBack="../"
        />

        <Box id="collapse-box" marginY={2}>
          <Collapse 
            menuItems={menuItems}
            isCreatedVisible={true}
            sheet={sheet}
          />
        </Box>

        <Table 
          menuItems={menuItems}
          rows={rows}
          total={total}
          pagination={pagination}
        />
      </Box>
    </Layout>
  );
};
  
export default ViewAllPage;