import { FilterComponentProps } from "components/filters/types";
import React from "react";
import { FieldPath, FieldValues, useController } from "react-hook-form";
import { NumberFilterInput } from "./NumberFilterInput";

export const NumberFilter = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  controllerProps,
}: FilterComponentProps<TFieldValues, TName>) => {
  const {
    field: { value, onChange },
  } = useController(controllerProps);

  return (
    <NumberFilterInput
      label={label}
      // @ts-ignore FIXME:
      value={value}
      onChange={onChange}
    />
  );
};

export default NumberFilter;
