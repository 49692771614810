import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconTab from './IconTab/template';

export type TabsType = {
  icon: string;
  label: string;
  to: string;
};

type TabsBarProps = {
  tabs: TabsType[];
  value: string;
  onChange: (value: string) => void;
};

const TabsBar = ({ 
  tabs, 
  value, 
  onChange 
}: TabsBarProps) => {

  return (
    <StyledTabs
      id="tabs-apps"
      variant="scrollable"
      scrollButtons
      value={value}
      onChange={(_, value) => onChange(value)}
      TabIndicatorProps={{
        style: {
          visibility: "hidden",
        },
      }}
      centered
    >
      {tabs.map(({ icon, label, to }, index) => (
        <StyledTab
          id={`NAVBAR_${to}`}
          key={index}
          value={to}
          // label={label}
          icon={
            <IconTab
              src={icon}
              label={label}
            />
          }
        />
      ))}
    </StyledTabs>
  );
};
export default TabsBar;


const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: "100%",
  color: theme.palette.stepper.text,
  overflow: "visible",
  "& .MuiTabs-scroller": {
    height: "175px",
    overflowX: "auto !important",
    overflowY: "hidden !important",
  },
  "& .MuiButtonBase-root img": {
    fill: theme.palette.primary.main,
    height: 60,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  height: "175px",
  width: "200px",
  color: theme.palette.primary.contrastText,
  opacity: "1",
  transition: `transform ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
  "& p": {
    visibility: "hidden",
  },
  "&:hover": {
    transform: "scale(1.25) translateY(-5px)",
  },
  "&:hover p": {
    visibility: "visible",
  },
  "&.Mui-selected": {
    transform: "scale(1.25) translateY(-5px)",
    color: theme.palette.primary.contrastText,
  },
  "&.Mui-selected p": {
    visibility: "visible",
  },
}));

