import {
  Box,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import React from "react";

export type TablePaginationProps = {
  page: number;
  isPrevButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
};

const TablePagination = ({
  page,
  isPrevButtonDisabled,
  isNextButtonDisabled,
  onPrevPage,
  onNextPage,
}: TablePaginationProps) => {
  const { palette } = useTheme();

  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
        paddingX={2}
        paddingY={1}
        borderRadius="25px"
        bgcolor={palette.primary.main}
      >
        <Box display="flex">
          {isPrevButtonDisabled ? (
            <StyledIconButton size="small" onClick={onPrevPage} disabled>
              <ArrowBackIosIcon
                style={{
                  fontSize: "12px", // FIXME:
                }}
              />
            </StyledIconButton>
          ) : (
            <Tooltip
              title="Anterior" // FIXME: traduccion
              placement="bottom"
            >
              <StyledIconButton size="small" onClick={onPrevPage}>
                <ArrowBackIosIcon
                  style={{
                    fontSize: "12px", // FIXME:
                  }}
                />
              </StyledIconButton>
            </Tooltip>
          )}

          <Box color={palette.primary.contrastText} marginX={1}>
            <Typography>{page + 1}</Typography>
          </Box>

          {isNextButtonDisabled ? (
            <StyledIconButton size="small" onClick={onNextPage} disabled>
              <ArrowForwardIosIcon
                style={{
                  fontSize: "12px", // FIXME:
                }}
              />
            </StyledIconButton>
          ) : (
            <Tooltip
              title="Siguiente" // FIXME: traduccion
              placement="bottom"
            >
              <StyledIconButton size="small" onClick={onNextPage}>
                <ArrowForwardIosIcon
                  style={{
                    fontSize: "12px", // FIXME:
                  }}
                />
              </StyledIconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TablePagination;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
