import { useCallback, useState } from "react";

export type UseSnackbarReturn = {
  isOpen: boolean;
  message?: string;
  open: (message: string) => void;
  close: () => void;
};

const useSnackbar = (initiallyOpen = false): UseSnackbarReturn => {
  const [message, setMessage] = useState<string>();

  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const open = useCallback((message: string) => {
    setMessage(message);
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    message,
    open,
    close,
  };
};

export default useSnackbar;
