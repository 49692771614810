export enum AccountingEntityRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":accountingEntityId/view",
  EDIT = ":accountingEntityId/edit",
}

export type AccountingEntityRoutesState = {
  [AccountingEntityRoutes.EDIT]: { activeStep?: number };
};
