import { DescriptionFormFields } from "components/inputs/DescriptionFormControl/types";
import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { regionEmptyRow } from "./RegionsForm/RegionsNamesForm/template";
import { RegionsFormFields } from "./RegionsForm/types";
import { BasicDataFormFields } from "./BasicDataForm/types";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<BasicDataFormFields>({
    defaultValues: initialValue?.basicData,
  });

  const descriptions = useForm<DescriptionFormFields>({
    defaultValues: initialValue?.descriptions ?? {
      descriptions: [emptyDescriptionRow],
    },
  });

  const regions = useForm<RegionsFormFields>({
    defaultValues: initialValue?.regions ?? { regions: [regionEmptyRow] },
  });

  return { basicData, descriptions, regions };
};
