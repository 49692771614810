import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { titlesEmptyRow } from "./TitlesForm/template";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const descriptions = useForm<FormFields["descriptions"]>({
    defaultValues: initialValue?.["descriptions"] ?? {
      descriptions: [titlesEmptyRow],
    },
  });

  const tileType = useForm<FormFields["tileType"]>({
    defaultValues: initialValue?.["tileType"],
  });

  tileType.register("tileType.shape", { required: true });
  tileType.register("tileType.hasImage");
  tileType.register("tileType.hasKpi");

  const kpi = useForm<FormFields["kpi"]>({
    defaultValues: initialValue?.["kpi"],
  });

  const icon = useForm<FormFields["icon"]>({
    defaultValues: initialValue?.["icon"],
  });

  icon.register("icon");

  return { basicData, descriptions, tileType, kpi, icon };
};
