import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Add as AddCircleOutline } from "@mui/icons-material";
import React from "react";
import { HiddenColumnsListProps } from "./types";

const HiddenColumns = <T extends string>({
  columns,
  onShowColumn,
}: HiddenColumnsListProps<T>) => (
  <List dense component="div" role="list">
    {columns.map(({ id, label }) => {
      return (
        <ListItem key={id} role="listitem">
          <ListItemIcon>
            <Tooltip
              title="Agregar proceso a escenario" // FIXME: traduccion
              placement="bottom"
            >
              <IconButton size="small" onClick={() => onShowColumn(id)}>
                <AddCircleOutline />
              </IconButton>
            </Tooltip>
          </ListItemIcon>

          <ListItemText id={id} primary={label} />
        </ListItem>
      );
    })}
    <ListItem />
  </List>
);

export default HiddenColumns;
