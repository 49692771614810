import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useMemo } from "react";
import { useGetDataElementsChoicesQuery } from "__generated__/graphql/types";

type Props = {
  generateMap: boolean;
};

const useDataElementChoices = (
  names: string[],
  { generateMap }: Props = { generateMap: false }
) => {
  const { language } = useSystemLanguage();

  const { data: { dataElements } = {} } = useGetDataElementsChoicesQuery({
    language,
    dataElementIds: names,
  });

  const dataElementsChoices:
    | Record<string, { value: string; text: string }[]>
    | undefined = useMemo(
    () =>
      dataElements == null
        ? undefined
        : Object.fromEntries(
            dataElements.map((dataElement) => [
              dataElement.id,
              dataElement.choices?.map((choice) => ({
                value: choice.id,
                text: choice.translation.description,
              })) ?? [],
            ])
          ),
    [dataElements]
  );

  const dataElementsChoicesMap:
    | Record<string, Record<string, string>>
    | undefined = useMemo(
    () =>
      generateMap
        ? Object.fromEntries(
            dataElements?.map((dataElement) => [
              dataElement.id,
              Object.fromEntries(
                dataElement.choices?.map((choice) => [
                  choice.id,
                  choice.translation.description,
                ]) ?? []
              ) ?? [],
            ]) ?? []
          )
        : undefined,
    [dataElements, generateMap]
  );

  return { dataElementsChoices, dataElementsChoicesMap };
};

export default useDataElementChoices;
