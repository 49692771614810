import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import {
  Box,
  Button, Grid, styled
} from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { MenuInstructions } from 'components/Instructor/steps/menu';
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from 'components/layout/LoadingLayoutPage';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSnackbar } from "hooks/useSnackbarV2";
import React from "react";
import { useNavigate } from "react-router-dom";
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { useGetDeliveryPurchaseOrderItemForDirectPageQuery } from '../../../__generated__/graphql/types';
import { InvoiceFreightDirectRoutes } from '../routes';
import { GroupsMatchcode } from "./components/GroupsMatchcode";

export type PurchaseOrderInformation = {
  purchaseOrder: string,
  purchaseOrderItem: string,
}

const PurchaseOrderItemSelectPage = () => {

  const [purchaseOrderInfo, setPurchaseOrderInfo] = React.useState<PurchaseOrderInformation | null>(null);
  const [validationMessage, setMessage] = React.useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const errorSnackbar = useSnackbar();

  const { data: { deliveryPurchaseOrderItems } = {} } = useGetDeliveryPurchaseOrderItemForDirectPageQuery();
  
  const { appMessage } = useAppMessages("MESSAGES");
  const { appMessage: appPurchaseOrdersItems } = useAppMessages("FREIGHT_INVOICE");
  const { dataElementsDescriptions } = useDataElementDescription(
    ["DELIVERY", "PURCHASE_ORDER"]
  );

  if (deliveryPurchaseOrderItems == null) return <LoadingLayoutPage />;
  if (dataElementsDescriptions == null) return <LoadingLayoutPage />;

  const changeInvoice = (value:string | undefined) => {

    if(!value) {
      setPurchaseOrderInfo(null);
      return;
    }

    const {purchaseOrder, purchaseOrderItem} = JSON.parse(value);

    setMessage(undefined);
    setPurchaseOrderInfo({
      purchaseOrder: purchaseOrder,
      purchaseOrderItem: purchaseOrderItem
    });
  }

  const navigateInvoices = () => {

    if(!purchaseOrderInfo) {
      setMessage(appMessage["CHOOSE_ONE"])
      return;
    }

    navigate(InvoiceFreightDirectRoutes.PATH_INVOICES, {
      state: {
        purchaseOrderId: purchaseOrderInfo.purchaseOrder,
        purchaseOrderItemsIds: [purchaseOrderInfo.purchaseOrderItem],
        toBack: TO_BACK_DISPLAY
      }
    });
  }

  return (
    <Layout>
      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      {/* Instructor */}
      <MenuInstructions tutorialsIds={["FREIGHT_DISPLAY"]} />

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appPurchaseOrdersItems["FREIGHT_INVOICE_TITLE"]}
          toBack={TO_BACK_ROUTER}
        />

        <BodyWrapper readOnly={false} >
          <Grid container direction="column" spacing={2} xs={12} alignItems="center" justifyContent="center">
            <GridGroupFields item id="delivery-group" xs={10}>
              <GroupsMatchcode
                invoices={deliveryPurchaseOrderItems}
                dataElementsDescriptions={dataElementsDescriptions}
                value={ 
                  purchaseOrderInfo
                  ? JSON.stringify({
                    purchaseOrder: purchaseOrderInfo.purchaseOrder, 
                    purchaseOrderItem: purchaseOrderInfo.purchaseOrderItem
                  })
                  : undefined
                 }
                onChange={(value) => changeInvoice(value)}
                text={purchaseOrderInfo?.purchaseOrderItem}
                invalid={false}
                errorMessage={validationMessage}
                readOnly={false}
              />
            </GridGroupFields>
            <Grid item>
              <ButtonStyled 
                id="find-button"
                aria-label="remove" 
                size="medium" 
                endIcon={<AssignmentOutlinedIcon/>} 
                onClick={() => navigateInvoices()}
              >                
                {appMessage["FIND"] ?? "Find"}
              </ButtonStyled>
            </Grid>
          </Grid>
        </BodyWrapper>

      </Box>
    </Layout>
  );
};

export default PurchaseOrderItemSelectPage;

const TO_BACK_DISPLAY = "../";
const TO_BACK_ROUTER = "../../";

const ButtonStyled = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const BodyWrapper = styled(FormNonTableBodyWrapper)(({theme}) => ({
  marginTop: theme.spacing(1)
}));

const GridGroupFields = styled(Grid)({
  minWidth: '300px', // FIXME:
  maxWidth: '300px', // FIXME:
  textAlign:'center',
  alignItems: 'center'
});

