import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { Pagination } from "utils/Pagination";
import { push } from "utils/push";
import { COLUMNS_KEYS } from "./columnsSlice";

export const FILTERS_KEYS = COLUMNS_KEYS;

export type FiltersKeys = typeof FILTERS_KEYS[number];

export type Filters = Partial<{
  name: string;
  description: string;
}>;

type FiltersState = {
  filtersVisibility: Record<FiltersKeys, boolean>;
  filters: Filters;
  pagination: Pagination;
};

const initialState: FiltersState = {
  filtersVisibility: FILTERS_KEYS.reduce(
    (prev, curr) => push(prev, curr, false),
    {} as Record<FiltersKeys, boolean>
  ),
  filters: {},
  pagination: { page: 0, pageSize: 5 },
};

const viewFiltersAndPaginationSlice = createSlice({
  name: "businessPartnerTypesFiltersAndPaginationSlice",
  initialState,
  reducers: {
    setFilterVisibility: (
      state,
      action: PayloadAction<{
        key: FiltersKeys;
        isVisible: boolean;
      }>
    ) => {
      const { key, isVisible } = action.payload;
      state.filtersVisibility[key] = isVisible;
      state.filters[key] = undefined;
    },
    setFilters: (state, action: PayloadAction<Filters>) => {
      state.pagination = initialState.pagination;
      state.filters = action.payload;
    },
    prevPage: (state) => {
      state.pagination.page--;
    },
    nextPage: (state) => {
      state.pagination.page++;
    },
    resetPage: (state) => {
      state.pagination.page = 0;
    },
  },
});

export const {
  setFilters,
  setFilterVisibility,
  resetPage,
  prevPage,
  nextPage,
} = viewFiltersAndPaginationSlice.actions;

export const selectIsFilterVisible = (state: RootState) =>
  state.businessPartnerType.filtersAndPagination.filtersVisibility;

export const selectVisibleFiltersKeys = (state: RootState) => {
  const { filtersVisibility } = state.businessPartnerType.filtersAndPagination;
  return FILTERS_KEYS.filter((filterKey) => filtersVisibility[filterKey]);
};

export const selectHiddenFiltersKeys = (state: RootState) => {
  const { filtersVisibility } = state.businessPartnerType.filtersAndPagination;
  return FILTERS_KEYS.filter((filterKey) => !filtersVisibility[filterKey]);
};

export const selectFilters = (state: RootState) =>
  state.businessPartnerType.filtersAndPagination.filters;

export const selectPagination = (state: RootState) =>
  state.businessPartnerType.filtersAndPagination.pagination;

export default viewFiltersAndPaginationSlice.reducer;
