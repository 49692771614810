import React from "react";
import { Box, styled, Tooltip } from "@mui/material";
import { green, yellow } from "@mui/material/colors";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import {
  CheckCircle as CheckCircleIcon,
  InfoRounded as InfoRoundedIcon
} from "@mui/icons-material";

export type TrafficLightStatus = "COMPLETE" | "INCOMPLETE";

type TrafficLightElementProps = {
  text: string;
  element: any;
};

type TrafficLightProps = {
  status: TrafficLightStatus;
};

const TrafficLight = ({ status }: TrafficLightProps) => {

  const { dataElementsChoices } = useDataElementChoices(["COMPLETE_INVOICE"]);
  if (dataElementsChoices === undefined) return <BeatLoadingPage />;

  const statusTextAndElement: Record<TrafficLightStatus, TrafficLightElementProps> = {
    COMPLETE: {
      text: dataElementsChoices["COMPLETE_INVOICE"].find( e => e.value === "COMPLETE")?.text ?? "Complete",
      element: <StyledCheckCircleIcon />,
    },
    INCOMPLETE: {
      text: dataElementsChoices["COMPLETE_INVOICE"].find( e => e.value === "INCOMPLETE")?.text ?? "Incomplete",
      element: <StyledIncompleteIcon />,
    },
  };

  return (
    <Tooltip title={statusTextAndElement[status].text} placement="top">
      <Box display="flex" justifyContent="center">
        {statusTextAndElement[status].element}
      </Box>
    </Tooltip>
  );
};

export default TrafficLight;

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: green[200],
});

const StyledIncompleteIcon = styled(InfoRoundedIcon)({
  color: yellow[600],
});