import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetPurchaseEntity_ViewPageQuery,
  useUpdatePurchaseEntityMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { PurchaseEntityRoutesState, PurchaseEntityRoutes } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

type Route = PurchaseEntityRoutes.EDIT;
type LocationState = PurchaseEntityRoutesState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { purchaseEntityId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const {
    data: { purchaseEntity } = {},
    isFetchedAfterMount,
  } = useGetPurchaseEntity_ViewPageQuery({
    id: purchaseEntityId,
  });

  const initialValue =
    purchaseEntity != null && isFetchedAfterMount
      ? mapDtoToForm(purchaseEntity)
      : undefined;

  const { mutateAsync: replace } = useUpdatePurchaseEntityMutation();

  const onSave = (form: FormFields) =>
    replace({
      id: purchaseEntityId,
      purchaseEntity: mapFormToDto(form),
    });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header title="Editar entidad de compra" toBack={TO_BACK_ROUTER} />

      <Form
        mode="edit"
        onSave={onSave}
        onCancel={onCancel}
        errorMessage="Error al editar la entidad de compra. Vuelva a intentar."
        initialValue={initialValue}
        initialStep={activeStep}
      />
    </Layout>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
