import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { regionDescriptionEmptyRow } from "../RegionsDescriptionsForm/template";
import { Region, RegionsFormFields } from "../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { RegionsNamesFormProps } from "./types";

export const regionEmptyRow: Region = {
  name: "",
  descriptions: [regionDescriptionEmptyRow],
};

const RegionsNamesForm = ({
  readOnly = false,
  onEdit,
  onEnterDescriptions,
  form,
}: RegionsNamesFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray<RegionsFormFields>({
    control,
    name: "regions",
  });

  const appendRow = () => {
    append(regionEmptyRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header
          onAppend={() => appendRow()}
          onEdit={() => onEdit?.()}
          readOnly={readOnly}
        />

        <StyledTableBody>
          {fields.map((item, index) => (
            <Row
              key={item.id}
              index={index}
              remove={onRemove(index)}
              item={item}
              readOnly={readOnly}
              onEnterDescriptions={onEnterDescriptions}
              form={form}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default RegionsNamesForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
