import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ascend, sortWith } from "ramda";
import { RootState } from "store";
import {
  isColumnVisible,
  isPairColumnHidden,
  isPairColumnVisible,
} from "utils/columnTypesUtils";
import { push } from "utils/push";

type TableHiddenColumn = { isVisible: false };

type TableColumnVisible = { isVisible: true; position: number };

export type TableColumn = TableColumnVisible | TableHiddenColumn;

export const TABLE_COLUMNS_KEYS = [
  "name",
  "description",
  "status",
  "tableType",
  "businessObject",
] as const;

export type TableColumnsKeys = typeof TABLE_COLUMNS_KEYS[number];

export const dataElementsColumnsLabels: Record<TableColumnsKeys, string> = {
  name: "Nombre", // FIXME: traduccion
  description: "Descripción", // FIXME: traduccion
  tableType: "Tipo de tabla", // FIXME: traduccion
  status: "Estatus", // FIXME: traduccion
  businessObject: "Objeto de negocio", // FIXME: traduccion
};

const tableColumnsInitialValues = TABLE_COLUMNS_KEYS.reduce(
  (prev, curr, index) => push(prev, curr, { isVisible: true, position: index }),
  {} as Record<TableColumnsKeys, TableColumn>
);

type TableColumnsState = Record<TableColumnsKeys, TableColumn>;

const initialState: TableColumnsState = tableColumnsInitialValues;

const viewTableColumnsSlice = createSlice({
  name: "tablesVisibleColumnsSlice",
  initialState,
  reducers: {
    hideColumn: (state, action: PayloadAction<{ id: TableColumnsKeys }>) => {
      const { id } = action.payload;
      state[id] = { isVisible: false };
    },
    showColumn: (state, action: PayloadAction<{ id: TableColumnsKeys }>) => {
      const { id } = action.payload;
      const positions = Object.values(state)
        .filter(isColumnVisible<TableColumn, TableColumnVisible>())
        .map((e) => e.position);
      const maxPosition = Math.max(...positions);
      state[id] = { isVisible: true, position: maxPosition + 1 };
    },
    orderColumns: (
      state,
      action: PayloadAction<{ ids: TableColumnsKeys[] }>
    ) => {
      action.payload.ids.forEach((value, index) => {
        const column = state[value];
        if (!isColumnVisible<TableColumn, TableColumnVisible>()(column)) return;
        column.position = index;
      });
    },
  },
});

export const {
  hideColumn,
  showColumn,
  orderColumns,
} = viewTableColumnsSlice.actions;

const sorter = sortWith<[TableColumnsKeys, TableColumnVisible]>([
  ascend(([, value]) => value.position),
]);

export const selectTableVisibleColumnsKeysInOrder = (
  state: RootState
): TableColumnsKeys[] => {
  const visibleColumns = Object.entries(state.tables.columns).filter(
    isPairColumnVisible<TableColumn, TableColumnVisible, TableColumnsKeys>(
      TABLE_COLUMNS_KEYS
    )
  );

  return sorter(visibleColumns).map(([key]) => key);
};

export const selectTableHiddenColumnsKeys = (
  state: RootState
): TableColumnsKeys[] => {
  return Object.entries(state.tables.columns)
    .filter(
      isPairColumnHidden<TableColumn, TableHiddenColumn, TableColumnsKeys>(
        TABLE_COLUMNS_KEYS
      )
    )
    .map(([key]) => key);
};

export default viewTableColumnsSlice.reducer;
