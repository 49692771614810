import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import accountingEntities from "apps/AccountingEntitiesPage/reducer";
import appsReducer from "apps/AppsPage/reducer";
import backendReducer from "apps/BackendsPage/reducer";
import businessGroups from "apps/BusinessGroupsPage/reducer";
import businessObjectsReducer from "apps/BusinessObject/reducer";
import businessPartnerReducer from "apps/BusinessPartnersPage/reducer";
import businessPartnerTypeReducer from "apps/BusinessPartnerTypePage/reducer";
import businessProfilesReducer from "apps/BusinessProfile/reducer";
import businessUnitsReducer from "apps/BusinessUnitsPage/reducer";
import chartOfAccountReducer from "apps/ChartOfAccountsPage/reducer";
import commercialEntities from "apps/CommercialEntityPage/reducer";
import companies from "apps/CompaniesPage/reducer";
import costCollectorReducer from "apps/CostCollectorPage/reducer";
import countriesIdsTypesReducer from "apps/CountriesIdsTypesPage/reducer";
import countryReducer from "apps/CountriesPage/reducer";
import currencyReducer from "apps/CurrencyPage/reducer";
import dataElementsReducer from "apps/DataElementsPage/reducer";
import employees from "apps/EmployeesPage/reducer";
import exportMigrationsPackageReducer from "apps/ExportMigrationsPackagePage/reducer";
import groupsReducer from "apps/GroupsPage/reducer";
import importMigrationsPackageReducer from "apps/ImportMigrationsPackagePage/reducer";
import invoiceFreightReducer from "apps/InvoicesFreightPage/reducer";
import invoicesReportsReducer from "apps/InvoicesOverviewPage/report/reducer";
import invoicesReducer from "apps/InvoicesPage/reducer";
import kpiReducer from "apps/KpiPage/reducer";
import languageReducer from "apps/LanguagePage/reducer";
import locations from "apps/LocationsPage/reducer";
import locationTypeReducer from "apps/LocationTypePage/reducer";
import messagesReducer from "apps/MessagesPage/reducer";
import positionsReducer from "apps/PositionsPage/reducer";
import purchaseEntities from "apps/PurchaseEntitiesPage/reducer";
import purchaseOrderItemsReportsReducer from "apps/PurchaseOrdersItemsOverviewPage/report/reducer";
import purchaseOrderItemsReducer from "apps/PurchaseOrdersItemsPage/ViewPage/reducer";
import purchaseOrdersReducer from "apps/PurchaseOrdersPage/reducer";
import revenueCollectorReducer from "apps/RevenueCollectorsPage/reducer";
import tablesReducer from "apps/TablesPage/reducer";
import themesReducer from "apps/ThemePage/reducer";
import tilesReducer from "apps/TilesPage/reducer";
import usersReducer from "apps/UsersPage/reducer";
import instructionsReducer from 'components/Instructor/reducer/instructionsReducer';
import themeSlice from "components/providers/ReduxMuiThemeProvider/reducer";
import myInformationSlice from "components/providers/ReduxMyProvider/reducer";
import groupSelectedReducer from "pages/ReportsPage/ViewReportsPage/reducer/groupSelected";
import tutorialsReducer from './apps/TutorialsPage/reducer';

export const store = configureStore({
  reducer: {
    me: myInformationSlice,
    groupSelected: groupSelectedReducer,
    instructions: instructionsReducer,
    theme: themeSlice,
    themes: themesReducer,
    dataElements: dataElementsReducer,
    tables: tablesReducer,
    businessObjects: businessObjectsReducer,
    apps: appsReducer,
    tiles: tilesReducer,
    groups: groupsReducer,
    businessProfiles: businessProfilesReducer,
    users: usersReducer,
    kpi: kpiReducer,
    language: languageReducer,
    message: messagesReducer,
    currencies: currencyReducer,
    countries: countryReducer,
    chartOfAccounts: chartOfAccountReducer,
    businessGroups: businessGroups,
    accountingEntities: accountingEntities,
    companies: companies,
    purchaseEntities: purchaseEntities,
    commercialEntities: commercialEntities,
    locations: locations,
    locationType: locationTypeReducer,
    businessPartnerType: businessPartnerTypeReducer,
    countriesIdsTypes: countriesIdsTypesReducer,
    backends: backendReducer,
    exportMigrationsPackages: exportMigrationsPackageReducer,
    importMigrationsPackages: importMigrationsPackageReducer,
    employees: employees,
    businessPartners: businessPartnerReducer,
    positions: positionsReducer,
    purchaseOrders: purchaseOrdersReducer,
    purchaseOrdersItems: purchaseOrderItemsReducer,
    purchaseOrdersItemsReports: purchaseOrderItemsReportsReducer,
    invoices: invoicesReducer,
    invoicesReports: invoicesReportsReducer,
    invoicesFreight: invoiceFreightReducer,
    revenueCollectors: revenueCollectorReducer,
    costCollectors: costCollectorReducer,
    businessUnits: businessUnitsReducer,
    tutorials: tutorialsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type CountryDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
