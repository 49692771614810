import { Clear as ClearIcon } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAppMessages } from "hooks/useAppMessages";
import React, { useCallback } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { DraggableVisibleColumnsListProps } from "./types";
import { moveElementInListToPosition } from "./utils";

const VisibleColumns = <T extends string>({
  columns,
  onOrderColumns,
  onHideColumn,
}: DraggableVisibleColumnsListProps<T>) => {
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (typeof result?.destination?.index === "undefined") {
        return;
      }

      const newColumnsOrder = moveElementInListToPosition(
        result.source.index,
        result.destination.index,
        columns
      );

      onOrderColumns(newColumnsOrder.map(({ id }) => id));
    },
    [columns, onOrderColumns]
  );

  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(dropableProvided) => (
          <>
            <List
              ref={dropableProvided.innerRef}
              component="nav"
              aria-label="main mailbox folders"
            >
              {columns.map(({ id, label }, index) => {
                return (
                  <Draggable
                    // FIXME:
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${id}:${index}`}
                    draggableId={`${id}:${index}`}
                    index={index}
                  >
                    {(dragableProvided) => (
                      <ListItem
                        ref={dragableProvided.innerRef} // FIXME: migracion mui v5
                        {...dragableProvided.draggableProps}
                        {...dragableProvided.dragHandleProps}
                      >
                        <Grid container wrap="nowrap">
                          <Grid item>
                            <Box
                              component="span"
                              whiteSpace="nowrap"
                              fontWeight="bold"
                              bgcolor={grey["700"]}
                              color={grey["200"]}
                              marginRight="4px"
                              borderRadius="2px"
                              paddingX="4px"
                            >
                              {index + 1}
                            </Box>
                          </Grid>

                          <Grid item>{label}</Grid>
                        </Grid>

                        <ListItemIcon>
                          <Tooltip
                            title={appMessage["DONT_SHOW_COLUMN"] ?? "Don't show column"}
                            placement="bottom"
                          >
                            <IconButton
                              size="small"
                              onClick={() => onHideColumn(id)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                    )}
                  </Draggable>
                );
              })}
              {dropableProvided.placeholder}
            </List>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VisibleColumns;
