import {
  MenuItemsProps,
  TableColumnType, TableRowType
} from "components/datadisplay/GenericTable/template";
import {
  GolfCourse as GolfCourseIcon,
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppMessages } from "hooks/useAppMessages";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useCallback, useMemo } from "react";
import { push } from "utils/push";
import {
  GetThemesQuery,
} from '../../../../../__generated__/graphql/types';
import { useThemesLabels } from "../../useThemesLabels";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked
} from "../../reducers/checkedRowsSlice";
import {
  nextPage,
  prevPage,
} from "../../reducers/filtersAndPaginationSlice";
import {
  ColumnsKeys,
  COLUMNS_KEYS,
  selectVisibleColumnsKeysInOrder
} from "../../reducers/visibleColumnsSlice";

type useTableProps = {
  themes: GetThemesQuery["themes"];
  menuItems: MenuItemsProps[]
};

export const useTable = ({
  themes,
  menuItems
}: useTableProps) => {

  const dispatch = useAppDispatch();
  const errorSnackbar = useSnackbar();
  
  const isDataElementsRowChecked = useAppSelector(selectIsRowChecked);
  const { appMessage } = useAppMessages("THEMES");

  const orderedColumnsKeys = useAppSelector(selectVisibleColumnsKeysInOrder);
  const areAllDataElementsRowsChecked = useAppSelector(selectAreAllRowsChecked);
  const { columnsLabels } = useThemesLabels();

  const columns: TableColumnType<ColumnsKeys>[] = useMemo(
    () => {
      const header = COLUMNS_KEYS.reduce((acc, curr) => {
        return push(acc, curr, {
          key: curr,
          content: columnsLabels[curr].toUpperCase(),
        });
      }, {} as Record<ColumnsKeys, TableColumnType<ColumnsKeys>>);
      return orderedColumnsKeys.map((colKey) => header[colKey]);
    },
    [orderedColumnsKeys, columnsLabels]
  );
  
  const rows = useMemo(() => themes?.map(dto => {
    const res: TableRowType<ColumnsKeys> =  {
      rowKey: dto!.id,
      name: dto.id,
      description: dto.translation.description,
      isDefault: dto.isDefault && <GolfCourseIcon />,
    }
    return res;
  }), [themes]);

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPage());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  return {
    rows,
    errorSnackbar,
    columns,
    appMessage,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
  };
};