export enum PurchaseOrdersItemsOverviewRoutes {
  PATH = "/",
  PATH_INVOICES = "invoices",
  PATH_INVOICES_VIEW = "invoices/view",
}

export type PurchaseOrdersItemsOverviewState = {
  purchaseOrderId: string
};

