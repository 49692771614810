import { Box, Dialog, DialogTitle, styled } from "@mui/material";
import { UpdatePasswordForm } from "apps/UsersPage/ViewAllPage/components/PasswordForm";
import { useAppMessages } from "hooks/useAppMessages";
import { AppRoutes } from "pages/AppRoutes";
import { useLogMeOut } from "hooks/useLogMeOut";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMyPasswordUpdatedQuery } from "__generated__/graphql/types";

const DialogChangePassword = () => {

  const { data: { me } = {}, refetch: refetchMe } = useGetMyPasswordUpdatedQuery();
  const [open, setOpen] = React.useState(true);
  const [password, setPasswordUpdated] = React.useState(false);

  const navigate = useNavigate();
  const { appMessage } = useAppMessages("MAIN");
  const { mutateAsync: logout } = useLogMeOut();

  const handleClose = () => {
    logout();
    setOpen(false);
  };

  useEffect(() => {
    refetchMe();
    if(me === undefined) return;

    if (me.isPasswordUpdated) {
      setOpen(false);
      navigate(AppRoutes.PATH_REPORTS);
    } 
  }, [me, navigate, refetchMe]);

  useEffect(() => {
    if(password){
      setOpen(false);
      navigate(AppRoutes.PATH_REPORTS);
    }
  }, [password, navigate])

  return (
    <>
      { me && !me.isPasswordUpdated && (
          <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
            <DialogTitle>
              {`${appMessage["CHANGE_PASSWORD"]} ${appMessage["PASSWORD"]}`}
            </DialogTitle>
            <UpdatePasswordBox>
              <UpdatePasswordForm setPasswordUpdated={setPasswordUpdated}/>
            </UpdatePasswordBox>
          </Dialog>
        )
      }
    </>
  );
};

export default DialogChangePassword;

const UpdatePasswordBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  margin: "auto",
});