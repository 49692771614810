import axios from "axios";
import { getSessionTokenHeader } from "__dev__/auth";

const axiosAuthToken = axios.create();

axiosAuthToken.interceptors.request.use((config) => {
  config.headers = {
    ...getSessionTokenHeader(), // FIXME: solo para ambiente dev
  };
  return config;
});

export default axiosAuthToken;
