import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  BackendExistsDocument,
  BackendExistsQuery,
  BackendExistsQueryVariables,
  useGenerateBackendPasswordMutation,
} from "__generated__/graphql/types";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Fingerprint } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export type BasicDataFormFields = {
  id: string;
  url: string;
  ip: string;
  domain: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;

  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const canSee = mode === "edit";

  const { dataElementsDescriptions } = useDataElementDescription([
    "BACKEND",
    "URL",
    "IP",
    "DOMAIN",
  ]);

  const {
    mutateAsync: generatePassword,
  } = useGenerateBackendPasswordMutation();

  const [state, setstate] = useState("");

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="wrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["BACKEND"]}
              controllerProps={{
                name: "id",
                control,
                rules: {
                  required: "Ingrese el nombre.", // FIXME: traduccion
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { backendExists } = await backendExistsFn({
                      id: value,
                    });
                    return backendExists
                      ? "El nombre ya existe." // FIXME: traduccion
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: disabled }}
            label={dataElementsDescriptions["URL"]}
            controllerProps={{
              name: "url",
              control,
              rules: {
                required: "Ingrese la URL.", // FIXME: traduccion
              },
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: disabled }}
            label={dataElementsDescriptions["IP"]}
            controllerProps={{
              name: "ip",
              control,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldController
            inputProps={{ disabled: disabled }}
            label={dataElementsDescriptions["DOMAIN"]}
            controllerProps={{
              name: "domain",
              control,
            }}
          />
        </Grid>
        {canSee && (
          <>
            <Tooltip title="Copy">
              <Grid item>
                <CopyToClipboard
                  text={state}
                  onCopy={() => console.log("copied")}
                >
                  <Button variant="contained" style={{textTransform: 'none'}} endIcon={<ContentCopyIcon />}>
                    {state}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Tooltip>
            <Grid item xs={3}>
              <IconButton
                aria-label="fingerprint"
                color="secondary"
                onClick={() => {
                  generatePassword({ id: form.getValues().id })
                    .then((r) => setstate(r.generateBackendRandomPassword))
                    .catch(() =>
                      console.log(
                        "Error" // FIXME: traduccion
                      )
                    );
                }}
              >
                {" "}
                Generate
                <Fingerprint />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const backendExistsFn = pDebounce(
  (args: BackendExistsQueryVariables) =>
    fetcher<BackendExistsQuery, BackendExistsQueryVariables>(
      BackendExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
