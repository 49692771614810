import {
  DeleteForever as DeleteForeverIcon, DownloadRounded as DownloadRoundedIcon, Edit as EditIcon,
  Search as SearchIcon, SendRounded as SendRoundedIcon
} from "@mui/icons-material";
import { useDeleteInvoiceMutation, useGetInvoicesViewAllPageQuery, useUpdateInvoiceStatusMutation } from "__generated__/graphql/types";
import { resetTablePage } from "apps/TablesPage/ViewAllPage/reducers/filtersAndPaginationSlice";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useSnackbar } from "hooks/useSnackbarV2";
import { UseSnackbarReturn } from "hooks/useSnackbarV2/hook";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { downloadFile } from "utils/downloadFile";
import { notNullUndefined } from "utils/notNullUndefined";
import { useLocationState } from "utils/typedRoutesUtils";
import useAllowedUserAppActions from '../../../hooks/useAlloweduserAppActions/template';
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { InvoicesRoutes, InvoicesRoutesState } from '../routes';
import { TrafficLight } from "./components/Table/TrafficLight";
import { InvoiceCheckedRowsType, selectRowsChecked } from "./reducers/checkedRowsSlice";
import { selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useViewAllPage = () => {

  const state = useLocationState<InvoicesRoutesState>();

  // FIXME: Agregar validacion si el state es null
  const purchaseOrderId = state!.purchaseOrderId;
  const purchaseOrderItemId = state!.purchaseOrderItemsIds[0]; 
  const toBack = state!.toBack;
    
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const { mutateAsync: deleteInvoice } = useDeleteInvoiceMutation();
  const { mutateAsync: updateInvoiceStatus } = useUpdateInvoiceStatusMutation();
  
  const { appMessage: appInvoices } = useAppMessages("INVOICES");
  const { appMessage: appMessages } = useAppMessages("MESSAGES");
  const { actions } = useAllowedUserAppActions("INVOICES");
  const { formatConfig } = useLanguageAndRegionConfig();
  
  const errorSnackbar = useSnackbar();
  const rowsChecked = useAppSelector(selectRowsChecked);
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);

  const {
    data: { purchaseOrdersInvoices, purchaseOrdersInvoicesCount: total } = {},
    refetch: getInvoices,
  } = useGetInvoicesViewAllPageQuery({
    purchaseOrder: purchaseOrderId, 
    purchaseOrderItem: purchaseOrderItemId,
    pagination,
    filters: {
      id: filters.id,
      invoiceVendorNumber: filters.invoiceVendorNumber,
      invoiceBackendNumber: filters.invoiceBackendNumber,
      headerText: filters.headerText,
      fiscalYear: filters.fiscalYear,
      currency: filters.currency,
      invoiceDate: filters.invoiceDate,
      invoiceStatus: filters.invoiceStatus as any, // FIXME:
    }
  });

  const rows = useMemo(() => purchaseOrdersInvoices?.map(dto => {
    const res: TableRowType<ColumnsKeys> =  {
      rowKey: dto!.id,
      id: dto!.id,
      invoiceVendorNumber: dto?.invoiceVendorNumber,
      invoiceBackendNumber: dto?.invoiceBackendNumber,
      headerText: dto?.headerText,
      fiscalYear: dto?.fiscalYear,
      total: formatConfig.amountMapper(dto?.total),
      currency: dto?.currency,
      invoiceDate: formatConfig.dateMapper(dto?.invoiceDate),
      plannedPaymentDate: formatConfig.dateMapper(dto?.plannedPaymentDate),
      lastPaymentDate: formatConfig.dateMapper(dto?.lastPaymentDate),
      rejectionReason: dto?.rejectionReason,
      invoiceStatus: <TrafficLight status={dto!.invoiceStatus} />,
    }
    return res;
  }), [purchaseOrdersInvoices, formatConfig]);


  const {
    data: { purchaseOrdersInvoices: exportToExcel } = {},
  } = useGetInvoicesViewAllPageQuery({
    purchaseOrder: purchaseOrderId, 
    purchaseOrderItem: purchaseOrderItemId,
    filters: {
      id: filters.id,
      invoiceVendorNumber: filters.invoiceVendorNumber,
      invoiceBackendNumber: filters.invoiceBackendNumber,
      headerText: filters.headerText,
      fiscalYear: filters.fiscalYear,
      currency: filters.currency,
      invoiceDate: filters.invoiceDate,
      invoiceStatus: filters.invoiceStatus as any, // FIXME:
    }
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              id: dto!.id,
              invoiceVendorNumber: dto?.invoiceVendorNumber ?? "",
              invoiceBackendNumber: dto?.invoiceBackendNumber ?? "",
              headerText: dto?.headerText ?? "",
              fiscalYear: dto?.fiscalYear ?? "",
              total: formatConfig.amountMapper(dto?.total),
              currency: dto?.currency ?? "",
              invoiceDate: formatConfig.dateMapper(dto?.invoiceDate),
              plannedPaymentDate: formatConfig.dateMapper(dto?.plannedPaymentDate),
              lastPaymentDate: formatConfig.dateMapper(dto?.lastPaymentDate),
              rejectionReason: dto?.rejectionReason ?? "",
              invoiceStatus: dto?.invoiceStatus ?? "",
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel, formatConfig]
  );

  const readAction = actions.find(e => e.id === "READ");
  const updateAction = actions.find(e => e.id === "UPDATE");
  const deleteAction = actions.find(e => e.id === "DELETE");
  const sendAction = actions.find(e => e.id === "SEND");
  const downloadAction = actions.find(e => e.id === "DOWNLOAD");

  const menuItems = useMemo(
    () => [
      readAction && {
        key: "display",
        Icon: SearchIcon,
        text: readAction.translation.description,
        onClick: (id: string) => {
          if(id === "display") {
            if(validateSelectRows(rowsChecked, purchaseOrderId, purchaseOrderItemId, appMessages, errorSnackbar)) return;
            id = rowsChecked[0].id
          }
          navigate(InvoicesRoutes.PATH_INVOICES_VIEW, {
            state: {
              invoiceNumber: id,
              purchaseOrderId: purchaseOrderId,
              purchaseOrderItemsIds: [purchaseOrderItemId],
              toBack: toBack
            }
          })
        },
      },
      updateAction && {
        key: "update",
        Icon: EditIcon,
        text: updateAction.translation.description,
        onClick: (id: string) => {
          if(id === "update") {
            if(validateSelectRows(rowsChecked, purchaseOrderId, purchaseOrderItemId, appMessages, errorSnackbar)) return;
            id = rowsChecked[0].id
          }
          navigate(InvoicesRoutes.PATH_INVOICES_EDIT, {
            state: {
              invoiceNumber: id,
              purchaseOrderId: purchaseOrderId,
              purchaseOrderItemsIds: [purchaseOrderItemId],
              toBack: toBack
            }
          })
        },
      },
      deleteAction && {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: deleteAction.translation.description,
        onClick: (id: string) => {
          if(id === "delete") {
            if(validateSelectRows(rowsChecked, purchaseOrderId, purchaseOrderItemId, appMessages, errorSnackbar)) return;
            id = rowsChecked[0].id
          }
          deleteInvoice({ id })
            .then(() => dispatch(resetTablePage()))
            .then(() => getInvoices())
            .catch(() => errorSnackbar.open(appInvoices["ERROR"]))
        },
      },
      sendAction && {
        key: "send",
        Icon: SendRoundedIcon,
        text: sendAction.translation.description,
        onClick: (id: string) => {
          if(id === "send") {
            if(validateSelectRows(rowsChecked, purchaseOrderId, purchaseOrderItemId, appMessages, errorSnackbar)) return;
            id = rowsChecked[0].id
          }
          updateInvoiceStatus({ id })
            .then(() => dispatch(resetTablePage()))
            .then(() => getInvoices())
            .catch(() => errorSnackbar.open(appInvoices["ERROR"]))
        },
      },
      downloadAction && {
        key: "download",
        Icon: DownloadRoundedIcon,
        text: downloadAction.translation.description,
        onClick: (id: string) => {
          if(id === "download") {
            if(validateSelectRows(rowsChecked, purchaseOrderId, purchaseOrderItemId, appMessages, errorSnackbar)) return;
            id = rowsChecked[0].id
          }
          const invoice = purchaseOrdersInvoices?.find( e => e?.id === id)
          if(invoice) downloadFile(invoice.file.url, invoice.file.id, undefined, "pdf")
        },
      }
    ].filter(notNullUndefined),
    [
      dispatch, navigate, deleteInvoice, updateInvoiceStatus, getInvoices, 
      appMessages, rowsChecked, purchaseOrderId, toBack, errorSnackbar,
      purchaseOrderItemId, purchaseOrdersInvoices, appInvoices, deleteAction, 
      downloadAction, readAction, sendAction, updateAction
    ]
  );
  
  const menuItemsRow = useMemo(
    () => purchaseOrdersInvoices?.map(dto => {
      if(dto?.invoiceStatus === "CREATED") {
        return { 
          key: dto!.id,
          menuItems: menuItems
        }
      } else if(dto?.invoiceStatus === "REJECTED") {
        return { 
          key: dto!.id,
          menuItems: menuItems.filter(e => e.key !== "delete" && e.key !== "send")
        }
      } else {
        return { 
          key: dto!.id,
          menuItems: menuItems.filter( e => e.key !== "delete" && e.key !== "update" && e.key !== "send")
        }
      }
    }), 
    [purchaseOrdersInvoices, menuItems]
  );

  return {
    errorSnackbar,
    appInvoices,
    menuItems,
    menuItemsRow,
    rows,
    total,
    pagination,
    sheet,
    state
  }
}

const validateSelectRows: any = (
  rowsChecked: InvoiceCheckedRowsType[], 
  purchaseOrder: string, 
  item: string, 
  appMessages: any, 
  errorSnackbar: UseSnackbarReturn
) => {
  const rowsFiltered = rowsChecked.filter(e => e.purchaseOrder === purchaseOrder && e.item === item);
  if(rowsFiltered.length === 0) {
    errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
    return true;
  } else if(rowsFiltered.length > 1) {
    errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
    return true;
  }
  return false;
}