import { Close as CloseIcon, Save as SaveIcon } from "@mui/icons-material";
import { Box, Grid, IconButton, styled, Tooltip } from "@mui/material";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { useAppMessages } from "hooks/useAppMessages";

type SaveCloseButtonsProps = {
  isCancelButtonVisible: boolean;
  isSaveButtonVisible: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const SaveCloseButtons = ({
  onSave,
  onCancel,
  isCancelButtonVisible,
  isSaveButtonVisible,
}: SaveCloseButtonsProps) => {

  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <>
      {
        // Instructor
        isSaveButtonVisible && (
          <MenuInstructions tutorialsIds={["FORM_SAVE"]} />
        )
      }

      <Box marginTop={2}>
        <Grid container spacing={2} justifyContent="center">
          {isSaveButtonVisible && (
            <Grid item>
              <SaveIconButton id="form-save-button" onClick={onSave}>
                <Tooltip
                  title={appMessage["SAVE"] ?? "Save"}
                >
                  <SaveIcon fontSize="large" />
                </Tooltip>
              </SaveIconButton>
            </Grid>
          )}

          {isCancelButtonVisible && (
            <Grid item>
              <CloseIconButton onClick={onCancel}>
                <Tooltip
                  title={appMessage["EXIT"] ?? "Exit"}
                >
                  <CloseIcon fontSize="large" />
                </Tooltip>
              </CloseIconButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default SaveCloseButtons;

const SaveIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: "#EEE",
  },
}));
