import { TableCell, TableRow } from "@mui/material";
import { CheckboxController } from "components/inputs/CheckboxController";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { PermissionsFormFields } from "../../types";
import { AppActionDtoForCreateBusinessProfiles } from "../types";

export type RowProps = {
  readOnly: boolean;
  index: number;
  action: AppActionDtoForCreateBusinessProfiles;
  appId: string;
  form: UseFormReturn<PermissionsFormFields>;
};

const Row = ({ readOnly, action, appId, form }: RowProps) => {
  const actionId = action.id;

  const isAllowed =
    useWatch({
      control: form.control,
      name: `apps.${appId}.actions.${actionId}.isAllowed` as "apps.0.actions.0.isAllowed",
    }) ?? false;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <CheckboxController
          controllerProps={{
            control: form.control,
            name: `apps.${appId}.actions.${actionId}.isAllowed` as "apps.0.actions.0.isAllowed",
            defaultValue: isAllowed,
          }}
          CheckboxProps={{
            inputProps: { disabled: readOnly },
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextField
          value={action.id.toUpperCase()}
          inputProps={{ disabled: true }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextField
          value={action.translation.description}
          inputProps={{ disabled: true }}
        />
      </TableCell>

      <TableCell component="th" scope="row"></TableCell>
    </TableRow>
  );
};

export default Row;
