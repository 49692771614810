import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  Radio,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from "react-hook-form";
import { range } from "utils/rangeUtil";
import { GetTablesForCreateBusinessObjectQuery } from "__generated__/graphql/types";
import { TablesFormFields } from "../types";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { DataElementMatchcode } from "./DataElementMatchcode";

type RowProps = {
  form: UseFormReturn<TablesFormFields>;
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<TablesFormFields>["fields"][number];
  tables: NonNullable<GetTablesForCreateBusinessObjectQuery["tables"]>;
};

const Row = ({ form, index, remove, item, readOnly, tables }: RowProps) => {
  const { control, watch, trigger, setValue, getValues } = form;

  const selectedTableId = watch(
    `tables.${index}.tableId` as "tables.0.tableId"
  );

  const tablesOptions = tables.map((e) => ({
    id: e.id,
    name: e.id,
    description: e.translation.description,
  }));

  const selectedTable = tables.find((e) => e.id === selectedTableId);

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
      // style={{ width: "1" }} // FIXME:
      >
        <Controller
          control={control}
          name={`tables.${index}.tableId` as "tables.0.tableId"}
          rules={{
            validate: (table) => {
              if (readOnly) return true;
              if (table === "")
                return "Seleccione una tabla";
              ////Header table selected
              const tables = getValues("tables").map((e) => e.tableId);
              if (isRepeatedValue(table, tables ))
                return "Tabla duplicada" // FIXME: traduccion

              ////Header table selected
              const header = getValues("tables").map((e) => e.isHeader);
              if (!header.includes(true))
                return "Debe seleccionar una tabla cabecera" // FIXME: traduccion

              return true;
            },
          }}// FIXME: traduccion
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <DataElementMatchcode
              tablesOptions={tablesOptions}
              value={value}
              onChange={(value) => {
                // TODO:
                // esto es necesario porque watch(...) no detecta cambio si nuevo valor es undefined
                onChange(value ?? "");
              }}
              invalid={invalid}
              errorMessage={error?.message}
              readOnly={readOnly}
            />
          )}
          defaultValue={item.tableId}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`tables.${index}.isHeader` as "tables.0.isHeader"}
          render={({ field: { value, onChange } }) => (
            <Radio
              checked={value}
              onChange={(event) => {
                range(getValues("tables").length).forEach((idx) => {
                  setValue(
                    `tables.${idx}.isHeader` as "tables.0.isHeader",
                    false
                  );
                });
                onChange(event.target.checked);
              }}
            />
          )}
          defaultValue={item.isHeader}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {selectedTable?.translation?.description}
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
