import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";
import { useDialog } from "hooks/useDialog";
import { useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

type AlertUnsavedDataInputProps = {
  isBlocked: boolean;
  unblock: () => void;
};

const AlertUnsavedDataInput = ({
  isBlocked,
  unblock,
}: AlertUnsavedDataInputProps) => {
  const { isOpen, open, close } = useDialog();

  const [pathname, setPathname] = useState<string>();
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  const navigate = useNavigate();
  const { appMessage } = useAppMessages("MESSAGES");

  useBlocker((transition) => {
    setPathname(transition.location.pathname);
    open();
  }, isBlocked);

  const onUnblock = () => {
    unblock();
    setIsExitConfirmed(true);
  };

  useEffect(() => {
    if (pathname === undefined) return;
    if (!isExitConfirmed) return;
    navigate(pathname);
  }, [navigate, pathname, isExitConfirmed]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {appMessage["EXIT_PAGE"] ?? "Exit page?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {appMessage["UNSAVED_DATA"] ?? "There is unsaved data. Are you sure you want to leave?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {appMessage["KEEP_EDITING"] ?? "Keep editing"}
        </Button>
        <Button
          variant="contained"
          onClick={onUnblock}
          color="primary"
          autoFocus
        >
          {appMessage["EXIT"] ?? "Exit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertUnsavedDataInput;
