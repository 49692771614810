export enum CompanyRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":companyId/view",
  EDIT = ":companyId/edit",
}

export type CompanyRoutesState = {
  [CompanyRoutes.EDIT]: { activeStep?: number };
};
