import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  CheckCircle as CheckCircleIcon,
  Edit as EditCircleIcon,
} from "@mui/icons-material";
import React from "react";
import { HeaderProps } from "./types";
import { useDataElementDescription } from "hooks/useDataElementDescription";

const Header = ({
  readOnly = false,
  onEdit,
  onAppend,
  onFinish,
}: HeaderProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "LANGUAGE",
    "APP_SECTION_STEP",
    "APP_SECTION_TITLE",
    "APP_SECTION_SUBTITLE",
  ]);

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["LANGUAGE"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["APP_SECTION_STEP"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["APP_SECTION_TITLE"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["APP_SECTION_SUBTITLE"]}
        </StyledHeadTableCell>

        <TableCell align="right">
          {readOnly ? (
            <Tooltip
              title="Editar" // FIXME: traduccion
            >
              <IconButton aria-label="edit" size="small" onClick={onEdit}>
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip
                title="Guardar" // FIXME: traduccion
              >
                <IconButton aria-label="edit" size="small" onClick={onFinish}>
                  <StyledCheckCircleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Agregar fila" // FIXME: traduccion
              >
                <IconButton aria-label="add" size="small" onClick={onAppend}>
                  <StyledAddCircleIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
