import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  CountryExistsDocument,
  CountryExistsQuery,
  CountryExistsQueryVariables,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";

const BasicDataForm = ({
  form: { control },
  mode,
  onEdit,
}: BasicDataFormProps) => {
  const isIdReadOnly = mode !== "create";
  const readOnly = mode === "view";

  const { dataElementsDescriptions } = useDataElementDescription([
    "COUNTRIES",
    "DESCRIPTION",
  ]);

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool control={control} placement="top-left" />

      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              label={dataElementsDescriptions["COUNTRIES"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre del pais.", // FIXME: traduccion
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { countryExists } = await countryExistsFn({
                      countryId: value,
                    });
                    return countryExists ? "El nombre ya existe." : true; // FIXME: traduccion
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
              inputProps={{ disabled: isIdReadOnly }}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const countryExistsFn = pDebounce(
  (args: CountryExistsQueryVariables) =>
    fetcher<CountryExistsQuery, CountryExistsQueryVariables>(
      CountryExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
