import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { BasicDataFormFields } from "./BasicDataForm/types";
import { emptyTableRow } from "./TablesForm/template";
import { TablesFormFields } from "./TablesForm/types";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: Partial<FormFields>
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<BasicDataFormFields>({
    defaultValues: initialValue?.basicData,
  });

  const tables = useForm<TablesFormFields>({
    defaultValues: initialValue?.tables ?? { tables: [emptyTableRow] },
  });

  return {
    basicData,
    tables,
  };
};
