import { Box, Typography } from "@mui/material";
import { SelectController } from "components/inputs/SelectController";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useLanguagesOptions } from "hooks/useLanguagesOptions";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useAppSelector } from "hooks/reduxHooks";
import { getMyInformation } from "components/providers/ReduxMyProvider/reducer";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

export type LanguageAndRegionFormFields = {
  language: string;
  dateFormat: string;
  timeFormat: string;
  decimalSeparator: string;
  numberDecimalsPlaces: number;
};

export type LanguageAndRegionFormProps = {
  form: UseFormReturn<LanguageAndRegionFormFields>;
};

const LanguageAndRegionForm = ({ form }: LanguageAndRegionFormProps) => {
  
  const { me } = useAppSelector(getMyInformation);

  const { dataElementsDescriptions } = useDataElementDescription([
    "LANGUAGE",
    "USER_TIME_FORMAT",
    "USER_DATE_FORMAT",
    "USER_DECIMAL_SEPARATOR",
    "USER_DECIMAL_PLACES"
  ]);
  
  const { appMessage } = useAppMessages("USERS");
  const { appMessage:main } = useAppMessages("MAIN");

  const languages = useLanguagesOptions();
  const { dataElementsChoices } = useDataElementChoices([
    "USER_TIME_FORMAT",
    "USER_DATE_FORMAT",
    "USER_DECIMAL_SEPARATOR",
    "USER_DECIMAL_PLACES",
  ]);

  if (dataElementsChoices == null) return <BeatLoadingPage />;
  if (dataElementsDescriptions == null) return <BeatLoadingPage />;
  if (me == null) return <BeatLoadingPage />;

  return (
    <Box width="100%">
      <Typography variant="h6">
        {main["LANG_REG"]}
      </Typography>

      <Box maxWidth={"300px"}>
        <Box marginTop={2}>
          <SelectController
            label={dataElementsDescriptions["LANGUAGE"]}
            controllerProps={{
              name: "language",
              control: form.control,
              defaultValue: me?.language.id,
              rules: {
                required: appMessage["ENTER_LANGUAGE"] ?? "Please select the user language", ///FIXPRD QUITAR
                },
            }}
            options={languages.languagesOptions}
          />
        </Box>

        <Box marginTop={2}>
          <SelectController
              label={dataElementsDescriptions["USER_DATE_FORMAT"]}
            controllerProps={{
              name: "dateFormat",
              control: form.control,
              defaultValue: me?.dateFormat,
              rules: {
                required: appMessage["ENTER_DATE_FTM"] ?? "Please select the user date format", ///FIXPRD QUITAR
              },
            }}
            options={dataElementsChoices["USER_DATE_FORMAT"]}
          />
        </Box>

        <Box marginTop={2}>
          <SelectController
            label={dataElementsDescriptions["USER_TIME_FORMAT"]}
            controllerProps={{
              name: "timeFormat",
              control: form.control,
              defaultValue: me?.timeFormat,
              rules: {
                required: appMessage["ENTER_TIME_FTM"] ?? "Please select the user time format", ///FIXPRD QUITAR
              },
            }}
            options={dataElementsChoices["USER_TIME_FORMAT"]}
          />
        </Box>

        <Box marginTop={2}>
          <SelectController
          label={dataElementsDescriptions["USER_DECIMAL_SEPARATOR"]}
              controllerProps={{
              name: "decimalSeparator",
              control: form.control,
              defaultValue: me?.decimalSeparator,
              rules: {
                required: appMessage["ENTER_DEC_SEP"] ?? "Please select the decimal separator type", ///FIXPRD QUITAR
              },
            }}
            options={dataElementsChoices["USER_DECIMAL_SEPARATOR"]}
          />
        </Box>
        <Box marginTop={2}>
        <SelectController
               label={dataElementsDescriptions["USER_DECIMAL_PLACES"]}
               controllerProps={{
                name: "numberDecimalsPlaces",
                control: form.control,
                defaultValue: me?.numberDecimalsPlaces,
                rules: {
                  required: appMessage["ENTER_DEC_NUM"] ?? "Please select the decimal places", ///FIXPRD QUITAR
                },
              }}
              options={dataElementsChoices["USER_DECIMAL_PLACES"]}
            />
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageAndRegionForm;
