import { combineReducers } from "@reduxjs/toolkit";
import columnsSlice from "./ViewAllPage/reducers/columnsSlice";
import filtersAndPaginationSlice from "./ViewAllPage/reducers/filtersAndPaginationSlice";
import rowsSelectedSlice from "./ViewAllPage/reducers/checkedRowsSlice";

const BusinessPartnerTypeReducer = combineReducers({
  columns: columnsSlice,
  filtersAndPagination: filtersAndPaginationSlice,
  rowsChecked: rowsSelectedSlice,
});

export default BusinessPartnerTypeReducer;
