import {
  AddCircle as AddCircleIcon,
  Edit as EditCircleIcon
} from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useDataElementDescription } from "hooks/useDataElementDescription";

type HeaderProps = {
  appendRow: () => void;
  onEdit?: () => void;
  readOnly?: boolean;
};

const Header = ({ 
  appendRow, 
  onEdit, 
  readOnly, 
}: HeaderProps) => {

  const { appMessage } = useAppMessages("MESSAGES");
  const { dataElementsDescriptions } = useDataElementDescription([
    "APPS",
    "DESCRIPTION",
  ]);

  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["APPS"]?? "APPS"} 
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["DESCRIPTION"] ?? "DESCRIPTION"} 
        </StyledHeadTableCell>

        <TableCell align="right">
          { readOnly ? (
              <Tooltip title={appMessage["EDIT"]} >
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => onEdit?.()}>
                  <StyledEditCircleIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={appMessage["ADD_ROW"]} >
                <IconButton aria-label="add" size="small" onClick={appendRow}>
                  <StyledAddCircleIcon />
                </IconButton>
              </Tooltip>
            )
          }
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
  whiteSpace: "nowrap",
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
