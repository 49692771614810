import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateBackendMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";

const CreatePage = () => {
  const { mutateAsync: create } = useCreateBackendMutation();
  const navigate = useNavigate();

  const onSave = (form: FormFields) => create({ backend: mapFormToDto(form) });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title="Crear backend" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="create"
          errorMessage="Error al crear el backend. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
