import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateGroupMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { useUploadGroupIconToS3 } from "./useUploadTileIconToS3";
import { mapFormToDto } from "./utils";

const CreatePage = () => {
  const navigate = useNavigate();

  const { mutateAsync: createGroup } = useCreateGroupMutation();
  const { mutateAsync: uploadTileIconToS3 } = useUploadGroupIconToS3();

  const onSave = async (form: FormFields) => {
    const { fileId } = await uploadTileIconToS3(form.icon.icon);
    const group = mapFormToDto(form, fileId);
    await createGroup({ group });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title="Crear grupo de mosaicos" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          errorMessage="Error al crear grupo de mosaicos. Vuelva a intentar." // FIXME: traduccion
          mode="create"
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
