import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useTutorialsLabels = () => {

    const id = "TUTORIAL_ID";
    const name = "TUTORIAL_NAME";
    
    const dataElementsIds = [
        id, name
    ];
    
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels : NonNullable<Record<ColumnsKeys, string>> = {
        id: dataElementsDescriptions === undefined ? id : (dataElementsDescriptions[id] ?? id),  
        name: dataElementsDescriptions === undefined ? name : (dataElementsDescriptions[name] ?? name),
    }

    return { columnsLabels }
}