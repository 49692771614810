export enum EmployeeRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":employeeId/view",
  EDIT = ":employeeId/edit",
}

export type EmployeeRoutesState = {
  [EmployeeRoutes.EDIT]: { activeStep?: number };
};
