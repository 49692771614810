import { styled, TableCell as MuiTableCell, TableRow } from "@mui/material";
import React from "react";
import { ColDef } from "../types";

type TableHeaderRowProps<TColKeys extends string> = {
  columns: ColDef<TColKeys>[];
};

const TableHeaderRow = <TColKeys extends string>({
  columns,
}: TableHeaderRowProps<TColKeys>) => {
  return (
    <TableRow>
      <TableCell />

      {columns.map(({ key, text }) => (
        <TableCell key={key}>{text}</TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeaderRow;

const TableCell = styled(MuiTableCell)({
  whiteSpace: "nowrap",
});
