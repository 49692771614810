import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { emptyAppId } from "./AppsForm/template";
import { instructionEmptyRow } from "./InstructionsForm/InstructionsDataForm/template";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {

  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const name = useForm<FormFields["name"]>({
    defaultValues: initialValue?.["name"] ?? {
      descriptions: [emptyDescriptionRow],
    },
  });

  const apps = useForm<FormFields["apps"]>({
    defaultValues: initialValue?.["apps"] ?? {
      apps: [emptyAppId],
    },
  });

  const instructions = useForm<FormFields["instructions"]>({
    defaultValues: initialValue?.["instructions"] ?? {
      instructions: [instructionEmptyRow],
    },
  });

  return { basicData, name, apps, instructions };
};
