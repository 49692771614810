import { DevTool } from "@hookform/devtools";
import React from "react";
import { AddressDescriptionsForm } from "./AddressDescriptionsForm";
import { AddressNamesForm } from "./AddressNamesForm";
import { AddressFormProps } from "./types";

const AddressForm = ({
  areDescriptionsVisible,
  form,
  externalIndex,
  readOnly,
  onEdit,
  onEnterDescriptions,
  onExitDescriptions,
}: AddressFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <AddressDescriptionsForm
          onFinish={onExitDescriptions}
          formMethods={form}
          externalIndex={externalIndex}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      ) : (
        <AddressNamesForm
          form={form}
          onEnterDescriptions={onEnterDescriptions}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default AddressForm;
