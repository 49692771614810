import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { VisuallyHidden } from "components/other/VisuallyHidden";
import { useSnackbar } from "hooks/useSnackbarV2";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  UpdatableUserProfileInput,
  UserDateFormat,
  UserDecimalSeparator,
  UserTimeFormat,
  useUpdateMyProfileMutation,
} from "__generated__/graphql/types";
import { ImageForm } from "./ImageForm";
import { LanguageAndRegionForm } from "./LanguageAndRegionForm";
import { LanguageAndRegionFormFields } from "./LanguageAndRegionForm/template";
import { PasswordForm } from "./PasswordForm";
import { PersonalInformationForm } from "./PersonalInformationForm";
import { PersonalInformationFormFields } from "./PersonalInformationForm/template";
import { SideNavigation } from "./SideNavigation";
import { SiteNavigationOptions } from "./SideNavigation/template";
import { ThemeForm } from "./ThemeForm";
import { ThemeFormFields } from "./ThemeForm/template";
import { mapEmptyStringToUndefined } from "utils/mapEmptyStringToUndefined";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useGetMyInformationQuery } from '../../../__generated__/graphql/types';
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { setMyInformation } from 'components/providers/ReduxMyProvider/reducer';
import { useAppDispatch } from "hooks/reduxHooks";
import { notNullUndefined } from '../../../utils/notNullUndefined';

export type UserPreferencesDialogProps = {
  isOpen: boolean;
  close: () => void;
};

const isSaveButtonVisible: Record<SiteNavigationOptions, boolean> = {
  PERSONAL_INFORMATION: true,
  LANGUAGE_REGION: true,
  THEME: true,
  IMAGE: false,
  PASSWORD: false,
};

const UserPreferencesDialog = ({
  isOpen,
  close,
}: UserPreferencesDialogProps) => {

  const personalInformationForm = useForm<PersonalInformationFormFields>();
  const languageAndRegionForm = useForm<LanguageAndRegionFormFields>();
  const themeForm = useForm<ThemeFormFields>();
  const { appMessage } = useAppMessages("MAIN");

  const errorSnackbar = useSnackbar();
  const { mutateAsync: updateMyProfile, status } = useUpdateMyProfileMutation();

  const dispatch = useAppDispatch();
  const { language } = useSystemLanguage();
  
  const { 
    data: { me } = { refetchOnWindowsFocus: false }, 
    refetch: getMyInformation 
  } = useGetMyInformationQuery(
    { language },
    { enabled: status === 'success' }
  );

  useEffect(() => {
    if(notNullUndefined(me)) {
        dispatch(setMyInformation(me));  
    }
  // FIXME: Actualizar información en el hook de myInformation
  // eslint-disable-next-line 
  }, [me]);

  const [selectedNavTab, setSelectedNavTab] = useState<SiteNavigationOptions>(
    "PERSONAL_INFORMATION"
  );

  const errors = Object.entries({
    PERSONAL_INFORMATION: personalInformationForm.formState.isValid,
    LANGUAGE_REGION: languageAndRegionForm.formState.isValid,
    THEME: themeForm.formState.isValid,
  })
    .filter(([, isValid]) => !isValid)
    .map(([key]) => key as SiteNavigationOptions); // FIXME:

  const onSave = async () => {
    try {
      const isPersonalInformationFormValid = await personalInformationForm.trigger();
      const isLanguageAndRegionFormValid = await languageAndRegionForm.trigger();
      const isThemeFormValid = await themeForm.trigger();

      if (
        !isPersonalInformationFormValid ||
        !isLanguageAndRegionFormValid ||
        !isThemeFormValid
      )
        return;

      const personalInformation = personalInformationForm.getValues();
      const languageAndRegion = languageAndRegionForm.getValues();
      const theme = themeForm.getValues();

      await updateMyProfile({
        userProfile: mapFormToDto({
          personalInformation,
          languageAndRegion,
          theme,
        }),
      });

      await getMyInformation();
      dispatch(setMyInformation(me));
      close();
    } catch {
      errorSnackbar.open(appMessage["SAVE_ERROR"]); // FIXME: traduccion
    }
  };

  return (
    <>
      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
        <DialogTitle>
              {appMessage["USER_OPTIONS"]}
        </DialogTitle>

        <DialogContent style={{ display: "flex" }}>
          <Box width="300px" marginRight={4}>
            <SideNavigation
              errors={errors}
              selected={selectedNavTab}
              onChangeSelected={setSelectedNavTab}
            />
          </Box>

          <Box flexGrow={1}>
            <VisuallyHidden
              isVisible={selectedNavTab === "PERSONAL_INFORMATION"}
            >
              <PersonalInformationForm form={personalInformationForm} />
            </VisuallyHidden>

            <VisuallyHidden isVisible={selectedNavTab === "LANGUAGE_REGION"}>
              <LanguageAndRegionForm form={languageAndRegionForm} />
            </VisuallyHidden>

            <VisuallyHidden isVisible={selectedNavTab === "THEME"}>
              <ThemeForm form={themeForm} />
            </VisuallyHidden>

            {selectedNavTab === "IMAGE" && <ImageForm />}

            {selectedNavTab === "PASSWORD" && <PasswordForm />}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={close} color="primary">
              {appMessage["CLOSE"]}
          </Button>

          {isSaveButtonVisible[selectedNavTab] && (
            <Button
              onClick={onSave}
              variant="contained"
              color="primary"
              autoFocus
            >
              {appMessage["SAVE"]}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserPreferencesDialog;

const mapFormToDto = ({
  personalInformation,
  languageAndRegion,
  theme,
}: {
  personalInformation: PersonalInformationFormFields;
  languageAndRegion: LanguageAndRegionFormFields;
  theme: ThemeFormFields;
}): UpdatableUserProfileInput => {
  return {
    // personalInformation
    email: personalInformation.email,
    firstName: personalInformation.firstName,
    lastName: personalInformation.lastName,
    // languageAndRegion
    dateFormat: languageAndRegion.dateFormat as UserDateFormat,
    timeFormat: languageAndRegion.timeFormat as UserTimeFormat,
    decimalSeparator: languageAndRegion.decimalSeparator as UserDecimalSeparator,
    language: languageAndRegion.language,
    numberDecimalsPlaces: languageAndRegion.numberDecimalsPlaces,
    // theme
    theme: mapEmptyStringToUndefined(theme.themeId), // FIXME: este mapeo deberia hacerse adentro del formulario de themeId
  };
};
