import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { Form } from "apps/PositionsPage/CreatePage/Form";
import { FormFields } from "apps/PositionsPage/CreatePage/Form/types";
import { mapFormToDto } from "apps/PositionsPage/CreatePage/utils";
import { mapDtoToForm } from "apps/PositionsPage/ViewPage/utils";
import { useNavigate } from "react-router";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetPosition_ViewPageQuery,
  useUpdatePositionMutation,
} from "__generated__/graphql/types";
import { PositionsRoutes, PositionsRoutesState } from "../routes";

type Route = PositionsRoutes.EDIT;
type PositionTypeState = PositionsRoutesState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { positionId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<PositionTypeState>() ?? {};

  const {
    data: { position } = {},
    isFetchedAfterMount,
  } = useGetPosition_ViewPageQuery({
    id: positionId,
  });

  const initialValue =
    position != null && isFetchedAfterMount
      ? mapDtoToForm(position)
      : undefined;

  const { mutateAsync: replace } = useUpdatePositionMutation();

  const onSave = (form: FormFields) =>
    replace({
      id: positionId,
      position: mapFormToDto(form),
    });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header title="Editar Posición" toBack={ TO_BACK_ROUTER } />

      <Form
        mode="edit"
        onSave={ onSave }
        onCancel={ onCancel }
        errorMessage="Error al editar la posición. Vuelva a intentar."
        initialValue={ initialValue }
        initialStep={ activeStep }
      />
    </Layout>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
