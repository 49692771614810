export enum AppsRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":appId/view",
  EDIT = ":appId/edit",
}

export type AppsRoutesState = {
  [AppsRoutes.EDIT]: { activeStep?: number };
};
