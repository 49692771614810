import { GetBusinessGroup_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetBusinessGroup_ViewPageQuery["businessGroup"]>
): FormFields => {
  return {
    basicData: {
      businessGroup: dto.id,
      name: dto.name,
      groupCurrency: dto.currency.id,
      chartOfAccount: dto.chartOfAccount.id,
    },
  };
};
