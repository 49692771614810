export enum ThemeRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":themeId/view",
  EDIT = ":themeId/edit",
}

export type ThemeRoutesState = {
  [ThemeRoutes.EDIT]: { activeStep?: number };
};
