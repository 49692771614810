import React from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import {  useGetRevenueCollector_ViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { RevenueCollectorRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {
  const navigate = useNavigate();

  const { revenueCollectorId } = useRouteParams<RevenueCollectorRoutes.VIEW>();

  const {
    data: { revenueCollector } = {},
    isFetchedAfterMount,
  } = useGetRevenueCollector_ViewPageQuery({
    id: revenueCollectorId,
  });

  const initialValue =
    revenueCollector != null && isFetchedAfterMount
      ? mapDtoToForm(revenueCollector)
      : undefined;

  const onEdit = (activeStep: number) => {
    navigate(
      `../../${generatePath(RevenueCollectorRoutes.EDIT, {
        revenueCollectorId,
      })}`,
      { state: { activeStep } }
    );
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header title="Visualizar recaudador de ingresos" toBack="../../" />

        <Form mode="view" initialValue={initialValue} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;
