import { Box, styled } from "@mui/material";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { Collapse } from "./components/Collapse";
import { Table } from "./components/Table";
import { DetailTitle } from "./components/Table/DetailTitle";
import { useViewAllPage } from "./useViewAllPage";

export type DetailsValues = { 
  name: string, 
  value: string | undefined 
}

const ViewPurchaseOrdersItemsPage = () => {
  
  const {
    errorSnackbar,
    purchaseOrder,
    menuItems,
    rows,
    total,
    pagination,
    sheet,
    state,
    detailsLabels,
    mapPurchaseOrderDetail
  } = useViewAllPage();

  // FIXME: Agregar validacion si el state es null
  const purchaseOrderId = state!.purchaseOrderId;

  return (
    <>
      <Layout>
        <SnackbarAlert
          open={errorSnackbar.isOpen}
          onClose={errorSnackbar.close}
          severity="warning"
        >
          {errorSnackbar.message}
        </SnackbarAlert>

        {/* Instructor */}
        <MenuInstructions tutorialsIds={["PO_CREATE_INVOICE"]} />

        <BoxWrapper>
          <Header
            title={detailsLabels["id"] + ": "+purchaseOrderId}
            toBack={TO_BACK_ROUTER}
          />

          <DetailTitle
            subtitles={mapPurchaseOrderDetail(detailsLabels, purchaseOrder)}
          />
  
          <Box marginY={2}>
            <Collapse 
              isCreatedVisible={false}
              menuItems={menuItems}
              sheet={sheet}
            />
          </Box>

          <Table 
            menuItems={menuItems}
            rows={rows}
            total={total}
            pagination={pagination}
          />
        </BoxWrapper>
      </Layout>
    </>
  );
};

export default ViewPurchaseOrdersItemsPage;

const TO_BACK_ROUTER = "../";

const BoxWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  overflow: "hidden"
});

