import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateCountryIdsTypesMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";

const CreatePage = () => {
  const { mutateAsync: create } = useCreateCountryIdsTypesMutation();
  const navigate = useNavigate();

  const onSave = (form: FormFields) =>
    create({ countryIdsTypes: mapFormToDto(form) });

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header title="Crear documentos de pais" toBack={TO_BACK_ROUTER} />

        <Form
          mode="create"
          errorMessage="Error al crear documentos de pais. Vuelva a intentar."
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
