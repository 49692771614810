import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SelectController } from "components/inputs/SelectController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import {
  UserExistsQueryVariables,
  UserExistsQuery,
  UserExistsDocument,
  UserType,
  useUserTypesQuery,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";
import useAppMessages from 'hooks/useAppMessages/hook';
import BeatLoadingPage from '../../../../../components/layout/BeatLoadingPage/template';

const BasicDataForm = ({
  form: { control },
  mode,
  onEdit,
}: BasicDataFormProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "USER_ID",
    "USER_FIRSTNAME",
    "USER_LASTNAME",
    "USER_EMAIL",
    "USER_TYPE",
    "USER_PHONE_NUMBER",
  ]);

  const { dataElementsChoices } = useDataElementChoices(["USER_TYPE"]);
  const { data: { userTypes } = {} } = useUserTypesQuery();
  
  const isIdReadOnly = mode !== "create";
  const readOnly = mode === "view";
  const { appMessage } = useAppMessages("USERS");
  
  if (dataElementsChoices === undefined) return <BeatLoadingPage/>; 
  if (dataElementsDescriptions == null) return <BeatLoadingPage/>;
  
  const choices = dataElementsChoices["USER_TYPE"].filter( e => userTypes?.includes(e.value as UserType))
  
  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <TextFieldController
              label={dataElementsDescriptions["USER_ID"]}
              controllerProps={{
                name: "userName",
                control,
                rules: {
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { userExists } = await userExistsFn({
                      userId: value,
                    });
                    return userExists ? appMessage["NAME_EXIST"] : true;
                  },
                  required:appMessage["ENTER_NAME"]??"Please enter a name", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: isIdReadOnly }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldController
              label={dataElementsDescriptions["USER_FIRSTNAME"]}
              controllerProps={{
                name: "firstName",
                control,
                rules: {
                  required: appMessage["ENTER_NAME"]??"Please enter a name", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldController
              label={dataElementsDescriptions["USER_LASTNAME"]}
              controllerProps={{
                name: "lastName",
                control,
                rules: {
                  required: appMessage["ENTER_LAST_NAME"] ?? "Please enter the last name", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldController
              label={dataElementsDescriptions["USER_EMAIL"]}
              controllerProps={{
                name: "email",
                control,
                rules: {
                  required: appMessage["ENTER_MAIL"]??"Please enter the mail", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldController
              label={dataElementsDescriptions["USER_PHONE_NUMBER"]}
              controllerProps={{
                name: "phoneNumber",
                control,
                rules: {
                  required: appMessage["ENTER_TLFNO"] ??"Please enter the phone number", ///FIXPRD QUITAR
                },
              }}
              inputProps={{ disabled: readOnly }}
            />
          </Grid>

          <Grid item xs={3}>
            <SelectController
              inputProps={{ disabled: readOnly }}
              label={dataElementsDescriptions["USER_TYPE"]}
              select
              controllerProps={{
                name: "userType",
                control,
                rules: { required:  appMessage["ENTER_USTYPE"] ?? "Please enter the user type", },  ///FIXPRD QUITAR
              }}
              options={choices}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BasicDataForm;

const userExistsFn = pDebounce(
  (args: UserExistsQueryVariables) =>
    fetcher<UserExistsQuery, UserExistsQueryVariables>(
      UserExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
