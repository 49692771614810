import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useFieldArray } from "react-hook-form";
import { Backends, BackendsFormFields } from "../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { BackendsNamesFormProps } from "./types";

export const backendsEmptyRow: Backends = {
  idBackend: "",
  idBackendExt: "",
};

const BackendsNamesForm = ({
  mode,
  onEdit,
  form,
}: BackendsNamesFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray<BackendsFormFields>({
    control,
    name: "backends",
  });

  const appendRow = () => {
    append(backendsEmptyRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header
          onAppend={() => appendRow()}
          onEdit={() => onEdit?.()}
          mode={mode || ''}
        />

        <StyledTableBody>
          {fields.map((item, index) => (
            <Row
              key={item.id}
              index={index}
              remove={onRemove(index)}
              item={item}
              mode={mode || ''}
              form={form}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default BackendsNamesForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
