import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon, Search as SearchIcon
} from "@mui/icons-material";
import { useDeleteTutorialMutation, useGetTutorialsViewAllPageQuery } from "__generated__/graphql/types";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAllowedUserAppActions } from "hooks/useAlloweduserAppActions";
import { useAppMessages } from "hooks/useAppMessages";
import { useSnackbar } from "hooks/useSnackbarV2";
import { UseSnackbarReturn } from "hooks/useSnackbarV2/hook";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { notNullUndefined } from "utils/notNullUndefined";
import { TutorialsRoutes } from "../routes";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { resetPage, selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useViewAllPage = () => {
    
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const { appMessage: appTutorials } = useAppMessages("TUTORIALS");
  const { appMessage: appMessages } = useAppMessages("MESSAGES");
  const { actions } = useAllowedUserAppActions("TUTORIALS");
  const { language } = useSystemLanguage();

  const errorSnackbar = useSnackbar();
  const filters = useAppSelector(selectFilters);
  const pagination = useAppSelector(selectPagination);
  const rowsChecked = useAppSelector(selectRowsChecked);

  const {
    data: { tutorials, tutorialsCount: total } = {},
    refetch: findTutorials,
  } = useGetTutorialsViewAllPageQuery({
    language,
    pagination,
    filters: {
      id: filters.id,
      description: filters.name
    },
  });

  const rows = useMemo(() => tutorials?.map( dto => {
    const res: TableRowType<ColumnsKeys> = {
      rowKey: dto.id,
      id: dto.id,
      name: dto.translation.description,
    }
    return res;
  }), [tutorials]);

  const {
    data: { tutorials: exportToExcel  } = {},
  } = useGetTutorialsViewAllPageQuery({
    language,
    filters: {
      id: filters.id,
      description: filters.name
    },
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              id: dto.id,
              name: dto.translation.description,
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel]
  );

  const { mutateAsync: deleteTutorial } = useDeleteTutorialMutation();

  const readAction = actions.find(e => e.id === "READ");
  const updateAction = actions.find(e => e.id === "UPDATE");
  const deleteAction = actions.find(e => e.id === "DELETE");

  const menuItems = useMemo(() => [
    readAction && {
      key: "display",
      Icon: SearchIcon,
      text: readAction.translation.description,
      onClick: (id: string) => {
        if(id === "display") {
          if(rowsChecked.length === 0) {
            errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
            return;
          } else if(rowsChecked.length > 1) {
            errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
            return;
          }
          id = rowsChecked.toString()
        }

        navigate(generatePath(TutorialsRoutes.PATH_VIEW, {
          tutorialId: id
        }))
      },
    },
    updateAction && {  
      key: "edit",
      Icon: EditIcon,
      text: updateAction.translation.description,
      onClick: (id: string) => {
        if (id === "update") {
          if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
          id = rowsChecked.toString()
        }
        navigate(generatePath(TutorialsRoutes.PATH_EDIT, { 
          tutorialId: id 
        }));
      },
    },
    deleteAction && {
      key: "delete",
      Icon: DeleteForeverIcon,
      text: deleteAction.translation.description,
      onClick: (id: string) => {
        if (id === "delete") {
          if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
          id = rowsChecked.toString()
        }
        deleteTutorial({ tutorialId: id })
          .then(() => dispatch(resetPage()))
          .then(() => findTutorials())
          .catch(() => errorSnackbar.open(appTutorials["ERROR_DELETE"]))
      }
    },
  ].filter(notNullUndefined), 
  [
    dispatch, navigate, deleteTutorial, findTutorials,
    appMessages, errorSnackbar, readAction, rowsChecked, appTutorials, deleteAction, updateAction
  ]);

  return {
    errorSnackbar,
    appTutorials,
    menuItems,
    rows,
    total,
    pagination,
    sheet
  }
};

const validateSelectRows: any = (
  rowsChecked: string[], 
  appMessages: any, 
  errorSnackbar: UseSnackbarReturn
) => {

  if (rowsChecked.length === 0) {
    errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
    return true;
  } else if (rowsChecked.length > 1) {
    errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
    return true;
  }
  return false;
}