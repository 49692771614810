import { dateFormat, numberFormat } from 'utils/GenericFormats';
import { Maybe, GetMyInformationQuery } from '../../__generated__/graphql/types';
import { notImplemented } from "utils/assert";
import { amountFormat } from '../../utils/GenericFormats';
import { useAppSelector } from 'hooks/reduxHooks';
import { getMyInformation } from 'components/providers/ReduxMyProvider/reducer';

export type UserFormatConfig = {
  numberMapper:(value:Maybe<string> | number | undefined) => string;
  amountMapper:(value:Maybe<string> | number | undefined) => string;
  amountDecimalsMapper:(value:Maybe<string> | number | undefined, numberDecimals: number) => string;
  dateMapper:(value:Maybe<string> | undefined) => string;
  completeDateMapper:(value:Maybe<string> | undefined) => string;
  formats: {
    date: string;
    dateMask: string;
    time: string;
    numDecimals: number;
    locationDecimals: string;
    decimalSeparator: string;
    thousandSeparator: string;
  }
};

const mapDate = (data?:GetMyInformationQuery["me"]["dateFormat"]) => {
  if(!data) {return "yyyy-MM-dd"}
  switch ( data ) {
    case 'DD_MM_YYYY':
      return"dd-MM-yyyy"
    case 'MM_DD_YYYY': 
      return "MM-dd-yyyy"
    case 'YYYY_MM_DD':
      return "yyyy-MM-dd";   
  }
  notImplemented(data);
}

const mapDateMask = (data?:GetMyInformationQuery["me"]["dateFormat"]) => {
  if(!data) {return "____-__-__"}
  switch ( data ) {
    case 'DD_MM_YYYY':
      return "__-__-____"
    case 'MM_DD_YYYY': 
      return "__-__-____"
    case 'YYYY_MM_DD':
      return "____-__-__";   
  }
  notImplemented(data);
}

const mapTime = (data?:GetMyInformationQuery["me"]["timeFormat"]) => {
  let time = "HH:mm:ss";
  if(data === 'HS_12') {
    time = "hh:mm:ss"
  }
  return time;
}

const mapLocationDecimals = (data?:GetMyInformationQuery["me"]["decimalSeparator"]) => {
  let time = "en-US";
  if(data === 'COMMA') {
    time = "it-IT"
  }
  return time;
}

const mapDecimalSeparator = (data?:GetMyInformationQuery["me"]["decimalSeparator"]) => {
  let separator = ".";
  if(data === 'COMMA') {
    separator = ","
  }
  return separator;
}

const mapThousandSeparator = (data?:GetMyInformationQuery["me"]["decimalSeparator"]) => {
  let separator = ",";
  if(data === 'COMMA') {
    separator = "."
  }
  return separator;
}

const useLanguageAndRegionConfig = () => {

  const { me } = useAppSelector(getMyInformation);
  const numDecimals = me?.numberDecimalsPlaces ?? 3;
  const date = mapDate(me?.dateFormat);
  const mask = mapDateMask(me?.dateFormat);
  const time = mapTime(me?.timeFormat);
  const locationDecimals = mapLocationDecimals(me?.decimalSeparator);
  const decimalSeparator = mapDecimalSeparator(me?.decimalSeparator);
  const thousandSeparator = mapThousandSeparator(me?.decimalSeparator);

  const numberMapper = (value: Maybe<string> | number | undefined) => {
    return numberFormat(value, locationDecimals);
  }

  const amountMapper = (value: Maybe<string> | number | undefined) => {
    return amountFormat(value, locationDecimals, numDecimals);
  }

  const amountDecimalsMapper = (value: Maybe<string> | number | undefined, numberDecimals: number) => {
    return amountFormat(value, locationDecimals, numberDecimals);
  }

  const dateMapper = (value: Maybe<string> | undefined) => {
    return dateFormat(value, date);
  }

  const completeDateMapper = (value: Maybe<string> | undefined) => {
    return dateFormat(value, date+", "+time);
  }

  const formatConfig: UserFormatConfig = {
    dateMapper: dateMapper,
    completeDateMapper: completeDateMapper,
    amountMapper: amountMapper,
    amountDecimalsMapper: amountDecimalsMapper,
    numberMapper: numberMapper,
    formats: {
      date: date,
      dateMask: mask,
      time: time,
      numDecimals: numDecimals,
      locationDecimals: locationDecimals,
      decimalSeparator: decimalSeparator,
      thousandSeparator: thousandSeparator
    }
  };

  return { formatConfig };
};

export default useLanguageAndRegionConfig;
