import { DEFAULT_LANGUAGE } from "consts/languages";
import { isRepeatedValue } from "./isRepeatedValue";

type Translation = { language: string } & Record<string, string>;

export const containsDefaultLanguage = <T extends Translation>(
  translations: T[]
) => translations.some((e) => e.language === DEFAULT_LANGUAGE);

export const containsTranslations = <T extends Translation>(
  translations: T[]
) => translations !== undefined && translations.length !== 0;

export const containsUniqueLanguages = <T extends Translation>(
  translations: T[]
) => {
  const languages = translations.map((e) => e.language);
  return languages.length === new Set(languages).size;
};

export const containsEmptyFields = <T extends Translation, U extends keyof T>(
  translation: T,
  keys: U[]
) => {
  keys.map((key) => translation[key] === "");
};

export const translationsFieldsAreNotEmpty = <
  T extends Translation,
  U extends keyof T
>(
  translation: T[],
  keys: U[]
) =>
  !translation.some((translations) => containsEmptyFields(translations, keys));

export const isRepeatedLanguage = <T extends Translation>(
  translations: T[],
  language: string
): boolean =>
  isRepeatedValue(
    language,
    translations.map(({ language }) => language)
  );
