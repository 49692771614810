import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetThemeByIdQuery } from "__generated__/graphql/types";
import { Form } from "../CreateThemePage/Form";
import { FormFields } from "../CreateThemePage/Form/types";
import { mapDtoToForm } from "../EditThemePage/utils";
import { ThemeRoutes } from "../routes";
import useAppMessages from 'hooks/useAppMessages/hook';

const ViewThemePage = () => {
  const [initialValues, setInitialValues] = useState<FormFields>();
  const { appMessage } = useAppMessages("THEMES");

  const navigate = useNavigate();

  const { themeId } = useRouteParams<ThemeRoutes.VIEW>();

  const { data: { theme } = {}, isFetchedAfterMount } = useGetThemeByIdQuery({
    name: themeId,
  });

  const onEdit = (activeStep: number) => {
    navigate(`../../${generatePath(ThemeRoutes.EDIT, { themeId })}`, {
      state: { activeStep },
    });
  };

  useEffect(() => {
    if (theme == null) return;
    if (!isFetchedAfterMount) return;
    mapDtoToForm({ theme }).then((initialValues) =>
      setInitialValues(initialValues)
    );
  }, [theme, setInitialValues, isFetchedAfterMount]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title={appMessage["VIEW_THEME"]}
        toBack={TO_BACK_ROUTER}
      />

      <Form initialValue={initialValues} mode="view" onEdit={onEdit} />
    </Layout>
  );
};

export default ViewThemePage;

const TO_BACK_ROUTER = "../../";
