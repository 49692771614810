import { TutorialInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): TutorialInput => {
  return {
    id: form.basicData.tutorialId,
    translations: form.name.descriptions.map(e => {
      return {
        language: e.language,
        description: e.text
      }
    }),
    apps: form.apps.apps.map(e => e.id),
    instructions: form.instructions.instructions.map(e => {
      return {
        stepNumber: e.stepNumber,
        stepName: e.stepName,
        target: e.target,
        style: e.style,
        clickEnabled: e.enabledClick,
        translations: e.descriptions.map(t => {
          return {
            language: t.language,
            description: t.description
          }
        })
      }
    })
  };
};
