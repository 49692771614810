import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";
import { HiddenColumns } from "./HiddenColumns";
import { VisibleColumns } from "./VisibleColumns";

type ColumnsDialogProps<T extends string> = {
  open: boolean;
  visibleColumns: { id: T; label: string }[];
  hiddenColumns: { id: T; label: string }[];
  handleClose: () => void;
  orderColumn: (ids: T[]) => void;
  showColumn: (id: T) => void;
  hideColumn: (id: T) => void;
};

const VisibleColumnsDialog = <T extends string>(
  {
    visibleColumns,
    hiddenColumns,
    open,
    handleClose,
    orderColumn,
    showColumn,
    hideColumn,
  }: ColumnsDialogProps<T> // FIXME:
) => {

  const { appMessage } = useAppMessages("MESSAGES");
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {appMessage["TITLE_COLUMNS_DIALOG"] ?? " Which columns do you want to display?"}
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item>
            <VisibleColumns
              columns={visibleColumns}
              onOrderColumns={orderColumn}
              onHideColumn={hideColumn}
            />
          </Grid>

          <Grid item>
            <HiddenColumns columns={hiddenColumns} onShowColumn={showColumn} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {appMessage["EXIT"] ?? "Exit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisibleColumnsDialog;
