import { DataElementType } from "apps/DataElementsPage/CreatePage/Form/BasicDataForm/template";
import { useMemo } from "react";
import { GetDataElementsForCreateTableQuery } from "__generated__/graphql/types";
import { CharacteristicColumn } from "../CharacteristicsColumnsForm/types";
import { NUMERIC_DATA_ELEMENT_TYPE } from "../utils";

export const useCharacteristicsColumnsOptions = (
  characteristicsColumns: CharacteristicColumn[],
  dataElements?: GetDataElementsForCreateTableQuery["dataElements"]
) => {
  const nonNumericDataElementsOptions = useMemo(() => {
    if (dataElements === undefined) return undefined;
    return new Map(
      dataElements
        .filter(
          (dataElement) =>
            !NUMERIC_DATA_ELEMENT_TYPE[dataElement.dataType as DataElementType]
        )
        .map((dataElementOption) => [dataElementOption.id, dataElementOption])
    );
  }, [dataElements]);

  const characteristics = useMemo(() => {
    if (nonNumericDataElementsOptions === undefined) return undefined;
    return characteristicsColumns
      .map((column) => [
        column.name,
        nonNumericDataElementsOptions.get(column.dataElementId),
      ])
      .filter(([, value]) => value !== undefined) as [
      columnName: string,
      option: NonNullable<
        NonNullable<GetDataElementsForCreateTableQuery["dataElements"]>[number]
      >
    ][];
  }, [characteristicsColumns, nonNumericDataElementsOptions]);

  const amountCharacteristicsOptions = useMemo(() => {
    if (characteristics === undefined) return undefined;
    return characteristics
      .filter(([, option]) => option.dataType === "CURRENCY")
      .map(([columnName]) => ({
        value: columnName,
        text: columnName.toUpperCase(),
      }));
  }, [characteristics]);

  const quantityCharacteristicsOptions = useMemo(() => {
    if (characteristics === undefined) return undefined;
    return characteristics
      .filter(([, option]) => option.dataType === "UNIT")
      .map(([columnName]) => ({
        value: columnName,
        text: columnName.toUpperCase(),
      }));
  }, [characteristics]);

  return { amountCharacteristicsOptions, quantityCharacteristicsOptions };
};

export const useRatiosDataElements = (
  dataElements?: GetDataElementsForCreateTableQuery["dataElements"]
) => {
  const ratiosDataElements = useMemo(() => {
    if (dataElements === undefined) return undefined;
    return new Map(
      dataElements!
        .filter(
          (dataElement) =>
            NUMERIC_DATA_ELEMENT_TYPE[dataElement.dataType as DataElementType] // FIXME:
        )
        .map((dataElementOption) => [dataElementOption.id, dataElementOption])
    );
  }, [dataElements]);
  return { ratiosDataElements };
};

export const useCharacteristicColumnsNames = (
  characteristicsColumns: CharacteristicColumn[]
) => {
  const characteristicsColumnsNames = useMemo(
    () => characteristicsColumns.map(({ name }) => name),
    [characteristicsColumns]
  );
  return { characteristicsColumnsNames };
};
