import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow, TextField as MuiTextField, Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useEffect, useState } from "react";
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn
} from "react-hook-form";
import NumberFormat from "react-number-format";
import { PicConstants } from "utils/constants";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { PositionDtoForCreateInvoice, PositionsColumnsFormFields } from "../types";
import { PositionMatchcode } from "./PositionMatchcode";

type RowProps = {
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<PositionsColumnsFormFields>["fields"][number];
  positionsByIdMap: Map<string, PositionDtoForCreateInvoice> | undefined;
  form: UseFormReturn<PositionsColumnsFormFields>;
  showColumns: boolean;
};

type amountsProps = {
  quantity: number,
  netUnitPrice: number,
  netTotalPrice: number,
  taxesAmount: number,
  extraChargesAmount: number,
  total: number
};

const Row = ({
  index,
  remove,
  item,
  readOnly,
  positionsByIdMap,
  form,
  showColumns
}: RowProps) => {

  const { control, watch, trigger, getValues, setValue } = form;

  const { formatConfig } = useLanguageAndRegionConfig();
  const { appMessage } = useAppMessages("MESSAGES");
  const { appMessage: appInvoicesMessage } = useAppMessages("INVOICES");

  const [position, setPosition] = useState<PositionDtoForCreateInvoice | undefined>(undefined);
  const [positions, setPositions] = useState<PositionDtoForCreateInvoice[]>([]);

  const [calculateValues, setCalculateValues] = useState({
    quantity: getValues(`positions.${index}.quantity` as "positions.0.quantity"),
    netUnitPrice: getValues(`positions.${index}.netUnitPrice` as "positions.0.netUnitPrice"),
    netTotalPrice: getValues(`positions.${index}.netTotalPrice` as "positions.0.netTotalPrice"),
    taxesAmount: getValues(`positions.${index}.taxesAmount` as "positions.0.taxesAmount"),
    extraChargesAmount: getValues(`positions.${index}.extraChargesAmount` as "positions.0.extraChargesAmount"),
    total: getValues(`positions.${index}.amountToBill` as "positions.0.amountToBill")
  });

  const valuesHandler = (e: any, name: string) => {
    const { value } = e;

    const newValues = {
      ...calculateValues,
      [name]: value
    }
    calculateAmounts(newValues)
  } 

  const calculateAmounts = (newValues: amountsProps) => {

    const { quantity, netUnitPrice, taxesAmount, extraChargesAmount } = newValues;
    const netTotalPrice = (quantity * netUnitPrice);
    const total =  Number(netTotalPrice) + Number(taxesAmount) + Number(extraChargesAmount);

    const amounts = {
      ...newValues,
      netTotalPrice: netTotalPrice,
      total: total
    }

    setCalculateValues(amounts)

    setValue(`positions.${index}.amountToBill` as "positions.0.amountToBill", total);
    setValue(`positions.${index}.taxesAmount` as "positions.0.taxesAmount", taxesAmount);
    setValue(`positions.${index}.extraChargesAmount` as "positions.0.extraChargesAmount", extraChargesAmount);
    setValue(`positions.${index}.netTotalPrice` as "positions.0.netTotalPrice", netTotalPrice);
    setValue(`positions.${index}.netUnitPrice` as "positions.0.netUnitPrice", netUnitPrice);
    setValue(`positions.${index}.quantity` as "positions.0.quantity", quantity);
  }

  useEffect(() => {

    const purchaseOrderItemIdForm = watch(
      `positions.${index}.purchaseOrderItemId` as "positions.0.purchaseOrderItemId"
    );

    setPosition(positionsByIdMap?.get(purchaseOrderItemIdForm));
    setPositions(
      Array.from(positionsByIdMap 
        ? positionsByIdMap.values() 
        : []
      )
    );
    
  }, [positionsByIdMap, watch, setValue, index]);
  
  useEffect(() => {
    if(position) {

      setValue(`positions.${index}.backend` as "positions.0.backend", position.backend)
      setValue(`positions.${index}.productId` as "positions.0.productId", position.productId)
      setValue(`positions.${index}.unit` as "positions.0.unit", position.unit)

      const netUnitPrice: number = watch(
        `positions.${index}.netUnitPrice` as "positions.0.netUnitPrice"
      );

      if(netUnitPrice === 0) {
        setCalculateValues({
          ...calculateValues,
          netUnitPrice: position.netUnitPrice,
        });
      }
    }
  }, [position, calculateValues, index, watch, setValue]);

  if (positionsByIdMap === undefined) return <BeatLoadingPage />;  

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={
            `positions.${index}.purchaseOrderItemId` as "positions.0.purchaseOrderItemId"
          }
          rules={{ 
            required: appMessage["CHOOSE_ONE"] ?? "Choose one",
            validate: (value: string) => {
              const itemsIds = getValues("positions").map((e) => e.purchaseOrderItemId);
                return isRepeatedValue(value, itemsIds)
                  ? appMessage["DATA_REPEATED"] ?? "Data repeated"
                  : true;
            },
          }} 
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => ( 
            <PositionMatchcode
              itemsOptions={positions}
              value={value}
              onChange={onChange}
              invalid={invalid}
              errorMessage={error?.message}
              readOnly={readOnly}
            /> 
          )}
          defaultValue={item.purchaseOrderItemId}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {position?.purchaseOrderItem}
      </TableCell>

      <TableCell component="th" scope="row">
        {position?.productDescription}
      </TableCell>

      <TableCell component="th" scope="row">
        {position?.unit}
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.numberMapper(position?.poQuantity)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.amountDecimalsMapper(position?.netUnitPrice, PicConstants.NET_UNT_PRICE_DECIMALS)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.amountMapper(position?.netTotalPrice)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.numberMapper(position?.grQuantity)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.amountMapper(position?.grAmount)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.numberMapper(position?.invoiceApprovedQuantity)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.amountMapper(position?.invoiceApprovedAmount)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.numberMapper(position?.remainingQuantity)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {
          formatConfig.amountMapper(position?.remainingAmount)
        } 
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        <Controller
          control={control}
          name={`positions.${index}.quantity` as "positions.0.quantity"}
          render={({ 
            fieldState: { invalid, error } 
          }) => (
            <NumberFormat
              size="small"
              margin="none"
              fullWidth
              customInput={MuiTextField}
              decimalSeparator={formatConfig.formats.decimalSeparator}
              thousandSeparator={formatConfig.formats.thousandSeparator}
              isNumericString={true}
              allowNegative={false}
              decimalScale={formatConfig.formats.numDecimals}
              onValueChange={(values) => valuesHandler(values, "quantity")}
              value={calculateValues.quantity}
              error={invalid}
              helperText={error?.message}
            />
          )}
          rules={{
            min: {
              value: 1,
              message: appMessage["POSITIVE_NUMBERS"] ?? "Only positive numbers are accepted"
            },
            required: appMessage["FIELD_REQUIRED"] ?? "Field required",
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row" align="right" >
        <Controller
          control={control}
          name={`positions.${index}.netUnitPrice` as "positions.0.netUnitPrice"}
          render={({ 
            fieldState: { invalid, error } 
          }) => (
            <NumberFormat
              size="small"
              margin="none"
              fullWidth
              customInput={MuiTextField}
              decimalScale={PicConstants.NET_UNT_PRICE_DECIMALS}
              decimalSeparator={formatConfig.formats.decimalSeparator}
              thousandSeparator={formatConfig.formats.thousandSeparator}
              isNumericString={true}
              allowNegative={false}
              prefix={'$'}
              onValueChange={(values) => valuesHandler(values, "netUnitPrice")}
              value={calculateValues.netUnitPrice}
              error={invalid}
              helperText={error?.message}
            />
          )}
          rules={{
            min: {
              value: 0,
              message: appMessage["POSITIVE_NUMBERS"] ?? "Only positive numbers are accepted"
            },
            required: appMessage["FIELD_REQUIRED"] ?? "Field required",
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        <Controller
          control={control}
          name={`positions.${index}.netTotalPrice` as "positions.0.netTotalPrice"}
          render={({ 
            fieldState: { invalid, error } 
          }) => (
            <NumberFormat
              size="small"
              margin="none"
              fullWidth
              customInput={MuiTextField}
              decimalScale={formatConfig.formats.numDecimals}
              decimalSeparator={formatConfig.formats.decimalSeparator}
              thousandSeparator={formatConfig.formats.thousandSeparator}
              isNumericString={true}
              allowNegative={false}
              prefix={'$'}
              onValueChange={(values) => valuesHandler(values, "netTotalPrice")}
              value={calculateValues.netTotalPrice}
              error={invalid}
              helperText={error?.message}
            />
          )}
          rules={{
            validate: (value) => {
              if(!readOnly) {
                
                const roundedValue = Number(value.toFixed(formatConfig.formats.numDecimals))
                const tolerancePercentage = Number((position?.tolerancePercentage / 100)) ;
                const toleranceAmount  =  Number(position?.toleranceAmount);
                const amountToCompare = Number(position?.remainingAmount);
                
                if(position?.tolerance === "PERCENTAGE" 
                  && Number(roundedValue) > (amountToCompare * (1 + tolerancePercentage))
                ) {
                  return appInvoicesMessage["INVALID_PERCENTAGE"] ?? "Invalid invoice percentage"
                } 
                else if(position?.tolerance === "AMOUNT" 
                  && Number(roundedValue) > (amountToCompare + toleranceAmount)
                ) {
                  return appInvoicesMessage["INVALID_AMOUNT"] ?? "Invalid invoice amount"
                } 
                else if(Number(roundedValue) > (amountToCompare)){
                  return appInvoicesMessage["INVALID_AMOUNT"] ?? "Invalid invoice amount"
                }
              }
            }
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        <Controller
          control={control}
          name={`positions.${index}.taxesAmount` as "positions.0.taxesAmount"}
          render={({ 
            fieldState: { invalid, error } 
          }) => (
            <NumberFormat
              size="small"
              margin="none"
              fullWidth
              customInput={MuiTextField}
              decimalScale={formatConfig.formats.numDecimals}
              decimalSeparator={formatConfig.formats.decimalSeparator}
              thousandSeparator={formatConfig.formats.thousandSeparator}
              isNumericString={true}
              allowNegative={false}
              prefix={'$'}
              onValueChange={(values) => valuesHandler(values, "taxesAmount")}
              value={calculateValues.taxesAmount}
              error={invalid}
              helperText={error?.message}
            />
          )}
          rules={{
            min: {
              value: 0,
              message: appMessage["POSITIVE_NUMBERS"] ?? "Only positive numbers are accepted"
            },
            required: appMessage["FIELD_REQUIRED"],
          }}
        />
      </TableCell>

      {
        showColumns && (
          <TableCell component="th" scope="row" align="right">
            <Controller
              control={control}
              name={`positions.${index}.extraChargesAmount` as "positions.0.extraChargesAmount"}
              render={({ 
                fieldState: { invalid, error } 
              }) => (
                <NumberFormat
                  size="small"
                  margin="none"
                  fullWidth
                  customInput={MuiTextField}
                  decimalScale={formatConfig.formats.numDecimals}
                  decimalSeparator={formatConfig.formats.decimalSeparator}
                  thousandSeparator={formatConfig.formats.thousandSeparator}
                  isNumericString={true}
                  allowNegative={false}
                  prefix={'$'}
                  onValueChange={(values) => valuesHandler(values, "extraChargesAmount")}
                  value={calculateValues.extraChargesAmount}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
              rules={{
                min: {
                  value: 0,
                  message: appMessage["POSITIVE_NUMBERS"] ?? "Only positive numbers are accepted"
                },
                required: appMessage["FIELD_REQUIRED"] ?? "Field required",
              }}
            />
          </TableCell>
        )
      }

      <TableCell component="th" scope="row" align="right">
        <Controller
          control={control}
          name={`positions.${index}.amountToBill` as "positions.0.amountToBill"}
          render={({ 
            fieldState: { invalid, error } 
          }) => (
            <NumberFormat
              size="small"
              margin="none"
              fullWidth
              customInput={MuiTextField}
              decimalScale={formatConfig.formats.numDecimals}
              decimalSeparator={formatConfig.formats.decimalSeparator}
              thousandSeparator={formatConfig.formats.thousandSeparator}
              isNumericString={true}
              allowNegative={false}
              prefix={'$'}
              onValueChange={(values) => valuesHandler(values, "total")}
              value={calculateValues.total}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`positions.${index}.itemText` as "positions.0.itemText"}
          render={({ 
            field: { value, onChange },
            fieldState: { invalid, error } 
          }) => (
            <MuiTextField
              size="small"
              margin="none"
              fullWidth
              error={invalid}
              helperText={error?.message}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip title={appMessage["DELETE_ROW"]} >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});