export enum UsersRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":userId/view",
  EDIT = ":userId/edit",
}

export type UsersState = {
  [UsersRoutes.EDIT]: { activeStep?: number };
};
