import { ViewAllInvoicesPage } from "apps/InvoicesPage/ViewAllPage";
import { ViewInvoicePage } from "apps/InvoicesPage/ViewPage";
import { Route, Routes } from "react-router-dom";
import { PurchaseOrdersItemsReportsPage } from "./report";
import { PurchaseOrdersItemsOverviewRoutes } from "./routes";

const PurchaseOrdersItemsOverviewPage = () => {
  return (
    <Routes>
      <Route
        path={PurchaseOrdersItemsOverviewRoutes.PATH}
        element={<PurchaseOrdersItemsReportsPage />}
      />
      <Route 
        path={PurchaseOrdersItemsOverviewRoutes.PATH_INVOICES} 
        element={<ViewAllInvoicesPage />} 
      />
      <Route 
        path={PurchaseOrdersItemsOverviewRoutes.PATH_INVOICES_VIEW} 
        element={<ViewInvoicePage />} 
      />
    </Routes>
  );
};

export default PurchaseOrdersItemsOverviewPage;
