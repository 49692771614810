import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { notNullUndefined } from "utils/notNullUndefined";


export type PurchaseOrdersItemsCheckedRowsType = {
  id: string; 
  purchaseOrder: string;
  checked: boolean
}

type CheckedRowsState = {
  areAllRowsChecked: boolean;
  individualRowsChecked: PurchaseOrdersItemsCheckedRowsType[];
};

const initialState: CheckedRowsState = { 
  areAllRowsChecked: false,
  individualRowsChecked: [], 
};

// FIXME: Si se seleccionan todas las columnas el filtro no funciona
const checkedRowsSlice = createSlice({
  name: "purchaseOrderItemsCheckedRowsSlice",
  initialState,
  reducers: {
    checkAllRows: (state: any, action: PayloadAction<{checked:boolean}>) => {
      const { checked } = action.payload;
      if (checked) {
        state.individualRowsChecked = [];
      }
      state.areAllRowsChecked = checked;

    },
    checkRow: (
      state: CheckedRowsState,
      action: PayloadAction<{ rowKey: string; checked: boolean }>
    ) => {
      const { rowKey, checked } = action.payload;
      const { id, purchaseOrder } = JSON.parse(rowKey);
      const row = state.individualRowsChecked.find((e) => e.id === id && e.purchaseOrder === purchaseOrder);

      if(notNullUndefined(row)) {
        row.checked = checked;
      } else {
        state.individualRowsChecked.push({
          id: id,
          purchaseOrder: purchaseOrder,
          checked: checked
        })
      }
    },
  },
});

export const { checkRow, checkAllRows } = checkedRowsSlice.actions;

export const selectIsRowChecked = (state: RootState) => (rowKey: string) =>  {

  const { id, purchaseOrder } = JSON.parse(rowKey);

  if(state.purchaseOrdersItems.rowsChecked.areAllRowsChecked) return true;
  return state.purchaseOrdersItems.rowsChecked.individualRowsChecked
    .find(e => e.id === id && e.purchaseOrder === purchaseOrder)?.checked ?? false;
}

// FIXME: Si se seleccionan todas las columnas el filtro no funciona
export const selectAreAllRowsChecked = (state: RootState) => 
state.purchaseOrdersItems.rowsChecked.areAllRowsChecked

export const selectRowsChecked = (state: RootState) => {
  return state.purchaseOrdersItems.rowsChecked.individualRowsChecked
    .filter(e => e.checked === true)
}

export default checkedRowsSlice.reducer;
