export enum BackendRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":backendId/view",
  EDIT = ":backendId/edit",
}

export type BackendRoutesState = {
  [BackendRoutes.EDIT]: { activeStep?: number };
};
