import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  AccountingEntityExistsDocument,
  AccountingEntityExistsQuery,
  AccountingEntityExistsQueryVariables,
  useGetCurrenciesForAccountingEntitiesFormPageQuery,
  GetCurrenciesForAccountingEntitiesFormPageQuery,
  GetCompaniesForAccountingEntitiesFormPageQuery,
  useGetCompaniesForAccountingEntitiesFormPageQuery,
} from "__generated__/graphql/types";
import { useDataElementChoices } from "hooks/useDataElementChoices";

export type BasicDataFormFields = {
  accountingEntity: string;
  name: string;
  currency: string;
  company: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, isUsed, onEdit }: BasicDataFormProps) => {
  const { control } = form;
  const { language } = useSystemLanguage();

  const isIdReadOnly = mode !== "create";
  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "ACCOUNTING_ENTITY",
    "DESCRIPTION",
    "CURRENCY",
    "COMPANY",
  ]);

  const { data: { currencies } = {} } = useGetCurrenciesForAccountingEntitiesFormPageQuery({
    language,
  });

  const { data: { companies } = {} } = useGetCompaniesForAccountingEntitiesFormPageQuery({});
  const { dataElementsChoices } = useDataElementChoices(["COMPANY"]);

  if (dataElementsChoices === undefined) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner
  if (currencies == null) return null;
  if (companies == null) return null;

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["ACCOUNTING_ENTITY"]}
              controllerProps={{
                name: "accountingEntity",
                control,
                rules: {
                  required: "Ingrese el Id.", // FIXME: traduccion
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const {
                      accountingEntityExists,
                    } = await accountingEntityExistsFn({
                      id: value,
                    });
                    return accountingEntityExists
                      ? "El Id ya existe." // FIXME: traduccion
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["DESCRIPTION"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["CURRENCY"]}
              controllerProps={{
                name: "currency",
                control,
                rules: { required: "Selecciona la moneda." }, // FIXME: traduccion
              }}
              readOnly={disabled}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["CURRENCY"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="name"
              options={currencies.map(mapDataElementToOption)}
            />
          </KeyInputWrapper>
        </Grid>

        {/* comienza el codigo noob */}

        <Grid item xs={3}>
          <KeyInputWrapper>
            <MatchcodeInputController
              label={dataElementsDescriptions["COMPANY"]}
              controllerProps={{
                name: "company",
                control,
                rules: { required: "Please, Select the company" }, // FIXME: traduccion
              }}
              readOnly={disabled}
              column={[
                {
                  key: "name",
                  text: dataElementsDescriptions["COMPANY"],
                },
              ]}
              displayColumn="name"
              options={companies.map(mapCompaniesToOptions)}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const accountingEntityExistsFn = pDebounce(
  (args: AccountingEntityExistsQueryVariables) =>
    fetcher<AccountingEntityExistsQuery, AccountingEntityExistsQueryVariables>(
      AccountingEntityExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);

const mapDataElementToOption = (
  dto: NonNullable<GetCurrenciesForAccountingEntitiesFormPageQuery["currencies"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});

const mapCompaniesToOptions = (
  dto: NonNullable<GetCompaniesForAccountingEntitiesFormPageQuery["companies"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
});
