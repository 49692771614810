import { Box, Typography } from "@mui/material";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useAppSelector } from "hooks/reduxHooks";
import { getMyInformation } from "components/providers/ReduxMyProvider/reducer";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useGetAllThemesQuery } from "__generated__/graphql/types";

export type ThemeFormFields = {
  themeId: string; // FIXME: en caso de campo vacio retornar "", deberia retornar undefined (o null)
};

export type ThemeFormProps = {
  form: UseFormReturn<ThemeFormFields>;
};

const ThemeForm = ({ form }: ThemeFormProps) => {
  const { language } = useSystemLanguage();
  const { me } = useAppSelector(getMyInformation);
  const { appMessage } = useAppMessages("MAIN");

  const { dataElementsDescriptions } = useDataElementDescription([
    "DESCRIPTION",
    "THEME"
  ]);

  const { data: { themes } = {} } = useGetAllThemesQuery({
    language: language,
    filters: {},
    pagination: { page: 0, pageSize: 1000 }, // FIXME: page, pageSize
  });

  if (themes == null) return <BeatLoadingPage />;
  if (dataElementsDescriptions == null) return <BeatLoadingPage />;
  if (me == null) return <BeatLoadingPage />;

  return (
    <Box>
      <Typography variant="h6">
        {appMessage["THEME"]}
      </Typography>

      <Box maxWidth={"300px"}>
        <Box marginTop={2}>
          <MatchcodeInputController
            label={appMessage["THEME"]}
            controllerProps={{
              name: "themeId",
              control: form.control,
              defaultValue: me?.themeId ?? "", // FIXME:
            }}
            column={[
              { key: "name", text: dataElementsDescriptions["THEME"] },
              { key: "description",  text: dataElementsDescriptions["DESCRIPTION"] }, 
            ]}
            displayColumn="name"
            options={themes.map((e) => ({
              id: e.id,
              name: e.id,
              description: e.translation.description,
            }))}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ThemeForm;

