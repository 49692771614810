export enum InvoicesFreightRoutes {
  PATH = "/",
  PATH_FREIGHT = "freight",
  PATH_INVOICES = "invoices",
  PATH_INVOICES_VIEW = "invoices/view",
  PATH_INVOICES_EDIT = "invoices/edit",
  PATH_INVOICES_CREATE = "invoices/create",
}

export type InvoicesFreightRoutesState = {};

