import { DevTool } from "@hookform/devtools";
import { Box, Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ImagePicker } from "components/inputs/ImagePicker";
import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { fileToBase64 } from "utils/fileToBase64";
import { ImageFormProps } from "./types";
import useAppMessages from 'hooks/useAppMessages/hook';

const IconForm = ({ forms, readOnly = false, onEdit }: ImageFormProps) => {
  const { control } = forms.image;

  const { appMessage } = useAppMessages("USERS");
  const [iconBase64, setIconBase64] = useState<string>();

  const image = useWatch({
    control: forms.image.control,
    name: "image",
  });

  const { dataElementsDescriptions } = useDataElementDescription(["ICON"]);

  useEffect(() => {
    if (!(image instanceof File)) return;
    fileToBase64(image).then((e) => setIconBase64(e as string));
  }, [image]);

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <>
      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <DevTool placement="top-left" control={control} />

        <Grid container wrap="nowrap" justifyContent="center">
          <Grid item>
            <ImagePicker
              tooltipTitle={appMessage["ENTER_IMG"]}
              label={dataElementsDescriptions["ICON"]}
              controllerProps={{
                name: "image",
                control,
                rules: { required: appMessage["ENTER_IMG"]??"Select an image" }, //FIXPRD QUITAR
              }}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>

      {iconBase64 && (
        <Box padding={2} marginTop={2}>
          <Grid
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <img
                src={iconBase64}
                // height="100px"
                // width="100px"
                alt=""
                style={{
                  height: "100px",
                  width: "100px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default IconForm;
