import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from "react-hook-form";
import { GetPositionsForCreateEmployeeQuery } from "__generated__/graphql/types";
import { PositionsFormFields } from "../types";
import { PositionMatchcode } from "./PositionMatchcode";
import { DatePicker } from '@mui/lab';


import TextField from '@mui/material/TextField';


type RowProps = {
  form: UseFormReturn<PositionsFormFields>;
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<PositionsFormFields>["fields"][number];
  positions: NonNullable<GetPositionsForCreateEmployeeQuery["positions"]>;
};

const Row = ({ form, index, remove, item, readOnly, positions }: RowProps) => {
  const { control, trigger } = form;

  const positionsOptions = positions.map((e) => ({
    id: e.id,
    position: e.id.toUpperCase(),
    description: e.translation.description,
  }));

  return (

<TableRow>
  <TableCell
    component="th"
    scope="row"
  >
    <Controller
      control={control}
      name={`positions.${index}.positionId` as "positions.0.positionId"}
      rules={{ required: "Seleccione un cargo." }} // FIXME: traduccion
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => (
        <PositionMatchcode
          positionsOptions={ positionsOptions }
          value={ value }
          onChange={( value ) => {
            // TODO:
            // esto es necesario porque watch(...) no detecta cambio si nuevo valor es undefined
            onChange(value ?? "");
          }}
          invalid={invalid}
          errorMessage={error?.message}
          readOnly={readOnly}
         
        />
      )}
      defaultValue={item.positionId}
      
    />
  </TableCell>

  <TableCell
    component="th"
    scope="row"
  >
    <Controller
      control={ control }
          name={`positions.${index}.startDate` as "positions.0.startDate"}
      rules={{ required: "Seleccione una fecha" }} // FIXME: traduccion
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => (
        <DatePicker
          views={["year", "month", "day"]}
          label="Start Date"
          inputFormat='yyyy-MM-dd'
          value={ value }
          onChange={ onChange }
          renderInput={(params) => <TextField {...params} />}
          readOnly={readOnly}
        />
      )}
    />
  </TableCell>

  <TableCell align="right">
    {!readOnly && (
      <Tooltip
        title="Eliminar fila" // FIXME: traduccion
      >
        <IconButton
          aria-label="remove"
          size="small"
          onClick={() => {
            remove();
            trigger();
          }}
        >
          <StyledClearIcon />
        </IconButton>
      </Tooltip>
    )}
  </TableCell>
</TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
