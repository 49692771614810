import { Snackbar } from "@mui/material";
import { Alert as MuiAlert } from "@mui/material";
import { AlertProps as MuiAlertProps } from "@mui/lab";
import React from "react";

export type AlertSnackbarProps = {
  open: boolean;
  onClose: () => void;
} & MuiAlertProps;

const SnackbarAlert = ({ open, onClose, ...props }: AlertSnackbarProps) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <MuiAlert elevation={6} variant="standard" onClose={onClose} {...props} />
    </Snackbar>
  );
};

export default SnackbarAlert;
