import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon, PowerSettingsNew as PowerSettingsNewIcon, Search as SearchIcon
} from "@mui/icons-material";
import { Box } from '@mui/material';
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAllowedUserAppActions } from "hooks/useAlloweduserAppActions";
import { useAppMessages } from "hooks/useAppMessages";
import { useSnackbar } from "hooks/useSnackbarV2";
import { UseSnackbarReturn } from "hooks/useSnackbarV2/hook";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { notNullUndefined } from "utils/notNullUndefined";
import { useDeleteThemeMutation, useGetThemesQuery, useSetDefaultThemeMutation } from "__generated__/graphql/types";
import { ThemeRoutes } from "../routes";
import { Collapse } from "./components/ThemesCollapse";
import { Table } from "./components/ThemesTable";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { resetPage, selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";

const ViewAllPage = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { language } = useSystemLanguage();

  const { appMessage } = useAppMessages("THEMES");
  const { appMessage: appMessages } = useAppMessages("MESSAGES");

  const { actions } = useAllowedUserAppActions("THEMES");

  const errorSnackbar = useSnackbar();
  const rowsChecked = useAppSelector(selectRowsChecked);
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);

  const {
    data: { themes, themesCount } = {},
    refetch: findApps,
  } = useGetThemesQuery({
    pagination,
    language,
    filters: {
      id: filters.name ?? undefined,
      description: filters.description ?? undefined,
    }
  });

  const { mutateAsync: deleteTheme, } = useDeleteThemeMutation();
  const { mutateAsync: setDefaultTheme } = useSetDefaultThemeMutation();

  const readAction = actions.find(e => e.id === "READ");
  const updateAction = actions.find(e => e.id === "UPDATE");
  const deleteAction = actions.find(e => e.id === "DELETE");
  const setAction = actions.find(e => e.id === "SET_DEFAULT");

  const menuItems = useMemo(
    () => [
      readAction && {
        key: "display",
        Icon: SearchIcon,
        text: readAction.translation.description,
        onClick: (id: string) => {
          if (id === "display") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          navigate(generatePath(ThemeRoutes.VIEW, { themeId: id }));
        },
      },
      updateAction && {
        key: "edit",
        Icon: EditIcon,
        text: updateAction.translation.description,
        onClick: (id: string) => {
          if (id === "update") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          navigate(generatePath(ThemeRoutes.EDIT, { themeId: id }));
        },
      },
      deleteAction && {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: deleteAction.translation.description,
        onClick: (id: string) => {
          if (id === "delete") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          deleteTheme({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open(appMessage["ERROR_DELETE"]))
        }
      },
      setAction && {
        key: "set_default",
        Icon: PowerSettingsNewIcon,
        text: setAction.translation.description,
        onClick: (id: string) => {
          if (id === "set_default") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          setDefaultTheme({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open(appMessage["ERROR_SET"]));
        }
      },
    ].filter(notNullUndefined), [deleteTheme, setDefaultTheme, dispatch, errorSnackbar, findApps, navigate,
    appMessage, appMessages, rowsChecked, readAction, updateAction, deleteAction, setAction])

  const total = themesCount;

  return (
    <Layout>

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="warning"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <Box display="flex" flexDirection="column" height="100%">
        <Header
          title={appMessage["THEME_TITTLE"]}
          toBack="../"
        />

        <Box marginY={2}>
          <Collapse
            menuItems={menuItems}
          />
        </Box>

        <Table
          menuItems={menuItems}
          themes={themes ?? []}
          total={total}
          pagination={pagination}
        />
      </Box>
    </Layout>
  );
};

export default ViewAllPage;

const validateSelectRows: any = (rowsChecked: string[], appMessages: any, errorSnackbar: UseSnackbarReturn) => {
  if (rowsChecked.length === 0) {
    errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
    return true;
  } else if (rowsChecked.length > 1) {
    errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
    return true;
  }
  return false;
}