import { DevTool } from "@hookform/devtools";
import { TableCollapse } from "components/datadisplay/TableCollapse";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { SelectFilter } from "components/filters/SelectFilter";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useDialog } from "hooks/useDialog";
import React, { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generatePath } from "utils/typedRoutesUtils";
import { ExportMigrationPackageRoutes } from "../../../routes";
import {
  Filters,
  FiltersKeys,
  filtersLabels,
  selectFilters,
  selectHiddenFiltersKeys,
  selectVisibleFiltersKeys,
  setFilters,
} from "../../reducers/filtersAndPaginationSlice";
import { ColumnsVisibilityDialog } from "./ColumnsVisibilityDialog";
import { FiltersVisibilityDialog } from "./FiltersVisibilityDialog";

const Collapse = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const filtersDialog = useDialog();
  const columnsDialog = useDialog();

  const filters = useAppSelector(selectFilters);
  const visibleFiltersKeys = useAppSelector(selectVisibleFiltersKeys);
  const hiddenFiltersKeys = useAppSelector(selectHiddenFiltersKeys);

  const { control, handleSubmit, unregister } = useForm<Filters>({
    defaultValues: filters,
  });

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: filtersLabels,
    control,
  });

  const onCreate = () => {
    navigate(generatePath(ExportMigrationPackageRoutes.CREATE));
  };

  const onSearch = handleSubmit((form) => {
    dispatch(setFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <ColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapse
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={onCreate}
        onDownload={() => {}}
        onExpand={() => {}}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
      />
    </>
  );
};

export default Collapse;

const filtersComponents: Record<
  FiltersKeys,
  ComponentType<FilterComponentProps<Filters, FiltersKeys>>
> = {
  id: TextFilter,
  description: TextFilter,
  status: (props) => (
    <SelectFilter
      {...props}
      options={[
        { value: "PUBLISHED", text: "Publicado" }, // FIXME: traduccion
        { value: "DRAFT", text: "Borrador" }, // FIXME: traduccion
      ]}
    />
  ),
};
