import { Box } from "@mui/material";
import React from "react";

type VisuallyHiddenProps = {
  isVisible?: boolean;
  children: JSX.Element;
};

const VisuallyHidden = ({
  isVisible = false,
  children,
}: VisuallyHiddenProps) => {
  return <Box display={isVisible ? "block" : "none"}>{children}</Box>;
};

export default VisuallyHidden;
