import { format, parseISO } from "date-fns";
import { Maybe } from "graphql/jsutils/Maybe";

export const amountFormat = (
  value: Maybe<string> | number | undefined,
  location: string,
  numDecimals: number,
): string => {
  if(!value) value = "0";
  return  "$"+ Number(value).toLocaleString(location, 
    { maximumFractionDigits: numDecimals, minimumFractionDigits: numDecimals});
};

export const numberFormat = (
  value: Maybe<string> | number | undefined,
  location: string,
): string => {
  if(!value) value = "0";
  return  Number(value).toLocaleString(location);
};

export const dateFormat = (
  date: Maybe<string> | undefined,
  dateFormat: string
): string => {
  if(!date) return "";
  return format(parseISO(date), dateFormat) 
}

export const isoLocalDateFormat = (
  date: Date,
): string => {
  if(!date) return "";
  return format(date, "yyyy-MM-dd") 
}

export const isoLocalDateTimeFormat = (
  date: Date,
): string => {
  if(!date) return "";
  return format(date, "yyyy-MM-dd'T'HH:mm:ss") 
}

export const yearDateFormat = (
  date: Date,
): string => {
  if(!date) return "";
  return format(date, "yyyy") 
}