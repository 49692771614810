import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetAppViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { AppsRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {
  const navigate = useNavigate();

  const { appId } = useRouteParams<AppsRoutes.VIEW>();

  const { data: { app } = {}, isFetchedAfterMount } = useGetAppViewPageQuery({
    id: appId,
  });

  const initialValue =
    app != null && isFetchedAfterMount ? mapDtoToForm(app) : undefined;

  const onEdit = (activeStep: number) => {
    navigate(`../../${generatePath(AppsRoutes.EDIT, { appId })}`, {
      state: { activeStep },
    });
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Crear app" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValue} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
