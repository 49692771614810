import {
  Check as CheckIcon,
  InfoOutlined as InfoOutlinedIcon,
  PhotoCameraBack as PhotoCameraBackIcon,
  ListRounded as ListRoundedIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { AlertUnsavedDataInput } from "components/feedback/AlertUnsavedDataInput";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { SuccessMessage } from "components/feedback/SuccessMessage";
import { DescriptionFormControl } from "components/inputs/DescriptionFormControl";
import { SaveCloseButtons } from "components/inputs/SaveCloseButtons";
import { FormStepper } from "components/navigation/FormStepper";
import { setIsThemeFromBackend } from "components/providers/ReduxMuiThemeProvider/reducer";
import { useAppDispatch } from "hooks/reduxHooks";
import { useAppSectionsTranslations } from "hooks/useAppSectionsTranslations";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useFormSteps } from "hooks/useFormSteps";
import { StepBehavior } from "hooks/useFormSteps/hook";
import React, { useCallback } from "react";
import { FormProps } from "types/Form";
import { assertUnreachable } from "utils/assert";
import { FormStep } from "utils/FormStep";
import { AppsColorFormProps } from "./AppsColorForm";
import { BackgroundImageForm } from "./BackgroundImageForm";
import { BasicDataForm } from "./BasicDataForm";
import { PrimaryColorForm } from "./PrimaryColorForm";
import { StepperColorForm } from "./StepperColorForm";
import { FormFields, FormStepsKeys, NonFormStepsKeys } from "./types";
import { useEffectUpdateTheme } from "./useEffectUpdateTheme";
import { useForms } from "./useForms";
import { CompanyLogoForm } from "./CompanyLogoForm";
  import useAppMessages from 'hooks/useAppMessages/hook';
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

const Form = ({
  initialValue,
  initialStep,
  mode,
  errorMessage = "", // FIXME:
  onSave = assertUnreachable, // FIXME:
  onCancel = assertUnreachable, // FIXME:
  onEdit,
}: FormProps<FormFields>) => {
  const dispatch = useAppDispatch();
  const forms = useForms(initialValue);
  const errorSnackbar = useSnackbar();
  const { appMessage } = useAppMessages("MESSAGES");

  const {
    appSectionsTranslations,
    isLoadingSections,
  } = useAppSectionsTranslations("THEMES");

  const stepsKeysInOrder: StepBehavior<FormStepsKeys, NonFormStepsKeys>[] = [
    { key: "basicData", hasForm: true },
    { key: "descriptions", hasForm: true },
    { key: "primaryColor", hasForm: true },
    { key: "backgroundImage", hasForm: true },
    { key: "appsColor", hasForm: true },
    { key: "stepperColor", hasForm: true },
    { key: "companyLogo", hasForm: true },
    { key: "save", isHidden: mode === "view" },
    { key: "success", isHidden: mode === "view" },
  ];

  const onSaveHandler = useCallback(
    (value: FormFields) =>
      onSave?.(value).then(() => dispatch(setIsThemeFromBackend(true))),
    [dispatch, onSave]
  );

  const readOnly = mode === "view";
  const noLinearStepper = mode ==="view" || mode ==="edit";

  const stepsNavigation = useFormSteps({
    forms,
    initialStep,
    errorSnackbar,
    errorMessage: errorMessage ?? "", // FIXME:
    onSave: (onSaveHandler ?? assertUnreachable) as any, // FIXME:,
    stepsKeysInOrder,
    onEdit,
    readOnly,
  });

  const unorderedSteps: Record<FormStepsKeys | NonFormStepsKeys, FormStep> = {
    basicData: {
      ...appSectionsTranslations("BASIC_DATA"),
      icon: <ListRoundedIcon />,
      body: <BasicDataForm form={forms.basicData} mode={mode} />,
    },
    descriptions: {
      ...appSectionsTranslations("DESCRIPTIONS"),
      icon: <ListRoundedIcon />,
      body: (
        <DescriptionFormControl
          form={forms.descriptions}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    primaryColor: {
      ...appSectionsTranslations("PRIMARY"),
      icon: <InfoOutlinedIcon />,
      body: <PrimaryColorForm form={forms.primaryColor} readOnly={readOnly} />,
    },
    appsColor: {
      ...appSectionsTranslations("APPS"),
      icon: <InfoOutlinedIcon />,
      body: <AppsColorFormProps form={forms.appsColor} readOnly={readOnly} />,
    },
    stepperColor: {
      ...appSectionsTranslations("STEPPER"),
      icon: <InfoOutlinedIcon />,
      body: <StepperColorForm form={forms.stepperColor} readOnly={readOnly} />,
    },
    backgroundImage: {
      ...appSectionsTranslations("IMAGE"),
      icon: <InfoOutlinedIcon />,
      body: (
        <BackgroundImageForm form={forms.backgroundImage} readOnly={readOnly} />
      ),
    },
    companyLogo: {
      ...appSectionsTranslations("LOGO"),
      icon: <PhotoCameraBackIcon />,
      body: (
        <CompanyLogoForm form={forms.companyLogo} readOnly={readOnly} />
      ),
    },
    save: {
      title: appMessage["SAVE"],
      subtitle: appMessage["SAVE_CONFIRMATION"],
      icon: <SaveIcon />,
      isSaveButtonVisible: true,
    },
    success: {
      title: appMessage["END"],
      icon: <CheckIcon />,
      body: <SuccessMessage />,
      isHeaderHidden: true,
    },
  };

  const currentStep = unorderedSteps[stepsNavigation.activeStepKey];

  const visibleSteps = stepsNavigation.visibleStepsKeysInOrder.map(
    (key) => unorderedSteps[key]
  );

  useEffectUpdateTheme(forms);

  if (isLoadingSections) return <BeatLoadingPage />;

  return (
    <>
      <AlertUnsavedDataInput
        isBlocked={stepsNavigation.isRouteBlocked}
        unblock={stepsNavigation.unblockRoute}
      />

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <FormStepper
        steps={visibleSteps}
        activeStepIndex={stepsNavigation.stepper.activeStepIndex}
        isNonLinear={noLinearStepper}
        onChangeActiveStepIndex={stepsNavigation.stepper.setActiveStepIndex}
      />

      {!currentStep.isHeaderHidden && (
        <FormHeader
          title={currentStep.title}
          subtitle={currentStep.subtitle}
          step={currentStep.step}
          leftButtonText={currentStep.leftButtonText}
          isPreviousButtonVisible={stepsNavigation.isPreviousButtonVisible}
          isNextButtonVisible={stepsNavigation.isNextButtonVisible}
          isUpButtonVisible={stepsNavigation.isUpButtonVisible}
          onPrevious={stepsNavigation.onPrevious}
          onNext={stepsNavigation.onNext}
          onUp={stepsNavigation.onUp}
        />
      )}

      <FormBodyWrapper>{currentStep.body}</FormBodyWrapper>

      {mode !== "view" && (
        <SaveCloseButtons
          isCancelButtonVisible={stepsNavigation.activeStepKey !== "success"}
          isSaveButtonVisible={currentStep.isSaveButtonVisible ?? false} // FIXME:
          onCancel={onCancel ?? assertUnreachable}
          onSave={stepsNavigation.onClickSave}
        />
      )}
    </>
  );
};

export default Form;
