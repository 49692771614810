import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { SelectController } from "components/inputs/SelectController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import pDebounce from "p-debounce";
import React from "react";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  GetBusinessObjectsForCreateKpiQuery,
  KpiExistsDocument,
  KpiExistsQuery,
  KpiExistsQueryVariables,
  useGetBusinessObjectsForCreateKpiQuery,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";

const BasicDataForm = ({
  mode,
  onEdit,
  form: { control },
}: BasicDataFormProps) => {
  const { language } = useSystemLanguage();

  const {
    data: { businessObjects } = {},
  } = useGetBusinessObjectsForCreateKpiQuery({ language });

  const { dataElementsDescriptions } = useDataElementDescription([
    "KPI",
    "BUSINESS_OBJECT",
    "KPI_TYPE",
    "DESCRIPTION",
  ]);

  const isIdReadOnly = mode !== "create";
  const readOnly = mode === "view";

  const { dataElementsChoices } = useDataElementChoices(["KPI_TYPE"]);

  if (businessObjects == null) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner
  if (dataElementsChoices == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool placement="top-left" control={control} />

      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              label={dataElementsDescriptions["KPI"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: "Ingrese el nombre del KPI.", // FIXME: traduccion
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { kpiExists } = await kpiExistsFn({
                      id: value,
                    });
                    return kpiExists
                      ? "El nombre ya existe." // FIXME: traduccion
                      : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
              inputProps={{ disabled: isIdReadOnly }}
            />
          </KeyInputWrapper>
        </Grid>

        <Grid item xs={3}>
          <MatchcodeInputController
            label={dataElementsDescriptions["BUSINESS_OBJECT"]}
            controllerProps={{
              name: "businessObjectId",
              control,
              rules: { required: "Seleccione la app." }, // FIXME: traduccion
            }}
            column={[
              {
                key: "businessObject",
                text: dataElementsDescriptions["BUSINESS_OBJECT"],
              },
              {
                key: "description",
                text: dataElementsDescriptions["DESCRIPTION"],
              },
            ]}
            displayColumn="businessObject"
            options={businessObjects.map(mapToMatchcodeOptions)}
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={3}>
          <SelectController
            label={dataElementsDescriptions["KPI_TYPE"]}
            controllerProps={{
              name: "type",
              control,
              rules: { required: "Seleccione el tipo de KPI." }, // FIXME: traduccion
            }}
            options={dataElementsChoices["KPI_TYPE"]}
            // readOnly={readOnly} FIXME:
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const mapToMatchcodeOptions = (
  dto: NonNullable<
    GetBusinessObjectsForCreateKpiQuery["businessObjects"]
  >[number]
) => ({
  id: dto.id,
  businessObject: dto.id,
  description: dto.dataElement.translation.description,
});

const kpiExistsFn = pDebounce(
  (args: KpiExistsQueryVariables) =>
    fetcher<KpiExistsQuery, KpiExistsQueryVariables>(KpiExistsDocument, args)(),
  DEBOUNCE_TIME_ID
);
