import {
  AddCircle as AddCircleIcon,
  Edit as EditCircleIcon
} from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useDataElementDescription } from "hooks/useDataElementDescription";

type HeaderProps = {
  appendRow: () => void;
  onEdit?: () => void;
  readOnly?: boolean;
  showColumns: boolean;
};

const Header = ({ appendRow, readOnly, onEdit, showColumns }: HeaderProps) => {

  const { appMessage } = useAppMessages("MESSAGES");
  const { dataElementsDescriptions } = useDataElementDescription([
    "PURCHASE_ORDER_ITEM_ID",
    "PRODUCT_ID",
    "PRODUCT_DESCRIPTION",
    "UNIT",
    "PO_QUANTITY",
    "NET_UNIT_PRICE",
    "NET_TOTAL_PRICE",
    "NET_TOTAL_PRICE",
    "GR_QUANTITY",
    "GR_AMOUNT",
    "PIC_INVOICED_QUANTITY",
    "PIC_INVOICED_AMOUNT",
    "REMAINING_QUANTITY",
    "REMAINING_AMOUNT",
    "QUANTITY_TO_BILL",
    "NET_UNIT_PRICE_TO_BILL",
    "NET_TOTAL_PRICE_TO_BILL",
    "TAX_AMOUNT_TO_BILL",
    "EXTRA_CHARGES",
    "TOTAL_TO_BILL",
    "COMMENTS"
  ]);

  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["PRODUCT_ID"]?? "PRODUCT_ID"} 
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["PURCHASE_ORDER_ITEM_ID"] ?? "PURCHASE_ORDER_ITEM_ID"} 
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["PRODUCT_DESCRIPTION"] ?? "PRODUCT_DESCRIPTION"}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["UNIT"] ?? "UNIT"}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["PO_QUANTITY"] ?? "PO_QUANTITY"}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["NET_UNIT_PRICE"] ?? "NET_UNIT_PRICE"}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["NET_TOTAL_PRICE"] ?? "NET_TOTAL_PRICE"} 
        </StyledHeadTableCell>

        <StyledHeadTableCell id="gr-quantity-header" align="center">
          {dataElementsDescriptions["GR_QUANTITY"] ?? "GR_QUANTITY"}
        </StyledHeadTableCell>

        <StyledHeadTableCell id="gr-amount-header" align="center">
          {dataElementsDescriptions["GR_AMOUNT"] ?? "GR_AMOUNT"}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["PIC_INVOICED_QUANTITY"] ?? "PIC_INVOICED_QUANTITY"}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["PIC_INVOICED_AMOUNT"] ?? "PIC_INVOICED_AMOUNT"}
        </StyledHeadTableCell>

        <StyledHeadTableCell id="remaining_quantity" align="center">
          {dataElementsDescriptions["REMAINING_QUANTITY"] ?? "REMAINING_QUANTITY"}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["REMAINING_AMOUNT"] ?? "REMAINING_AMOUNT"}
        </StyledHeadTableCell>

        <StyledHeadTableCell id="quantity-to-bill-header" align="center">
          {dataElementsDescriptions["QUANTITY_TO_BILL"] ?? "QUANTITY_TO_BILL"} 
        </StyledHeadTableCell>

        <StyledHeadTableCell id="net_unit_price-to-bill-header" align="center">
          {dataElementsDescriptions["NET_UNIT_PRICE_TO_BILL"] ?? "NET_UNIT_PRICE_TO_BILL"}  
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["NET_TOTAL_PRICE_TO_BILL"] ?? "NET_TOTAL_PRICE_TO_BILL"}  
        </StyledHeadTableCell>

        <StyledHeadTableCell align="center">
          {dataElementsDescriptions["TAX_AMOUNT_TO_BILL"] ?? "TAX_AMOUNT_TO_BILL"}
        </StyledHeadTableCell>

        {
          showColumns && (
            <StyledHeadTableCell align="center">
              {dataElementsDescriptions["EXTRA_CHARGES"] ?? "EXTRA_CHARGES"}
            </StyledHeadTableCell>
          )
        }

        <StyledPaddingHeadTableCell id="total-to-bill-header" align="right">
          {dataElementsDescriptions["TOTAL_TO_BILL"] ?? "TOTAL_TO_BILL"}
        </StyledPaddingHeadTableCell>

        <StyledPaddingHeadTableCell align="right">
          {dataElementsDescriptions["COMMENTS"] ?? "COMMENTS"}
        </StyledPaddingHeadTableCell>

        <TableCell align="right">
          { readOnly ? (
              <Tooltip title={appMessage["EDIT"]} >
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => onEdit?.()}>
                  <StyledEditCircleIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={appMessage["ADD_ROW"]} >
                <IconButton aria-label="add" size="small" onClick={appendRow}>
                  <StyledAddCircleIcon />
                </IconButton>
              </Tooltip>
            )
          }
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
  whiteSpace: "nowrap",
}));

const StyledPaddingHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  paddingLeft: theme.spacing(10),
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
