import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import React from "react";

type MatchcodeDisplayInputProps = {
  label?: string;
  name: string;
  text: string;
  isDeleteButtonVisible: boolean;
  onClearValue: () => void;
  onOpenDialog: () => void;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
};

const MatchcodeDisplayInput = ({
  label,
  name,
  text,
  isDeleteButtonVisible,
  onClearValue,
  onOpenDialog,
  error,
  helperText,
  readOnly,
}: MatchcodeDisplayInputProps) => {
  return (
    <MuiTextField
      id={name}
      name={name}
      label={label}
      variant="outlined"
      color="primary"
      size="small"
      margin="none"
      value={text ?? ""}
      fullWidth
      inputProps={{ disabled: true }}
      InputLabelProps={{ shrink: text !== "" }}
      InputProps={{
        startAdornment: !readOnly && isDeleteButtonVisible && (
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClearValue}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: !readOnly && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onOpenDialog}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={error}
      helperText={helperText}
    />
  );
};

export default MatchcodeDisplayInput;
