import {  RevenueCollectorInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): RevenueCollectorInput => {
  return {
    companyId: form.basicData.companyId,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
  };
};
