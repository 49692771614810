import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { GetMyInformationQuery } from '../../../__generated__/graphql/types';

type MyInformationState = {
  me: GetMyInformationQuery["me"] | undefined;
};

const initialState: MyInformationState = {
  me: undefined,
};

const myInformationSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    setMyInformation: (state: MyInformationState, action: PayloadAction<any>) => {
      state.me = action.payload;
    },
    clearMyInformation: (state: MyInformationState) => {
      state.me = undefined;
    },
  },
});

export const {
  setMyInformation,
  clearMyInformation
} = myInformationSlice.actions;

export const getMyInformation = (state: RootState) => state.me;

export default myInformationSlice.reducer;
