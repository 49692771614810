import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { TextFieldController } from "components/inputs/TextFieldController";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { RowProps } from "./types";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import {
  GetRevenueCollectorsForBusinessUnitsFormQuery,
  useGetRevenueCollectorsForBusinessUnitsFormQuery
} from "__generated__/graphql/types";

const Row = ({ index, remove, item, readOnly, form }: RowProps) => {
  const { control, trigger, getValues, watch } = form;

  const { language } = useSystemLanguage();

  const { data: { revenueCollectors } = {} } = useGetRevenueCollectorsForBusinessUnitsFormQuery({ language });

  const { dataElementsDescriptions } = useDataElementDescription([
    "REVENUE_COLLECTOR",
    "DESCRIPTION"
  ]);
  if (revenueCollectors === undefined) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  const selectedColl = watch(`collectors.${index}.id` as "collectors.0.id");
  const selectedCollectors = revenueCollectors.find(
    (coll) => coll.id === selectedColl
  );
  const onRemoveRow = () => {
    remove();
    trigger();
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <KeyInputWrapper>
          <MatchcodeInputController
            label={dataElementsDescriptions["REVENUE_COLLECTOR"]}
            controllerProps={{
              name: `collectors.${index}.id` as "collectors.0.id",
              control,
              rules: {
                required: "Seleccione el recaudador de ingresos",
                validate: (coll) => {
                  if (readOnly) return true;
                  const id = getValues("collectors");
                  if (
                    isRepeatedValue(
                      coll.toUpperCase(),
                      id.map(({ id }) => id.toUpperCase())
                    )
                  )
                    return "El recaudador ya ha sido seleccionado."; // FIXME: traduccion
  
                  return true;
                },
              }, // FIXME: traduccion
            }}
            readOnly={readOnly}
            column={[
              {
                key: "name",
                text: dataElementsDescriptions["REVENUE_COLLECTOR"],
              },
              {
                key: "description",
                text: dataElementsDescriptions["DESCRIPTION"],
              },
            ]}
            displayColumn="name"
            options={revenueCollectors.map(mapDataElementToOption)}
          />
        </KeyInputWrapper>
      </TableCell>
      <TableCell component="th" scope="row">
        <TextFieldController
          inputProps={{
            disabled: true,
            value: selectedCollectors?.translation.description ?? "",
          }}
          label={dataElementsDescriptions["DESCRIPTION"]}
          controllerProps={{
            name: `collectors.${index}._collDesc` as "collectors.0._collDesc",
            control,
          }}
        />
      </TableCell>
      <TableCell>
        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});

const mapDataElementToOption = (
  dto: NonNullable<GetRevenueCollectorsForBusinessUnitsFormQuery["revenueCollectors"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.translation.description,
});
