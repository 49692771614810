import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { SuccessMessage } from "components/feedback/SuccessMessage";
import { SaveCloseButtons } from "components/inputs/SaveCloseButtons";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { FormStepper } from "components/navigation/FormStepper";
import { useAppSectionsTranslations } from "hooks/useAppSectionsTranslations";
import { useFormSteps } from "hooks/useFormSteps";
import { StepBehavior } from "hooks/useFormSteps/hook";
import { useSnackbar } from "hooks/useSnackbarV2";
import { FormProps } from "types/Form";
import { assertUnreachable } from "utils/assert";
import { FormStep } from "utils/FormStep";
import { InvoiceForm } from "./InvoiceForm";
import InvoicePreview from './InvoicePreview/template';
import { PositionsColumnsForm } from "./PositionsForm";
import { SendInvoiceForm } from "./SendInvoiceForm";
import { FormFields, FormStepsKeys, NonFormStepsKeys } from "./types";
import { useForms } from "./useForms";

import {
  Check as CheckIcon,
  ListRounded as ListRoundedIcon, Preview as PreviewIcon, Save as SaveIcon, Send as SendIcon,
  Upload as UploadIcon
} from "@mui/icons-material";
import { InvoicesRoutesState } from "apps/InvoicesPage/routes";
import { AlertUnsavedDataStateInput } from "components/feedback/AlertUnsavedDataStateInput";
import { useLocationState } from "utils/typedRoutesUtils";
import { InvoiceFileForm } from "./FileForm";

const Form = ({
  initialValue,
  initialStep,
  mode,
  errorMessage = "", // FIXME: Agregar detalle del error
  onSave = assertUnreachable, // FIXME:
  onCancel = assertUnreachable, // FIXME:
  onEdit,
}: FormProps<FormFields>) => {

  const readOnly = mode === "view";
  const noLinearStepper = mode ==="view" || mode ==="edit";
  
  const state = useLocationState<InvoicesRoutesState>();
  
  const {
    appSectionsTranslations,
    isLoadingSections,
  } = useAppSectionsTranslations("INVOICES");

  const forms = useForms(initialValue);
  const errorSnackbar = useSnackbar();

  const toBack = state!.toBack;
  
  const stepsKeysInOrder: StepBehavior<FormStepsKeys, NonFormStepsKeys>[] = [
    { key: "invoice", hasForm: true },
    { key: "positions", hasForm: true },
    { key: "file", hasForm: true, isHidden: readOnly },
    { key: "preview"},
    { key: "send", hasForm: true, isHidden: readOnly },
    { key: "save", isHidden: readOnly  },
    { key: "success", isHidden: readOnly },
  ];

  const stepsNavigation = useFormSteps({
    forms,
    initialStep,
    errorSnackbar,
    errorMessage,
    stepsKeysInOrder,
    readOnly,
    onSave,
    onEdit,
  });

  const unorderedSteps: Record<FormStepsKeys | NonFormStepsKeys, FormStep> = {
    invoice: {
      ...appSectionsTranslations("BASIC_DATA"),
      icon: <ListRoundedIcon />,
      body: (
        <InvoiceForm
          form={forms}
          mode={mode}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    positions: {
      ...appSectionsTranslations("ITEMS"),
      icon: <ListRoundedIcon />,
      body: (
        <PositionsColumnsForm
          form={forms.positions}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    file: {
      ...appSectionsTranslations("UPLOAD"),
      icon: <UploadIcon />,
      body: (
        <InvoiceFileForm
          form={forms}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    preview: {
      ...appSectionsTranslations("PREVIEW"),
      icon: <PreviewIcon />,
      body: (
        <InvoicePreview
          forms={forms}
          readOnly={readOnly}
        />
      ),
    },
    send: {
      ...appSectionsTranslations("SEND"),
      icon: <SendIcon />,
      body: (
        <SendInvoiceForm
          form={forms}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    save: {
      ...appSectionsTranslations("SAVE"),
      icon: <SaveIcon />,
      isSaveButtonVisible: true
    },
    success: {
      ...appSectionsTranslations("END"),
      icon: <CheckIcon />,
      body: <SuccessMessage />,
      isHeaderHidden: true,
    },
  };

  const currentStep = unorderedSteps[stepsNavigation.activeStepKey];

  const visibleSteps = stepsNavigation.visibleStepsKeysInOrder.map(
    (key) => unorderedSteps[key]
  );

  if (isLoadingSections) return <BeatLoadingPage />;

  return (
    <>
      <AlertUnsavedDataStateInput
        isBlocked={stepsNavigation.isRouteBlocked}
        unblock={stepsNavigation.unblockRoute}
        toBack={toBack}
        state={state}
      />

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <FormStepper
        steps={visibleSteps}
        activeStepIndex={stepsNavigation.stepper.activeStepIndex}
        isNonLinear={noLinearStepper}
        onChangeActiveStepIndex={stepsNavigation.stepper.setActiveStepIndex}
      />

      {!currentStep.isHeaderHidden && (
        <FormHeader
          title={currentStep.title}
          subtitle={currentStep.subtitle}
          step={currentStep.step}
          leftButtonText={currentStep.leftButtonText}
          isPreviousButtonVisible={stepsNavigation.isPreviousButtonVisible}
          isNextButtonVisible={stepsNavigation.isNextButtonVisible}
          isUpButtonVisible={stepsNavigation.isUpButtonVisible}
          onPrevious={stepsNavigation.onPrevious}
          onNext={stepsNavigation.onNext}
          onUp={stepsNavigation.onUp}
        />
      )}

      <FormBodyWrapper>{currentStep.body}</FormBodyWrapper>

      {!readOnly && (
        <SaveCloseButtons
          isCancelButtonVisible
          isSaveButtonVisible={currentStep.isSaveButtonVisible ?? false} // FIXME:
          onCancel={onCancel}
          onSave={stepsNavigation.onClickSave}
        />
      )}
    </>
  );
};

export default Form;
