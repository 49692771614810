import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const descriptions = useForm<FormFields["descriptions"]>({
    defaultValues: initialValue?.["descriptions"],
  });

  const primaryColor = useForm<FormFields["primaryColor"]>({
    defaultValues: initialValue?.["primaryColor"],
  });

  const appsColor = useForm<FormFields["appsColor"]>({
    defaultValues: initialValue?.["appsColor"],
  });

  const stepperColor = useForm<FormFields["stepperColor"]>({
    defaultValues: initialValue?.["stepperColor"],
  });

  const backgroundImage = useForm<FormFields["backgroundImage"]>({
    defaultValues: initialValue?.["backgroundImage"],
  });

  backgroundImage.register("backgroundImage");

  const companyLogo = useForm<FormFields["companyLogo"]>({
    defaultValues: initialValue?.["companyLogo"],
  });

  companyLogo.register("companyLogo");

  return {
    basicData,
    descriptions,
    primaryColor,
    appsColor,
    stepperColor,
    backgroundImage,
    companyLogo
  };
};
