import { DevTool } from "@hookform/devtools";
import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { useGetTilesGroupsForCreateBusinessProfileQuery } from "__generated__/graphql/types";
import { Header } from "./Header";
import { Row } from "./Row";
import {
  CharacteristicsColumnsTableFormProps,
  GroupRow,
  GroupsFormFields,
} from "./types";

export const emptyGroupRow: GroupRow = {
  groupId: "",
};

const CharacteristicsColumnsForm = ({
  form,
  readOnly = false,
  onEdit,
}: CharacteristicsColumnsTableFormProps) => {
  const { control } = form;

  const { language } = useSystemLanguage();

  const {
    data: { groups } = {},
  } = useGetTilesGroupsForCreateBusinessProfileQuery({
    language,
  });

  const groupsByIdMap =
    groups !== undefined
      ? new Map(groups.map((group) => [group.id, group]))
      : undefined;

  const { fields, append, remove } = useFieldArray<GroupsFormFields>({
    control,
    name: "groups",
  });

  const onAppendRow = () => {
    append(emptyGroupRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  if (groupsByIdMap === undefined) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <TableContainer>
        <StyledTable aria-label="simple table" size="small">
          <Header
            onAppendRow={onAppendRow}
            onEdit={onEdit}
            readOnly={readOnly}
          />

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                key={item.id}
                index={index}
                remove={onRemove(index)}
                item={item}
                readOnly={readOnly}
                groupsByIdMap={groupsByIdMap}
                form={form}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

export default CharacteristicsColumnsForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
