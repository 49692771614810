import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { SelectController } from "components/inputs/SelectController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  TableExistsDocument,
  TableExistsQuery,
  TableExistsQueryVariables,
} from "__generated__/graphql/types";
import { BasicDataTableFormProps } from "./types";

const BasicDataForm = ({ form, mode, onEdit }: BasicDataTableFormProps) => {
  const { control } = form;

  const readOnly = mode === "view";
  const idIdReadOnly = mode !== "create";

  const { dataElementsDescriptions } = useDataElementDescription([
    "TABLE",
    "TABLE_TYPE",
  ]);

  const { dataElementsChoices } = useDataElementChoices(["TABLE_TYPE"]);

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner
  if (dataElementsChoices === undefined) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <KeyInputWrapper>
              <TextFieldController
                label={dataElementsDescriptions["TABLE"]}
                inputProps={{ disabled: idIdReadOnly }}
                controllerProps={{
                  name: "name",
                  control,
                  rules: {
                    required: "Ingrese el nombre de la tabla.", // FIXME: traduccion
                    validate: async (value) => {
                      if (idIdReadOnly) return true;
                      const { tableExists } = await tableExistsFn({
                        tableId: value,
                      });
                      return tableExists
                        ? "El nombre ya existe." // FIXME: traduccion
                        : true;
                    },
                    pattern: {
                      value: keyNameInputRegexPattern,
                      message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                    },
                  },
                }}
              />
            </KeyInputWrapper>
          </Grid>

          <Grid item xs={3}>
            <SelectController
              inputProps={{ disabled: readOnly }}
              label={dataElementsDescriptions["TABLE_TYPE"]}
              select
              controllerProps={{
                name: "tableType",
                control,
                rules: { required: "Seleccione el tipo de tabla." }, // FIXME: traduccion
              }}
              options={dataElementsChoices["TABLE_TYPE"]}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BasicDataForm;

const tableExistsFn = pDebounce(
  (args: TableExistsQueryVariables) =>
    fetcher<TableExistsQuery, TableExistsQueryVariables>(
      TableExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
