import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { BusinessObjectsFormFields } from "./BusinessObjectsForm/types";

export const useBusinessObjForm = (
  actionsForm: UseFormReturn<BusinessObjectsFormFields>
) => {

  const [index, setIndex] = useState<number>();
  const [name, setName] = useState<string>();
  const [areBusinessObjActionsVisible, setAreBusinessObjActionsVisible] = useState(false);

  const onEnterBusinessObjActions = async (index: number) => {
    const name = actionsForm.getValues(
      `businessObjects.${index}.id` as "businessObjects.0.id"
    );
    setName(name);
    setIndex(index);
    setAreBusinessObjActionsVisible(true);
  };

  const exitBusinessObjActions = () => {
    setName(undefined);
    setAreBusinessObjActionsVisible(false);
  };

  const onExitBusinessObjActions = actionsForm.handleSubmit(() => {
    exitBusinessObjActions();
  });

  const onClickPageButton = () => {
    exitBusinessObjActions();
  };

  return {
    onEnterBusinessObjActions,
    onExitBusinessObjActions,
    areBusinessObjActionsVisible,
    onClickPageButton,
    name,
    index
  };
};
