import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback, useEffect, useReducer } from "react";

type UseStepperReturn = {
  activeStepIndex: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  hasStepChanged: boolean;
  isLastStepMinus: (steps: number) => boolean;
  onPrevious: () => void;
  onNext: () => void;
  setActiveStepIndex: (step: number) => void;
};

const useStepper = (
  totalSteps: number,
  initialStep: number = 0
): UseStepperReturn => {
  const [state, dispatch] = useReducer(
    stepperSlice.reducer,
    { initialStep, totalSteps },
    initReducer
  );

  const { activeStepIndex, hasStepChanged, firstStep, lastStep } = state;

  const isFirstStep = activeStepIndex === firstStep;
  const isLastStep = activeStepIndex === lastStep;

  const isLastStepMinus = useCallback(
    (steps: number) => {
      return activeStepIndex === lastStep - steps;
    },
    [activeStepIndex, lastStep]
  );

  const onPrevious = useCallback(() => {
    dispatch(stepperSlice.actions.increaseStep(-1));
  }, []);

  const onNext = useCallback(() => {
    dispatch(stepperSlice.actions.increaseStep(+1));
  }, []);

  const setActiveStepIndex = useCallback((step: number) => {
    dispatch(stepperSlice.actions.setActiveStep(step));
  }, []);

  useEffect(() => {
    dispatch(stepperSlice.actions.setTotalSteps(totalSteps));
  }, [totalSteps]);

  return {
    activeStepIndex,
    isFirstStep,
    isLastStep,
    hasStepChanged,
    isLastStepMinus,
    onPrevious,
    onNext,
    setActiveStepIndex,
  };
};

export default useStepper;

const isValidStep = ({
  step,
  firstStep,
  lastStep,
}: {
  step: number;
  firstStep: number;
  lastStep: number;
}) => (step < firstStep ? false : step > lastStep ? false : true);

const initReducer = ({
  initialStep = 0,
  totalSteps,
}: {
  initialStep?: number;
  totalSteps: number;
}): StepperState => {
  const firstStep = 0;
  const lastStep = totalSteps - 1;
  if (lastStep < 0) throw Error("'lastStep' cannot be a negative number.");

  return {
    hasStepChanged: false,
    activeStepIndex: isValidStep({ step: initialStep, firstStep, lastStep })
      ? initialStep
      : firstStep,
    firstStep,
    lastStep,
  };
};

type StepperState = {
  activeStepIndex: number;
  hasStepChanged: boolean;
  readonly firstStep: 0;
  lastStep: number;
};

const stepperSlice = createSlice({
  name: "stepperSlice",
  initialState: {} as StepperState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      const newStep = state.activeStepIndex;
      if (!isValidStep({ ...state, step: newStep })) return;
      state.hasStepChanged = true;
      state.activeStepIndex = action.payload;
    },
    increaseStep: (state, action: PayloadAction<number>) => {
      const delta = action.payload;
      const newStep = state.activeStepIndex + delta;
      if (!isValidStep({ ...state, step: newStep })) return;
      state.hasStepChanged = true;
      state.activeStepIndex = newStep;
    },
    setTotalSteps: (state, action: PayloadAction<number>) => {
      const lastStep = action.payload - 1;
      if (lastStep === state.lastStep) return;
      if (lastStep < state.activeStepIndex)
        throw Error("'activeStep' cannot be greater than 'lastStep'.");
      state.hasStepChanged = true;
      state.lastStep = lastStep;
    },
  },
});
