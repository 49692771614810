export enum InvoicesOverviewRoutes {

  PATH = "/",
  PATH_INVOICES_VIEW = "view",
}

export type InvoicesOverviewRoutesState = {
  activeStep: number;
  purchaseOrderId: string;
  purchaseOrderItemsIds: string[];
  invoiceNumber: string;
  toBack: string;
};