import CreateInvoicePage from "apps/InvoicesPage/CreatePage/template";
import { EditInvoicePage } from "apps/InvoicesPage/EditPage";
import { ViewAllInvoicesPage } from "apps/InvoicesPage/ViewAllPage";
import { ViewInvoicePage } from "apps/InvoicesPage/ViewPage";
import ViewPurchaseOrdersItemsPage from "apps/PurchaseOrdersItemsPage/ViewPage/template";
import { Route, Routes } from "react-router-dom";
import { PurchaseOrdersRoutes } from "./routes";
import { ViewAllPage } from "./ViewAllPage";

const PurchaseOrdersPage = () => {
  return (
    <Routes>
      <Route
        path={PurchaseOrdersRoutes.PATH}
        element={<ViewAllPage />}
      />
      <Route 
        path={PurchaseOrdersRoutes.PATH_ITEMS} 
        element={<ViewPurchaseOrdersItemsPage />} 
      />
      <Route 
        path={PurchaseOrdersRoutes.PATH_INVOICES} 
        element={<ViewAllInvoicesPage />} 
      />
      <Route 
        path={PurchaseOrdersRoutes.PATH_INVOICES_VIEW} 
        element={<ViewInvoicePage />} 
      />
      <Route 
        path={PurchaseOrdersRoutes.PATH_INVOICES_EDIT} 
        element={<EditInvoicePage />} 
      />
      <Route 
        path={PurchaseOrdersRoutes.PATH_INVOICES_CREATE} 
        element={<CreateInvoicePage />} 
      />
    </Routes>
  );
};

export default PurchaseOrdersPage;
