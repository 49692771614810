import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  ArrowDownward as ArrowDownwardIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import { useTranslation } from "hooks/useTranslation";
import React from "react";
import { Controller } from "react-hook-form";
import {
  containsDefaultLanguage,
  containsTranslations,
  containsUniqueLanguages,
  translationsFieldsAreNotEmpty,
} from "utils/translations";
import { RowProps } from "./types";

const Row = ({
  index,
  remove,
  item,
  readOnly,
  onEnterDescriptions,
  form,
}: RowProps) => {
  const { control, trigger, watch } = form;

  const descriptions = watch(
    `regions.${index}.descriptions` as "regions.0.descriptions"
  );

  const description = useTranslation(descriptions);

  const onRemoveRow = () => {
    remove();
    trigger();
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `regions.${index}.name` as "regions.0.name",
            control,
            rules: { required: "Ingrese el nombre." }, // FIXME: traduccion
            defaultValue: item.name,
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`regions.${index}._virtualField` as "regions.0._virtualField"}
          rules={{
            validate: () => {
              const translations = form.getValues(
                `regions.${index}.descriptions` as "regions.0.descriptions"
              );

              if (!containsTranslations(translations))
                return "Ingrese las traducciones."; // FIXME: traduccion

              if (!containsDefaultLanguage(translations))
                return "Debe al menos incluir idioma default (EN)"; // FIXME: traduccion

              if (!containsUniqueLanguages(translations))
                return "Lenguajes repetidos."; // FIXME: traduccion

              if (
                !translationsFieldsAreNotEmpty(translations, [
                  "language",
                  "text",
                ])
              )
                return "Error de validación. Ingrese para ver más detalles."; // FIXME: traduccion

              return true;
            },
          }}
          render={({ fieldState: { invalid, error } }) => (
            <FormTableTextField
              value={description?.text}
              helperText={error?.message}
              error={invalid}
              inputProps={{ disabled: readOnly }}
            />
          )}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip
          title="Agregar descripciones" // FIXME: traduccion
        >
          <StyledIconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={() => onEnterDescriptions(index)}
          >
            <ArrowDownwardIcon />
          </StyledIconButton>
        </Tooltip>

        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton aria-label="remove" size="small" onClick={onRemoveRow}>
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});

// FIXME: commons
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
