export enum DataElementsRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":dataElementId/view",
  EDIT = ":dataElementId/edit",
}

export type DataElementsRoutesState = {
  [DataElementsRoutes.EDIT]: { activeStep?: number };
};
