import { groupBy } from "ramda";
import { GetBusinessProfileForViewPageQuery } from "__generated__/graphql/types";
import { PermissionsFormFields } from "../CreatePage/Form/PermissionsForm/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (dto: BusinessProfileDto): FormFields => {
  return {
    basicData: {
      name: dto.id,
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
    groups: {
      groups: dto.groups.map((e) => ({ groupId: e.id })),
    },
    permissions: { apps: mapAppsActionsToFormPermissions(dto.appsActions) },
  };
};

export const mapAppsActionsToFormPermissions = (
  appsActions: AppActionDto[]
): PermissionsFormFields["apps"] => {
  return Object.entries(groupAppActionsByAppId(appsActions)).reduce(
    (appAcc, [appId, actions]) => ({
      ...appAcc,
      [appId]: {
        actions: actions.reduce(
          (actionAcc, { id: appActionId }) => ({
            ...actionAcc,
            [appActionId]: {
              isAllowed: true,
            },
          }),
          {}
        ),
      },
    }),
    {}
  );
};

const groupAppActionsByAppId = groupBy<
  NonNullable<
    NonNullable<
      GetBusinessProfileForViewPageQuery["businessProfile"]
    >["appsActions"]
  >[number]
>((e) => e.app.id);

type BusinessProfileDto = NonNullable<
  NonNullable<GetBusinessProfileForViewPageQuery["businessProfile"]>
>;

type AppActionDto = NonNullable<BusinessProfileDto["appsActions"][number]>;
