export enum ImportMigrationPackageRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":packageMigrationId/view",
  EDIT = ":packageMigrationId/edit",
}

export type ImportMigrationPackageRoutesState = {
  [ImportMigrationPackageRoutes.EDIT]: { activeStep?: number };
};
