import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useFieldArray } from "react-hook-form";
import { useGetBusinessObjectsForCreateDataMigrationsQuery } from "__generated__/graphql/types";
import { BusinessObjectRow, BusinessObjectsFormFields } from "../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { BusinessObjFormProps } from "./types";

export const emptyBusinessObjRow: BusinessObjectRow = {
  id: "",
  idsBusinessObject: {}
};

const BusinessObjForm = ({
  readOnly = false,
  onEdit,
  onEnterBusinessActions,
  form,
}: BusinessObjFormProps) => {
  
  const { control } = form;

  const {
    data: { businessObjects } = {},
  } = useGetBusinessObjectsForCreateDataMigrationsQuery();

  const businessObjectsByIdMap =
  businessObjects !== undefined
      ? new Map(businessObjects.map((businessObject) => [businessObject.id, businessObject]))
      : undefined;


  const { fields, append, remove} = useFieldArray<BusinessObjectsFormFields>({
    control,
    name: "businessObjects",
  });

  const onAppendRow = () => {
    append(emptyBusinessObjRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  if (businessObjectsByIdMap === undefined) return <BeatLoadingPage />;

  return (

    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header
          onAppend={() => onAppendRow()}
          onEdit={() => onEdit?.()}
          readOnly={readOnly}
        />

        <StyledTableBody>
          {fields.map((item, index) => (
            <Row
              key={item.id}
              index={index}
              remove={onRemove(index)}
              item={item}
              readOnly={readOnly}
              onEnterBusinessActions={onEnterBusinessActions}
              form={form}
              businessObjectsByIdMap={businessObjectsByIdMap}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default BusinessObjForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
