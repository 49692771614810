import { URL_BASE } from "apis/const";
import axios from "axiosAuth";
import { useMutation } from "react-query";
import { setSessionTokenHeader } from "__dev__/auth";
import { useGetAmIAuthenticatedQuery } from "__generated__/graphql/types";

const useLogMeIn = () => {
  const { refetch: refetchAmIAuthenticated } = useGetAmIAuthenticatedQuery();

  return useMutation(
    ({ username, password }: { username: string; password: string }) => {
      const formData = new FormData();
      formData.set("username", username);
      formData.set("password", password);
      return axios.request({
        url: URL_BASE + "/api/auth/login",
        method: "POST",
        xsrfHeaderName: "X-CSRF-TOKEN",
        data: formData,
      });
    },
    {
      onSuccess: (data) => {
        setSessionTokenHeader(data.headers); // FIXME: solo para ambiente de dev
        refetchAmIAuthenticated();
      },
    }
  );
};

export default useLogMeIn;
