import { DevTool } from "@hookform/devtools";
import { TableCollapse } from "components/datadisplay/TableCollapse";
import { generateFiltersElements } from "components/datadisplay/TableCollapse/utils";
import { CheckboxFilter } from "components/filters/CheckboxFilter";
import { NumberFilter } from "components/filters/NumberFilter";
import { TextFilter } from "components/filters/TextFilter";
import { FilterComponentProps } from "components/filters/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useDialog } from "hooks/useDialog";
import { DataElementsRoutes } from "../../../routes";
import React, { ComponentType, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generatePath } from "utils/typedRoutesUtils";
import { dataElementsColumnsLabels } from "../../reducers/visibleColumnsSlice";
import {
  DataElementsFilters,
  DataElementsFiltersKeys,
  selectDataElementsFilters,
  selectDataElementsHiddenFiltersKeys,
  selectDataElementsVisibleFiltersKeys,
  setDataElementsFilters,
} from "../../reducers/filtersAndPaginationSlice";
import { DataElementsColumnsVisibilityDialog } from "./DataElementsColumnsVisibilityDialog";
import { DataElementsFiltersVisibilityDialog } from "./DataElementsFiltersVisibilityDialog";

const Collapse = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const filtersDialog = useDialog();

  const columnsDialog = useDialog();

  const filters = useAppSelector(selectDataElementsFilters);

  const visibleFiltersKeys = useAppSelector(
    selectDataElementsVisibleFiltersKeys
  );
  const hiddenFiltersKeys = useAppSelector(selectDataElementsHiddenFiltersKeys);

  const { control, handleSubmit, unregister } = useForm<DataElementsFilters>({
    defaultValues: filters,
  });

  const visibleFilters = generateFiltersElements({
    visibleFiltersKeys,
    filtersComponents,
    filtersLabels: dataElementsColumnsLabels,
    control,
  });

  const onCreate = () => {
    navigate(generatePath(DataElementsRoutes.CREATE));
  };

  const onSearch = handleSubmit((form) => {
    dispatch(setDataElementsFilters(form));
  });

  useEffect(() => {
    unregister(hiddenFiltersKeys, { keepDefaultValue: true });
  }, [hiddenFiltersKeys, unregister]);

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <DataElementsFiltersVisibilityDialog
        open={filtersDialog.isOpen}
        handleClose={filtersDialog.close}
      />

      <DataElementsColumnsVisibilityDialog
        open={columnsDialog.isOpen}
        handleClose={columnsDialog.close}
      />

      <TableCollapse
        filters={visibleFilters}
        onColumns={columnsDialog.open}
        onCreate={onCreate}
        onDownload={() => {}}
        onExpand={() => {}}
        onFilters={filtersDialog.open}
        onSearch={onSearch}
      />
    </>
  );
};

export default Collapse;

const filtersComponents: Record<
  DataElementsFiltersKeys,
  ComponentType<
    FilterComponentProps<DataElementsFilters, DataElementsFiltersKeys>
  >
> = {
  name: TextFilter,
  used: CheckboxFilter,
  dataType: TextFilter,
  status: TextFilter,
  length: NumberFilter,
  decimals: NumberFilter,
  isLanguageDependent: CheckboxFilter,
  description: TextFilter,
};
