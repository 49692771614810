import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { notNullUndefined } from '../../../../utils/notNullUndefined';

export type InvoiceCheckedRowsType = {
  id: string; 
  purchaseOrder: string;
  item: string;
  checked: boolean
}
 
type CheckedRowsState = {
  areAllRowsChecked: boolean;
  individualRowsChecked: InvoiceCheckedRowsType[];
};

const initialState: CheckedRowsState = {
  areAllRowsChecked: false,
  individualRowsChecked: [],
};

const checkedRowsSlice = createSlice({
  name: "invoicesCheckedRowsSlice",
  initialState,
  reducers: {
    checkAllRows: (state: any, action: PayloadAction<boolean>) => {
      const checked = action.payload;
      if (checked) {
        state.individualRowsChecked = [];
      }
      state.areAllRowsChecked = checked;
    },
    checkRow: (
      state: CheckedRowsState,
      action: PayloadAction<{ id: string; purchaseOrder: string, item: string, checked: boolean }>
    ) => {
      const { id, purchaseOrder, item, checked } = action.payload;
      const row = state.individualRowsChecked.find(e => e.id === id);

      if(notNullUndefined(row)) {
        row.checked = checked;
      } else {
        state.individualRowsChecked.push({
          id: id,
          purchaseOrder: purchaseOrder,
          item: item,
          checked: checked
        })
      }
    },
  },
});

export const { checkRow, checkAllRows } = checkedRowsSlice.actions;

export const selectIsRowChecked = (state: RootState) => (rowKey: string) => {
  if(state.invoices.rowsChecked.areAllRowsChecked) return true;
  return state.invoices.rowsChecked.individualRowsChecked
    .find(e => e.id === rowKey)?.checked ?? false;
}

// FIXME: Si se seleccionan todas las columnas el filtro no funciona
export const selectAreAllRowsChecked = (state: RootState) =>
  state.invoices.rowsChecked.areAllRowsChecked;

export const selectRowsChecked = (state: RootState): InvoiceCheckedRowsType[] => {
  return state.invoices.rowsChecked.individualRowsChecked
    .filter(e => e.checked === true)
}

export default checkedRowsSlice.reducer;
