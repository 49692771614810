import { GetBusinessUnitViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetBusinessUnitViewPageQuery["businessUnit"]>
): FormFields => {
  return {
    basicData: {
      companyId: dto.company.id,
      type: dto.businessUnitCategory,
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      }))
    },
    revenueData: {
      collectors: dto.revenueCollectors?.map((e) => ({
        id: e.id
      })) ?? []
    },
    costData: {
      collectors: dto.costCollectors?.map((e) => ({
        id: e.id
      })) ?? []
    } 
  };
};
