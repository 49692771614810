import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";

export type AddressDataFormFields = {
  street: string;
  number: string;
  floor: string;
  department: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
};

export type AddressDataFormProps = {
  form: UseFormReturn<AddressDataFormFields>;
  mode: FormMode;
  isUsed?: boolean;
  onEdit?: () => void;
};

const AddressDataForm = ({
  form,
  mode,
  isUsed,
  onEdit,
}: AddressDataFormProps) => {
  const { control } = form;

  const disabled = mode === "view" || (mode === "edit" && isUsed);

  const { dataElementsDescriptions } = useDataElementDescription([
    "STREET",
    "NUMBER",
    "FLOOR",
    "DEPARTMENT",
    "CITY",
    "POSTAL_CODE",
    "STATE",
    "COUNTRIES",
  ]);
  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["STREET"]}
              controllerProps={{
                name: "street",
                control,
                rules: {
                  required: "Ingrese calle.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["NUMBER"]}
              controllerProps={{
                name: "number",
                control,
                rules: {
                  required: "Ingrese el numero de casa.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["FLOOR"]}
              controllerProps={{
                name: "floor",
                control,
                rules: {
                  required: "Ingrese el piso.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["DEPARTMENT"]}
              controllerProps={{
                name: "department",
                control,
                rules: {
                  required: "Ingrese el departamento.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["CITY"]}
              controllerProps={{
                name: "city",
                control,
                rules: {
                  required: "Ingrese la ciudad.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["POSTAL_CODE"]}
              controllerProps={{
                name: "postalCode",
                control,
                rules: {
                  required: "Ingrese el cp.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["STATE"]}
              controllerProps={{
                name: "state",
                control,
                rules: {
                  required: "Ingrese el estado.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: disabled }}
              label={dataElementsDescriptions["COUNTRIES"]}
              controllerProps={{
                name: "country",
                control,
                rules: {
                  required: "Ingrese el pais.", // FIXME: traduccion
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default AddressDataForm;
