import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { SelectController } from "components/inputs/SelectController";
import { useDataElementChoices } from "hooks/useDataElementChoices";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import React from "react";
import { BasicDataFormProps } from "./types";
import {
  GetCompaniesForBusinessUnitFormQuery,
  useGetCompaniesForBusinessUnitFormQuery
} from "__generated__/graphql/types";

const BasicDataForm = ({
  form: { control, watch },
  mode,
  onEdit,
}: BasicDataFormProps) => {
  const readOnly = mode === "view";

  const { dataElementsChoices } = useDataElementChoices(["BUSINESS_UNIT_CATEGORY"]);
  const { dataElementsDescriptions } = useDataElementDescription([
    "COMPANY",
    "BUSINESS_UNIT_CATEGORY",
    "DESCRIPTION"
  ]);
  const { data: { companies } = {} } = useGetCompaniesForBusinessUnitFormQuery({});

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner
  if (dataElementsChoices === undefined) return null; // TODO: loading spinner
  if (companies === undefined) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool control={control} placement="top-left" />
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <MatchcodeInputController
            label={dataElementsDescriptions["COMPANY"]}
            controllerProps={{
              name: "companyId",
              control,
              rules: {
                required: "Seleccione la compania",
                // validate: (back) => {
                //   if (readOnly) return true;
                //   const id = getValues("backends");
                //   if (
                //     isRepeatedValue(
                //       back.toUpperCase(),
                //       id.map(({ idBackend }) => idBackend.toUpperCase())
                //     )
                //   )
                //     return "El backend ya ha sido seleccionado."; // FIXME: traduccion
  
                //   return true;
                // },
              }, // FIXME: traduccion
            }}
            readOnly={readOnly}
            column={[
              {
                key: "name",
                text: dataElementsDescriptions["COMPANY"],
              },
              {
                key: "description",
                text: dataElementsDescriptions["DESCRIPTION"],
              },
            ]}
            displayColumn="name"
            options={companies.map(mapDataElementToOption)}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectController
            inputProps={{ disabled: readOnly }}
            label={dataElementsDescriptions["BUSINESS_UNIT_CATEGORY"]}
            select
            controllerProps={{
              name: "type",
              control,
              rules: { required: "Seleccione la categoria de unidad comercial." }, // FIXME: traduccion
            }}
            options={dataElementsChoices["BUSINESS_UNIT_CATEGORY"]}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const mapDataElementToOption = (
  dto: NonNullable<GetCompaniesForBusinessUnitFormQuery["companies"]>[number]
) => ({
  id: dto.id,
  name: dto.id.toUpperCase(),
  description: dto.name,
});
