import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notNullUndefined } from "utils/notNullUndefined";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetThemeByIdQuery,
  useUpdateThemeMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreateThemePage/Form";
import { FormFields } from "../CreateThemePage/Form/types";
import { useUploadBackgroundImageToS3 } from "../CreateThemePage/useUploadBackgroundImageS3";
import { mapFormToDto } from "../CreateThemePage/utils";
import { ThemeRoutes, ThemeRoutesState } from "../routes";
import { mapDtoToForm } from "./utils";
import useAppMessages from 'hooks/useAppMessages/hook';

type Route = ThemeRoutes.EDIT;
type LocationState = ThemeRoutesState[Route];

const EditThemePage = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<FormFields>();
  const { appMessage } = useAppMessages("THEMES");

  const { themeId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};

  const { data: { theme } = {}, isFetchedAfterMount } = useGetThemeByIdQuery({
    name: themeId,
  });

  const { mutateAsync: updateTheme } = useUpdateThemeMutation();

  const {
    mutateAsync: uploadBackgroundImageFile,
  } = useUploadBackgroundImageToS3();

  const onSave = async (formFields: FormFields) => {
    
    const backgroundImageId = await uploadBackgroundImageFile(
      formFields.backgroundImage.backgroundImage
    );
    const companyLogoId = notNullUndefined(formFields.companyLogo.companyLogo) 
      ? await uploadBackgroundImageFile(formFields.companyLogo.companyLogo)
      : null;

    const themeUpdated = mapFormToDto(formFields, backgroundImageId.fileId, companyLogoId?.fileId);
    await updateTheme({ id: themeId, theme: themeUpdated });
  };

  useEffect(() => {
    if (theme == null) return;
    if (!isFetchedAfterMount) return;
    mapDtoToForm({ theme }).then((initialValues) =>
      setInitialValues(initialValues)
    );
  }, [theme, setInitialValues, isFetchedAfterMount]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title={appMessage["EDIT_THEME"]}
        toBack={TO_BACK_ROUTER}
      />

      <Form
        initialValue={initialValues}
        errorMessage={appMessage["EDIT_THEME"] ?? "An error has been occurred while updating the theme. Please try again"}///FIXPRD QUITAR
        onSave={onSave}
        mode="edit"
        onCancel={() => navigate(TO_BACK_ROUTER)}
        initialStep={activeStep}
      />
    </Layout>
  );
};

export default EditThemePage;

const TO_BACK_ROUTER = "../../";
