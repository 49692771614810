import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon, LockOutlined as LockIcon, VpnKey as PassIcon, Search as SearchIcon, LockOpenOutlined as UnlockIcon
} from "@mui/icons-material";
import { useDeleteUserMutation, useGetUsersForTablePageQuery, useLockUserMutation } from "__generated__/graphql/types";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useSnackbar } from "hooks/useSnackbarV2";
import { UseSnackbarReturn } from "hooks/useSnackbarV2/hook";
import React, { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { notNullUndefined } from "utils/notNullUndefined";
import useAllowedUserAppActions from '../../../hooks/useAlloweduserAppActions/template';
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { UsersRoutes } from "../routes";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { resetPage, selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useViewAllPage = () => {

  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState('');

  const handleClickOpen = (id: string) => {
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { appMessage } = useAppMessages("USERS");
  const { appMessage: appMessages } = useAppMessages("MESSAGES");

  const { actions } = useAllowedUserAppActions("USERS");

  const errorSnackbar = useSnackbar();
  const rowsChecked = useAppSelector(selectRowsChecked);
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);

  const {
    data: { users, usersCount } = {},
    refetch: findApps,
  } = useGetUsersForTablePageQuery({
    pagination, // TODO: filtros
    filters: {
      id: filters.username !== undefined ? filters.username : undefined, // FIXME: list
      firstName: filters.firstName !== undefined ? filters.firstName : undefined, // FIXME: list
      lastName: filters.lastName !== undefined ? filters.lastName : undefined, // FIXME: list
      email: filters.email !== undefined ? filters.email : undefined, // FIXME: list
      userType: filters.userType !== undefined ? filters.userType : undefined, // FIXME: list
      businessProfile: filters.businessProfile !== undefined ? filters.businessProfile : undefined, // FIXME: list
    },
  });

  const { mutateAsync: deleteUser } = useDeleteUserMutation();
  const { mutateAsync: lockUser } = useLockUserMutation();

  const rows = useMemo(() => users?.map(dto => {
    const res: TableRowType<ColumnsKeys> = {
      rowKey: dto!.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      username: dto.id,
      email: dto.email,
      userType: dto.userType,
      businessProfile: dto.businessProfile.id,
      isLocked: dto.isLocked ? <LockIcon color="error" /> : <UnlockIcon color="primary" />,
    }
    return res;
  }), [users]);

  const {
    data: { users: exportToExcel} = {},
  } = useGetUsersForTablePageQuery({
    filters: {
      id: filters.username !== undefined ? filters.username : undefined, // FIXME: list
      firstName: filters.firstName !== undefined ? filters.firstName : undefined, // FIXME: list
      lastName: filters.lastName !== undefined ? filters.lastName : undefined, // FIXME: list
      email: filters.email !== undefined ? filters.email : undefined, // FIXME: list
      userType: filters.userType !== undefined ? filters.userType : undefined, // FIXME: list
      businessProfile: filters.businessProfile !== undefined ? filters.businessProfile : undefined, // FIXME: list
    },
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              firstName: dto.firstName,
              lastName: dto.lastName,
              username: dto.id,
              email: dto.email,
              userType: dto.userType,
              businessProfile: dto.businessProfile.id,
              isLocked: dto.isLocked.toString(),
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel]
  );

  const readAction = actions.find(e => e.id === "READ");
  const updateAction = actions.find(e => e.id === "UPDATE");
  const deleteAction = actions.find(e => e.id === "DELETE");
  const lockAction = actions.find(e => e.id === "LOCK");
  const unlockAction = actions.find(e => e.id === "UNLOCK");
  const passAction = actions.find(e => e.id === "PASSWORDS");

  const menuItems = useMemo(
    () => [
      readAction && {
        key: "display",
        Icon: SearchIcon,
        text: readAction.translation.description,
        onClick: (id: string) => {
          if (id === "display") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          navigate(generatePath(UsersRoutes.VIEW, { userId: id }));
        },
      },
      updateAction && {
        key: "edit",
        Icon: EditIcon,
        text: updateAction.translation.description,
        onClick: (id: string) => {
          if (id === "update") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          navigate(generatePath(UsersRoutes.EDIT, { userId: id }));
        },
      },
      deleteAction && {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: deleteAction.translation.description,
        onClick: (id: string) => {
          if (id === "delete") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          deleteUser({ userId: id })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open(appMessage["ERROR_DELETE"]))
        }
      },
      lockAction && {
        key: "lock",
        Icon: LockIcon,
        text: lockAction.translation.description,
        onClick: (id: string) => {
          if (id === "lock") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          lockUser({ userId: id, lock: true })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open(appMessage["ERROR_LOCK"]))
        }
      },
      unlockAction && {
        key: "unlock",
        Icon: UnlockIcon,
        text: unlockAction.translation.description,
        onClick: (id: string) => {
          if (id === "unlock") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          lockUser({ userId: id, lock: false })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open(appMessage["ERROR_UNLOCK"]))
        }
      },
      passAction && {
        key: "passwords",
        Icon: PassIcon,
        text: passAction.translation.description,
        onClick: (id: string) => {
          if (id === "passwords") {
            if (validateSelectRows(rowsChecked, appMessages, errorSnackbar)) return;
            id = rowsChecked.toString()
          }
          handleClickOpen(id);
        }
      },
    ].filter(notNullUndefined), [deleteUser, lockUser, dispatch, errorSnackbar, findApps, navigate,
    appMessage, appMessages, rowsChecked, readAction, updateAction, deleteAction, lockAction, unlockAction, passAction])

  const menuItemsRow = useMemo(() => users?.map(dto => {
    if (dto?.isLocked) {
      return {
        key: dto!.id,
        menuItems: menuItems.filter(e => e.key !== "lock")
      }
    } else {
      return {
        key: dto!.id,
        menuItems: menuItems.filter(e => e.key !== "unlock")
      }
    }
  }), [users, menuItems]);

  const total = usersCount;

  return {
    errorSnackbar,
    appMessage,
    menuItems,
    menuItemsRow,
    rows,
    total,
    pagination,
    sheet,
    userId,
    open,
    handleClose,
  }
}

const validateSelectRows: any = (rowsChecked: string[], appMessages: any, errorSnackbar: UseSnackbarReturn) => {
  if (rowsChecked.length === 0) {
    errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
    return true;
  } else if (rowsChecked.length > 1) {
    errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
    return true;
  }
  return false;
}
