import { CompanyInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): CompanyInput => {
  return {
    id: form.basicData.company,
    name: form.basicData.name,
    currencyId: form.basicData.currency,
    chartOfAccountId: form.basicData.chartOfAccount,
  };
};
