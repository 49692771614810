import styled from "@emotion/styled";
import {
  Search as SearchIcon
} from "@mui/icons-material";
import { Box, Checkbox, Tooltip } from "@mui/material";
import { useGetPurchaseOrdersForReportPageQuery, useGetPurchaseOrdersItemsReportPageQuery } from "__generated__/graphql/types";
import { TrafficLightStatus } from "apps/InvoicesFreightPage/ViewAllPage/components/Table/TrafficLight/template";
import { TrafficLight } from "apps/PurchaseOrdersItemsPage/ViewPage/components/Table/TrafficLight";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppSelector } from "hooks/reduxHooks";
import { useAllowedUserAppActions } from "hooks/useAlloweduserAppActions";
import { useAppMessages } from "hooks/useAppMessages";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { PicConstants } from "utils/constants";
import { notNullUndefined } from "utils/notNullUndefined";
import { PurchaseOrdersItemsOverviewRoutes } from "../routes";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useReportPage = () => {

  const navigate = useNavigate();
  
  const message = useAppMessages("MESSAGES");
  const { appMessage } = useAppMessages("PURCHASE_ORDERS_ITEMS_REPORTS");
  const { actions } = useAllowedUserAppActions("PURCHASE_ORDERS_ITEMS_REPORTS");
  const { formatConfig } = useLanguageAndRegionConfig();
  
  const errorSnackbar = useSnackbar();
  const rowsChecked = useAppSelector(selectRowsChecked);
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);

  const {
    data: { allPurchaseOrderItems, allPurchaseOrderItemsCount: total } = {},
    isFetchedAfterMount
  } = useGetPurchaseOrdersItemsReportPageQuery({
    pagination,
    filters: {
      purchaseOrderItem: filters.purchaseOrderItem,
      productId: filters.productId,
      productDescription: filters.productDescription,
      locationDescription: filters.locationDesc,
      poQuantity: filters.poQuantity,
      unit: filters.unit,
      netUnitPrice: filters.netUnitPrice,
      netTotalPrice: filters.netTotalPrice
    },
  });

  const {
    data: { purchaseOrders } = { }, 
  } = useGetPurchaseOrdersForReportPageQuery(
    {
      filters: {
        ids: allPurchaseOrderItems?.map(poi => poi.purchaseOrder)
      },
    },
    { enabled: isFetchedAfterMount }
  );

  const rows = useMemo(() => allPurchaseOrderItems?.map( dto => {
    const res: TableRowType<ColumnsKeys> = {
      rowKey: JSON.stringify({ 
        item: dto.purchaseOrderItem, 
        purchaseOrder:  purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.id
      }),
      purchaseOrderItem: dto.purchaseOrderItem,
      productId: dto.productId,
      productDescription: dto.productDescription,
      hasInvoice: (
        <Tooltip
          title={dto.hasInvoice.toString()}
          placement="top"
        >
          <Box display="flex" justifyContent="center">
            <StyledCheckbox
              size="small"
              checked={dto.hasInvoice}
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Tooltip>
      ),
      locationDesc: dto.locationDescription,
      unit: dto.unit,
      poQuantity:  formatConfig.numberMapper(dto.poQuantity),
      netUnitPrice: formatConfig.amountDecimalsMapper(dto.netUnitPrice, PicConstants.NET_UNT_PRICE_DECIMALS),
      netTotalPrice: formatConfig.amountMapper(dto.netTotalPrice),
      grQuantity: formatConfig.numberMapper(dto.grQuantity),
      grAmount: formatConfig.amountMapper(dto.grAmount),
      picInvoicedQuantity: formatConfig.numberMapper(dto.picInvoicedQuantity),
      picInvoicedAmount: formatConfig.amountMapper(dto.picInvoicedAmount),
      invoiceApprovedQuantity: formatConfig.numberMapper(dto.invoiceApprovedQuantity),
      invoiceApprovedAmount: formatConfig.amountMapper(dto.invoiceApprovedAmount),
      completeInvoice: <TrafficLight 
        status={dto.completeInvoice !== "COMPLETE" ? "INCOMPLETE" : "COMPLETE" as TrafficLightStatus} 
      />,
      purchaseOrder: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.id,
      vendorId: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.vendorId,
      vendor: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.vendorName, 
      accountingEntityDesc: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.accountingEntityDescription,
      purchaseEntity: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.purchaseEntity,
      total: formatConfig.amountMapper(purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.netTotalPrice), 
      currency: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.currency,
      creationDate: formatConfig.dateMapper(purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.createdAt),
    };
    return res;

  // eslint-disable-next-line
  }), [purchaseOrders])

  const {
    data: { allPurchaseOrderItems: exportToExcel } = {},
  } = useGetPurchaseOrdersItemsReportPageQuery({
    filters: {
      purchaseOrderItem: filters.purchaseOrderItem,
      productId: filters.productId,
      productDescription: filters.productDescription,
      locationDescription: filters.locationDesc,
      poQuantity: filters.poQuantity,
      unit: filters.unit,
      netUnitPrice: filters.netUnitPrice,
      netTotalPrice: filters.netTotalPrice
    },
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              purchaseOrderItem: dto.purchaseOrderItem,
              productId: dto.productId,
              productDescription: dto.productDescription,
              hasInvoice: dto.hasInvoice.toString(),
              locationDesc: dto.locationDescription ?? "",
              unit: dto.unit,
              poQuantity:  formatConfig.numberMapper(dto.poQuantity),
              netUnitPrice: formatConfig.amountDecimalsMapper(dto.netUnitPrice, PicConstants.NET_UNT_PRICE_DECIMALS),
              netTotalPrice: formatConfig.amountMapper(dto.netTotalPrice),
              grQuantity: formatConfig.numberMapper(dto.grQuantity),
              grAmount: formatConfig.amountMapper(dto.grAmount),
              picInvoicedQuantity: formatConfig.numberMapper(dto.picInvoicedQuantity),
              picInvoicedAmount: formatConfig.amountMapper(dto.picInvoicedAmount),
              invoiceApprovedQuantity: formatConfig.numberMapper(dto.invoiceApprovedQuantity),
              invoiceApprovedAmount: formatConfig.amountMapper(dto.invoiceApprovedAmount),
              completeInvoice: dto.completeInvoice ?? "",
              purchaseOrder: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.id ?? "",
              vendorId: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.vendorId ?? "",
              vendor: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.vendorName ?? "", 
              accountingEntityDesc: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.accountingEntityDescription ?? "",
              purchaseEntity: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.purchaseEntity ?? "",
              total: formatConfig.amountMapper(purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.netTotalPrice), 
              currency: purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.currency ?? "",
              creationDate: formatConfig.dateMapper(purchaseOrders?.find(e => e.id === dto.purchaseOrder)?.createdAt),
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel, formatConfig, purchaseOrders]
  );

  const read = actions.find(e => e.id === "READ");

  const menuItems = useMemo(
    () => [
      read && {
        key: "display",
        Icon: SearchIcon,
        text: read.translation.description,
        onClick: (id: string) => {
          if(id === "display") {
            if(rowsChecked.length === 0) {
              errorSnackbar.open(message.appMessage["ONLY_ONE_ROW"])
              return;
            } else if(rowsChecked.length > 1) {
              errorSnackbar.open(message.appMessage["MORE_ONE_ROW"])
              return;
            }
            id = rowsChecked[0];
          }

          const { item, purchaseOrder } = JSON.parse(id);

          navigate(PurchaseOrdersItemsOverviewRoutes.PATH_INVOICES, { 
            state: {
              toBack: TO_BACK,
              purchaseOrderId: purchaseOrder,
              purchaseOrderItemsIds: [item]
            }
          });
        },
      }
    ].filter(notNullUndefined),
    [navigate, errorSnackbar, rowsChecked, message, read]
  );

  return {
    errorSnackbar,
    appMessage,
    menuItems,
    rows,
    total,
    pagination,
    sheet
  }

}

const TO_BACK = "../";

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
});