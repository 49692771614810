import { EmployeePage } from "apps/EmployeesPage";
import { InvoicesFreightPage } from "apps/InvoicesFreightPage";
import { InvoicesOverviewPage } from "apps/InvoicesOverviewPage";
import { InvoiceVendorNumberDirectPage } from "apps/InvoiceVendorNumberDirectPage";
import { PurchaseOrdersDirectPage } from "apps/PurchaseOrdersDirectPage";
import { PurchaseOrdersItemsDirectPage } from "apps/PurchaseOrdersItemsDirectPage";
import { TutorialsPage } from "apps/TutorialsPage";
import { Route, Routes } from "react-router-dom";
import { AccountingEntitiesPage } from "../../apps/AccountingEntitiesPage";
import { AppsPage } from "../../apps/AppsPage";
import { BackendsPage } from "../../apps/BackendsPage";
import { BusinessGroupsPage } from "../../apps/BusinessGroupsPage";
import { BusinessObjectPage } from "../../apps/BusinessObject";
import { BusinessPartnersPage } from "../../apps/BusinessPartnersPage";
import { BusinessPartnerTypePage } from "../../apps/BusinessPartnerTypePage";
import { BusinessProfilePage } from "../../apps/BusinessProfile";
import { BusinessUnitsPage } from "../../apps/BusinessUnitsPage";
import { ChartOfAccountPage } from "../../apps/ChartOfAccountsPage";
import { CommercialEntityPage } from "../../apps/CommercialEntityPage";
import { CompaniesPage } from "../../apps/CompaniesPage";
import { CostCollectorPage } from "../../apps/CostCollectorPage";
import { CountriesIdsTypesPage } from "../../apps/CountriesIdsTypesPage";
import { CountriesPage } from "../../apps/CountriesPage";
import { CurrencyPage } from "../../apps/CurrencyPage";
import { DataElementsPage } from "../../apps/DataElementsPage";
import { ExportMigrationPackagePage } from "../../apps/ExportMigrationsPackagePage";
import { GroupsPage } from "../../apps/GroupsPage";
import { ImportMigrationPackagePage } from "../../apps/ImportMigrationsPackagePage";
import InvoiceFreightDirectPage from '../../apps/InvoiceFreightDirectPage/template';
import { KpisPage } from "../../apps/KpiPage";
import { LanguagePage } from "../../apps/LanguagePage";
import { LocationsPage } from "../../apps/LocationsPage";
import { LocationTypePage } from "../../apps/LocationTypePage";
import { MessagesPage } from "../../apps/MessagesPage";
import { PositionsPage } from "../../apps/PositionsPage";
import { PurchaseEntitiesPage } from "../../apps/PurchaseEntitiesPage";
import PurchaseOrdersItemsOverviewPage from '../../apps/PurchaseOrdersItemsOverviewPage/template';
import { PurchaseOrdersPage } from "../../apps/PurchaseOrdersPage";
import { RevenueCollectorsPage } from "../../apps/RevenueCollectorsPage";
import { TablesPage } from "../../apps/TablesPage";
import { ThemePage } from "../../apps/ThemePage";
import { TilesPage } from "../../apps/TilesPage";
import { UsersPage } from "../../apps/UsersPage";
import { ReportsRoutes } from "./ReportsRoutes";
import { ViewReportsPage } from "./ViewReportsPage";

const ReportsPage = () => {
  return (
    <Routes>
      <Route 
        path={ReportsRoutes.VIEW_REPORTS} 
        element={<ViewReportsPage />} 
      />
      <Route
        path={`${ReportsRoutes.DATA_ELEMENTS}/*`}
        element={<DataElementsPage />}
      />
      <Route 
        path={`${ReportsRoutes.TABLES}/*`} 
        element={<TablesPage />} 
      />
      <Route 
        path={`${ReportsRoutes.THEMES}/*`} 
        element={<ThemePage />} 
      />
      <Route
        path={`${ReportsRoutes.BUSINESS_OBJECTS}/*`}
        element={<BusinessObjectPage />}
      />
      <Route 
        path={`${ReportsRoutes.APPS}/*`} 
        element={<AppsPage />} 
      />
      <Route 
        path={`${ReportsRoutes.TILES}/*`} 
        element={<TilesPage />} 
      />
      <Route 
        path={`${ReportsRoutes.KPI}/*`} 
        element={<KpisPage />} 
      />
      <Route 
        path={`${ReportsRoutes.GROUPS}/*`} 
        element={<GroupsPage />} 
      />
      <Route
        path={`${ReportsRoutes.BUSINESS_PROFILES}/*`}
        element={<BusinessProfilePage />}
      />
      <Route 
        path={`${ReportsRoutes.USERS}/*`} 
        element={<UsersPage />} 
      />
      <Route 
        path={`${ReportsRoutes.LANGUAGES}/*`} 
        element={<LanguagePage />} 
      />
      <Route 
        path={`${ReportsRoutes.MESSAGES}/*`} 
        element={<MessagesPage />} 
      />
      <Route 
        path={`${ReportsRoutes.CURRENCY}/*`} 
        element={<CurrencyPage />} 
      />
      <Route 
        path={`${ReportsRoutes.COUNTRY}/*`} 
        element={<CountriesPage />} 
      />
      <Route
        path={`${ReportsRoutes.CHART_OF_ACCOUNT}/*`}
        element={<ChartOfAccountPage />}
      />
      <Route
        path={`${ReportsRoutes.BUSINESS_GROUP}/*`}
        element={<BusinessGroupsPage />}
      />
      <Route
        path={`${ReportsRoutes.ACCOUNTING_ENTITY}/*`}
        element={<AccountingEntitiesPage />}
      />
      <Route 
        path={`${ReportsRoutes.COMPANY}/*`} 
        element={<CompaniesPage />} 
      />
      <Route
        path={`${ReportsRoutes.PURCHASE_ENTITY}/*`}
        element={<PurchaseEntitiesPage />}
      />
      <Route
        path={`${ReportsRoutes.COMMERCIAL_ENTITY}/*`}
        element={<CommercialEntityPage />}
      />
      <Route 
        path={`${ReportsRoutes.LOCATION}/*`} 
        element={<LocationsPage />} 
      />
      <Route
        path={`${ReportsRoutes.LOCATION_TYPE}/*`}
        element={<LocationTypePage />}
      />
      <Route
        path={`${ReportsRoutes.BUSINESS_PARTNER_TYPE}/*`}
        element={<BusinessPartnerTypePage />}
      />
      <Route
        path={`${ReportsRoutes.COUNTRY_IDS_TYPE}/*`}
        element={<CountriesIdsTypesPage />}
      />
      <Route
        path={`${ReportsRoutes.MIGRATIONS_PACKAGES}/*`}
        element={<ExportMigrationPackagePage />}
      />
      <Route
        path={`${ReportsRoutes.IMPORT_MIGRATIONS_PACKAGES}/*`}
        element={<ImportMigrationPackagePage />}
      />
      <Route 
        path={`${ReportsRoutes.BACKEND}/*`} 
        element={<BackendsPage />} 
      />
      <Route 
        path={`${ReportsRoutes.EMPLOYEE}/*`} 
        element={<EmployeePage />} 
      />
      <Route 
        path={`${ReportsRoutes.POSITIONS}/*`} 
        element={<PositionsPage />} 
      />
      <Route 
        path={`${ReportsRoutes.BUSINESS_PARTNER}/*`} 
        element={<BusinessPartnersPage />} 
      />
      <Route 
        path={`${ReportsRoutes.REVENUE_COLLECTORS}/*`} 
        element={<RevenueCollectorsPage />} 
      />
      <Route 
        path={`${ReportsRoutes.COST_COLLECTORS}/*`} 
        element={<CostCollectorPage />} 
      />
      <Route 
        path={`${ReportsRoutes.BUSINESS_UNITS}/*`} 
        element={<BusinessUnitsPage />} 
      />
      <Route 
        path={`${ReportsRoutes.PURCHASE_ORDERS}/*`} 
        element={<PurchaseOrdersPage />} 
      />
      <Route 
        path={`${ReportsRoutes.PURCHASE_ORDERS_DIRECT}/*`} 
        element={<PurchaseOrdersDirectPage />} 
      />
      <Route 
        path={`${ReportsRoutes.PURCHASE_ORDERS_ITEMS_DIRECT}/*`} 
        element={<PurchaseOrdersItemsDirectPage />} 
      />
      <Route 
        path={`${ReportsRoutes.PURCHASE_ORDERS_ITEMS_OVERVIEW}/*`} 
        element={< PurchaseOrdersItemsOverviewPage/>} 
      />
      <Route 
        path={`${ReportsRoutes.INVOICES_OVERVIEW}/*`} 
        element={<InvoicesOverviewPage />} 
      />
      <Route 
        path={`${ReportsRoutes.INVOICES_VENDOR_NUMBER_DIRECT}/*`} 
        element={<InvoiceVendorNumberDirectPage />} 
      />
      <Route 
        path={`${ReportsRoutes.INVOICES_FREIGHT}/*`} 
        element={<InvoicesFreightPage />} 
      />
      <Route 
        path={`${ReportsRoutes.INVOICES_FREIGHT_DIRECT}/*`} 
        element={<InvoiceFreightDirectPage />} 
      />
      <Route 
        path={`${ReportsRoutes.TUTORIALS}/*`} 
        element={<TutorialsPage />} 
      />
    </Routes>
  );
}; 

export default ReportsPage;
