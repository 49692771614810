import { DevTool } from "@hookform/devtools";
import { Box, Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import { Tile } from "components/navigation/Tile";
import { equals } from "ramda";
import React from "react";
import { useWatch } from "react-hook-form";
import { TileType, TileTypeFormProps } from "./types";

const options: TileType[] = [
  // TODO usar type de graphql
  {
    shape: "SQUARE",
    hasKpi: false,
    hasImage: false,
  },
  {
    shape: "SQUARE",
    hasKpi: true,
    hasImage: false,
  },
  {
    shape: "SQUARE",
    hasKpi: false,
    hasImage: true,
  },
  //
  {
    shape: "RECTANGULAR",
    hasKpi: false,
    hasImage: false,
  },
  {
    shape: "RECTANGULAR",
    hasKpi: true,
    hasImage: false,
  },
  {
    shape: "RECTANGULAR",
    hasKpi: false,
    hasImage: true,
  },
  {
    shape: "RECTANGULAR",
    hasKpi: true,
    hasImage: true,
  },
  //
  {
    shape: "CIRCULAR",
    hasKpi: false,
    hasImage: false,
  },
  {
    shape: "CIRCULAR",
    hasKpi: true,
    hasImage: false,
  },
];

const TileTypeForm = ({
  forms,
  onEdit, // TODO:
  readOnly = false,
}: TileTypeFormProps) => {
  const { control, setValue } = forms.tileType;

  const descriptions = useWatch({
    control: forms.descriptions.control,
    name: "descriptions",
  });

  const description = descriptions.find((e) => e.language === "ES");

  const tileDescription = {
    title: description?.title ?? "", // FIXME:
    subtitle: description?.subtitle ?? "", // FIXME:
    indicator: "12.3", // FIXME:
    imageUrl:
      "https://i.ibb.co/T1xjZrm/pngfind-com-penguin-clipart-png-6050425.png", // FIXME:
  };

  const { title, subtitle, indicator, imageUrl } = tileDescription;

  const tileType = useWatch({ control, name: "tileType" });

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <Grid container spacing={4}>
        {options.map((option) => (
          <Grid item key={JSON.stringify(option)}>
            <Tile
              title={title}
              subtitle={subtitle}
              shape={option.shape}
              imageUrl={option.hasImage ? imageUrl : undefined}
              indicator={option.hasKpi ? indicator : undefined}
            />
            <Box display="flex" justifyContent="center">
              <Radio
                disabled={readOnly}
                checked={equals(tileType, option)}
                value={JSON.stringify(option)}
                onChange={(event) =>
                  setValue("tileType", JSON.parse(event.target.value))
                }
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TileTypeForm;
