import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon
} from "@mui/icons-material";
import {
  Checkbox,
  CheckboxProps,
  styled,
  TableCell,
  TableCellProps,
  TableRow
} from "@mui/material";
import React from "react";

export type TableHeaderColumns = {
  key: string;
  content: React.ReactNode;
  align?: TableCellProps["align"];
};

export type TableHeaderProps = {
  columns: TableHeaderColumns[];
  isChecked: boolean;
  onChangeChecked: (check: boolean) => void;
};

const TableHeader = ({
  columns,
  isChecked,
  onChangeChecked,
}: TableHeaderProps) => {
  return (
    <>
      <StyledTableRow>
        <StyledHeaderTableCell
          style={{
            width: 1, // FIXME: esta es la unica manera que encontrar para hacer que el ancho de la columna sea igual al icono
          }}
        >
          <GlobalCheckbox
            checked={isChecked}
            onChange={(e) => onChangeChecked(e.target.checked)}
          />
        </StyledHeaderTableCell>

        {columns.map((column) => (
          <StyledHeaderTableCell key={column.key} id={column.key} align={column.align}>
            {column.content}
          </StyledHeaderTableCell>
        ))}
      </StyledTableRow>
    </>
  );
};

export default TableHeader;

const StyledTableRow = styled(TableRow)({
  "& th:not(:first-child):not(:last-child)": {
    width: "1%",
  },
});

const StyledHeaderTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
});

const StyledCheckbox = (props: CheckboxProps) => (
  <Checkbox
    {...props}
    icon={<RadioButtonUncheckedOutlinedIcon />}
    checkedIcon={<CheckCircleOutlinedIcon />}
  />
);

const GlobalCheckbox = styled(StyledCheckbox)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  "&.Mui-checked": {
    color: theme.palette.primary.contrastText,
  },
}));
