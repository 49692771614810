import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: Partial<FormFields>
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const develop = useForm<FormFields["develop"]>({
    defaultValues: initialValue?.["develop"],
  });

  const configure = useForm<FormFields["configure"]>({
    defaultValues: initialValue?.["configure"],
  });

  return { basicData, develop, configure };
};
