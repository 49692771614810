import axios from "axios";

export const downloadFile = (
  url: string,
  fileName: string,
  params?: unknown,
  extension?: string
) => {
  axios
    .request({
      url,
      method: "GET",
      params,
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", extension === undefined ? fileName : fileName+"."+extension);
      document.body.appendChild(link);
      link.click();
    });
};
