import { styled, Table, TableBody, TableHead } from "@mui/material";
import React from "react";
import { TableBodyRow } from "./TableBodyRow";
import { TableHeaderRow } from "./TableHeaderRow";
import { MatchcodeTableProps } from "./types";

const MatchcodeTable = <TColKeys extends string>({
  columns,
  rows,
  rowIdSelected,
  onChangeRowIdSelected,
}: MatchcodeTableProps<TColKeys>) => {
  const colKeys = columns.map(({ key }) => key);

  return (
    <Table aria-label="matchcode-table" stickyHeader size="small">
      <MatchcodeTableHead>
        <TableHeaderRow columns={columns} />
      </MatchcodeTableHead>

      <TableBody>
        {rows.map(({ id, ...props }) => (
          <TableBodyRow
            key={id}
            values={colKeys.map((key) => ({
              key,
              text: (props as Record<TColKeys, string>)[key],
            }))}
            selected={rowIdSelected === id}
            onChangeSelected={() => onChangeRowIdSelected(id)}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default MatchcodeTable;

const MatchcodeTableHead = styled(TableHead)(({ theme }) => ({
  display: 'table-header-group',
  "& .MuiTableCell-stickyHeader": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: "uppercase",
  },
}));
