export enum InvoicesRoutes {

  PATH = "/",
  PATH_INVOICES = "invoices",
  PATH_INVOICES_VIEW = "view",
  PATH_INVOICES_CREATE = "create",
  PATH_INVOICES_EDIT = "edit",
}

export type InvoicesRoutesState = {
  activeStep: number;
  purchaseOrderId: string;
  purchaseOrderItemsIds: string[];
  invoiceNumber: string;
  toBack: string;
};