import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./ViewAllPage/reducers/visibleColumnsSlice";

export const useInvoicesLabels = () => {

    const id = "INVOICE_ID";
    const invoiceBackendNumber = "INVOICE_BACKEND_NUMBER";
    const invoiceVendorNumber = "INVOICE_VENDOR_NUMBER";
    const headerText = "INVOICE_HEADER_TEXT";
    const fiscalYear = "FISCAL_YEAR";
    const total = "TOTAL";
    const currency = "CURRENCY";
    const invoiceDate = "INVOICE_DATE";
    const plannedPaymentDate = "PLANNED_PAYMENT_DATE";
    const lastPaymentDate = "LAST_PAYMENT_DATE";
    const rejectionReason = "REJECTION_DESC";
    const invoiceStatus = "INVOICE_STATUS";
    
    const dataElementsIds = [
        id, invoiceVendorNumber, invoiceBackendNumber, headerText, fiscalYear, currency, invoiceDate, plannedPaymentDate, 
        lastPaymentDate, rejectionReason, invoiceStatus
    ];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones por si un id es incorrecto o no existe el sistema no falle
    const columnsLabels : NonNullable<Record<ColumnsKeys, string>> = {
        id: dataElementsDescriptions === undefined ? id : (dataElementsDescriptions[id] ?? id),  
        invoiceVendorNumber: dataElementsDescriptions === undefined ? invoiceVendorNumber : (dataElementsDescriptions[invoiceVendorNumber] ?? invoiceVendorNumber),
        invoiceBackendNumber: dataElementsDescriptions === undefined ? invoiceBackendNumber : (dataElementsDescriptions[invoiceBackendNumber] ?? invoiceBackendNumber),
        headerText: dataElementsDescriptions === undefined ? headerText : (dataElementsDescriptions[headerText] ?? headerText),
        fiscalYear: dataElementsDescriptions === undefined ? fiscalYear : (dataElementsDescriptions[fiscalYear] ?? fiscalYear),
        total: dataElementsDescriptions === undefined ? total : (dataElementsDescriptions[total] ?? total),
        currency: dataElementsDescriptions === undefined ? currency : (dataElementsDescriptions[currency] ?? currency),
        invoiceDate: dataElementsDescriptions === undefined ? invoiceDate : (dataElementsDescriptions[invoiceDate] ?? invoiceDate),
        lastPaymentDate: dataElementsDescriptions === undefined ? lastPaymentDate : (dataElementsDescriptions[lastPaymentDate] ?? lastPaymentDate),
        plannedPaymentDate: dataElementsDescriptions === undefined ? plannedPaymentDate : (dataElementsDescriptions[plannedPaymentDate] ?? plannedPaymentDate),
        rejectionReason: dataElementsDescriptions === undefined ? rejectionReason : (dataElementsDescriptions[rejectionReason] ?? rejectionReason),
        invoiceStatus: dataElementsDescriptions === undefined ? invoiceStatus : (dataElementsDescriptions[invoiceStatus] ?? invoiceStatus),  
    };

    return { columnsLabels }
}