import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Edit as EditCircleIcon,
} from "@mui/icons-material";
import React from "react";
import { useDataElementDescription } from "hooks/useDataElementDescription";

type HeadProps = {
  isEditButtonVisible: boolean;
  appendRow: () => void;
  onEdit?: () => void;
};

const Head = ({ isEditButtonVisible, appendRow, onEdit }: HeadProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "LANGUAGE",
    "TILE_TITLE",
    "TILE_SUBTITLE",
  ]);

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell
          align="left"
          style={{
            minWidth: 150, // FIXME:
          }}
        >
          {dataElementsDescriptions["LANGUAGE"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["TILE_TITLE"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["TILE_SUBTITLE"]}
        </StyledHeadTableCell>

        <TableCell align="right">
          {isEditButtonVisible ? (
            <Tooltip
              title="Editar" // FIXME: traduccion
            >
              <IconButton
                aria-label="add"
                size="small"
                onClick={() => onEdit?.()}
              >
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Agregar fila" // FIXME: traduccion
            >
              <IconButton aria-label="add" size="small" onClick={appendRow}>
                <StyledAddCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Head;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
