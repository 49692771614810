import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Clear as ClearIcon } from "@mui/icons-material";
import React from "react";
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { GroupDto, GroupsFormFields } from "../types";
import { GroupsMatchcode } from "./GroupsMatchcode";
import useAppMessages from 'hooks/useAppMessages/hook';

type RowProps = {
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<GroupsFormFields>["fields"][number];
  groupsByIdMap: Map<string, GroupDto>;
  form: UseFormReturn<GroupsFormFields>;
};

const Row = ({
  index,
  remove,
  item,
  readOnly,
  groupsByIdMap,
  form,
}: RowProps) => {
  const { control, trigger, getValues } = form;
  const { appMessage } = useAppMessages("BUSINESS_PROFILES")
  const {appMessage:appMsg} = useAppMessages("MESSAGES");

  const groupId = useWatch({
    control,
    name: `groups.${index}.groupId` as "groups.0.groupId",
  });

  const groupsOptions = Array.from(groupsByIdMap.values());

  const group = groupsByIdMap.get(groupId);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={`groups.${index}.groupId` as "groups.0.groupId"}
          rules={{
            required: appMessage["ENTER_TLGROUP"] ?? "Select a tile group", ///FIXPRD QUITAR

            validate: (value: string) => {
              const groupsIds = getValues("groups").map((e) => e.groupId);

              return isRepeatedValue(value, groupsIds)
                ? appMessage["TLGROUP_SELECTED"] ?? "Tile group already selected" ///FIXPRD QUITAR
                : true;
            },
          }}
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <GroupsMatchcode
              groups={groupsOptions}
              value={value}
              onChange={onChange}
              invalid={invalid}
              errorMessage={error?.message}
              readOnly={readOnly}
            />
          )}
          defaultValue={item.groupId}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {group?.translation?.description}
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip
            title={appMsg["DELETE_ROW"]}
          >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
