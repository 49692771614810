import { MatchcodeDialogContainer } from "components/inputs/matchcode/MatchcodeDialog";
import { MatchcodeDisplayInput } from "components/inputs/matchcode/MatchcodeInputController/MatchcodeDisplayInput";
import MatchcodeTable from "components/inputs/matchcode/MatchcodeTable/template";
import { useMatchcode } from "components/inputs/matchcode/useMatchcode";
import { VendorInvoiceInformation } from "../../template";

export type GroupsMatchcodeProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  invoices: VendorInvoiceInformation[];
  invalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  text?:string;
  dataElementsDescriptions: Record<string, string>;
};

export const GroupsMatchcode = ({
  value,
  onChange,
  invoices,
  invalid,
  errorMessage,
  readOnly = false,
  text,
  dataElementsDescriptions
}: GroupsMatchcodeProps) => {
  
  const options = invoices.map((e) => ({
    id: JSON.stringify({id: e.invoiceNumber, invoiceVendorNumber: e.invoiceVendorNumber}),
    invoiceNumber: e.invoiceNumber,
    invoiceVendorNumber: e.invoiceVendorNumber,
  }));

  const {
    dialog,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  } = useMatchcode({
    options,
    value,
    onChange,
    searchKeys: ["invoiceVendorNumber", "invoiceNumber"],
  });

  return (
    <>
      <MatchcodeDisplayInput
        label={dataElementsDescriptions["INVOICE_VENDOR_NUMBER"] ?? "INVOICE_VENDOR_NUMBER"}
        name={dataElementsDescriptions["INVOICE_VENDOR_NUMBER"] ?? "INVOICE_VENDOR_NUMBER"}
        text={text ?? ""}
        isDeleteButtonVisible={isDeleteButtonVisible}
        onClearValue={onClearValue}
        onOpenDialog={dialog.open}
        error={invalid}
        helperText={errorMessage}
        readOnly={readOnly}
      />

      <MatchcodeDialogContainer
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        filterValue={textFilter}
        onChangeFilterValue={setTextFilter}
        onSubmit={onSubmit}
        tableElement={
          <MatchcodeTable
            columns={[
              { key: "invoiceNumber", text: dataElementsDescriptions["INVOICE_ID"] ?? "INVOICE_ID"},
              { key: "invoiceVendorNumber", text: dataElementsDescriptions["INVOICE_VENDOR_NUMBER"] ?? "INVOICE_VENDOR_NUMBER"},
            ]}
            rows={visibleOptions ?? []}
            rowIdSelected={selectedRowId ?? ""}
            onChangeRowIdSelected={setSelectedRowId}
          />
        }
      />
    </>
  );
};

export default GroupsMatchcode;
