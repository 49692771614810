import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetGroupForViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { GroupsRoutes } from "../routes";
import { mapDtoToForm } from "./util";

const ViewPage = () => {
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<FormFields>();

  const { groupId } = useRouteParams<GroupsRoutes.VIEW>();

  const {
    data: { group } = {},
    isFetchedAfterMount,
  } = useGetGroupForViewPageQuery({
    id: groupId,
  });

  const onEdit = (activeStep: number) => {
    navigate(
      `../../${generatePath(GroupsRoutes.EDIT, {
        groupId,
      })}`,
      { state: { activeStep } }
    );
  };

  useEffect(() => {
    if (group === undefined) return;
    if (group === null) return;
    if (!isFetchedAfterMount) return;
    mapDtoToForm(group).then((v) => setInitialValues(v));
  }, [group, isFetchedAfterMount]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Grupo de mosaicos" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValues} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
