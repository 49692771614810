import { Box, Grid, styled } from "@mui/material";
import { FabCompanyLogo } from "components/inputs/FabCompanyLogo";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { Layout } from "components/layout/Layout";
import { LoadingPage } from "components/layout/LoadingPage";
import { Tile } from "components/navigation/Tile";
import { getMyInformation } from "components/providers/ReduxMyProvider/reducer";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabsBar } from "../../../components/navigation/TabsBar";
import { getGroupSelected, setAppSelected, setGroupSelected } from "./reducer/groupSelected";

const ViewReportsPage = () => {

  const [tiles, setTiles] = useState<any>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setGroupId = (groupId:string) =>{
    dispatch(setGroupSelected(groupId))
  }
  
  const navigateApp = (path: string, appName:string) =>{
    dispatch(setAppSelected(appName));
    navigate(path);
  }

  const groupState = useAppSelector(getGroupSelected);
  const { me } = useAppSelector(getMyInformation);

  const { groups } = me?.businessProfile ?? {};
  const groupsTabs =  groups?.map((e) => ({
    label: e.translation.description,
    icon: e.icon.url,
    to: e.id,
  }));

  // TODO: Tipar card
  useEffect(() => {
    setTiles(Object.fromEntries([
      ...(groups?.map((group) => [
        group.id,
        group.tiles.map((dto) => ({
          title: dto.translation.title,
          subtitle: dto.translation.subtitle,
          indicator: dto.kpi != null ? dto.kpi.value! : undefined, // FIXME:
          imageUrl: dto.icon?.url ?? "",
          shape: dto.shape,
          path: dto.path,
        })),
      ]) ?? []),
    ]));
    dispatch(setAppSelected(null));
  }, [groups, setTiles, dispatch]);

  if (groupsTabs === undefined) return <LoadingPage />;

  return (
    <Layout>
      
      {/* Instructor */}
      <MenuInstructions tutorialsIds={[
        "HOME_TOUR", "HOME_INVOICES_DISPLAY", "HOME_INVOICES_UPLOAD", "HOME_INVOICES_REPORT",
        "HOME_PO_DISPLAY", "HOME_PO_ITEMS_DISPLAY", "HOME_PO_REPORTS",
        "HOME_FREIGHT_DISPLAY", "HOME_FREIGHT_UPLOAD"
      ]} />

      {/* Apps bar */}
      <FabCompanyLogo />
      <TabsBar tabs={groupsTabs} value={groupState.groupSelected.groupId} onChange={setGroupId} />

      {/* // TODO: mover a componente TilesGroup */}
      <StyledBox> 
        <Grid container spacing={3}>
          {
            tiles?.[groupState.groupSelected.groupId]?.map((
              card: any // FIXME:
            ) => (
              <Grid 
                item 
                key={card.path} 
                onClick={() => navigateApp(card.path, card.title)}
              >
                <PointerBox id={`card${card.path.replaceAll("/", "_")}`}>
                  <Tile
                    title={card.title}
                    subtitle={card.subtitle}
                    indicator={card.indicator}
                    shape={card.shape}
                    imageUrl={card.imageUrl}
                  />
                </PointerBox>
              </Grid>
            ))
          }
        </Grid>
      </StyledBox>
    </Layout>
  );
};

export default ViewReportsPage;

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  justifyContent: "left",
  display: "flex",
  paddingLeft: theme.spacing(6),
  paddingTop: theme.spacing(2),
}));

const PointerBox = styled(Box)({
  cursor: "pointer",
});