import {
  Check as CheckIcon,
  InfoOutlined as InfoOutlinedIcon,
  ListRounded as ListRoundedIcon,
  Save as SaveIcon
} from "@mui/icons-material";
import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { AlertUnsavedDataInput } from "components/feedback/AlertUnsavedDataInput";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { SuccessMessageParams } from "components/feedback/SuccessMessageParams";
import { DescriptionFormControl } from "components/inputs/DescriptionFormControl";
import { SaveCloseButtons } from "components/inputs/SaveCloseButtons";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { FormStepper } from "components/navigation/FormStepper";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useAppSectionsTranslations } from "hooks/useAppSectionsTranslations";
import { useFormSteps } from "hooks/useFormSteps";
import { StepBehavior } from "hooks/useFormSteps/hook";
import { useSnackbar } from "hooks/useSnackbarV2";
import { FormProps } from "types/Form";
import { assertUnreachable } from "utils/assert";
import { FormStep } from "utils/FormStep";
import { AppsForm } from "./AppsForm";
import { BasicDataForm } from "./BasicDataForm";
import { InstructionsForm } from "./InstructionsForm";
import { FormFields, FormStepsKeys, NonFormStepsKeys } from "./types";
import { useForms } from "./useForms";
import { useInstructionsForm } from "./useInstructionsForm";

const Form = ({
  initialValue,
  initialStep,
  mode,
  errorMessage = "", // FIXME:
  saveMessage,
  onSave = assertUnreachable, // FIXME:
  onCancel = assertUnreachable, // FIXME:
  onEdit,
}: FormProps<FormFields>) => {

  const forms = useForms(initialValue);
  const errorSnackbar = useSnackbar();
  const { appMessage } = useAppMessages("MESSAGES");

  const instructions = useInstructionsForm(forms.instructions);

  const {
    appSectionsTranslations,
    isLoadingSections,
  } = useAppSectionsTranslations("TUTORIALS");

  const readOnly = mode === "view";
  const noLinearStepper = mode ==="view" || mode ==="edit";

  const stepsKeysInOrder: StepBehavior<FormStepsKeys, NonFormStepsKeys>[] = [
    { key: "basicData", hasForm: true },
    { key: "name", hasForm: true },
    { key: "apps", hasForm: true },
    { 
      key: "instructions", 
      hasForm: true,
      isNestedPage: instructions.areDescriptionsVisible,
      onClickPrevButton: instructions.onClickPageButton,
    },
    { key: "save", isHidden: readOnly },
    { key: "success", isHidden: readOnly },
  ];

  const stepsNavigation = useFormSteps({
    forms,
    initialStep,
    errorSnackbar,
    errorMessage,
    stepsKeysInOrder,
    readOnly,
    onSave,
    onEdit,
  });

  const unorderedSteps: Record<FormStepsKeys | NonFormStepsKeys, FormStep> = {
    basicData: {
      ...appSectionsTranslations("BASIC_DATA"),
      icon: <InfoOutlinedIcon />,
      body: (
        <BasicDataForm
          form={forms.basicData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    name: {
      ...appSectionsTranslations("NAME"),
      icon: <ListRoundedIcon />,
      body: (
        <DescriptionFormControl
          form={forms.name}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    apps: {
      ...appSectionsTranslations("APPS"),
      icon: <InfoOutlinedIcon />,
      body: (
        <AppsForm
          forms={forms.apps}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    instructions: {
      ...appSectionsTranslations("INSTRUCTIONS"),
      icon: <InfoOutlinedIcon />,
      leftButtonText: instructions.name,
      body: (
        <InstructionsForm
          mode={mode}
          form={forms.instructions}
          externalIndex={instructions.index}
          areDescriptionsVisible={instructions.areDescriptionsVisible}
          onEnterDescriptions={instructions.onEnterDescriptions}
          onExitDescriptions={instructions.onExitDescriptions}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    save: {
      title: appMessage["SAVE"], 
      subtitle:appMessage["SAVE_CONFIRMATION"], 
      icon: <SaveIcon />,
      isSaveButtonVisible: true,
    },
    success: {
      title:appMessage["END"], 
      icon: <CheckIcon />,
      body: <SuccessMessageParams message={saveMessage?.message} params={saveMessage?.params} />,
      isHeaderHidden: true,
    },
  };

  const currentStep = unorderedSteps[stepsNavigation.activeStepKey];

  const visibleSteps = stepsNavigation.visibleStepsKeysInOrder.map(
    (key) => unorderedSteps[key]
  );

  if (isLoadingSections) return <BeatLoadingPage/>;

  return (
    <>
      <AlertUnsavedDataInput
        isBlocked={stepsNavigation.isRouteBlocked}
        unblock={stepsNavigation.unblockRoute}
      />

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <FormStepper
        steps={visibleSteps}
        activeStepIndex={stepsNavigation.stepper.activeStepIndex}
        isNonLinear={noLinearStepper}
        onChangeActiveStepIndex={stepsNavigation.stepper.setActiveStepIndex}
      />

      {!currentStep.isHeaderHidden && (
        <FormHeader
          title={currentStep.title}
          subtitle={currentStep.subtitle}
          step={currentStep.step}
          leftButtonText={currentStep.leftButtonText}
          isPreviousButtonVisible={stepsNavigation.isPreviousButtonVisible}
          isNextButtonVisible={stepsNavigation.isNextButtonVisible}
          isUpButtonVisible={stepsNavigation.isUpButtonVisible}
          onPrevious={stepsNavigation.onPrevious}
          onNext={stepsNavigation.onNext}
          onUp={stepsNavigation.onUp}
        />
      )}

      <FormBodyWrapper>{currentStep.body}</FormBodyWrapper>

      {!readOnly && (
        <SaveCloseButtons
          isCancelButtonVisible
          isSaveButtonVisible={currentStep.isSaveButtonVisible ?? false} // FIXME:
          onCancel={onCancel}
          onSave={stepsNavigation.onClickSave}
        />
      )}
    </>
  );
};

export default Form;
