import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  TextField
} from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";
import React from "react";

type MatchcodeDialogContainerProps = {
  isOpen: boolean;
  onClose: () => void;
  tableElement: React.ReactNode;
  onSubmit: () => void;
  filterValue: string;
  onChangeFilterValue: (value: string) => void;
};

const MatchcodeDialogContainer = ({
  isOpen,
  onClose,
  tableElement,
  onSubmit,
  filterValue,
  onChangeFilterValue,
}: MatchcodeDialogContainerProps) => {

  const { appMessage } = useAppMessages("MESSAGES");
  
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <FilterWrapper>
        <TextField
          label={appMessage["FILTER"] ?? "Filter"}
          id="search"
          name="search"
          variant="outlined"
          type="text"
          margin="dense"
          size="small"
          value={filterValue}
          onChange={(event) => onChangeFilterValue(event.target.value)}
        />
        <Box mb={2} />
      </FilterWrapper>

      <DialogContentStyled>
        {tableElement}
      </DialogContentStyled>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {appMessage["EXIT"] ?? "Exit"}
        </Button>

        <Button onClick={onSubmit} color="primary">
          {appMessage["APPLY"] ?? "Apply"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchcodeDialogContainer;

const FilterWrapper = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  width: "100%"
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingTop: '0px'
}));
