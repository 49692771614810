import { CreateInvoicePage } from "apps/InvoicesPage/CreatePage";
import { EditInvoicePage } from "apps/InvoicesPage/EditPage";
import { ViewAllInvoicesPage } from "apps/InvoicesPage/ViewAllPage";
import { ViewInvoicePage } from "apps/InvoicesPage/ViewPage";
import { Route, Routes } from "react-router-dom";
import { PurchaseOrdersItemsDirectRoutes } from "./routes";
import { PurchaseOrderItemsSelectFormPage } from "./SelectForm";

const PurchaseOrdersItemsDirectPage = () => {
  return (
    <Routes>
      <Route
        path={PurchaseOrdersItemsDirectRoutes.PATH}
        element={<PurchaseOrderItemsSelectFormPage />}
      />
      <Route 
        path={PurchaseOrdersItemsDirectRoutes.PATH_INVOICES} 
        element={<ViewAllInvoicesPage />} 
      />
      <Route 
        path={PurchaseOrdersItemsDirectRoutes.PATH_INVOICES_VIEW} 
        element={<ViewInvoicePage />} 
      />
      <Route 
        path={PurchaseOrdersItemsDirectRoutes.PATH_INVOICES_EDIT} 
        element={<EditInvoicePage />} 
      />
      <Route 
        path={PurchaseOrdersItemsDirectRoutes.PATH_INVOICES_CREATE} 
        element={<CreateInvoicePage />} 
      />
    </Routes>
  );
};

export default PurchaseOrdersItemsDirectPage;
