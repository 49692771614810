import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid
} from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";
import { ascend, sortWith } from "ramda";

export type FilterDialogElement = {
  key: string;
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

type FiltersDialogProps = {
  open: boolean;
  handleClose: () => void;
  filters: FilterDialogElement[];
};

const FiltersDialog = ({ filters, open, handleClose }: FiltersDialogProps) => {

  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {appMessage["TITLE_FILTERS_DIALOG"] ?? "Which filters do you want to apply?"}
      </DialogTitle>

      <DialogContent>
        <Grid container item wrap="wrap">
          {sortByLabelAsc(filters).map(({ key, label, checked, onChange }) => (
            <Grid key={key} item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(event) => {
                      onChange(event.target.checked);
                    }}
                    name={`filter-checkbox-${key}`}
                    color="primary"
                  />
                }
                label={label}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {appMessage["EXIT"] ?? "Exit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FiltersDialog;

const sortByLabelAsc = sortWith<FilterDialogElement>([
  ascend(({ label }) => label),
]);
