import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetBusinessUnitViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { BusinessUnitsRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {
  const navigate = useNavigate();

  const { businessUnitsId } = useRouteParams<BusinessUnitsRoutes.VIEW>();

  const { data: { businessUnit } = {}, isFetchedAfterMount } = useGetBusinessUnitViewPageQuery({
    id: businessUnitsId,
  });

  const initialValue =
    businessUnit != null && isFetchedAfterMount ? mapDtoToForm(businessUnit) : undefined;

  const onEdit = (activeStep: number) => {
    navigate(`../../${generatePath(BusinessUnitsRoutes.EDIT, { businessUnitsId })}`, {
      state: { activeStep },
    });
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Ver unidad de negocio" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValue} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
