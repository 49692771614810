export enum CountryIdsTypesRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":countryIdsTypesId/view",
  EDIT = ":countryIdsTypesId/edit",
}

export type CountryIdsTypesRoutesState = {
  [CountryIdsTypesRoutes.EDIT]: { activeStep?: number };
};
