import { GetEmployee_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetEmployee_ViewPageQuery["employee"]>
): FormFields => {
  return {
    basicData: {
      employeeType: dto.type,
      accountingEntity: dto.accountingEntityId ?? "",
      businessPartner: dto.businessPartnerId ?? "",
    },
    contactData: {
      contact: {
        firstName: dto.contact.name ?? "",
        lastName: dto.contact.lastName ?? "",
        phone: dto.contact.phoneNumber ?? "",
        email: dto.contact.email ?? "",
      },
      user: {
        id: dto.contact.user?.id ?? "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      _hasUser: dto.contact.user?.id != null,
    },
    positionHistory: {
      positions: dto.positionHistory.map((x) => ({
        positionId: x.positionId,
        startDate: new Date(x.startDate),
      })),
    },
  };
};
