import { styled } from "@mui/material";
import MuiColorPicker from "material-ui-color-picker";
import React, { ComponentProps } from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

export type ColorPickerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  ComponentProps<typeof StyledColorPicker>,
  "name" | "value" | "onChange" | "onBlur"
> & {
  controllerProps: UseControllerProps<TFieldValues, TName>;
};

const ColorPicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  controllerProps,
  ...colorPickerProps
}: ColorPickerProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>({
    // @ts-ignore FIXME:
    defaultValue: "",
    ...controllerProps,
  });
  return (
    <StyledColorPicker
      name={name}
      size="small"
      value={value}
      inputProps={{
        value: value,
        ...colorPickerProps.inputProps,
      }}
      onChange={onChange}
      onBlur={onBlur}
      error={invalid}
      helperText={error?.message}
      variant="outlined"
      fullWidth
      {...colorPickerProps}
    />
  );
};

export default ColorPicker;

const StyledColorPicker = styled(MuiColorPicker)(({ theme }) => ({
  "& input": {
    color: theme.palette.text.primary,
  },
  "& + div": {
    top: -300,
  },
}));
