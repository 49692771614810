import { Clear as ClearIcon } from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableRow, Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAppMessages } from "hooks/useAppMessages";
import { useEffect, useState } from "react";
import {
  Controller,
  UseFormReturn
} from "react-hook-form";
import { isRepeatedValue } from "utils/isRepeatedValue";
import { AppDtoForCreateTutorial, AppsFormFields } from "../types";
import { PositionMatchcode } from "./PositionMatchcode";

type RowProps = {
  readOnly: boolean;
  index: number;
  remove: () => void;
  appsByIdMap: Map<string, AppDtoForCreateTutorial> | undefined;
  form: UseFormReturn<AppsFormFields>;
};

const Row = ({
  index,
  remove,
  readOnly,
  appsByIdMap,
  form,
}: RowProps) => {

  const [app, setApp] = useState<AppDtoForCreateTutorial | undefined>(undefined);
  const [apps, setApps] = useState<AppDtoForCreateTutorial[]>([]);

  const { control, trigger, getValues, watch } = form;
  const { appMessage } = useAppMessages("MESSAGES");

  const appId = watch(
    `apps.${index}.id` as "apps.0.id"
  );

  useEffect(() => {
    setApps(
      Array.from(appsByIdMap 
        ? appsByIdMap.values() 
        : []
      )
    );
  }, [appsByIdMap, setApps]);

  useEffect(() => {
    setApp(appsByIdMap?.get(appId))
  }, [appId, setApp, appsByIdMap]);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={
            `apps.${index}.id` as "apps.0.id"
          }
          rules={{ 
            required: appMessage["CHOOSE_ONE"] ?? "Choose one",
            validate: (value: string) => {
              const itemsIds = getValues("apps").map((e) => e.id);
                return isRepeatedValue(value, itemsIds)
                  ? appMessage["DATA_REPEATED"] ?? "Data repeated"
                  : true;
            },
          }} 
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => ( 
            <PositionMatchcode
              appsOptions={apps}
              value={value}
              onChange={onChange}
              invalid={invalid}
              errorMessage={error?.message}
              readOnly={readOnly}
            /> 
          )}
          defaultValue={""}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {app?.translation.description}
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip title={appMessage["DELETE_ROW"]} >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});