import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Edit as EditCircleIcon } from "@mui/icons-material";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import React from "react";
import useAppMessages from 'hooks/useAppMessages/hook';

export type HeaderProps = {
  readOnly: boolean;
  onEdit: () => void;
};

const Header = ({ readOnly = false, onEdit }: HeaderProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "APP",
    "DESCRIPTION",
  ]);
  const { appMessage } = useAppMessages("BUSINESS_PROFILES");

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["APP"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["DESCRIPTION"]}
        </StyledHeadTableCell>

        <TableCell align="right">
          {readOnly && (
            <Tooltip
              title={appMessage["EDIT"]}
            >
              <IconButton aria-label="edit" size="small" onClick={onEdit}>
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
