import { VisibleColumnsDialog } from "components/datadisplay/VisibleColumnsDialog";
import { useAppSelector } from "hooks/reduxHooks";
import React from "react";
import { useDispatch } from "react-redux";
import {
  DataElementColumnKeys,
  dataElementsColumnsLabels,
  hideColumn,
  orderColumns,
  selectDataElementsHiddenColumnsKeys,
  selectDataElementsVisibleColumnsKeysInOrder,
  showColumn,
} from "../../../reducers/visibleColumnsSlice";

type DataElementsColumnsVisibilityDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const DataElementsColumnsVisibilityDialog = ({
  open,
  handleClose,
}: DataElementsColumnsVisibilityDialogProps) => {
  const dispatch = useDispatch();

  const visibleColumns = useAppSelector(
    selectDataElementsVisibleColumnsKeysInOrder
  );

  const hiddenColumns = useAppSelector(selectDataElementsHiddenColumnsKeys);

  return (
    <VisibleColumnsDialog
      open={open}
      handleClose={handleClose}
      visibleColumns={mapColumnKeyToDialog(visibleColumns)}
      hiddenColumns={mapColumnKeyToDialog(hiddenColumns)}
      hideColumn={(id) => dispatch(hideColumn({ id }))}
      orderColumn={(ids) => dispatch(orderColumns({ ids }))}
      showColumn={(id) => dispatch(showColumn({ id }))}
    />
  );
};

export default DataElementsColumnsVisibilityDialog;

const mapColumnKeyToDialog = (keys: DataElementColumnKeys[]) => {
  return keys.map((key) => ({
    id: key,
    label: dataElementsColumnsLabels[key],
  }));
};
