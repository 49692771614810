import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { notNullUndefined } from "utils/notNullUndefined";

export type InvoicesFreightCheckedRowsType = {
  purchaseOrder: string;
  purchaseOrderItem: string; 
  checked: boolean
}

type CheckedRowsState = {
  areAllRowsChecked: boolean;
  individualRowsChecked: InvoicesFreightCheckedRowsType[];
};

const initialState: CheckedRowsState = {
  areAllRowsChecked: false,
  individualRowsChecked: [],
};

const checkedRowsSlice = createSlice({
  name: "invoicesFreightCheckedRowsSlice",
  initialState,
  reducers: {
    checkAllRows: (state, action: PayloadAction<boolean>) => {
      const checked = action.payload;
      if (checked) {
        state.individualRowsChecked = [];
      }
      state.areAllRowsChecked = checked;
    },
    checkRow: (
      state,
      action: PayloadAction<{ rowKey: string; checked: boolean }>
    ) => {

      const { rowKey, checked } = action.payload;
      const { purchaseOrderItem, purchaseOrder } = JSON.parse(rowKey);
      const row = state.individualRowsChecked
        .find((e) => e.purchaseOrderItem === purchaseOrderItem && e.purchaseOrder === purchaseOrder);

      if(notNullUndefined(row)) {
        row.checked = checked;
      } else {
        state.individualRowsChecked.push({
          purchaseOrderItem: purchaseOrderItem,
          purchaseOrder: purchaseOrder,
          checked: checked
        })
      }
    },
  },
});

export const { checkRow, checkAllRows } = checkedRowsSlice.actions;

export const selectIsRowChecked = (state: RootState) => (rowKey: string) =>  {

  const { purchaseOrderItem, purchaseOrder } = JSON.parse(rowKey);

  if(state.invoicesFreight.rowsChecked.areAllRowsChecked) return true;
  return state.invoicesFreight.rowsChecked.individualRowsChecked
    .find(e => e.purchaseOrderItem === purchaseOrderItem && e.purchaseOrder === purchaseOrder)?.checked ?? false;
}

// FIXME: Si se seleccionan todas las columnas el filtro no funciona
export const selectAreAllRowsChecked = (state: RootState) => 
state.invoicesFreight.rowsChecked.areAllRowsChecked

export const selectRowsChecked = (state: RootState) => {
  return state.invoicesFreight.rowsChecked.individualRowsChecked
    .filter(e => e.checked === true)
}

export default checkedRowsSlice.reducer;
