export enum CurrencyRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":currencyId/view",
  EDIT = ":currencyId/edit",
}

export type CurrencyRoutesState = {
  [CurrencyRoutes.EDIT]: { activeStep?: number };
};
