import { urlToPdfFile } from "utils/urlToFile";
import { GetInvoiceForViewPageQuery, GetInvoiceItemsForViewPageQuery } from '../../../__generated__/graphql/types';
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = async (
  invoice: NonNullable<GetInvoiceForViewPageQuery["invoice"]>,
  invoiceItems: NonNullable<GetInvoiceItemsForViewPageQuery>["invoiceItem"],
  purchaseOrderId: string,
): Promise<FormFields> => {
  return {
    invoice: {
      purchaseOrder: purchaseOrderId,
      backend: invoice.backend ?? "",
      accountingEntity: invoice.accountingEntity,
      vendorId: invoice.vendorId,
      currency: invoice.currency,
      headerText: invoice.headerText,
      invoiceDate: new Date(invoice.invoiceDate),
      invoiceVendorNumber: invoice.invoiceVendorNumber ?? "" //FIXME:
    },
    positions: {
      positions: invoiceItems === undefined || invoiceItems === null //FIXME:
      ? []
      : invoiceItems.map((e) => ({
        id: e!.id,
        purchaseOrderItemId: e!.purchaseOrderItem,
        backend: e!.backend ?? "",
        taxesAmount: Number(e!.taxesAmount),
        extraChargesAmount: Number(e!.extraChargesAmount),
        amountToBill: Number(e!.totalPrice),
        itemText: e!.itemText ?? "",
        productId: e!.productId,      
        quantity: Number(e!.quantity),
        unit: e!.unit,
        netUnitPrice: Number(e!.netUnitPrice),
        netTotalPrice: Number(e!.netTotalPrice),
      }))
    },
    file: {
      file: invoice.file?.url === undefined
        ? (null as any)
        : await urlToPdfFile(invoice?.file.url, invoice?.file.id),
    },
    send: {
      send: (invoice.invoiceStatus) === "CREATED" ? false : true
    }
  };
};
