import { GenericTable } from "components/datadisplay/GenericTable";
import { MenuItemsProps, TableRowType } from "components/datadisplay/GenericTable/template";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { Maybe } from "graphql/jsutils/Maybe";
import { ColumnsKeys } from "../../reducers/visibleColumnsSlice";
import { useTable } from "./useTable";

type TableProps = {
  menuItems: MenuItemsProps[];
  rows?: TableRowType<ColumnsKeys>[];
  total?: Maybe<number> | undefined;
  pagination: any;
};

const Table = ({
  menuItems,
  rows,
  total,
  pagination
}: TableProps) => {
  
  const {
    errorSnackbar,
    columns,
    appMessage,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
  } = useTable();

  if (rows === undefined) return <BeatLoadingPage />;
  if (total === undefined) return <BeatLoadingPage />;

  return (
    <>
      <SnackbarAlert
        severity="error"
        onClose={errorSnackbar.close}
        open={errorSnackbar.isOpen}
      >
        {/* TODO: Especificar con mas detalle que error ocurrio */}
        { appMessage["ERROR"] }
      </SnackbarAlert>

      <GenericTable
        columns={columns}
        rows={rows}
        menuItems={menuItems}
        isRowChecked={isDataElementsRowChecked}
        onChangeCheckedRow={onChangeCheckedRow}
        areAllRowsChecked={areAllDataElementsRowsChecked}
        onChangeAllRowsChecked={onChangeAllRowsChecked}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        totalRows={total ?? 0}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
      />
    </>
  );
};

export default Table;
