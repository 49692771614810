import { DeprecatedThemeOptions } from "@mui/material";
import {
  setBackgroundImageFile,
  setCompanyLogoFile,
  setIsThemeFromBackend,
  setThemeOptions,
} from "components/providers/ReduxMuiThemeProvider/reducer";
import { useAppDispatch } from "hooks/reduxHooks";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { notNullUndefined } from "utils/notNullUndefined";
import { FormFields } from "./types";

export const useEffectUpdateTheme = (form: MapToUseFormReturn<FormFields>) => {
  const dispatch = useAppDispatch();

  const [primaryColor, primaryColorContrast, userColor] = useWatch({
    control: form.primaryColor.control,
    name: ["primaryColor", "primaryColorContrast", "userColor"],
  });

  const [appsColor, appsTitleColor] = useWatch({
    control: form.appsColor.control,
    name: ["appsColor", "appsTitleColor"],
  });

  const [color1, color2, color3, internalColor, textColor] = useWatch({
    control: form.stepperColor.control,
    name: ["color1", "color2", "color3", "internalColor", "textColor"],
  });

  const backgroundImage = form.backgroundImage.watch("backgroundImage");
  const companyLogo = form.companyLogo.watch("companyLogo");

  const themeOptions: DeprecatedThemeOptions = useMemo(
    () => ({
      palette: {
        primary: {
          main: primaryColor,
          contrastText: primaryColorContrast,
        },
        username: userColor,
        background: {
          apps: appsColor,
        },
        appsTitle: appsTitleColor,
        stepper: {
          color1: color1,
          color2: color2,
          color3: color3,
          internal: internalColor,
          text: textColor,
        },
      },
    }),
    [
      appsColor,
      appsTitleColor,
      color1,
      color2,
      color3,
      internalColor,
      primaryColor,
      primaryColorContrast,
      textColor,
      userColor,
    ]
  );

  const [themeOptionsDebounced] = useDebounce(themeOptions, 500);

  useEffect(() => {
    if (themeOptionsDebounced !== undefined) {
      dispatch(setThemeOptions(themeOptionsDebounced));
    }
    if (backgroundImage !== undefined) {
      dispatch(setBackgroundImageFile(backgroundImage));
    }
    if (notNullUndefined(companyLogo)) {
      dispatch(setCompanyLogoFile(companyLogo));
    }
  }, [dispatch, backgroundImage, companyLogo, themeOptionsDebounced]);

  useEffect(() => {
    dispatch(setIsThemeFromBackend(false));
    return () => {
      dispatch(setIsThemeFromBackend(true));
    };
  }, [dispatch]);
};
