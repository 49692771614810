import { urlToFile } from "utils/urlToFile";
import { GetTileForViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = async (
  dto: NonNullable<GetTileForViewPageQuery["tile"]>
): Promise<FormFields> => {
  return {
    basicData: {
      appId: dto.app.id,
      path: dto.path,
    },
    tileType: {
      tileType: {
        hasKpi: dto.kpi != null,
        hasImage: dto.icon != null,
        shape: dto.shape,
      },
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        subtitle: e.subtitle,
        title: e.title,
      })),
    },
    icon:
      dto?.icon?.url !== undefined
        ? { icon: await urlToFile(dto.icon.url, dto.icon.name) }
        : (undefined as any), // FIXME: formFields.icon debe ser opcion
    kpi: {
      kpiId: dto?.kpi?.id ?? "", // FIXME:
    },
  };
};
