import { DevTool } from "@hookform/devtools";
import { Box, Grid, IconButton } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { Tile } from "components/navigation/Tile";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { useWatch } from "react-hook-form";
import {
  useGetBusinessObjectsForGroupFormQuery,
  useGetTilesForGroupFormQuery,
} from "__generated__/graphql/types";
import { TilesFormProps } from "./types";
import { useDataElementDescription } from "hooks/useDataElementDescription";

const TilesForm = ({
  form: { control, setValue, getValues },
  readOnly,
  onEdit,
}: TilesFormProps) => {
  const businessObjectId = useWatch({ control, name: "businessObjectId" });
  const tilesInGroup = useWatch({ control, name: "tilesIds" });
  const { dataElementsDescriptions } = useDataElementDescription([
    "BUSINESS_OBJECT",
    "DESCRIPTION",
  ]);

  const { language } = useSystemLanguage();

  const { data: businessObjects } = useGetBusinessObjectsForGroupFormQuery({
    language,
  });

  const { data: tiles } = useGetTilesForGroupFormQuery(
    {
      language,
      filters: { businessObjectId },
    },
    { enabled: businessObjectId !== undefined }
  );

  const onAdd = (tileId: string) => () => {
    const values = getValues("tilesIds") ?? [];
    setValue("tilesIds", [...values, tileId]);
  };

  const onDelete = (tileId: string) => () => {
    setValue(
      "tilesIds",
      getValues("tilesIds").filter((e) => e !== tileId)
    );
  };

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <KeyInputWrapper>
              <MatchcodeInputController
                label={dataElementsDescriptions["BUSINESS_OBJECT"]}
                controllerProps={{
                  name: "businessObjectId",
                  control,
                  rules: { required: readOnly ? "" : "Seleccione el objeto de negocio." }, // FIXME: traduccion
                }}
                column={[
                  {
                    key: "name",
                    text: dataElementsDescriptions["BUSINESS_OBJECT"],
                  },
                  {
                    key: "description",
                    text: dataElementsDescriptions["DESCRIPTION"],
                  },
                ]}
                displayColumn="name"
                options={
                  businessObjects?.businessObjects.map((e) => ({
                    id: e.dataElement.id,
                    name: e.dataElement.id,
                    description: e.dataElement.translation.description,
                  })) ?? []
                }
              />
            </KeyInputWrapper>
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>

      <Box marginTop={2}>
        <Grid container spacing={2}>
          {tiles?.tiles?.map((e) => (
            <Grid item key={e.id}>
              <AddDeleteTileWrapper
                type={tilesInGroup?.includes(e.id) ? "DELETE" : "ADD"} // FIXME:
                onAdd={onAdd(e.id)}
                onDelete={onDelete(e.id)}
              >
                <Tile
                  shape={e.shape}
                  title={e.translation.title}
                  subtitle={e.translation.subtitle}
                  indicator={""} // FIXME:
                />
              </AddDeleteTileWrapper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TilesForm;

type AddDeleteTileWrapperProps = {
  type: "ADD" | "DELETE";
  children: JSX.Element;
  onAdd?: () => void;
  onDelete?: () => void;
};

export const AddDeleteTileWrapper = ({
  children,
  type,
  onAdd,
  onDelete,
}: AddDeleteTileWrapperProps) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>{children}</Grid>
      <Grid item>
        {type === "ADD" ? (
          <IconButton
            aria-label="add"
            sx={{ color: "#f50057" }} // FIXME: hardcodeado
            onClick={onAdd}
            size="large"
          >
            <AddIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label="delete"
            sx={{ color: "#f50057" }} // FIXME: hardcodeado
            onClick={onDelete}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
