import { Box } from "@mui/material";
import React from "react";

export const FormBodyWrapper = ({
  children = null,
}: {
  children?: JSX.Element | null;
}) => <Box id="form-boddy-wrapper"marginY={2}>
  {children}
</Box>;

export default FormBodyWrapper;
