export enum PositionsRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":positionId/view",
  EDIT = ":positionId/edit",
}

export type PositionsRoutesState = {
  [PositionsRoutes.EDIT]: { activeStep?: number };
};
