import { Grid, Tooltip } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { CheckboxWithLabelController } from "components/inputs/CheckboxWithLabelController";
import { MenuInstructions } from "components/Instructor/steps/menu";
import { useAppMessages } from "hooks/useAppMessages";
import { useWatch } from "react-hook-form";
import { SendInvoiceFormProps } from "./types";


const SendInvoiceForm = ({ 
  form, 
  readOnly = false, 
  onEdit 
}: SendInvoiceFormProps) => {

  const { control } = form.send;

  const { appMessage } = useAppMessages("INVOICES");
  const send = useWatch({ control: form.send.control, name: "send" });

  var message = send ? appMessage["SEND_INVOICE_DESC"] : appMessage["NO_SEND_INVOICE_DESC"]

  return (

    <>
      {/* Instructor */}
      <MenuInstructions tutorialsIds={["INVOICES_FORM_SEND"]} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container wrap="nowrap" justifyContent="center">
          <Tooltip
            title = {message ?? ""}
          >
            <span id="checkbox-send-invoice">
              <CheckboxWithLabelController
                FormControlLabelProps={{
                  label: appMessage["SEND_INVOICE"] ?? ""
                }}
                controllerProps={{
                  name: "send",
                  control,
                  defaultValue: false,
                }}
                readOnly={readOnly}
              />
            </span>
          </Tooltip>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default SendInvoiceForm;