import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Step } from "react-joyride";
import { RootState } from "store";

export const emptyStep: Step[] =  [{
    content: "null",
    target: "null"
}]

type InstructionsState = {
    petState: "SLEEP" | "AWAKE";
    open: boolean;
    stepIndex: number;
    steps: Step[];
};

const initialState: InstructionsState = {
    petState: "SLEEP",
    open: false,
    stepIndex: 0,
    steps: emptyStep
};

const instructionsReducer = createSlice({
    name: "instructions",
    initialState,
    reducers: {
        setInstructions: (state, action: PayloadAction<any>) => {
            const { open, stepIndex, steps } = action.payload;            
            state.open = open;
            state.stepIndex = stepIndex;
            state.steps = steps;
        },
        setPetState: (state, action: PayloadAction<any>) => {
            const { petState } = action.payload;
            state.petState = petState
        },
        setCurrentStep: (state, action: PayloadAction<any>) => {
            const { stepIndex } = action.payload;
            state.stepIndex = stepIndex;
        },
    },
});

export const { 
    setPetState, 
    setCurrentStep, 
    setInstructions 
} = instructionsReducer.actions;

export const getInstructionsState = (state: RootState) => state.instructions;

export const getSteps = (state: RootState) => state.instructions.steps;
export const isInstructionsOpen = (state: RootState) => state.instructions.open;
export const getPetState = (state: RootState) => state.instructions.petState;
export const getCurrentInstructionsStep = (state: RootState) => state.instructions.stepIndex;

export default instructionsReducer.reducer;
