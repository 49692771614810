import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetKpiForViewPageQuery,
  useUpdateKpiMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { KpiRoutes } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

const EditPage = () => {
  const navigate = useNavigate();
  const { kpiId } = useRouteParams<KpiRoutes.EDIT>();

  const { mutateAsync: updateKpi } = useUpdateKpiMutation();

  const { data: { kpi } = {} } = useGetKpiForViewPageQuery({ id: kpiId });

  const initialValues = kpi && mapDtoToForm(kpi);

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  const onSave = async (form: FormFields) => {
    const dto = mapFormToDto(form);
    await updateKpi({ id: kpiId, kpi: dto });
  };

  if (initialValues == null) return null; // FIXME: loading spinner

  return (
    <>
      <Layout>
        <Header
          title="Editar KPI" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValues}
          errorMessage="Error al crear app. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
