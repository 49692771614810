import { DevTool } from "@hookform/devtools";
import React from "react";
import { RevenueNamesForm } from "./RevenueNamesForm";
import { RevenueFormProps } from "./types";

const RevenueForm = ({ form, readOnly, onEdit }: RevenueFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />
      <RevenueNamesForm form={form} readOnly={readOnly} onEdit={onEdit} />
    </>
  );
};

export default RevenueForm;
