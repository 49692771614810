import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { GenericTable } from "components/datadisplay/GenericTable";
import React from "react";
import { useTable } from "./useTable";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { GetBusinessPartners_ViewAllPageQuery } from "__generated__/graphql/types";
import { MenuItemsProps } from "components/datadisplay/GenericTable/template";

type TableProps = {
  menuItems: MenuItemsProps[];
  businessPartners: GetBusinessPartners_ViewAllPageQuery["businessPartners"];
  total?: number;
  pagination: any;
};

const Table = ({
  menuItems,
  businessPartners,
  total,
  pagination
}: TableProps) => {
  const {
    rows,
    columns,
    errorSnackbar,
    appMessage,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
  } = useTable({
    businessPartners,
    menuItems
  });


  return (
    <>
      <SnackbarAlert
        severity="error"
        onClose={errorSnackbar.close}
        open={errorSnackbar.isOpen}
      >
        {appMessage["ERROR"]}
      </SnackbarAlert>
      {total === undefined ? <BeatLoadingPage /> :
        <GenericTable
          columns={columns}
          rows={rows}
          menuItems={menuItems}
          isRowChecked={isDataElementsRowChecked}
          onChangeCheckedRow={onChangeCheckedRow}
          areAllRowsChecked={areAllDataElementsRowsChecked}
          onChangeAllRowsChecked={onChangeAllRowsChecked}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          totalRows={total ?? 0}
          page={pagination.page}
          rowsPerPage={pagination.pageSize}
        />
      }
    </>
  );
};

export default Table;
