import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetBusinessProfileForViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { BusinessProfileRoutes } from "../routes";
import { mapDtoToForm } from "./utils";
import useAppMessages from 'hooks/useAppMessages/hook';

const ViewPage = () => {
  const navigate = useNavigate();
  const { appMessage } = useAppMessages("BUSINESS_PROFILES");

  const { businessProfileId } = useRouteParams<BusinessProfileRoutes.VIEW>();

  const {
    data: { businessProfile } = {},
    isFetchedAfterMount,
  } = useGetBusinessProfileForViewPageQuery({
    id: businessProfileId,
  });

  const initialValues =
    businessProfile != null && isFetchedAfterMount
      ? mapDtoToForm(businessProfile)
      : undefined;

  const onEdit = (activeStep: number) => {
    navigate(
      `../../${generatePath(BusinessProfileRoutes.EDIT, {
        businessProfileId,
      })}`,
      { state: { activeStep } }
    );
  };

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["BP_TITLE"]}
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValues} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
