import { Box, Button, Typography } from "@mui/material";
import { TextFieldController } from "components/inputs/TextFieldController";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useUpdateMyPasswordMutation } from "__generated__/graphql/types";
import useAppMessages from 'hooks/useAppMessages/hook';

export type PasswordFormFields = {
  password: string;
  repeatedPassword: string;
};

const PasswordForm = () => {
  const { appMessage } = useAppMessages("MAIN");
  const form = useForm<PasswordFormFields>();
  const {
    mutateAsync: updateMyPassword,
    isSuccess,
  } = useUpdateMyPasswordMutation();

  const [isPasswordFieldVisible, setIsPasswordFieldVisible] = useState(false);

  const onSubmit = form.handleSubmit(async (formValue) => {
    try {
      await updateMyPassword({ password: formValue.password });
    } catch {
      // TODO:
    }
  });

  return (
    <Box>
      <Typography variant="h6">
        {appMessage["PASSWORD"]}
      </Typography>

      {isSuccess ? (
        <Box>
          {appMessage["PASS_SUCCESS"]}
        </Box>
      ) : !isPasswordFieldVisible ? (
        <Box marginTop={2}>
          <Button
            variant="contained"
            onClick={() => setIsPasswordFieldVisible(true)}
            color="primary"
          >
            {appMessage["PASS_UPDATE"]}
          </Button>
        </Box>
      ) : (
        <Box maxWidth={"300px"}>
          <form onSubmit={onSubmit}>
            <Box marginTop={2}>
              <TextFieldController
                label={appMessage["PASSWORD"]}
                controllerProps={{
                  name: "password",
                  control: form.control,
                  rules: {
                    required: appMessage["PASSWORD"] ?? "Please enter the password",///FIXPRD QUITAR
                    minLength: {
                      value: 8,
                      message:
                        appMessage["PASS_AT_LEAST_8C"] ?? "The password must have at least 8 characters",///FIXPRD QUITAR
                    },
                  },
                }}
                type="password"
              />
            </Box>

            <Box marginTop={2}>
              <TextFieldController
                label={appMessage["PASS_REPEAT"]}
                controllerProps={{
                  name: "repeatedPassword",
                  control: form.control,
                  rules: {
                    required: appMessage["PASS_REPEAT_VLD"] ?? "Write the password again", ///FIXPRD QUITAR
                    validate: (value: string) => {
                      return value === form.getValues("password")
                        ? true
                        : appMessage["PASS_NOEQUAL"] ?? "Passwords do not match"; ///FIXPRD QUITAR
                    },
                  },
                }}
                type="password"
              />
            </Box>

            <Box marginTop={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {appMessage["SAVE"]}
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default PasswordForm;
