import { Box, Checkbox, styled, TableCellProps, Tooltip } from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppActions } from "hooks/useAppActions";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath } from "utils/typedRoutesUtils";
import {
  GetDataElementsTablePageQuery,
  useDeleteDataElementMutation,
  useGetDataElementsTablePageQuery,
} from "__generated__/graphql/types";
import { DataElementsRoutes } from "../../../routes";
import {
  DataElementColumnKeys,
  dataElementsColumnsLabels,
  DATA_ELEMENTS_COLUMNS_KEYS,
  selectDataElementsVisibleColumnsKeysInOrder,
} from "../../reducers/visibleColumnsSlice";
import {
  nextPageDataElements,
  prevPageDataElements,
  resetDataElementsPage,
  selectDataElementsFilters,
  selectDataElementsPagination,
} from "../../reducers/filtersAndPaginationSlice";
import {
  checkAllDataElementsRows,
  checkDataElementsRow,
  selectAreAllDataElementsRowsChecked,
  selectIsDataElementsRowChecked,
} from "../../reducers/checkedRowsSlice";
import { TrafficLight } from "./TrafficLight";

export const useDataElementsTable = () => {
  const errorSnackbar = useSnackbar();

  const { language } = useSystemLanguage();

  const { actions } = useAppActions("DATA_ELEMENTS");

  const filters = useAppSelector(selectDataElementsFilters);

  const { mutateAsync: deleteDataElement } = useDeleteDataElementMutation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const orderedColumns = useAppSelector(
    selectDataElementsVisibleColumnsKeysInOrder
  );

  const pagination = useAppSelector(selectDataElementsPagination);

  const areAllRowsChecked = useAppSelector(selectAreAllDataElementsRowsChecked);

  const isRowChecked = useAppSelector(selectIsDataElementsRowChecked);

  const {
    data: { dataElements, dataElementsCount: total } = {},
    refetch: findDataElements,
  } = useGetDataElementsTablePageQuery({
    language,
    filters,
    pagination,
  });

  const items = useMemo(
    () => [
      {
        key: "display",
        Icon: SearchIcon,
        text: actions["READ"],
        onClick: (id: string) => {
          navigate(
            generatePath(DataElementsRoutes.VIEW, {
              dataElementId: id,
            })
          );
        },
      },
      {
        key: "edit",
        Icon: EditIcon,
        text: actions["UPDATE"],
        onClick: (id: string) => {
          navigate(
            generatePath(DataElementsRoutes.EDIT, {
              dataElementId: id,
            })
          );
        },
      },
      {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: actions["DELETE"],
        onClick: (id: string) => {
          if (dataElements?.find((e) => e.id === id)?.isUsed) {
            errorSnackbar.open(
              "El elemento de datos no se puede eliminar mientras esté siendo usado."
            );
            return;
          }

          deleteDataElement({ id })
            .then(() => dispatch(resetDataElementsPage()))
            .then(() => findDataElements())
            .catch(() =>
              errorSnackbar.open(
                "Error al eliminar el elemento de dato. Vuelva a intentar." // FIXME: traduccion
              )
            );
        },
      },
    ],
    [
      actions,
      dataElements,
      deleteDataElement,
      dispatch,
      errorSnackbar,
      findDataElements,
      navigate,
    ]
  );

  const columns = useMemo(
    () => orderedColumns.map((colKey) => header[colKey]),
    [orderedColumns]
  );

  const rows = useMemo(() => dataElements?.map(mapRowDataToRowCells), [
    dataElements,
  ]);

  const onPrevPage = useCallback(() => {
    dispatch(prevPageDataElements());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPageDataElements());
  }, [dispatch]);

  const onChangeCheckedRow = useCallback(
    (id: string, checked: boolean) => {
      dispatch(checkDataElementsRow({ id, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllDataElementsRows(checked));
    },
    [dispatch]
  );

  return {
    columns,
    rows,
    items,
    isRowChecked,
    onChangeCheckedRow,
    areAllRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    total,
    pagination,
    errorSnackbar,
  };
};

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
});

const mapRowDataToRowCells = (
  dto: NonNullable<GetDataElementsTablePageQuery["dataElements"]>[number]
): TableRowType<DataElementColumnKeys> => {
  return {
    rowKey: dto.id,
    name: dto.id.toUpperCase(),
    dataType: dto.dataType,
    // @ts-ignore FIXME:
    status: <TrafficLight status={dto.status} />,
    used: (
      <Tooltip
        title={dto.usedByTables.join(" - ").toUpperCase()}
        placement="top"
      >
        <Box display="flex" justifyContent="center">
          <StyledCheckbox
            size="small"
            checked={dto.isUsed ?? false} // dto.isUsed TODO:
            disabled
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Box>
      </Tooltip>
    ),
    length: dto.length ?? "-",
    decimals: dto.decimals ?? "-",
    isLanguageDependent: (
      <StyledCheckbox
        size="small"
        checked={dto.isLanguageDependent ?? false}
        disabled
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    ),
    description: dto.translation.description,
  };
};

const columnAlign: Partial<
  Record<DataElementColumnKeys, TableCellProps["align"]>
> = {
  status: "center",
  used: "center",
  isLanguageDependent: "center",
  length: "right",
  decimals: "right",
};

const header = Object.fromEntries(
  DATA_ELEMENTS_COLUMNS_KEYS.map((key) => [
    key,
    {
      key,
      content: dataElementsColumnsLabels[key].toUpperCase(),
      align: columnAlign[key],
    },
  ])
);
