import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const descriptions = useForm<FormFields["descriptions"]>({
    defaultValues: initialValue?.["descriptions"] ?? {
      descriptions: [emptyDescriptionRow],
    },
  });

  const icon = useForm<FormFields["icon"]>({
    defaultValues: initialValue?.["icon"],
  });

  icon.register("icon");

  const tiles = useForm<FormFields["tiles"]>({
    defaultValues: initialValue?.["tiles"],
  });

  return { basicData, descriptions, icon, tiles };
};
