export enum BusinessGroupRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":businessGroupId/view",
  EDIT = ":businessGroupId/edit",
}

export type BusinessGroupRoutesState = {
  [BusinessGroupRoutes.EDIT]: { activeStep?: number };
};
