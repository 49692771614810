import { useCallback, useMemo, useState } from "react";

const usePagination = <T>(data?: T[], rowsPerPage = 5) => {
  const [page, setPage] = useState(0);
  const totalRows = Math.ceil(data?.length ?? 1 / rowsPerPage);

  const visibleRows = useMemo(
    () =>
      data?.filter(
        (_, idx) => rowsPerPage * page <= idx && idx < rowsPerPage * (page + 1)
      ),
    [data, page, rowsPerPage]
  );

  const onNextPage = useCallback(() => setPage((p) => p + 1), []);
  const onPrevPage = useCallback(() => setPage((p) => p - 1), []);

  return {
    page,
    totalRows,
    visibleRows,
    rowsPerPage,
    onNextPage,
    onPrevPage,
  };
};

export default usePagination;
