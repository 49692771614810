import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useUsersLabels = () => {

    const firstName = "USER_FIRSTNAME";
    const lastName = "USER_LASTNAME"
    const username = "USER_ID";
    const email = "USER_EMAIL";
    const userType = "USER_TYPE";
    const businessProfile = "BUSINESS_PROFILE";
    const isLocked = "USER_IS_LOCKED";

    const dataElementsIds = [
        firstName,lastName, username, email, userType, businessProfile, isLocked
    ];

    type DetailsKeys = typeof dataElementsIds[number];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels: NonNullable<Record<ColumnsKeys, string>> = {
        firstName: dataElementsDescriptions === undefined ? firstName : (dataElementsDescriptions[firstName] ?? firstName),
        lastName: dataElementsDescriptions === undefined ? lastName : (dataElementsDescriptions[lastName] ?? lastName),
        username: dataElementsDescriptions === undefined ? username : (dataElementsDescriptions[username] ?? username),
        email: dataElementsDescriptions === undefined ? email : (dataElementsDescriptions[email] ?? email),
        userType: dataElementsDescriptions === undefined ? userType : (dataElementsDescriptions[userType] ?? userType),
        businessProfile: dataElementsDescriptions === undefined ? businessProfile : (dataElementsDescriptions[businessProfile] ?? businessProfile),
        isLocked: dataElementsDescriptions === undefined ? isLocked : (dataElementsDescriptions[isLocked] ?? isLocked),
    };

    const detailsLabels: NonNullable<Record<DetailsKeys, string>> = {
        firstName: dataElementsDescriptions === undefined ? firstName : (dataElementsDescriptions[firstName] ?? firstName),
        lastName: dataElementsDescriptions === undefined ? lastName : (dataElementsDescriptions[lastName] ?? lastName),
        username: dataElementsDescriptions === undefined ? username : (dataElementsDescriptions[username] ?? username),
        email: dataElementsDescriptions === undefined ? email : (dataElementsDescriptions[email] ?? email),
        userType: dataElementsDescriptions === undefined ? userType : (dataElementsDescriptions[userType] ?? userType),
        businessProfile: dataElementsDescriptions === undefined ? businessProfile : (dataElementsDescriptions[businessProfile] ?? businessProfile),
        isLocked: dataElementsDescriptions === undefined ? isLocked : (dataElementsDescriptions[isLocked] ?? isLocked),
    };

    return { columnsLabels, detailsLabels }
}