import { DevTool } from "@hookform/devtools";
import { Box, Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ColorPicker } from "./ColorPicker";
import useAppMessages from 'hooks/useAppMessages/hook';

export type PrimaryColorFormFields = {
  primaryColor: string;
  primaryColorContrast: string;
  userColor: string;
};

export type PrimaryColorFormProps = {
  form: UseFormReturn<PrimaryColorFormFields>;
  readOnly?: boolean;
};

const PrimaryColorForm = ({
  form,
  readOnly = false,
}: PrimaryColorFormProps) => {
  const { control } = form;

  const { appMessage } = useAppMessages("THEMES");
  return (
    <FormNonTableBodyWrapper>
      <DevTool control={control} placement="top-left" />

      <Box marginBottom={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={3}>
            <ColorPicker
              label={appMessage["PRIMARY_COLOR"]}
              disabled={readOnly}
              controllerProps={{
                name: "primaryColor",
                control,
                rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <ColorPicker
              label={appMessage["CNTR_PRIMARY_COLOR"]}
              disabled={readOnly}
              controllerProps={{
                name: "primaryColorContrast",
                control,
                rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <ColorPicker
              label={appMessage["USER_COLOR"]}
              disabled={readOnly}
              controllerProps={{
                name: "userColor",
                control,
                rules: { required: appMessage["SELECT_COLOR"] ?? "Select a color" },   ///FIXPRD QUITAR
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormNonTableBodyWrapper>
  );
};

export default PrimaryColorForm;
