import { GetBackend_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetBackend_ViewPageQuery["backend"]>
): FormFields => {
  return {
    basicData: {
      id: dto.id,
      url: dto.url,
      ip: dto.ip ?? '',
      domain: dto.domain ?? '',
    }
  };
};
