import { uploadFileS3 } from "apis/uploadFileS3Api";
import { useMutation } from "react-query";
import {
  useRegisterFileUploadedToS3Mutation,
  useRequestPermissionUploadGroupIconToS3Mutation,
} from "__generated__/graphql/types";

// TODO: extraer useUploadFileToS3
export const useUploadGroupIconToS3 = () => {
  const {
    mutateAsync: requestPermissionUploadFileToS3,
  } = useRequestPermissionUploadGroupIconToS3Mutation();

  const {
    mutateAsync: registerFileUploadedToS3Api,
  } = useRegisterFileUploadedToS3Mutation();

  return useMutation(async (icon: File) => {
    const {
      requestPermissionUploadGroupIconToS3: permission,
    } = await requestPermissionUploadFileToS3({});

    await uploadFileS3({
      file: icon,
      url: permission.url,
    });

    const {
      registerFileUploadedToS3: { fileId },
    } = await registerFileUploadedToS3Api({
      s3File: {
        key: permission.key,
        bucket: permission.bucket,
      },
    });

    return { fileId };
  });
};
