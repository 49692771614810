import { Box, Grid, IconButton, styled } from "@mui/material";
import petBotSleep from "assets/img/instructor/pet-sleep.png";
import petBot from "assets/img/instructor/pet-tutorial.png";
import logo from "assets/img/logo.png";
import { getPetState, setPetState } from "components/Instructor/reducer/instructionsReducer";
import { selectCompanyLogo } from "components/providers/ReduxMuiThemeProvider/reducer";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppMessages } from "hooks/useAppMessages";

const FabCompanyLogo = () => {
  
  const petState = useAppSelector(getPetState);
  const companyLogo = useAppSelector(selectCompanyLogo);

  const { appMessage } = useAppMessages("TUTORIALS");
  
  const dispatch = useAppDispatch();

  const openInstructions = () => {
    if(petState === "SLEEP") {
      dispatch(setPetState({
        petState: "AWAKE"
      }));
    } else {
      dispatch(setPetState({
        petState: "SLEEP"
      }));
    }
  }

  return (
    <>
      <Grid container alignItems="center" flexDirection={"row-reverse"}>
        <Grid item>
          <Box width="140px" height="60px" display={"flex"} flexDirection={"column"}>
            <StyledImg alt="logo" src={companyLogo ?? logo} />
          </Box>
        </Grid>
        {
          <Grid 
            item 
            onClick={openInstructions}
          >
            {
              petState === "SLEEP" && (
                <StyledDiv id="bubble-help">
                  <Bubble>
                    <TextWrapper>
                      {
                        appMessage["HELP_YOU"] ?? "Can I help you"
                      }
                    </TextWrapper>
                  </Bubble>
                  <Pointer />
                </StyledDiv>
              )
            }

            <IconButton size="medium">
              <BoxButton marginTop={petState === "SLEEP" ? "-25px" : "0px"}>
                <StyledImg alt="tutorial bot" src={petState === "AWAKE" ? petBot : petBotSleep} />
              </BoxButton>
            </IconButton>
          </Grid>
        }
      </Grid>
    </>
  );
};

export default FabCompanyLogo;

const StyledImg = styled("img")({
  height: "100%",
  objectFit: "contain",
});

const StyledDiv = styled("div")({
  animationName: "bounce-4",
  animationTimingFunction: "ease",
  animationDuration: "2s",
  animationIterationCount: "3",
  "@keyframes bounce-4": {
    "0%": {
      transform: "scale(1,1) translateY(0)",
    },
    "10%": {
      transform: "scale(1.1,.9) translateY(0)",
    },
    "30%": {
      transform: "scale(.9,1.1) translateY(-20px)",
    },
    "50%": {
      transform: "scale(1,1) translateY(0)",
    },
    "100%": {
      transform: "scale(1,1) translateY(0)",
    }
  }
});

const BoxButton = styled(Box)({
  height: "50px",
  objectFit: "cover",
  marginRight: "10px",
  marginLeft: "10px",
});

const Bubble = styled("div")(({ theme }) => ({
  minHeight: "20px",
  maxWidth: "120px",
  overflow: "hidden",
  background: theme.palette.primary.main,
  display: "flex",
  margin: "0 auto",
  borderRadius: "20px",
  textAlign: "center",
  justifyContent: "center",
  zIndex: 2
}));

const Pointer = styled("div")(({ theme }) => ({
  height: "20px",
  width: "20px",
  background: theme.palette.primary.main,
  margin: "0 auto",
  transform: "rotate(45deg)",
  borderRadius: "0 0 6px 0",
  marginTop: "-10px",
  position: "relative",
  left: "calc(0.5vw - 20px)",
  justifyContent: "center"
}));

const TextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "-webkit-fill-available",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  padding: "5px",
  textAlign: "center",
  fontSize: "11px",
  zIndex: 3
}));