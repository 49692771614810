import { emptyDescriptionRow } from "components/inputs/DescriptionFormControl/template";
import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { emptyGroupRow } from "./GroupsForm/template";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {
  const basicData = useForm<FormFields["basicData"]>({
    defaultValues: initialValue?.["basicData"],
  });

  const descriptions = useForm<FormFields["descriptions"]>({
    defaultValues: initialValue?.["descriptions"] ?? {
      descriptions: [emptyDescriptionRow],
    },
  });

  const groups = useForm<FormFields["groups"]>({
    defaultValues: initialValue?.["groups"] ?? {
      groups: [emptyGroupRow],
    },
  });

  const permissions = useForm<FormFields["permissions"]>({
    defaultValues: initialValue?.["permissions"],
  });

  return { basicData, descriptions, groups, permissions };
};
