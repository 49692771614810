export enum PurchaseOrdersItemsDirectRoutes {
  PATH = "/",
  PATH_INVOICES = "invoices",
  PATH_INVOICES_VIEW = "invoices/view",
  PATH_INVOICES_EDIT = "invoices/edit",
  PATH_INVOICES_CREATE = "invoices/create",
}

export type PurchaseOrdersItemsDirectRoutesState = {
  activeStep: number;
  purchaseOrderId: string;
  purchaseOrderItemsIds: string[];
  invoiceNumber: string;
  toBack: string;
};