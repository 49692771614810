import { DevTool } from "@hookform/devtools";
import {
  AddCircle as AddCircleIcon,
  Clear as ClearIcon,
  Edit as EditCircleIcon
} from "@mui/icons-material";
import {
  IconButton,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import { useAppMessages } from "hooks/useAppMessages";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useLanguagesOptions } from "hooks/useLanguagesOptions";
import {
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn
} from "react-hook-form";
import {
  containsDefaultLanguage,
  isRepeatedLanguage
} from "utils/translations";
import { FormTableTextFieldController } from "../FormTableTextFieldController";
import {
  DescriptionFormControlProps,
  DescriptionFormFields,
  DescriptionRowFields
} from "./types";

export const emptyDescriptionRow: DescriptionRowFields = {
  language: "EN",
  text: "",
};

const DescriptionFormControl = ({
  form,
  readOnly = false,
  onEdit,
}: DescriptionFormControlProps) => {

  const { control } = form;

  const { fields, append, remove } = useFieldArray<DescriptionFormFields>({
    control,
    name: "descriptions",
  });

  const { dataElementsDescriptions } = useDataElementDescription([
    "LANGUAGE",
    "DESCRIPTION",
  ]);

  const { appMessage } = useAppMessages("MESSAGES");

  const appendRow = () => {
    append(emptyDescriptionRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  if (dataElementsDescriptions == null) return <BeatLoadingPage />;

  return (
    <>
      <DevTool control={control} placement="top-left" />
      <TableContainer>
        <StyledTable aria-label="simple table" size="small">
          <StyledTableHead>
            <TableRow>
              <StyledHeadTableCell
                align="left"
                style={{
                  minWidth: 150, // FIXME:
                  width: 1, // FIXME:
                }}
              >
                {dataElementsDescriptions["LANGUAGE"]}
              </StyledHeadTableCell>

              <StyledHeadTableCell align="left">
                {dataElementsDescriptions["DESCRIPTION"]}
              </StyledHeadTableCell>

              <TableCell
                align="right"
                style={{ width: 1 }} // FIXME:
              >
                {readOnly ? (
                  <Tooltip
                  title={appMessage["EDIT"] ?? "Edit"}
                  >
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={() => onEdit?.()}
                    >
                      <StyledEditCircleIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={appMessage["ADD_ROW"] ?? "Add row"}
                  >
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={appendRow}
                    >
                      <StyledAddCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </StyledTableHead>

          <StyledTableBody>
            {fields.map((item, index) => (
              <Row
                key={item.id}
                index={index}
                remove={onRemove(index)}
                item={item}
                readOnly={readOnly}
                form={form}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

export default DescriptionFormControl;

type RowProps = {
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<DescriptionFormFields>["fields"][number];
  form: UseFormReturn<DescriptionFormFields>;
};

const Row = ({ index, remove, item, readOnly, form }: RowProps) => {
  const { control, getValues, trigger } = form;

  const { languagesOptions } = useLanguagesOptions();
  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `descriptions.${index}.language` as "descriptions.0.language",
            control,
            rules: {
              required: appMessage["CHOOSE_ONE"] ?? "Choose one",
              validate: (language) => {
                if (readOnly) return true;

                const descriptions = getValues("descriptions");

                if (isRepeatedLanguage(descriptions, language))
                  return appMessage["DATA_REPEATED"] ?? "Data repeated";

                if (!containsDefaultLanguage(descriptions))
                  return appMessage["EN_LANGUAGE"] ?? "Must include default language (EN)";

                return true;
              },
            },
            defaultValue: item.language,
          }}
          select
        >
          {languagesOptions.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </FormTableTextFieldController>
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextFieldController
          inputProps={{ disabled: readOnly }}
          controllerProps={{
            name: `descriptions.${index}.text` as "descriptions.0.text",
            control,
            rules: { required: appMessage["FIELD_REQUIRED"] ?? "Field required" },
            defaultValue: item.text,
          }}
        />
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <IconButton
            aria-label="remove"
            size="small"
            onClick={() => {
              remove();
              trigger();
            }}
          >
            <StyledClearIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "uppercase",
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
