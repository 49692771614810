import { GetKpiForViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetKpiForViewPageQuery["kpi"]>
): FormFields => {
  return {
    basicData: {
      name: dto.id,
      type: dto.type,
      businessObjectId: dto.businessObject.id,
    },
    configure: {
      tableId: dto.configuration?.tableId ?? "", // FIXME:
      columnName: dto.configuration?.columnId ?? "", // FIXME:
      summarization: dto.configuration?.aggregationType ?? "", // FIXME:
      criteria: "", // FIXME:
      filters:
        (dto.configuration?.filters?.reduce(
          (acc, value) => ({
            ...acc,
            [value.columnId as string]: value.reference as string, // FIXME:
          }),
          {}
        ) as any) ?? {}, // FIXME:
    },
    develop: {
      develop: "", // TODO:
    },
  };
};
