import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetCountryViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { CountriesRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

const ViewPage = () => {
  const navigate = useNavigate();

  const { countryId } = useRouteParams<CountriesRoutes.VIEW>();

  const {
    data: { country } = {},
    isFetchedAfterMount,
  } = useGetCountryViewPageQuery({
    id: countryId,
  });

  const initialValue =
    country != null && isFetchedAfterMount ? mapDtoToForm(country) : undefined;

  const onEdit = (activeStep: number) => {
    navigate(`../../${generatePath(CountriesRoutes.EDIT, { countryId })}`, {
      state: { activeStep },
    });
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Crear país" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValue} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
