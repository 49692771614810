import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '../../config/reactQueryGraphQLFetcher';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Amount: any;
  S3PresignedUrlFile: any;
  Void: any;
  _FieldSet: any;
};








export type AccountingEntity = {
  __typename?: 'AccountingEntity';
  id: Scalars['ID'];
  name: Scalars['String'];
  company: Company;
  currency: FisCurrency;
  address: Address;
};

export type AccountingEntityFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  names?: Maybe<Array<Scalars['String']>>;
};

export type AccountingEntityInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  companyId: Scalars['ID'];
  currencyId: Scalars['ID'];
  address: AddressInput;
};

export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  number: Scalars['String'];
  floor?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
};

export type AddressInput = {
  street: Scalars['String'];
  number: Scalars['String'];
  floor?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
};

export type AggregationType =
  | 'COUNT';


export type App = {
  __typename?: 'App';
  id: Scalars['ID'];
  businessObject: BusinessObject;
  translations: Array<AppTranslation>;
  translation: AppTranslation;
  sections: Array<AppSection>;
  actions: Array<AppAction>;
  messages: Array<AppMessage>;
};


export type AppTranslationArgs = {
  language: Scalars['String'];
};

export type AppAction = {
  __typename?: 'AppAction';
  id: Scalars['ID'];
  translations: Array<AppActionTranslation>;
  translation: AppActionTranslation;
  app: App;
};


export type AppActionTranslationArgs = {
  language: Scalars['String'];
};

export type AppActionInput = {
  id: Scalars['ID'];
  translations: Array<AppActionTranslationInput>;
};

export type AppActionTranslation = {
  __typename?: 'AppActionTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type AppActionTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type AppActionsInput = {
  appId: Scalars['ID'];
  actionsIds: Array<Scalars['ID']>;
};

export type AppIds =
  | 'ACCOUNTING_ENTITIES'
  | 'APPS'
  | 'BACKENDS'
  | 'BUSINESS_GROUPS'
  | 'BUSINESS_OBJECTS'
  | 'BUSINESS_PARTNERS'
  | 'BUSINESS_PARTNER_TYPES'
  | 'BUSINESS_PROFILES'
  | 'BUSINESS_UNITS'
  | 'CHART_OF_ACCOUNTS'
  | 'COMMERCIAL_ENTITIES'
  | 'COMPANIES'
  | 'COST_COLLECTORS'
  | 'COUNTRIES'
  | 'COUNTRIES_IDS_TYPES'
  | 'CURRENCIES'
  | 'DATA_ELEMENTS'
  | 'EMPLOYEES'
  | 'EXPORT_MIGRATIONS_PACKAGES'
  | 'GROUPS'
  | 'IMPORT_MIGRATIONS_PACKAGES'
  | 'INVOICES'
  | 'INVOICES_REPORTS'
  | 'KPIS'
  | 'LANGUAGES'
  | 'LOCATIONS'
  | 'LOCATION_TYPES'
  | 'MESSAGES'
  | 'PAYMENTS'
  | 'POSITIONS'
  | 'PURCHASE_ENTITIES'
  | 'PURCHASE_ORDERS'
  | 'PURCHASE_ORDERS_ITEMS'
  | 'PURCHASE_ORDERS_ITEMS_REPORTS'
  | 'REVENUE_COLLECTORS'
  | 'TABLES'
  | 'THEMES'
  | 'TILES'
  | 'TUTORIALS'
  | 'UNITS'
  | 'USERS';

export type AppInput = {
  id: Scalars['ID'];
  businessObjectId: Scalars['String'];
  translations: Array<AppTranslationInput>;
  sections: Array<AppSectionInput>;
  actions: Array<AppActionInput>;
  messages: Array<AppMessageInput>;
};

export type AppMessage = {
  __typename?: 'AppMessage';
  id: Scalars['ID'];
  translations: Array<AppMessageTranslation>;
  translation: AppMessageTranslation;
};


export type AppMessageTranslationArgs = {
  language: Scalars['String'];
};

export type AppMessageInput = {
  id: Scalars['ID'];
  translations: Array<AppMessageTranslationInput>;
};

export type AppMessageTranslation = {
  __typename?: 'AppMessageTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type AppMessageTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type AppSection = {
  __typename?: 'AppSection';
  id: Scalars['ID'];
  translations: Array<AppSectionTranslation>;
  translation: AppSectionTranslation;
};


export type AppSectionTranslationArgs = {
  language: Scalars['String'];
};

export type AppSectionInput = {
  id: Scalars['ID'];
  translations: Array<AppSectionTranslationInput>;
};

export type AppSectionTranslation = {
  __typename?: 'AppSectionTranslation';
  language: Scalars['String'];
  step: Scalars['String'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
};

export type AppSectionTranslationInput = {
  language: Scalars['String'];
  step?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  subtitle: Scalars['String'];
};

export type AppTranslation = {
  __typename?: 'AppTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type AppTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type AppsFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  dataElementId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  groupsIds?: Maybe<Array<Scalars['ID']>>;
};

export type Backend = {
  __typename?: 'Backend';
  id: Scalars['ID'];
  url: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type BackendInput = {
  id: Scalars['ID'];
  url: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type BackendsFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type BusinessGroup = {
  __typename?: 'BusinessGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  currency: FisCurrency;
  chartOfAccount: ChartOfAccount;
};

export type BusinessGroupFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  names?: Maybe<Array<Scalars['String']>>;
};

export type BusinessGroupInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currencyId: Scalars['ID'];
  chartOfAccountId: Scalars['ID'];
};

export type BusinessObject = {
  __typename?: 'BusinessObject';
  id: Scalars['ID'];
  dataElement: DataElement;
  status: BusinessObjectStatus;
  tables: Array<BusinessObjectTable>;
};

export type BusinessObjectDataMigration = {
  __typename?: 'BusinessObjectDataMigration';
  businessObjectId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

export type BusinessObjectDataMigrationInput = {
  businessObjectId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

export type BusinessObjectInput = {
  dataElementId: Scalars['String'];
  tablesIds: Array<BusinessObjectTableInput>;
};

export type BusinessObjectStatus =
  | 'ACTIVE'
  | 'INACTIVE';

export type BusinessObjectTable = {
  __typename?: 'BusinessObjectTable';
  table: Table;
  isHeaderTable: Scalars['Boolean'];
};

export type BusinessObjectTableInput = {
  tableId: Scalars['String'];
  isHeader: Scalars['Boolean'];
};

export type BusinessObjectsFilters = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<BusinessObjectStatus>>;
  description?: Maybe<Scalars['String']>;
};

export type BusinessPartner = {
  __typename?: 'BusinessPartner';
  id: Scalars['ID'];
  name: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  taxName?: Maybe<Scalars['String']>;
  identification: BusinessPartnerCategory;
  locations: Array<BusinessPartnerLocation>;
  taxId?: Maybe<BusinessPartnerTaxId>;
  businessPartnerType: Array<BusinessPartnerBusinessPartnerType>;
  contacts: Array<BusinessPartnerContact>;
  backend: Array<BusinessPartnerBackend>;
};

export type BusinessPartnerBackend = {
  __typename?: 'BusinessPartnerBackend';
  backendId: Scalars['ID'];
  externalId: Scalars['ID'];
};

export type BusinessPartnerBackendInput = {
  backendId: Scalars['ID'];
  externalId: Scalars['ID'];
};

export type BusinessPartnerBusinessPartnerType = {
  __typename?: 'BusinessPartnerBusinessPartnerType';
  id: Scalars['ID'];
};

export type BusinessPartnerBusinessPartnerTypeInput = {
  businessPartnerTypeId: Scalars['ID'];
};

export type BusinessPartnerCategory =
  | 'PERSON'
  | 'COMPANY';

export type BusinessPartnerContact = {
  __typename?: 'BusinessPartnerContact';
  userId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['ID']>;
};

export type BusinessPartnerContactInput = {
  userId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['ID']>;
};

export type BusinessPartnerInput = {
  name: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  taxName?: Maybe<Scalars['String']>;
  identification: BusinessPartnerCategory;
  locations: Array<BusinessPartnerLocationInput>;
  taxId: BusinessPartnerTaxIdInput;
  businessPartnerType: Array<BusinessPartnerBusinessPartnerTypeInput>;
  contact: Array<BusinessPartnerContactInput>;
  backend: Array<BusinessPartnerBackendInput>;
};

export type BusinessPartnerLocation = {
  __typename?: 'BusinessPartnerLocation';
  id: Scalars['ID'];
  address: Address;
  isFiscalAddress: Scalars['Boolean'];
};

export type BusinessPartnerLocationInput = {
  address: AddressInput;
  isFiscalAddress: Scalars['Boolean'];
};

export type BusinessPartnerTaxId = {
  __typename?: 'BusinessPartnerTaxId';
  countryId: Scalars['ID'];
  typeId: IdType;
  id: Scalars['ID'];
};

export type BusinessPartnerTaxIdInput = {
  countryId: Scalars['ID'];
  typeId: Scalars['ID'];
  id: Scalars['ID'];
};

export type BusinessPartnerType = {
  __typename?: 'BusinessPartnerType';
  id: Scalars['ID'];
  translations: Array<BusinessPartnerTypeTranslation>;
  translation: BusinessPartnerTypeTranslation;
};


export type BusinessPartnerTypeTranslationArgs = {
  language: Scalars['String'];
};

export type BusinessPartnerTypeFilters = {
  id?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
};

export type BusinessPartnerTypeInput = {
  id: Scalars['ID'];
  translations: Array<BusinessPartnerTypeTranslationInput>;
};

export type BusinessPartnerTypeTranslation = {
  __typename?: 'BusinessPartnerTypeTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type BusinessPartnerTypeTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type BusinessPartnersBackendFilters = {
  backendId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
};

export type BusinessPartnersFilters = {
  id?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  taxName?: Maybe<Scalars['String']>;
  identification?: Maybe<BusinessPartnerCategory>;
  backend?: Maybe<BusinessPartnersBackendFilters>;
};

export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  id: Scalars['ID'];
  translation: BusinessProfileTranslation;
  translations: Array<BusinessProfileTranslation>;
  groups: Array<Group>;
  appsActions: Array<AppAction>;
};


export type BusinessProfileTranslationArgs = {
  language: Scalars['String'];
};

export type BusinessProfileInput = {
  id: Scalars['ID'];
  groupsIds: Array<Scalars['ID']>;
  appsActionsIds: Array<AppActionsInput>;
  translations: Array<BusinessProfileTranslationInput>;
};

export type BusinessProfileTranslation = {
  __typename?: 'BusinessProfileTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type BusinessProfileTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type BusinessProfilesFilters = {
  id?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  id: Scalars['ID'];
  company: Company;
  businessUnitCategory: BusinessUnitCategory;
  translations: Array<BusinessUnitTranslation>;
  translation: BusinessUnitTranslation;
  revenueCollectors?: Maybe<Array<RevenueCollector>>;
  costCollectors?: Maybe<Array<CostCollector>>;
};


export type BusinessUnitTranslationArgs = {
  language: Scalars['String'];
};

export type BusinessUnitCategory =
  | 'REVENUE'
  | 'COST'
  | 'PROFIT';

export type BusinessUnitFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  companies?: Maybe<Array<Scalars['ID']>>;
};

export type BusinessUnitInput = {
  companyId: Scalars['ID'];
  type: BusinessUnitCategory;
  translations: Array<BusinessUnitTranslationInput>;
  revenueCollectors?: Maybe<Array<Scalars['ID']>>;
  costCollectors?: Maybe<Array<Scalars['ID']>>;
};

export type BusinessUnitTranslation = {
  __typename?: 'BusinessUnitTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type BusinessUnitTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type ChartOfAccount = {
  __typename?: 'ChartOfAccount';
  id: Scalars['ID'];
  translations: Array<ChartOfAccountTranslation>;
  translation: ChartOfAccountTranslation;
};


export type ChartOfAccountTranslationArgs = {
  language: Scalars['String'];
};

export type ChartOfAccountFilters = {
  ids?: Maybe<Array<Scalars['String']>>;
};

export type ChartOfAccountInput = {
  id: Scalars['String'];
  translations: Array<ChartOfAccountTranslationInput>;
};

export type ChartOfAccountTranslation = {
  __typename?: 'ChartOfAccountTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type ChartOfAccountTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CommercialEntity = {
  __typename?: 'CommercialEntity';
  id: Scalars['ID'];
  company: Company;
  translations: Array<CommercialEntityTranslation>;
  translation: CommercialEntityTranslation;
};


export type CommercialEntityTranslationArgs = {
  language: Scalars['String'];
};

export type CommercialEntityFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type CommercialEntityInput = {
  id: Scalars['ID'];
  company: Scalars['ID'];
  translations: Array<CommercialEntityTranslationInput>;
};

export type CommercialEntityTranslation = {
  __typename?: 'CommercialEntityTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CommercialEntityTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  currency: FisCurrency;
  chartOfAccount: ChartOfAccount;
};

export type CompanyFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  names?: Maybe<Array<Scalars['String']>>;
};

export type CompanyInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currencyId: Scalars['ID'];
  chartOfAccountId: Scalars['ID'];
};

export type ComparisionInt = {
  gte?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};

export type ComparisonType =
  | 'EQUAL';

export type CostCollector = {
  __typename?: 'CostCollector';
  id: Scalars['ID'];
  company: Company;
  descriptions: Array<CostCollectorTranslation>;
  description: CostCollectorTranslation;
};


export type CostCollectorDescriptionArgs = {
  language: Scalars['String'];
};

export type CostCollectorFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  companyId?: Maybe<Scalars['String']>;
};

export type CostCollectorInput = {
  descriptions: Array<CostCollectorTranslationInput>;
  company: Scalars['ID'];
};

export type CostCollectorTranslation = {
  __typename?: 'CostCollectorTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CostCollectorTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  translations: Array<CountryTranslation>;
  translation: CountryTranslation;
  regions: Array<CountryRegion>;
};


export type CountryTranslationArgs = {
  language: Scalars['String'];
};

export type CountryFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type CountryIdsType = {
  __typename?: 'CountryIdsType';
  country: Country;
  idsTypes: Array<IdType>;
};

export type CountryIdsTypeFilters = {
  countryIds?: Maybe<Array<Scalars['ID']>>;
};

export type CountryIdsTypeInput = {
  countryId: Scalars['ID'];
  idsTypes: Array<IdTypeInput>;
};

export type CountryInput = {
  id: Scalars['String'];
  translations: Array<CountryTranslationInput>;
  regions: Array<CountryRegionInput>;
};

export type CountryRegion = {
  __typename?: 'CountryRegion';
  id: Scalars['ID'];
  translations: Array<CountryRegionTranslation>;
  translation: CountryRegionTranslation;
};


export type CountryRegionTranslationArgs = {
  language: Scalars['String'];
};

export type CountryRegionInput = {
  id: Scalars['ID'];
  translations: Array<CountryRegionTranslationInput>;
};

export type CountryRegionTranslation = {
  __typename?: 'CountryRegionTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CountryRegionTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CountryTranslation = {
  __typename?: 'CountryTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CountryTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CurrencyFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type CurrencyInput = {
  id: Scalars['ID'];
  translations: Array<CurrencyTranslationInput>;
};

export type CurrencyTranslation = {
  __typename?: 'CurrencyTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type CurrencyTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type DataElement = {
  __typename?: 'DataElement';
  id: Scalars['ID'];
  dataType: DataElementTypeName;
  length?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  isLanguageDependent?: Maybe<Scalars['Boolean']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  usedByTables: Array<Scalars['String']>;
  status: DataElementStatus;
  translations: Array<DataElementTranslation>;
  translation: DataElementTranslation;
  choices?: Maybe<Array<DataElementChoice>>;
};


export type DataElementTranslationArgs = {
  language: Scalars['String'];
};

export type DataElementChoice = {
  __typename?: 'DataElementChoice';
  id: Scalars['ID'];
  translations: Array<DataElementChoiceTranslation>;
  translation: DataElementChoiceTranslation;
};


export type DataElementChoiceTranslationArgs = {
  language: Scalars['String'];
};

export type DataElementChoiceInput = {
  id: Scalars['ID'];
  translations: Array<DataElementChoiceTranslationInput>;
};

export type DataElementChoiceTranslation = {
  __typename?: 'DataElementChoiceTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type DataElementChoiceTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type DataElementFilters = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  used?: Maybe<Scalars['Boolean']>;
  dataType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  length?: Maybe<ComparisionInt>;
  decimals?: Maybe<ComparisionInt>;
  isLanguageDependent?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type DataElementInput = {
  id: Scalars['ID'];
  dataType: DataElementTypeName;
  length?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  isLanguageDependent?: Maybe<Scalars['Boolean']>;
  translations: Array<DataElementTranslationInput>;
  choices?: Maybe<Array<DataElementChoiceInput>>;
};

export type DataElementStatus =
  | 'ACTIVE'
  | 'INACTIVE';

export type DataElementTranslation = {
  __typename?: 'DataElementTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type DataElementTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type DataElementTypeName =
  | 'VARCHAR'
  | 'INTEGER'
  | 'DECIMAL'
  | 'BOOLEAN'
  | 'AMOUNT'
  | 'QUANTITY'
  | 'CURRENCY'
  | 'UNIT'
  | 'LANGUAGE'
  | 'TIMESTAMP'
  | 'DATE'
  | 'TIME'
  | 'UUID'
  | 'CHOICE';

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID'];
  type: EmployeeType;
  businessPartnerId?: Maybe<Scalars['ID']>;
  accountingEntityId?: Maybe<Scalars['ID']>;
  positionHistory: Array<PositionHistory>;
  contact: EmployeeContact;
};

export type EmployeeContact = {
  __typename?: 'EmployeeContact';
  user?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type EmployeeContactInput = {
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type EmployeeInput = {
  type: EmployeeType;
  businessPartnerId?: Maybe<Scalars['ID']>;
  accountingEntityId?: Maybe<Scalars['ID']>;
  positionHistory: Array<PositionsHistoryInput>;
  contact: EmployeeContactInput;
};

export type EmployeeType =
  | 'INTERNAL'
  | 'EXTERNAL';

export type EmployeesFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  types?: Maybe<Array<EmployeeType>>;
};

export type ErrorDetail =
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  | 'UNKNOWN'
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  | 'FIELD_NOT_FOUND'
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  | 'INVALID_CURSOR'
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  | 'UNIMPLEMENTED'
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  | 'INVALID_ARGUMENT'
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  | 'DEADLINE_EXCEEDED'
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  | 'SERVICE_ERROR'
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  | 'THROTTLED_CPU'
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  | 'THROTTLED_CONCURRENCY'
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  | 'ENHANCE_YOUR_CALM'
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  | 'TCP_FAILURE'
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  | 'MISSING_RESOURCE';

export type ErrorType =
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  | 'UNKNOWN'
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  | 'INTERNAL'
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  | 'NOT_FOUND'
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  | 'UNAUTHENTICATED'
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  | 'PERMISSION_DENIED'
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  | 'BAD_REQUEST'
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  | 'UNAVAILABLE'
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  | 'FAILED_PRECONDITION';

export type FileUploadedToS3Input = {
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export type FisCurrency = {
  __typename?: 'FisCurrency';
  id: Scalars['ID'];
  translations: Array<CurrencyTranslation>;
  translation: CurrencyTranslation;
};


export type FisCurrencyTranslationArgs = {
  language: Scalars['String'];
};

/**  gate */
export type Gate = {
  __typename?: 'Gate';
  id: Scalars['ID'];
  isInput: Scalars['Boolean'];
  isOutput: Scalars['Boolean'];
  translations: Array<GateTranslation>;
  translation: GateTranslation;
};


/**  gate */
export type GateTranslationArgs = {
  language: Scalars['String'];
};

/**  gate */
export type GateInput = {
  id: Scalars['ID'];
  isInput: Scalars['Boolean'];
  isOutput: Scalars['Boolean'];
  translations: Array<GateTranslationInput>;
};

export type GateTranslation = {
  __typename?: 'GateTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type GateTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  translation: GroupTranslation;
  translations: Array<GroupTranslation>;
  tiles: Array<Tile>;
  icon: S3File;
};


export type GroupTranslationArgs = {
  language: Scalars['String'];
};

export type GroupInput = {
  id: Scalars['ID'];
  translations: Array<GroupTranslationInput>;
  iconId: Scalars['String'];
  tilesIds: Array<Scalars['String']>;
};

export type GroupTranslation = {
  __typename?: 'GroupTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type GroupTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type GroupsFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type IdType = {
  __typename?: 'IdType';
  id: Scalars['ID'];
  isTaxId: Scalars['Boolean'];
};

export type IdTypeInput = {
  id: Scalars['ID'];
  isTaxId: Scalars['Boolean'];
};

export type Instruction = {
  __typename?: 'Instruction';
  stepNumber: Scalars['Int'];
  stepName: Scalars['String'];
  target: Scalars['String'];
  style: InstructionStyle;
  clickEnabled: Scalars['Boolean'];
  translation: TutorialTranslation;
  translations: Array<TutorialTranslation>;
};


export type InstructionTranslationArgs = {
  language: Scalars['String'];
};

export type InstructionInput = {
  stepNumber: Scalars['Int'];
  stepName: Scalars['String'];
  target: Scalars['String'];
  style: InstructionStyle;
  clickEnabled: Scalars['Boolean'];
  translations: Array<TutorialTranslationInput>;
};

export type InstructionStyle =
  | 'TOP'
  | 'BOTTOM'
  | 'LEFT'
  | 'RIGHT'
  | 'CENTER';

export type InvoiceCreationType =
  | 'ACCOUNTED_SAP'
  | 'CREATED_PIC';

export type InvoiceFilters = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  purchaseOrder?: Maybe<Scalars['String']>;
  purchaseOrderItem?: Maybe<Scalars['String']>;
  accountingEntity?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceVendorNumber?: Maybe<Scalars['String']>;
  invoiceBackendNumber?: Maybe<Scalars['String']>;
  fiscalYear?: Maybe<Scalars['String']>;
  headerText?: Maybe<Scalars['String']>;
  plannedPaymentDate?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Array<InvoiceStatus>>;
  creationType?: Maybe<Array<InvoiceCreationType>>;
};

export type InvoiceHeader = {
  __typename?: 'InvoiceHeader';
  id: Scalars['ID'];
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  backend: Scalars['String'];
  accountingEntity: Scalars['String'];
  vendorId: Scalars['String'];
  currency: Scalars['String'];
  invoiceDate: Scalars['String'];
  invoiceVendorNumber?: Maybe<Scalars['String']>;
  invoiceBackendNumber?: Maybe<Scalars['String']>;
  fiscalYear: Scalars['String'];
  headerText: Scalars['String'];
  total: Scalars['Amount'];
  plannedPaymentDate?: Maybe<Scalars['String']>;
  lastPaymentDate?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<Scalars['String']>;
  invoiceStatus: InvoiceStatus;
  creationType?: Maybe<InvoiceCreationType>;
  file: S3File;
};

export type InvoiceInput = {
  accountingEntity: Scalars['String'];
  vendorId: Scalars['String'];
  currency: Scalars['String'];
  invoiceDate: Scalars['String'];
  invoiceVendorNumber: Scalars['String'];
  fiscalYear?: Maybe<Scalars['String']>;
  backend: Scalars['String'];
  headerText: Scalars['String'];
  physicalInvoice: Scalars['String'];
  toTransfer?: Maybe<Scalars['Boolean']>;
  items: Array<Maybe<InvoiceItemInput>>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  id: Scalars['ID'];
  backend: Scalars['String'];
  invoiceNumber: Scalars['String'];
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  productId: Scalars['String'];
  unit: Scalars['String'];
  quantity: Scalars['Amount'];
  netUnitPrice: Scalars['Amount'];
  netTotalPrice: Scalars['Amount'];
  taxesAmount: Scalars['Amount'];
  totalPrice: Scalars['Amount'];
  itemText?: Maybe<Scalars['String']>;
  extraChargesAmount?: Maybe<Scalars['Amount']>;
};

export type InvoiceItemFilters = {
  id?: Maybe<Scalars['ID']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  purchaseOrderItem?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Amount']>;
  netUnitPrice?: Maybe<Scalars['Amount']>;
  netTotalPrice?: Maybe<Scalars['Amount']>;
  taxesAmount?: Maybe<Scalars['Amount']>;
  totalPrice?: Maybe<Scalars['Amount']>;
  itemText?: Maybe<Scalars['String']>;
};

export type InvoiceItemInput = {
  id?: Maybe<Scalars['ID']>;
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  backend: Scalars['String'];
  productId: Scalars['String'];
  unit: Scalars['String'];
  quantity: Scalars['Amount'];
  netUnitPrice: Scalars['Amount'];
  taxesAmount: Scalars['Amount'];
  itemText?: Maybe<Scalars['String']>;
  extraChargesAmount?: Maybe<Scalars['Amount']>;
};

export type InvoiceItemStatus =
  | 'INCOMPLETE'
  | 'COMPLETE';

export type InvoiceStatus =
  | 'CREATED'
  | 'TRANSFERRED'
  | 'ACCOUNTING'
  | 'REJECTED'
  | 'CANCELLED'
  | 'PARTIAL_PAYMENT'
  | 'PAID';

export type ItemType =
  | 'INVENTORY'
  | 'DELIVERY'
  | 'SERVICE';

export type Kpi = {
  __typename?: 'Kpi';
  id: Scalars['ID'];
  businessObject: BusinessObject;
  type: KpiType;
  configuration?: Maybe<KpiConfiguration>;
  value?: Maybe<Scalars['String']>;
};

export type KpiConfiguration = {
  __typename?: 'KpiConfiguration';
  tableId?: Maybe<Scalars['ID']>;
  columnId?: Maybe<Scalars['ID']>;
  aggregationType?: Maybe<AggregationType>;
  filters?: Maybe<Array<KpiConfigurationFilter>>;
};

export type KpiConfigurationFilter = {
  __typename?: 'KpiConfigurationFilter';
  columnId?: Maybe<Scalars['ID']>;
  comparisonType?: Maybe<ComparisonType>;
  reference?: Maybe<Scalars['String']>;
};

export type KpiConfigurationFilterInput = {
  columnId?: Maybe<Scalars['String']>;
  comparisonType?: Maybe<ComparisonType>;
  reference?: Maybe<Scalars['String']>;
};

export type KpiConfigurationInput = {
  tableId?: Maybe<Scalars['String']>;
  columnId?: Maybe<Scalars['String']>;
  aggregationType?: Maybe<AggregationType>;
  filters?: Maybe<Array<KpiConfigurationFilterInput>>;
};

export type KpiFilters = {
  id?: Maybe<Scalars['ID']>;
};

export type KpiInput = {
  id: Scalars['ID'];
  businessObjectId: Scalars['String'];
  type: KpiType;
  configuration?: Maybe<KpiConfigurationInput>;
};

export type KpiType =
  | 'DEVELOP'
  | 'CONFIGURE';

export type Language = {
  __typename?: 'Language';
  id: Scalars['ID'];
  translations: Array<LanguageTranslation>;
  translation: LanguageTranslation;
};


export type LanguageTranslationArgs = {
  language: Scalars['String'];
};

export type LanguageFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type LanguageInput = {
  id: Scalars['ID'];
  translations: Array<LanguageTranslationInput>;
};

export type LanguageTranslation = {
  __typename?: 'LanguageTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type LanguageTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

/**  level */
export type Level = {
  __typename?: 'Level';
  id: Scalars['ID'];
  translation: LevelTranslation;
  translations: Array<LevelTranslation>;
  slots: Array<Slot>;
};


/**  level */
export type LevelTranslationArgs = {
  language: Scalars['String'];
};

/**  level */
export type LevelInput = {
  id: Scalars['ID'];
  translations: Array<LevelTranslationInput>;
  slots: Array<SlotInput>;
};

export type LevelTranslation = {
  __typename?: 'LevelTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type LevelTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  address: Address;
  translations: Array<LocationTranslation>;
  translation: LocationTranslation;
  locationType: LocationType;
  gates: Array<Gate>;
  storages: Array<Storage>;
};


export type LocationTranslationArgs = {
  language: Scalars['String'];
};

export type LocationFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  id: Scalars['ID'];
  address: AddressInput;
  translations: Array<LocationTranslationInput>;
  locationType: Scalars['ID'];
  gates?: Maybe<Array<GateInput>>;
  storages: Array<StorageInput>;
};

export type LocationTranslation = {
  __typename?: 'LocationTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type LocationTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type LocationType = {
  __typename?: 'LocationType';
  id: Scalars['ID'];
  translations: Array<LocationTypeTranslation>;
  translation: LocationTypeTranslation;
};


export type LocationTypeTranslationArgs = {
  language: Scalars['String'];
};

export type LocationTypeFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type LocationTypeInput = {
  id: Scalars['ID'];
  translations: Array<LocationTypeTranslationInput>;
};

export type LocationTypeTranslation = {
  __typename?: 'LocationTypeTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type LocationTypeTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  translations: Array<MessageTranslation>;
  translation: MessageTranslation;
};


export type MessageTranslationArgs = {
  language: Scalars['String'];
};

export type MessageFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type MessageInput = {
  id: Scalars['String'];
  translations: Array<MessageTranslationInput>;
};

export type MessageTranslation = {
  __typename?: 'MessageTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type MessageTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type MigrationPackage = {
  __typename?: 'MigrationPackage';
  systemId: Scalars['ID'];
  packageId: Scalars['ID'];
  description: Scalars['String'];
  status: MigrationPackageStatus;
  isApplied: Scalars['Boolean'];
  appliedAt?: Maybe<Scalars['String']>;
  definition: Array<BusinessObjectDataMigration>;
  publishedAt: Scalars['String'];
};

export type MigrationPackageInput = {
  description: Scalars['String'];
  data: Array<BusinessObjectDataMigrationInput>;
};

export type MigrationPackageOrigin =
  | 'INTERNAL'
  | 'EXTERNAL';

export type MigrationPackageStatus =
  | 'DRAFT'
  | 'PUBLISHED';

export type Mutation = {
  __typename?: 'Mutation';
  createAccountingEntity?: Maybe<Scalars['Void']>;
  updateAccountingEntity?: Maybe<Scalars['Void']>;
  deleteAccountingEntity?: Maybe<Scalars['Void']>;
  createApp?: Maybe<Scalars['Void']>;
  updateApp?: Maybe<Scalars['Void']>;
  deleteApp?: Maybe<Scalars['Void']>;
  createBackend?: Maybe<Scalars['Void']>;
  updateBackend?: Maybe<Scalars['Void']>;
  deleteBackend?: Maybe<Scalars['Void']>;
  generateBackendRandomPassword: Scalars['String'];
  createBusinessGroup?: Maybe<Scalars['Void']>;
  updateBusinessGroup?: Maybe<Scalars['Void']>;
  deleteBusinessGroup?: Maybe<Scalars['Void']>;
  createBusinessObject?: Maybe<Scalars['Void']>;
  updateBusinessObject?: Maybe<Scalars['Void']>;
  deleteBusinessObject?: Maybe<Scalars['Void']>;
  createBusinessPartnerType?: Maybe<Scalars['Void']>;
  updateBusinessPartnerType?: Maybe<Scalars['Void']>;
  deleteBusinessPartnerType?: Maybe<Scalars['Void']>;
  createBusinessPartner?: Maybe<Scalars['Void']>;
  updateBusinessPartner?: Maybe<Scalars['Void']>;
  deleteBusinessPartner?: Maybe<Scalars['Void']>;
  createBusinessProfile?: Maybe<Scalars['ID']>;
  updateBusinessProfile?: Maybe<Scalars['ID']>;
  deleteBusinessProfile?: Maybe<Scalars['ID']>;
  createBusinessUnit?: Maybe<Scalars['Void']>;
  updateBusinessUnit?: Maybe<Scalars['Void']>;
  deleteBusinessUnit?: Maybe<Scalars['Void']>;
  createChartOfAccount?: Maybe<Scalars['Void']>;
  updateChartOfAccount?: Maybe<Scalars['Void']>;
  deleteChartOfAccount?: Maybe<Scalars['Void']>;
  createCommercialEntity?: Maybe<Scalars['Void']>;
  updateCommercialEntity?: Maybe<Scalars['Void']>;
  deleteCommercialEntity?: Maybe<Scalars['Void']>;
  createCompany?: Maybe<Scalars['Void']>;
  updateCompany?: Maybe<Scalars['Void']>;
  deleteCompany?: Maybe<Scalars['Void']>;
  createCostCollector?: Maybe<Scalars['Void']>;
  updateCostCollector?: Maybe<Scalars['Void']>;
  deleteCostCollector?: Maybe<Scalars['Void']>;
  createCountry?: Maybe<Scalars['Void']>;
  updateCountry?: Maybe<Scalars['Void']>;
  deleteCountry?: Maybe<Scalars['Void']>;
  createCountryIdsTypes?: Maybe<Scalars['Void']>;
  updateCountryIdsTypes?: Maybe<Scalars['Void']>;
  deleteCountryIdsTypes?: Maybe<Scalars['Void']>;
  createCurrency?: Maybe<Scalars['Void']>;
  updateCurrency?: Maybe<Scalars['Void']>;
  deleteCurrency?: Maybe<Scalars['Void']>;
  createDataElement?: Maybe<Scalars['Void']>;
  updateDataElement?: Maybe<Scalars['Void']>;
  deleteDataElement?: Maybe<Scalars['Void']>;
  createEmployee?: Maybe<Scalars['Void']>;
  updateEmployee?: Maybe<Scalars['Void']>;
  deleteEmployee?: Maybe<Scalars['Void']>;
  createGroup?: Maybe<Scalars['ID']>;
  updateGroup?: Maybe<Scalars['ID']>;
  deleteGroup?: Maybe<Scalars['ID']>;
  requestPermissionUploadGroupIconToS3: SignatureUploadFileS3;
  createInvoice?: Maybe<Scalars['Void']>;
  updateInvoice?: Maybe<Scalars['Void']>;
  deleteInvoice?: Maybe<Scalars['Void']>;
  updateInvoiceStatus?: Maybe<Scalars['Void']>;
  requestPermissionUploadInvoiceFileToS3: SignatureUploadFileS3;
  createKpi?: Maybe<Scalars['Void']>;
  updateKpi?: Maybe<Scalars['Void']>;
  deleteKpi?: Maybe<Scalars['Void']>;
  createLanguage?: Maybe<Scalars['Void']>;
  updateLanguage?: Maybe<Scalars['Void']>;
  deleteLanguage?: Maybe<Scalars['Void']>;
  createLocationType?: Maybe<Scalars['Void']>;
  updateLocationType?: Maybe<Scalars['Void']>;
  deleteLocationType?: Maybe<Scalars['Void']>;
  createLocation?: Maybe<Scalars['Void']>;
  updateLocation?: Maybe<Scalars['Void']>;
  deleteLocation?: Maybe<Scalars['Void']>;
  updateMyPassword?: Maybe<Scalars['Void']>;
  updateMyImage?: Maybe<Scalars['Void']>;
  updateMyProfile?: Maybe<Scalars['Void']>;
  createMessage?: Maybe<Scalars['Void']>;
  updateMessage?: Maybe<Scalars['Void']>;
  deleteMessage?: Maybe<Scalars['Void']>;
  createMigrationPackage?: Maybe<Scalars['Void']>;
  updateMigrationPackage?: Maybe<Scalars['Void']>;
  deleteMigrationPackage?: Maybe<Scalars['Void']>;
  publishMigrationPackage?: Maybe<Scalars['Void']>;
  applyMigrationPackage?: Maybe<Scalars['Void']>;
  createPosition?: Maybe<Scalars['Void']>;
  updatePosition?: Maybe<Scalars['Void']>;
  deletePosition?: Maybe<Scalars['Void']>;
  createPurchaseEntity?: Maybe<Scalars['Void']>;
  updatePurchaseEntity?: Maybe<Scalars['Void']>;
  deletePurchaseEntity?: Maybe<Scalars['Void']>;
  createRevenueCollector?: Maybe<Scalars['Void']>;
  updateRevenueCollector?: Maybe<Scalars['Void']>;
  deleteRevenueCollector?: Maybe<Scalars['Void']>;
  registerFileUploadedToS3: RegisterFileUploadedToS3Payload;
  createTable?: Maybe<Scalars['Void']>;
  updateTable?: Maybe<Scalars['Void']>;
  deleteTable?: Maybe<Scalars['Void']>;
  activateTable?: Maybe<Scalars['Void']>;
  deactivateTable?: Maybe<Scalars['Void']>;
  createTheme?: Maybe<Scalars['Void']>;
  updateTheme?: Maybe<Scalars['Void']>;
  deleteTheme?: Maybe<Scalars['Void']>;
  setDefaultTheme?: Maybe<Scalars['Void']>;
  requestPermissionUploadThemeBackgroundImageToS3: SignatureUploadFileS3;
  createTile?: Maybe<Scalars['Void']>;
  updateTile?: Maybe<Scalars['Void']>;
  deleteTile?: Maybe<Scalars['Void']>;
  requestPermissionUploadTileIconToS3: SignatureUploadFileS3;
  createTutorial?: Maybe<Scalars['Void']>;
  updateTutorial?: Maybe<Scalars['Void']>;
  deleteTutorial?: Maybe<Scalars['Void']>;
  createUnit?: Maybe<Scalars['Void']>;
  updateUnit?: Maybe<Scalars['Void']>;
  deleteUnit?: Maybe<Scalars['Void']>;
  createUser: Scalars['String'];
  updateUser?: Maybe<Scalars['Void']>;
  updatePassword?: Maybe<Scalars['Void']>;
  deleteUser?: Maybe<Scalars['Void']>;
  lockUser?: Maybe<Scalars['Void']>;
  requestPermissionUploadUserImageToS3: SignatureUploadFileS3;
};


export type MutationCreateAccountingEntityArgs = {
  accountingEntity: AccountingEntityInput;
};


export type MutationUpdateAccountingEntityArgs = {
  id: Scalars['ID'];
  accountingEntity: AccountingEntityInput;
};


export type MutationDeleteAccountingEntityArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAppArgs = {
  app: AppInput;
};


export type MutationUpdateAppArgs = {
  id: Scalars['ID'];
  app: AppInput;
};


export type MutationDeleteAppArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBackendArgs = {
  backend: BackendInput;
};


export type MutationUpdateBackendArgs = {
  id: Scalars['ID'];
  backend: BackendInput;
};


export type MutationDeleteBackendArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateBackendRandomPasswordArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessGroupArgs = {
  businessGroup: BusinessGroupInput;
};


export type MutationUpdateBusinessGroupArgs = {
  id: Scalars['ID'];
  businessGroup: BusinessGroupInput;
};


export type MutationDeleteBusinessGroupArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessObjectArgs = {
  businessObject: BusinessObjectInput;
};


export type MutationUpdateBusinessObjectArgs = {
  id: Scalars['ID'];
  businessObject: BusinessObjectInput;
};


export type MutationDeleteBusinessObjectArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessPartnerTypeArgs = {
  businessPartnerType: BusinessPartnerTypeInput;
};


export type MutationUpdateBusinessPartnerTypeArgs = {
  id: Scalars['ID'];
  businessPartnerType: BusinessPartnerTypeInput;
};


export type MutationDeleteBusinessPartnerTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessPartnerArgs = {
  businessPartner: BusinessPartnerInput;
};


export type MutationUpdateBusinessPartnerArgs = {
  id: Scalars['ID'];
  businessPartner: BusinessPartnerInput;
};


export type MutationDeleteBusinessPartnerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessProfileArgs = {
  businessProfile: BusinessProfileInput;
};


export type MutationUpdateBusinessProfileArgs = {
  id: Scalars['ID'];
  businessProfile: BusinessProfileInput;
};


export type MutationDeleteBusinessProfileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessUnitArgs = {
  businessUnit: BusinessUnitInput;
};


export type MutationUpdateBusinessUnitArgs = {
  id: Scalars['ID'];
  businessUnit: BusinessUnitInput;
};


export type MutationDeleteBusinessUnitArgs = {
  id: Scalars['ID'];
};


export type MutationCreateChartOfAccountArgs = {
  chartOfAccount: ChartOfAccountInput;
};


export type MutationUpdateChartOfAccountArgs = {
  id: Scalars['ID'];
  chartOfAccount: ChartOfAccountInput;
};


export type MutationDeleteChartOfAccountArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCommercialEntityArgs = {
  commercialEntity: CommercialEntityInput;
};


export type MutationUpdateCommercialEntityArgs = {
  id: Scalars['ID'];
  commercialEntity: CommercialEntityInput;
};


export type MutationDeleteCommercialEntityArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  company: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  company: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCostCollectorArgs = {
  costCollector: CostCollectorInput;
};


export type MutationUpdateCostCollectorArgs = {
  id: Scalars['ID'];
  costCollector: CostCollectorInput;
};


export type MutationDeleteCostCollectorArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCountryArgs = {
  country: CountryInput;
};


export type MutationUpdateCountryArgs = {
  id: Scalars['ID'];
  country: CountryInput;
};


export type MutationDeleteCountryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCountryIdsTypesArgs = {
  countryIdsTypes: CountryIdsTypeInput;
};


export type MutationUpdateCountryIdsTypesArgs = {
  id: Scalars['ID'];
  countryIdsTypes: CountryIdsTypeInput;
};


export type MutationDeleteCountryIdsTypesArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCurrencyArgs = {
  currency: CurrencyInput;
};


export type MutationUpdateCurrencyArgs = {
  id: Scalars['ID'];
  currency: CurrencyInput;
};


export type MutationDeleteCurrencyArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDataElementArgs = {
  dataElement: DataElementInput;
};


export type MutationUpdateDataElementArgs = {
  id: Scalars['ID'];
  dataElement: DataElementInput;
};


export type MutationDeleteDataElementArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEmployeeArgs = {
  employee: EmployeeInput;
};


export type MutationUpdateEmployeeArgs = {
  id: Scalars['ID'];
  employee: EmployeeInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGroupArgs = {
  group: GroupInput;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  group: GroupInput;
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInvoiceArgs = {
  invoice: InvoiceInput;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID'];
  invoice: InvoiceInput;
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateInvoiceStatusArgs = {
  id: Scalars['ID'];
  invoiceStatus: InvoiceStatus;
};


export type MutationCreateKpiArgs = {
  kpi: KpiInput;
};


export type MutationUpdateKpiArgs = {
  id: Scalars['ID'];
  kpi: KpiInput;
};


export type MutationDeleteKpiArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLanguageArgs = {
  language: LanguageInput;
};


export type MutationUpdateLanguageArgs = {
  id: Scalars['ID'];
  language: LanguageInput;
};


export type MutationDeleteLanguageArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLocationTypeArgs = {
  locationType: LocationTypeInput;
};


export type MutationUpdateLocationTypeArgs = {
  id: Scalars['ID'];
  locationType: LocationTypeInput;
};


export type MutationDeleteLocationTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLocationArgs = {
  location: LocationInput;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['ID'];
  location: LocationInput;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMyPasswordArgs = {
  password: Scalars['String'];
};


export type MutationUpdateMyImageArgs = {
  imageId: Scalars['String'];
};


export type MutationUpdateMyProfileArgs = {
  userProfile: UpdatableUserProfileInput;
};


export type MutationCreateMessageArgs = {
  message: MessageInput;
};


export type MutationUpdateMessageArgs = {
  id: Scalars['ID'];
  message: MessageInput;
};


export type MutationDeleteMessageArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMigrationPackageArgs = {
  migrationPackage: MigrationPackageInput;
};


export type MutationUpdateMigrationPackageArgs = {
  id: Scalars['ID'];
  migrationPackage: MigrationPackageInput;
};


export type MutationDeleteMigrationPackageArgs = {
  id: Scalars['ID'];
};


export type MutationPublishMigrationPackageArgs = {
  id: Scalars['ID'];
};


export type MutationApplyMigrationPackageArgs = {
  systemId: Scalars['ID'];
  packageId: Scalars['ID'];
};


export type MutationCreatePositionArgs = {
  position: PositionInput;
};


export type MutationUpdatePositionArgs = {
  id: Scalars['ID'];
  position: PositionInput;
};


export type MutationDeletePositionArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePurchaseEntityArgs = {
  purchaseEntity: PurchaseEntityInput;
};


export type MutationUpdatePurchaseEntityArgs = {
  id: Scalars['ID'];
  purchaseEntity: PurchaseEntityInput;
};


export type MutationDeletePurchaseEntityArgs = {
  id: Scalars['ID'];
};


export type MutationCreateRevenueCollectorArgs = {
  revenueCollector: RevenueCollectorInput;
};


export type MutationUpdateRevenueCollectorArgs = {
  id: Scalars['ID'];
  revenueCollector: RevenueCollectorInput;
};


export type MutationDeleteRevenueCollectorArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterFileUploadedToS3Args = {
  s3File: FileUploadedToS3Input;
};


export type MutationCreateTableArgs = {
  table: TableInput;
};


export type MutationUpdateTableArgs = {
  id: Scalars['ID'];
  table: TableInput;
};


export type MutationDeleteTableArgs = {
  id: Scalars['ID'];
};


export type MutationActivateTableArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateTableArgs = {
  id: Scalars['ID'];
};


export type MutationCreateThemeArgs = {
  theme: ThemeInput;
};


export type MutationUpdateThemeArgs = {
  id: Scalars['ID'];
  theme: ThemeInput;
};


export type MutationDeleteThemeArgs = {
  id: Scalars['ID'];
};


export type MutationSetDefaultThemeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTileArgs = {
  tile: TileInput;
};


export type MutationUpdateTileArgs = {
  id: Scalars['ID'];
  tile: TileInput;
};


export type MutationDeleteTileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTutorialArgs = {
  tutorial: TutorialInput;
};


export type MutationUpdateTutorialArgs = {
  id: Scalars['ID'];
  tutorial: TutorialInput;
};


export type MutationDeleteTutorialArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUnitArgs = {
  unit: UnitInput;
};


export type MutationUpdateUnitArgs = {
  id: Scalars['ID'];
  unit: UnitInput;
};


export type MutationDeleteUnitArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  user: UserInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  user: UserInput;
};


export type MutationUpdatePasswordArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationLockUserArgs = {
  id: Scalars['ID'];
  lock?: Maybe<Scalars['Boolean']>;
};

export type Pagination = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  backend: Scalars['String'];
  invoiceNumber: Scalars['String'];
  accountingEntity: Scalars['String'];
  vendorId: Scalars['String'];
  invoiceVendorNumber?: Maybe<Scalars['String']>;
  invoiceBackendNumber?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Amount']>;
};

export type PaymentFilters = {
  id?: Maybe<Scalars['ID']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  accountingEntity?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  invoiceBackendNumber?: Maybe<Scalars['String']>;
  invoiceVendorNumber?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Amount']>;
};

export type Position = {
  __typename?: 'Position';
  id: Scalars['ID'];
  translations: Array<PositionTranslation>;
  translation: PositionTranslation;
};


export type PositionTranslationArgs = {
  language: Scalars['String'];
};

export type PositionFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type PositionHistory = {
  __typename?: 'PositionHistory';
  positionId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type PositionInput = {
  id: Scalars['String'];
  translations: Array<PositionTranslationInput>;
};

export type PositionTranslation = {
  __typename?: 'PositionTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type PositionTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type PositionsHistoryInput = {
  positionId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type PrimaryKeyColumnsFilters = {
  dataElementId: Scalars['String'];
};

export type PurchaseEntity = {
  __typename?: 'PurchaseEntity';
  id: Scalars['ID'];
  translations: Array<PurchaseEntityTranslation>;
  translation: PurchaseEntityTranslation;
  company: Company;
};


export type PurchaseEntityTranslationArgs = {
  language: Scalars['String'];
};

export type PurchaseEntityFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type PurchaseEntityInput = {
  id: Scalars['ID'];
  translations: Array<PurchaseEntityTranslationInput>;
  companyId: Scalars['ID'];
};

export type PurchaseEntityTranslation = {
  __typename?: 'PurchaseEntityTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type PurchaseEntityTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type PurchaseOrderFilters = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  accountingEntity?: Maybe<Scalars['String']>;
  accountingEntityDescription?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorMappingType?: Maybe<Scalars['String']>;
  purchaseEntity?: Maybe<Scalars['String']>;
  purchaseOrderType?: Maybe<Scalars['String']>;
  purchaseGroup?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type PurchaseOrderHeader = {
  __typename?: 'PurchaseOrderHeader';
  id: Scalars['ID'];
  backend: Scalars['String'];
  accountingEntity: Scalars['String'];
  accountingEntityDescription?: Maybe<Scalars['String']>;
  vendorId: Scalars['String'];
  vendorName?: Maybe<Scalars['String']>;
  vendorMappingType: Scalars['String'];
  purchaseEntity: Scalars['String'];
  purchaseOrderType: Scalars['String'];
  purchaseGroup: Scalars['String'];
  currency: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  netTotalPrice?: Maybe<Scalars['Amount']>;
  purchaseOrderStatus?: Maybe<PurchaseOrderStatus>;
  extraCharges: Scalars['Boolean'];
};

export type PurchaseOrderItem = {
  __typename?: 'PurchaseOrderItem';
  purchaseOrderItem: Scalars['String'];
  purchaseOrder: Scalars['String'];
  backend: Scalars['String'];
  productId: Scalars['String'];
  productDescription: Scalars['String'];
  location: Scalars['String'];
  locationDescription?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  productGroup: Scalars['String'];
  unit: Scalars['String'];
  type: ItemType;
  poQuantity: Scalars['Amount'];
  netUnitPrice: Scalars['Amount'];
  netTotalPrice: Scalars['Amount'];
  grQuantity?: Maybe<Scalars['Amount']>;
  grAmount?: Maybe<Scalars['Amount']>;
  picInvoicedQuantity?: Maybe<Scalars['Amount']>;
  picInvoicedAmount?: Maybe<Scalars['Amount']>;
  invoiceApprovedQuantity?: Maybe<Scalars['Amount']>;
  invoiceApprovedAmount?: Maybe<Scalars['Amount']>;
  remainingQuantity?: Maybe<Scalars['Amount']>;
  remainingAmount?: Maybe<Scalars['Amount']>;
  plannedDeliveryDate?: Maybe<Scalars['String']>;
  needGr?: Maybe<Scalars['Boolean']>;
  tolerance?: Maybe<ToleranceCatalogue>;
  tolerancePercentage?: Maybe<Scalars['Amount']>;
  toleranceAmount?: Maybe<Scalars['Amount']>;
  hasInvoice: Scalars['Boolean'];
  completeInvoice?: Maybe<InvoiceItemStatus>;
};

export type PurchaseOrderItemFilters = {
  purchaseOrderItem?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  backend?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationDescription?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  poQuantity?: Maybe<Scalars['Amount']>;
  netUnitPrice?: Maybe<Scalars['Amount']>;
  netTotalPrice?: Maybe<Scalars['Amount']>;
  plannedDeliveryDate?: Maybe<Scalars['String']>;
  needGr?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrderStatus =
  | 'OPEN'
  | 'CLOSE'
  | 'VOID';

export type Query = {
  __typename?: 'Query';
  accountingEntities: Array<AccountingEntity>;
  accountingEntitiesCount: Scalars['Int'];
  accountingEntity?: Maybe<AccountingEntity>;
  accountingEntityExists: Scalars['Boolean'];
  apps: Array<App>;
  appsCount: Scalars['Int'];
  app?: Maybe<App>;
  appExists: Scalars['Boolean'];
  backends: Array<Backend>;
  backendsCount: Scalars['Int'];
  backend?: Maybe<Backend>;
  backendExists: Scalars['Boolean'];
  businessGroups: Array<BusinessGroup>;
  businessGroupsCount: Scalars['Int'];
  businessGroup?: Maybe<BusinessGroup>;
  businessGroupsExists: Scalars['Boolean'];
  idsInBusinessObject: Array<Scalars['ID']>;
  businessObject?: Maybe<BusinessObject>;
  businessObjects: Array<BusinessObject>;
  businessObjectsCount: Scalars['Int'];
  businessPartnerTypes: Array<BusinessPartnerType>;
  businessPartnerTypesCount: Scalars['Int'];
  businessPartnerType?: Maybe<BusinessPartnerType>;
  businessPartnerTypeExists: Scalars['Boolean'];
  businessPartners: Array<BusinessPartner>;
  businessPartnersCount: Scalars['Int'];
  businessPartner?: Maybe<BusinessPartner>;
  businessPartnerExists: Scalars['Boolean'];
  businessProfiles: Array<BusinessProfile>;
  businessProfilesCount: Scalars['Int'];
  businessProfile?: Maybe<BusinessProfile>;
  businessProfileExists: Scalars['Boolean'];
  businessUnits: Array<BusinessUnit>;
  businessUnitsCount: Scalars['Int'];
  businessUnit?: Maybe<BusinessUnit>;
  chartOfAccounts: Array<ChartOfAccount>;
  chartOfAccountsCount: Scalars['Int'];
  chartOfAccount?: Maybe<ChartOfAccount>;
  chartOfAccountExists: Scalars['Boolean'];
  commercialEntities: Array<CommercialEntity>;
  commercialEntitiesCount: Scalars['Int'];
  commercialEntity?: Maybe<CommercialEntity>;
  commercialEntityExists: Scalars['Boolean'];
  companies: Array<Company>;
  companiesCount: Scalars['Int'];
  company?: Maybe<Company>;
  companiesExists: Scalars['Boolean'];
  costCollectors: Array<CostCollector>;
  costCollectorsCount: Scalars['Int'];
  costCollector?: Maybe<CostCollector>;
  costCollectorsExists: Scalars['Boolean'];
  countries: Array<Country>;
  countriesCount: Scalars['Int'];
  country?: Maybe<Country>;
  countryExists: Scalars['Boolean'];
  countriesIdsTypes: Array<CountryIdsType>;
  countriesIdsTypesCount: Scalars['Int'];
  countryIdsTypes?: Maybe<CountryIdsType>;
  countryIdsTypesExist: Scalars['Boolean'];
  currencies: Array<FisCurrency>;
  currenciesCount: Scalars['Int'];
  currency?: Maybe<FisCurrency>;
  currencyExists: Scalars['Boolean'];
  dataElements: Array<DataElement>;
  dataElementsCount: Scalars['Int'];
  dataElement?: Maybe<DataElement>;
  dataElementExists: Scalars['Boolean'];
  employees: Array<Employee>;
  employeesCount: Scalars['Int'];
  employee?: Maybe<Employee>;
  employeeExists: Scalars['Boolean'];
  groups: Array<Group>;
  groupsCount: Scalars['Int'];
  group?: Maybe<Group>;
  groupExists: Scalars['Boolean'];
  invoiceItems?: Maybe<Array<Maybe<InvoiceItem>>>;
  invoiceItemsCount: Scalars['Int'];
  invoiceItem?: Maybe<Array<Maybe<InvoiceItem>>>;
  invoiceItemExist: Scalars['Boolean'];
  invoices?: Maybe<Array<Maybe<InvoiceHeader>>>;
  invoicesCount: Scalars['Int'];
  purchaseOrdersInvoices?: Maybe<Array<Maybe<InvoiceHeader>>>;
  purchaseOrdersInvoicesCount: Scalars['Int'];
  invoice?: Maybe<InvoiceHeader>;
  invoiceExist: Scalars['Boolean'];
  kpis: Array<Kpi>;
  kpisCount: Scalars['Int'];
  kpi?: Maybe<Kpi>;
  kpiExists: Scalars['Boolean'];
  languages: Array<Language>;
  languagesCount: Scalars['Int'];
  language?: Maybe<Language>;
  languageExists: Scalars['Boolean'];
  locationTypes: Array<LocationType>;
  locationTypesCount: Scalars['Int'];
  locationType?: Maybe<LocationType>;
  locationTypeExists: Scalars['Boolean'];
  locations: Array<Location>;
  locationsCount: Scalars['Int'];
  location?: Maybe<Location>;
  locationExists: Scalars['Boolean'];
  me: User;
  amIAuthenticated: Scalars['Boolean'];
  messages: Array<Message>;
  messagesCount: Scalars['Int'];
  message?: Maybe<Message>;
  messageExists: Scalars['Boolean'];
  ownMigrationPackages: Array<MigrationPackage>;
  ownMigrationPackagesCount: Scalars['Int'];
  ownMigrationPackage?: Maybe<MigrationPackage>;
  externalMigrationPackages: Array<MigrationPackage>;
  externalMigrationPackagesCount: Scalars['Int'];
  externalMigrationPackage?: Maybe<MigrationPackage>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  paymentsCount: Scalars['Int'];
  payment?: Maybe<Payment>;
  paymentExist: Scalars['Boolean'];
  positions: Array<Position>;
  positionsCount: Scalars['Int'];
  position?: Maybe<Position>;
  positionExists: Scalars['Boolean'];
  purchaseEntities: Array<PurchaseEntity>;
  purchaseEntitiesCount: Scalars['Int'];
  purchaseEntity?: Maybe<PurchaseEntity>;
  purchaseEntityExists: Scalars['Boolean'];
  allPurchaseOrderItems: Array<PurchaseOrderItem>;
  allPurchaseOrderItemsCount: Scalars['Int'];
  deliveryPurchaseOrderItems: Array<PurchaseOrderItem>;
  deliveryPurchaseOrderItemsCount: Scalars['Int'];
  purchaseOrderItems: Array<PurchaseOrderItem>;
  purchaseOrderItemsCount: Scalars['Int'];
  purchaseOrderItem?: Maybe<PurchaseOrderItem>;
  purchaseOrderItemExist: Scalars['Boolean'];
  purchaseOrders: Array<PurchaseOrderHeader>;
  purchaseOrdersCount: Scalars['Int'];
  purchaseOrder?: Maybe<PurchaseOrderHeader>;
  purchaseOrderExist: Scalars['Boolean'];
  revenueCollectors: Array<RevenueCollector>;
  revenueCollectorsCount: Scalars['Int'];
  revenueCollector?: Maybe<RevenueCollector>;
  revenueCollectorExists: Scalars['Boolean'];
  stockMovementsItems: Array<StockMovementItem>;
  stockMovementsItemsCount: Scalars['Int'];
  stockMovementItem?: Maybe<StockMovementItem>;
  stockMovementItemExist: Scalars['Boolean'];
  stockMovement?: Maybe<StockMovementHeader>;
  stockMovementExist: Scalars['Boolean'];
  tables: Array<Table>;
  tablesCount: Scalars['Int'];
  table?: Maybe<Table>;
  tableMigrations: Array<TableMigration>;
  tableExists: Scalars['Boolean'];
  themes: Array<Theme>;
  themesCount: Scalars['Int'];
  theme?: Maybe<Theme>;
  themeExists: Scalars['Boolean'];
  tile?: Maybe<Tile>;
  tiles: Array<Tile>;
  tilesCount: Scalars['Int'];
  userTiles: Array<Tile>;
  tutorials: Array<Tutorial>;
  tutorial: Tutorial;
  tutorialsCount?: Maybe<Scalars['Int']>;
  tutorialExists: Scalars['Boolean'];
  units: Array<Unit>;
  unitsCount: Scalars['Int'];
  unit?: Maybe<Unit>;
  unitExists: Scalars['Boolean'];
  users: Array<User>;
  usersCount: Scalars['Int'];
  user?: Maybe<User>;
  userExists: Scalars['Boolean'];
  userTypes: Array<UserType>;
  _service?: Maybe<_Service>;
};


export type QueryAccountingEntitiesArgs = {
  filters?: Maybe<AccountingEntityFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryAccountingEntitiesCountArgs = {
  filters?: Maybe<AccountingEntityFilters>;
};


export type QueryAccountingEntityArgs = {
  id: Scalars['ID'];
};


export type QueryAccountingEntityExistsArgs = {
  id: Scalars['ID'];
};


export type QueryAppsArgs = {
  filters?: Maybe<AppsFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryAppsCountArgs = {
  filters?: Maybe<AppsFilters>;
};


export type QueryAppArgs = {
  id: Scalars['ID'];
};


export type QueryAppExistsArgs = {
  id: Scalars['ID'];
};


export type QueryBackendsArgs = {
  filters?: Maybe<BackendsFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryBackendsCountArgs = {
  filters?: Maybe<BackendsFilters>;
};


export type QueryBackendArgs = {
  id: Scalars['ID'];
};


export type QueryBackendExistsArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessGroupsArgs = {
  filters?: Maybe<BusinessGroupFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryBusinessGroupsCountArgs = {
  filters?: Maybe<BusinessGroupFilters>;
};


export type QueryBusinessGroupArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessGroupsExistsArgs = {
  id: Scalars['ID'];
};


export type QueryIdsInBusinessObjectArgs = {
  businessObjectId: Scalars['ID'];
  pagination?: Maybe<Pagination>;
};


export type QueryBusinessObjectArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessObjectsArgs = {
  filters?: Maybe<BusinessObjectsFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryBusinessObjectsCountArgs = {
  filters?: Maybe<BusinessObjectsFilters>;
};


export type QueryBusinessPartnerTypesArgs = {
  filters?: Maybe<BusinessPartnerTypeFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryBusinessPartnerTypesCountArgs = {
  filters?: Maybe<BusinessPartnerTypeFilters>;
};


export type QueryBusinessPartnerTypeArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessPartnerTypeExistsArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessPartnersArgs = {
  filters?: Maybe<BusinessPartnersFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryBusinessPartnersCountArgs = {
  filters?: Maybe<BusinessPartnersFilters>;
};


export type QueryBusinessPartnerArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessPartnerExistsArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessProfilesArgs = {
  filters?: Maybe<BusinessProfilesFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryBusinessProfilesCountArgs = {
  filters?: Maybe<BusinessProfilesFilters>;
};


export type QueryBusinessProfileArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessProfileExistsArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessUnitsArgs = {
  filters?: Maybe<BusinessUnitFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryBusinessUnitsCountArgs = {
  filters?: Maybe<BusinessUnitFilters>;
};


export type QueryBusinessUnitArgs = {
  id: Scalars['ID'];
};


export type QueryChartOfAccountsArgs = {
  filters?: Maybe<ChartOfAccountFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryChartOfAccountsCountArgs = {
  filters?: Maybe<ChartOfAccountFilters>;
};


export type QueryChartOfAccountArgs = {
  id: Scalars['ID'];
};


export type QueryChartOfAccountExistsArgs = {
  id: Scalars['ID'];
};


export type QueryCommercialEntitiesArgs = {
  filters?: Maybe<CommercialEntityFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryCommercialEntitiesCountArgs = {
  filters?: Maybe<CommercialEntityFilters>;
};


export type QueryCommercialEntityArgs = {
  id: Scalars['ID'];
};


export type QueryCommercialEntityExistsArgs = {
  id: Scalars['ID'];
};


export type QueryCompaniesArgs = {
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryCompaniesCountArgs = {
  filters?: Maybe<CompanyFilters>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCompaniesExistsArgs = {
  id: Scalars['ID'];
};


export type QueryCostCollectorsArgs = {
  filters?: Maybe<CostCollectorFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryCostCollectorsCountArgs = {
  filters?: Maybe<CostCollectorFilters>;
};


export type QueryCostCollectorArgs = {
  id: Scalars['ID'];
};


export type QueryCostCollectorsExistsArgs = {
  id: Scalars['ID'];
};


export type QueryCountriesArgs = {
  filters?: Maybe<CountryFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryCountriesCountArgs = {
  filters?: Maybe<CountryFilters>;
};


export type QueryCountryArgs = {
  id: Scalars['ID'];
};


export type QueryCountryExistsArgs = {
  id: Scalars['ID'];
};


export type QueryCountriesIdsTypesArgs = {
  filters?: Maybe<CountryIdsTypeFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryCountriesIdsTypesCountArgs = {
  filters?: Maybe<CountryIdsTypeFilters>;
};


export type QueryCountryIdsTypesArgs = {
  id: Scalars['ID'];
};


export type QueryCountryIdsTypesExistArgs = {
  countryId: Scalars['ID'];
};


export type QueryCurrenciesArgs = {
  filters?: Maybe<CurrencyFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryCurrenciesCountArgs = {
  filters?: Maybe<CurrencyFilters>;
};


export type QueryCurrencyArgs = {
  id: Scalars['ID'];
};


export type QueryCurrencyExistsArgs = {
  id: Scalars['ID'];
};


export type QueryDataElementsArgs = {
  filters?: Maybe<DataElementFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryDataElementsCountArgs = {
  filters?: Maybe<DataElementFilters>;
};


export type QueryDataElementArgs = {
  id: Scalars['ID'];
};


export type QueryDataElementExistsArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeesArgs = {
  filters?: Maybe<EmployeesFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryEmployeesCountArgs = {
  filters?: Maybe<EmployeesFilters>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeeExistsArgs = {
  id: Scalars['ID'];
};


export type QueryGroupsArgs = {
  filters?: Maybe<GroupsFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryGroupsCountArgs = {
  filters?: Maybe<GroupsFilters>;
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGroupExistsArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceItemsArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  filters?: Maybe<InvoiceItemFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryInvoiceItemsCountArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  filters?: Maybe<InvoiceItemFilters>;
};


export type QueryInvoiceItemArgs = {
  invoiceNumber?: Maybe<Scalars['String']>;
};


export type QueryInvoiceItemExistArgs = {
  invoiceNumber?: Maybe<Scalars['String']>;
};


export type QueryInvoicesArgs = {
  filters?: Maybe<InvoiceFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryInvoicesCountArgs = {
  filters?: Maybe<InvoiceFilters>;
};


export type QueryPurchaseOrdersInvoicesArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  filters?: Maybe<InvoiceFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryPurchaseOrdersInvoicesCountArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  filters?: Maybe<InvoiceFilters>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceExistArgs = {
  id: Scalars['ID'];
};


export type QueryKpisArgs = {
  filters?: Maybe<KpiFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryKpisCountArgs = {
  filters?: Maybe<KpiFilters>;
};


export type QueryKpiArgs = {
  id: Scalars['ID'];
};


export type QueryKpiExistsArgs = {
  id: Scalars['ID'];
};


export type QueryLanguagesArgs = {
  filters?: Maybe<LanguageFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryLanguagesCountArgs = {
  filters?: Maybe<LanguageFilters>;
};


export type QueryLanguageArgs = {
  id: Scalars['ID'];
};


export type QueryLanguageExistsArgs = {
  id: Scalars['ID'];
};


export type QueryLocationTypesArgs = {
  filters?: Maybe<LocationTypeFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryLocationTypesCountArgs = {
  filters?: Maybe<LocationTypeFilters>;
};


export type QueryLocationTypeArgs = {
  id: Scalars['ID'];
};


export type QueryLocationTypeExistsArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  filters?: Maybe<LocationFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryLocationsCountArgs = {
  filters?: Maybe<LocationFilters>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationExistsArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  filters?: Maybe<MessageFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryMessagesCountArgs = {
  filters?: Maybe<MessageFilters>;
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryMessageExistsArgs = {
  id: Scalars['ID'];
};


export type QueryOwnMigrationPackagesArgs = {
  pagination?: Maybe<Pagination>;
};


export type QueryOwnMigrationPackageArgs = {
  id: Scalars['ID'];
};


export type QueryExternalMigrationPackagesArgs = {
  pagination?: Maybe<Pagination>;
};


export type QueryExternalMigrationPackageArgs = {
  systemId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryPaymentsArgs = {
  filters?: Maybe<PaymentFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryPaymentsCountArgs = {
  filters?: Maybe<PaymentFilters>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentExistArgs = {
  id: Scalars['ID'];
};


export type QueryPositionsArgs = {
  filters?: Maybe<PositionFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryPositionsCountArgs = {
  filters?: Maybe<PositionFilters>;
};


export type QueryPositionArgs = {
  id: Scalars['ID'];
};


export type QueryPositionExistsArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseEntitiesArgs = {
  filters?: Maybe<PurchaseEntityFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryPurchaseEntitiesCountArgs = {
  filters?: Maybe<PurchaseEntityFilters>;
};


export type QueryPurchaseEntityArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseEntityExistsArgs = {
  id: Scalars['ID'];
};


export type QueryAllPurchaseOrderItemsArgs = {
  filters?: Maybe<PurchaseOrderItemFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryAllPurchaseOrderItemsCountArgs = {
  filters?: Maybe<PurchaseOrderItemFilters>;
};


export type QueryDeliveryPurchaseOrderItemsArgs = {
  filters?: Maybe<PurchaseOrderItemFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryDeliveryPurchaseOrderItemsCountArgs = {
  filters?: Maybe<PurchaseOrderItemFilters>;
};


export type QueryPurchaseOrderItemsArgs = {
  purchaseOrder: Scalars['String'];
  filters?: Maybe<PurchaseOrderItemFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryPurchaseOrderItemsCountArgs = {
  purchaseOrder: Scalars['String'];
  filters?: Maybe<PurchaseOrderItemFilters>;
};


export type QueryPurchaseOrderItemArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
};


export type QueryPurchaseOrderItemExistArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
};


export type QueryPurchaseOrdersArgs = {
  filters?: Maybe<PurchaseOrderFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryPurchaseOrdersCountArgs = {
  filters?: Maybe<PurchaseOrderFilters>;
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderExistArgs = {
  id: Scalars['ID'];
};


export type QueryRevenueCollectorsArgs = {
  filters?: Maybe<RevenueCollectorFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryRevenueCollectorsCountArgs = {
  filters?: Maybe<RevenueCollectorFilters>;
};


export type QueryRevenueCollectorArgs = {
  id: Scalars['ID'];
};


export type QueryRevenueCollectorExistsArgs = {
  id: Scalars['ID'];
};


export type QueryStockMovementsItemsArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  filters?: Maybe<StockMovementItemFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryStockMovementsItemsCountArgs = {
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  filters?: Maybe<StockMovementItemFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryStockMovementItemArgs = {
  id: Scalars['ID'];
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
};


export type QueryStockMovementItemExistArgs = {
  id: Scalars['ID'];
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
};


export type QueryStockMovementArgs = {
  stockMovementDocNumber: Scalars['ID'];
  stockMovementDocYear: Scalars['ID'];
};


export type QueryStockMovementExistArgs = {
  stockMovementDocNumber: Scalars['ID'];
  stockMovementDocYear: Scalars['ID'];
};


export type QueryTablesArgs = {
  filters?: Maybe<TablesFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryTablesCountArgs = {
  filters?: Maybe<TablesFilters>;
};


export type QueryTableArgs = {
  id: Scalars['ID'];
};


export type QueryTableMigrationsArgs = {
  id: Scalars['ID'];
};


export type QueryTableExistsArgs = {
  id: Scalars['ID'];
};


export type QueryThemesArgs = {
  filters?: Maybe<ThemesFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryThemesCountArgs = {
  filters?: Maybe<ThemesFilters>;
};


export type QueryThemeArgs = {
  id: Scalars['ID'];
};


export type QueryThemeExistsArgs = {
  id: Scalars['ID'];
};


export type QueryTileArgs = {
  id: Scalars['ID'];
};


export type QueryTilesArgs = {
  filters?: Maybe<TilesFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryTilesCountArgs = {
  filters?: Maybe<TilesFilters>;
};


export type QueryTutorialsArgs = {
  filters?: Maybe<TutorialsFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryTutorialArgs = {
  id: Scalars['ID'];
};


export type QueryTutorialsCountArgs = {
  filters?: Maybe<TutorialsFilters>;
};


export type QueryTutorialExistsArgs = {
  id: Scalars['ID'];
};


export type QueryUnitsArgs = {
  filters?: Maybe<UnitFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryUnitsCountArgs = {
  filters?: Maybe<UnitFilters>;
};


export type QueryUnitArgs = {
  id: Scalars['ID'];
};


export type QueryUnitExistsArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filters?: Maybe<UsersFilters>;
  pagination?: Maybe<Pagination>;
};


export type QueryUsersCountArgs = {
  filters?: Maybe<UsersFilters>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserExistsArgs = {
  id: Scalars['ID'];
};

/**  rack */
export type Rack = {
  __typename?: 'Rack';
  id: Scalars['ID'];
  translation: RackTranslation;
  translations: Array<RackTranslation>;
  levels: Array<Level>;
};


/**  rack */
export type RackTranslationArgs = {
  language: Scalars['String'];
};

/**  rack */
export type RackInput = {
  id: Scalars['ID'];
  translations: Array<RackTranslationInput>;
  levels: Array<LevelInput>;
};

export type RackTranslation = {
  __typename?: 'RackTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type RackTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type RegisterFileUploadedToS3Payload = {
  __typename?: 'RegisterFileUploadedToS3Payload';
  fileId: Scalars['ID'];
};

export type RevenueCollector = {
  __typename?: 'RevenueCollector';
  id: Scalars['ID'];
  translations: Array<RevenueCollectorTranslation>;
  translation: RevenueCollectorTranslation;
  company: Company;
};


export type RevenueCollectorTranslationArgs = {
  language: Scalars['String'];
};

export type RevenueCollectorFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
  companyId?: Maybe<Scalars['String']>;
};

export type RevenueCollectorInput = {
  companyId: Scalars['ID'];
  translations: Array<RevenueCollectorTranslationInput>;
};

export type RevenueCollectorTranslation = {
  __typename?: 'RevenueCollectorTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type RevenueCollectorTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type S3File = {
  __typename?: 'S3File';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['S3PresignedUrlFile'];
};


export type SignatureUploadFileS3 = {
  __typename?: 'SignatureUploadFileS3';
  url: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
};

/**  slot */
export type Slot = {
  __typename?: 'Slot';
  id: Scalars['ID'];
  translation: SlotTranslation;
  translations: Array<SlotTranslation>;
};


/**  slot */
export type SlotTranslationArgs = {
  language: Scalars['String'];
};

/**  slot */
export type SlotInput = {
  id: Scalars['ID'];
  translations: Array<SlotTranslationInput>;
};

export type SlotTranslation = {
  __typename?: 'SlotTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type SlotTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type StockMovementFilters = {
  stockMovementDocNumber?: Maybe<Scalars['ID']>;
  stockMovementDocYear?: Maybe<Scalars['ID']>;
  stockMovementDocDate?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

export type StockMovementHeader = {
  __typename?: 'StockMovementHeader';
  stockMovementDocNumber: Scalars['ID'];
  stockMovementDocYear: Scalars['ID'];
  stockMovementDocDate: Scalars['String'];
  currency: Scalars['String'];
  backend: Scalars['String'];
};

export type StockMovementItem = {
  __typename?: 'StockMovementItem';
  id: Scalars['ID'];
  backend: Scalars['String'];
  stockMovementDocYear: Scalars['String'];
  stockMovementDocNumber: Scalars['String'];
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  unit: Scalars['String'];
  quantity: Scalars['Amount'];
  netUnitPrice: Scalars['Amount'];
  netTotalPrice: Scalars['Amount'];
};

export type StockMovementItemFilters = {
  id?: Maybe<Scalars['ID']>;
  stockMovementDocYear?: Maybe<Scalars['String']>;
  stockMovementDocNumber?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  purchaseOrderItem?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Amount']>;
  netUnitPrice?: Maybe<Scalars['Amount']>;
  netTotalPrice?: Maybe<Scalars['Amount']>;
};

/**  storage */
export type Storage = {
  __typename?: 'Storage';
  id: Scalars['ID'];
  translation: StorageTranslation;
  translations: Array<StorageTranslation>;
  isWarehouseManagementActive: Scalars['Boolean'];
  racks?: Maybe<Array<Rack>>;
};


/**  storage */
export type StorageTranslationArgs = {
  language: Scalars['String'];
};

/**  storage */
export type StorageInput = {
  id: Scalars['ID'];
  translations: Array<StorageTranslationInput>;
  isWarehouseManagementActive: Scalars['Boolean'];
  racks?: Maybe<Array<RackInput>>;
};

export type StorageTranslation = {
  __typename?: 'StorageTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type StorageTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type Table = {
  __typename?: 'Table';
  id: Scalars['ID'];
  tableType: TableType;
  status: TableStatus;
  translations: Array<TableTranslation>;
  translation: TableTranslation;
  columns: Array<TableColumn>;
};


export type TableTranslationArgs = {
  language: Scalars['String'];
};

export type TableColumn = {
  __typename?: 'TableColumn';
  id: Scalars['String'];
  isPrimaryKey: Scalars['Boolean'];
  dataElement: DataElement;
  type: TableColumnType;
};

export type TableColumnInput = {
  id: Scalars['ID'];
  isPrimaryKey: Scalars['Boolean'];
  dataElementId: Scalars['String'];
  type: TableColumnType;
  reference?: Maybe<Scalars['String']>;
};

export type TableColumnType =
  | 'CHARACTERISTIC'
  | 'RATIO';

export type TableInput = {
  id: Scalars['ID'];
  tableType: TableType;
  translations: Array<TableTranslationInput>;
  columns: Array<TableColumnInput>;
};

export type TableMigration = {
  __typename?: 'TableMigration';
  version: Scalars['Int'];
  fileName: Scalars['String'];
  timestamp: Scalars['String'];
  downloadUrl: Scalars['String'];
};

export type TableStatus =
  | 'ACTIVE'
  | 'INACTIVE';

export type TableTranslation = {
  __typename?: 'TableTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type TableTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type TableType =
  | 'INTERNAL_SYSTEM_TABLE'
  | 'CONFIGURATION_TABLE'
  | 'BUSINESS_DATA_TABLE';

export type TablesFilters = {
  id?: Maybe<Scalars['ID']>;
  tableType?: Maybe<Array<TableType>>;
  status?: Maybe<Array<TableStatus>>;
  description?: Maybe<Scalars['String']>;
  primaryKeyColumnsFilters?: Maybe<PrimaryKeyColumnsFilters>;
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  primaryColor: Scalars['String'];
  primaryColorContrast: Scalars['String'];
  userColor: Scalars['String'];
  appsColor: Scalars['String'];
  appsTitleColor: Scalars['String'];
  stepperColor1: Scalars['String'];
  stepperColor2: Scalars['String'];
  stepperColor3: Scalars['String'];
  stepperInternalColor: Scalars['String'];
  stepperTextColor: Scalars['String'];
  backgroundImage: S3File;
  companyLogo?: Maybe<S3File>;
  translations: Array<ThemeTranslation>;
  translation: ThemeTranslation;
};


export type ThemeTranslationArgs = {
  language: Scalars['String'];
};

export type ThemeInput = {
  id: Scalars['ID'];
  primaryColor: Scalars['String'];
  primaryColorContrast: Scalars['String'];
  userColor: Scalars['String'];
  appsColor: Scalars['String'];
  appsTitleColor: Scalars['String'];
  stepperColor1: Scalars['String'];
  stepperColor2: Scalars['String'];
  stepperColor3: Scalars['String'];
  stepperInternalColor: Scalars['String'];
  stepperTextColor: Scalars['String'];
  backgroundImageId: Scalars['String'];
  companyLogoId?: Maybe<Scalars['String']>;
  translations: Array<ThemeTranslationInput>;
};

export type ThemeTranslation = {
  __typename?: 'ThemeTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type ThemeTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type ThemesFilters = {
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
};

export type Tile = {
  __typename?: 'Tile';
  id: Scalars['ID'];
  app: App;
  path: Scalars['String'];
  shape: TileShape;
  icon?: Maybe<S3File>;
  kpi?: Maybe<Kpi>;
  translations: Array<TileTranslation>;
  translation: TileTranslation;
};


export type TileTranslationArgs = {
  language: Scalars['String'];
};

export type TileInput = {
  appId: Scalars['String'];
  shape: TileShape;
  path: Scalars['String'];
  iconId?: Maybe<Scalars['String']>;
  kpiId?: Maybe<Scalars['String']>;
  translations: Array<TileTranslationInput>;
};

export type TileShape =
  | 'SQUARE'
  | 'RECTANGULAR'
  | 'CIRCULAR';

export type TileTranslation = {
  __typename?: 'TileTranslation';
  language: Scalars['String'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
};

export type TileTranslationInput = {
  language: Scalars['String'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
};

export type TilesFilters = {
  ids?: Maybe<Array<Scalars['String']>>;
  businessObjectId?: Maybe<Scalars['String']>;
};

export type ToleranceCatalogue =
  | 'NONE'
  | 'AMOUNT'
  | 'PERCENTAGE';

export type Tutorial = {
  __typename?: 'Tutorial';
  id: Scalars['ID'];
  translation: TutorialTranslation;
  translations: Array<TutorialTranslation>;
  apps: Array<Maybe<App>>;
  instructions: Array<Instruction>;
};


export type TutorialTranslationArgs = {
  language: Scalars['String'];
};

export type TutorialInput = {
  id: Scalars['ID'];
  translations: Array<TutorialTranslationInput>;
  apps: Array<Scalars['String']>;
  instructions: Array<InstructionInput>;
};

export type TutorialTranslation = {
  __typename?: 'TutorialTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type TutorialTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type TutorialsFilters = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
};

export type Unit = {
  __typename?: 'Unit';
  id: Scalars['ID'];
  translations: Array<UnitTranslation>;
  translation: UnitTranslation;
};


export type UnitTranslationArgs = {
  language: Scalars['String'];
};

export type UnitFilters = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type UnitInput = {
  id: Scalars['ID'];
  translations: Array<UnitTranslationInput>;
};

export type UnitTranslation = {
  __typename?: 'UnitTranslation';
  language: Scalars['String'];
  description: Scalars['String'];
};

export type UnitTranslationInput = {
  language: Scalars['String'];
  description: Scalars['String'];
};

export type UpdatableUserProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  dateFormat: UserDateFormat;
  timeFormat: UserTimeFormat;
  decimalSeparator: UserDecimalSeparator;
  numberDecimalsPlaces: Scalars['Int'];
  language: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  userType: UserType;
  businessProfile: BusinessProfile;
  image: S3File;
  dateFormat: UserDateFormat;
  timeFormat: UserTimeFormat;
  decimalSeparator: UserDecimalSeparator;
  numberDecimalsPlaces: Scalars['Int'];
  language: Language;
  themeId?: Maybe<Scalars['String']>;
  effectiveTheme: Theme;
  isLocked: Scalars['Boolean'];
  isPasswordUpdated: Scalars['Boolean'];
};

export type UserDataElements =
  | 'USER_TYPE'
  | 'USER_DATE_FORMAT'
  | 'USER_TIME_FORMAT'
  | 'USER_DECIMAL_SEPARATOR';

export type UserDateFormat =
  | 'YYYY_MM_DD'
  | 'MM_DD_YYYY'
  | 'DD_MM_YYYY';

export type UserDecimalSeparator =
  | 'COMMA'
  | 'POINT';

export type UserInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  userType: UserType;
  imageId: Scalars['String'];
  businessProfileId: Scalars['String'];
  language: Scalars['String'];
  dateFormat: UserDateFormat;
  timeFormat: UserTimeFormat;
  decimalSeparator: UserDecimalSeparator;
  numberDecimalsPlaces?: Maybe<Scalars['Int']>;
  theme?: Maybe<Scalars['String']>;
};

export type UserTimeFormat =
  | 'HS_12'
  | 'HS_24';

export type UserType =
  | 'INTERNAL'
  | 'EXTERNAL'
  | 'GHI'
  | 'ADMIN_GHI';

export type UsersFilters = {
  id?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  businessProfile?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  uniqueUserForEmployee?: Maybe<Scalars['Boolean']>;
};



export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type RegisterFileUploadedToS3MutationVariables = Exact<{
  s3File: FileUploadedToS3Input;
}>;


export type RegisterFileUploadedToS3Mutation = { __typename?: 'Mutation', registerFileUploadedToS3: { __typename?: 'RegisterFileUploadedToS3Payload', fileId: string } };

export type AccountingEntityExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AccountingEntityExistsQuery = { __typename?: 'Query', accountingEntityExists: boolean };

export type GetCurrenciesForAccountingEntitiesFormPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CurrencyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCurrenciesForAccountingEntitiesFormPageQuery = { __typename?: 'Query', currencies: Array<{ __typename?: 'FisCurrency', id: string, translation: { __typename?: 'CurrencyTranslation', description: string } }> };

export type GetCompaniesForAccountingEntitiesFormPageQueryVariables = Exact<{
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCompaniesForAccountingEntitiesFormPageQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type CreateAccountingEntityMutationVariables = Exact<{
  accountingEntity: AccountingEntityInput;
}>;


export type CreateAccountingEntityMutation = { __typename?: 'Mutation', createAccountingEntity?: Maybe<any> };

export type UpdateAccountingEntityMutationVariables = Exact<{
  id: Scalars['ID'];
  accountingEntity: AccountingEntityInput;
}>;


export type UpdateAccountingEntityMutation = { __typename?: 'Mutation', updateAccountingEntity?: Maybe<any> };

export type DeletAccountingEntityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletAccountingEntityMutation = { __typename?: 'Mutation', deleteAccountingEntity?: Maybe<any> };

export type GetAccountingEntities_ViewAllPageQueryVariables = Exact<{
  filters?: Maybe<AccountingEntityFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetAccountingEntities_ViewAllPageQuery = { __typename?: 'Query', accountingEntitiesCount: number, accountingEntities: Array<{ __typename?: 'AccountingEntity', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, address: { __typename?: 'Address', street: string, number: string, floor?: Maybe<string>, department?: Maybe<string>, city: string, postalCode: string, state: string, country: string } }> };

export type GetAccountingEntity_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAccountingEntity_ViewPageQuery = { __typename?: 'Query', accountingEntity?: Maybe<{ __typename?: 'AccountingEntity', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, company: { __typename?: 'Company', id: string }, address: { __typename?: 'Address', street: string, number: string, floor?: Maybe<string>, department?: Maybe<string>, city: string, postalCode: string, state: string, country: string } }> };

export type GetBusinessObjectsForCreateAppQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetBusinessObjectsForCreateAppQuery = { __typename?: 'Query', businessObjects: Array<{ __typename?: 'BusinessObject', dataElement: { __typename?: 'DataElement', id: string, translation: { __typename?: 'DataElementTranslation', description: string } } }> };

export type AppExistsQueryVariables = Exact<{
  appId: Scalars['ID'];
}>;


export type AppExistsQuery = { __typename?: 'Query', appExists: boolean };

export type CreateAppMutationVariables = Exact<{
  app: AppInput;
}>;


export type CreateAppMutation = { __typename?: 'Mutation', createApp?: Maybe<any> };

export type UpdateAppMutationVariables = Exact<{
  id: Scalars['ID'];
  app: AppInput;
}>;


export type UpdateAppMutation = { __typename?: 'Mutation', updateApp?: Maybe<any> };

export type DeleteAppMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAppMutation = { __typename?: 'Mutation', deleteApp?: Maybe<any> };

export type GetAppsTableQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<AppsFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetAppsTableQuery = { __typename?: 'Query', appsCount: number, apps: Array<{ __typename?: 'App', id: string, businessObject: { __typename?: 'BusinessObject', dataElement: { __typename?: 'DataElement', id: string } }, translation: { __typename?: 'AppTranslation', description: string } }> };

export type GetAppViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAppViewPageQuery = { __typename?: 'Query', app?: Maybe<{ __typename?: 'App', id: string, businessObject: { __typename?: 'BusinessObject', dataElement: { __typename?: 'DataElement', id: string } }, translations: Array<{ __typename?: 'AppTranslation', language: string, description: string }>, sections: Array<{ __typename?: 'AppSection', id: string, translations: Array<{ __typename?: 'AppSectionTranslation', language: string, step: string, title: string, subtitle: string }> }>, actions: Array<{ __typename?: 'AppAction', id: string, translations: Array<{ __typename?: 'AppActionTranslation', language: string, description: string }> }>, messages: Array<{ __typename?: 'AppMessage', id: string, translations: Array<{ __typename?: 'AppMessageTranslation', language: string, description: string }> }> }> };

export type BackendExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BackendExistsQuery = { __typename?: 'Query', backendExists: boolean };

export type CreateBackendMutationVariables = Exact<{
  backend: BackendInput;
}>;


export type CreateBackendMutation = { __typename?: 'Mutation', createBackend?: Maybe<any> };

export type GenerateBackendPasswordMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GenerateBackendPasswordMutation = { __typename?: 'Mutation', generateBackendRandomPassword: string };

export type UpdateBackendMutationVariables = Exact<{
  id: Scalars['ID'];
  backend: BackendInput;
}>;


export type UpdateBackendMutation = { __typename?: 'Mutation', updateBackend?: Maybe<any> };

export type DeleteBackendMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBackendMutation = { __typename?: 'Mutation', deleteBackend?: Maybe<any> };

export type GetBackends_ViewAllPageQueryVariables = Exact<{
  filters?: Maybe<BackendsFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBackends_ViewAllPageQuery = { __typename?: 'Query', backendsCount: number, backends: Array<{ __typename?: 'Backend', id: string, url: string }> };

export type GetBackend_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBackend_ViewPageQuery = { __typename?: 'Query', backend?: Maybe<{ __typename?: 'Backend', id: string, url: string, ip?: Maybe<string>, domain?: Maybe<string> }> };

export type BusinessGroupsExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessGroupsExistsQuery = { __typename?: 'Query', businessGroupsExists: boolean };

export type GetCurrenciesForBusinessGroupsFormPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CurrencyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCurrenciesForBusinessGroupsFormPageQuery = { __typename?: 'Query', currencies: Array<{ __typename?: 'FisCurrency', id: string, translation: { __typename?: 'CurrencyTranslation', description: string } }> };

export type GetChartOfAccountsForBusinessGroupsFormPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<ChartOfAccountFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetChartOfAccountsForBusinessGroupsFormPageQuery = { __typename?: 'Query', chartOfAccounts: Array<{ __typename?: 'ChartOfAccount', id: string, translation: { __typename?: 'ChartOfAccountTranslation', description: string } }> };

export type CreateBusinessGroupMutationVariables = Exact<{
  businessGroup: BusinessGroupInput;
}>;


export type CreateBusinessGroupMutation = { __typename?: 'Mutation', createBusinessGroup?: Maybe<any> };

export type UpdateBusinessGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  businessGroup: BusinessGroupInput;
}>;


export type UpdateBusinessGroupMutation = { __typename?: 'Mutation', updateBusinessGroup?: Maybe<any> };

export type DeletBusinessGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletBusinessGroupMutation = { __typename?: 'Mutation', deleteBusinessGroup?: Maybe<any> };

export type GetBusinessGroups_ViewAllPageQueryVariables = Exact<{
  filters?: Maybe<BusinessGroupFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBusinessGroups_ViewAllPageQuery = { __typename?: 'Query', businessGroupsCount: number, businessGroups: Array<{ __typename?: 'BusinessGroup', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type GetBusinessGroup_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBusinessGroup_ViewPageQuery = { __typename?: 'Query', businessGroup?: Maybe<{ __typename?: 'BusinessGroup', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type GetDataElementsForCreateBusinessObjectPageQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetDataElementsForCreateBusinessObjectPageQuery = { __typename?: 'Query', dataElements: Array<{ __typename?: 'DataElement', id: string, translation: { __typename?: 'DataElementTranslation', description: string } }> };

export type GetDataElementByIdForCreateBusinessObjectPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDataElementByIdForCreateBusinessObjectPageQuery = { __typename?: 'Query', dataElement?: Maybe<{ __typename?: 'DataElement', id: string, translations: Array<{ __typename?: 'DataElementTranslation', language: string, description: string }> }> };

export type GetTablesForCreateBusinessObjectQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetTablesForCreateBusinessObjectQuery = { __typename?: 'Query', tables: Array<{ __typename?: 'Table', id: string, translation: { __typename?: 'TableTranslation', description: string } }> };

export type CreateBusinessObjectMutationVariables = Exact<{
  businessObject: BusinessObjectInput;
}>;


export type CreateBusinessObjectMutation = { __typename?: 'Mutation', createBusinessObject?: Maybe<any> };

export type UpdateBusinessObjectMutationVariables = Exact<{
  id: Scalars['ID'];
  businessObject: BusinessObjectInput;
}>;


export type UpdateBusinessObjectMutation = { __typename?: 'Mutation', updateBusinessObject?: Maybe<any> };

export type DeleteBusinessObjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBusinessObjectMutation = { __typename?: 'Mutation', deleteBusinessObject?: Maybe<any> };

export type GetBusinessObjectsForViewPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<BusinessObjectsFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBusinessObjectsForViewPageQuery = { __typename?: 'Query', businessObjectsCount: number, businessObjects: Array<{ __typename?: 'BusinessObject', status: BusinessObjectStatus, dataElement: { __typename?: 'DataElement', id: string, translation: { __typename?: 'DataElementTranslation', description: string } } }> };

export type GetBusinessObjectForViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBusinessObjectForViewPageQuery = { __typename?: 'Query', businessObject?: Maybe<{ __typename?: 'BusinessObject', status: BusinessObjectStatus, dataElement: { __typename?: 'DataElement', id: string }, tables: Array<{ __typename?: 'BusinessObjectTable', isHeaderTable: boolean, table: { __typename?: 'Table', id: string } }> }> };

export type BusinessPartnerTypeExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessPartnerTypeExistsQuery = { __typename?: 'Query', businessPartnerTypeExists: boolean };

export type CreateBusinessPartnerTypeMutationVariables = Exact<{
  businessPartnerType: BusinessPartnerTypeInput;
}>;


export type CreateBusinessPartnerTypeMutation = { __typename?: 'Mutation', createBusinessPartnerType?: Maybe<any> };

export type UpdateBusinessPartnerTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  businessPartnerType: BusinessPartnerTypeInput;
}>;


export type UpdateBusinessPartnerTypeMutation = { __typename?: 'Mutation', updateBusinessPartnerType?: Maybe<any> };

export type DeleteBusinessPartnerTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBusinessPartnerTypeMutation = { __typename?: 'Mutation', deleteBusinessPartnerType?: Maybe<any> };

export type GetBusinessPartnerTypes_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<BusinessPartnerTypeFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBusinessPartnerTypes_ViewAllPageQuery = { __typename?: 'Query', businessPartnerTypesCount: number, businessPartnerTypes: Array<{ __typename?: 'BusinessPartnerType', id: string, translation: { __typename?: 'BusinessPartnerTypeTranslation', description: string } }> };

export type GetBusinessPartnerType_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBusinessPartnerType_ViewPageQuery = { __typename?: 'Query', businessPartnerType?: Maybe<{ __typename?: 'BusinessPartnerType', id: string, translations: Array<{ __typename?: 'BusinessPartnerTypeTranslation', language: string, description: string }> }> };

export type GetBusinessPartnersBackendSavedFormPageQueryVariables = Exact<{
  filters?: Maybe<BusinessPartnersFilters>;
}>;


export type GetBusinessPartnersBackendSavedFormPageQuery = { __typename?: 'Query', businessPartners: Array<{ __typename?: 'BusinessPartner', id: string }> };

export type GetBackendsNamesForBusinessPartnersFormPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBackendsNamesForBusinessPartnersFormPageQuery = { __typename?: 'Query', backendsCount: number, backends: Array<{ __typename?: 'Backend', id: string, url: string }> };

export type BusinessPartnerExistsQueryVariables = Exact<{
  businessPartnerId: Scalars['ID'];
}>;


export type BusinessPartnerExistsQuery = { __typename?: 'Query', businessPartnerExists: boolean };

export type GetAllPositionsForBusinessPartnersFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<PositionFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetAllPositionsForBusinessPartnersFormQuery = { __typename?: 'Query', positions: Array<{ __typename?: 'Position', id: string, translation: { __typename?: 'PositionTranslation', language: string, description: string } }> };

export type GetUsersForBusinessPartnersFormQueryVariables = Exact<{
  filters?: Maybe<UsersFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetUsersForBusinessPartnersFormQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, userType: UserType, businessProfile: { __typename?: 'BusinessProfile', id: string } }> };

export type GetCountriesIdsTypesForCreatingBusinessPartnerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCountriesIdsTypesForCreatingBusinessPartnerQuery = { __typename?: 'Query', countryIdsTypes?: Maybe<{ __typename?: 'CountryIdsType', country: { __typename?: 'Country', id: string }, idsTypes: Array<{ __typename?: 'IdType', id: string }> }> };

export type GetCountriesForCreatingBusinessPartnerQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CountryFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCountriesForCreatingBusinessPartnerQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'Country', id: string, translation: { __typename?: 'CountryTranslation', description: string } }> };

export type GetBusinessPartnerTypesForBusinessPartnersFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<BusinessPartnerTypeFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBusinessPartnerTypesForBusinessPartnersFormQuery = { __typename?: 'Query', businessPartnerTypesCount: number, businessPartnerTypes: Array<{ __typename?: 'BusinessPartnerType', id: string, translation: { __typename?: 'BusinessPartnerTypeTranslation', description: string } }> };

export type CreateBusinessPartnerMutationVariables = Exact<{
  businessPartner: BusinessPartnerInput;
}>;


export type CreateBusinessPartnerMutation = { __typename?: 'Mutation', createBusinessPartner?: Maybe<any> };

export type UpdateBusinessPartnerMutationVariables = Exact<{
  id: Scalars['ID'];
  businessPartner: BusinessPartnerInput;
}>;


export type UpdateBusinessPartnerMutation = { __typename?: 'Mutation', updateBusinessPartner?: Maybe<any> };

export type DeleteBusinessPartnerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBusinessPartnerMutation = { __typename?: 'Mutation', deleteBusinessPartner?: Maybe<any> };

export type GetBusinessPartners_ViewAllPageQueryVariables = Exact<{
  filters?: Maybe<BusinessPartnersFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBusinessPartners_ViewAllPageQuery = { __typename?: 'Query', businessPartnersCount: number, businessPartners: Array<{ __typename?: 'BusinessPartner', id: string, name: string, identification: BusinessPartnerCategory, lastName?: Maybe<string>, taxName?: Maybe<string>, locations: Array<{ __typename?: 'BusinessPartnerLocation', id: string, isFiscalAddress: boolean, address: { __typename?: 'Address', street: string, number: string, floor?: Maybe<string>, department?: Maybe<string>, city: string, postalCode: string, state: string, country: string } }>, taxId?: Maybe<{ __typename?: 'BusinessPartnerTaxId', countryId: string, id: string, typeId: { __typename?: 'IdType', id: string, isTaxId: boolean } }>, businessPartnerType: Array<{ __typename?: 'BusinessPartnerBusinessPartnerType', id: string }>, contacts: Array<{ __typename?: 'BusinessPartnerContact', userId?: Maybe<string>, email?: Maybe<string>, name?: Maybe<string>, lastName?: Maybe<string>, phoneNumber?: Maybe<string>, birthDate?: Maybe<string>, position?: Maybe<string> }>, backend: Array<{ __typename?: 'BusinessPartnerBackend', backendId: string, externalId: string }> }> };

export type GetBusinessPartnerViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBusinessPartnerViewPageQuery = { __typename?: 'Query', businessPartner?: Maybe<{ __typename?: 'BusinessPartner', id: string, name: string, identification: BusinessPartnerCategory, lastName?: Maybe<string>, taxName?: Maybe<string>, locations: Array<{ __typename?: 'BusinessPartnerLocation', id: string, isFiscalAddress: boolean, address: { __typename?: 'Address', street: string, number: string, floor?: Maybe<string>, department?: Maybe<string>, city: string, postalCode: string, state: string, country: string } }>, taxId?: Maybe<{ __typename?: 'BusinessPartnerTaxId', countryId: string, id: string, typeId: { __typename?: 'IdType', id: string, isTaxId: boolean } }>, businessPartnerType: Array<{ __typename?: 'BusinessPartnerBusinessPartnerType', id: string }>, contacts: Array<{ __typename?: 'BusinessPartnerContact', userId?: Maybe<string>, email?: Maybe<string>, name?: Maybe<string>, lastName?: Maybe<string>, phoneNumber?: Maybe<string>, position?: Maybe<string>, birthDate?: Maybe<string> }>, backend: Array<{ __typename?: 'BusinessPartnerBackend', backendId: string, externalId: string }> }> };

export type BusinessProfileExistsQueryVariables = Exact<{
  businessProfileId: Scalars['ID'];
}>;


export type BusinessProfileExistsQuery = { __typename?: 'Query', businessProfileExists: boolean };

export type GetTilesGroupsForCreateBusinessProfileQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetTilesGroupsForCreateBusinessProfileQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, translation: { __typename?: 'GroupTranslation', language: string, description: string } }> };

export type GetAppActionsForCreateBusinessProfilesQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Scalars['String'];
}>;


export type GetAppActionsForCreateBusinessProfilesQuery = { __typename?: 'Query', app?: Maybe<{ __typename?: 'App', id: string, actions: Array<{ __typename?: 'AppAction', id: string, translation: { __typename?: 'AppActionTranslation', description: string } }> }> };

export type GetAppsForCreateBusinessProfilesQueryVariables = Exact<{
  language: Scalars['String'];
  groupsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetAppsForCreateBusinessProfilesQuery = { __typename?: 'Query', apps: Array<{ __typename?: 'App', id: string, translation: { __typename?: 'AppTranslation', description: string } }> };

export type CreateBusinessProfileMutationVariables = Exact<{
  businessProfile: BusinessProfileInput;
}>;


export type CreateBusinessProfileMutation = { __typename?: 'Mutation', createBusinessProfile?: Maybe<string> };

export type UpdateBusinessProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  businessProfile: BusinessProfileInput;
}>;


export type UpdateBusinessProfileMutation = { __typename?: 'Mutation', updateBusinessProfile?: Maybe<string> };

export type DeleteBusinessProfileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBusinessProfileMutation = { __typename?: 'Mutation', deleteBusinessProfile?: Maybe<string> };

export type GetBusinessProfilesForTablePageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<BusinessProfilesFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBusinessProfilesForTablePageQuery = { __typename?: 'Query', businessProfilesCount: number, businessProfiles: Array<{ __typename?: 'BusinessProfile', id: string, translation: { __typename?: 'BusinessProfileTranslation', description: string, language: string } }> };

export type GetBusinessProfileForViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBusinessProfileForViewPageQuery = { __typename?: 'Query', businessProfile?: Maybe<{ __typename?: 'BusinessProfile', id: string, translations: Array<{ __typename?: 'BusinessProfileTranslation', description: string, language: string }>, groups: Array<{ __typename?: 'Group', id: string }>, appsActions: Array<{ __typename?: 'AppAction', id: string, app: { __typename?: 'App', id: string } }> }> };

export type GetCompaniesForBusinessUnitFormQueryVariables = Exact<{
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCompaniesForBusinessUnitFormQuery = { __typename?: 'Query', companiesCount: number, companies: Array<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type GetCostCollectorForBusinessUnitsFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CostCollectorFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCostCollectorForBusinessUnitsFormQuery = { __typename?: 'Query', costCollectors: Array<{ __typename?: 'CostCollector', id: string, company: { __typename?: 'Company', id: string }, description: { __typename?: 'CostCollectorTranslation', description: string } }> };

export type GetRevenueCollectorsForBusinessUnitsFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<RevenueCollectorFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetRevenueCollectorsForBusinessUnitsFormQuery = { __typename?: 'Query', revenueCollectors: Array<{ __typename?: 'RevenueCollector', id: string, translation: { __typename?: 'RevenueCollectorTranslation', description: string }, company: { __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } } }> };

export type CreateBusinessUnitMutationVariables = Exact<{
  businessUnit: BusinessUnitInput;
}>;


export type CreateBusinessUnitMutation = { __typename?: 'Mutation', createBusinessUnit?: Maybe<any> };

export type UpdateBusinessUnitMutationVariables = Exact<{
  id: Scalars['ID'];
  businessUnit: BusinessUnitInput;
}>;


export type UpdateBusinessUnitMutation = { __typename?: 'Mutation', updateBusinessUnit?: Maybe<any> };

export type DeleteBusinessUnitMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBusinessUnitMutation = { __typename?: 'Mutation', deleteBusinessUnit?: Maybe<any> };

export type GetBusinessUnits_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<BusinessUnitFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetBusinessUnits_ViewAllPageQuery = { __typename?: 'Query', businessUnitsCount: number, businessUnits: Array<{ __typename?: 'BusinessUnit', id: string, businessUnitCategory: BusinessUnitCategory, company: { __typename?: 'Company', id: string, name: string }, translations: Array<{ __typename?: 'BusinessUnitTranslation', language: string, description: string }>, translation: { __typename?: 'BusinessUnitTranslation', description: string }, revenueCollectors?: Maybe<Array<{ __typename?: 'RevenueCollector', id: string, translations: Array<{ __typename?: 'RevenueCollectorTranslation', language: string, description: string }>, company: { __typename?: 'Company', id: string, name: string } }>>, costCollectors?: Maybe<Array<{ __typename?: 'CostCollector', id: string, descriptions: Array<{ __typename?: 'CostCollectorTranslation', language: string, description: string }>, company: { __typename?: 'Company', id: string, name: string } }>> }> };

export type GetBusinessUnitViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBusinessUnitViewPageQuery = { __typename?: 'Query', businessUnit?: Maybe<{ __typename?: 'BusinessUnit', id: string, businessUnitCategory: BusinessUnitCategory, company: { __typename?: 'Company', id: string, name: string }, translations: Array<{ __typename?: 'BusinessUnitTranslation', language: string, description: string }>, revenueCollectors?: Maybe<Array<{ __typename?: 'RevenueCollector', id: string, translations: Array<{ __typename?: 'RevenueCollectorTranslation', language: string, description: string }>, company: { __typename?: 'Company', id: string, name: string } }>>, costCollectors?: Maybe<Array<{ __typename?: 'CostCollector', id: string, descriptions: Array<{ __typename?: 'CostCollectorTranslation', language: string, description: string }>, company: { __typename?: 'Company', id: string, name: string } }>> }> };

export type ChartOfAccountExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ChartOfAccountExistsQuery = { __typename?: 'Query', chartOfAccountExists: boolean };

export type CreateChartOfAccountMutationVariables = Exact<{
  chartOfAccount: ChartOfAccountInput;
}>;


export type CreateChartOfAccountMutation = { __typename?: 'Mutation', createChartOfAccount?: Maybe<any> };

export type UpdateChartOfAccountMutationVariables = Exact<{
  id: Scalars['ID'];
  chartOfAccount: ChartOfAccountInput;
}>;


export type UpdateChartOfAccountMutation = { __typename?: 'Mutation', updateChartOfAccount?: Maybe<any> };

export type DeletChartOfAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletChartOfAccountMutation = { __typename?: 'Mutation', deleteChartOfAccount?: Maybe<any> };

export type GetChartOfAccounts_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<ChartOfAccountFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetChartOfAccounts_ViewAllPageQuery = { __typename?: 'Query', chartOfAccountsCount: number, chartOfAccounts: Array<{ __typename?: 'ChartOfAccount', id: string, translation: { __typename?: 'ChartOfAccountTranslation', description: string } }> };

export type GetChartOfAccount_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetChartOfAccount_ViewPageQuery = { __typename?: 'Query', chartOfAccount?: Maybe<{ __typename?: 'ChartOfAccount', id: string, translations: Array<{ __typename?: 'ChartOfAccountTranslation', language: string, description: string }> }> };

export type CommercialEntityExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommercialEntityExistsQuery = { __typename?: 'Query', commercialEntityExists: boolean };

export type GetCompaniesForCommercialEntitiesFormQueryVariables = Exact<{
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCompaniesForCommercialEntitiesFormQuery = { __typename?: 'Query', companiesCount: number, companies: Array<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type CreateCommercialEntityMutationVariables = Exact<{
  commercialEntity: CommercialEntityInput;
}>;


export type CreateCommercialEntityMutation = { __typename?: 'Mutation', createCommercialEntity?: Maybe<any> };

export type UpdateCommercialEntityMutationVariables = Exact<{
  id: Scalars['ID'];
  commercialEntity: CommercialEntityInput;
}>;


export type UpdateCommercialEntityMutation = { __typename?: 'Mutation', updateCommercialEntity?: Maybe<any> };

export type DeleteCommercialEntityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommercialEntityMutation = { __typename?: 'Mutation', deleteCommercialEntity?: Maybe<any> };

export type GetCommercialEntities_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CommercialEntityFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCommercialEntities_ViewAllPageQuery = { __typename?: 'Query', commercialEntitiesCount: number, commercialEntities: Array<{ __typename?: 'CommercialEntity', id: string, company: { __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }, translation: { __typename?: 'CommercialEntityTranslation', description: string } }> };

export type GetCommercialEntity_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCommercialEntity_ViewPageQuery = { __typename?: 'Query', commercialEntity?: Maybe<{ __typename?: 'CommercialEntity', id: string, company: { __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }, translations: Array<{ __typename?: 'CommercialEntityTranslation', language: string, description: string }> }> };

export type CompaniesExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompaniesExistsQuery = { __typename?: 'Query', companiesExists: boolean };

export type GetChartOfAccountsForCompaniesFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<ChartOfAccountFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetChartOfAccountsForCompaniesFormQuery = { __typename?: 'Query', chartOfAccountsCount: number, chartOfAccounts: Array<{ __typename?: 'ChartOfAccount', id: string, translation: { __typename?: 'ChartOfAccountTranslation', description: string } }> };

export type GetCurrenciesForCompaniesFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CurrencyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCurrenciesForCompaniesFormQuery = { __typename?: 'Query', currenciesCount: number, currencies: Array<{ __typename?: 'FisCurrency', id: string, translation: { __typename?: 'CurrencyTranslation', description: string } }> };

export type CreateCompanyMutationVariables = Exact<{
  company: CompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany?: Maybe<any> };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  company: CompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany?: Maybe<any> };

export type DeletCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletCompanyMutation = { __typename?: 'Mutation', deleteCompany?: Maybe<any> };

export type GetCompanies_ViewAllPageQueryVariables = Exact<{
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCompanies_ViewAllPageQuery = { __typename?: 'Query', companiesCount: number, companies: Array<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type GetCompany_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCompany_ViewPageQuery = { __typename?: 'Query', company?: Maybe<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type CostCollectorsExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CostCollectorsExistsQuery = { __typename?: 'Query', costCollectorsExists: boolean };

export type GetCompaniesForCostCollectorsFormQueryVariables = Exact<{
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCompaniesForCostCollectorsFormQuery = { __typename?: 'Query', companiesCount: number, companies: Array<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type CreateCostCollectorMutationVariables = Exact<{
  costCollector: CostCollectorInput;
}>;


export type CreateCostCollectorMutation = { __typename?: 'Mutation', createCostCollector?: Maybe<any> };

export type UpdateCostCollectorMutationVariables = Exact<{
  id: Scalars['ID'];
  costCollector: CostCollectorInput;
}>;


export type UpdateCostCollectorMutation = { __typename?: 'Mutation', updateCostCollector?: Maybe<any> };

export type DeleteCostCollectorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCostCollectorMutation = { __typename?: 'Mutation', deleteCostCollector?: Maybe<any> };

export type GetCostCollector_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CostCollectorFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCostCollector_ViewAllPageQuery = { __typename?: 'Query', costCollectorsCount: number, costCollectors: Array<{ __typename?: 'CostCollector', id: string, company: { __typename?: 'Company', id: string }, description: { __typename?: 'CostCollectorTranslation', description: string } }> };

export type GetCostCollector_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCostCollector_ViewPageQuery = { __typename?: 'Query', costCollector?: Maybe<{ __typename?: 'CostCollector', id: string, company: { __typename?: 'Company', id: string }, descriptions: Array<{ __typename?: 'CostCollectorTranslation', language: string, description: string }> }> };

export type CountryIdsTypesExistQueryVariables = Exact<{
  countryId: Scalars['ID'];
}>;


export type CountryIdsTypesExistQuery = { __typename?: 'Query', countryIdsTypesExist: boolean };

export type GetCountriesForCountriesIdsTypesFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CountryFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCountriesForCountriesIdsTypesFormQuery = { __typename?: 'Query', countriesCount: number, countries: Array<{ __typename?: 'Country', id: string, translation: { __typename?: 'CountryTranslation', description: string } }> };

export type CreateCountryIdsTypesMutationVariables = Exact<{
  countryIdsTypes: CountryIdsTypeInput;
}>;


export type CreateCountryIdsTypesMutation = { __typename?: 'Mutation', createCountryIdsTypes?: Maybe<any> };

export type UpdateCountryIdsTypesMutationVariables = Exact<{
  id: Scalars['ID'];
  countryIdsTypes: CountryIdsTypeInput;
}>;


export type UpdateCountryIdsTypesMutation = { __typename?: 'Mutation', updateCountryIdsTypes?: Maybe<any> };

export type DeleteCountryIdsTypesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCountryIdsTypesMutation = { __typename?: 'Mutation', deleteCountryIdsTypes?: Maybe<any> };

export type GetCountriesIdsTypes_ViewAllPageQueryVariables = Exact<{
  filters?: Maybe<CountryIdsTypeFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCountriesIdsTypes_ViewAllPageQuery = { __typename?: 'Query', countriesIdsTypesCount: number, countriesIdsTypes: Array<{ __typename?: 'CountryIdsType', country: { __typename?: 'Country', id: string }, idsTypes: Array<{ __typename?: 'IdType', id: string, isTaxId: boolean }> }> };

export type GetCountryIdsTypes_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCountryIdsTypes_ViewPageQuery = { __typename?: 'Query', countryIdsTypes?: Maybe<{ __typename?: 'CountryIdsType', country: { __typename?: 'Country', id: string }, idsTypes: Array<{ __typename?: 'IdType', id: string, isTaxId: boolean }> }> };

export type CountryExistsQueryVariables = Exact<{
  countryId: Scalars['ID'];
}>;


export type CountryExistsQuery = { __typename?: 'Query', countryExists: boolean };

export type CreateCountryMutationVariables = Exact<{
  country: CountryInput;
}>;


export type CreateCountryMutation = { __typename?: 'Mutation', createCountry?: Maybe<any> };

export type UpdateCountryMutationVariables = Exact<{
  id: Scalars['ID'];
  countryInput: CountryInput;
}>;


export type UpdateCountryMutation = { __typename?: 'Mutation', updateCountry?: Maybe<any> };

export type DeleteCountryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCountryMutation = { __typename?: 'Mutation', deleteCountry?: Maybe<any> };

export type GetCountriesTableQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CountryFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCountriesTableQuery = { __typename?: 'Query', countriesCount: number, countries: Array<{ __typename?: 'Country', id: string, translation: { __typename?: 'CountryTranslation', description: string } }> };

export type GetCountryViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCountryViewPageQuery = { __typename?: 'Query', country?: Maybe<{ __typename?: 'Country', id: string, translations: Array<{ __typename?: 'CountryTranslation', language: string, description: string }>, regions: Array<{ __typename?: 'CountryRegion', id: string, translations: Array<{ __typename?: 'CountryRegionTranslation', language: string, description: string }> }> }> };

export type CurrencyExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CurrencyExistsQuery = { __typename?: 'Query', currencyExists: boolean };

export type CreateCurrencyMutationVariables = Exact<{
  currency: CurrencyInput;
}>;


export type CreateCurrencyMutation = { __typename?: 'Mutation', createCurrency?: Maybe<any> };

export type UpdateCurrencyMutationVariables = Exact<{
  id: Scalars['ID'];
  currency: CurrencyInput;
}>;


export type UpdateCurrencyMutation = { __typename?: 'Mutation', updateCurrency?: Maybe<any> };

export type DeleteCurrencyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCurrencyMutation = { __typename?: 'Mutation', deleteCurrency?: Maybe<any> };

export type GetCurrencies_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<CurrencyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCurrencies_ViewAllPageQuery = { __typename?: 'Query', currenciesCount: number, currencies: Array<{ __typename?: 'FisCurrency', id: string, translation: { __typename?: 'CurrencyTranslation', description: string } }> };

export type GetCurrency_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCurrency_ViewPageQuery = { __typename?: 'Query', currency?: Maybe<{ __typename?: 'FisCurrency', id: string, translations: Array<{ __typename?: 'CurrencyTranslation', language: string, description: string }> }> };

export type DataElementExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DataElementExistsQuery = { __typename?: 'Query', dataElementExists: boolean };

export type CreateDataElementMutationVariables = Exact<{
  dataElement: DataElementInput;
}>;


export type CreateDataElementMutation = { __typename?: 'Mutation', createDataElement?: Maybe<any> };

export type UpdateDataElementMutationVariables = Exact<{
  id: Scalars['ID'];
  dataElement: DataElementInput;
}>;


export type UpdateDataElementMutation = { __typename?: 'Mutation', updateDataElement?: Maybe<any> };

export type DeleteDataElementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDataElementMutation = { __typename?: 'Mutation', deleteDataElement?: Maybe<any> };

export type GetDataElementsTablePageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<DataElementFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetDataElementsTablePageQuery = { __typename?: 'Query', dataElementsCount: number, dataElements: Array<{ __typename?: 'DataElement', id: string, status: DataElementStatus, dataType: DataElementTypeName, length?: Maybe<number>, decimals?: Maybe<number>, isLanguageDependent?: Maybe<boolean>, isUsed?: Maybe<boolean>, usedByTables: Array<string>, translation: { __typename?: 'DataElementTranslation', language: string, description: string } }> };

export type GetDataElementViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDataElementViewPageQuery = { __typename?: 'Query', dataElement?: Maybe<{ __typename?: 'DataElement', id: string, dataType: DataElementTypeName, length?: Maybe<number>, decimals?: Maybe<number>, isLanguageDependent?: Maybe<boolean>, isUsed?: Maybe<boolean>, status: DataElementStatus, translations: Array<{ __typename?: 'DataElementTranslation', language: string, description: string }>, choices?: Maybe<Array<{ __typename?: 'DataElementChoice', id: string, translations: Array<{ __typename?: 'DataElementChoiceTranslation', language: string, description: string }> }>> }> };

export type EmployeeExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmployeeExistsQuery = { __typename?: 'Query', employeeExists: boolean };

export type FindBusinessPartnerForCreatingEmployeeQueryVariables = Exact<{ [key: string]: never; }>;


export type FindBusinessPartnerForCreatingEmployeeQuery = { __typename?: 'Query', businessPartners: Array<{ __typename?: 'BusinessPartner', id: string, name: string }> };

export type GetUsersForCreatingEmployeeQueryVariables = Exact<{
  filters?: Maybe<UsersFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetUsersForCreatingEmployeeQuery = { __typename?: 'Query', usersCount: number, users: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, phoneNumber: string }> };

export type GetUserForCreatingEmployeeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserForCreatingEmployeeQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, firstName: string, lastName: string, phoneNumber: string, email: string }> };

export type GetAccountingEntitiesForEmployessFormQueryVariables = Exact<{
  filters?: Maybe<AccountingEntityFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetAccountingEntitiesForEmployessFormQuery = { __typename?: 'Query', accountingEntitiesCount: number, accountingEntities: Array<{ __typename?: 'AccountingEntity', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, address: { __typename?: 'Address', street: string, number: string, floor?: Maybe<string>, department?: Maybe<string>, city: string, postalCode: string, state: string, country: string } }> };

export type GetPositionsForCreateEmployeeQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetPositionsForCreateEmployeeQuery = { __typename?: 'Query', positions: Array<{ __typename?: 'Position', id: string, translation: { __typename?: 'PositionTranslation', description: string } }> };

export type CreateEmployeeMutationVariables = Exact<{
  employee: EmployeeInput;
}>;


export type CreateEmployeeMutation = { __typename?: 'Mutation', createEmployee?: Maybe<any> };

export type UpdateEmployeeMutationVariables = Exact<{
  id: Scalars['ID'];
  employee: EmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'Mutation', updateEmployee?: Maybe<any> };

export type DeleteEmployeeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEmployeeMutation = { __typename?: 'Mutation', deleteEmployee?: Maybe<any> };

export type GetEmployees_ViewAllPageQueryVariables = Exact<{
  filters?: Maybe<EmployeesFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetEmployees_ViewAllPageQuery = { __typename?: 'Query', employeesCount: number, employees: Array<{ __typename?: 'Employee', id: string, type: EmployeeType, businessPartnerId?: Maybe<string>, accountingEntityId?: Maybe<string>, positionHistory: Array<{ __typename?: 'PositionHistory', positionId: string, startDate: string }>, contact: { __typename?: 'EmployeeContact', email?: Maybe<string>, name?: Maybe<string>, lastName?: Maybe<string>, phoneNumber?: Maybe<string>, user?: Maybe<{ __typename?: 'User', id: string, firstName: string, lastName: string, phoneNumber: string, email: string }> } }> };

export type GetEmployee_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEmployee_ViewPageQuery = { __typename?: 'Query', employee?: Maybe<{ __typename?: 'Employee', id: string, type: EmployeeType, businessPartnerId?: Maybe<string>, accountingEntityId?: Maybe<string>, positionHistory: Array<{ __typename?: 'PositionHistory', positionId: string, startDate: string }>, contact: { __typename?: 'EmployeeContact', email?: Maybe<string>, name?: Maybe<string>, lastName?: Maybe<string>, phoneNumber?: Maybe<string>, user?: Maybe<{ __typename?: 'User', id: string, firstName: string, lastName: string, phoneNumber: string, email: string }> } }> };

export type GetBusinessObjectsForCreateDataMigrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessObjectsForCreateDataMigrationsQuery = { __typename?: 'Query', businessObjects: Array<{ __typename?: 'BusinessObject', id: string }> };

export type GetIdsInBusinessObjectForCreateMigrationPackageQueryVariables = Exact<{
  businessObjectId: Scalars['ID'];
}>;


export type GetIdsInBusinessObjectForCreateMigrationPackageQuery = { __typename?: 'Query', idsInBusinessObject: Array<string> };

export type CreateMigrationPackageInputMutationVariables = Exact<{
  migrationPackage: MigrationPackageInput;
}>;


export type CreateMigrationPackageInputMutation = { __typename?: 'Mutation', createMigrationPackage?: Maybe<any> };

export type UpdateMigrationPackageInputMutationVariables = Exact<{
  id: Scalars['ID'];
  migrationPackage: MigrationPackageInput;
}>;


export type UpdateMigrationPackageInputMutation = { __typename?: 'Mutation', updateMigrationPackage?: Maybe<any> };

export type DeleteMigrationPackageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMigrationPackageMutation = { __typename?: 'Mutation', deleteMigrationPackage?: Maybe<any> };

export type PublishMigrationPackageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublishMigrationPackageMutation = { __typename?: 'Mutation', publishMigrationPackage?: Maybe<any> };

export type GetOwnMigrationPackagesForTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type GetOwnMigrationPackagesForTableQuery = { __typename?: 'Query', ownMigrationPackagesCount: number, ownMigrationPackages: Array<{ __typename?: 'MigrationPackage', packageId: string, description: string, status: MigrationPackageStatus, publishedAt: string }> };

export type GetOwnMigrationPackageForPageViewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOwnMigrationPackageForPageViewQuery = { __typename?: 'Query', ownMigrationPackage?: Maybe<{ __typename?: 'MigrationPackage', description: string, status: MigrationPackageStatus, definition: Array<{ __typename?: 'BusinessObjectDataMigration', ids: Array<string>, businessObjectId: string }> }> };

export type GroupExistsQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type GroupExistsQuery = { __typename?: 'Query', groupExists: boolean };

export type GetTilesForGroupFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<TilesFilters>;
}>;


export type GetTilesForGroupFormQuery = { __typename?: 'Query', tiles: Array<{ __typename?: 'Tile', id: string, shape: TileShape, translation: { __typename?: 'TileTranslation', language: string, title: string, subtitle: string } }> };

export type GetBusinessObjectsForGroupFormQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetBusinessObjectsForGroupFormQuery = { __typename?: 'Query', businessObjects: Array<{ __typename?: 'BusinessObject', dataElement: { __typename?: 'DataElement', id: string, translation: { __typename?: 'DataElementTranslation', description: string } } }> };

export type CreateGroupMutationVariables = Exact<{
  group: GroupInput;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup?: Maybe<string> };

export type RequestPermissionUploadGroupIconToS3MutationVariables = Exact<{ [key: string]: never; }>;


export type RequestPermissionUploadGroupIconToS3Mutation = { __typename?: 'Mutation', requestPermissionUploadGroupIconToS3: { __typename?: 'SignatureUploadFileS3', url: string, bucket: string, key: string } };

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  group: GroupInput;
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', updateGroup?: Maybe<string> };

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGroupMutation = { __typename?: 'Mutation', deleteGroup?: Maybe<string> };

export type GetGroupsForTablePageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<GroupsFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetGroupsForTablePageQuery = { __typename?: 'Query', groupsCount: number, groups: Array<{ __typename?: 'Group', id: string, translation: { __typename?: 'GroupTranslation', description: string, language: string } }> };

export type GetGroupForViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGroupForViewPageQuery = { __typename?: 'Query', group?: Maybe<{ __typename?: 'Group', id: string, translations: Array<{ __typename?: 'GroupTranslation', description: string, language: string }>, tiles: Array<{ __typename?: 'Tile', id: string }>, icon: { __typename?: 'S3File', id: string, name: string, url: any } }> };

export type ApplyMigrationPackageMutationVariables = Exact<{
  systemId: Scalars['ID'];
  packageId: Scalars['ID'];
}>;


export type ApplyMigrationPackageMutation = { __typename?: 'Mutation', applyMigrationPackage?: Maybe<any> };

export type GetExternalMigrationPackagesForTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type GetExternalMigrationPackagesForTableQuery = { __typename?: 'Query', externalMigrationPackagesCount: number, externalMigrationPackages: Array<{ __typename?: 'MigrationPackage', systemId: string, packageId: string, description: string, isApplied: boolean, appliedAt?: Maybe<string>, publishedAt: string }> };

export type GetDeliveryPurchaseOrderItemForDirectPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeliveryPurchaseOrderItemForDirectPageQuery = { __typename?: 'Query', deliveryPurchaseOrderItems: Array<{ __typename?: 'PurchaseOrderItem', purchaseOrderItem: string, purchaseOrder: string }> };

export type GetAllInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllInvoicesQuery = { __typename?: 'Query', invoices?: Maybe<Array<Maybe<{ __typename?: 'InvoiceHeader', id: string, invoiceVendorNumber?: Maybe<string>, invoiceBackendNumber?: Maybe<string> }>>> };

export type GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQueryVariables = Exact<{
  filters?: Maybe<PurchaseOrderItemFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery = { __typename?: 'Query', deliveryPurchaseOrderItemsCount: number, deliveryPurchaseOrderItems: Array<{ __typename?: 'PurchaseOrderItem', purchaseOrder: string, purchaseOrderItem: string, productId: string, productDescription: string, hasInvoice: boolean, locationDescription?: Maybe<string>, unit: string, poQuantity: any, netUnitPrice: any, netTotalPrice: any, grQuantity?: Maybe<any>, grAmount?: Maybe<any>, picInvoicedQuantity?: Maybe<any>, picInvoicedAmount?: Maybe<any>, invoiceApprovedQuantity?: Maybe<any>, invoiceApprovedAmount?: Maybe<any>, needGr?: Maybe<boolean>, completeInvoice?: Maybe<InvoiceItemStatus> }> };

export type GetInvoicesReportPageQueryVariables = Exact<{
  filters?: Maybe<InvoiceFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetInvoicesReportPageQuery = { __typename?: 'Query', invoicesCount: number, invoices?: Maybe<Array<Maybe<{ __typename?: 'InvoiceHeader', id: string, purchaseOrder: string, purchaseOrderItem: string, accountingEntity: string, vendorId: string, currency: string, invoiceStatus: InvoiceStatus, invoiceDate: string, invoiceVendorNumber?: Maybe<string>, invoiceBackendNumber?: Maybe<string>, fiscalYear: string, headerText: string, plannedPaymentDate?: Maybe<string>, lastPaymentDate?: Maybe<string>, rejectionReason?: Maybe<string>, total: any, file: { __typename?: 'S3File', id: string, name: string, url: any } }>>> };

export type GetInvoiceForExtraInformationFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInvoiceForExtraInformationFormQuery = { __typename?: 'Query', invoice?: Maybe<{ __typename?: 'InvoiceHeader', id: string, invoiceBackendNumber?: Maybe<string>, fiscalYear: string, currency: string, plannedPaymentDate?: Maybe<string>, lastPaymentDate?: Maybe<string>, invoiceStatus: InvoiceStatus, rejectionReason?: Maybe<string> }> };

export type GetPurchaseOrderExtraChargesQueryVariables = Exact<{
  purchaseOrder: Scalars['ID'];
}>;


export type GetPurchaseOrderExtraChargesQuery = { __typename?: 'Query', purchaseOrder?: Maybe<{ __typename?: 'PurchaseOrderHeader', extraCharges: boolean }> };

export type GetPurchaseOrdersItemsForCreateInvoiceQueryVariables = Exact<{
  purchaseOrder: Scalars['String'];
}>;


export type GetPurchaseOrdersItemsForCreateInvoiceQuery = { __typename?: 'Query', purchaseOrderItems: Array<{ __typename?: 'PurchaseOrderItem', purchaseOrderItem: string, backend: string, productId: string, productDescription: string, unit: string, poQuantity: any, netUnitPrice: any, netTotalPrice: any, grQuantity?: Maybe<any>, grAmount?: Maybe<any>, picInvoicedQuantity?: Maybe<any>, picInvoicedAmount?: Maybe<any>, invoiceApprovedQuantity?: Maybe<any>, invoiceApprovedAmount?: Maybe<any>, remainingQuantity?: Maybe<any>, remainingAmount?: Maybe<any>, needGr?: Maybe<boolean>, tolerance?: Maybe<ToleranceCatalogue>, tolerancePercentage?: Maybe<any>, toleranceAmount?: Maybe<any> }> };

export type CreateInvoiceMutationVariables = Exact<{
  invoice: InvoiceInput;
}>;


export type CreateInvoiceMutation = { __typename?: 'Mutation', createInvoice?: Maybe<any> };

export type RequestPermissionUploadInvoiceFileToS3MutationVariables = Exact<{ [key: string]: never; }>;


export type RequestPermissionUploadInvoiceFileToS3Mutation = { __typename?: 'Mutation', requestPermissionUploadInvoiceFileToS3: { __typename?: 'SignatureUploadFileS3', url: string, bucket: string, key: string } };

export type UpdateInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
  invoice: InvoiceInput;
}>;


export type UpdateInvoiceMutation = { __typename?: 'Mutation', updateInvoice?: Maybe<any> };

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteInvoiceMutation = { __typename?: 'Mutation', deleteInvoice?: Maybe<any> };

export type UpdateInvoiceStatusMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateInvoiceStatusMutation = { __typename?: 'Mutation', updateInvoiceStatus?: Maybe<any> };

export type GetInvoicesViewAllPageQueryVariables = Exact<{
  purchaseOrder: Scalars['String'];
  purchaseOrderItem: Scalars['String'];
  filters?: Maybe<InvoiceFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetInvoicesViewAllPageQuery = { __typename?: 'Query', purchaseOrdersInvoicesCount: number, purchaseOrdersInvoices?: Maybe<Array<Maybe<{ __typename?: 'InvoiceHeader', id: string, invoiceBackendNumber?: Maybe<string>, invoiceVendorNumber?: Maybe<string>, headerText: string, fiscalYear: string, total: any, currency: string, invoiceDate: string, plannedPaymentDate?: Maybe<string>, lastPaymentDate?: Maybe<string>, rejectionReason?: Maybe<string>, invoiceStatus: InvoiceStatus, file: { __typename?: 'S3File', id: string, url: any } }>>> };

export type GetInvoiceForViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInvoiceForViewPageQuery = { __typename?: 'Query', invoice?: Maybe<{ __typename?: 'InvoiceHeader', id: string, backend: string, accountingEntity: string, vendorId: string, currency: string, headerText: string, invoiceDate: string, invoiceBackendNumber?: Maybe<string>, invoiceVendorNumber?: Maybe<string>, invoiceStatus: InvoiceStatus, total: any, file: { __typename?: 'S3File', id: string, url: any } }> };

export type GetInvoiceItemsForViewPageQueryVariables = Exact<{
  invoiceNumber: Scalars['String'];
}>;


export type GetInvoiceItemsForViewPageQuery = { __typename?: 'Query', invoiceItem?: Maybe<Array<Maybe<{ __typename?: 'InvoiceItem', id: string, backend: string, taxesAmount: any, totalPrice: any, itemText?: Maybe<string>, invoiceNumber: string, purchaseOrder: string, purchaseOrderItem: string, productId: string, unit: string, quantity: any, netUnitPrice: any, netTotalPrice: any, extraChargesAmount?: Maybe<any> }>>> };

export type GetBusinessObjectsForCreateKpiQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetBusinessObjectsForCreateKpiQuery = { __typename?: 'Query', businessObjects: Array<{ __typename?: 'BusinessObject', id: string, dataElement: { __typename?: 'DataElement', translation: { __typename?: 'DataElementTranslation', description: string } } }> };

export type KpiExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KpiExistsQuery = { __typename?: 'Query', kpiExists: boolean };

export type GetBusinessObjectForCreatingKpiQueryVariables = Exact<{
  businessObjectId: Scalars['ID'];
  language: Scalars['String'];
}>;


export type GetBusinessObjectForCreatingKpiQuery = { __typename?: 'Query', businessObject?: Maybe<{ __typename?: 'BusinessObject', tables: Array<{ __typename?: 'BusinessObjectTable', table: { __typename?: 'Table', id: string, translation: { __typename?: 'TableTranslation', description: string }, columns: Array<{ __typename?: 'TableColumn', id: string, type: TableColumnType, dataElement: { __typename?: 'DataElement', id: string, translation: { __typename?: 'DataElementTranslation', description: string } } }> } }> }> };

export type CreateKpiMutationVariables = Exact<{
  kpi: KpiInput;
}>;


export type CreateKpiMutation = { __typename?: 'Mutation', createKpi?: Maybe<any> };

export type UpdateKpiMutationVariables = Exact<{
  id: Scalars['ID'];
  kpi: KpiInput;
}>;


export type UpdateKpiMutation = { __typename?: 'Mutation', updateKpi?: Maybe<any> };

export type DeleteKpiMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteKpiMutation = { __typename?: 'Mutation', deleteKpi?: Maybe<any> };

export type GetKpisForViewTableQueryVariables = Exact<{
  pagination?: Maybe<Pagination>;
  filters?: Maybe<KpiFilters>;
}>;


export type GetKpisForViewTableQuery = { __typename?: 'Query', kpisCount: number, kpis: Array<{ __typename?: 'Kpi', id: string, type: KpiType, value?: Maybe<string>, businessObject: { __typename?: 'BusinessObject', id: string } }> };

export type GetKpiForViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetKpiForViewPageQuery = { __typename?: 'Query', kpi?: Maybe<{ __typename?: 'Kpi', id: string, type: KpiType, businessObject: { __typename?: 'BusinessObject', id: string }, configuration?: Maybe<{ __typename?: 'KpiConfiguration', tableId?: Maybe<string>, columnId?: Maybe<string>, aggregationType?: Maybe<AggregationType>, filters?: Maybe<Array<{ __typename?: 'KpiConfigurationFilter', columnId?: Maybe<string>, comparisonType?: Maybe<ComparisonType>, reference?: Maybe<string> }>> }> }> };

export type LanguageExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LanguageExistsQuery = { __typename?: 'Query', languageExists: boolean };

export type CreateLanguageMutationVariables = Exact<{
  language: LanguageInput;
}>;


export type CreateLanguageMutation = { __typename?: 'Mutation', createLanguage?: Maybe<any> };

export type UpdateLanguageMutationVariables = Exact<{
  id: Scalars['ID'];
  language: LanguageInput;
}>;


export type UpdateLanguageMutation = { __typename?: 'Mutation', updateLanguage?: Maybe<any> };

export type DeleteLanguageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLanguageMutation = { __typename?: 'Mutation', deleteLanguage?: Maybe<any> };

export type GetLanguages_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<LanguageFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetLanguages_ViewAllPageQuery = { __typename?: 'Query', languagesCount: number, languages: Array<{ __typename?: 'Language', id: string, translation: { __typename?: 'LanguageTranslation', description: string } }> };

export type GetLanguage_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLanguage_ViewPageQuery = { __typename?: 'Query', language?: Maybe<{ __typename?: 'Language', id: string, translations: Array<{ __typename?: 'LanguageTranslation', language: string, description: string }> }> };

export type LocationTypeExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LocationTypeExistsQuery = { __typename?: 'Query', locationTypeExists: boolean };

export type CreateLocationTypeMutationVariables = Exact<{
  locationType: LocationTypeInput;
}>;


export type CreateLocationTypeMutation = { __typename?: 'Mutation', createLocationType?: Maybe<any> };

export type UpdateLocationTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  locationType: LocationTypeInput;
}>;


export type UpdateLocationTypeMutation = { __typename?: 'Mutation', updateLocationType?: Maybe<any> };

export type DeleteLocationTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationTypeMutation = { __typename?: 'Mutation', deleteLocationType?: Maybe<any> };

export type GetLocationTypes_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<LocationTypeFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetLocationTypes_ViewAllPageQuery = { __typename?: 'Query', locationTypesCount: number, locationTypes: Array<{ __typename?: 'LocationType', id: string, translation: { __typename?: 'LocationTypeTranslation', description: string } }> };

export type GetLocationType_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationType_ViewPageQuery = { __typename?: 'Query', locationType?: Maybe<{ __typename?: 'LocationType', id: string, translations: Array<{ __typename?: 'LocationTypeTranslation', language: string, description: string }> }> };

export type LocationExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LocationExistsQuery = { __typename?: 'Query', locationExists: boolean };

export type GetLocationTypesForLocationsFormQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<LocationTypeFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetLocationTypesForLocationsFormQuery = { __typename?: 'Query', locationTypesCount: number, locationTypes: Array<{ __typename?: 'LocationType', id: string, translation: { __typename?: 'LocationTypeTranslation', description: string } }> };

export type CreateLocationMutationVariables = Exact<{
  location: LocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation?: Maybe<any> };

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  location: LocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation?: Maybe<any> };

export type DeletLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletLocationMutation = { __typename?: 'Mutation', deleteLocation?: Maybe<any> };

export type GetLocations_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<LocationFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetLocations_ViewAllPageQuery = { __typename?: 'Query', locationsCount: number, locations: Array<{ __typename?: 'Location', id: string, address: { __typename?: 'Address', street: string, number: string, floor?: Maybe<string>, department?: Maybe<string>, city: string, postalCode: string, state: string, country: string }, translation: { __typename?: 'LocationTranslation', description: string }, locationType: { __typename?: 'LocationType', id: string, translations: Array<{ __typename?: 'LocationTypeTranslation', language: string, description: string }> } }> };

export type GetLocation_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocation_ViewPageQuery = { __typename?: 'Query', location?: Maybe<{ __typename?: 'Location', id: string, address: { __typename?: 'Address', street: string, number: string, floor?: Maybe<string>, department?: Maybe<string>, city: string, postalCode: string, state: string, country: string }, translations: Array<{ __typename?: 'LocationTranslation', language: string, description: string }>, locationType: { __typename?: 'LocationType', id: string, translations: Array<{ __typename?: 'LocationTypeTranslation', language: string, description: string }> }, gates: Array<{ __typename?: 'Gate', id: string, isInput: boolean, isOutput: boolean, translations: Array<{ __typename?: 'GateTranslation', language: string, description: string }> }> }> };

export type MessageExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MessageExistsQuery = { __typename?: 'Query', messageExists: boolean };

export type CreateMessageMutationVariables = Exact<{
  message: MessageInput;
}>;


export type CreateMessageMutation = { __typename?: 'Mutation', createMessage?: Maybe<any> };

export type UpdateMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message: MessageInput;
}>;


export type UpdateMessageMutation = { __typename?: 'Mutation', updateMessage?: Maybe<any> };

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMessageMutation = { __typename?: 'Mutation', deleteMessage?: Maybe<any> };

export type GetMessages_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<MessageFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetMessages_ViewAllPageQuery = { __typename?: 'Query', messagesCount: number, messages: Array<{ __typename?: 'Message', id: string, translation: { __typename?: 'MessageTranslation', description: string } }> };

export type GetMessage_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMessage_ViewPageQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', id: string, translations: Array<{ __typename?: 'MessageTranslation', language: string, description: string }> }> };

export type PositionExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PositionExistsQuery = { __typename?: 'Query', positionExists: boolean };

export type CreatePositionMutationVariables = Exact<{
  position: PositionInput;
}>;


export type CreatePositionMutation = { __typename?: 'Mutation', createPosition?: Maybe<any> };

export type UpdatePositionMutationVariables = Exact<{
  id: Scalars['ID'];
  position: PositionInput;
}>;


export type UpdatePositionMutation = { __typename?: 'Mutation', updatePosition?: Maybe<any> };

export type DeletePositionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePositionMutation = { __typename?: 'Mutation', deletePosition?: Maybe<any> };

export type GetPositions_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<PositionFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetPositions_ViewAllPageQuery = { __typename?: 'Query', positionsCount: number, positions: Array<{ __typename?: 'Position', id: string, translation: { __typename?: 'PositionTranslation', description: string } }> };

export type GetPosition_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPosition_ViewPageQuery = { __typename?: 'Query', position?: Maybe<{ __typename?: 'Position', id: string, translations: Array<{ __typename?: 'PositionTranslation', language: string, description: string }> }> };

export type PurchaseEntityExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PurchaseEntityExistsQuery = { __typename?: 'Query', purchaseEntityExists: boolean };

export type GetCompaniesForPurchaseEntitiesFormQueryVariables = Exact<{
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCompaniesForPurchaseEntitiesFormQuery = { __typename?: 'Query', companiesCount: number, companies: Array<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type CreatePurchaseEntityMutationVariables = Exact<{
  purchaseEntity: PurchaseEntityInput;
}>;


export type CreatePurchaseEntityMutation = { __typename?: 'Mutation', createPurchaseEntity?: Maybe<any> };

export type UpdatePurchaseEntityMutationVariables = Exact<{
  id: Scalars['ID'];
  purchaseEntity: PurchaseEntityInput;
}>;


export type UpdatePurchaseEntityMutation = { __typename?: 'Mutation', updatePurchaseEntity?: Maybe<any> };

export type DeletePurchaseEntityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePurchaseEntityMutation = { __typename?: 'Mutation', deletePurchaseEntity?: Maybe<any> };

export type GetPurchaseEntities_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<PurchaseEntityFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetPurchaseEntities_ViewAllPageQuery = { __typename?: 'Query', purchaseEntitiesCount: number, purchaseEntities: Array<{ __typename?: 'PurchaseEntity', id: string, translation: { __typename?: 'PurchaseEntityTranslation', description: string }, company: { __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } } }> };

export type GetPurchaseEntity_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPurchaseEntity_ViewPageQuery = { __typename?: 'Query', purchaseEntity?: Maybe<{ __typename?: 'PurchaseEntity', id: string, translations: Array<{ __typename?: 'PurchaseEntityTranslation', language: string, description: string }>, company: { __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } } }> };

export type GetPurchaseOrdersForPurchaseOrdersItemsReportsQueryVariables = Exact<{
  filters?: Maybe<PurchaseOrderFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetPurchaseOrdersForPurchaseOrdersItemsReportsQuery = { __typename?: 'Query', purchaseOrdersCount: number, purchaseOrders: Array<{ __typename?: 'PurchaseOrderHeader', id: string, accountingEntityDescription?: Maybe<string>, vendorId: string, vendorName?: Maybe<string>, currency: string, netTotalPrice?: Maybe<any>, createdAt?: Maybe<string>, purchaseOrderStatus?: Maybe<PurchaseOrderStatus> }> };

export type GetPurchaseOrdersForInvoicesReportsQueryVariables = Exact<{
  filters?: Maybe<PurchaseOrderFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetPurchaseOrdersForInvoicesReportsQuery = { __typename?: 'Query', purchaseOrdersCount: number, purchaseOrders: Array<{ __typename?: 'PurchaseOrderHeader', id: string, accountingEntityDescription?: Maybe<string>, vendorId: string, vendorName?: Maybe<string>, currency: string, netTotalPrice?: Maybe<any>, createdAt?: Maybe<string>, purchaseOrderStatus?: Maybe<PurchaseOrderStatus> }> };

export type GetPurchaseOrdersItemsForInvoicesReportsQueryVariables = Exact<{
  purchaseOrder: Scalars['String'];
  filters?: Maybe<PurchaseOrderItemFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetPurchaseOrdersItemsForInvoicesReportsQuery = { __typename?: 'Query', purchaseOrderItems: Array<{ __typename?: 'PurchaseOrderItem', purchaseOrderItem: string }> };

export type GetPurchaseOrdersItemsReportPageQueryVariables = Exact<{
  filters?: Maybe<PurchaseOrderItemFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetPurchaseOrdersItemsReportPageQuery = { __typename?: 'Query', allPurchaseOrderItemsCount: number, allPurchaseOrderItems: Array<{ __typename?: 'PurchaseOrderItem', purchaseOrderItem: string, purchaseOrder: string, productId: string, productDescription: string, hasInvoice: boolean, locationDescription?: Maybe<string>, unit: string, poQuantity: any, netUnitPrice: any, netTotalPrice: any, grQuantity?: Maybe<any>, grAmount?: Maybe<any>, picInvoicedQuantity?: Maybe<any>, picInvoicedAmount?: Maybe<any>, invoiceApprovedQuantity?: Maybe<any>, invoiceApprovedAmount?: Maybe<any>, completeInvoice?: Maybe<InvoiceItemStatus> }> };

export type GetPurchaseOrdersForReportPageQueryVariables = Exact<{
  filters?: Maybe<PurchaseOrderFilters>;
}>;


export type GetPurchaseOrdersForReportPageQuery = { __typename?: 'Query', purchaseOrders: Array<{ __typename?: 'PurchaseOrderHeader', id: string, accountingEntityDescription?: Maybe<string>, vendorId: string, vendorName?: Maybe<string>, currency: string, netTotalPrice?: Maybe<any>, createdAt?: Maybe<string>, purchaseEntity: string, purchaseOrderStatus?: Maybe<PurchaseOrderStatus> }> };

export type GetPurchaseOrderItemsViewAllPageQueryVariables = Exact<{
  filters?: Maybe<PurchaseOrderItemFilters>;
  pagination?: Maybe<Pagination>;
  purchaseOrder: Scalars['String'];
}>;


export type GetPurchaseOrderItemsViewAllPageQuery = { __typename?: 'Query', purchaseOrderItemsCount: number, purchaseOrderItems: Array<{ __typename?: 'PurchaseOrderItem', purchaseOrderItem: string, productId: string, productDescription: string, hasInvoice: boolean, locationDescription?: Maybe<string>, unit: string, poQuantity: any, netUnitPrice: any, netTotalPrice: any, grQuantity?: Maybe<any>, grAmount?: Maybe<any>, picInvoicedQuantity?: Maybe<any>, picInvoicedAmount?: Maybe<any>, invoiceApprovedQuantity?: Maybe<any>, invoiceApprovedAmount?: Maybe<any>, needGr?: Maybe<boolean>, completeInvoice?: Maybe<InvoiceItemStatus> }> };

export type GetPurchaseOrderDetailViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPurchaseOrderDetailViewPageQuery = { __typename?: 'Query', purchaseOrder?: Maybe<{ __typename?: 'PurchaseOrderHeader', id: string, backend: string, accountingEntity: string, accountingEntityDescription?: Maybe<string>, vendorId: string, vendorName?: Maybe<string>, currency: string, extraCharges: boolean, purchaseEntity: string, netTotalPrice?: Maybe<any>, createdAt?: Maybe<string> }> };

export type GetPurchaseOrdersViewAllPageQueryVariables = Exact<{
  filters?: Maybe<PurchaseOrderFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetPurchaseOrdersViewAllPageQuery = { __typename?: 'Query', purchaseOrdersCount: number, purchaseOrders: Array<{ __typename?: 'PurchaseOrderHeader', id: string, accountingEntityDescription?: Maybe<string>, vendorId: string, vendorName?: Maybe<string>, currency: string, netTotalPrice?: Maybe<any>, createdAt?: Maybe<string>, purchaseOrderStatus?: Maybe<PurchaseOrderStatus>, extraCharges: boolean }> };

export type RevenueCollectorExistsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RevenueCollectorExistsQuery = { __typename?: 'Query', revenueCollectorExists: boolean };

export type CreateRevenueCollectorMutationVariables = Exact<{
  revenueCollector: RevenueCollectorInput;
}>;


export type CreateRevenueCollectorMutation = { __typename?: 'Mutation', createRevenueCollector?: Maybe<any> };

export type UpdateRevenueCollectorMutationVariables = Exact<{
  id: Scalars['ID'];
  revenueCollector: RevenueCollectorInput;
}>;


export type UpdateRevenueCollectorMutation = { __typename?: 'Mutation', updateRevenueCollector?: Maybe<any> };

export type DeleteRevenueCollectorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRevenueCollectorMutation = { __typename?: 'Mutation', deleteRevenueCollector?: Maybe<any> };

export type GetRevenueCollectors_ViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<RevenueCollectorFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetRevenueCollectors_ViewAllPageQuery = { __typename?: 'Query', revenueCollectorsCount: number, revenueCollectors: Array<{ __typename?: 'RevenueCollector', id: string, translation: { __typename?: 'RevenueCollectorTranslation', description: string }, company: { __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } } }> };

export type GetCompanies_ForRevenueCollectorsPageQueryVariables = Exact<{
  filters?: Maybe<CompanyFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetCompanies_ForRevenueCollectorsPageQuery = { __typename?: 'Query', companiesCount: number, companies: Array<{ __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } }> };

export type GetRevenueCollector_ViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRevenueCollector_ViewPageQuery = { __typename?: 'Query', revenueCollector?: Maybe<{ __typename?: 'RevenueCollector', id: string, translations: Array<{ __typename?: 'RevenueCollectorTranslation', language: string, description: string }>, company: { __typename?: 'Company', id: string, name: string, currency: { __typename?: 'FisCurrency', id: string }, chartOfAccount: { __typename?: 'ChartOfAccount', id: string } } }> };

export type TableExistsQueryVariables = Exact<{
  tableId: Scalars['ID'];
}>;


export type TableExistsQuery = { __typename?: 'Query', tableExists: boolean };

export type GetTablesForCreateTableQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTablesForCreateTableQuery = { __typename?: 'Query', tables: Array<{ __typename?: 'Table', id: string, columns: Array<{ __typename?: 'TableColumn', id: string, dataElement: { __typename?: 'DataElement', id: string } }> }> };

export type GetDataElementsForCreateTableQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetDataElementsForCreateTableQuery = { __typename?: 'Query', dataElements: Array<{ __typename?: 'DataElement', id: string, dataType: DataElementTypeName, decimals?: Maybe<number>, length?: Maybe<number>, isLanguageDependent?: Maybe<boolean>, translation: { __typename?: 'DataElementTranslation', description: string } }> };

export type CreateTableMutationVariables = Exact<{
  table: TableInput;
}>;


export type CreateTableMutation = { __typename?: 'Mutation', createTable?: Maybe<any> };

export type UpdateTableMutationVariables = Exact<{
  id: Scalars['ID'];
  table: TableInput;
}>;


export type UpdateTableMutation = { __typename?: 'Mutation', updateTable?: Maybe<any> };

export type GetTableMigrationsQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Scalars['String'];
}>;


export type GetTableMigrationsQuery = { __typename?: 'Query', table?: Maybe<{ __typename?: 'Table', translation: { __typename?: 'TableTranslation', language: string, description: string } }>, tableMigrations: Array<{ __typename?: 'TableMigration', version: number, fileName: string, timestamp: string, downloadUrl: string }> };

export type DeleteTableMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTableMutation = { __typename?: 'Mutation', deleteTable?: Maybe<any> };

export type ActivateTableMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateTableMutation = { __typename?: 'Mutation', activateTable?: Maybe<any> };

export type DeactivateTableMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateTableMutation = { __typename?: 'Mutation', deactivateTable?: Maybe<any> };

export type GetTablesForViewPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<TablesFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetTablesForViewPageQuery = { __typename?: 'Query', tablesCount: number, tables: Array<{ __typename?: 'Table', id: string, tableType: TableType, status: TableStatus, translation: { __typename?: 'TableTranslation', description: string } }> };

export type GetTableForViewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTableForViewQuery = { __typename?: 'Query', table?: Maybe<{ __typename?: 'Table', id: string, tableType: TableType, columns: Array<{ __typename?: 'TableColumn', id: string, isPrimaryKey: boolean, type: TableColumnType, dataElement: { __typename?: 'DataElement', id: string } }>, translations: Array<{ __typename?: 'TableTranslation', language: string, description: string }> }> };

export type ThemeExistsQueryVariables = Exact<{
  themeId: Scalars['ID'];
}>;


export type ThemeExistsQuery = { __typename?: 'Query', themeExists: boolean };

export type CreateThemeMutationVariables = Exact<{
  theme: ThemeInput;
}>;


export type CreateThemeMutation = { __typename?: 'Mutation', createTheme?: Maybe<any> };

export type RequestPermissionUploadThemeBackgroundImageToS3MutationVariables = Exact<{ [key: string]: never; }>;


export type RequestPermissionUploadThemeBackgroundImageToS3Mutation = { __typename?: 'Mutation', requestPermissionUploadThemeBackgroundImageToS3: { __typename?: 'SignatureUploadFileS3', url: string, bucket: string, key: string } };

export type GetThemeInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetThemeInformationQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, effectiveTheme: { __typename?: 'Theme', id: string, primaryColor: string, primaryColorContrast: string, userColor: string, appsColor: string, appsTitleColor: string, stepperColor1: string, stepperColor2: string, stepperColor3: string, stepperTextColor: string, stepperInternalColor: string, backgroundImage: { __typename?: 'S3File', name: string, url: any }, companyLogo?: Maybe<{ __typename?: 'S3File', name: string, url: any }> } } };

export type UpdateThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: ThemeInput;
}>;


export type UpdateThemeMutation = { __typename?: 'Mutation', updateTheme?: Maybe<any> };

export type SetDefaultThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetDefaultThemeMutation = { __typename?: 'Mutation', setDefaultTheme?: Maybe<any> };

export type DeleteThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteThemeMutation = { __typename?: 'Mutation', deleteTheme?: Maybe<any> };

export type GetThemesQueryVariables = Exact<{
  filters: ThemesFilters;
  pagination: Pagination;
  language: Scalars['String'];
}>;


export type GetThemesQuery = { __typename?: 'Query', themesCount: number, themes: Array<{ __typename?: 'Theme', id: string, isDefault: boolean, primaryColor: string, primaryColorContrast: string, userColor: string, appsColor: string, appsTitleColor: string, stepperColor1: string, stepperColor2: string, stepperColor3: string, stepperTextColor: string, stepperInternalColor: string, backgroundImage: { __typename?: 'S3File', name: string, url: any }, translation: { __typename?: 'ThemeTranslation', description: string } }> };

export type GetThemeByIdQueryVariables = Exact<{
  name: Scalars['ID'];
}>;


export type GetThemeByIdQuery = { __typename?: 'Query', theme?: Maybe<{ __typename?: 'Theme', id: string, primaryColor: string, primaryColorContrast: string, userColor: string, appsColor: string, appsTitleColor: string, stepperColor1: string, stepperColor2: string, stepperColor3: string, stepperTextColor: string, stepperInternalColor: string, backgroundImage: { __typename?: 'S3File', name: string, url: any }, companyLogo?: Maybe<{ __typename?: 'S3File', name: string, url: any }>, translations: Array<{ __typename?: 'ThemeTranslation', language: string, description: string }> }> };

export type GetAppsCreateTileFormQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetAppsCreateTileFormQuery = { __typename?: 'Query', apps: Array<{ __typename?: 'App', id: string, translation: { __typename?: 'AppTranslation', description: string } }> };

export type GetKpisForCreateTileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKpisForCreateTileQuery = { __typename?: 'Query', kpis: Array<{ __typename?: 'Kpi', id: string, businessObject: { __typename?: 'BusinessObject', id: string, status: BusinessObjectStatus } }> };

export type CreateTileMutationVariables = Exact<{
  tile: TileInput;
}>;


export type CreateTileMutation = { __typename?: 'Mutation', createTile?: Maybe<any> };

export type RequestPermissionUploadTileIconToS3MutationVariables = Exact<{ [key: string]: never; }>;


export type RequestPermissionUploadTileIconToS3Mutation = { __typename?: 'Mutation', requestPermissionUploadTileIconToS3: { __typename?: 'SignatureUploadFileS3', url: string, bucket: string, key: string } };

export type UpdateTileMutationVariables = Exact<{
  id: Scalars['ID'];
  tile: TileInput;
}>;


export type UpdateTileMutation = { __typename?: 'Mutation', updateTile?: Maybe<any> };

export type DeleteTileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTileMutation = { __typename?: 'Mutation', deleteTile?: Maybe<any> };

export type GetTilesForTablePageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<TilesFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetTilesForTablePageQuery = { __typename?: 'Query', tilesCount: number, tiles: Array<{ __typename?: 'Tile', id: string, app: { __typename?: 'App', id: string, translation: { __typename?: 'AppTranslation', description: string } }, translation: { __typename?: 'TileTranslation', title: string, subtitle: string } }> };

export type GetTileForViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTileForViewPageQuery = { __typename?: 'Query', tile?: Maybe<{ __typename?: 'Tile', id: string, path: string, shape: TileShape, app: { __typename?: 'App', id: string }, translations: Array<{ __typename?: 'TileTranslation', language: string, title: string, subtitle: string }>, kpi?: Maybe<{ __typename?: 'Kpi', id: string }>, icon?: Maybe<{ __typename?: 'S3File', id: string, name: string, url: any }> }> };

export type GetAppsForCreateTutorialFormQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetAppsForCreateTutorialFormQuery = { __typename?: 'Query', apps: Array<{ __typename?: 'App', id: string, translation: { __typename?: 'AppTranslation', description: string } }> };

export type TutorialExistsQueryVariables = Exact<{
  tutorialId: Scalars['ID'];
}>;


export type TutorialExistsQuery = { __typename?: 'Query', tutorialExists: boolean };

export type CreateTutorialMutationVariables = Exact<{
  tutorial: TutorialInput;
}>;


export type CreateTutorialMutation = { __typename?: 'Mutation', createTutorial?: Maybe<any> };

export type UpdateTutorialMutationVariables = Exact<{
  id: Scalars['ID'];
  tutorial: TutorialInput;
}>;


export type UpdateTutorialMutation = { __typename?: 'Mutation', updateTutorial?: Maybe<any> };

export type DeleteTutorialMutationVariables = Exact<{
  tutorialId: Scalars['ID'];
}>;


export type DeleteTutorialMutation = { __typename?: 'Mutation', deleteTutorial?: Maybe<any> };

export type GetTutorialsViewAllPageQueryVariables = Exact<{
  language: Scalars['String'];
  filters?: Maybe<TutorialsFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetTutorialsViewAllPageQuery = { __typename?: 'Query', tutorialsCount?: Maybe<number>, tutorials: Array<{ __typename?: 'Tutorial', id: string, translation: { __typename?: 'TutorialTranslation', description: string } }> };

export type GetTutorialByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTutorialByIdQuery = { __typename?: 'Query', tutorial: { __typename?: 'Tutorial', id: string, translations: Array<{ __typename?: 'TutorialTranslation', language: string, description: string }>, apps: Array<Maybe<{ __typename?: 'App', id: string }>>, instructions: Array<{ __typename?: 'Instruction', stepNumber: number, stepName: string, target: string, style: InstructionStyle, clickEnabled: boolean, translations: Array<{ __typename?: 'TutorialTranslation', language: string, description: string }> }> } };

export type UserExistsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserExistsQuery = { __typename?: 'Query', userExists: boolean };

export type UserTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTypesQuery = { __typename?: 'Query', userTypes: Array<UserType> };

export type GetBusinessProfilesForCreateUserQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetBusinessProfilesForCreateUserQuery = { __typename?: 'Query', businessProfiles: Array<{ __typename?: 'BusinessProfile', id: string, translation: { __typename?: 'BusinessProfileTranslation', description: string } }> };

export type CreateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: string };

export type RequestPermissionUploadUserImageToS3MutationVariables = Exact<{ [key: string]: never; }>;


export type RequestPermissionUploadUserImageToS3Mutation = { __typename?: 'Mutation', requestPermissionUploadUserImageToS3: { __typename?: 'SignatureUploadFileS3', url: string, bucket: string, key: string } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  user: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: Maybe<any> };

export type UpdatePasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  password: Scalars['String'];
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword?: Maybe<any> };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: Maybe<any> };

export type LockUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  lock: Scalars['Boolean'];
}>;


export type LockUserMutation = { __typename?: 'Mutation', lockUser?: Maybe<any> };

export type GetUsersForTablePageQueryVariables = Exact<{
  filters?: Maybe<UsersFilters>;
  pagination?: Maybe<Pagination>;
}>;


export type GetUsersForTablePageQuery = { __typename?: 'Query', usersCount: number, users: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, userType: UserType, isLocked: boolean, businessProfile: { __typename?: 'BusinessProfile', id: string } }> };

export type GetUserForViewPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserForViewPageQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, phoneNumber: string, userType: UserType, decimalSeparator: UserDecimalSeparator, numberDecimalsPlaces: number, isLocked: boolean, dateFormat: UserDateFormat, timeFormat: UserTimeFormat, language: { __typename?: 'Language', id: string }, image: { __typename?: 'S3File', name: string, url: any }, businessProfile: { __typename?: 'BusinessProfile', id: string } }> };

export type GetMyInformationQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetMyInformationQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, themeId?: Maybe<string>, isPasswordUpdated: boolean, timeFormat: UserTimeFormat, dateFormat: UserDateFormat, decimalSeparator: UserDecimalSeparator, numberDecimalsPlaces: number, image: { __typename?: 'S3File', name: string, url: any }, language: { __typename?: 'Language', id: string }, effectiveTheme: { __typename?: 'Theme', id: string, primaryColor: string, primaryColorContrast: string, userColor: string, appsColor: string, appsTitleColor: string, stepperColor1: string, stepperColor2: string, stepperColor3: string, stepperTextColor: string, stepperInternalColor: string, backgroundImage: { __typename?: 'S3File', name: string, url: any }, companyLogo?: Maybe<{ __typename?: 'S3File', name: string, url: any }> }, businessProfile: { __typename?: 'BusinessProfile', id: string, translation: { __typename?: 'BusinessProfileTranslation', description: string }, appsActions: Array<{ __typename?: 'AppAction', id: string, translation: { __typename?: 'AppActionTranslation', description: string }, app: { __typename?: 'App', id: string } }>, groups: Array<{ __typename?: 'Group', id: string, icon: { __typename?: 'S3File', url: any }, translation: { __typename?: 'GroupTranslation', description: string }, tiles: Array<{ __typename?: 'Tile', id: string, path: string, shape: TileShape, icon?: Maybe<{ __typename?: 'S3File', url: any }>, kpi?: Maybe<{ __typename?: 'Kpi', value?: Maybe<string> }>, translation: { __typename?: 'TileTranslation', title: string, subtitle: string } }> }> } } };

export type UpdateMyImageMutationVariables = Exact<{
  imageId: Scalars['String'];
}>;


export type UpdateMyImageMutation = { __typename?: 'Mutation', updateMyImage?: Maybe<any> };

export type GetMyProfileImageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProfileImageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, image: { __typename?: 'S3File', name: string, url: any } } };

export type UpdateMyPasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type UpdateMyPasswordMutation = { __typename?: 'Mutation', updateMyPassword?: Maybe<any> };

export type GetAllThemesQueryVariables = Exact<{
  filters: ThemesFilters;
  pagination: Pagination;
  language: Scalars['String'];
}>;


export type GetAllThemesQuery = { __typename?: 'Query', themesCount: number, themes: Array<{ __typename?: 'Theme', id: string, isDefault: boolean, primaryColor: string, primaryColorContrast: string, userColor: string, appsColor: string, appsTitleColor: string, stepperColor1: string, stepperColor2: string, stepperColor3: string, stepperTextColor: string, stepperInternalColor: string, backgroundImage: { __typename?: 'S3File', name: string, url: any }, translation: { __typename?: 'ThemeTranslation', description: string } }> };

export type UpdateMyProfileMutationVariables = Exact<{
  userProfile: UpdatableUserProfileInput;
}>;


export type UpdateMyProfileMutation = { __typename?: 'Mutation', updateMyProfile?: Maybe<any> };

export type GetAppActionsQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Scalars['String'];
}>;


export type GetAppActionsQuery = { __typename?: 'Query', apps: Array<{ __typename?: 'App', actions: Array<{ __typename?: 'AppAction', id: string, translation: { __typename?: 'AppActionTranslation', description: string } }> }> };

export type GetAppMessagesQueryVariables = Exact<{
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type GetAppMessagesQuery = { __typename?: 'Query', app?: Maybe<{ __typename?: 'App', messages: Array<{ __typename?: 'AppMessage', id: string, translation: { __typename?: 'AppMessageTranslation', language: string, description: string } }> }> };

export type GetAppSectionsQueryVariables = Exact<{
  language: Scalars['String'];
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetAppSectionsQuery = { __typename?: 'Query', apps: Array<{ __typename?: 'App', id: string, sections: Array<{ __typename?: 'AppSection', id: string, translation: { __typename?: 'AppSectionTranslation', language: string, step: string, title: string, subtitle: string } }> }> };

export type GetDataElementsChoicesQueryVariables = Exact<{
  language: Scalars['String'];
  dataElementIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetDataElementsChoicesQuery = { __typename?: 'Query', dataElements: Array<{ __typename?: 'DataElement', id: string, choices?: Maybe<Array<{ __typename?: 'DataElementChoice', id: string, translation: { __typename?: 'DataElementChoiceTranslation', language: string, description: string } }>> }> };

export type GetDataElementDescriptionQueryVariables = Exact<{
  language: Scalars['String'];
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetDataElementDescriptionQuery = { __typename?: 'Query', dataElements: Array<{ __typename?: 'DataElement', id: string, translation: { __typename?: 'DataElementTranslation', language: string, description: string } }> };

export type GetLanguagesOptionsQueryVariables = Exact<{
  language: Scalars['String'];
}>;


export type GetLanguagesOptionsQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'Language', id: string, translation: { __typename?: 'LanguageTranslation', language: string, description: string } }> };

export type GetMyLanguageInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyLanguageInformationQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, language: { __typename?: 'Language', id: string } } };

export type GetTutorialsQueryVariables = Exact<{
  filters?: Maybe<TutorialsFilters>;
  language: Scalars['String'];
}>;


export type GetTutorialsQuery = { __typename?: 'Query', tutorials: Array<{ __typename?: 'Tutorial', id: string, translation: { __typename?: 'TutorialTranslation', language: string, description: string }, instructions: Array<{ __typename?: 'Instruction', stepNumber: number, stepName: string, target: string, style: InstructionStyle, clickEnabled: boolean, translation: { __typename?: 'TutorialTranslation', language: string, description: string } }> }> };

export type GetAmIAuthenticatedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAmIAuthenticatedQuery = { __typename?: 'Query', amIAuthenticated: boolean };

export type GetMyPasswordUpdatedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPasswordUpdatedQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, isPasswordUpdated: boolean } };


export const RegisterFileUploadedToS3Document = `
    mutation RegisterFileUploadedToS3($s3File: FileUploadedToS3Input!) {
  registerFileUploadedToS3(s3File: $s3File) {
    fileId
  }
}
    `;
export const useRegisterFileUploadedToS3Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RegisterFileUploadedToS3Mutation, TError, RegisterFileUploadedToS3MutationVariables, TContext>) => 
    useMutation<RegisterFileUploadedToS3Mutation, TError, RegisterFileUploadedToS3MutationVariables, TContext>(
      (variables?: RegisterFileUploadedToS3MutationVariables) => fetcher<RegisterFileUploadedToS3Mutation, RegisterFileUploadedToS3MutationVariables>(RegisterFileUploadedToS3Document, variables)(),
      options
    );
export const AccountingEntityExistsDocument = `
    query AccountingEntityExists($id: ID!) @app(id: ACCOUNTING_ENTITIES) {
  accountingEntityExists(id: $id)
}
    `;
export const useAccountingEntityExistsQuery = <
      TData = AccountingEntityExistsQuery,
      TError = unknown
    >(
      variables: AccountingEntityExistsQueryVariables, 
      options?: UseQueryOptions<AccountingEntityExistsQuery, TError, TData>
    ) => 
    useQuery<AccountingEntityExistsQuery, TError, TData>(
      ['AccountingEntityExists', variables],
      fetcher<AccountingEntityExistsQuery, AccountingEntityExistsQueryVariables>(AccountingEntityExistsDocument, variables),
      options
    );
export const GetCurrenciesForAccountingEntitiesFormPageDocument = `
    query GetCurrenciesForAccountingEntitiesFormPage($language: String!, $filters: CurrencyFilters, $pagination: Pagination) @app(id: ACCOUNTING_ENTITIES) {
  currencies(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetCurrenciesForAccountingEntitiesFormPageQuery = <
      TData = GetCurrenciesForAccountingEntitiesFormPageQuery,
      TError = unknown
    >(
      variables: GetCurrenciesForAccountingEntitiesFormPageQueryVariables, 
      options?: UseQueryOptions<GetCurrenciesForAccountingEntitiesFormPageQuery, TError, TData>
    ) => 
    useQuery<GetCurrenciesForAccountingEntitiesFormPageQuery, TError, TData>(
      ['GetCurrenciesForAccountingEntitiesFormPage', variables],
      fetcher<GetCurrenciesForAccountingEntitiesFormPageQuery, GetCurrenciesForAccountingEntitiesFormPageQueryVariables>(GetCurrenciesForAccountingEntitiesFormPageDocument, variables),
      options
    );
export const GetCompaniesForAccountingEntitiesFormPageDocument = `
    query GetCompaniesForAccountingEntitiesFormPage($filters: CompanyFilters, $pagination: Pagination) @app(id: ACCOUNTING_ENTITIES) {
  companies(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
}
    `;
export const useGetCompaniesForAccountingEntitiesFormPageQuery = <
      TData = GetCompaniesForAccountingEntitiesFormPageQuery,
      TError = unknown
    >(
      variables?: GetCompaniesForAccountingEntitiesFormPageQueryVariables, 
      options?: UseQueryOptions<GetCompaniesForAccountingEntitiesFormPageQuery, TError, TData>
    ) => 
    useQuery<GetCompaniesForAccountingEntitiesFormPageQuery, TError, TData>(
      ['GetCompaniesForAccountingEntitiesFormPage', variables],
      fetcher<GetCompaniesForAccountingEntitiesFormPageQuery, GetCompaniesForAccountingEntitiesFormPageQueryVariables>(GetCompaniesForAccountingEntitiesFormPageDocument, variables),
      options
    );
export const CreateAccountingEntityDocument = `
    mutation CreateAccountingEntity($accountingEntity: AccountingEntityInput!) {
  createAccountingEntity(accountingEntity: $accountingEntity)
}
    `;
export const useCreateAccountingEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAccountingEntityMutation, TError, CreateAccountingEntityMutationVariables, TContext>) => 
    useMutation<CreateAccountingEntityMutation, TError, CreateAccountingEntityMutationVariables, TContext>(
      (variables?: CreateAccountingEntityMutationVariables) => fetcher<CreateAccountingEntityMutation, CreateAccountingEntityMutationVariables>(CreateAccountingEntityDocument, variables)(),
      options
    );
export const UpdateAccountingEntityDocument = `
    mutation UpdateAccountingEntity($id: ID!, $accountingEntity: AccountingEntityInput!) {
  updateAccountingEntity(id: $id, accountingEntity: $accountingEntity)
}
    `;
export const useUpdateAccountingEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateAccountingEntityMutation, TError, UpdateAccountingEntityMutationVariables, TContext>) => 
    useMutation<UpdateAccountingEntityMutation, TError, UpdateAccountingEntityMutationVariables, TContext>(
      (variables?: UpdateAccountingEntityMutationVariables) => fetcher<UpdateAccountingEntityMutation, UpdateAccountingEntityMutationVariables>(UpdateAccountingEntityDocument, variables)(),
      options
    );
export const DeletAccountingEntityDocument = `
    mutation DeletAccountingEntity($id: ID!) {
  deleteAccountingEntity(id: $id)
}
    `;
export const useDeletAccountingEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletAccountingEntityMutation, TError, DeletAccountingEntityMutationVariables, TContext>) => 
    useMutation<DeletAccountingEntityMutation, TError, DeletAccountingEntityMutationVariables, TContext>(
      (variables?: DeletAccountingEntityMutationVariables) => fetcher<DeletAccountingEntityMutation, DeletAccountingEntityMutationVariables>(DeletAccountingEntityDocument, variables)(),
      options
    );
export const GetAccountingEntities_ViewAllPageDocument = `
    query GetAccountingEntities_ViewAllPage($filters: AccountingEntityFilters, $pagination: Pagination) @app(id: ACCOUNTING_ENTITIES) {
  accountingEntities(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    address {
      street
      number
      floor
      department
      city
      postalCode
      state
      country
    }
  }
  accountingEntitiesCount(filters: $filters)
}
    `;
export const useGetAccountingEntities_ViewAllPageQuery = <
      TData = GetAccountingEntities_ViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetAccountingEntities_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetAccountingEntities_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetAccountingEntities_ViewAllPageQuery, TError, TData>(
      ['GetAccountingEntities_ViewAllPage', variables],
      fetcher<GetAccountingEntities_ViewAllPageQuery, GetAccountingEntities_ViewAllPageQueryVariables>(GetAccountingEntities_ViewAllPageDocument, variables),
      options
    );
export const GetAccountingEntity_ViewPageDocument = `
    query GetAccountingEntity_ViewPage($id: ID!) @app(id: ACCOUNTING_ENTITIES) {
  accountingEntity(id: $id) {
    id
    name
    currency {
      id
    }
    company {
      id
    }
    address {
      street
      number
      floor
      department
      city
      postalCode
      state
      country
    }
  }
}
    `;
export const useGetAccountingEntity_ViewPageQuery = <
      TData = GetAccountingEntity_ViewPageQuery,
      TError = unknown
    >(
      variables: GetAccountingEntity_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetAccountingEntity_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetAccountingEntity_ViewPageQuery, TError, TData>(
      ['GetAccountingEntity_ViewPage', variables],
      fetcher<GetAccountingEntity_ViewPageQuery, GetAccountingEntity_ViewPageQueryVariables>(GetAccountingEntity_ViewPageDocument, variables),
      options
    );
export const GetBusinessObjectsForCreateAppDocument = `
    query GetBusinessObjectsForCreateApp($language: String!) @app(id: APPS) {
  businessObjects {
    dataElement {
      id
      translation(language: $language) {
        description
      }
    }
  }
}
    `;
export const useGetBusinessObjectsForCreateAppQuery = <
      TData = GetBusinessObjectsForCreateAppQuery,
      TError = unknown
    >(
      variables: GetBusinessObjectsForCreateAppQueryVariables, 
      options?: UseQueryOptions<GetBusinessObjectsForCreateAppQuery, TError, TData>
    ) => 
    useQuery<GetBusinessObjectsForCreateAppQuery, TError, TData>(
      ['GetBusinessObjectsForCreateApp', variables],
      fetcher<GetBusinessObjectsForCreateAppQuery, GetBusinessObjectsForCreateAppQueryVariables>(GetBusinessObjectsForCreateAppDocument, variables),
      options
    );
export const AppExistsDocument = `
    query AppExists($appId: ID!) @app(id: APPS) {
  appExists(id: $appId)
}
    `;
export const useAppExistsQuery = <
      TData = AppExistsQuery,
      TError = unknown
    >(
      variables: AppExistsQueryVariables, 
      options?: UseQueryOptions<AppExistsQuery, TError, TData>
    ) => 
    useQuery<AppExistsQuery, TError, TData>(
      ['AppExists', variables],
      fetcher<AppExistsQuery, AppExistsQueryVariables>(AppExistsDocument, variables),
      options
    );
export const CreateAppDocument = `
    mutation CreateApp($app: AppInput!) {
  createApp(app: $app)
}
    `;
export const useCreateAppMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAppMutation, TError, CreateAppMutationVariables, TContext>) => 
    useMutation<CreateAppMutation, TError, CreateAppMutationVariables, TContext>(
      (variables?: CreateAppMutationVariables) => fetcher<CreateAppMutation, CreateAppMutationVariables>(CreateAppDocument, variables)(),
      options
    );
export const UpdateAppDocument = `
    mutation UpdateApp($id: ID!, $app: AppInput!) {
  updateApp(id: $id, app: $app)
}
    `;
export const useUpdateAppMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateAppMutation, TError, UpdateAppMutationVariables, TContext>) => 
    useMutation<UpdateAppMutation, TError, UpdateAppMutationVariables, TContext>(
      (variables?: UpdateAppMutationVariables) => fetcher<UpdateAppMutation, UpdateAppMutationVariables>(UpdateAppDocument, variables)(),
      options
    );
export const DeleteAppDocument = `
    mutation DeleteApp($id: ID!) {
  deleteApp(id: $id)
}
    `;
export const useDeleteAppMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAppMutation, TError, DeleteAppMutationVariables, TContext>) => 
    useMutation<DeleteAppMutation, TError, DeleteAppMutationVariables, TContext>(
      (variables?: DeleteAppMutationVariables) => fetcher<DeleteAppMutation, DeleteAppMutationVariables>(DeleteAppDocument, variables)(),
      options
    );
export const GetAppsTableDocument = `
    query GetAppsTable($language: String!, $filters: AppsFilters, $pagination: Pagination) @app(id: APPS) {
  apps(filters: $filters, pagination: $pagination) {
    id
    businessObject {
      dataElement {
        id
      }
    }
    translation(language: $language) {
      description
    }
  }
  appsCount(filters: $filters)
}
    `;
export const useGetAppsTableQuery = <
      TData = GetAppsTableQuery,
      TError = unknown
    >(
      variables: GetAppsTableQueryVariables, 
      options?: UseQueryOptions<GetAppsTableQuery, TError, TData>
    ) => 
    useQuery<GetAppsTableQuery, TError, TData>(
      ['GetAppsTable', variables],
      fetcher<GetAppsTableQuery, GetAppsTableQueryVariables>(GetAppsTableDocument, variables),
      options
    );
export const GetAppViewPageDocument = `
    query GetAppViewPage($id: ID!) @app(id: APPS) {
  app(id: $id) {
    id
    businessObject {
      dataElement {
        id
      }
    }
    translations {
      language
      description
    }
    sections {
      id
      translations {
        language
        step
        title
        subtitle
      }
    }
    actions {
      id
      translations {
        language
        description
      }
    }
    messages {
      id
      translations {
        language
        description
      }
    }
  }
}
    `;
export const useGetAppViewPageQuery = <
      TData = GetAppViewPageQuery,
      TError = unknown
    >(
      variables: GetAppViewPageQueryVariables, 
      options?: UseQueryOptions<GetAppViewPageQuery, TError, TData>
    ) => 
    useQuery<GetAppViewPageQuery, TError, TData>(
      ['GetAppViewPage', variables],
      fetcher<GetAppViewPageQuery, GetAppViewPageQueryVariables>(GetAppViewPageDocument, variables),
      options
    );
export const BackendExistsDocument = `
    query BackendExists($id: ID!) @app(id: BACKENDS) {
  backendExists(id: $id)
}
    `;
export const useBackendExistsQuery = <
      TData = BackendExistsQuery,
      TError = unknown
    >(
      variables: BackendExistsQueryVariables, 
      options?: UseQueryOptions<BackendExistsQuery, TError, TData>
    ) => 
    useQuery<BackendExistsQuery, TError, TData>(
      ['BackendExists', variables],
      fetcher<BackendExistsQuery, BackendExistsQueryVariables>(BackendExistsDocument, variables),
      options
    );
export const CreateBackendDocument = `
    mutation CreateBackend($backend: BackendInput!) {
  createBackend(backend: $backend)
}
    `;
export const useCreateBackendMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBackendMutation, TError, CreateBackendMutationVariables, TContext>) => 
    useMutation<CreateBackendMutation, TError, CreateBackendMutationVariables, TContext>(
      (variables?: CreateBackendMutationVariables) => fetcher<CreateBackendMutation, CreateBackendMutationVariables>(CreateBackendDocument, variables)(),
      options
    );
export const GenerateBackendPasswordDocument = `
    mutation GenerateBackendPassword($id: ID!) {
  generateBackendRandomPassword(id: $id)
}
    `;
export const useGenerateBackendPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GenerateBackendPasswordMutation, TError, GenerateBackendPasswordMutationVariables, TContext>) => 
    useMutation<GenerateBackendPasswordMutation, TError, GenerateBackendPasswordMutationVariables, TContext>(
      (variables?: GenerateBackendPasswordMutationVariables) => fetcher<GenerateBackendPasswordMutation, GenerateBackendPasswordMutationVariables>(GenerateBackendPasswordDocument, variables)(),
      options
    );
export const UpdateBackendDocument = `
    mutation UpdateBackend($id: ID!, $backend: BackendInput!) {
  updateBackend(id: $id, backend: $backend)
}
    `;
export const useUpdateBackendMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBackendMutation, TError, UpdateBackendMutationVariables, TContext>) => 
    useMutation<UpdateBackendMutation, TError, UpdateBackendMutationVariables, TContext>(
      (variables?: UpdateBackendMutationVariables) => fetcher<UpdateBackendMutation, UpdateBackendMutationVariables>(UpdateBackendDocument, variables)(),
      options
    );
export const DeleteBackendDocument = `
    mutation DeleteBackend($id: ID!) {
  deleteBackend(id: $id)
}
    `;
export const useDeleteBackendMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBackendMutation, TError, DeleteBackendMutationVariables, TContext>) => 
    useMutation<DeleteBackendMutation, TError, DeleteBackendMutationVariables, TContext>(
      (variables?: DeleteBackendMutationVariables) => fetcher<DeleteBackendMutation, DeleteBackendMutationVariables>(DeleteBackendDocument, variables)(),
      options
    );
export const GetBackends_ViewAllPageDocument = `
    query GetBackends_ViewAllPage($filters: BackendsFilters, $pagination: Pagination) @app(id: BACKENDS) {
  backends(filters: $filters, pagination: $pagination) {
    id
    url
  }
  backendsCount(filters: $filters)
}
    `;
export const useGetBackends_ViewAllPageQuery = <
      TData = GetBackends_ViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetBackends_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetBackends_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetBackends_ViewAllPageQuery, TError, TData>(
      ['GetBackends_ViewAllPage', variables],
      fetcher<GetBackends_ViewAllPageQuery, GetBackends_ViewAllPageQueryVariables>(GetBackends_ViewAllPageDocument, variables),
      options
    );
export const GetBackend_ViewPageDocument = `
    query GetBackend_ViewPage($id: ID!) @app(id: BACKENDS) {
  backend(id: $id) {
    id
    url
    ip
    domain
  }
}
    `;
export const useGetBackend_ViewPageQuery = <
      TData = GetBackend_ViewPageQuery,
      TError = unknown
    >(
      variables: GetBackend_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetBackend_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBackend_ViewPageQuery, TError, TData>(
      ['GetBackend_ViewPage', variables],
      fetcher<GetBackend_ViewPageQuery, GetBackend_ViewPageQueryVariables>(GetBackend_ViewPageDocument, variables),
      options
    );
export const BusinessGroupsExistsDocument = `
    query BusinessGroupsExists($id: ID!) @app(id: BUSINESS_GROUPS) {
  businessGroupsExists(id: $id)
}
    `;
export const useBusinessGroupsExistsQuery = <
      TData = BusinessGroupsExistsQuery,
      TError = unknown
    >(
      variables: BusinessGroupsExistsQueryVariables, 
      options?: UseQueryOptions<BusinessGroupsExistsQuery, TError, TData>
    ) => 
    useQuery<BusinessGroupsExistsQuery, TError, TData>(
      ['BusinessGroupsExists', variables],
      fetcher<BusinessGroupsExistsQuery, BusinessGroupsExistsQueryVariables>(BusinessGroupsExistsDocument, variables),
      options
    );
export const GetCurrenciesForBusinessGroupsFormPageDocument = `
    query GetCurrenciesForBusinessGroupsFormPage($language: String!, $filters: CurrencyFilters, $pagination: Pagination) @app(id: BUSINESS_GROUPS) {
  currencies(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetCurrenciesForBusinessGroupsFormPageQuery = <
      TData = GetCurrenciesForBusinessGroupsFormPageQuery,
      TError = unknown
    >(
      variables: GetCurrenciesForBusinessGroupsFormPageQueryVariables, 
      options?: UseQueryOptions<GetCurrenciesForBusinessGroupsFormPageQuery, TError, TData>
    ) => 
    useQuery<GetCurrenciesForBusinessGroupsFormPageQuery, TError, TData>(
      ['GetCurrenciesForBusinessGroupsFormPage', variables],
      fetcher<GetCurrenciesForBusinessGroupsFormPageQuery, GetCurrenciesForBusinessGroupsFormPageQueryVariables>(GetCurrenciesForBusinessGroupsFormPageDocument, variables),
      options
    );
export const GetChartOfAccountsForBusinessGroupsFormPageDocument = `
    query GetChartOfAccountsForBusinessGroupsFormPage($language: String!, $filters: ChartOfAccountFilters, $pagination: Pagination) @app(id: BUSINESS_GROUPS) {
  chartOfAccounts(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetChartOfAccountsForBusinessGroupsFormPageQuery = <
      TData = GetChartOfAccountsForBusinessGroupsFormPageQuery,
      TError = unknown
    >(
      variables: GetChartOfAccountsForBusinessGroupsFormPageQueryVariables, 
      options?: UseQueryOptions<GetChartOfAccountsForBusinessGroupsFormPageQuery, TError, TData>
    ) => 
    useQuery<GetChartOfAccountsForBusinessGroupsFormPageQuery, TError, TData>(
      ['GetChartOfAccountsForBusinessGroupsFormPage', variables],
      fetcher<GetChartOfAccountsForBusinessGroupsFormPageQuery, GetChartOfAccountsForBusinessGroupsFormPageQueryVariables>(GetChartOfAccountsForBusinessGroupsFormPageDocument, variables),
      options
    );
export const CreateBusinessGroupDocument = `
    mutation CreateBusinessGroup($businessGroup: BusinessGroupInput!) {
  createBusinessGroup(businessGroup: $businessGroup)
}
    `;
export const useCreateBusinessGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBusinessGroupMutation, TError, CreateBusinessGroupMutationVariables, TContext>) => 
    useMutation<CreateBusinessGroupMutation, TError, CreateBusinessGroupMutationVariables, TContext>(
      (variables?: CreateBusinessGroupMutationVariables) => fetcher<CreateBusinessGroupMutation, CreateBusinessGroupMutationVariables>(CreateBusinessGroupDocument, variables)(),
      options
    );
export const UpdateBusinessGroupDocument = `
    mutation UpdateBusinessGroup($id: ID!, $businessGroup: BusinessGroupInput!) {
  updateBusinessGroup(id: $id, businessGroup: $businessGroup)
}
    `;
export const useUpdateBusinessGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBusinessGroupMutation, TError, UpdateBusinessGroupMutationVariables, TContext>) => 
    useMutation<UpdateBusinessGroupMutation, TError, UpdateBusinessGroupMutationVariables, TContext>(
      (variables?: UpdateBusinessGroupMutationVariables) => fetcher<UpdateBusinessGroupMutation, UpdateBusinessGroupMutationVariables>(UpdateBusinessGroupDocument, variables)(),
      options
    );
export const DeletBusinessGroupDocument = `
    mutation DeletBusinessGroup($id: ID!) {
  deleteBusinessGroup(id: $id)
}
    `;
export const useDeletBusinessGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletBusinessGroupMutation, TError, DeletBusinessGroupMutationVariables, TContext>) => 
    useMutation<DeletBusinessGroupMutation, TError, DeletBusinessGroupMutationVariables, TContext>(
      (variables?: DeletBusinessGroupMutationVariables) => fetcher<DeletBusinessGroupMutation, DeletBusinessGroupMutationVariables>(DeletBusinessGroupDocument, variables)(),
      options
    );
export const GetBusinessGroups_ViewAllPageDocument = `
    query GetBusinessGroups_ViewAllPage($filters: BusinessGroupFilters, $pagination: Pagination) @app(id: BUSINESS_GROUPS) {
  businessGroups(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
  businessGroupsCount(filters: $filters)
}
    `;
export const useGetBusinessGroups_ViewAllPageQuery = <
      TData = GetBusinessGroups_ViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetBusinessGroups_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessGroups_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessGroups_ViewAllPageQuery, TError, TData>(
      ['GetBusinessGroups_ViewAllPage', variables],
      fetcher<GetBusinessGroups_ViewAllPageQuery, GetBusinessGroups_ViewAllPageQueryVariables>(GetBusinessGroups_ViewAllPageDocument, variables),
      options
    );
export const GetBusinessGroup_ViewPageDocument = `
    query GetBusinessGroup_ViewPage($id: ID!) @app(id: BUSINESS_GROUPS) {
  businessGroup(id: $id) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
}
    `;
export const useGetBusinessGroup_ViewPageQuery = <
      TData = GetBusinessGroup_ViewPageQuery,
      TError = unknown
    >(
      variables: GetBusinessGroup_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessGroup_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessGroup_ViewPageQuery, TError, TData>(
      ['GetBusinessGroup_ViewPage', variables],
      fetcher<GetBusinessGroup_ViewPageQuery, GetBusinessGroup_ViewPageQueryVariables>(GetBusinessGroup_ViewPageDocument, variables),
      options
    );
export const GetDataElementsForCreateBusinessObjectPageDocument = `
    query GetDataElementsForCreateBusinessObjectPage($language: String!) @app(id: BUSINESS_OBJECTS) {
  dataElements {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetDataElementsForCreateBusinessObjectPageQuery = <
      TData = GetDataElementsForCreateBusinessObjectPageQuery,
      TError = unknown
    >(
      variables: GetDataElementsForCreateBusinessObjectPageQueryVariables, 
      options?: UseQueryOptions<GetDataElementsForCreateBusinessObjectPageQuery, TError, TData>
    ) => 
    useQuery<GetDataElementsForCreateBusinessObjectPageQuery, TError, TData>(
      ['GetDataElementsForCreateBusinessObjectPage', variables],
      fetcher<GetDataElementsForCreateBusinessObjectPageQuery, GetDataElementsForCreateBusinessObjectPageQueryVariables>(GetDataElementsForCreateBusinessObjectPageDocument, variables),
      options
    );
export const GetDataElementByIdForCreateBusinessObjectPageDocument = `
    query GetDataElementByIdForCreateBusinessObjectPage($id: ID!) @app(id: BUSINESS_OBJECTS) {
  dataElement(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetDataElementByIdForCreateBusinessObjectPageQuery = <
      TData = GetDataElementByIdForCreateBusinessObjectPageQuery,
      TError = unknown
    >(
      variables: GetDataElementByIdForCreateBusinessObjectPageQueryVariables, 
      options?: UseQueryOptions<GetDataElementByIdForCreateBusinessObjectPageQuery, TError, TData>
    ) => 
    useQuery<GetDataElementByIdForCreateBusinessObjectPageQuery, TError, TData>(
      ['GetDataElementByIdForCreateBusinessObjectPage', variables],
      fetcher<GetDataElementByIdForCreateBusinessObjectPageQuery, GetDataElementByIdForCreateBusinessObjectPageQueryVariables>(GetDataElementByIdForCreateBusinessObjectPageDocument, variables),
      options
    );
export const GetTablesForCreateBusinessObjectDocument = `
    query GetTablesForCreateBusinessObject($language: String!) @app(id: BUSINESS_OBJECTS) {
  tables {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetTablesForCreateBusinessObjectQuery = <
      TData = GetTablesForCreateBusinessObjectQuery,
      TError = unknown
    >(
      variables: GetTablesForCreateBusinessObjectQueryVariables, 
      options?: UseQueryOptions<GetTablesForCreateBusinessObjectQuery, TError, TData>
    ) => 
    useQuery<GetTablesForCreateBusinessObjectQuery, TError, TData>(
      ['GetTablesForCreateBusinessObject', variables],
      fetcher<GetTablesForCreateBusinessObjectQuery, GetTablesForCreateBusinessObjectQueryVariables>(GetTablesForCreateBusinessObjectDocument, variables),
      options
    );
export const CreateBusinessObjectDocument = `
    mutation CreateBusinessObject($businessObject: BusinessObjectInput!) {
  createBusinessObject(businessObject: $businessObject)
}
    `;
export const useCreateBusinessObjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBusinessObjectMutation, TError, CreateBusinessObjectMutationVariables, TContext>) => 
    useMutation<CreateBusinessObjectMutation, TError, CreateBusinessObjectMutationVariables, TContext>(
      (variables?: CreateBusinessObjectMutationVariables) => fetcher<CreateBusinessObjectMutation, CreateBusinessObjectMutationVariables>(CreateBusinessObjectDocument, variables)(),
      options
    );
export const UpdateBusinessObjectDocument = `
    mutation UpdateBusinessObject($id: ID!, $businessObject: BusinessObjectInput!) {
  updateBusinessObject(id: $id, businessObject: $businessObject)
}
    `;
export const useUpdateBusinessObjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBusinessObjectMutation, TError, UpdateBusinessObjectMutationVariables, TContext>) => 
    useMutation<UpdateBusinessObjectMutation, TError, UpdateBusinessObjectMutationVariables, TContext>(
      (variables?: UpdateBusinessObjectMutationVariables) => fetcher<UpdateBusinessObjectMutation, UpdateBusinessObjectMutationVariables>(UpdateBusinessObjectDocument, variables)(),
      options
    );
export const DeleteBusinessObjectDocument = `
    mutation DeleteBusinessObject($id: ID!) {
  deleteBusinessObject(id: $id)
}
    `;
export const useDeleteBusinessObjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBusinessObjectMutation, TError, DeleteBusinessObjectMutationVariables, TContext>) => 
    useMutation<DeleteBusinessObjectMutation, TError, DeleteBusinessObjectMutationVariables, TContext>(
      (variables?: DeleteBusinessObjectMutationVariables) => fetcher<DeleteBusinessObjectMutation, DeleteBusinessObjectMutationVariables>(DeleteBusinessObjectDocument, variables)(),
      options
    );
export const GetBusinessObjectsForViewPageDocument = `
    query GetBusinessObjectsForViewPage($language: String!, $filters: BusinessObjectsFilters, $pagination: Pagination) @app(id: BUSINESS_OBJECTS) {
  businessObjects(filters: $filters, pagination: $pagination) {
    dataElement {
      id
      translation(language: $language) {
        description
      }
    }
    status
  }
  businessObjectsCount(filters: $filters)
}
    `;
export const useGetBusinessObjectsForViewPageQuery = <
      TData = GetBusinessObjectsForViewPageQuery,
      TError = unknown
    >(
      variables: GetBusinessObjectsForViewPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessObjectsForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessObjectsForViewPageQuery, TError, TData>(
      ['GetBusinessObjectsForViewPage', variables],
      fetcher<GetBusinessObjectsForViewPageQuery, GetBusinessObjectsForViewPageQueryVariables>(GetBusinessObjectsForViewPageDocument, variables),
      options
    );
export const GetBusinessObjectForViewPageDocument = `
    query GetBusinessObjectForViewPage($id: ID!) @app(id: BUSINESS_OBJECTS) {
  businessObject(id: $id) {
    dataElement {
      id
    }
    status
    tables {
      isHeaderTable
      table {
        id
      }
    }
  }
}
    `;
export const useGetBusinessObjectForViewPageQuery = <
      TData = GetBusinessObjectForViewPageQuery,
      TError = unknown
    >(
      variables: GetBusinessObjectForViewPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessObjectForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessObjectForViewPageQuery, TError, TData>(
      ['GetBusinessObjectForViewPage', variables],
      fetcher<GetBusinessObjectForViewPageQuery, GetBusinessObjectForViewPageQueryVariables>(GetBusinessObjectForViewPageDocument, variables),
      options
    );
export const BusinessPartnerTypeExistsDocument = `
    query BusinessPartnerTypeExists($id: ID!) @app(id: BUSINESS_PARTNER_TYPES) {
  businessPartnerTypeExists(id: $id)
}
    `;
export const useBusinessPartnerTypeExistsQuery = <
      TData = BusinessPartnerTypeExistsQuery,
      TError = unknown
    >(
      variables: BusinessPartnerTypeExistsQueryVariables, 
      options?: UseQueryOptions<BusinessPartnerTypeExistsQuery, TError, TData>
    ) => 
    useQuery<BusinessPartnerTypeExistsQuery, TError, TData>(
      ['BusinessPartnerTypeExists', variables],
      fetcher<BusinessPartnerTypeExistsQuery, BusinessPartnerTypeExistsQueryVariables>(BusinessPartnerTypeExistsDocument, variables),
      options
    );
export const CreateBusinessPartnerTypeDocument = `
    mutation CreateBusinessPartnerType($businessPartnerType: BusinessPartnerTypeInput!) {
  createBusinessPartnerType(businessPartnerType: $businessPartnerType)
}
    `;
export const useCreateBusinessPartnerTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBusinessPartnerTypeMutation, TError, CreateBusinessPartnerTypeMutationVariables, TContext>) => 
    useMutation<CreateBusinessPartnerTypeMutation, TError, CreateBusinessPartnerTypeMutationVariables, TContext>(
      (variables?: CreateBusinessPartnerTypeMutationVariables) => fetcher<CreateBusinessPartnerTypeMutation, CreateBusinessPartnerTypeMutationVariables>(CreateBusinessPartnerTypeDocument, variables)(),
      options
    );
export const UpdateBusinessPartnerTypeDocument = `
    mutation UpdateBusinessPartnerType($id: ID!, $businessPartnerType: BusinessPartnerTypeInput!) {
  updateBusinessPartnerType(id: $id, businessPartnerType: $businessPartnerType)
}
    `;
export const useUpdateBusinessPartnerTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBusinessPartnerTypeMutation, TError, UpdateBusinessPartnerTypeMutationVariables, TContext>) => 
    useMutation<UpdateBusinessPartnerTypeMutation, TError, UpdateBusinessPartnerTypeMutationVariables, TContext>(
      (variables?: UpdateBusinessPartnerTypeMutationVariables) => fetcher<UpdateBusinessPartnerTypeMutation, UpdateBusinessPartnerTypeMutationVariables>(UpdateBusinessPartnerTypeDocument, variables)(),
      options
    );
export const DeleteBusinessPartnerTypeDocument = `
    mutation DeleteBusinessPartnerType($id: ID!) {
  deleteBusinessPartnerType(id: $id)
}
    `;
export const useDeleteBusinessPartnerTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBusinessPartnerTypeMutation, TError, DeleteBusinessPartnerTypeMutationVariables, TContext>) => 
    useMutation<DeleteBusinessPartnerTypeMutation, TError, DeleteBusinessPartnerTypeMutationVariables, TContext>(
      (variables?: DeleteBusinessPartnerTypeMutationVariables) => fetcher<DeleteBusinessPartnerTypeMutation, DeleteBusinessPartnerTypeMutationVariables>(DeleteBusinessPartnerTypeDocument, variables)(),
      options
    );
export const GetBusinessPartnerTypes_ViewAllPageDocument = `
    query GetBusinessPartnerTypes_ViewAllPage($language: String!, $filters: BusinessPartnerTypeFilters, $pagination: Pagination) @app(id: BUSINESS_PARTNER_TYPES) {
  businessPartnerTypes(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  businessPartnerTypesCount(filters: $filters)
}
    `;
export const useGetBusinessPartnerTypes_ViewAllPageQuery = <
      TData = GetBusinessPartnerTypes_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetBusinessPartnerTypes_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessPartnerTypes_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessPartnerTypes_ViewAllPageQuery, TError, TData>(
      ['GetBusinessPartnerTypes_ViewAllPage', variables],
      fetcher<GetBusinessPartnerTypes_ViewAllPageQuery, GetBusinessPartnerTypes_ViewAllPageQueryVariables>(GetBusinessPartnerTypes_ViewAllPageDocument, variables),
      options
    );
export const GetBusinessPartnerType_ViewPageDocument = `
    query GetBusinessPartnerType_ViewPage($id: ID!) @app(id: BUSINESS_PARTNER_TYPES) {
  businessPartnerType(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetBusinessPartnerType_ViewPageQuery = <
      TData = GetBusinessPartnerType_ViewPageQuery,
      TError = unknown
    >(
      variables: GetBusinessPartnerType_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessPartnerType_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessPartnerType_ViewPageQuery, TError, TData>(
      ['GetBusinessPartnerType_ViewPage', variables],
      fetcher<GetBusinessPartnerType_ViewPageQuery, GetBusinessPartnerType_ViewPageQueryVariables>(GetBusinessPartnerType_ViewPageDocument, variables),
      options
    );
export const GetBusinessPartnersBackendSavedFormPageDocument = `
    query GetBusinessPartnersBackendSavedFormPage($filters: BusinessPartnersFilters) @app(id: BUSINESS_PARTNERS) {
  businessPartners(filters: $filters) {
    id
  }
}
    `;
export const useGetBusinessPartnersBackendSavedFormPageQuery = <
      TData = GetBusinessPartnersBackendSavedFormPageQuery,
      TError = unknown
    >(
      variables?: GetBusinessPartnersBackendSavedFormPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessPartnersBackendSavedFormPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessPartnersBackendSavedFormPageQuery, TError, TData>(
      ['GetBusinessPartnersBackendSavedFormPage', variables],
      fetcher<GetBusinessPartnersBackendSavedFormPageQuery, GetBusinessPartnersBackendSavedFormPageQueryVariables>(GetBusinessPartnersBackendSavedFormPageDocument, variables),
      options
    );
export const GetBackendsNamesForBusinessPartnersFormPageDocument = `
    query GetBackendsNamesForBusinessPartnersFormPage @app(id: BUSINESS_PARTNERS) {
  backends {
    id
    url
  }
  backendsCount
}
    `;
export const useGetBackendsNamesForBusinessPartnersFormPageQuery = <
      TData = GetBackendsNamesForBusinessPartnersFormPageQuery,
      TError = unknown
    >(
      variables?: GetBackendsNamesForBusinessPartnersFormPageQueryVariables, 
      options?: UseQueryOptions<GetBackendsNamesForBusinessPartnersFormPageQuery, TError, TData>
    ) => 
    useQuery<GetBackendsNamesForBusinessPartnersFormPageQuery, TError, TData>(
      ['GetBackendsNamesForBusinessPartnersFormPage', variables],
      fetcher<GetBackendsNamesForBusinessPartnersFormPageQuery, GetBackendsNamesForBusinessPartnersFormPageQueryVariables>(GetBackendsNamesForBusinessPartnersFormPageDocument, variables),
      options
    );
export const BusinessPartnerExistsDocument = `
    query BusinessPartnerExists($businessPartnerId: ID!) @app(id: BUSINESS_PARTNERS) {
  businessPartnerExists(id: $businessPartnerId)
}
    `;
export const useBusinessPartnerExistsQuery = <
      TData = BusinessPartnerExistsQuery,
      TError = unknown
    >(
      variables: BusinessPartnerExistsQueryVariables, 
      options?: UseQueryOptions<BusinessPartnerExistsQuery, TError, TData>
    ) => 
    useQuery<BusinessPartnerExistsQuery, TError, TData>(
      ['BusinessPartnerExists', variables],
      fetcher<BusinessPartnerExistsQuery, BusinessPartnerExistsQueryVariables>(BusinessPartnerExistsDocument, variables),
      options
    );
export const GetAllPositionsForBusinessPartnersFormDocument = `
    query GetAllPositionsForBusinessPartnersForm($language: String!, $filters: PositionFilters, $pagination: Pagination) @app(id: BUSINESS_PARTNERS) {
  positions(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      language
      description
    }
  }
}
    `;
export const useGetAllPositionsForBusinessPartnersFormQuery = <
      TData = GetAllPositionsForBusinessPartnersFormQuery,
      TError = unknown
    >(
      variables: GetAllPositionsForBusinessPartnersFormQueryVariables, 
      options?: UseQueryOptions<GetAllPositionsForBusinessPartnersFormQuery, TError, TData>
    ) => 
    useQuery<GetAllPositionsForBusinessPartnersFormQuery, TError, TData>(
      ['GetAllPositionsForBusinessPartnersForm', variables],
      fetcher<GetAllPositionsForBusinessPartnersFormQuery, GetAllPositionsForBusinessPartnersFormQueryVariables>(GetAllPositionsForBusinessPartnersFormDocument, variables),
      options
    );
export const GetUsersForBusinessPartnersFormDocument = `
    query GetUsersForBusinessPartnersForm($filters: UsersFilters, $pagination: Pagination) @app(id: BUSINESS_PARTNERS) {
  users(filters: $filters, pagination: $pagination) {
    id
    firstName
    lastName
    email
    userType
    businessProfile {
      id
    }
  }
}
    `;
export const useGetUsersForBusinessPartnersFormQuery = <
      TData = GetUsersForBusinessPartnersFormQuery,
      TError = unknown
    >(
      variables?: GetUsersForBusinessPartnersFormQueryVariables, 
      options?: UseQueryOptions<GetUsersForBusinessPartnersFormQuery, TError, TData>
    ) => 
    useQuery<GetUsersForBusinessPartnersFormQuery, TError, TData>(
      ['GetUsersForBusinessPartnersForm', variables],
      fetcher<GetUsersForBusinessPartnersFormQuery, GetUsersForBusinessPartnersFormQueryVariables>(GetUsersForBusinessPartnersFormDocument, variables),
      options
    );
export const GetCountriesIdsTypesForCreatingBusinessPartnerDocument = `
    query GetCountriesIdsTypesForCreatingBusinessPartner($id: ID!) @app(id: BUSINESS_PARTNERS) {
  countryIdsTypes(id: $id) {
    country {
      id
    }
    idsTypes {
      id
    }
  }
}
    `;
export const useGetCountriesIdsTypesForCreatingBusinessPartnerQuery = <
      TData = GetCountriesIdsTypesForCreatingBusinessPartnerQuery,
      TError = unknown
    >(
      variables: GetCountriesIdsTypesForCreatingBusinessPartnerQueryVariables, 
      options?: UseQueryOptions<GetCountriesIdsTypesForCreatingBusinessPartnerQuery, TError, TData>
    ) => 
    useQuery<GetCountriesIdsTypesForCreatingBusinessPartnerQuery, TError, TData>(
      ['GetCountriesIdsTypesForCreatingBusinessPartner', variables],
      fetcher<GetCountriesIdsTypesForCreatingBusinessPartnerQuery, GetCountriesIdsTypesForCreatingBusinessPartnerQueryVariables>(GetCountriesIdsTypesForCreatingBusinessPartnerDocument, variables),
      options
    );
export const GetCountriesForCreatingBusinessPartnerDocument = `
    query GetCountriesForCreatingBusinessPartner($language: String!, $filters: CountryFilters, $pagination: Pagination) @app(id: BUSINESS_PARTNERS) {
  countries(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetCountriesForCreatingBusinessPartnerQuery = <
      TData = GetCountriesForCreatingBusinessPartnerQuery,
      TError = unknown
    >(
      variables: GetCountriesForCreatingBusinessPartnerQueryVariables, 
      options?: UseQueryOptions<GetCountriesForCreatingBusinessPartnerQuery, TError, TData>
    ) => 
    useQuery<GetCountriesForCreatingBusinessPartnerQuery, TError, TData>(
      ['GetCountriesForCreatingBusinessPartner', variables],
      fetcher<GetCountriesForCreatingBusinessPartnerQuery, GetCountriesForCreatingBusinessPartnerQueryVariables>(GetCountriesForCreatingBusinessPartnerDocument, variables),
      options
    );
export const GetBusinessPartnerTypesForBusinessPartnersFormDocument = `
    query GetBusinessPartnerTypesForBusinessPartnersForm($language: String!, $filters: BusinessPartnerTypeFilters, $pagination: Pagination) @app(id: BUSINESS_PARTNERS) {
  businessPartnerTypes(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  businessPartnerTypesCount(filters: $filters)
}
    `;
export const useGetBusinessPartnerTypesForBusinessPartnersFormQuery = <
      TData = GetBusinessPartnerTypesForBusinessPartnersFormQuery,
      TError = unknown
    >(
      variables: GetBusinessPartnerTypesForBusinessPartnersFormQueryVariables, 
      options?: UseQueryOptions<GetBusinessPartnerTypesForBusinessPartnersFormQuery, TError, TData>
    ) => 
    useQuery<GetBusinessPartnerTypesForBusinessPartnersFormQuery, TError, TData>(
      ['GetBusinessPartnerTypesForBusinessPartnersForm', variables],
      fetcher<GetBusinessPartnerTypesForBusinessPartnersFormQuery, GetBusinessPartnerTypesForBusinessPartnersFormQueryVariables>(GetBusinessPartnerTypesForBusinessPartnersFormDocument, variables),
      options
    );
export const CreateBusinessPartnerDocument = `
    mutation CreateBusinessPartner($businessPartner: BusinessPartnerInput!) {
  createBusinessPartner(businessPartner: $businessPartner)
}
    `;
export const useCreateBusinessPartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBusinessPartnerMutation, TError, CreateBusinessPartnerMutationVariables, TContext>) => 
    useMutation<CreateBusinessPartnerMutation, TError, CreateBusinessPartnerMutationVariables, TContext>(
      (variables?: CreateBusinessPartnerMutationVariables) => fetcher<CreateBusinessPartnerMutation, CreateBusinessPartnerMutationVariables>(CreateBusinessPartnerDocument, variables)(),
      options
    );
export const UpdateBusinessPartnerDocument = `
    mutation UpdateBusinessPartner($id: ID!, $businessPartner: BusinessPartnerInput!) {
  updateBusinessPartner(id: $id, businessPartner: $businessPartner)
}
    `;
export const useUpdateBusinessPartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBusinessPartnerMutation, TError, UpdateBusinessPartnerMutationVariables, TContext>) => 
    useMutation<UpdateBusinessPartnerMutation, TError, UpdateBusinessPartnerMutationVariables, TContext>(
      (variables?: UpdateBusinessPartnerMutationVariables) => fetcher<UpdateBusinessPartnerMutation, UpdateBusinessPartnerMutationVariables>(UpdateBusinessPartnerDocument, variables)(),
      options
    );
export const DeleteBusinessPartnerDocument = `
    mutation DeleteBusinessPartner($id: ID!) {
  deleteBusinessPartner(id: $id)
}
    `;
export const useDeleteBusinessPartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBusinessPartnerMutation, TError, DeleteBusinessPartnerMutationVariables, TContext>) => 
    useMutation<DeleteBusinessPartnerMutation, TError, DeleteBusinessPartnerMutationVariables, TContext>(
      (variables?: DeleteBusinessPartnerMutationVariables) => fetcher<DeleteBusinessPartnerMutation, DeleteBusinessPartnerMutationVariables>(DeleteBusinessPartnerDocument, variables)(),
      options
    );
export const GetBusinessPartners_ViewAllPageDocument = `
    query GetBusinessPartners_ViewAllPage($filters: BusinessPartnersFilters, $pagination: Pagination) @app(id: BUSINESS_PARTNERS) {
  businessPartners(filters: $filters, pagination: $pagination) {
    id
    name
    identification
    lastName
    taxName
    locations {
      id
      address {
        street
        number
        floor
        department
        city
        postalCode
        state
        country
      }
      isFiscalAddress
    }
    taxId {
      countryId
      typeId {
        id
        isTaxId
      }
      id
    }
    businessPartnerType {
      id
    }
    contacts {
      userId
      email
      name
      lastName
      phoneNumber
      birthDate
      position
    }
    backend {
      backendId
      externalId
    }
  }
  businessPartnersCount(filters: $filters)
}
    `;
export const useGetBusinessPartners_ViewAllPageQuery = <
      TData = GetBusinessPartners_ViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetBusinessPartners_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessPartners_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessPartners_ViewAllPageQuery, TError, TData>(
      ['GetBusinessPartners_ViewAllPage', variables],
      fetcher<GetBusinessPartners_ViewAllPageQuery, GetBusinessPartners_ViewAllPageQueryVariables>(GetBusinessPartners_ViewAllPageDocument, variables),
      options
    );
export const GetBusinessPartnerViewPageDocument = `
    query GetBusinessPartnerViewPage($id: ID!) @app(id: BUSINESS_PARTNERS) {
  businessPartner(id: $id) {
    id
    name
    identification
    lastName
    taxName
    locations {
      id
      address {
        street
        number
        floor
        department
        city
        postalCode
        state
        country
      }
      isFiscalAddress
    }
    taxId {
      countryId
      typeId {
        id
        isTaxId
      }
      id
    }
    businessPartnerType {
      id
    }
    contacts {
      userId
      email
      name
      lastName
      phoneNumber
      position
      birthDate
    }
    backend {
      backendId
      externalId
    }
  }
}
    `;
export const useGetBusinessPartnerViewPageQuery = <
      TData = GetBusinessPartnerViewPageQuery,
      TError = unknown
    >(
      variables: GetBusinessPartnerViewPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessPartnerViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessPartnerViewPageQuery, TError, TData>(
      ['GetBusinessPartnerViewPage', variables],
      fetcher<GetBusinessPartnerViewPageQuery, GetBusinessPartnerViewPageQueryVariables>(GetBusinessPartnerViewPageDocument, variables),
      options
    );
export const BusinessProfileExistsDocument = `
    query BusinessProfileExists($businessProfileId: ID!) @app(id: BUSINESS_PROFILES) {
  businessProfileExists(id: $businessProfileId)
}
    `;
export const useBusinessProfileExistsQuery = <
      TData = BusinessProfileExistsQuery,
      TError = unknown
    >(
      variables: BusinessProfileExistsQueryVariables, 
      options?: UseQueryOptions<BusinessProfileExistsQuery, TError, TData>
    ) => 
    useQuery<BusinessProfileExistsQuery, TError, TData>(
      ['BusinessProfileExists', variables],
      fetcher<BusinessProfileExistsQuery, BusinessProfileExistsQueryVariables>(BusinessProfileExistsDocument, variables),
      options
    );
export const GetTilesGroupsForCreateBusinessProfileDocument = `
    query GetTilesGroupsForCreateBusinessProfile($language: String!) @app(id: BUSINESS_PROFILES) {
  groups {
    id
    translation(language: $language) {
      language
      description
    }
  }
}
    `;
export const useGetTilesGroupsForCreateBusinessProfileQuery = <
      TData = GetTilesGroupsForCreateBusinessProfileQuery,
      TError = unknown
    >(
      variables: GetTilesGroupsForCreateBusinessProfileQueryVariables, 
      options?: UseQueryOptions<GetTilesGroupsForCreateBusinessProfileQuery, TError, TData>
    ) => 
    useQuery<GetTilesGroupsForCreateBusinessProfileQuery, TError, TData>(
      ['GetTilesGroupsForCreateBusinessProfile', variables],
      fetcher<GetTilesGroupsForCreateBusinessProfileQuery, GetTilesGroupsForCreateBusinessProfileQueryVariables>(GetTilesGroupsForCreateBusinessProfileDocument, variables),
      options
    );
export const GetAppActionsForCreateBusinessProfilesDocument = `
    query GetAppActionsForCreateBusinessProfiles($id: ID!, $language: String!) @app(id: BUSINESS_PROFILES) {
  app(id: $id) {
    id
    actions {
      id
      translation(language: $language) {
        description
      }
    }
  }
}
    `;
export const useGetAppActionsForCreateBusinessProfilesQuery = <
      TData = GetAppActionsForCreateBusinessProfilesQuery,
      TError = unknown
    >(
      variables: GetAppActionsForCreateBusinessProfilesQueryVariables, 
      options?: UseQueryOptions<GetAppActionsForCreateBusinessProfilesQuery, TError, TData>
    ) => 
    useQuery<GetAppActionsForCreateBusinessProfilesQuery, TError, TData>(
      ['GetAppActionsForCreateBusinessProfiles', variables],
      fetcher<GetAppActionsForCreateBusinessProfilesQuery, GetAppActionsForCreateBusinessProfilesQueryVariables>(GetAppActionsForCreateBusinessProfilesDocument, variables),
      options
    );
export const GetAppsForCreateBusinessProfilesDocument = `
    query GetAppsForCreateBusinessProfiles($language: String!, $groupsIds: [ID!]!) @app(id: BUSINESS_PROFILES) {
  apps(filters: {groupsIds: $groupsIds}) {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetAppsForCreateBusinessProfilesQuery = <
      TData = GetAppsForCreateBusinessProfilesQuery,
      TError = unknown
    >(
      variables: GetAppsForCreateBusinessProfilesQueryVariables, 
      options?: UseQueryOptions<GetAppsForCreateBusinessProfilesQuery, TError, TData>
    ) => 
    useQuery<GetAppsForCreateBusinessProfilesQuery, TError, TData>(
      ['GetAppsForCreateBusinessProfiles', variables],
      fetcher<GetAppsForCreateBusinessProfilesQuery, GetAppsForCreateBusinessProfilesQueryVariables>(GetAppsForCreateBusinessProfilesDocument, variables),
      options
    );
export const CreateBusinessProfileDocument = `
    mutation CreateBusinessProfile($businessProfile: BusinessProfileInput!) {
  createBusinessProfile(businessProfile: $businessProfile)
}
    `;
export const useCreateBusinessProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBusinessProfileMutation, TError, CreateBusinessProfileMutationVariables, TContext>) => 
    useMutation<CreateBusinessProfileMutation, TError, CreateBusinessProfileMutationVariables, TContext>(
      (variables?: CreateBusinessProfileMutationVariables) => fetcher<CreateBusinessProfileMutation, CreateBusinessProfileMutationVariables>(CreateBusinessProfileDocument, variables)(),
      options
    );
export const UpdateBusinessProfileDocument = `
    mutation UpdateBusinessProfile($id: ID!, $businessProfile: BusinessProfileInput!) {
  updateBusinessProfile(id: $id, businessProfile: $businessProfile)
}
    `;
export const useUpdateBusinessProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBusinessProfileMutation, TError, UpdateBusinessProfileMutationVariables, TContext>) => 
    useMutation<UpdateBusinessProfileMutation, TError, UpdateBusinessProfileMutationVariables, TContext>(
      (variables?: UpdateBusinessProfileMutationVariables) => fetcher<UpdateBusinessProfileMutation, UpdateBusinessProfileMutationVariables>(UpdateBusinessProfileDocument, variables)(),
      options
    );
export const DeleteBusinessProfileDocument = `
    mutation DeleteBusinessProfile($id: ID!) {
  deleteBusinessProfile(id: $id)
}
    `;
export const useDeleteBusinessProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBusinessProfileMutation, TError, DeleteBusinessProfileMutationVariables, TContext>) => 
    useMutation<DeleteBusinessProfileMutation, TError, DeleteBusinessProfileMutationVariables, TContext>(
      (variables?: DeleteBusinessProfileMutationVariables) => fetcher<DeleteBusinessProfileMutation, DeleteBusinessProfileMutationVariables>(DeleteBusinessProfileDocument, variables)(),
      options
    );
export const GetBusinessProfilesForTablePageDocument = `
    query GetBusinessProfilesForTablePage($language: String!, $filters: BusinessProfilesFilters, $pagination: Pagination) @app(id: BUSINESS_PROFILES) {
  businessProfiles(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
      language
    }
  }
  businessProfilesCount(filters: $filters)
}
    `;
export const useGetBusinessProfilesForTablePageQuery = <
      TData = GetBusinessProfilesForTablePageQuery,
      TError = unknown
    >(
      variables: GetBusinessProfilesForTablePageQueryVariables, 
      options?: UseQueryOptions<GetBusinessProfilesForTablePageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessProfilesForTablePageQuery, TError, TData>(
      ['GetBusinessProfilesForTablePage', variables],
      fetcher<GetBusinessProfilesForTablePageQuery, GetBusinessProfilesForTablePageQueryVariables>(GetBusinessProfilesForTablePageDocument, variables),
      options
    );
export const GetBusinessProfileForViewPageDocument = `
    query GetBusinessProfileForViewPage($id: ID!) @app(id: BUSINESS_PROFILES) {
  businessProfile(id: $id) {
    id
    translations {
      description
      language
    }
    groups {
      id
    }
    appsActions {
      id
      app {
        id
      }
    }
  }
}
    `;
export const useGetBusinessProfileForViewPageQuery = <
      TData = GetBusinessProfileForViewPageQuery,
      TError = unknown
    >(
      variables: GetBusinessProfileForViewPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessProfileForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessProfileForViewPageQuery, TError, TData>(
      ['GetBusinessProfileForViewPage', variables],
      fetcher<GetBusinessProfileForViewPageQuery, GetBusinessProfileForViewPageQueryVariables>(GetBusinessProfileForViewPageDocument, variables),
      options
    );
export const GetCompaniesForBusinessUnitFormDocument = `
    query GetCompaniesForBusinessUnitForm($filters: CompanyFilters, $pagination: Pagination) @app(id: BUSINESS_UNITS) {
  companies(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
  companiesCount(filters: $filters)
}
    `;
export const useGetCompaniesForBusinessUnitFormQuery = <
      TData = GetCompaniesForBusinessUnitFormQuery,
      TError = unknown
    >(
      variables?: GetCompaniesForBusinessUnitFormQueryVariables, 
      options?: UseQueryOptions<GetCompaniesForBusinessUnitFormQuery, TError, TData>
    ) => 
    useQuery<GetCompaniesForBusinessUnitFormQuery, TError, TData>(
      ['GetCompaniesForBusinessUnitForm', variables],
      fetcher<GetCompaniesForBusinessUnitFormQuery, GetCompaniesForBusinessUnitFormQueryVariables>(GetCompaniesForBusinessUnitFormDocument, variables),
      options
    );
export const GetCostCollectorForBusinessUnitsFormDocument = `
    query GetCostCollectorForBusinessUnitsForm($language: String!, $filters: CostCollectorFilters, $pagination: Pagination) @app(id: BUSINESS_UNITS) {
  costCollectors(filters: $filters, pagination: $pagination) {
    id
    company {
      id
    }
    description(language: $language) {
      description
    }
  }
}
    `;
export const useGetCostCollectorForBusinessUnitsFormQuery = <
      TData = GetCostCollectorForBusinessUnitsFormQuery,
      TError = unknown
    >(
      variables: GetCostCollectorForBusinessUnitsFormQueryVariables, 
      options?: UseQueryOptions<GetCostCollectorForBusinessUnitsFormQuery, TError, TData>
    ) => 
    useQuery<GetCostCollectorForBusinessUnitsFormQuery, TError, TData>(
      ['GetCostCollectorForBusinessUnitsForm', variables],
      fetcher<GetCostCollectorForBusinessUnitsFormQuery, GetCostCollectorForBusinessUnitsFormQueryVariables>(GetCostCollectorForBusinessUnitsFormDocument, variables),
      options
    );
export const GetRevenueCollectorsForBusinessUnitsFormDocument = `
    query GetRevenueCollectorsForBusinessUnitsForm($language: String!, $filters: RevenueCollectorFilters, $pagination: Pagination) @app(id: BUSINESS_UNITS) {
  revenueCollectors(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
    company {
      id
      name
      currency {
        id
      }
      chartOfAccount {
        id
      }
    }
  }
}
    `;
export const useGetRevenueCollectorsForBusinessUnitsFormQuery = <
      TData = GetRevenueCollectorsForBusinessUnitsFormQuery,
      TError = unknown
    >(
      variables: GetRevenueCollectorsForBusinessUnitsFormQueryVariables, 
      options?: UseQueryOptions<GetRevenueCollectorsForBusinessUnitsFormQuery, TError, TData>
    ) => 
    useQuery<GetRevenueCollectorsForBusinessUnitsFormQuery, TError, TData>(
      ['GetRevenueCollectorsForBusinessUnitsForm', variables],
      fetcher<GetRevenueCollectorsForBusinessUnitsFormQuery, GetRevenueCollectorsForBusinessUnitsFormQueryVariables>(GetRevenueCollectorsForBusinessUnitsFormDocument, variables),
      options
    );
export const CreateBusinessUnitDocument = `
    mutation CreateBusinessUnit($businessUnit: BusinessUnitInput!) {
  createBusinessUnit(businessUnit: $businessUnit)
}
    `;
export const useCreateBusinessUnitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBusinessUnitMutation, TError, CreateBusinessUnitMutationVariables, TContext>) => 
    useMutation<CreateBusinessUnitMutation, TError, CreateBusinessUnitMutationVariables, TContext>(
      (variables?: CreateBusinessUnitMutationVariables) => fetcher<CreateBusinessUnitMutation, CreateBusinessUnitMutationVariables>(CreateBusinessUnitDocument, variables)(),
      options
    );
export const UpdateBusinessUnitDocument = `
    mutation UpdateBusinessUnit($id: ID!, $businessUnit: BusinessUnitInput!) {
  updateBusinessUnit(id: $id, businessUnit: $businessUnit)
}
    `;
export const useUpdateBusinessUnitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBusinessUnitMutation, TError, UpdateBusinessUnitMutationVariables, TContext>) => 
    useMutation<UpdateBusinessUnitMutation, TError, UpdateBusinessUnitMutationVariables, TContext>(
      (variables?: UpdateBusinessUnitMutationVariables) => fetcher<UpdateBusinessUnitMutation, UpdateBusinessUnitMutationVariables>(UpdateBusinessUnitDocument, variables)(),
      options
    );
export const DeleteBusinessUnitDocument = `
    mutation DeleteBusinessUnit($id: ID!) {
  deleteBusinessUnit(id: $id)
}
    `;
export const useDeleteBusinessUnitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBusinessUnitMutation, TError, DeleteBusinessUnitMutationVariables, TContext>) => 
    useMutation<DeleteBusinessUnitMutation, TError, DeleteBusinessUnitMutationVariables, TContext>(
      (variables?: DeleteBusinessUnitMutationVariables) => fetcher<DeleteBusinessUnitMutation, DeleteBusinessUnitMutationVariables>(DeleteBusinessUnitDocument, variables)(),
      options
    );
export const GetBusinessUnits_ViewAllPageDocument = `
    query GetBusinessUnits_ViewAllPage($language: String!, $filters: BusinessUnitFilters, $pagination: Pagination) @app(id: BUSINESS_UNITS) {
  businessUnits(filters: $filters, pagination: $pagination) {
    id
    company {
      id
      name
    }
    businessUnitCategory
    translations {
      language
      description
    }
    translation(language: $language) {
      description
    }
    revenueCollectors {
      id
      translations {
        language
        description
      }
      company {
        id
        name
      }
    }
    costCollectors {
      id
      descriptions {
        language
        description
      }
      company {
        id
        name
      }
    }
  }
  businessUnitsCount(filters: $filters)
}
    `;
export const useGetBusinessUnits_ViewAllPageQuery = <
      TData = GetBusinessUnits_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetBusinessUnits_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessUnits_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessUnits_ViewAllPageQuery, TError, TData>(
      ['GetBusinessUnits_ViewAllPage', variables],
      fetcher<GetBusinessUnits_ViewAllPageQuery, GetBusinessUnits_ViewAllPageQueryVariables>(GetBusinessUnits_ViewAllPageDocument, variables),
      options
    );
export const GetBusinessUnitViewPageDocument = `
    query GetBusinessUnitViewPage($id: ID!) @app(id: BUSINESS_UNITS) {
  businessUnit(id: $id) {
    id
    company {
      id
      name
    }
    translations {
      language
      description
    }
    businessUnitCategory
    revenueCollectors {
      id
      translations {
        language
        description
      }
      company {
        id
        name
      }
    }
    costCollectors {
      id
      descriptions {
        language
        description
      }
      company {
        id
        name
      }
    }
  }
}
    `;
export const useGetBusinessUnitViewPageQuery = <
      TData = GetBusinessUnitViewPageQuery,
      TError = unknown
    >(
      variables: GetBusinessUnitViewPageQueryVariables, 
      options?: UseQueryOptions<GetBusinessUnitViewPageQuery, TError, TData>
    ) => 
    useQuery<GetBusinessUnitViewPageQuery, TError, TData>(
      ['GetBusinessUnitViewPage', variables],
      fetcher<GetBusinessUnitViewPageQuery, GetBusinessUnitViewPageQueryVariables>(GetBusinessUnitViewPageDocument, variables),
      options
    );
export const ChartOfAccountExistsDocument = `
    query ChartOfAccountExists($id: ID!) @app(id: CHART_OF_ACCOUNTS) {
  chartOfAccountExists(id: $id)
}
    `;
export const useChartOfAccountExistsQuery = <
      TData = ChartOfAccountExistsQuery,
      TError = unknown
    >(
      variables: ChartOfAccountExistsQueryVariables, 
      options?: UseQueryOptions<ChartOfAccountExistsQuery, TError, TData>
    ) => 
    useQuery<ChartOfAccountExistsQuery, TError, TData>(
      ['ChartOfAccountExists', variables],
      fetcher<ChartOfAccountExistsQuery, ChartOfAccountExistsQueryVariables>(ChartOfAccountExistsDocument, variables),
      options
    );
export const CreateChartOfAccountDocument = `
    mutation CreateChartOfAccount($chartOfAccount: ChartOfAccountInput!) {
  createChartOfAccount(chartOfAccount: $chartOfAccount)
}
    `;
export const useCreateChartOfAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateChartOfAccountMutation, TError, CreateChartOfAccountMutationVariables, TContext>) => 
    useMutation<CreateChartOfAccountMutation, TError, CreateChartOfAccountMutationVariables, TContext>(
      (variables?: CreateChartOfAccountMutationVariables) => fetcher<CreateChartOfAccountMutation, CreateChartOfAccountMutationVariables>(CreateChartOfAccountDocument, variables)(),
      options
    );
export const UpdateChartOfAccountDocument = `
    mutation UpdateChartOfAccount($id: ID!, $chartOfAccount: ChartOfAccountInput!) {
  updateChartOfAccount(id: $id, chartOfAccount: $chartOfAccount)
}
    `;
export const useUpdateChartOfAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateChartOfAccountMutation, TError, UpdateChartOfAccountMutationVariables, TContext>) => 
    useMutation<UpdateChartOfAccountMutation, TError, UpdateChartOfAccountMutationVariables, TContext>(
      (variables?: UpdateChartOfAccountMutationVariables) => fetcher<UpdateChartOfAccountMutation, UpdateChartOfAccountMutationVariables>(UpdateChartOfAccountDocument, variables)(),
      options
    );
export const DeletChartOfAccountDocument = `
    mutation DeletChartOfAccount($id: ID!) {
  deleteChartOfAccount(id: $id)
}
    `;
export const useDeletChartOfAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletChartOfAccountMutation, TError, DeletChartOfAccountMutationVariables, TContext>) => 
    useMutation<DeletChartOfAccountMutation, TError, DeletChartOfAccountMutationVariables, TContext>(
      (variables?: DeletChartOfAccountMutationVariables) => fetcher<DeletChartOfAccountMutation, DeletChartOfAccountMutationVariables>(DeletChartOfAccountDocument, variables)(),
      options
    );
export const GetChartOfAccounts_ViewAllPageDocument = `
    query GetChartOfAccounts_ViewAllPage($language: String!, $filters: ChartOfAccountFilters, $pagination: Pagination) @app(id: CHART_OF_ACCOUNTS) {
  chartOfAccounts(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  chartOfAccountsCount(filters: $filters)
}
    `;
export const useGetChartOfAccounts_ViewAllPageQuery = <
      TData = GetChartOfAccounts_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetChartOfAccounts_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetChartOfAccounts_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetChartOfAccounts_ViewAllPageQuery, TError, TData>(
      ['GetChartOfAccounts_ViewAllPage', variables],
      fetcher<GetChartOfAccounts_ViewAllPageQuery, GetChartOfAccounts_ViewAllPageQueryVariables>(GetChartOfAccounts_ViewAllPageDocument, variables),
      options
    );
export const GetChartOfAccount_ViewPageDocument = `
    query GetChartOfAccount_ViewPage($id: ID!) @app(id: CHART_OF_ACCOUNTS) {
  chartOfAccount(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetChartOfAccount_ViewPageQuery = <
      TData = GetChartOfAccount_ViewPageQuery,
      TError = unknown
    >(
      variables: GetChartOfAccount_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetChartOfAccount_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetChartOfAccount_ViewPageQuery, TError, TData>(
      ['GetChartOfAccount_ViewPage', variables],
      fetcher<GetChartOfAccount_ViewPageQuery, GetChartOfAccount_ViewPageQueryVariables>(GetChartOfAccount_ViewPageDocument, variables),
      options
    );
export const CommercialEntityExistsDocument = `
    query CommercialEntityExists($id: ID!) @app(id: COMMERCIAL_ENTITIES) {
  commercialEntityExists(id: $id)
}
    `;
export const useCommercialEntityExistsQuery = <
      TData = CommercialEntityExistsQuery,
      TError = unknown
    >(
      variables: CommercialEntityExistsQueryVariables, 
      options?: UseQueryOptions<CommercialEntityExistsQuery, TError, TData>
    ) => 
    useQuery<CommercialEntityExistsQuery, TError, TData>(
      ['CommercialEntityExists', variables],
      fetcher<CommercialEntityExistsQuery, CommercialEntityExistsQueryVariables>(CommercialEntityExistsDocument, variables),
      options
    );
export const GetCompaniesForCommercialEntitiesFormDocument = `
    query GetCompaniesForCommercialEntitiesForm($filters: CompanyFilters, $pagination: Pagination) @app(id: COMMERCIAL_ENTITIES) {
  companies(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
  companiesCount(filters: $filters)
}
    `;
export const useGetCompaniesForCommercialEntitiesFormQuery = <
      TData = GetCompaniesForCommercialEntitiesFormQuery,
      TError = unknown
    >(
      variables?: GetCompaniesForCommercialEntitiesFormQueryVariables, 
      options?: UseQueryOptions<GetCompaniesForCommercialEntitiesFormQuery, TError, TData>
    ) => 
    useQuery<GetCompaniesForCommercialEntitiesFormQuery, TError, TData>(
      ['GetCompaniesForCommercialEntitiesForm', variables],
      fetcher<GetCompaniesForCommercialEntitiesFormQuery, GetCompaniesForCommercialEntitiesFormQueryVariables>(GetCompaniesForCommercialEntitiesFormDocument, variables),
      options
    );
export const CreateCommercialEntityDocument = `
    mutation CreateCommercialEntity($commercialEntity: CommercialEntityInput!) {
  createCommercialEntity(commercialEntity: $commercialEntity)
}
    `;
export const useCreateCommercialEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCommercialEntityMutation, TError, CreateCommercialEntityMutationVariables, TContext>) => 
    useMutation<CreateCommercialEntityMutation, TError, CreateCommercialEntityMutationVariables, TContext>(
      (variables?: CreateCommercialEntityMutationVariables) => fetcher<CreateCommercialEntityMutation, CreateCommercialEntityMutationVariables>(CreateCommercialEntityDocument, variables)(),
      options
    );
export const UpdateCommercialEntityDocument = `
    mutation UpdateCommercialEntity($id: ID!, $commercialEntity: CommercialEntityInput!) {
  updateCommercialEntity(id: $id, commercialEntity: $commercialEntity)
}
    `;
export const useUpdateCommercialEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCommercialEntityMutation, TError, UpdateCommercialEntityMutationVariables, TContext>) => 
    useMutation<UpdateCommercialEntityMutation, TError, UpdateCommercialEntityMutationVariables, TContext>(
      (variables?: UpdateCommercialEntityMutationVariables) => fetcher<UpdateCommercialEntityMutation, UpdateCommercialEntityMutationVariables>(UpdateCommercialEntityDocument, variables)(),
      options
    );
export const DeleteCommercialEntityDocument = `
    mutation DeleteCommercialEntity($id: ID!) {
  deleteCommercialEntity(id: $id)
}
    `;
export const useDeleteCommercialEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCommercialEntityMutation, TError, DeleteCommercialEntityMutationVariables, TContext>) => 
    useMutation<DeleteCommercialEntityMutation, TError, DeleteCommercialEntityMutationVariables, TContext>(
      (variables?: DeleteCommercialEntityMutationVariables) => fetcher<DeleteCommercialEntityMutation, DeleteCommercialEntityMutationVariables>(DeleteCommercialEntityDocument, variables)(),
      options
    );
export const GetCommercialEntities_ViewAllPageDocument = `
    query GetCommercialEntities_ViewAllPage($language: String!, $filters: CommercialEntityFilters, $pagination: Pagination) @app(id: COMMERCIAL_ENTITIES) {
  commercialEntities(filters: $filters, pagination: $pagination) {
    id
    company {
      id
      name
      currency {
        id
      }
      chartOfAccount {
        id
      }
    }
    translation(language: $language) {
      description
    }
  }
  commercialEntitiesCount(filters: $filters)
}
    `;
export const useGetCommercialEntities_ViewAllPageQuery = <
      TData = GetCommercialEntities_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetCommercialEntities_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetCommercialEntities_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetCommercialEntities_ViewAllPageQuery, TError, TData>(
      ['GetCommercialEntities_ViewAllPage', variables],
      fetcher<GetCommercialEntities_ViewAllPageQuery, GetCommercialEntities_ViewAllPageQueryVariables>(GetCommercialEntities_ViewAllPageDocument, variables),
      options
    );
export const GetCommercialEntity_ViewPageDocument = `
    query GetCommercialEntity_ViewPage($id: ID!) @app(id: COMMERCIAL_ENTITIES) {
  commercialEntity(id: $id) {
    id
    company {
      id
      name
      currency {
        id
      }
      chartOfAccount {
        id
      }
    }
    translations {
      language
      description
    }
  }
}
    `;
export const useGetCommercialEntity_ViewPageQuery = <
      TData = GetCommercialEntity_ViewPageQuery,
      TError = unknown
    >(
      variables: GetCommercialEntity_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetCommercialEntity_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetCommercialEntity_ViewPageQuery, TError, TData>(
      ['GetCommercialEntity_ViewPage', variables],
      fetcher<GetCommercialEntity_ViewPageQuery, GetCommercialEntity_ViewPageQueryVariables>(GetCommercialEntity_ViewPageDocument, variables),
      options
    );
export const CompaniesExistsDocument = `
    query CompaniesExists($id: ID!) @app(id: COMPANIES) {
  companiesExists(id: $id)
}
    `;
export const useCompaniesExistsQuery = <
      TData = CompaniesExistsQuery,
      TError = unknown
    >(
      variables: CompaniesExistsQueryVariables, 
      options?: UseQueryOptions<CompaniesExistsQuery, TError, TData>
    ) => 
    useQuery<CompaniesExistsQuery, TError, TData>(
      ['CompaniesExists', variables],
      fetcher<CompaniesExistsQuery, CompaniesExistsQueryVariables>(CompaniesExistsDocument, variables),
      options
    );
export const GetChartOfAccountsForCompaniesFormDocument = `
    query GetChartOfAccountsForCompaniesForm($language: String!, $filters: ChartOfAccountFilters, $pagination: Pagination) @app(id: COMPANIES) {
  chartOfAccounts(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  chartOfAccountsCount(filters: $filters)
}
    `;
export const useGetChartOfAccountsForCompaniesFormQuery = <
      TData = GetChartOfAccountsForCompaniesFormQuery,
      TError = unknown
    >(
      variables: GetChartOfAccountsForCompaniesFormQueryVariables, 
      options?: UseQueryOptions<GetChartOfAccountsForCompaniesFormQuery, TError, TData>
    ) => 
    useQuery<GetChartOfAccountsForCompaniesFormQuery, TError, TData>(
      ['GetChartOfAccountsForCompaniesForm', variables],
      fetcher<GetChartOfAccountsForCompaniesFormQuery, GetChartOfAccountsForCompaniesFormQueryVariables>(GetChartOfAccountsForCompaniesFormDocument, variables),
      options
    );
export const GetCurrenciesForCompaniesFormDocument = `
    query GetCurrenciesForCompaniesForm($language: String!, $filters: CurrencyFilters, $pagination: Pagination) @app(id: COMPANIES) {
  currencies(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  currenciesCount(filters: $filters)
}
    `;
export const useGetCurrenciesForCompaniesFormQuery = <
      TData = GetCurrenciesForCompaniesFormQuery,
      TError = unknown
    >(
      variables: GetCurrenciesForCompaniesFormQueryVariables, 
      options?: UseQueryOptions<GetCurrenciesForCompaniesFormQuery, TError, TData>
    ) => 
    useQuery<GetCurrenciesForCompaniesFormQuery, TError, TData>(
      ['GetCurrenciesForCompaniesForm', variables],
      fetcher<GetCurrenciesForCompaniesFormQuery, GetCurrenciesForCompaniesFormQueryVariables>(GetCurrenciesForCompaniesFormDocument, variables),
      options
    );
export const CreateCompanyDocument = `
    mutation CreateCompany($company: CompanyInput!) {
  createCompany(company: $company)
}
    `;
export const useCreateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCompanyMutation, TError, CreateCompanyMutationVariables, TContext>) => 
    useMutation<CreateCompanyMutation, TError, CreateCompanyMutationVariables, TContext>(
      (variables?: CreateCompanyMutationVariables) => fetcher<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, variables)(),
      options
    );
export const UpdateCompanyDocument = `
    mutation UpdateCompany($id: ID!, $company: CompanyInput!) {
  updateCompany(id: $id, company: $company)
}
    `;
export const useUpdateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>) => 
    useMutation<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>(
      (variables?: UpdateCompanyMutationVariables) => fetcher<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, variables)(),
      options
    );
export const DeletCompanyDocument = `
    mutation DeletCompany($id: ID!) {
  deleteCompany(id: $id)
}
    `;
export const useDeletCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletCompanyMutation, TError, DeletCompanyMutationVariables, TContext>) => 
    useMutation<DeletCompanyMutation, TError, DeletCompanyMutationVariables, TContext>(
      (variables?: DeletCompanyMutationVariables) => fetcher<DeletCompanyMutation, DeletCompanyMutationVariables>(DeletCompanyDocument, variables)(),
      options
    );
export const GetCompanies_ViewAllPageDocument = `
    query GetCompanies_ViewAllPage($filters: CompanyFilters, $pagination: Pagination) @app(id: COMPANIES) {
  companies(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
  companiesCount(filters: $filters)
}
    `;
export const useGetCompanies_ViewAllPageQuery = <
      TData = GetCompanies_ViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetCompanies_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetCompanies_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetCompanies_ViewAllPageQuery, TError, TData>(
      ['GetCompanies_ViewAllPage', variables],
      fetcher<GetCompanies_ViewAllPageQuery, GetCompanies_ViewAllPageQueryVariables>(GetCompanies_ViewAllPageDocument, variables),
      options
    );
export const GetCompany_ViewPageDocument = `
    query GetCompany_ViewPage($id: ID!) @app(id: COMPANIES) {
  company(id: $id) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
}
    `;
export const useGetCompany_ViewPageQuery = <
      TData = GetCompany_ViewPageQuery,
      TError = unknown
    >(
      variables: GetCompany_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetCompany_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetCompany_ViewPageQuery, TError, TData>(
      ['GetCompany_ViewPage', variables],
      fetcher<GetCompany_ViewPageQuery, GetCompany_ViewPageQueryVariables>(GetCompany_ViewPageDocument, variables),
      options
    );
export const CostCollectorsExistsDocument = `
    query CostCollectorsExists($id: ID!) @app(id: COST_COLLECTORS) {
  costCollectorsExists(id: $id)
}
    `;
export const useCostCollectorsExistsQuery = <
      TData = CostCollectorsExistsQuery,
      TError = unknown
    >(
      variables: CostCollectorsExistsQueryVariables, 
      options?: UseQueryOptions<CostCollectorsExistsQuery, TError, TData>
    ) => 
    useQuery<CostCollectorsExistsQuery, TError, TData>(
      ['CostCollectorsExists', variables],
      fetcher<CostCollectorsExistsQuery, CostCollectorsExistsQueryVariables>(CostCollectorsExistsDocument, variables),
      options
    );
export const GetCompaniesForCostCollectorsFormDocument = `
    query GetCompaniesForCostCollectorsForm($filters: CompanyFilters, $pagination: Pagination) @app(id: COMMERCIAL_ENTITIES) {
  companies(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
  companiesCount(filters: $filters)
}
    `;
export const useGetCompaniesForCostCollectorsFormQuery = <
      TData = GetCompaniesForCostCollectorsFormQuery,
      TError = unknown
    >(
      variables?: GetCompaniesForCostCollectorsFormQueryVariables, 
      options?: UseQueryOptions<GetCompaniesForCostCollectorsFormQuery, TError, TData>
    ) => 
    useQuery<GetCompaniesForCostCollectorsFormQuery, TError, TData>(
      ['GetCompaniesForCostCollectorsForm', variables],
      fetcher<GetCompaniesForCostCollectorsFormQuery, GetCompaniesForCostCollectorsFormQueryVariables>(GetCompaniesForCostCollectorsFormDocument, variables),
      options
    );
export const CreateCostCollectorDocument = `
    mutation CreateCostCollector($costCollector: CostCollectorInput!) {
  createCostCollector(costCollector: $costCollector)
}
    `;
export const useCreateCostCollectorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCostCollectorMutation, TError, CreateCostCollectorMutationVariables, TContext>) => 
    useMutation<CreateCostCollectorMutation, TError, CreateCostCollectorMutationVariables, TContext>(
      (variables?: CreateCostCollectorMutationVariables) => fetcher<CreateCostCollectorMutation, CreateCostCollectorMutationVariables>(CreateCostCollectorDocument, variables)(),
      options
    );
export const UpdateCostCollectorDocument = `
    mutation UpdateCostCollector($id: ID!, $costCollector: CostCollectorInput!) {
  updateCostCollector(id: $id, costCollector: $costCollector)
}
    `;
export const useUpdateCostCollectorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCostCollectorMutation, TError, UpdateCostCollectorMutationVariables, TContext>) => 
    useMutation<UpdateCostCollectorMutation, TError, UpdateCostCollectorMutationVariables, TContext>(
      (variables?: UpdateCostCollectorMutationVariables) => fetcher<UpdateCostCollectorMutation, UpdateCostCollectorMutationVariables>(UpdateCostCollectorDocument, variables)(),
      options
    );
export const DeleteCostCollectorDocument = `
    mutation DeleteCostCollector($id: ID!) {
  deleteCostCollector(id: $id)
}
    `;
export const useDeleteCostCollectorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCostCollectorMutation, TError, DeleteCostCollectorMutationVariables, TContext>) => 
    useMutation<DeleteCostCollectorMutation, TError, DeleteCostCollectorMutationVariables, TContext>(
      (variables?: DeleteCostCollectorMutationVariables) => fetcher<DeleteCostCollectorMutation, DeleteCostCollectorMutationVariables>(DeleteCostCollectorDocument, variables)(),
      options
    );
export const GetCostCollector_ViewAllPageDocument = `
    query GetCostCollector_ViewAllPage($language: String!, $filters: CostCollectorFilters, $pagination: Pagination) @app(id: COST_COLLECTORS) {
  costCollectors(filters: $filters, pagination: $pagination) {
    id
    company {
      id
    }
    description(language: $language) {
      description
    }
  }
  costCollectorsCount(filters: $filters)
}
    `;
export const useGetCostCollector_ViewAllPageQuery = <
      TData = GetCostCollector_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetCostCollector_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetCostCollector_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetCostCollector_ViewAllPageQuery, TError, TData>(
      ['GetCostCollector_ViewAllPage', variables],
      fetcher<GetCostCollector_ViewAllPageQuery, GetCostCollector_ViewAllPageQueryVariables>(GetCostCollector_ViewAllPageDocument, variables),
      options
    );
export const GetCostCollector_ViewPageDocument = `
    query GetCostCollector_ViewPage($id: ID!) @app(id: COST_COLLECTORS) {
  costCollector(id: $id) {
    id
    company {
      id
    }
    descriptions {
      language
      description
    }
  }
}
    `;
export const useGetCostCollector_ViewPageQuery = <
      TData = GetCostCollector_ViewPageQuery,
      TError = unknown
    >(
      variables: GetCostCollector_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetCostCollector_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetCostCollector_ViewPageQuery, TError, TData>(
      ['GetCostCollector_ViewPage', variables],
      fetcher<GetCostCollector_ViewPageQuery, GetCostCollector_ViewPageQueryVariables>(GetCostCollector_ViewPageDocument, variables),
      options
    );
export const CountryIdsTypesExistDocument = `
    query CountryIdsTypesExist($countryId: ID!) @app(id: COUNTRIES_IDS_TYPES) {
  countryIdsTypesExist(countryId: $countryId)
}
    `;
export const useCountryIdsTypesExistQuery = <
      TData = CountryIdsTypesExistQuery,
      TError = unknown
    >(
      variables: CountryIdsTypesExistQueryVariables, 
      options?: UseQueryOptions<CountryIdsTypesExistQuery, TError, TData>
    ) => 
    useQuery<CountryIdsTypesExistQuery, TError, TData>(
      ['CountryIdsTypesExist', variables],
      fetcher<CountryIdsTypesExistQuery, CountryIdsTypesExistQueryVariables>(CountryIdsTypesExistDocument, variables),
      options
    );
export const GetCountriesForCountriesIdsTypesFormDocument = `
    query GetCountriesForCountriesIdsTypesForm($language: String!, $filters: CountryFilters, $pagination: Pagination) @app(id: COUNTRIES_IDS_TYPES) {
  countries(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  countriesCount(filters: $filters)
}
    `;
export const useGetCountriesForCountriesIdsTypesFormQuery = <
      TData = GetCountriesForCountriesIdsTypesFormQuery,
      TError = unknown
    >(
      variables: GetCountriesForCountriesIdsTypesFormQueryVariables, 
      options?: UseQueryOptions<GetCountriesForCountriesIdsTypesFormQuery, TError, TData>
    ) => 
    useQuery<GetCountriesForCountriesIdsTypesFormQuery, TError, TData>(
      ['GetCountriesForCountriesIdsTypesForm', variables],
      fetcher<GetCountriesForCountriesIdsTypesFormQuery, GetCountriesForCountriesIdsTypesFormQueryVariables>(GetCountriesForCountriesIdsTypesFormDocument, variables),
      options
    );
export const CreateCountryIdsTypesDocument = `
    mutation CreateCountryIdsTypes($countryIdsTypes: CountryIdsTypeInput!) {
  createCountryIdsTypes(countryIdsTypes: $countryIdsTypes)
}
    `;
export const useCreateCountryIdsTypesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCountryIdsTypesMutation, TError, CreateCountryIdsTypesMutationVariables, TContext>) => 
    useMutation<CreateCountryIdsTypesMutation, TError, CreateCountryIdsTypesMutationVariables, TContext>(
      (variables?: CreateCountryIdsTypesMutationVariables) => fetcher<CreateCountryIdsTypesMutation, CreateCountryIdsTypesMutationVariables>(CreateCountryIdsTypesDocument, variables)(),
      options
    );
export const UpdateCountryIdsTypesDocument = `
    mutation UpdateCountryIdsTypes($id: ID!, $countryIdsTypes: CountryIdsTypeInput!) {
  updateCountryIdsTypes(id: $id, countryIdsTypes: $countryIdsTypes)
}
    `;
export const useUpdateCountryIdsTypesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCountryIdsTypesMutation, TError, UpdateCountryIdsTypesMutationVariables, TContext>) => 
    useMutation<UpdateCountryIdsTypesMutation, TError, UpdateCountryIdsTypesMutationVariables, TContext>(
      (variables?: UpdateCountryIdsTypesMutationVariables) => fetcher<UpdateCountryIdsTypesMutation, UpdateCountryIdsTypesMutationVariables>(UpdateCountryIdsTypesDocument, variables)(),
      options
    );
export const DeleteCountryIdsTypesDocument = `
    mutation DeleteCountryIdsTypes($id: ID!) {
  deleteCountryIdsTypes(id: $id)
}
    `;
export const useDeleteCountryIdsTypesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCountryIdsTypesMutation, TError, DeleteCountryIdsTypesMutationVariables, TContext>) => 
    useMutation<DeleteCountryIdsTypesMutation, TError, DeleteCountryIdsTypesMutationVariables, TContext>(
      (variables?: DeleteCountryIdsTypesMutationVariables) => fetcher<DeleteCountryIdsTypesMutation, DeleteCountryIdsTypesMutationVariables>(DeleteCountryIdsTypesDocument, variables)(),
      options
    );
export const GetCountriesIdsTypes_ViewAllPageDocument = `
    query GetCountriesIdsTypes_ViewAllPage($filters: CountryIdsTypeFilters, $pagination: Pagination) @app(id: COUNTRIES_IDS_TYPES) {
  countriesIdsTypes(filters: $filters, pagination: $pagination) {
    country {
      id
    }
    idsTypes {
      id
      isTaxId
    }
  }
  countriesIdsTypesCount(filters: $filters)
}
    `;
export const useGetCountriesIdsTypes_ViewAllPageQuery = <
      TData = GetCountriesIdsTypes_ViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetCountriesIdsTypes_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetCountriesIdsTypes_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetCountriesIdsTypes_ViewAllPageQuery, TError, TData>(
      ['GetCountriesIdsTypes_ViewAllPage', variables],
      fetcher<GetCountriesIdsTypes_ViewAllPageQuery, GetCountriesIdsTypes_ViewAllPageQueryVariables>(GetCountriesIdsTypes_ViewAllPageDocument, variables),
      options
    );
export const GetCountryIdsTypes_ViewPageDocument = `
    query GetCountryIdsTypes_ViewPage($id: ID!) @app(id: COUNTRIES_IDS_TYPES) {
  countryIdsTypes(id: $id) {
    country {
      id
    }
    idsTypes {
      id
      isTaxId
    }
  }
}
    `;
export const useGetCountryIdsTypes_ViewPageQuery = <
      TData = GetCountryIdsTypes_ViewPageQuery,
      TError = unknown
    >(
      variables: GetCountryIdsTypes_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetCountryIdsTypes_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetCountryIdsTypes_ViewPageQuery, TError, TData>(
      ['GetCountryIdsTypes_ViewPage', variables],
      fetcher<GetCountryIdsTypes_ViewPageQuery, GetCountryIdsTypes_ViewPageQueryVariables>(GetCountryIdsTypes_ViewPageDocument, variables),
      options
    );
export const CountryExistsDocument = `
    query CountryExists($countryId: ID!) @app(id: COUNTRIES) {
  countryExists(id: $countryId)
}
    `;
export const useCountryExistsQuery = <
      TData = CountryExistsQuery,
      TError = unknown
    >(
      variables: CountryExistsQueryVariables, 
      options?: UseQueryOptions<CountryExistsQuery, TError, TData>
    ) => 
    useQuery<CountryExistsQuery, TError, TData>(
      ['CountryExists', variables],
      fetcher<CountryExistsQuery, CountryExistsQueryVariables>(CountryExistsDocument, variables),
      options
    );
export const CreateCountryDocument = `
    mutation CreateCountry($country: CountryInput!) {
  createCountry(country: $country)
}
    `;
export const useCreateCountryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCountryMutation, TError, CreateCountryMutationVariables, TContext>) => 
    useMutation<CreateCountryMutation, TError, CreateCountryMutationVariables, TContext>(
      (variables?: CreateCountryMutationVariables) => fetcher<CreateCountryMutation, CreateCountryMutationVariables>(CreateCountryDocument, variables)(),
      options
    );
export const UpdateCountryDocument = `
    mutation UpdateCountry($id: ID!, $countryInput: CountryInput!) {
  updateCountry(id: $id, country: $countryInput)
}
    `;
export const useUpdateCountryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCountryMutation, TError, UpdateCountryMutationVariables, TContext>) => 
    useMutation<UpdateCountryMutation, TError, UpdateCountryMutationVariables, TContext>(
      (variables?: UpdateCountryMutationVariables) => fetcher<UpdateCountryMutation, UpdateCountryMutationVariables>(UpdateCountryDocument, variables)(),
      options
    );
export const DeleteCountryDocument = `
    mutation DeleteCountry($id: ID!) {
  deleteCountry(id: $id)
}
    `;
export const useDeleteCountryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCountryMutation, TError, DeleteCountryMutationVariables, TContext>) => 
    useMutation<DeleteCountryMutation, TError, DeleteCountryMutationVariables, TContext>(
      (variables?: DeleteCountryMutationVariables) => fetcher<DeleteCountryMutation, DeleteCountryMutationVariables>(DeleteCountryDocument, variables)(),
      options
    );
export const GetCountriesTableDocument = `
    query GetCountriesTable($language: String!, $filters: CountryFilters, $pagination: Pagination) @app(id: COUNTRIES) {
  countries(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  countriesCount(filters: $filters)
}
    `;
export const useGetCountriesTableQuery = <
      TData = GetCountriesTableQuery,
      TError = unknown
    >(
      variables: GetCountriesTableQueryVariables, 
      options?: UseQueryOptions<GetCountriesTableQuery, TError, TData>
    ) => 
    useQuery<GetCountriesTableQuery, TError, TData>(
      ['GetCountriesTable', variables],
      fetcher<GetCountriesTableQuery, GetCountriesTableQueryVariables>(GetCountriesTableDocument, variables),
      options
    );
export const GetCountryViewPageDocument = `
    query GetCountryViewPage($id: ID!) @app(id: COUNTRIES) {
  country(id: $id) {
    id
    translations {
      language
      description
    }
    regions {
      id
      translations {
        language
        description
      }
    }
  }
}
    `;
export const useGetCountryViewPageQuery = <
      TData = GetCountryViewPageQuery,
      TError = unknown
    >(
      variables: GetCountryViewPageQueryVariables, 
      options?: UseQueryOptions<GetCountryViewPageQuery, TError, TData>
    ) => 
    useQuery<GetCountryViewPageQuery, TError, TData>(
      ['GetCountryViewPage', variables],
      fetcher<GetCountryViewPageQuery, GetCountryViewPageQueryVariables>(GetCountryViewPageDocument, variables),
      options
    );
export const CurrencyExistsDocument = `
    query CurrencyExists($id: ID!) @app(id: CURRENCIES) {
  currencyExists(id: $id)
}
    `;
export const useCurrencyExistsQuery = <
      TData = CurrencyExistsQuery,
      TError = unknown
    >(
      variables: CurrencyExistsQueryVariables, 
      options?: UseQueryOptions<CurrencyExistsQuery, TError, TData>
    ) => 
    useQuery<CurrencyExistsQuery, TError, TData>(
      ['CurrencyExists', variables],
      fetcher<CurrencyExistsQuery, CurrencyExistsQueryVariables>(CurrencyExistsDocument, variables),
      options
    );
export const CreateCurrencyDocument = `
    mutation CreateCurrency($currency: CurrencyInput!) {
  createCurrency(currency: $currency)
}
    `;
export const useCreateCurrencyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCurrencyMutation, TError, CreateCurrencyMutationVariables, TContext>) => 
    useMutation<CreateCurrencyMutation, TError, CreateCurrencyMutationVariables, TContext>(
      (variables?: CreateCurrencyMutationVariables) => fetcher<CreateCurrencyMutation, CreateCurrencyMutationVariables>(CreateCurrencyDocument, variables)(),
      options
    );
export const UpdateCurrencyDocument = `
    mutation UpdateCurrency($id: ID!, $currency: CurrencyInput!) {
  updateCurrency(id: $id, currency: $currency)
}
    `;
export const useUpdateCurrencyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCurrencyMutation, TError, UpdateCurrencyMutationVariables, TContext>) => 
    useMutation<UpdateCurrencyMutation, TError, UpdateCurrencyMutationVariables, TContext>(
      (variables?: UpdateCurrencyMutationVariables) => fetcher<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UpdateCurrencyDocument, variables)(),
      options
    );
export const DeleteCurrencyDocument = `
    mutation DeleteCurrency($id: ID!) {
  deleteCurrency(id: $id)
}
    `;
export const useDeleteCurrencyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCurrencyMutation, TError, DeleteCurrencyMutationVariables, TContext>) => 
    useMutation<DeleteCurrencyMutation, TError, DeleteCurrencyMutationVariables, TContext>(
      (variables?: DeleteCurrencyMutationVariables) => fetcher<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>(DeleteCurrencyDocument, variables)(),
      options
    );
export const GetCurrencies_ViewAllPageDocument = `
    query GetCurrencies_ViewAllPage($language: String!, $filters: CurrencyFilters, $pagination: Pagination) @app(id: CURRENCIES) {
  currencies(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  currenciesCount(filters: $filters)
}
    `;
export const useGetCurrencies_ViewAllPageQuery = <
      TData = GetCurrencies_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetCurrencies_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetCurrencies_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetCurrencies_ViewAllPageQuery, TError, TData>(
      ['GetCurrencies_ViewAllPage', variables],
      fetcher<GetCurrencies_ViewAllPageQuery, GetCurrencies_ViewAllPageQueryVariables>(GetCurrencies_ViewAllPageDocument, variables),
      options
    );
export const GetCurrency_ViewPageDocument = `
    query GetCurrency_ViewPage($id: ID!) @app(id: CURRENCIES) {
  currency(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetCurrency_ViewPageQuery = <
      TData = GetCurrency_ViewPageQuery,
      TError = unknown
    >(
      variables: GetCurrency_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetCurrency_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetCurrency_ViewPageQuery, TError, TData>(
      ['GetCurrency_ViewPage', variables],
      fetcher<GetCurrency_ViewPageQuery, GetCurrency_ViewPageQueryVariables>(GetCurrency_ViewPageDocument, variables),
      options
    );
export const DataElementExistsDocument = `
    query DataElementExists($id: ID!) @app(id: DATA_ELEMENTS) {
  dataElementExists(id: $id)
}
    `;
export const useDataElementExistsQuery = <
      TData = DataElementExistsQuery,
      TError = unknown
    >(
      variables: DataElementExistsQueryVariables, 
      options?: UseQueryOptions<DataElementExistsQuery, TError, TData>
    ) => 
    useQuery<DataElementExistsQuery, TError, TData>(
      ['DataElementExists', variables],
      fetcher<DataElementExistsQuery, DataElementExistsQueryVariables>(DataElementExistsDocument, variables),
      options
    );
export const CreateDataElementDocument = `
    mutation CreateDataElement($dataElement: DataElementInput!) {
  createDataElement(dataElement: $dataElement)
}
    `;
export const useCreateDataElementMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDataElementMutation, TError, CreateDataElementMutationVariables, TContext>) => 
    useMutation<CreateDataElementMutation, TError, CreateDataElementMutationVariables, TContext>(
      (variables?: CreateDataElementMutationVariables) => fetcher<CreateDataElementMutation, CreateDataElementMutationVariables>(CreateDataElementDocument, variables)(),
      options
    );
export const UpdateDataElementDocument = `
    mutation UpdateDataElement($id: ID!, $dataElement: DataElementInput!) {
  updateDataElement(id: $id, dataElement: $dataElement)
}
    `;
export const useUpdateDataElementMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDataElementMutation, TError, UpdateDataElementMutationVariables, TContext>) => 
    useMutation<UpdateDataElementMutation, TError, UpdateDataElementMutationVariables, TContext>(
      (variables?: UpdateDataElementMutationVariables) => fetcher<UpdateDataElementMutation, UpdateDataElementMutationVariables>(UpdateDataElementDocument, variables)(),
      options
    );
export const DeleteDataElementDocument = `
    mutation DeleteDataElement($id: ID!) {
  deleteDataElement(id: $id)
}
    `;
export const useDeleteDataElementMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteDataElementMutation, TError, DeleteDataElementMutationVariables, TContext>) => 
    useMutation<DeleteDataElementMutation, TError, DeleteDataElementMutationVariables, TContext>(
      (variables?: DeleteDataElementMutationVariables) => fetcher<DeleteDataElementMutation, DeleteDataElementMutationVariables>(DeleteDataElementDocument, variables)(),
      options
    );
export const GetDataElementsTablePageDocument = `
    query GetDataElementsTablePage($language: String!, $filters: DataElementFilters, $pagination: Pagination) @app(id: DATA_ELEMENTS) {
  dataElements(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      language
      description
    }
    status
    dataType
    length
    decimals
    isLanguageDependent
    isUsed
    usedByTables
  }
  dataElementsCount(filters: $filters)
}
    `;
export const useGetDataElementsTablePageQuery = <
      TData = GetDataElementsTablePageQuery,
      TError = unknown
    >(
      variables: GetDataElementsTablePageQueryVariables, 
      options?: UseQueryOptions<GetDataElementsTablePageQuery, TError, TData>
    ) => 
    useQuery<GetDataElementsTablePageQuery, TError, TData>(
      ['GetDataElementsTablePage', variables],
      fetcher<GetDataElementsTablePageQuery, GetDataElementsTablePageQueryVariables>(GetDataElementsTablePageDocument, variables),
      options
    );
export const GetDataElementViewPageDocument = `
    query GetDataElementViewPage($id: ID!) @app(id: DATA_ELEMENTS) {
  dataElement(id: $id) {
    id
    dataType
    length
    decimals
    isLanguageDependent
    isUsed
    status
    translations {
      language
      description
    }
    choices {
      id
      translations {
        language
        description
      }
    }
  }
}
    `;
export const useGetDataElementViewPageQuery = <
      TData = GetDataElementViewPageQuery,
      TError = unknown
    >(
      variables: GetDataElementViewPageQueryVariables, 
      options?: UseQueryOptions<GetDataElementViewPageQuery, TError, TData>
    ) => 
    useQuery<GetDataElementViewPageQuery, TError, TData>(
      ['GetDataElementViewPage', variables],
      fetcher<GetDataElementViewPageQuery, GetDataElementViewPageQueryVariables>(GetDataElementViewPageDocument, variables),
      options
    );
export const EmployeeExistsDocument = `
    query EmployeeExists($id: ID!) @app(id: EMPLOYEES) {
  employeeExists(id: $id)
}
    `;
export const useEmployeeExistsQuery = <
      TData = EmployeeExistsQuery,
      TError = unknown
    >(
      variables: EmployeeExistsQueryVariables, 
      options?: UseQueryOptions<EmployeeExistsQuery, TError, TData>
    ) => 
    useQuery<EmployeeExistsQuery, TError, TData>(
      ['EmployeeExists', variables],
      fetcher<EmployeeExistsQuery, EmployeeExistsQueryVariables>(EmployeeExistsDocument, variables),
      options
    );
export const FindBusinessPartnerForCreatingEmployeeDocument = `
    query FindBusinessPartnerForCreatingEmployee @app(id: EMPLOYEES) {
  businessPartners {
    id
    name
  }
}
    `;
export const useFindBusinessPartnerForCreatingEmployeeQuery = <
      TData = FindBusinessPartnerForCreatingEmployeeQuery,
      TError = unknown
    >(
      variables?: FindBusinessPartnerForCreatingEmployeeQueryVariables, 
      options?: UseQueryOptions<FindBusinessPartnerForCreatingEmployeeQuery, TError, TData>
    ) => 
    useQuery<FindBusinessPartnerForCreatingEmployeeQuery, TError, TData>(
      ['FindBusinessPartnerForCreatingEmployee', variables],
      fetcher<FindBusinessPartnerForCreatingEmployeeQuery, FindBusinessPartnerForCreatingEmployeeQueryVariables>(FindBusinessPartnerForCreatingEmployeeDocument, variables),
      options
    );
export const GetUsersForCreatingEmployeeDocument = `
    query GetUsersForCreatingEmployee($filters: UsersFilters, $pagination: Pagination) @app(id: EMPLOYEES) {
  users(filters: $filters, pagination: $pagination) {
    id
    email
    firstName
    lastName
    phoneNumber
  }
  usersCount(filters: $filters)
}
    `;
export const useGetUsersForCreatingEmployeeQuery = <
      TData = GetUsersForCreatingEmployeeQuery,
      TError = unknown
    >(
      variables?: GetUsersForCreatingEmployeeQueryVariables, 
      options?: UseQueryOptions<GetUsersForCreatingEmployeeQuery, TError, TData>
    ) => 
    useQuery<GetUsersForCreatingEmployeeQuery, TError, TData>(
      ['GetUsersForCreatingEmployee', variables],
      fetcher<GetUsersForCreatingEmployeeQuery, GetUsersForCreatingEmployeeQueryVariables>(GetUsersForCreatingEmployeeDocument, variables),
      options
    );
export const GetUserForCreatingEmployeeDocument = `
    query GetUserForCreatingEmployee($id: ID!) @app(id: EMPLOYEES) {
  user(id: $id) {
    id
    firstName
    lastName
    phoneNumber
    email
  }
}
    `;
export const useGetUserForCreatingEmployeeQuery = <
      TData = GetUserForCreatingEmployeeQuery,
      TError = unknown
    >(
      variables: GetUserForCreatingEmployeeQueryVariables, 
      options?: UseQueryOptions<GetUserForCreatingEmployeeQuery, TError, TData>
    ) => 
    useQuery<GetUserForCreatingEmployeeQuery, TError, TData>(
      ['GetUserForCreatingEmployee', variables],
      fetcher<GetUserForCreatingEmployeeQuery, GetUserForCreatingEmployeeQueryVariables>(GetUserForCreatingEmployeeDocument, variables),
      options
    );
export const GetAccountingEntitiesForEmployessFormDocument = `
    query GetAccountingEntitiesForEmployessForm($filters: AccountingEntityFilters, $pagination: Pagination) @app(id: EMPLOYEES) {
  accountingEntities(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    address {
      street
      number
      floor
      department
      city
      postalCode
      state
      country
    }
  }
  accountingEntitiesCount(filters: $filters)
}
    `;
export const useGetAccountingEntitiesForEmployessFormQuery = <
      TData = GetAccountingEntitiesForEmployessFormQuery,
      TError = unknown
    >(
      variables?: GetAccountingEntitiesForEmployessFormQueryVariables, 
      options?: UseQueryOptions<GetAccountingEntitiesForEmployessFormQuery, TError, TData>
    ) => 
    useQuery<GetAccountingEntitiesForEmployessFormQuery, TError, TData>(
      ['GetAccountingEntitiesForEmployessForm', variables],
      fetcher<GetAccountingEntitiesForEmployessFormQuery, GetAccountingEntitiesForEmployessFormQueryVariables>(GetAccountingEntitiesForEmployessFormDocument, variables),
      options
    );
export const GetPositionsForCreateEmployeeDocument = `
    query GetPositionsForCreateEmployee($language: String!) @app(id: EMPLOYEES) {
  positions {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetPositionsForCreateEmployeeQuery = <
      TData = GetPositionsForCreateEmployeeQuery,
      TError = unknown
    >(
      variables: GetPositionsForCreateEmployeeQueryVariables, 
      options?: UseQueryOptions<GetPositionsForCreateEmployeeQuery, TError, TData>
    ) => 
    useQuery<GetPositionsForCreateEmployeeQuery, TError, TData>(
      ['GetPositionsForCreateEmployee', variables],
      fetcher<GetPositionsForCreateEmployeeQuery, GetPositionsForCreateEmployeeQueryVariables>(GetPositionsForCreateEmployeeDocument, variables),
      options
    );
export const CreateEmployeeDocument = `
    mutation CreateEmployee($employee: EmployeeInput!) {
  createEmployee(employee: $employee)
}
    `;
export const useCreateEmployeeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEmployeeMutation, TError, CreateEmployeeMutationVariables, TContext>) => 
    useMutation<CreateEmployeeMutation, TError, CreateEmployeeMutationVariables, TContext>(
      (variables?: CreateEmployeeMutationVariables) => fetcher<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, variables)(),
      options
    );
export const UpdateEmployeeDocument = `
    mutation UpdateEmployee($id: ID!, $employee: EmployeeInput!) {
  updateEmployee(id: $id, employee: $employee)
}
    `;
export const useUpdateEmployeeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateEmployeeMutation, TError, UpdateEmployeeMutationVariables, TContext>) => 
    useMutation<UpdateEmployeeMutation, TError, UpdateEmployeeMutationVariables, TContext>(
      (variables?: UpdateEmployeeMutationVariables) => fetcher<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, variables)(),
      options
    );
export const DeleteEmployeeDocument = `
    mutation DeleteEmployee($id: ID!) {
  deleteEmployee(id: $id)
}
    `;
export const useDeleteEmployeeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteEmployeeMutation, TError, DeleteEmployeeMutationVariables, TContext>) => 
    useMutation<DeleteEmployeeMutation, TError, DeleteEmployeeMutationVariables, TContext>(
      (variables?: DeleteEmployeeMutationVariables) => fetcher<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, variables)(),
      options
    );
export const GetEmployees_ViewAllPageDocument = `
    query GetEmployees_ViewAllPage($filters: EmployeesFilters, $pagination: Pagination) @app(id: EMPLOYEES) {
  employees(filters: $filters, pagination: $pagination) {
    id
    type
    businessPartnerId
    accountingEntityId
    positionHistory {
      positionId
      startDate
    }
    contact {
      user {
        id
        firstName
        lastName
        phoneNumber
        email
      }
      email
      name
      lastName
      phoneNumber
    }
  }
  employeesCount(filters: $filters)
}
    `;
export const useGetEmployees_ViewAllPageQuery = <
      TData = GetEmployees_ViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetEmployees_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetEmployees_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetEmployees_ViewAllPageQuery, TError, TData>(
      ['GetEmployees_ViewAllPage', variables],
      fetcher<GetEmployees_ViewAllPageQuery, GetEmployees_ViewAllPageQueryVariables>(GetEmployees_ViewAllPageDocument, variables),
      options
    );
export const GetEmployee_ViewPageDocument = `
    query GetEmployee_ViewPage($id: ID!) @app(id: EMPLOYEES) {
  employee(id: $id) {
    id
    type
    businessPartnerId
    accountingEntityId
    positionHistory {
      positionId
      startDate
    }
    contact {
      user {
        id
        firstName
        lastName
        phoneNumber
        email
      }
      email
      name
      lastName
      phoneNumber
    }
  }
}
    `;
export const useGetEmployee_ViewPageQuery = <
      TData = GetEmployee_ViewPageQuery,
      TError = unknown
    >(
      variables: GetEmployee_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetEmployee_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetEmployee_ViewPageQuery, TError, TData>(
      ['GetEmployee_ViewPage', variables],
      fetcher<GetEmployee_ViewPageQuery, GetEmployee_ViewPageQueryVariables>(GetEmployee_ViewPageDocument, variables),
      options
    );
export const GetBusinessObjectsForCreateDataMigrationsDocument = `
    query GetBusinessObjectsForCreateDataMigrations @app(id: EXPORT_MIGRATIONS_PACKAGES) {
  businessObjects {
    id
  }
}
    `;
export const useGetBusinessObjectsForCreateDataMigrationsQuery = <
      TData = GetBusinessObjectsForCreateDataMigrationsQuery,
      TError = unknown
    >(
      variables?: GetBusinessObjectsForCreateDataMigrationsQueryVariables, 
      options?: UseQueryOptions<GetBusinessObjectsForCreateDataMigrationsQuery, TError, TData>
    ) => 
    useQuery<GetBusinessObjectsForCreateDataMigrationsQuery, TError, TData>(
      ['GetBusinessObjectsForCreateDataMigrations', variables],
      fetcher<GetBusinessObjectsForCreateDataMigrationsQuery, GetBusinessObjectsForCreateDataMigrationsQueryVariables>(GetBusinessObjectsForCreateDataMigrationsDocument, variables),
      options
    );
export const GetIdsInBusinessObjectForCreateMigrationPackageDocument = `
    query GetIdsInBusinessObjectForCreateMigrationPackage($businessObjectId: ID!) @app(id: BUSINESS_PROFILES) {
  idsInBusinessObject(businessObjectId: $businessObjectId)
}
    `;
export const useGetIdsInBusinessObjectForCreateMigrationPackageQuery = <
      TData = GetIdsInBusinessObjectForCreateMigrationPackageQuery,
      TError = unknown
    >(
      variables: GetIdsInBusinessObjectForCreateMigrationPackageQueryVariables, 
      options?: UseQueryOptions<GetIdsInBusinessObjectForCreateMigrationPackageQuery, TError, TData>
    ) => 
    useQuery<GetIdsInBusinessObjectForCreateMigrationPackageQuery, TError, TData>(
      ['GetIdsInBusinessObjectForCreateMigrationPackage', variables],
      fetcher<GetIdsInBusinessObjectForCreateMigrationPackageQuery, GetIdsInBusinessObjectForCreateMigrationPackageQueryVariables>(GetIdsInBusinessObjectForCreateMigrationPackageDocument, variables),
      options
    );
export const CreateMigrationPackageInputDocument = `
    mutation CreateMigrationPackageInput($migrationPackage: MigrationPackageInput!) {
  createMigrationPackage(migrationPackage: $migrationPackage)
}
    `;
export const useCreateMigrationPackageInputMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateMigrationPackageInputMutation, TError, CreateMigrationPackageInputMutationVariables, TContext>) => 
    useMutation<CreateMigrationPackageInputMutation, TError, CreateMigrationPackageInputMutationVariables, TContext>(
      (variables?: CreateMigrationPackageInputMutationVariables) => fetcher<CreateMigrationPackageInputMutation, CreateMigrationPackageInputMutationVariables>(CreateMigrationPackageInputDocument, variables)(),
      options
    );
export const UpdateMigrationPackageInputDocument = `
    mutation UpdateMigrationPackageInput($id: ID!, $migrationPackage: MigrationPackageInput!) {
  updateMigrationPackage(id: $id, migrationPackage: $migrationPackage)
}
    `;
export const useUpdateMigrationPackageInputMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMigrationPackageInputMutation, TError, UpdateMigrationPackageInputMutationVariables, TContext>) => 
    useMutation<UpdateMigrationPackageInputMutation, TError, UpdateMigrationPackageInputMutationVariables, TContext>(
      (variables?: UpdateMigrationPackageInputMutationVariables) => fetcher<UpdateMigrationPackageInputMutation, UpdateMigrationPackageInputMutationVariables>(UpdateMigrationPackageInputDocument, variables)(),
      options
    );
export const DeleteMigrationPackageDocument = `
    mutation DeleteMigrationPackage($id: ID!) {
  deleteMigrationPackage(id: $id)
}
    `;
export const useDeleteMigrationPackageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMigrationPackageMutation, TError, DeleteMigrationPackageMutationVariables, TContext>) => 
    useMutation<DeleteMigrationPackageMutation, TError, DeleteMigrationPackageMutationVariables, TContext>(
      (variables?: DeleteMigrationPackageMutationVariables) => fetcher<DeleteMigrationPackageMutation, DeleteMigrationPackageMutationVariables>(DeleteMigrationPackageDocument, variables)(),
      options
    );
export const PublishMigrationPackageDocument = `
    mutation PublishMigrationPackage($id: ID!) {
  publishMigrationPackage(id: $id)
}
    `;
export const usePublishMigrationPackageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishMigrationPackageMutation, TError, PublishMigrationPackageMutationVariables, TContext>) => 
    useMutation<PublishMigrationPackageMutation, TError, PublishMigrationPackageMutationVariables, TContext>(
      (variables?: PublishMigrationPackageMutationVariables) => fetcher<PublishMigrationPackageMutation, PublishMigrationPackageMutationVariables>(PublishMigrationPackageDocument, variables)(),
      options
    );
export const GetOwnMigrationPackagesForTableDocument = `
    query GetOwnMigrationPackagesForTable($pagination: Pagination!) @app(id: EXPORT_MIGRATIONS_PACKAGES) {
  ownMigrationPackages(pagination: $pagination) {
    packageId
    description
    status
    publishedAt
  }
  ownMigrationPackagesCount
}
    `;
export const useGetOwnMigrationPackagesForTableQuery = <
      TData = GetOwnMigrationPackagesForTableQuery,
      TError = unknown
    >(
      variables: GetOwnMigrationPackagesForTableQueryVariables, 
      options?: UseQueryOptions<GetOwnMigrationPackagesForTableQuery, TError, TData>
    ) => 
    useQuery<GetOwnMigrationPackagesForTableQuery, TError, TData>(
      ['GetOwnMigrationPackagesForTable', variables],
      fetcher<GetOwnMigrationPackagesForTableQuery, GetOwnMigrationPackagesForTableQueryVariables>(GetOwnMigrationPackagesForTableDocument, variables),
      options
    );
export const GetOwnMigrationPackageForPageViewDocument = `
    query GetOwnMigrationPackageForPageView($id: ID!) @app(id: EXPORT_MIGRATIONS_PACKAGES) {
  ownMigrationPackage(id: $id) {
    description
    status
    definition {
      ids
      businessObjectId
    }
  }
}
    `;
export const useGetOwnMigrationPackageForPageViewQuery = <
      TData = GetOwnMigrationPackageForPageViewQuery,
      TError = unknown
    >(
      variables: GetOwnMigrationPackageForPageViewQueryVariables, 
      options?: UseQueryOptions<GetOwnMigrationPackageForPageViewQuery, TError, TData>
    ) => 
    useQuery<GetOwnMigrationPackageForPageViewQuery, TError, TData>(
      ['GetOwnMigrationPackageForPageView', variables],
      fetcher<GetOwnMigrationPackageForPageViewQuery, GetOwnMigrationPackageForPageViewQueryVariables>(GetOwnMigrationPackageForPageViewDocument, variables),
      options
    );
export const GroupExistsDocument = `
    query GroupExists($groupId: ID!) @app(id: GROUPS) {
  groupExists(id: $groupId)
}
    `;
export const useGroupExistsQuery = <
      TData = GroupExistsQuery,
      TError = unknown
    >(
      variables: GroupExistsQueryVariables, 
      options?: UseQueryOptions<GroupExistsQuery, TError, TData>
    ) => 
    useQuery<GroupExistsQuery, TError, TData>(
      ['GroupExists', variables],
      fetcher<GroupExistsQuery, GroupExistsQueryVariables>(GroupExistsDocument, variables),
      options
    );
export const GetTilesForGroupFormDocument = `
    query GetTilesForGroupForm($language: String!, $filters: TilesFilters) @app(id: GROUPS) {
  tiles(filters: $filters) {
    id
    translation(language: $language) {
      language
      title
      subtitle
    }
    shape
  }
}
    `;
export const useGetTilesForGroupFormQuery = <
      TData = GetTilesForGroupFormQuery,
      TError = unknown
    >(
      variables: GetTilesForGroupFormQueryVariables, 
      options?: UseQueryOptions<GetTilesForGroupFormQuery, TError, TData>
    ) => 
    useQuery<GetTilesForGroupFormQuery, TError, TData>(
      ['GetTilesForGroupForm', variables],
      fetcher<GetTilesForGroupFormQuery, GetTilesForGroupFormQueryVariables>(GetTilesForGroupFormDocument, variables),
      options
    );
export const GetBusinessObjectsForGroupFormDocument = `
    query GetBusinessObjectsForGroupForm($language: String!) @app(id: GROUPS) {
  businessObjects {
    dataElement {
      id
      translation(language: $language) {
        description
      }
    }
  }
}
    `;
export const useGetBusinessObjectsForGroupFormQuery = <
      TData = GetBusinessObjectsForGroupFormQuery,
      TError = unknown
    >(
      variables: GetBusinessObjectsForGroupFormQueryVariables, 
      options?: UseQueryOptions<GetBusinessObjectsForGroupFormQuery, TError, TData>
    ) => 
    useQuery<GetBusinessObjectsForGroupFormQuery, TError, TData>(
      ['GetBusinessObjectsForGroupForm', variables],
      fetcher<GetBusinessObjectsForGroupFormQuery, GetBusinessObjectsForGroupFormQueryVariables>(GetBusinessObjectsForGroupFormDocument, variables),
      options
    );
export const CreateGroupDocument = `
    mutation CreateGroup($group: GroupInput!) {
  createGroup(group: $group)
}
    `;
export const useCreateGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateGroupMutation, TError, CreateGroupMutationVariables, TContext>) => 
    useMutation<CreateGroupMutation, TError, CreateGroupMutationVariables, TContext>(
      (variables?: CreateGroupMutationVariables) => fetcher<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, variables)(),
      options
    );
export const RequestPermissionUploadGroupIconToS3Document = `
    mutation RequestPermissionUploadGroupIconToS3 {
  requestPermissionUploadGroupIconToS3 {
    url
    bucket
    key
  }
}
    `;
export const useRequestPermissionUploadGroupIconToS3Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestPermissionUploadGroupIconToS3Mutation, TError, RequestPermissionUploadGroupIconToS3MutationVariables, TContext>) => 
    useMutation<RequestPermissionUploadGroupIconToS3Mutation, TError, RequestPermissionUploadGroupIconToS3MutationVariables, TContext>(
      (variables?: RequestPermissionUploadGroupIconToS3MutationVariables) => fetcher<RequestPermissionUploadGroupIconToS3Mutation, RequestPermissionUploadGroupIconToS3MutationVariables>(RequestPermissionUploadGroupIconToS3Document, variables)(),
      options
    );
export const UpdateGroupDocument = `
    mutation UpdateGroup($id: ID!, $group: GroupInput!) {
  updateGroup(id: $id, group: $group)
}
    `;
export const useUpdateGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateGroupMutation, TError, UpdateGroupMutationVariables, TContext>) => 
    useMutation<UpdateGroupMutation, TError, UpdateGroupMutationVariables, TContext>(
      (variables?: UpdateGroupMutationVariables) => fetcher<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, variables)(),
      options
    );
export const DeleteGroupDocument = `
    mutation DeleteGroup($id: ID!) {
  deleteGroup(id: $id)
}
    `;
export const useDeleteGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteGroupMutation, TError, DeleteGroupMutationVariables, TContext>) => 
    useMutation<DeleteGroupMutation, TError, DeleteGroupMutationVariables, TContext>(
      (variables?: DeleteGroupMutationVariables) => fetcher<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, variables)(),
      options
    );
export const GetGroupsForTablePageDocument = `
    query GetGroupsForTablePage($language: String!, $filters: GroupsFilters, $pagination: Pagination) @app(id: GROUPS) {
  groups(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
      language
    }
  }
  groupsCount(filters: $filters)
}
    `;
export const useGetGroupsForTablePageQuery = <
      TData = GetGroupsForTablePageQuery,
      TError = unknown
    >(
      variables: GetGroupsForTablePageQueryVariables, 
      options?: UseQueryOptions<GetGroupsForTablePageQuery, TError, TData>
    ) => 
    useQuery<GetGroupsForTablePageQuery, TError, TData>(
      ['GetGroupsForTablePage', variables],
      fetcher<GetGroupsForTablePageQuery, GetGroupsForTablePageQueryVariables>(GetGroupsForTablePageDocument, variables),
      options
    );
export const GetGroupForViewPageDocument = `
    query GetGroupForViewPage($id: ID!) @app(id: GROUPS) {
  group(id: $id) {
    id
    translations {
      description
      language
    }
    tiles {
      id
    }
    icon {
      id
      name
      url
    }
  }
}
    `;
export const useGetGroupForViewPageQuery = <
      TData = GetGroupForViewPageQuery,
      TError = unknown
    >(
      variables: GetGroupForViewPageQueryVariables, 
      options?: UseQueryOptions<GetGroupForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetGroupForViewPageQuery, TError, TData>(
      ['GetGroupForViewPage', variables],
      fetcher<GetGroupForViewPageQuery, GetGroupForViewPageQueryVariables>(GetGroupForViewPageDocument, variables),
      options
    );
export const ApplyMigrationPackageDocument = `
    mutation ApplyMigrationPackage($systemId: ID!, $packageId: ID!) {
  applyMigrationPackage(systemId: $systemId, packageId: $packageId)
}
    `;
export const useApplyMigrationPackageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ApplyMigrationPackageMutation, TError, ApplyMigrationPackageMutationVariables, TContext>) => 
    useMutation<ApplyMigrationPackageMutation, TError, ApplyMigrationPackageMutationVariables, TContext>(
      (variables?: ApplyMigrationPackageMutationVariables) => fetcher<ApplyMigrationPackageMutation, ApplyMigrationPackageMutationVariables>(ApplyMigrationPackageDocument, variables)(),
      options
    );
export const GetExternalMigrationPackagesForTableDocument = `
    query GetExternalMigrationPackagesForTable($pagination: Pagination!) @app(id: IMPORT_MIGRATIONS_PACKAGES) {
  externalMigrationPackages(pagination: $pagination) {
    systemId
    packageId
    description
    isApplied
    appliedAt
    publishedAt
  }
  externalMigrationPackagesCount
}
    `;
export const useGetExternalMigrationPackagesForTableQuery = <
      TData = GetExternalMigrationPackagesForTableQuery,
      TError = unknown
    >(
      variables: GetExternalMigrationPackagesForTableQueryVariables, 
      options?: UseQueryOptions<GetExternalMigrationPackagesForTableQuery, TError, TData>
    ) => 
    useQuery<GetExternalMigrationPackagesForTableQuery, TError, TData>(
      ['GetExternalMigrationPackagesForTable', variables],
      fetcher<GetExternalMigrationPackagesForTableQuery, GetExternalMigrationPackagesForTableQueryVariables>(GetExternalMigrationPackagesForTableDocument, variables),
      options
    );
export const GetDeliveryPurchaseOrderItemForDirectPageDocument = `
    query GetDeliveryPurchaseOrderItemForDirectPage @app(id: PURCHASE_ORDERS_ITEMS) {
  deliveryPurchaseOrderItems {
    purchaseOrderItem
    purchaseOrder
  }
}
    `;
export const useGetDeliveryPurchaseOrderItemForDirectPageQuery = <
      TData = GetDeliveryPurchaseOrderItemForDirectPageQuery,
      TError = unknown
    >(
      variables?: GetDeliveryPurchaseOrderItemForDirectPageQueryVariables, 
      options?: UseQueryOptions<GetDeliveryPurchaseOrderItemForDirectPageQuery, TError, TData>
    ) => 
    useQuery<GetDeliveryPurchaseOrderItemForDirectPageQuery, TError, TData>(
      ['GetDeliveryPurchaseOrderItemForDirectPage', variables],
      fetcher<GetDeliveryPurchaseOrderItemForDirectPageQuery, GetDeliveryPurchaseOrderItemForDirectPageQueryVariables>(GetDeliveryPurchaseOrderItemForDirectPageDocument, variables),
      options
    );
export const GetAllInvoicesDocument = `
    query GetAllInvoices @app(id: INVOICES) {
  invoices {
    id
    invoiceVendorNumber
    invoiceBackendNumber
  }
}
    `;
export const useGetAllInvoicesQuery = <
      TData = GetAllInvoicesQuery,
      TError = unknown
    >(
      variables?: GetAllInvoicesQueryVariables, 
      options?: UseQueryOptions<GetAllInvoicesQuery, TError, TData>
    ) => 
    useQuery<GetAllInvoicesQuery, TError, TData>(
      ['GetAllInvoices', variables],
      fetcher<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(GetAllInvoicesDocument, variables),
      options
    );
export const GetDeliveryPurchaseOrdersItemForInvoiceFreightPageDocument = `
    query GetDeliveryPurchaseOrdersItemForInvoiceFreightPage($filters: PurchaseOrderItemFilters, $pagination: Pagination) @app(id: PURCHASE_ORDERS_ITEMS) {
  deliveryPurchaseOrderItems(pagination: $pagination, filters: $filters) {
    purchaseOrder
    purchaseOrderItem
    productId
    productDescription
    hasInvoice
    locationDescription
    unit
    poQuantity
    netUnitPrice
    netTotalPrice
    grQuantity
    grAmount
    picInvoicedQuantity
    picInvoicedAmount
    invoiceApprovedQuantity
    invoiceApprovedAmount
    needGr
    completeInvoice
  }
  deliveryPurchaseOrderItemsCount(filters: $filters)
}
    `;
export const useGetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery = <
      TData = GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery,
      TError = unknown
    >(
      variables?: GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQueryVariables, 
      options?: UseQueryOptions<GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery, TError, TData>
    ) => 
    useQuery<GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery, TError, TData>(
      ['GetDeliveryPurchaseOrdersItemForInvoiceFreightPage', variables],
      fetcher<GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery, GetDeliveryPurchaseOrdersItemForInvoiceFreightPageQueryVariables>(GetDeliveryPurchaseOrdersItemForInvoiceFreightPageDocument, variables),
      options
    );
export const GetInvoicesReportPageDocument = `
    query GetInvoicesReportPage($filters: InvoiceFilters, $pagination: Pagination) @app(id: INVOICES_REPORTS) {
  invoices(filters: $filters, pagination: $pagination) {
    id
    purchaseOrder
    purchaseOrderItem
    accountingEntity
    vendorId
    currency
    invoiceStatus
    invoiceDate
    invoiceVendorNumber
    invoiceBackendNumber
    fiscalYear
    headerText
    invoiceDate
    plannedPaymentDate
    lastPaymentDate
    rejectionReason
    total
    file {
      id
      name
      url
    }
  }
  invoicesCount(filters: $filters)
}
    `;
export const useGetInvoicesReportPageQuery = <
      TData = GetInvoicesReportPageQuery,
      TError = unknown
    >(
      variables?: GetInvoicesReportPageQueryVariables, 
      options?: UseQueryOptions<GetInvoicesReportPageQuery, TError, TData>
    ) => 
    useQuery<GetInvoicesReportPageQuery, TError, TData>(
      ['GetInvoicesReportPage', variables],
      fetcher<GetInvoicesReportPageQuery, GetInvoicesReportPageQueryVariables>(GetInvoicesReportPageDocument, variables),
      options
    );
export const GetInvoiceForExtraInformationFormDocument = `
    query GetInvoiceForExtraInformationForm($id: ID!) @app(id: INVOICES) {
  invoice(id: $id) {
    id
    invoiceBackendNumber
    fiscalYear
    currency
    plannedPaymentDate
    lastPaymentDate
    invoiceStatus
    rejectionReason
  }
}
    `;
export const useGetInvoiceForExtraInformationFormQuery = <
      TData = GetInvoiceForExtraInformationFormQuery,
      TError = unknown
    >(
      variables: GetInvoiceForExtraInformationFormQueryVariables, 
      options?: UseQueryOptions<GetInvoiceForExtraInformationFormQuery, TError, TData>
    ) => 
    useQuery<GetInvoiceForExtraInformationFormQuery, TError, TData>(
      ['GetInvoiceForExtraInformationForm', variables],
      fetcher<GetInvoiceForExtraInformationFormQuery, GetInvoiceForExtraInformationFormQueryVariables>(GetInvoiceForExtraInformationFormDocument, variables),
      options
    );
export const GetPurchaseOrderExtraChargesDocument = `
    query GetPurchaseOrderExtraCharges($purchaseOrder: ID!) @app(id: INVOICES) {
  purchaseOrder(id: $purchaseOrder) {
    extraCharges
  }
}
    `;
export const useGetPurchaseOrderExtraChargesQuery = <
      TData = GetPurchaseOrderExtraChargesQuery,
      TError = unknown
    >(
      variables: GetPurchaseOrderExtraChargesQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrderExtraChargesQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrderExtraChargesQuery, TError, TData>(
      ['GetPurchaseOrderExtraCharges', variables],
      fetcher<GetPurchaseOrderExtraChargesQuery, GetPurchaseOrderExtraChargesQueryVariables>(GetPurchaseOrderExtraChargesDocument, variables),
      options
    );
export const GetPurchaseOrdersItemsForCreateInvoiceDocument = `
    query GetPurchaseOrdersItemsForCreateInvoice($purchaseOrder: String!) @app(id: INVOICES) {
  purchaseOrderItems(purchaseOrder: $purchaseOrder) {
    purchaseOrderItem
    backend
    productId
    productDescription
    unit
    poQuantity
    netUnitPrice
    netTotalPrice
    grQuantity
    grAmount
    picInvoicedQuantity
    picInvoicedAmount
    invoiceApprovedQuantity
    invoiceApprovedAmount
    remainingQuantity
    remainingAmount
    needGr
    tolerance
    tolerancePercentage
    toleranceAmount
  }
}
    `;
export const useGetPurchaseOrdersItemsForCreateInvoiceQuery = <
      TData = GetPurchaseOrdersItemsForCreateInvoiceQuery,
      TError = unknown
    >(
      variables: GetPurchaseOrdersItemsForCreateInvoiceQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrdersItemsForCreateInvoiceQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrdersItemsForCreateInvoiceQuery, TError, TData>(
      ['GetPurchaseOrdersItemsForCreateInvoice', variables],
      fetcher<GetPurchaseOrdersItemsForCreateInvoiceQuery, GetPurchaseOrdersItemsForCreateInvoiceQueryVariables>(GetPurchaseOrdersItemsForCreateInvoiceDocument, variables),
      options
    );
export const CreateInvoiceDocument = `
    mutation createInvoice($invoice: InvoiceInput!) {
  createInvoice(invoice: $invoice)
}
    `;
export const useCreateInvoiceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateInvoiceMutation, TError, CreateInvoiceMutationVariables, TContext>) => 
    useMutation<CreateInvoiceMutation, TError, CreateInvoiceMutationVariables, TContext>(
      (variables?: CreateInvoiceMutationVariables) => fetcher<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, variables)(),
      options
    );
export const RequestPermissionUploadInvoiceFileToS3Document = `
    mutation requestPermissionUploadInvoiceFileToS3 {
  requestPermissionUploadInvoiceFileToS3 {
    url
    bucket
    key
  }
}
    `;
export const useRequestPermissionUploadInvoiceFileToS3Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestPermissionUploadInvoiceFileToS3Mutation, TError, RequestPermissionUploadInvoiceFileToS3MutationVariables, TContext>) => 
    useMutation<RequestPermissionUploadInvoiceFileToS3Mutation, TError, RequestPermissionUploadInvoiceFileToS3MutationVariables, TContext>(
      (variables?: RequestPermissionUploadInvoiceFileToS3MutationVariables) => fetcher<RequestPermissionUploadInvoiceFileToS3Mutation, RequestPermissionUploadInvoiceFileToS3MutationVariables>(RequestPermissionUploadInvoiceFileToS3Document, variables)(),
      options
    );
export const UpdateInvoiceDocument = `
    mutation UpdateInvoice($id: ID!, $invoice: InvoiceInput!) {
  updateInvoice(id: $id, invoice: $invoice)
}
    `;
export const useUpdateInvoiceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateInvoiceMutation, TError, UpdateInvoiceMutationVariables, TContext>) => 
    useMutation<UpdateInvoiceMutation, TError, UpdateInvoiceMutationVariables, TContext>(
      (variables?: UpdateInvoiceMutationVariables) => fetcher<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, variables)(),
      options
    );
export const DeleteInvoiceDocument = `
    mutation DeleteInvoice($id: ID!) {
  deleteInvoice(id: $id)
}
    `;
export const useDeleteInvoiceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteInvoiceMutation, TError, DeleteInvoiceMutationVariables, TContext>) => 
    useMutation<DeleteInvoiceMutation, TError, DeleteInvoiceMutationVariables, TContext>(
      (variables?: DeleteInvoiceMutationVariables) => fetcher<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, variables)(),
      options
    );
export const UpdateInvoiceStatusDocument = `
    mutation UpdateInvoiceStatus($id: ID!) {
  updateInvoiceStatus(id: $id, invoiceStatus: TRANSFERRED)
}
    `;
export const useUpdateInvoiceStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateInvoiceStatusMutation, TError, UpdateInvoiceStatusMutationVariables, TContext>) => 
    useMutation<UpdateInvoiceStatusMutation, TError, UpdateInvoiceStatusMutationVariables, TContext>(
      (variables?: UpdateInvoiceStatusMutationVariables) => fetcher<UpdateInvoiceStatusMutation, UpdateInvoiceStatusMutationVariables>(UpdateInvoiceStatusDocument, variables)(),
      options
    );
export const GetInvoicesViewAllPageDocument = `
    query GetInvoicesViewAllPage($purchaseOrder: String!, $purchaseOrderItem: String!, $filters: InvoiceFilters, $pagination: Pagination) @app(id: INVOICES) {
  purchaseOrdersInvoices(
    purchaseOrder: $purchaseOrder
    purchaseOrderItem: $purchaseOrderItem
    pagination: $pagination
    filters: $filters
  ) {
    id
    invoiceBackendNumber
    invoiceVendorNumber
    headerText
    fiscalYear
    total
    currency
    invoiceDate
    plannedPaymentDate
    lastPaymentDate
    rejectionReason
    invoiceStatus
    file {
      id
      url
    }
  }
  purchaseOrdersInvoicesCount(
    purchaseOrder: $purchaseOrder
    purchaseOrderItem: $purchaseOrderItem
    filters: $filters
  )
}
    `;
export const useGetInvoicesViewAllPageQuery = <
      TData = GetInvoicesViewAllPageQuery,
      TError = unknown
    >(
      variables: GetInvoicesViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetInvoicesViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetInvoicesViewAllPageQuery, TError, TData>(
      ['GetInvoicesViewAllPage', variables],
      fetcher<GetInvoicesViewAllPageQuery, GetInvoicesViewAllPageQueryVariables>(GetInvoicesViewAllPageDocument, variables),
      options
    );
export const GetInvoiceForViewPageDocument = `
    query GetInvoiceForViewPage($id: ID!) @app(id: INVOICES) {
  invoice(id: $id) {
    id
    backend
    accountingEntity
    vendorId
    currency
    headerText
    invoiceDate
    invoiceBackendNumber
    invoiceVendorNumber
    invoiceStatus
    total
    file {
      id
      url
    }
  }
}
    `;
export const useGetInvoiceForViewPageQuery = <
      TData = GetInvoiceForViewPageQuery,
      TError = unknown
    >(
      variables: GetInvoiceForViewPageQueryVariables, 
      options?: UseQueryOptions<GetInvoiceForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetInvoiceForViewPageQuery, TError, TData>(
      ['GetInvoiceForViewPage', variables],
      fetcher<GetInvoiceForViewPageQuery, GetInvoiceForViewPageQueryVariables>(GetInvoiceForViewPageDocument, variables),
      options
    );
export const GetInvoiceItemsForViewPageDocument = `
    query GetInvoiceItemsForViewPage($invoiceNumber: String!) @app(id: INVOICES) {
  invoiceItem(invoiceNumber: $invoiceNumber) {
    id
    backend
    taxesAmount
    totalPrice
    itemText
    invoiceNumber
    purchaseOrder
    purchaseOrderItem
    productId
    unit
    quantity
    netUnitPrice
    netTotalPrice
    extraChargesAmount
  }
}
    `;
export const useGetInvoiceItemsForViewPageQuery = <
      TData = GetInvoiceItemsForViewPageQuery,
      TError = unknown
    >(
      variables: GetInvoiceItemsForViewPageQueryVariables, 
      options?: UseQueryOptions<GetInvoiceItemsForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetInvoiceItemsForViewPageQuery, TError, TData>(
      ['GetInvoiceItemsForViewPage', variables],
      fetcher<GetInvoiceItemsForViewPageQuery, GetInvoiceItemsForViewPageQueryVariables>(GetInvoiceItemsForViewPageDocument, variables),
      options
    );
export const GetBusinessObjectsForCreateKpiDocument = `
    query GetBusinessObjectsForCreateKpi($language: String!) @app(id: KPIS) {
  businessObjects {
    id
    dataElement {
      translation(language: $language) {
        description
      }
    }
  }
}
    `;
export const useGetBusinessObjectsForCreateKpiQuery = <
      TData = GetBusinessObjectsForCreateKpiQuery,
      TError = unknown
    >(
      variables: GetBusinessObjectsForCreateKpiQueryVariables, 
      options?: UseQueryOptions<GetBusinessObjectsForCreateKpiQuery, TError, TData>
    ) => 
    useQuery<GetBusinessObjectsForCreateKpiQuery, TError, TData>(
      ['GetBusinessObjectsForCreateKpi', variables],
      fetcher<GetBusinessObjectsForCreateKpiQuery, GetBusinessObjectsForCreateKpiQueryVariables>(GetBusinessObjectsForCreateKpiDocument, variables),
      options
    );
export const KpiExistsDocument = `
    query KpiExists($id: ID!) @app(id: KPIS) {
  kpiExists(id: $id)
}
    `;
export const useKpiExistsQuery = <
      TData = KpiExistsQuery,
      TError = unknown
    >(
      variables: KpiExistsQueryVariables, 
      options?: UseQueryOptions<KpiExistsQuery, TError, TData>
    ) => 
    useQuery<KpiExistsQuery, TError, TData>(
      ['KpiExists', variables],
      fetcher<KpiExistsQuery, KpiExistsQueryVariables>(KpiExistsDocument, variables),
      options
    );
export const GetBusinessObjectForCreatingKpiDocument = `
    query GetBusinessObjectForCreatingKpi($businessObjectId: ID!, $language: String!) @app(id: KPIS) {
  businessObject(id: $businessObjectId) {
    tables {
      table {
        id
        translation(language: $language) {
          description
        }
        columns {
          id
          type
          dataElement {
            id
            translation(language: $language) {
              description
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetBusinessObjectForCreatingKpiQuery = <
      TData = GetBusinessObjectForCreatingKpiQuery,
      TError = unknown
    >(
      variables: GetBusinessObjectForCreatingKpiQueryVariables, 
      options?: UseQueryOptions<GetBusinessObjectForCreatingKpiQuery, TError, TData>
    ) => 
    useQuery<GetBusinessObjectForCreatingKpiQuery, TError, TData>(
      ['GetBusinessObjectForCreatingKpi', variables],
      fetcher<GetBusinessObjectForCreatingKpiQuery, GetBusinessObjectForCreatingKpiQueryVariables>(GetBusinessObjectForCreatingKpiDocument, variables),
      options
    );
export const CreateKpiDocument = `
    mutation CreateKpi($kpi: KpiInput!) {
  createKpi(kpi: $kpi)
}
    `;
export const useCreateKpiMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateKpiMutation, TError, CreateKpiMutationVariables, TContext>) => 
    useMutation<CreateKpiMutation, TError, CreateKpiMutationVariables, TContext>(
      (variables?: CreateKpiMutationVariables) => fetcher<CreateKpiMutation, CreateKpiMutationVariables>(CreateKpiDocument, variables)(),
      options
    );
export const UpdateKpiDocument = `
    mutation UpdateKpi($id: ID!, $kpi: KpiInput!) {
  updateKpi(id: $id, kpi: $kpi)
}
    `;
export const useUpdateKpiMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateKpiMutation, TError, UpdateKpiMutationVariables, TContext>) => 
    useMutation<UpdateKpiMutation, TError, UpdateKpiMutationVariables, TContext>(
      (variables?: UpdateKpiMutationVariables) => fetcher<UpdateKpiMutation, UpdateKpiMutationVariables>(UpdateKpiDocument, variables)(),
      options
    );
export const DeleteKpiDocument = `
    mutation DeleteKpi($id: ID!) {
  deleteKpi(id: $id)
}
    `;
export const useDeleteKpiMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteKpiMutation, TError, DeleteKpiMutationVariables, TContext>) => 
    useMutation<DeleteKpiMutation, TError, DeleteKpiMutationVariables, TContext>(
      (variables?: DeleteKpiMutationVariables) => fetcher<DeleteKpiMutation, DeleteKpiMutationVariables>(DeleteKpiDocument, variables)(),
      options
    );
export const GetKpisForViewTableDocument = `
    query GetKpisForViewTable($pagination: Pagination, $filters: KpiFilters) @app(id: KPIS) {
  kpis(filters: $filters, pagination: $pagination) {
    id
    businessObject {
      id
    }
    type
    value
  }
  kpisCount(filters: $filters)
}
    `;
export const useGetKpisForViewTableQuery = <
      TData = GetKpisForViewTableQuery,
      TError = unknown
    >(
      variables?: GetKpisForViewTableQueryVariables, 
      options?: UseQueryOptions<GetKpisForViewTableQuery, TError, TData>
    ) => 
    useQuery<GetKpisForViewTableQuery, TError, TData>(
      ['GetKpisForViewTable', variables],
      fetcher<GetKpisForViewTableQuery, GetKpisForViewTableQueryVariables>(GetKpisForViewTableDocument, variables),
      options
    );
export const GetKpiForViewPageDocument = `
    query GetKpiForViewPage($id: ID!) @app(id: KPIS) {
  kpi(id: $id) {
    id
    businessObject {
      id
    }
    type
    configuration {
      tableId
      columnId
      aggregationType
      filters {
        columnId
        comparisonType
        reference
      }
    }
  }
}
    `;
export const useGetKpiForViewPageQuery = <
      TData = GetKpiForViewPageQuery,
      TError = unknown
    >(
      variables: GetKpiForViewPageQueryVariables, 
      options?: UseQueryOptions<GetKpiForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetKpiForViewPageQuery, TError, TData>(
      ['GetKpiForViewPage', variables],
      fetcher<GetKpiForViewPageQuery, GetKpiForViewPageQueryVariables>(GetKpiForViewPageDocument, variables),
      options
    );
export const LanguageExistsDocument = `
    query LanguageExists($id: ID!) @app(id: LANGUAGES) {
  languageExists(id: $id)
}
    `;
export const useLanguageExistsQuery = <
      TData = LanguageExistsQuery,
      TError = unknown
    >(
      variables: LanguageExistsQueryVariables, 
      options?: UseQueryOptions<LanguageExistsQuery, TError, TData>
    ) => 
    useQuery<LanguageExistsQuery, TError, TData>(
      ['LanguageExists', variables],
      fetcher<LanguageExistsQuery, LanguageExistsQueryVariables>(LanguageExistsDocument, variables),
      options
    );
export const CreateLanguageDocument = `
    mutation CreateLanguage($language: LanguageInput!) {
  createLanguage(language: $language)
}
    `;
export const useCreateLanguageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateLanguageMutation, TError, CreateLanguageMutationVariables, TContext>) => 
    useMutation<CreateLanguageMutation, TError, CreateLanguageMutationVariables, TContext>(
      (variables?: CreateLanguageMutationVariables) => fetcher<CreateLanguageMutation, CreateLanguageMutationVariables>(CreateLanguageDocument, variables)(),
      options
    );
export const UpdateLanguageDocument = `
    mutation UpdateLanguage($id: ID!, $language: LanguageInput!) {
  updateLanguage(id: $id, language: $language)
}
    `;
export const useUpdateLanguageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateLanguageMutation, TError, UpdateLanguageMutationVariables, TContext>) => 
    useMutation<UpdateLanguageMutation, TError, UpdateLanguageMutationVariables, TContext>(
      (variables?: UpdateLanguageMutationVariables) => fetcher<UpdateLanguageMutation, UpdateLanguageMutationVariables>(UpdateLanguageDocument, variables)(),
      options
    );
export const DeleteLanguageDocument = `
    mutation DeleteLanguage($id: ID!) {
  deleteLanguage(id: $id)
}
    `;
export const useDeleteLanguageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteLanguageMutation, TError, DeleteLanguageMutationVariables, TContext>) => 
    useMutation<DeleteLanguageMutation, TError, DeleteLanguageMutationVariables, TContext>(
      (variables?: DeleteLanguageMutationVariables) => fetcher<DeleteLanguageMutation, DeleteLanguageMutationVariables>(DeleteLanguageDocument, variables)(),
      options
    );
export const GetLanguages_ViewAllPageDocument = `
    query GetLanguages_ViewAllPage($language: String!, $filters: LanguageFilters, $pagination: Pagination) @app(id: LANGUAGES) {
  languages(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  languagesCount(filters: $filters)
}
    `;
export const useGetLanguages_ViewAllPageQuery = <
      TData = GetLanguages_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetLanguages_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetLanguages_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetLanguages_ViewAllPageQuery, TError, TData>(
      ['GetLanguages_ViewAllPage', variables],
      fetcher<GetLanguages_ViewAllPageQuery, GetLanguages_ViewAllPageQueryVariables>(GetLanguages_ViewAllPageDocument, variables),
      options
    );
export const GetLanguage_ViewPageDocument = `
    query GetLanguage_ViewPage($id: ID!) @app(id: LANGUAGES) {
  language(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetLanguage_ViewPageQuery = <
      TData = GetLanguage_ViewPageQuery,
      TError = unknown
    >(
      variables: GetLanguage_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetLanguage_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetLanguage_ViewPageQuery, TError, TData>(
      ['GetLanguage_ViewPage', variables],
      fetcher<GetLanguage_ViewPageQuery, GetLanguage_ViewPageQueryVariables>(GetLanguage_ViewPageDocument, variables),
      options
    );
export const LocationTypeExistsDocument = `
    query LocationTypeExists($id: ID!) @app(id: LOCATION_TYPES) {
  locationTypeExists(id: $id)
}
    `;
export const useLocationTypeExistsQuery = <
      TData = LocationTypeExistsQuery,
      TError = unknown
    >(
      variables: LocationTypeExistsQueryVariables, 
      options?: UseQueryOptions<LocationTypeExistsQuery, TError, TData>
    ) => 
    useQuery<LocationTypeExistsQuery, TError, TData>(
      ['LocationTypeExists', variables],
      fetcher<LocationTypeExistsQuery, LocationTypeExistsQueryVariables>(LocationTypeExistsDocument, variables),
      options
    );
export const CreateLocationTypeDocument = `
    mutation CreateLocationType($locationType: LocationTypeInput!) {
  createLocationType(locationType: $locationType)
}
    `;
export const useCreateLocationTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateLocationTypeMutation, TError, CreateLocationTypeMutationVariables, TContext>) => 
    useMutation<CreateLocationTypeMutation, TError, CreateLocationTypeMutationVariables, TContext>(
      (variables?: CreateLocationTypeMutationVariables) => fetcher<CreateLocationTypeMutation, CreateLocationTypeMutationVariables>(CreateLocationTypeDocument, variables)(),
      options
    );
export const UpdateLocationTypeDocument = `
    mutation UpdateLocationType($id: ID!, $locationType: LocationTypeInput!) {
  updateLocationType(id: $id, locationType: $locationType)
}
    `;
export const useUpdateLocationTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateLocationTypeMutation, TError, UpdateLocationTypeMutationVariables, TContext>) => 
    useMutation<UpdateLocationTypeMutation, TError, UpdateLocationTypeMutationVariables, TContext>(
      (variables?: UpdateLocationTypeMutationVariables) => fetcher<UpdateLocationTypeMutation, UpdateLocationTypeMutationVariables>(UpdateLocationTypeDocument, variables)(),
      options
    );
export const DeleteLocationTypeDocument = `
    mutation DeleteLocationType($id: ID!) {
  deleteLocationType(id: $id)
}
    `;
export const useDeleteLocationTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteLocationTypeMutation, TError, DeleteLocationTypeMutationVariables, TContext>) => 
    useMutation<DeleteLocationTypeMutation, TError, DeleteLocationTypeMutationVariables, TContext>(
      (variables?: DeleteLocationTypeMutationVariables) => fetcher<DeleteLocationTypeMutation, DeleteLocationTypeMutationVariables>(DeleteLocationTypeDocument, variables)(),
      options
    );
export const GetLocationTypes_ViewAllPageDocument = `
    query GetLocationTypes_ViewAllPage($language: String!, $filters: LocationTypeFilters, $pagination: Pagination) @app(id: LOCATION_TYPES) {
  locationTypes(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  locationTypesCount(filters: $filters)
}
    `;
export const useGetLocationTypes_ViewAllPageQuery = <
      TData = GetLocationTypes_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetLocationTypes_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetLocationTypes_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetLocationTypes_ViewAllPageQuery, TError, TData>(
      ['GetLocationTypes_ViewAllPage', variables],
      fetcher<GetLocationTypes_ViewAllPageQuery, GetLocationTypes_ViewAllPageQueryVariables>(GetLocationTypes_ViewAllPageDocument, variables),
      options
    );
export const GetLocationType_ViewPageDocument = `
    query GetLocationType_ViewPage($id: ID!) @app(id: LOCATION_TYPES) {
  locationType(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetLocationType_ViewPageQuery = <
      TData = GetLocationType_ViewPageQuery,
      TError = unknown
    >(
      variables: GetLocationType_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetLocationType_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetLocationType_ViewPageQuery, TError, TData>(
      ['GetLocationType_ViewPage', variables],
      fetcher<GetLocationType_ViewPageQuery, GetLocationType_ViewPageQueryVariables>(GetLocationType_ViewPageDocument, variables),
      options
    );
export const LocationExistsDocument = `
    query LocationExists($id: ID!) @app(id: LOCATIONS) {
  locationExists(id: $id)
}
    `;
export const useLocationExistsQuery = <
      TData = LocationExistsQuery,
      TError = unknown
    >(
      variables: LocationExistsQueryVariables, 
      options?: UseQueryOptions<LocationExistsQuery, TError, TData>
    ) => 
    useQuery<LocationExistsQuery, TError, TData>(
      ['LocationExists', variables],
      fetcher<LocationExistsQuery, LocationExistsQueryVariables>(LocationExistsDocument, variables),
      options
    );
export const GetLocationTypesForLocationsFormDocument = `
    query GetLocationTypesForLocationsForm($language: String!, $filters: LocationTypeFilters, $pagination: Pagination) @app(id: LOCATIONS) {
  locationTypes(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  locationTypesCount(filters: $filters)
}
    `;
export const useGetLocationTypesForLocationsFormQuery = <
      TData = GetLocationTypesForLocationsFormQuery,
      TError = unknown
    >(
      variables: GetLocationTypesForLocationsFormQueryVariables, 
      options?: UseQueryOptions<GetLocationTypesForLocationsFormQuery, TError, TData>
    ) => 
    useQuery<GetLocationTypesForLocationsFormQuery, TError, TData>(
      ['GetLocationTypesForLocationsForm', variables],
      fetcher<GetLocationTypesForLocationsFormQuery, GetLocationTypesForLocationsFormQueryVariables>(GetLocationTypesForLocationsFormDocument, variables),
      options
    );
export const CreateLocationDocument = `
    mutation CreateLocation($location: LocationInput!) {
  createLocation(location: $location)
}
    `;
export const useCreateLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateLocationMutation, TError, CreateLocationMutationVariables, TContext>) => 
    useMutation<CreateLocationMutation, TError, CreateLocationMutationVariables, TContext>(
      (variables?: CreateLocationMutationVariables) => fetcher<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, variables)(),
      options
    );
export const UpdateLocationDocument = `
    mutation UpdateLocation($id: ID!, $location: LocationInput!) {
  updateLocation(id: $id, location: $location)
}
    `;
export const useUpdateLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateLocationMutation, TError, UpdateLocationMutationVariables, TContext>) => 
    useMutation<UpdateLocationMutation, TError, UpdateLocationMutationVariables, TContext>(
      (variables?: UpdateLocationMutationVariables) => fetcher<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, variables)(),
      options
    );
export const DeletLocationDocument = `
    mutation DeletLocation($id: ID!) {
  deleteLocation(id: $id)
}
    `;
export const useDeletLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletLocationMutation, TError, DeletLocationMutationVariables, TContext>) => 
    useMutation<DeletLocationMutation, TError, DeletLocationMutationVariables, TContext>(
      (variables?: DeletLocationMutationVariables) => fetcher<DeletLocationMutation, DeletLocationMutationVariables>(DeletLocationDocument, variables)(),
      options
    );
export const GetLocations_ViewAllPageDocument = `
    query GetLocations_ViewAllPage($language: String!, $filters: LocationFilters, $pagination: Pagination) @app(id: LOCATIONS) {
  locations(filters: $filters, pagination: $pagination) {
    id
    address {
      street
      number
      floor
      department
      city
      postalCode
      state
      country
    }
    translation(language: $language) {
      description
    }
    locationType {
      id
      translations {
        language
        description
      }
    }
  }
  locationsCount(filters: $filters)
}
    `;
export const useGetLocations_ViewAllPageQuery = <
      TData = GetLocations_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetLocations_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetLocations_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetLocations_ViewAllPageQuery, TError, TData>(
      ['GetLocations_ViewAllPage', variables],
      fetcher<GetLocations_ViewAllPageQuery, GetLocations_ViewAllPageQueryVariables>(GetLocations_ViewAllPageDocument, variables),
      options
    );
export const GetLocation_ViewPageDocument = `
    query GetLocation_ViewPage($id: ID!) @app(id: LOCATIONS) {
  location(id: $id) {
    id
    address {
      street
      number
      floor
      department
      city
      postalCode
      state
      country
    }
    translations {
      language
      description
    }
    locationType {
      id
      translations {
        language
        description
      }
    }
    gates {
      id
      isInput
      isOutput
      translations {
        language
        description
      }
    }
  }
}
    `;
export const useGetLocation_ViewPageQuery = <
      TData = GetLocation_ViewPageQuery,
      TError = unknown
    >(
      variables: GetLocation_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetLocation_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetLocation_ViewPageQuery, TError, TData>(
      ['GetLocation_ViewPage', variables],
      fetcher<GetLocation_ViewPageQuery, GetLocation_ViewPageQueryVariables>(GetLocation_ViewPageDocument, variables),
      options
    );
export const MessageExistsDocument = `
    query MessageExists($id: ID!) @app(id: MESSAGES) {
  messageExists(id: $id)
}
    `;
export const useMessageExistsQuery = <
      TData = MessageExistsQuery,
      TError = unknown
    >(
      variables: MessageExistsQueryVariables, 
      options?: UseQueryOptions<MessageExistsQuery, TError, TData>
    ) => 
    useQuery<MessageExistsQuery, TError, TData>(
      ['MessageExists', variables],
      fetcher<MessageExistsQuery, MessageExistsQueryVariables>(MessageExistsDocument, variables),
      options
    );
export const CreateMessageDocument = `
    mutation CreateMessage($message: MessageInput!) {
  createMessage(message: $message)
}
    `;
export const useCreateMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateMessageMutation, TError, CreateMessageMutationVariables, TContext>) => 
    useMutation<CreateMessageMutation, TError, CreateMessageMutationVariables, TContext>(
      (variables?: CreateMessageMutationVariables) => fetcher<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, variables)(),
      options
    );
export const UpdateMessageDocument = `
    mutation UpdateMessage($id: ID!, $message: MessageInput!) {
  updateMessage(id: $id, message: $message)
}
    `;
export const useUpdateMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMessageMutation, TError, UpdateMessageMutationVariables, TContext>) => 
    useMutation<UpdateMessageMutation, TError, UpdateMessageMutationVariables, TContext>(
      (variables?: UpdateMessageMutationVariables) => fetcher<UpdateMessageMutation, UpdateMessageMutationVariables>(UpdateMessageDocument, variables)(),
      options
    );
export const DeleteMessageDocument = `
    mutation DeleteMessage($id: ID!) {
  deleteMessage(id: $id)
}
    `;
export const useDeleteMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMessageMutation, TError, DeleteMessageMutationVariables, TContext>) => 
    useMutation<DeleteMessageMutation, TError, DeleteMessageMutationVariables, TContext>(
      (variables?: DeleteMessageMutationVariables) => fetcher<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, variables)(),
      options
    );
export const GetMessages_ViewAllPageDocument = `
    query GetMessages_ViewAllPage($language: String!, $filters: MessageFilters, $pagination: Pagination) @app(id: MESSAGES) {
  messages(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  messagesCount(filters: $filters)
}
    `;
export const useGetMessages_ViewAllPageQuery = <
      TData = GetMessages_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetMessages_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetMessages_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetMessages_ViewAllPageQuery, TError, TData>(
      ['GetMessages_ViewAllPage', variables],
      fetcher<GetMessages_ViewAllPageQuery, GetMessages_ViewAllPageQueryVariables>(GetMessages_ViewAllPageDocument, variables),
      options
    );
export const GetMessage_ViewPageDocument = `
    query GetMessage_ViewPage($id: ID!) @app(id: MESSAGES) {
  message(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetMessage_ViewPageQuery = <
      TData = GetMessage_ViewPageQuery,
      TError = unknown
    >(
      variables: GetMessage_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetMessage_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetMessage_ViewPageQuery, TError, TData>(
      ['GetMessage_ViewPage', variables],
      fetcher<GetMessage_ViewPageQuery, GetMessage_ViewPageQueryVariables>(GetMessage_ViewPageDocument, variables),
      options
    );
export const PositionExistsDocument = `
    query PositionExists($id: ID!) @app(id: POSITIONS) {
  positionExists(id: $id)
}
    `;
export const usePositionExistsQuery = <
      TData = PositionExistsQuery,
      TError = unknown
    >(
      variables: PositionExistsQueryVariables, 
      options?: UseQueryOptions<PositionExistsQuery, TError, TData>
    ) => 
    useQuery<PositionExistsQuery, TError, TData>(
      ['PositionExists', variables],
      fetcher<PositionExistsQuery, PositionExistsQueryVariables>(PositionExistsDocument, variables),
      options
    );
export const CreatePositionDocument = `
    mutation CreatePosition($position: PositionInput!) {
  createPosition(position: $position)
}
    `;
export const useCreatePositionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePositionMutation, TError, CreatePositionMutationVariables, TContext>) => 
    useMutation<CreatePositionMutation, TError, CreatePositionMutationVariables, TContext>(
      (variables?: CreatePositionMutationVariables) => fetcher<CreatePositionMutation, CreatePositionMutationVariables>(CreatePositionDocument, variables)(),
      options
    );
export const UpdatePositionDocument = `
    mutation UpdatePosition($id: ID!, $position: PositionInput!) {
  updatePosition(id: $id, position: $position)
}
    `;
export const useUpdatePositionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePositionMutation, TError, UpdatePositionMutationVariables, TContext>) => 
    useMutation<UpdatePositionMutation, TError, UpdatePositionMutationVariables, TContext>(
      (variables?: UpdatePositionMutationVariables) => fetcher<UpdatePositionMutation, UpdatePositionMutationVariables>(UpdatePositionDocument, variables)(),
      options
    );
export const DeletePositionDocument = `
    mutation DeletePosition($id: ID!) {
  deletePosition(id: $id)
}
    `;
export const useDeletePositionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletePositionMutation, TError, DeletePositionMutationVariables, TContext>) => 
    useMutation<DeletePositionMutation, TError, DeletePositionMutationVariables, TContext>(
      (variables?: DeletePositionMutationVariables) => fetcher<DeletePositionMutation, DeletePositionMutationVariables>(DeletePositionDocument, variables)(),
      options
    );
export const GetPositions_ViewAllPageDocument = `
    query GetPositions_ViewAllPage($language: String!, $filters: PositionFilters, $pagination: Pagination) @app(id: POSITIONS) {
  positions(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
  }
  positionsCount(filters: $filters)
}
    `;
export const useGetPositions_ViewAllPageQuery = <
      TData = GetPositions_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetPositions_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetPositions_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetPositions_ViewAllPageQuery, TError, TData>(
      ['GetPositions_ViewAllPage', variables],
      fetcher<GetPositions_ViewAllPageQuery, GetPositions_ViewAllPageQueryVariables>(GetPositions_ViewAllPageDocument, variables),
      options
    );
export const GetPosition_ViewPageDocument = `
    query GetPosition_ViewPage($id: ID!) @app(id: POSITIONS) {
  position(id: $id) {
    id
    translations {
      language
      description
    }
  }
}
    `;
export const useGetPosition_ViewPageQuery = <
      TData = GetPosition_ViewPageQuery,
      TError = unknown
    >(
      variables: GetPosition_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetPosition_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetPosition_ViewPageQuery, TError, TData>(
      ['GetPosition_ViewPage', variables],
      fetcher<GetPosition_ViewPageQuery, GetPosition_ViewPageQueryVariables>(GetPosition_ViewPageDocument, variables),
      options
    );
export const PurchaseEntityExistsDocument = `
    query PurchaseEntityExists($id: ID!) @app(id: PURCHASE_ENTITIES) {
  purchaseEntityExists(id: $id)
}
    `;
export const usePurchaseEntityExistsQuery = <
      TData = PurchaseEntityExistsQuery,
      TError = unknown
    >(
      variables: PurchaseEntityExistsQueryVariables, 
      options?: UseQueryOptions<PurchaseEntityExistsQuery, TError, TData>
    ) => 
    useQuery<PurchaseEntityExistsQuery, TError, TData>(
      ['PurchaseEntityExists', variables],
      fetcher<PurchaseEntityExistsQuery, PurchaseEntityExistsQueryVariables>(PurchaseEntityExistsDocument, variables),
      options
    );
export const GetCompaniesForPurchaseEntitiesFormDocument = `
    query GetCompaniesForPurchaseEntitiesForm($filters: CompanyFilters, $pagination: Pagination) @app(id: COMPANIES) {
  companies(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
  companiesCount(filters: $filters)
}
    `;
export const useGetCompaniesForPurchaseEntitiesFormQuery = <
      TData = GetCompaniesForPurchaseEntitiesFormQuery,
      TError = unknown
    >(
      variables?: GetCompaniesForPurchaseEntitiesFormQueryVariables, 
      options?: UseQueryOptions<GetCompaniesForPurchaseEntitiesFormQuery, TError, TData>
    ) => 
    useQuery<GetCompaniesForPurchaseEntitiesFormQuery, TError, TData>(
      ['GetCompaniesForPurchaseEntitiesForm', variables],
      fetcher<GetCompaniesForPurchaseEntitiesFormQuery, GetCompaniesForPurchaseEntitiesFormQueryVariables>(GetCompaniesForPurchaseEntitiesFormDocument, variables),
      options
    );
export const CreatePurchaseEntityDocument = `
    mutation CreatePurchaseEntity($purchaseEntity: PurchaseEntityInput!) {
  createPurchaseEntity(purchaseEntity: $purchaseEntity)
}
    `;
export const useCreatePurchaseEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePurchaseEntityMutation, TError, CreatePurchaseEntityMutationVariables, TContext>) => 
    useMutation<CreatePurchaseEntityMutation, TError, CreatePurchaseEntityMutationVariables, TContext>(
      (variables?: CreatePurchaseEntityMutationVariables) => fetcher<CreatePurchaseEntityMutation, CreatePurchaseEntityMutationVariables>(CreatePurchaseEntityDocument, variables)(),
      options
    );
export const UpdatePurchaseEntityDocument = `
    mutation UpdatePurchaseEntity($id: ID!, $purchaseEntity: PurchaseEntityInput!) {
  updatePurchaseEntity(id: $id, purchaseEntity: $purchaseEntity)
}
    `;
export const useUpdatePurchaseEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePurchaseEntityMutation, TError, UpdatePurchaseEntityMutationVariables, TContext>) => 
    useMutation<UpdatePurchaseEntityMutation, TError, UpdatePurchaseEntityMutationVariables, TContext>(
      (variables?: UpdatePurchaseEntityMutationVariables) => fetcher<UpdatePurchaseEntityMutation, UpdatePurchaseEntityMutationVariables>(UpdatePurchaseEntityDocument, variables)(),
      options
    );
export const DeletePurchaseEntityDocument = `
    mutation DeletePurchaseEntity($id: ID!) {
  deletePurchaseEntity(id: $id)
}
    `;
export const useDeletePurchaseEntityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletePurchaseEntityMutation, TError, DeletePurchaseEntityMutationVariables, TContext>) => 
    useMutation<DeletePurchaseEntityMutation, TError, DeletePurchaseEntityMutationVariables, TContext>(
      (variables?: DeletePurchaseEntityMutationVariables) => fetcher<DeletePurchaseEntityMutation, DeletePurchaseEntityMutationVariables>(DeletePurchaseEntityDocument, variables)(),
      options
    );
export const GetPurchaseEntities_ViewAllPageDocument = `
    query GetPurchaseEntities_ViewAllPage($language: String!, $filters: PurchaseEntityFilters, $pagination: Pagination) @app(id: PURCHASE_ENTITIES) {
  purchaseEntities(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
    company {
      id
      name
      currency {
        id
      }
      chartOfAccount {
        id
      }
    }
  }
  purchaseEntitiesCount(filters: $filters)
}
    `;
export const useGetPurchaseEntities_ViewAllPageQuery = <
      TData = GetPurchaseEntities_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetPurchaseEntities_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetPurchaseEntities_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseEntities_ViewAllPageQuery, TError, TData>(
      ['GetPurchaseEntities_ViewAllPage', variables],
      fetcher<GetPurchaseEntities_ViewAllPageQuery, GetPurchaseEntities_ViewAllPageQueryVariables>(GetPurchaseEntities_ViewAllPageDocument, variables),
      options
    );
export const GetPurchaseEntity_ViewPageDocument = `
    query GetPurchaseEntity_ViewPage($id: ID!) @app(id: PURCHASE_ENTITIES) {
  purchaseEntity(id: $id) {
    id
    translations {
      language
      description
    }
    company {
      id
      name
      currency {
        id
      }
      chartOfAccount {
        id
      }
    }
  }
}
    `;
export const useGetPurchaseEntity_ViewPageQuery = <
      TData = GetPurchaseEntity_ViewPageQuery,
      TError = unknown
    >(
      variables: GetPurchaseEntity_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetPurchaseEntity_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseEntity_ViewPageQuery, TError, TData>(
      ['GetPurchaseEntity_ViewPage', variables],
      fetcher<GetPurchaseEntity_ViewPageQuery, GetPurchaseEntity_ViewPageQueryVariables>(GetPurchaseEntity_ViewPageDocument, variables),
      options
    );
export const GetPurchaseOrdersForPurchaseOrdersItemsReportsDocument = `
    query GetPurchaseOrdersForPurchaseOrdersItemsReports($filters: PurchaseOrderFilters, $pagination: Pagination) @app(id: PURCHASE_ORDERS_ITEMS) {
  purchaseOrders(pagination: $pagination, filters: $filters) {
    id
    accountingEntityDescription
    vendorId
    vendorName
    currency
    netTotalPrice
    createdAt
    purchaseOrderStatus
  }
  purchaseOrdersCount
}
    `;
export const useGetPurchaseOrdersForPurchaseOrdersItemsReportsQuery = <
      TData = GetPurchaseOrdersForPurchaseOrdersItemsReportsQuery,
      TError = unknown
    >(
      variables?: GetPurchaseOrdersForPurchaseOrdersItemsReportsQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrdersForPurchaseOrdersItemsReportsQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrdersForPurchaseOrdersItemsReportsQuery, TError, TData>(
      ['GetPurchaseOrdersForPurchaseOrdersItemsReports', variables],
      fetcher<GetPurchaseOrdersForPurchaseOrdersItemsReportsQuery, GetPurchaseOrdersForPurchaseOrdersItemsReportsQueryVariables>(GetPurchaseOrdersForPurchaseOrdersItemsReportsDocument, variables),
      options
    );
export const GetPurchaseOrdersForInvoicesReportsDocument = `
    query GetPurchaseOrdersForInvoicesReports($filters: PurchaseOrderFilters, $pagination: Pagination) @app(id: INVOICES) {
  purchaseOrders(pagination: $pagination, filters: $filters) {
    id
    accountingEntityDescription
    vendorId
    vendorName
    currency
    netTotalPrice
    createdAt
    purchaseOrderStatus
  }
  purchaseOrdersCount
}
    `;
export const useGetPurchaseOrdersForInvoicesReportsQuery = <
      TData = GetPurchaseOrdersForInvoicesReportsQuery,
      TError = unknown
    >(
      variables?: GetPurchaseOrdersForInvoicesReportsQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrdersForInvoicesReportsQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrdersForInvoicesReportsQuery, TError, TData>(
      ['GetPurchaseOrdersForInvoicesReports', variables],
      fetcher<GetPurchaseOrdersForInvoicesReportsQuery, GetPurchaseOrdersForInvoicesReportsQueryVariables>(GetPurchaseOrdersForInvoicesReportsDocument, variables),
      options
    );
export const GetPurchaseOrdersItemsForInvoicesReportsDocument = `
    query GetPurchaseOrdersItemsForInvoicesReports($purchaseOrder: String!, $filters: PurchaseOrderItemFilters, $pagination: Pagination) @app(id: INVOICES) {
  purchaseOrderItems(
    purchaseOrder: $purchaseOrder
    pagination: $pagination
    filters: $filters
  ) {
    purchaseOrderItem
  }
}
    `;
export const useGetPurchaseOrdersItemsForInvoicesReportsQuery = <
      TData = GetPurchaseOrdersItemsForInvoicesReportsQuery,
      TError = unknown
    >(
      variables: GetPurchaseOrdersItemsForInvoicesReportsQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrdersItemsForInvoicesReportsQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrdersItemsForInvoicesReportsQuery, TError, TData>(
      ['GetPurchaseOrdersItemsForInvoicesReports', variables],
      fetcher<GetPurchaseOrdersItemsForInvoicesReportsQuery, GetPurchaseOrdersItemsForInvoicesReportsQueryVariables>(GetPurchaseOrdersItemsForInvoicesReportsDocument, variables),
      options
    );
export const GetPurchaseOrdersItemsReportPageDocument = `
    query GetPurchaseOrdersItemsReportPage($filters: PurchaseOrderItemFilters, $pagination: Pagination) @app(id: PURCHASE_ORDERS_ITEMS_REPORTS) {
  allPurchaseOrderItems(filters: $filters, pagination: $pagination) {
    purchaseOrderItem
    purchaseOrder
    productId
    productDescription
    hasInvoice
    locationDescription
    unit
    poQuantity
    netUnitPrice
    netTotalPrice
    grQuantity
    grAmount
    picInvoicedQuantity
    picInvoicedAmount
    invoiceApprovedQuantity
    invoiceApprovedAmount
    completeInvoice
  }
  allPurchaseOrderItemsCount(filters: $filters)
}
    `;
export const useGetPurchaseOrdersItemsReportPageQuery = <
      TData = GetPurchaseOrdersItemsReportPageQuery,
      TError = unknown
    >(
      variables?: GetPurchaseOrdersItemsReportPageQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrdersItemsReportPageQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrdersItemsReportPageQuery, TError, TData>(
      ['GetPurchaseOrdersItemsReportPage', variables],
      fetcher<GetPurchaseOrdersItemsReportPageQuery, GetPurchaseOrdersItemsReportPageQueryVariables>(GetPurchaseOrdersItemsReportPageDocument, variables),
      options
    );
export const GetPurchaseOrdersForReportPageDocument = `
    query GetPurchaseOrdersForReportPage($filters: PurchaseOrderFilters) @app(id: PURCHASE_ORDERS) {
  purchaseOrders(filters: $filters) {
    id
    accountingEntityDescription
    vendorId
    vendorName
    currency
    netTotalPrice
    createdAt
    purchaseEntity
    purchaseOrderStatus
  }
}
    `;
export const useGetPurchaseOrdersForReportPageQuery = <
      TData = GetPurchaseOrdersForReportPageQuery,
      TError = unknown
    >(
      variables?: GetPurchaseOrdersForReportPageQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrdersForReportPageQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrdersForReportPageQuery, TError, TData>(
      ['GetPurchaseOrdersForReportPage', variables],
      fetcher<GetPurchaseOrdersForReportPageQuery, GetPurchaseOrdersForReportPageQueryVariables>(GetPurchaseOrdersForReportPageDocument, variables),
      options
    );
export const GetPurchaseOrderItemsViewAllPageDocument = `
    query GetPurchaseOrderItemsViewAllPage($filters: PurchaseOrderItemFilters, $pagination: Pagination, $purchaseOrder: String!) @app(id: PURCHASE_ORDERS_ITEMS) {
  purchaseOrderItems(
    purchaseOrder: $purchaseOrder
    pagination: $pagination
    filters: $filters
  ) {
    purchaseOrderItem
    productId
    productDescription
    hasInvoice
    locationDescription
    unit
    poQuantity
    netUnitPrice
    netTotalPrice
    grQuantity
    grAmount
    picInvoicedQuantity
    picInvoicedAmount
    invoiceApprovedQuantity
    invoiceApprovedAmount
    needGr
    completeInvoice
  }
  purchaseOrderItemsCount(purchaseOrder: $purchaseOrder, filters: $filters)
}
    `;
export const useGetPurchaseOrderItemsViewAllPageQuery = <
      TData = GetPurchaseOrderItemsViewAllPageQuery,
      TError = unknown
    >(
      variables: GetPurchaseOrderItemsViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrderItemsViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrderItemsViewAllPageQuery, TError, TData>(
      ['GetPurchaseOrderItemsViewAllPage', variables],
      fetcher<GetPurchaseOrderItemsViewAllPageQuery, GetPurchaseOrderItemsViewAllPageQueryVariables>(GetPurchaseOrderItemsViewAllPageDocument, variables),
      options
    );
export const GetPurchaseOrderDetailViewPageDocument = `
    query GetPurchaseOrderDetailViewPage($id: ID!) @app(id: PURCHASE_ORDERS) {
  purchaseOrder(id: $id) {
    id
    backend
    accountingEntity
    accountingEntityDescription
    vendorId
    vendorName
    currency
    extraCharges
    purchaseEntity
    netTotalPrice
    createdAt
  }
}
    `;
export const useGetPurchaseOrderDetailViewPageQuery = <
      TData = GetPurchaseOrderDetailViewPageQuery,
      TError = unknown
    >(
      variables: GetPurchaseOrderDetailViewPageQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrderDetailViewPageQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrderDetailViewPageQuery, TError, TData>(
      ['GetPurchaseOrderDetailViewPage', variables],
      fetcher<GetPurchaseOrderDetailViewPageQuery, GetPurchaseOrderDetailViewPageQueryVariables>(GetPurchaseOrderDetailViewPageDocument, variables),
      options
    );
export const GetPurchaseOrdersViewAllPageDocument = `
    query GetPurchaseOrdersViewAllPage($filters: PurchaseOrderFilters, $pagination: Pagination) @app(id: PURCHASE_ORDERS) {
  purchaseOrders(pagination: $pagination, filters: $filters) {
    id
    accountingEntityDescription
    vendorId
    vendorName
    currency
    netTotalPrice
    createdAt
    purchaseOrderStatus
    extraCharges
  }
  purchaseOrdersCount
}
    `;
export const useGetPurchaseOrdersViewAllPageQuery = <
      TData = GetPurchaseOrdersViewAllPageQuery,
      TError = unknown
    >(
      variables?: GetPurchaseOrdersViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetPurchaseOrdersViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetPurchaseOrdersViewAllPageQuery, TError, TData>(
      ['GetPurchaseOrdersViewAllPage', variables],
      fetcher<GetPurchaseOrdersViewAllPageQuery, GetPurchaseOrdersViewAllPageQueryVariables>(GetPurchaseOrdersViewAllPageDocument, variables),
      options
    );
export const RevenueCollectorExistsDocument = `
    query RevenueCollectorExists($id: ID!) @app(id: REVENUE_COLLECTORS) {
  revenueCollectorExists(id: $id)
}
    `;
export const useRevenueCollectorExistsQuery = <
      TData = RevenueCollectorExistsQuery,
      TError = unknown
    >(
      variables: RevenueCollectorExistsQueryVariables, 
      options?: UseQueryOptions<RevenueCollectorExistsQuery, TError, TData>
    ) => 
    useQuery<RevenueCollectorExistsQuery, TError, TData>(
      ['RevenueCollectorExists', variables],
      fetcher<RevenueCollectorExistsQuery, RevenueCollectorExistsQueryVariables>(RevenueCollectorExistsDocument, variables),
      options
    );
export const CreateRevenueCollectorDocument = `
    mutation CreateRevenueCollector($revenueCollector: RevenueCollectorInput!) {
  createRevenueCollector(revenueCollector: $revenueCollector)
}
    `;
export const useCreateRevenueCollectorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateRevenueCollectorMutation, TError, CreateRevenueCollectorMutationVariables, TContext>) => 
    useMutation<CreateRevenueCollectorMutation, TError, CreateRevenueCollectorMutationVariables, TContext>(
      (variables?: CreateRevenueCollectorMutationVariables) => fetcher<CreateRevenueCollectorMutation, CreateRevenueCollectorMutationVariables>(CreateRevenueCollectorDocument, variables)(),
      options
    );
export const UpdateRevenueCollectorDocument = `
    mutation UpdateRevenueCollector($id: ID!, $revenueCollector: RevenueCollectorInput!) {
  updateRevenueCollector(id: $id, revenueCollector: $revenueCollector)
}
    `;
export const useUpdateRevenueCollectorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateRevenueCollectorMutation, TError, UpdateRevenueCollectorMutationVariables, TContext>) => 
    useMutation<UpdateRevenueCollectorMutation, TError, UpdateRevenueCollectorMutationVariables, TContext>(
      (variables?: UpdateRevenueCollectorMutationVariables) => fetcher<UpdateRevenueCollectorMutation, UpdateRevenueCollectorMutationVariables>(UpdateRevenueCollectorDocument, variables)(),
      options
    );
export const DeleteRevenueCollectorDocument = `
    mutation DeleteRevenueCollector($id: ID!) {
  deleteRevenueCollector(id: $id)
}
    `;
export const useDeleteRevenueCollectorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteRevenueCollectorMutation, TError, DeleteRevenueCollectorMutationVariables, TContext>) => 
    useMutation<DeleteRevenueCollectorMutation, TError, DeleteRevenueCollectorMutationVariables, TContext>(
      (variables?: DeleteRevenueCollectorMutationVariables) => fetcher<DeleteRevenueCollectorMutation, DeleteRevenueCollectorMutationVariables>(DeleteRevenueCollectorDocument, variables)(),
      options
    );
export const GetRevenueCollectors_ViewAllPageDocument = `
    query GetRevenueCollectors_ViewAllPage($language: String!, $filters: RevenueCollectorFilters, $pagination: Pagination) @app(id: REVENUE_COLLECTORS) {
  revenueCollectors(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
    company {
      id
      name
      currency {
        id
      }
      chartOfAccount {
        id
      }
    }
  }
  revenueCollectorsCount
}
    `;
export const useGetRevenueCollectors_ViewAllPageQuery = <
      TData = GetRevenueCollectors_ViewAllPageQuery,
      TError = unknown
    >(
      variables: GetRevenueCollectors_ViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetRevenueCollectors_ViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetRevenueCollectors_ViewAllPageQuery, TError, TData>(
      ['GetRevenueCollectors_ViewAllPage', variables],
      fetcher<GetRevenueCollectors_ViewAllPageQuery, GetRevenueCollectors_ViewAllPageQueryVariables>(GetRevenueCollectors_ViewAllPageDocument, variables),
      options
    );
export const GetCompanies_ForRevenueCollectorsPageDocument = `
    query GetCompanies_forRevenueCollectorsPage($filters: CompanyFilters, $pagination: Pagination) @app(id: COMPANIES) {
  companies(filters: $filters, pagination: $pagination) {
    id
    name
    currency {
      id
    }
    chartOfAccount {
      id
    }
  }
  companiesCount(filters: $filters)
}
    `;
export const useGetCompanies_ForRevenueCollectorsPageQuery = <
      TData = GetCompanies_ForRevenueCollectorsPageQuery,
      TError = unknown
    >(
      variables?: GetCompanies_ForRevenueCollectorsPageQueryVariables, 
      options?: UseQueryOptions<GetCompanies_ForRevenueCollectorsPageQuery, TError, TData>
    ) => 
    useQuery<GetCompanies_ForRevenueCollectorsPageQuery, TError, TData>(
      ['GetCompanies_forRevenueCollectorsPage', variables],
      fetcher<GetCompanies_ForRevenueCollectorsPageQuery, GetCompanies_ForRevenueCollectorsPageQueryVariables>(GetCompanies_ForRevenueCollectorsPageDocument, variables),
      options
    );
export const GetRevenueCollector_ViewPageDocument = `
    query GetRevenueCollector_ViewPage($id: ID!) @app(id: REVENUE_COLLECTORS) {
  revenueCollector(id: $id) {
    id
    translations {
      language
      description
    }
    company {
      id
      name
      currency {
        id
      }
      chartOfAccount {
        id
      }
    }
  }
}
    `;
export const useGetRevenueCollector_ViewPageQuery = <
      TData = GetRevenueCollector_ViewPageQuery,
      TError = unknown
    >(
      variables: GetRevenueCollector_ViewPageQueryVariables, 
      options?: UseQueryOptions<GetRevenueCollector_ViewPageQuery, TError, TData>
    ) => 
    useQuery<GetRevenueCollector_ViewPageQuery, TError, TData>(
      ['GetRevenueCollector_ViewPage', variables],
      fetcher<GetRevenueCollector_ViewPageQuery, GetRevenueCollector_ViewPageQueryVariables>(GetRevenueCollector_ViewPageDocument, variables),
      options
    );
export const TableExistsDocument = `
    query TableExists($tableId: ID!) @app(id: TABLES) {
  tableExists(id: $tableId)
}
    `;
export const useTableExistsQuery = <
      TData = TableExistsQuery,
      TError = unknown
    >(
      variables: TableExistsQueryVariables, 
      options?: UseQueryOptions<TableExistsQuery, TError, TData>
    ) => 
    useQuery<TableExistsQuery, TError, TData>(
      ['TableExists', variables],
      fetcher<TableExistsQuery, TableExistsQueryVariables>(TableExistsDocument, variables),
      options
    );
export const GetTablesForCreateTableDocument = `
    query GetTablesForCreateTable @app(id: TABLES) {
  tables {
    id
    columns {
      id
      dataElement {
        id
      }
    }
  }
}
    `;
export const useGetTablesForCreateTableQuery = <
      TData = GetTablesForCreateTableQuery,
      TError = unknown
    >(
      variables?: GetTablesForCreateTableQueryVariables, 
      options?: UseQueryOptions<GetTablesForCreateTableQuery, TError, TData>
    ) => 
    useQuery<GetTablesForCreateTableQuery, TError, TData>(
      ['GetTablesForCreateTable', variables],
      fetcher<GetTablesForCreateTableQuery, GetTablesForCreateTableQueryVariables>(GetTablesForCreateTableDocument, variables),
      options
    );
export const GetDataElementsForCreateTableDocument = `
    query GetDataElementsForCreateTable($language: String!) @app(id: TABLES) {
  dataElements {
    id
    dataType
    decimals
    length
    isLanguageDependent
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetDataElementsForCreateTableQuery = <
      TData = GetDataElementsForCreateTableQuery,
      TError = unknown
    >(
      variables: GetDataElementsForCreateTableQueryVariables, 
      options?: UseQueryOptions<GetDataElementsForCreateTableQuery, TError, TData>
    ) => 
    useQuery<GetDataElementsForCreateTableQuery, TError, TData>(
      ['GetDataElementsForCreateTable', variables],
      fetcher<GetDataElementsForCreateTableQuery, GetDataElementsForCreateTableQueryVariables>(GetDataElementsForCreateTableDocument, variables),
      options
    );
export const CreateTableDocument = `
    mutation CreateTable($table: TableInput!) {
  createTable(table: $table)
}
    `;
export const useCreateTableMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateTableMutation, TError, CreateTableMutationVariables, TContext>) => 
    useMutation<CreateTableMutation, TError, CreateTableMutationVariables, TContext>(
      (variables?: CreateTableMutationVariables) => fetcher<CreateTableMutation, CreateTableMutationVariables>(CreateTableDocument, variables)(),
      options
    );
export const UpdateTableDocument = `
    mutation UpdateTable($id: ID!, $table: TableInput!) {
  updateTable(id: $id, table: $table)
}
    `;
export const useUpdateTableMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTableMutation, TError, UpdateTableMutationVariables, TContext>) => 
    useMutation<UpdateTableMutation, TError, UpdateTableMutationVariables, TContext>(
      (variables?: UpdateTableMutationVariables) => fetcher<UpdateTableMutation, UpdateTableMutationVariables>(UpdateTableDocument, variables)(),
      options
    );
export const GetTableMigrationsDocument = `
    query GetTableMigrations($id: ID!, $language: String!) @app(id: TABLES) {
  table(id: $id) {
    translation(language: $language) {
      language
      description
    }
  }
  tableMigrations(id: $id) {
    version
    fileName
    timestamp
    downloadUrl
  }
}
    `;
export const useGetTableMigrationsQuery = <
      TData = GetTableMigrationsQuery,
      TError = unknown
    >(
      variables: GetTableMigrationsQueryVariables, 
      options?: UseQueryOptions<GetTableMigrationsQuery, TError, TData>
    ) => 
    useQuery<GetTableMigrationsQuery, TError, TData>(
      ['GetTableMigrations', variables],
      fetcher<GetTableMigrationsQuery, GetTableMigrationsQueryVariables>(GetTableMigrationsDocument, variables),
      options
    );
export const DeleteTableDocument = `
    mutation DeleteTable($id: ID!) {
  deleteTable(id: $id)
}
    `;
export const useDeleteTableMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTableMutation, TError, DeleteTableMutationVariables, TContext>) => 
    useMutation<DeleteTableMutation, TError, DeleteTableMutationVariables, TContext>(
      (variables?: DeleteTableMutationVariables) => fetcher<DeleteTableMutation, DeleteTableMutationVariables>(DeleteTableDocument, variables)(),
      options
    );
export const ActivateTableDocument = `
    mutation ActivateTable($id: ID!) {
  activateTable(id: $id)
}
    `;
export const useActivateTableMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ActivateTableMutation, TError, ActivateTableMutationVariables, TContext>) => 
    useMutation<ActivateTableMutation, TError, ActivateTableMutationVariables, TContext>(
      (variables?: ActivateTableMutationVariables) => fetcher<ActivateTableMutation, ActivateTableMutationVariables>(ActivateTableDocument, variables)(),
      options
    );
export const DeactivateTableDocument = `
    mutation DeactivateTable($id: ID!) {
  deactivateTable(id: $id)
}
    `;
export const useDeactivateTableMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeactivateTableMutation, TError, DeactivateTableMutationVariables, TContext>) => 
    useMutation<DeactivateTableMutation, TError, DeactivateTableMutationVariables, TContext>(
      (variables?: DeactivateTableMutationVariables) => fetcher<DeactivateTableMutation, DeactivateTableMutationVariables>(DeactivateTableDocument, variables)(),
      options
    );
export const GetTablesForViewPageDocument = `
    query GetTablesForViewPage($language: String!, $filters: TablesFilters, $pagination: Pagination) @app(id: TABLES) {
  tables(filters: $filters, pagination: $pagination) {
    id
    translation(language: $language) {
      description
    }
    tableType
    status
  }
  tablesCount(filters: $filters)
}
    `;
export const useGetTablesForViewPageQuery = <
      TData = GetTablesForViewPageQuery,
      TError = unknown
    >(
      variables: GetTablesForViewPageQueryVariables, 
      options?: UseQueryOptions<GetTablesForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetTablesForViewPageQuery, TError, TData>(
      ['GetTablesForViewPage', variables],
      fetcher<GetTablesForViewPageQuery, GetTablesForViewPageQueryVariables>(GetTablesForViewPageDocument, variables),
      options
    );
export const GetTableForViewDocument = `
    query GetTableForView($id: ID!) @app(id: TABLES) {
  table(id: $id) {
    id
    tableType
    columns {
      id
      isPrimaryKey
      dataElement {
        id
      }
      type
    }
    translations {
      language
      description
    }
  }
}
    `;
export const useGetTableForViewQuery = <
      TData = GetTableForViewQuery,
      TError = unknown
    >(
      variables: GetTableForViewQueryVariables, 
      options?: UseQueryOptions<GetTableForViewQuery, TError, TData>
    ) => 
    useQuery<GetTableForViewQuery, TError, TData>(
      ['GetTableForView', variables],
      fetcher<GetTableForViewQuery, GetTableForViewQueryVariables>(GetTableForViewDocument, variables),
      options
    );
export const ThemeExistsDocument = `
    query ThemeExists($themeId: ID!) @app(id: THEMES) {
  themeExists(id: $themeId)
}
    `;
export const useThemeExistsQuery = <
      TData = ThemeExistsQuery,
      TError = unknown
    >(
      variables: ThemeExistsQueryVariables, 
      options?: UseQueryOptions<ThemeExistsQuery, TError, TData>
    ) => 
    useQuery<ThemeExistsQuery, TError, TData>(
      ['ThemeExists', variables],
      fetcher<ThemeExistsQuery, ThemeExistsQueryVariables>(ThemeExistsDocument, variables),
      options
    );
export const CreateThemeDocument = `
    mutation CreateTheme($theme: ThemeInput!) {
  createTheme(theme: $theme)
}
    `;
export const useCreateThemeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateThemeMutation, TError, CreateThemeMutationVariables, TContext>) => 
    useMutation<CreateThemeMutation, TError, CreateThemeMutationVariables, TContext>(
      (variables?: CreateThemeMutationVariables) => fetcher<CreateThemeMutation, CreateThemeMutationVariables>(CreateThemeDocument, variables)(),
      options
    );
export const RequestPermissionUploadThemeBackgroundImageToS3Document = `
    mutation RequestPermissionUploadThemeBackgroundImageToS3 {
  requestPermissionUploadThemeBackgroundImageToS3 {
    url
    bucket
    key
  }
}
    `;
export const useRequestPermissionUploadThemeBackgroundImageToS3Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestPermissionUploadThemeBackgroundImageToS3Mutation, TError, RequestPermissionUploadThemeBackgroundImageToS3MutationVariables, TContext>) => 
    useMutation<RequestPermissionUploadThemeBackgroundImageToS3Mutation, TError, RequestPermissionUploadThemeBackgroundImageToS3MutationVariables, TContext>(
      (variables?: RequestPermissionUploadThemeBackgroundImageToS3MutationVariables) => fetcher<RequestPermissionUploadThemeBackgroundImageToS3Mutation, RequestPermissionUploadThemeBackgroundImageToS3MutationVariables>(RequestPermissionUploadThemeBackgroundImageToS3Document, variables)(),
      options
    );
export const GetThemeInformationDocument = `
    query GetThemeInformation @app(id: THEMES) {
  me {
    id
    effectiveTheme {
      id
      primaryColor
      primaryColorContrast
      userColor
      appsColor
      appsTitleColor
      stepperColor1
      stepperColor2
      stepperColor3
      stepperTextColor
      stepperInternalColor
      backgroundImage {
        name
        url
      }
      companyLogo {
        name
        url
      }
    }
  }
}
    `;
export const useGetThemeInformationQuery = <
      TData = GetThemeInformationQuery,
      TError = unknown
    >(
      variables?: GetThemeInformationQueryVariables, 
      options?: UseQueryOptions<GetThemeInformationQuery, TError, TData>
    ) => 
    useQuery<GetThemeInformationQuery, TError, TData>(
      ['GetThemeInformation', variables],
      fetcher<GetThemeInformationQuery, GetThemeInformationQueryVariables>(GetThemeInformationDocument, variables),
      options
    );
export const UpdateThemeDocument = `
    mutation UpdateTheme($id: ID!, $theme: ThemeInput!) {
  updateTheme(id: $id, theme: $theme)
}
    `;
export const useUpdateThemeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateThemeMutation, TError, UpdateThemeMutationVariables, TContext>) => 
    useMutation<UpdateThemeMutation, TError, UpdateThemeMutationVariables, TContext>(
      (variables?: UpdateThemeMutationVariables) => fetcher<UpdateThemeMutation, UpdateThemeMutationVariables>(UpdateThemeDocument, variables)(),
      options
    );
export const SetDefaultThemeDocument = `
    mutation SetDefaultTheme($id: ID!) {
  setDefaultTheme(id: $id)
}
    `;
export const useSetDefaultThemeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetDefaultThemeMutation, TError, SetDefaultThemeMutationVariables, TContext>) => 
    useMutation<SetDefaultThemeMutation, TError, SetDefaultThemeMutationVariables, TContext>(
      (variables?: SetDefaultThemeMutationVariables) => fetcher<SetDefaultThemeMutation, SetDefaultThemeMutationVariables>(SetDefaultThemeDocument, variables)(),
      options
    );
export const DeleteThemeDocument = `
    mutation DeleteTheme($id: ID!) {
  deleteTheme(id: $id)
}
    `;
export const useDeleteThemeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteThemeMutation, TError, DeleteThemeMutationVariables, TContext>) => 
    useMutation<DeleteThemeMutation, TError, DeleteThemeMutationVariables, TContext>(
      (variables?: DeleteThemeMutationVariables) => fetcher<DeleteThemeMutation, DeleteThemeMutationVariables>(DeleteThemeDocument, variables)(),
      options
    );
export const GetThemesDocument = `
    query GetThemes($filters: ThemesFilters!, $pagination: Pagination!, $language: String!) @app(id: THEMES) {
  themes(filters: $filters, pagination: $pagination) {
    id
    isDefault
    primaryColor
    primaryColorContrast
    userColor
    appsColor
    appsTitleColor
    stepperColor1
    stepperColor2
    stepperColor3
    stepperTextColor
    stepperInternalColor
    backgroundImage {
      name
      url
    }
    translation(language: $language) {
      description
    }
  }
  themesCount(filters: $filters)
}
    `;
export const useGetThemesQuery = <
      TData = GetThemesQuery,
      TError = unknown
    >(
      variables: GetThemesQueryVariables, 
      options?: UseQueryOptions<GetThemesQuery, TError, TData>
    ) => 
    useQuery<GetThemesQuery, TError, TData>(
      ['GetThemes', variables],
      fetcher<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, variables),
      options
    );
export const GetThemeByIdDocument = `
    query GetThemeById($name: ID!) @app(id: THEMES) {
  theme(id: $name) {
    id
    primaryColor
    primaryColorContrast
    userColor
    appsColor
    appsTitleColor
    stepperColor1
    stepperColor2
    stepperColor3
    stepperTextColor
    stepperInternalColor
    backgroundImage {
      name
      url
    }
    companyLogo {
      name
      url
    }
    translations {
      language
      description
    }
  }
}
    `;
export const useGetThemeByIdQuery = <
      TData = GetThemeByIdQuery,
      TError = unknown
    >(
      variables: GetThemeByIdQueryVariables, 
      options?: UseQueryOptions<GetThemeByIdQuery, TError, TData>
    ) => 
    useQuery<GetThemeByIdQuery, TError, TData>(
      ['GetThemeById', variables],
      fetcher<GetThemeByIdQuery, GetThemeByIdQueryVariables>(GetThemeByIdDocument, variables),
      options
    );
export const GetAppsCreateTileFormDocument = `
    query GetAppsCreateTileForm($language: String!) @app(id: TILES) {
  apps {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetAppsCreateTileFormQuery = <
      TData = GetAppsCreateTileFormQuery,
      TError = unknown
    >(
      variables: GetAppsCreateTileFormQueryVariables, 
      options?: UseQueryOptions<GetAppsCreateTileFormQuery, TError, TData>
    ) => 
    useQuery<GetAppsCreateTileFormQuery, TError, TData>(
      ['GetAppsCreateTileForm', variables],
      fetcher<GetAppsCreateTileFormQuery, GetAppsCreateTileFormQueryVariables>(GetAppsCreateTileFormDocument, variables),
      options
    );
export const GetKpisForCreateTileDocument = `
    query GetKpisForCreateTile @app(id: TILES) {
  kpis {
    id
    businessObject {
      id
      status
    }
  }
}
    `;
export const useGetKpisForCreateTileQuery = <
      TData = GetKpisForCreateTileQuery,
      TError = unknown
    >(
      variables?: GetKpisForCreateTileQueryVariables, 
      options?: UseQueryOptions<GetKpisForCreateTileQuery, TError, TData>
    ) => 
    useQuery<GetKpisForCreateTileQuery, TError, TData>(
      ['GetKpisForCreateTile', variables],
      fetcher<GetKpisForCreateTileQuery, GetKpisForCreateTileQueryVariables>(GetKpisForCreateTileDocument, variables),
      options
    );
export const CreateTileDocument = `
    mutation CreateTile($tile: TileInput!) {
  createTile(tile: $tile)
}
    `;
export const useCreateTileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateTileMutation, TError, CreateTileMutationVariables, TContext>) => 
    useMutation<CreateTileMutation, TError, CreateTileMutationVariables, TContext>(
      (variables?: CreateTileMutationVariables) => fetcher<CreateTileMutation, CreateTileMutationVariables>(CreateTileDocument, variables)(),
      options
    );
export const RequestPermissionUploadTileIconToS3Document = `
    mutation RequestPermissionUploadTileIconToS3 {
  requestPermissionUploadTileIconToS3 {
    url
    bucket
    key
  }
}
    `;
export const useRequestPermissionUploadTileIconToS3Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestPermissionUploadTileIconToS3Mutation, TError, RequestPermissionUploadTileIconToS3MutationVariables, TContext>) => 
    useMutation<RequestPermissionUploadTileIconToS3Mutation, TError, RequestPermissionUploadTileIconToS3MutationVariables, TContext>(
      (variables?: RequestPermissionUploadTileIconToS3MutationVariables) => fetcher<RequestPermissionUploadTileIconToS3Mutation, RequestPermissionUploadTileIconToS3MutationVariables>(RequestPermissionUploadTileIconToS3Document, variables)(),
      options
    );
export const UpdateTileDocument = `
    mutation UpdateTile($id: ID!, $tile: TileInput!) {
  updateTile(id: $id, tile: $tile)
}
    `;
export const useUpdateTileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTileMutation, TError, UpdateTileMutationVariables, TContext>) => 
    useMutation<UpdateTileMutation, TError, UpdateTileMutationVariables, TContext>(
      (variables?: UpdateTileMutationVariables) => fetcher<UpdateTileMutation, UpdateTileMutationVariables>(UpdateTileDocument, variables)(),
      options
    );
export const DeleteTileDocument = `
    mutation DeleteTile($id: ID!) {
  deleteTile(id: $id)
}
    `;
export const useDeleteTileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTileMutation, TError, DeleteTileMutationVariables, TContext>) => 
    useMutation<DeleteTileMutation, TError, DeleteTileMutationVariables, TContext>(
      (variables?: DeleteTileMutationVariables) => fetcher<DeleteTileMutation, DeleteTileMutationVariables>(DeleteTileDocument, variables)(),
      options
    );
export const GetTilesForTablePageDocument = `
    query GetTilesForTablePage($language: String!, $filters: TilesFilters, $pagination: Pagination) @app(id: TILES) {
  tiles(filters: $filters, pagination: $pagination) {
    id
    app {
      id
      translation(language: $language) {
        description
      }
    }
    translation(language: $language) {
      title
      subtitle
    }
  }
  tilesCount(filters: $filters)
}
    `;
export const useGetTilesForTablePageQuery = <
      TData = GetTilesForTablePageQuery,
      TError = unknown
    >(
      variables: GetTilesForTablePageQueryVariables, 
      options?: UseQueryOptions<GetTilesForTablePageQuery, TError, TData>
    ) => 
    useQuery<GetTilesForTablePageQuery, TError, TData>(
      ['GetTilesForTablePage', variables],
      fetcher<GetTilesForTablePageQuery, GetTilesForTablePageQueryVariables>(GetTilesForTablePageDocument, variables),
      options
    );
export const GetTileForViewPageDocument = `
    query GetTileForViewPage($id: ID!) @app(id: TILES) {
  tile(id: $id) {
    id
    app {
      id
    }
    translations {
      language
      title
      subtitle
    }
    path
    shape
    kpi {
      id
    }
    icon {
      id
      name
      url
    }
  }
}
    `;
export const useGetTileForViewPageQuery = <
      TData = GetTileForViewPageQuery,
      TError = unknown
    >(
      variables: GetTileForViewPageQueryVariables, 
      options?: UseQueryOptions<GetTileForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetTileForViewPageQuery, TError, TData>(
      ['GetTileForViewPage', variables],
      fetcher<GetTileForViewPageQuery, GetTileForViewPageQueryVariables>(GetTileForViewPageDocument, variables),
      options
    );
export const GetAppsForCreateTutorialFormDocument = `
    query GetAppsForCreateTutorialForm($language: String!) @app(id: TUTORIALS) {
  apps {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetAppsForCreateTutorialFormQuery = <
      TData = GetAppsForCreateTutorialFormQuery,
      TError = unknown
    >(
      variables: GetAppsForCreateTutorialFormQueryVariables, 
      options?: UseQueryOptions<GetAppsForCreateTutorialFormQuery, TError, TData>
    ) => 
    useQuery<GetAppsForCreateTutorialFormQuery, TError, TData>(
      ['GetAppsForCreateTutorialForm', variables],
      fetcher<GetAppsForCreateTutorialFormQuery, GetAppsForCreateTutorialFormQueryVariables>(GetAppsForCreateTutorialFormDocument, variables),
      options
    );
export const TutorialExistsDocument = `
    query TutorialExists($tutorialId: ID!) @app(id: TUTORIALS) {
  tutorialExists(id: $tutorialId)
}
    `;
export const useTutorialExistsQuery = <
      TData = TutorialExistsQuery,
      TError = unknown
    >(
      variables: TutorialExistsQueryVariables, 
      options?: UseQueryOptions<TutorialExistsQuery, TError, TData>
    ) => 
    useQuery<TutorialExistsQuery, TError, TData>(
      ['TutorialExists', variables],
      fetcher<TutorialExistsQuery, TutorialExistsQueryVariables>(TutorialExistsDocument, variables),
      options
    );
export const CreateTutorialDocument = `
    mutation CreateTutorial($tutorial: TutorialInput!) {
  createTutorial(tutorial: $tutorial)
}
    `;
export const useCreateTutorialMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateTutorialMutation, TError, CreateTutorialMutationVariables, TContext>) => 
    useMutation<CreateTutorialMutation, TError, CreateTutorialMutationVariables, TContext>(
      (variables?: CreateTutorialMutationVariables) => fetcher<CreateTutorialMutation, CreateTutorialMutationVariables>(CreateTutorialDocument, variables)(),
      options
    );
export const UpdateTutorialDocument = `
    mutation UpdateTutorial($id: ID!, $tutorial: TutorialInput!) {
  updateTutorial(id: $id, tutorial: $tutorial)
}
    `;
export const useUpdateTutorialMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTutorialMutation, TError, UpdateTutorialMutationVariables, TContext>) => 
    useMutation<UpdateTutorialMutation, TError, UpdateTutorialMutationVariables, TContext>(
      (variables?: UpdateTutorialMutationVariables) => fetcher<UpdateTutorialMutation, UpdateTutorialMutationVariables>(UpdateTutorialDocument, variables)(),
      options
    );
export const DeleteTutorialDocument = `
    mutation DeleteTutorial($tutorialId: ID!) {
  deleteTutorial(id: $tutorialId)
}
    `;
export const useDeleteTutorialMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTutorialMutation, TError, DeleteTutorialMutationVariables, TContext>) => 
    useMutation<DeleteTutorialMutation, TError, DeleteTutorialMutationVariables, TContext>(
      (variables?: DeleteTutorialMutationVariables) => fetcher<DeleteTutorialMutation, DeleteTutorialMutationVariables>(DeleteTutorialDocument, variables)(),
      options
    );
export const GetTutorialsViewAllPageDocument = `
    query GetTutorialsViewAllPage($language: String!, $filters: TutorialsFilters, $pagination: Pagination) @app(id: TUTORIALS) {
  tutorials(pagination: $pagination, filters: $filters) {
    id
    translation(language: $language) {
      description
    }
  }
  tutorialsCount(filters: $filters)
}
    `;
export const useGetTutorialsViewAllPageQuery = <
      TData = GetTutorialsViewAllPageQuery,
      TError = unknown
    >(
      variables: GetTutorialsViewAllPageQueryVariables, 
      options?: UseQueryOptions<GetTutorialsViewAllPageQuery, TError, TData>
    ) => 
    useQuery<GetTutorialsViewAllPageQuery, TError, TData>(
      ['GetTutorialsViewAllPage', variables],
      fetcher<GetTutorialsViewAllPageQuery, GetTutorialsViewAllPageQueryVariables>(GetTutorialsViewAllPageDocument, variables),
      options
    );
export const GetTutorialByIdDocument = `
    query GetTutorialById($id: ID!) @app(id: TUTORIALS) {
  tutorial(id: $id) {
    id
    translations {
      language
      description
    }
    apps {
      id
    }
    instructions {
      stepNumber
      stepName
      target
      style
      clickEnabled
      translations {
        language
        description
      }
    }
  }
}
    `;
export const useGetTutorialByIdQuery = <
      TData = GetTutorialByIdQuery,
      TError = unknown
    >(
      variables: GetTutorialByIdQueryVariables, 
      options?: UseQueryOptions<GetTutorialByIdQuery, TError, TData>
    ) => 
    useQuery<GetTutorialByIdQuery, TError, TData>(
      ['GetTutorialById', variables],
      fetcher<GetTutorialByIdQuery, GetTutorialByIdQueryVariables>(GetTutorialByIdDocument, variables),
      options
    );
export const UserExistsDocument = `
    query UserExists($userId: ID!) @app(id: USERS) {
  userExists(id: $userId)
}
    `;
export const useUserExistsQuery = <
      TData = UserExistsQuery,
      TError = unknown
    >(
      variables: UserExistsQueryVariables, 
      options?: UseQueryOptions<UserExistsQuery, TError, TData>
    ) => 
    useQuery<UserExistsQuery, TError, TData>(
      ['UserExists', variables],
      fetcher<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, variables),
      options
    );
export const UserTypesDocument = `
    query UserTypes @app(id: USERS) {
  userTypes
}
    `;
export const useUserTypesQuery = <
      TData = UserTypesQuery,
      TError = unknown
    >(
      variables?: UserTypesQueryVariables, 
      options?: UseQueryOptions<UserTypesQuery, TError, TData>
    ) => 
    useQuery<UserTypesQuery, TError, TData>(
      ['UserTypes', variables],
      fetcher<UserTypesQuery, UserTypesQueryVariables>(UserTypesDocument, variables),
      options
    );
export const GetBusinessProfilesForCreateUserDocument = `
    query GetBusinessProfilesForCreateUser($language: String!) @app(id: USERS) {
  businessProfiles {
    id
    translation(language: $language) {
      description
    }
  }
}
    `;
export const useGetBusinessProfilesForCreateUserQuery = <
      TData = GetBusinessProfilesForCreateUserQuery,
      TError = unknown
    >(
      variables: GetBusinessProfilesForCreateUserQueryVariables, 
      options?: UseQueryOptions<GetBusinessProfilesForCreateUserQuery, TError, TData>
    ) => 
    useQuery<GetBusinessProfilesForCreateUserQuery, TError, TData>(
      ['GetBusinessProfilesForCreateUser', variables],
      fetcher<GetBusinessProfilesForCreateUserQuery, GetBusinessProfilesForCreateUserQueryVariables>(GetBusinessProfilesForCreateUserDocument, variables),
      options
    );
export const CreateUserDocument = `
    mutation CreateUser($user: UserInput!) {
  createUser(user: $user)
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>) => 
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
      options
    );
export const RequestPermissionUploadUserImageToS3Document = `
    mutation RequestPermissionUploadUserImageToS3 {
  requestPermissionUploadUserImageToS3 {
    url
    bucket
    key
  }
}
    `;
export const useRequestPermissionUploadUserImageToS3Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestPermissionUploadUserImageToS3Mutation, TError, RequestPermissionUploadUserImageToS3MutationVariables, TContext>) => 
    useMutation<RequestPermissionUploadUserImageToS3Mutation, TError, RequestPermissionUploadUserImageToS3MutationVariables, TContext>(
      (variables?: RequestPermissionUploadUserImageToS3MutationVariables) => fetcher<RequestPermissionUploadUserImageToS3Mutation, RequestPermissionUploadUserImageToS3MutationVariables>(RequestPermissionUploadUserImageToS3Document, variables)(),
      options
    );
export const UpdateUserDocument = `
    mutation UpdateUser($id: ID!, $user: UserInput!) {
  updateUser(id: $id, user: $user)
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) => 
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
      options
    );
export const UpdatePasswordDocument = `
    mutation UpdatePassword($id: ID!, $password: String!) {
  updatePassword(id: $id, password: $password)
}
    `;
export const useUpdatePasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePasswordMutation, TError, UpdatePasswordMutationVariables, TContext>) => 
    useMutation<UpdatePasswordMutation, TError, UpdatePasswordMutationVariables, TContext>(
      (variables?: UpdatePasswordMutationVariables) => fetcher<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, variables)(),
      options
    );
export const DeleteUserDocument = `
    mutation DeleteUser($userId: ID!) {
  deleteUser(id: $userId)
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>) => 
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      (variables?: DeleteUserMutationVariables) => fetcher<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, variables)(),
      options
    );
export const LockUserDocument = `
    mutation LockUser($userId: ID!, $lock: Boolean!) {
  lockUser(id: $userId, lock: $lock)
}
    `;
export const useLockUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LockUserMutation, TError, LockUserMutationVariables, TContext>) => 
    useMutation<LockUserMutation, TError, LockUserMutationVariables, TContext>(
      (variables?: LockUserMutationVariables) => fetcher<LockUserMutation, LockUserMutationVariables>(LockUserDocument, variables)(),
      options
    );
export const GetUsersForTablePageDocument = `
    query GetUsersForTablePage($filters: UsersFilters, $pagination: Pagination) @app(id: USERS) {
  users(filters: $filters, pagination: $pagination) {
    id
    firstName
    lastName
    email
    userType
    isLocked
    businessProfile {
      id
    }
  }
  usersCount(filters: $filters)
}
    `;
export const useGetUsersForTablePageQuery = <
      TData = GetUsersForTablePageQuery,
      TError = unknown
    >(
      variables?: GetUsersForTablePageQueryVariables, 
      options?: UseQueryOptions<GetUsersForTablePageQuery, TError, TData>
    ) => 
    useQuery<GetUsersForTablePageQuery, TError, TData>(
      ['GetUsersForTablePage', variables],
      fetcher<GetUsersForTablePageQuery, GetUsersForTablePageQueryVariables>(GetUsersForTablePageDocument, variables),
      options
    );
export const GetUserForViewPageDocument = `
    query GetUserForViewPage($id: ID!) @app(id: USERS) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    phoneNumber
    userType
    decimalSeparator
    numberDecimalsPlaces
    language {
      id
    }
    isLocked
    dateFormat
    timeFormat
    image {
      name
      url
    }
    businessProfile {
      id
    }
  }
}
    `;
export const useGetUserForViewPageQuery = <
      TData = GetUserForViewPageQuery,
      TError = unknown
    >(
      variables: GetUserForViewPageQueryVariables, 
      options?: UseQueryOptions<GetUserForViewPageQuery, TError, TData>
    ) => 
    useQuery<GetUserForViewPageQuery, TError, TData>(
      ['GetUserForViewPage', variables],
      fetcher<GetUserForViewPageQuery, GetUserForViewPageQueryVariables>(GetUserForViewPageDocument, variables),
      options
    );
export const GetMyInformationDocument = `
    query GetMyInformation($language: String!) @shared {
  me {
    id
    firstName
    lastName
    email
    themeId
    isPasswordUpdated
    image {
      name
      url
    }
    language {
      id
    }
    timeFormat
    dateFormat
    decimalSeparator
    numberDecimalsPlaces
    effectiveTheme {
      id
      primaryColor
      primaryColorContrast
      userColor
      appsColor
      appsTitleColor
      stepperColor1
      stepperColor2
      stepperColor3
      stepperTextColor
      stepperInternalColor
      backgroundImage {
        name
        url
      }
      companyLogo {
        name
        url
      }
    }
    businessProfile {
      id
      translation(language: $language) {
        description
      }
      appsActions {
        id
        translation(language: $language) {
          description
        }
        app {
          id
        }
      }
      groups {
        id
        icon {
          url
        }
        translation(language: $language) {
          description
        }
        tiles {
          id
          path
          shape
          icon {
            url
          }
          kpi {
            value
          }
          translation(language: $language) {
            title
            subtitle
          }
        }
      }
    }
  }
}
    `;
export const useGetMyInformationQuery = <
      TData = GetMyInformationQuery,
      TError = unknown
    >(
      variables: GetMyInformationQueryVariables, 
      options?: UseQueryOptions<GetMyInformationQuery, TError, TData>
    ) => 
    useQuery<GetMyInformationQuery, TError, TData>(
      ['GetMyInformation', variables],
      fetcher<GetMyInformationQuery, GetMyInformationQueryVariables>(GetMyInformationDocument, variables),
      options
    );
export const UpdateMyImageDocument = `
    mutation UpdateMyImage($imageId: String!) {
  updateMyImage(imageId: $imageId)
}
    `;
export const useUpdateMyImageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMyImageMutation, TError, UpdateMyImageMutationVariables, TContext>) => 
    useMutation<UpdateMyImageMutation, TError, UpdateMyImageMutationVariables, TContext>(
      (variables?: UpdateMyImageMutationVariables) => fetcher<UpdateMyImageMutation, UpdateMyImageMutationVariables>(UpdateMyImageDocument, variables)(),
      options
    );
export const GetMyProfileImageDocument = `
    query GetMyProfileImage @shared {
  me {
    id
    image {
      name
      url
    }
  }
}
    `;
export const useGetMyProfileImageQuery = <
      TData = GetMyProfileImageQuery,
      TError = unknown
    >(
      variables?: GetMyProfileImageQueryVariables, 
      options?: UseQueryOptions<GetMyProfileImageQuery, TError, TData>
    ) => 
    useQuery<GetMyProfileImageQuery, TError, TData>(
      ['GetMyProfileImage', variables],
      fetcher<GetMyProfileImageQuery, GetMyProfileImageQueryVariables>(GetMyProfileImageDocument, variables),
      options
    );
export const UpdateMyPasswordDocument = `
    mutation UpdateMyPassword($password: String!) {
  updateMyPassword(password: $password)
}
    `;
export const useUpdateMyPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMyPasswordMutation, TError, UpdateMyPasswordMutationVariables, TContext>) => 
    useMutation<UpdateMyPasswordMutation, TError, UpdateMyPasswordMutationVariables, TContext>(
      (variables?: UpdateMyPasswordMutationVariables) => fetcher<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>(UpdateMyPasswordDocument, variables)(),
      options
    );
export const GetAllThemesDocument = `
    query GetAllThemes($filters: ThemesFilters!, $pagination: Pagination!, $language: String!) @shared {
  themes(filters: $filters, pagination: $pagination) {
    id
    isDefault
    primaryColor
    primaryColorContrast
    userColor
    appsColor
    appsTitleColor
    stepperColor1
    stepperColor2
    stepperColor3
    stepperTextColor
    stepperInternalColor
    backgroundImage {
      name
      url
    }
    translation(language: $language) {
      description
    }
  }
  themesCount(filters: $filters)
}
    `;
export const useGetAllThemesQuery = <
      TData = GetAllThemesQuery,
      TError = unknown
    >(
      variables: GetAllThemesQueryVariables, 
      options?: UseQueryOptions<GetAllThemesQuery, TError, TData>
    ) => 
    useQuery<GetAllThemesQuery, TError, TData>(
      ['GetAllThemes', variables],
      fetcher<GetAllThemesQuery, GetAllThemesQueryVariables>(GetAllThemesDocument, variables),
      options
    );
export const UpdateMyProfileDocument = `
    mutation UpdateMyProfile($userProfile: UpdatableUserProfileInput!) {
  updateMyProfile(userProfile: $userProfile)
}
    `;
export const useUpdateMyProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMyProfileMutation, TError, UpdateMyProfileMutationVariables, TContext>) => 
    useMutation<UpdateMyProfileMutation, TError, UpdateMyProfileMutationVariables, TContext>(
      (variables?: UpdateMyProfileMutationVariables) => fetcher<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(UpdateMyProfileDocument, variables)(),
      options
    );
export const GetAppActionsDocument = `
    query GetAppActions($id: ID!, $language: String!) @shared {
  apps(filters: {ids: [$id]}) {
    actions {
      id
      translation(language: $language) {
        description
      }
    }
  }
}
    `;
export const useGetAppActionsQuery = <
      TData = GetAppActionsQuery,
      TError = unknown
    >(
      variables: GetAppActionsQueryVariables, 
      options?: UseQueryOptions<GetAppActionsQuery, TError, TData>
    ) => 
    useQuery<GetAppActionsQuery, TError, TData>(
      ['GetAppActions', variables],
      fetcher<GetAppActionsQuery, GetAppActionsQueryVariables>(GetAppActionsDocument, variables),
      options
    );
export const GetAppMessagesDocument = `
    query GetAppMessages($language: String!, $id: ID!) @shared {
  app(id: $id) {
    messages {
      id
      translation(language: $language) {
        language
        description
      }
    }
  }
}
    `;
export const useGetAppMessagesQuery = <
      TData = GetAppMessagesQuery,
      TError = unknown
    >(
      variables: GetAppMessagesQueryVariables, 
      options?: UseQueryOptions<GetAppMessagesQuery, TError, TData>
    ) => 
    useQuery<GetAppMessagesQuery, TError, TData>(
      ['GetAppMessages', variables],
      fetcher<GetAppMessagesQuery, GetAppMessagesQueryVariables>(GetAppMessagesDocument, variables),
      options
    );
export const GetAppSectionsDocument = `
    query GetAppSections($language: String!, $ids: [ID!]) @shared {
  apps(filters: {ids: $ids}) {
    id
    sections {
      id
      translation(language: $language) {
        language
        step
        title
        subtitle
      }
    }
  }
}
    `;
export const useGetAppSectionsQuery = <
      TData = GetAppSectionsQuery,
      TError = unknown
    >(
      variables: GetAppSectionsQueryVariables, 
      options?: UseQueryOptions<GetAppSectionsQuery, TError, TData>
    ) => 
    useQuery<GetAppSectionsQuery, TError, TData>(
      ['GetAppSections', variables],
      fetcher<GetAppSectionsQuery, GetAppSectionsQueryVariables>(GetAppSectionsDocument, variables),
      options
    );
export const GetDataElementsChoicesDocument = `
    query GetDataElementsChoices($language: String!, $dataElementIds: [ID!]!) @shared {
  dataElements(filters: {ids: $dataElementIds}) {
    id
    choices {
      id
      translation(language: $language) {
        language
        description
      }
    }
  }
}
    `;
export const useGetDataElementsChoicesQuery = <
      TData = GetDataElementsChoicesQuery,
      TError = unknown
    >(
      variables: GetDataElementsChoicesQueryVariables, 
      options?: UseQueryOptions<GetDataElementsChoicesQuery, TError, TData>
    ) => 
    useQuery<GetDataElementsChoicesQuery, TError, TData>(
      ['GetDataElementsChoices', variables],
      fetcher<GetDataElementsChoicesQuery, GetDataElementsChoicesQueryVariables>(GetDataElementsChoicesDocument, variables),
      options
    );
export const GetDataElementDescriptionDocument = `
    query GetDataElementDescription($language: String!, $ids: [ID!]!) @shared {
  dataElements(filters: {ids: $ids}) {
    id
    translation(language: $language) {
      language
      description
    }
  }
}
    `;
export const useGetDataElementDescriptionQuery = <
      TData = GetDataElementDescriptionQuery,
      TError = unknown
    >(
      variables: GetDataElementDescriptionQueryVariables, 
      options?: UseQueryOptions<GetDataElementDescriptionQuery, TError, TData>
    ) => 
    useQuery<GetDataElementDescriptionQuery, TError, TData>(
      ['GetDataElementDescription', variables],
      fetcher<GetDataElementDescriptionQuery, GetDataElementDescriptionQueryVariables>(GetDataElementDescriptionDocument, variables),
      options
    );
export const GetLanguagesOptionsDocument = `
    query GetLanguagesOptions($language: String!) @shared {
  languages {
    id
    translation(language: $language) {
      language
      description
    }
  }
}
    `;
export const useGetLanguagesOptionsQuery = <
      TData = GetLanguagesOptionsQuery,
      TError = unknown
    >(
      variables: GetLanguagesOptionsQueryVariables, 
      options?: UseQueryOptions<GetLanguagesOptionsQuery, TError, TData>
    ) => 
    useQuery<GetLanguagesOptionsQuery, TError, TData>(
      ['GetLanguagesOptions', variables],
      fetcher<GetLanguagesOptionsQuery, GetLanguagesOptionsQueryVariables>(GetLanguagesOptionsDocument, variables),
      options
    );
export const GetMyLanguageInformationDocument = `
    query GetMyLanguageInformation @shared {
  me {
    id
    language {
      id
    }
  }
}
    `;
export const useGetMyLanguageInformationQuery = <
      TData = GetMyLanguageInformationQuery,
      TError = unknown
    >(
      variables?: GetMyLanguageInformationQueryVariables, 
      options?: UseQueryOptions<GetMyLanguageInformationQuery, TError, TData>
    ) => 
    useQuery<GetMyLanguageInformationQuery, TError, TData>(
      ['GetMyLanguageInformation', variables],
      fetcher<GetMyLanguageInformationQuery, GetMyLanguageInformationQueryVariables>(GetMyLanguageInformationDocument, variables),
      options
    );
export const GetTutorialsDocument = `
    query GetTutorials($filters: TutorialsFilters, $language: String!) @shared {
  tutorials(filters: $filters) {
    id
    translation(language: $language) {
      language
      description
    }
    instructions {
      stepNumber
      stepName
      target
      style
      clickEnabled
      translation(language: $language) {
        language
        description
      }
    }
  }
}
    `;
export const useGetTutorialsQuery = <
      TData = GetTutorialsQuery,
      TError = unknown
    >(
      variables: GetTutorialsQueryVariables, 
      options?: UseQueryOptions<GetTutorialsQuery, TError, TData>
    ) => 
    useQuery<GetTutorialsQuery, TError, TData>(
      ['GetTutorials', variables],
      fetcher<GetTutorialsQuery, GetTutorialsQueryVariables>(GetTutorialsDocument, variables),
      options
    );
export const GetAmIAuthenticatedDocument = `
    query GetAmIAuthenticated @shared {
  amIAuthenticated
}
    `;
export const useGetAmIAuthenticatedQuery = <
      TData = GetAmIAuthenticatedQuery,
      TError = unknown
    >(
      variables?: GetAmIAuthenticatedQueryVariables, 
      options?: UseQueryOptions<GetAmIAuthenticatedQuery, TError, TData>
    ) => 
    useQuery<GetAmIAuthenticatedQuery, TError, TData>(
      ['GetAmIAuthenticated', variables],
      fetcher<GetAmIAuthenticatedQuery, GetAmIAuthenticatedQueryVariables>(GetAmIAuthenticatedDocument, variables),
      options
    );
export const GetMyPasswordUpdatedDocument = `
    query GetMyPasswordUpdated @shared {
  me {
    id
    isPasswordUpdated
  }
}
    `;
export const useGetMyPasswordUpdatedQuery = <
      TData = GetMyPasswordUpdatedQuery,
      TError = unknown
    >(
      variables?: GetMyPasswordUpdatedQueryVariables, 
      options?: UseQueryOptions<GetMyPasswordUpdatedQuery, TError, TData>
    ) => 
    useQuery<GetMyPasswordUpdatedQuery, TError, TData>(
      ['GetMyPasswordUpdated', variables],
      fetcher<GetMyPasswordUpdatedQuery, GetMyPasswordUpdatedQueryVariables>(GetMyPasswordUpdatedDocument, variables),
      options
    );