import { TableCellProps } from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  TableColumnType,
  TableRowType,
} from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppActions } from "hooks/useAppActions";
import { useSnackbar } from "hooks/useSnackbar";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { push } from "utils/push";
import { generatePath } from "utils/typedRoutesUtils";
import {
  GetAppsTableQuery,
  useDeleteAppMutation,
  useGetAppsTableQuery,
} from "__generated__/graphql/types";
import { AppsRoutes } from "../../../routes";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked,
} from "../../reducers/checkedRowsSlice";
import {
  filtersLabels,
  nextPage,
  prevPage,
  resetPage,
  selectFilters,
  selectPagination,
} from "../../reducers/filtersAndPaginationSlice";
import {
  ColumnsKeys,
  COLUMNS_KEYS,
  selectVisibleColumnsKeysInOrder,
} from "../../reducers/visibleColumnsSlice";

export const useTable = () => {
  const pagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);

  const errorSnackbar = useSnackbar();

  const { actions } = useAppActions("APPS");

  const { language } = useSystemLanguage();

  const {
    data: { apps, appsCount: total } = {},
    refetch: findApps,
  } = useGetAppsTableQuery({
    language,
    pagination,
    filters: {
      ids: filters.id !== undefined ? [filters.id] : undefined, // FIXME: list
      description: filters.description,
      // TODO: other filters
    },
  });

  const { mutateAsync: deleteApp } = useDeleteAppMutation();

  const dispatch = useAppDispatch();

  const orderedColumnsKeys = useAppSelector(selectVisibleColumnsKeysInOrder);

  const areAllDataElementsRowsChecked = useAppSelector(selectAreAllRowsChecked);

  const isDataElementsRowChecked = useAppSelector(selectIsRowChecked);

  const navigate = useNavigate();

  const columns: TableColumnType<ColumnsKeys>[] = useMemo(
    () => orderedColumnsKeys.map((colKey) => header[colKey]),
    [orderedColumnsKeys]
  );

  const rows = useMemo(() => apps?.map(mapRowDataToRowCells), [apps]);

  const menuItems = useMemo(
    () => [
      {
        key: "display",
        Icon: SearchIcon,
        text: actions["READ"],
        onClick: (id: string) => {
          navigate(generatePath(AppsRoutes.VIEW, { appId: id }));
        },
      },
      {
        key: "edit",
        Icon: EditIcon,
        text: actions["UPDATE"],
        onClick: (id: string) => {
          navigate(generatePath(AppsRoutes.EDIT, { appId: id }));
        },
      },
      {
        key: "delete",
        Icon: DeleteForeverIcon,
        text: actions["DELETE"],
        onClick: (id: string) =>
          deleteApp({ id })
            .then(() => dispatch(resetPage()))
            .then(() => findApps())
            .catch(() => errorSnackbar.open()),
      },
    ],
    [actions, deleteApp, dispatch, errorSnackbar, findApps, navigate]
  );

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPage());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  return {
    rows,
    total,
    errorSnackbar,
    columns,
    menuItems,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    pagination,
  };
};

const mapRowDataToRowCells = (
  dto: NonNullable<GetAppsTableQuery["apps"]>[number]
): TableRowType<ColumnsKeys> => {
  return {
    rowKey: dto.id,
    id: dto.id,
    description: dto.translation.description,
  };
};

const columnAlign: Partial<Record<ColumnsKeys, TableCellProps["align"]>> = {};

const header = COLUMNS_KEYS.reduce((acc, curr) => {
  return push(acc, curr, {
    key: curr,
    content: filtersLabels[curr].toUpperCase(),
    align: columnAlign[curr],
  });
}, {} as Record<ColumnsKeys, TableColumnType<ColumnsKeys>>);
