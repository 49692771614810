export enum ChartOfAccountRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":chartOfAccountId/view",
  EDIT = ":chartOfAccountId/edit",
}

export type ChartOfAccountRoutesState = {
  [ChartOfAccountRoutes.EDIT]: { activeStep?: number };
};
