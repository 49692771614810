import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { optionDescriptionEmptyRow } from "../OptionsDescriptionsForm/template";
import { Option, OptionsFormFields } from "../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { OptionsNamesFormProps } from "./types";

export const optionEmptyRow: Option = {
  name: "",
  descriptions: [optionDescriptionEmptyRow],
};

const OptionsNamesForm = ({
  onEdit,
  onEnterDescriptions,
  form,
  mode,
}: OptionsNamesFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray<OptionsFormFields>({
    control,
    name: "options",
  });

  const appendRow = () => {
    append(optionEmptyRow);
  };

  const onRemove = (index: number) => () => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <TableContainer>
      <StyledTable size="small">
        <Header
          onAppend={() => appendRow()}
          onEdit={() => onEdit?.()} // FIXME:
          readOnly={mode === "view"}
        />

        <StyledTableBody>
          {fields.map((item, index) => (
            <Row
              key={item.id}
              index={index}
              remove={onRemove(index)}
              item={item}
              readOnly={mode === "view"}
              onEnterDescriptions={onEnterDescriptions}
              form={form}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default OptionsNamesForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
