import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { FieldPath, FieldValues, useController } from "react-hook-form";
import { FilterComponentProps } from "../types";

const CheckboxFilter = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  label,
  controllerProps,
}: FilterComponentProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange, onBlur },
  } = useController({
    // @ts-ignore FIXME:
    defaultValue: false,
    ...controllerProps,
  });

  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={isValid(value) ? value : false}
          onChange={(_, checked) => onChange(checked)}
          onBlur={onBlur}
          name={name}
          color="primary"
        />
      }
    />
  );
};

export default CheckboxFilter;

const isValid = (value: unknown): value is boolean =>
  typeof value === "boolean";
