import { DevTool } from "@hookform/devtools";
import React from "react";
import { SectionsDescriptionsForm } from "./SectionsDescriptionsForm";
import { SectionsPositionsForm } from "./SectionsPositionsForm";
import { SectionsFormProps } from "./types";

const SectionsForm = ({
  areDescriptionsVisible,
  form,
  externalIndex,
  readOnly,
  onEnterDescriptions,
  onExitDescriptions,
  onEdit,
}: SectionsFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <SectionsDescriptionsForm
          onFinish={onExitDescriptions}
          formMethods={form}
          externalIndex={externalIndex}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      ) : (
        <SectionsPositionsForm
          form={form}
          onEnterDescriptions={onEnterDescriptions}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default SectionsForm;
