import { Box, styled, Typography } from "@mui/material";
import React from "react";

export type SuccessMessageProps = {
  message?: string;
}

const SuccessMessage = ({
  message
}: SuccessMessageProps )=> {
  return (
    <SuccessMessageWrapper>
      <StyledTypography>
        {
          message || "The data has been successfully saved."
        }
      </StyledTypography>
    </SuccessMessageWrapper>
  );
};

export default SuccessMessage;

const SuccessMessageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "auto",
  borderRadius: theme.shape.wrapperBorderRadius,
  backgroundColor: theme.palette.background.apps,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "center",
  fontWeight: 400,
  color: theme.palette.primary.dark,
  width: "fit-content",
  marginRight: "auto",
  marginLeft: "auto",
}));
