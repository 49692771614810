import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetTableForViewQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { TablesRoutes } from "../routes";
import { mapDtoToForm } from "./utils";

type Route = TablesRoutes.VIEW;

const ViewPage = () => {
  const navigate = useNavigate();

  const { tableId } = useRouteParams<Route>();

  const {
    data: { table } = {},
    isFetchedAfterMount,
  } = useGetTableForViewQuery({ id: tableId });

  const initialValue =
    table != null && isFetchedAfterMount ? mapDtoToForm(table) : undefined;

  const onEdit = useCallback(
    (activeStep: number) => {
      navigate(`../../${generatePath(TablesRoutes.EDIT, { tableId })}`, {
        state: { activeStep },
      });
    },
    [navigate, tableId]
  );

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title="Visualizar tabla" // FIXME: traduccion
        toBack={TO_BACK_ROUTER}
      />

      <Form initialValue={initialValue} mode="view" onEdit={onEdit} />
    </Layout>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
