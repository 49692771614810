import { DEFAULT_LANGUAGE } from "consts/languages";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useMemo } from "react";

const useTranslation = <T extends { language: string }>(translations: T[]) => {
  const { language } = useSystemLanguage();

  const valuesMap = useMemo(
    () => new Map(translations.map((e) => [e.language, e])),
    [translations]
  );

  const translation = useMemo(
    () => valuesMap.get(language) ?? valuesMap.get(DEFAULT_LANGUAGE),
    [language, valuesMap]
  );

  return translation;
};

export default useTranslation;
