import { TableInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): TableInput => {
  return {
    id: form.basicData.name,
    tableType: form.basicData.tableType,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
    columns: [
      ...form.characteristics.characteristics.map(
        ({ name, isPrimaryKey, dataElementId }) => ({
          id: name,
          isPrimaryKey,
          dataElementId,
          type: "CHARACTERISTIC" as const,
        })
      ),
      ...form.ratios.ratios.map(({ name, dataElementId, reference }) => ({
        id: name,
        dataElementId,
        reference,
        isPrimaryKey: false,
        type: "RATIO" as const,
      })),
    ],
  };
};
