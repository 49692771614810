import React from "react";
import { VisibleColumnsDialog } from "components/datadisplay/VisibleColumnsDialog";
import { useAppSelector } from "hooks/reduxHooks";
import { useDispatch } from "react-redux";
import {
  ColumnsKeys,
  hideColumn,
  orderColumns,
  selectHiddenColumnsKeys,
  selectVisibleColumnsKeysInOrder,
  showColumn,
} from "../../../reducers/visibleColumnsSlice";
import { usePurchaseOrderLabels } from '../../../usePurchaseOrderLabels';

type ColumnsVisibilityDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const ColumnsVisibilityDialog = ({
  open,
  handleClose,
}: ColumnsVisibilityDialogProps) => {
  const dispatch = useDispatch();
  const { columnsLabels } = usePurchaseOrderLabels();

  const visibleColumns = useAppSelector(selectVisibleColumnsKeysInOrder);
  const hiddenColumns = useAppSelector(selectHiddenColumnsKeys);

  const mapColumnKeyToDialog = (keys: ColumnsKeys[]) => {
    return keys.map((key) => ({
      id: key,
      label: columnsLabels[key],
    }));
  };

  return (
    <VisibleColumnsDialog
      open={open}
      handleClose={handleClose}
      visibleColumns={mapColumnKeyToDialog(visibleColumns)}
      hiddenColumns={mapColumnKeyToDialog(hiddenColumns)}
      hideColumn={(id) => dispatch(hideColumn({ id }))}
      orderColumn={(ids) => dispatch(orderColumns({ ids }))}
      showColumn={(id) => dispatch(showColumn({ id }))}
    />
  );
};

export default ColumnsVisibilityDialog;