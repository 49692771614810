import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetUserForViewPageQuery,
  useUpdateUserMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { useUploadUserImageToS3 } from "../CreatePage/useUploadUserImageToS3";
import { mapFormToDto } from "../CreatePage/utils";
import { UsersRoutes, UsersState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";
import useAppMessages from 'hooks/useAppMessages/hook';


type Route = UsersRoutes.EDIT;
type LocationState = UsersState[Route];

const EditPage = () => {
  const navigate = useNavigate();

  const { userId } = useRouteParams<Route>();
  const { activeStep } = useLocationState<LocationState>() ?? {};
  const [initialValues, setInitialValues] = useState<FormFields>();
  const { appMessage } = useAppMessages("USERS");
  const {
    data: { user } = {},
    isFetchedAfterMount,
  } = useGetUserForViewPageQuery({
    id: userId,
  });

  const { mutateAsync: updateUser } = useUpdateUserMutation();
  const { mutateAsync: uploadUserImage } = useUploadUserImageToS3();

  const onSave = async (form: FormFields) => {
    const { fileId } = await uploadUserImage(form.image.image);
    const user = mapFormToDto(form, fileId);
    await updateUser({ id: userId, user });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  useEffect(() => {
    if (user == null || !isFetchedAfterMount) return;
    mapDtoToForm(user).then((e) => setInitialValues(e));
  }, [isFetchedAfterMount, user]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["EDIT_USER"]} 
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValues}
          errorMessage={appMessage["ERROR_EDIT"]}
          onSave={onSave}
          onCancel={onCancel}
          initialStep={activeStep}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
