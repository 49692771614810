import {
  AggregationType,
  ComparisonType,
  KpiInput,
  KpiType,
} from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): KpiInput => {
  return {
    id: form.basicData.name,
    type: form.basicData.type as KpiType, // FIXME:
    businessObjectId: form.basicData.businessObjectId,
    configuration:
      form.configure === undefined
        ? undefined
        : {
            tableId: form.configure.tableId,
            columnId:
              form.configure.columnName === "" // FIXME:
                ? undefined
                : form.configure.columnName,
            aggregationType: form.configure.summarization as AggregationType, // FIXME:
            filters:
              form.configure.filters === undefined
                ? []
                : Object.entries(form.configure.filters).map(
                    ([key, value]) => ({
                      columnName: key,
                      comparisonType: "EQUAL" as ComparisonType, // FIXME:
                      reference: value,
                    })
                  ),
          },
  };
};
