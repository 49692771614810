import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  styled,
  Tooltip
} from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";
import React, { useState } from "react";
import CollapseButton from "./CollapseButton/template";

const StyledCollapse = ({
  children,
  buttons,
  onClickGo,
}: {
  children: React.ReactNode;
  onClickGo?: () => void;
  buttons?: {
    text: string;
    icon: JSX.Element;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
}) => {
  const [show, setShow] = useState(true);

  return (
    <Wrapper>
      <Collapse in={show}>{children}</Collapse>

      <CollapseButton show={show} toggleShow={() => setShow((show) => !show)} />

      {buttons && <Buttons onClickGo={onClickGo} buttons={buttons} />}
    </Wrapper>
  );
};

export default StyledCollapse;

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.apps,
  borderRadius: theme.shape.wrapperBorderRadius,
  padding: theme.spacing(2),
}));

type ButtonsProps = {
  onClickGo?: () => void;
  buttons: {
    text: string;
    icon: JSX.Element;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
};

const Buttons = ({ onClickGo, buttons }: ButtonsProps) => {

  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <Box marginTop={1}>
      <Grid
        container
        spacing={1}
        alignContent="flex-end"
        justifyContent="flex-end"
        wrap="nowrap"
      >
        <Grid item style={{ marginRight: "auto" }}>
          <StyledIconButton
            aria-label="settings"
            size="small"
            onClick={() => onClickGo?.()}
          >
            <Tooltip
              title={appMessage["FIND" ?? "Find"]}
            >
              <SearchIcon />
            </Tooltip>
          </StyledIconButton>
        </Grid>

        {buttons.map(({ text, icon, onClick }) => (
          <Grid
            key={text} // FIXME:
            item
          >
            <StyledIconButton
              aria-label="settings"
              size="small"
              onClick={onClick}
            >
              <Tooltip title={text}>{icon}</Tooltip>
            </StyledIconButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));
