import { GetApp as GetAppIcon } from "@mui/icons-material";
import {
  IconButton,
  Tooltip,
  styled
} from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";
import ReactExport from "react-export-excel";

// FIXME: react-export-excel -> does not support TypeScript
const ExcelComponent = (excelData) => {

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <>
    {
      (excelData.sheet) && (
        <ExcelFile 
          element={
            <StyledIconButton aria-label="download">
              <Tooltip title={appMessage["DOWNLOAD"] ?? "Download"}>
                <GetAppIcon />
              </Tooltip>
            </StyledIconButton>
          }
        >
          <ExcelSheet data={excelData.sheet.data} name="PIC">
          {
            excelData.sheet.columns.map((key) =>
              <ExcelColumn 
                key={key} 
                label={key.toUpperCase()} 
                value={key} 
              />
            )
          }
          </ExcelSheet>
        </ExcelFile>
      )
    }
    </>
  );
}

export default ExcelComponent;

const StyledIconButton = styled((props) => (
  <IconButton size="small" {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));