export enum GroupsRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":groupId/view",
  EDIT = ":groupId/edit",
}

export type GroupsRoutesState = {
  [GroupsRoutes.EDIT]: { activeStep?: number };
};
