import { UseSnackbarReturn } from "hooks/useSnackbarV2/hook";
import { useStepper } from "hooks/useStepper";
import { useBlockedRoute } from "hooks/useFormSteps/useBlockedRoute";
import { UseFormReturn } from "react-hook-form";
import { assertUnreachable } from "utils/assert";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";

export type CommonNonFormSteps = "save" | "success";

export type StepBehavior<TFormFieldsKeys, NonFormStepsKeys> = {
  key: TFormFieldsKeys | NonFormStepsKeys;
  isHidden?: boolean;
  isNestedPage?: boolean;
  hasForm?: boolean;
  onClickPrevButton?: () => void;
};

type UseFormStepsProps<
  TFormFields extends Record<TFormFieldsKeys, unknown>,
  TFormFieldsKeys extends string,
  TNonFormFieldsKeys extends string
> = {
  forms: MapToUseFormReturn<TFormFields>;
  initialStep?: number;
  errorSnackbar: UseSnackbarReturn;
  onSave: (value: TFormFields) => Promise<unknown>;
  errorMessage: string;
  stepsKeysInOrder: StepBehavior<TFormFieldsKeys, TNonFormFieldsKeys>[];
  readOnly?: boolean;
  onEdit?: (step: number) => void;
};

const useFormSteps = <
  TFormFields extends Record<TFormFieldsKeys, unknown>,
  TFormFieldsKeys extends string,
  TNonFormFieldsKeys extends string
>({
  forms,
  initialStep,
  errorSnackbar,
  onSave,
  errorMessage,
  stepsKeysInOrder,
  readOnly,
  onEdit,
}: UseFormStepsProps<TFormFields, TFormFieldsKeys, TNonFormFieldsKeys>) => {
  const countVisibleSteps = stepsKeysInOrder.filter(
    ({ isHidden }) => isHidden !== true
  ).length;

  const FORM_STEPS_KEYS = stepsKeysInOrder
    .filter(({ hasForm }) => hasForm)
    .map(({ key }) => key);

  const stepper = useStepper(countVisibleSteps, initialStep);

  const visibleStepsKeysInOrder = stepsKeysInOrder
    .filter(({ isHidden }) => !isHidden)
    .map(({ key }) => key);

  const activeStepKey = visibleStepsKeysInOrder[stepper.activeStepIndex];

  const isFormStep = (stepKey: unknown): stepKey is TFormFieldsKeys =>
    FORM_STEPS_KEYS.includes(stepKey as TFormFieldsKeys);

  const isFormStepVisible = (key: TFormFieldsKeys) =>
    visibleStepsKeysInOrder.includes(key);

  const getActiveStepForm = () => {
    const key = visibleStepsKeysInOrder[stepper.activeStepIndex];
    if (key === undefined) throw Error();
    if (!isFormStep(key)) return undefined;
    return forms[key];
  };

  const getActiveStepBehavior = () => {
    const step = stepsKeysInOrder[stepper.activeStepIndex];
    if (step === undefined) throw Error();
    return step;
  };

  const isPreviousButtonVisible = getActiveStepBehavior().isNestedPage
    ? false
    : !stepper.isFirstStep;

  const isNextButtonVisible = getActiveStepBehavior().isNestedPage
    ? false
    : readOnly && !stepper.isLastStep
    ? true
    : !stepper.isLastStep && !stepper.isLastStepMinus(1);

  const isUpButtonVisible = getActiveStepBehavior().isNestedPage;

  const onPrevious = stepper.onPrevious;

  const onNext = isFormStep(activeStepKey)
    ? getActiveStepForm()?.handleSubmit(() => stepper.onNext()) ??
      assertUnreachable
    : stepper.onNext;

  const onUp = getActiveStepBehavior().onClickPrevButton ?? assertUnreachable;

  const onClickSave = () => {
    const formValues = Object.entries(forms).reduce(
      (acc, [key, form]) =>
        isFormStep(key) && isFormStepVisible(key)
          ? { ...acc, [key]: (form as UseFormReturn).getValues() }
          : acc,
      {}
    ) as TFormFields;

    onSave(formValues)
      .then(() => stepper.onNext())
      .then(() => unblockRoute())
      .catch(() => errorSnackbar.open(errorMessage));
  };

  const onEditActiveStep = () => {
    if (onEdit === undefined) {
      assertUnreachable();
    }
    unblockRoute();
    onEdit(stepper.activeStepIndex);
  };

  const { isRouteBlocked, unblockRoute } = useBlockedRoute(
    stepper.hasStepChanged && !readOnly, // FIXME:
    (getActiveStepForm()?.formState.isDirty ?? false) && !readOnly // FIXME:
  );

  return {
    stepper,
    visibleStepsKeysInOrder,
    activeStepKey,
    isRouteBlocked,
    errorSnackbar,
    isPreviousButtonVisible,
    isNextButtonVisible,
    isUpButtonVisible,
    unblockRoute,
    onPrevious,
    onNext,
    onUp,
    onClickSave,
    onEditActiveStep,
  };
};

export default useFormSteps;
