import { DescriptionRowFields } from '../../../components/inputs/DescriptionFormControl/types';
import { GetTutorialByIdQuery } from '../../../__generated__/graphql/types';
import { AppIdsFormFields } from "../CreatePage/Form/AppsForm/types";
import { Instruction, InstructionDescription } from "../CreatePage/Form/InstructionsForm/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapTutorialDtoToForm = (
  dto: NonNullable<GetTutorialByIdQuery["tutorial"]>
): FormFields => {

  // Sort array
  dto.instructions.sort((a, b) => a.stepNumber - b.stepNumber);

  return {
    basicData: {
      tutorialId: dto.id
    },
    name: {
      descriptions: dto.translations.map((e):DescriptionRowFields => ({
        language: e.language,
        text: e.description
      }))
    },
    apps: {
      apps: dto.apps.map((e):AppIdsFormFields => ({
        id: e?.id ?? "" //FIXME:
      }))
    },
    instructions: {
      instructions: dto.instructions.map((e): Instruction => ({
        stepNumber: e.stepNumber,
        stepName: e.stepName,
        target: e.target,
        style: e.style,
        enabledClick: e.clickEnabled,
        descriptions: e.translations.map((d): InstructionDescription => ({
          language: d.language,
          description: d.description
        }))
      }))
    }
  };
};
