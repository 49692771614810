import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetBusinessPartnerViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { BusinessPartnersRoutes } from "../routes";
import { mapDtoToForm } from "./utils";
import useAppMessages from 'hooks/useAppMessages/hook';

const ViewPage = () => {
  const navigate = useNavigate();

  const { businessPartnerId } = useRouteParams<BusinessPartnersRoutes.VIEW>();

  const { data: { businessPartner } = {}, isFetchedAfterMount } = useGetBusinessPartnerViewPageQuery({
    id: businessPartnerId,
  });

  const { appMessage } = useAppMessages("BUSINESS_PARTNERS");
  const initialValue =
    businessPartner != null && isFetchedAfterMount ? mapDtoToForm(businessPartner) : undefined;

  const onEdit = (activeStep: number) => {
    navigate(`../../${generatePath(BusinessPartnersRoutes.EDIT, { businessPartnerId })}`, {
      state: { activeStep },
    });
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["CREATE_BP"]} 
          toBack={TO_BACK_ROUTER}
        />

        <Form mode="view" initialValue={initialValue} onEdit={onEdit} />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
