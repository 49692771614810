import { combineReducers } from "@reduxjs/toolkit";
import visibleColumnsSlice from "./ViewAllPage/reducers/columnsSlice";
import filtersAndPaginationSlice from "./ViewAllPage/reducers/filtersAndPaginationSlice";
import checkedRowsSlice from "./ViewAllPage/reducers/checkedRowsSlice";

const businessUnitsReducer = combineReducers({
  columns: visibleColumnsSlice,
  filtersAndPagination: filtersAndPaginationSlice,
  rowsChecked: checkedRowsSlice,
});

export default businessUnitsReducer;
