import { Box, Button, Grid, styled } from "@mui/material";
import logo from "assets/img/logo-styropek.png";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { TextFieldController } from "components/inputs/TextFieldController";
import { LoadingPage } from "components/layout/LoadingPage";
import { useLogMeIn } from "hooks/useLogMeIn";
import { useSnackbar } from "hooks/useSnackbarV2";
import React from "react";
import { useForm } from "react-hook-form";
import { useGetAmIAuthenticatedQuery } from "__generated__/graphql/types";
import { DialogChangePassword } from "./DialogChangePassword";

type FormFields = {
  username: string;
  password: string;
};

const LoginPage = () => {

  const formMethods = useForm<FormFields>();

  const { data: { amIAuthenticated } = {} } = useGetAmIAuthenticatedQuery();
  const { mutateAsync: logIn } = useLogMeIn();

  const errorSnackbar = useSnackbar();

  const onSubmit = formMethods.handleSubmit((form) => {
    logIn(form).catch(() => errorSnackbar.open("Login error.")); // FIXME: traduccion de acuerdo a la configuración del sistema
  });

  if (amIAuthenticated === undefined) return <LoadingPage />;

  return (
    <>
      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      {
        amIAuthenticated && (
          <DialogChangePassword />
        )
      }
      
      <BodyWrapper
        container
        alignItems="center"
      >
        <BodyBackground
          container
          item
          xs={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Box marginBottom={3}>
                {/* FIXME: Login dinamico en svg */}
                <StyledImg alt="styropek" src={logo} />
            </Box>
          </Grid>

          <Grid item>
            <Box bgcolor="#FFF" padding={4}>
              <form onSubmit={onSubmit}>
                <Box>
                  <TextFieldController
                    controllerProps={{
                      control: formMethods.control,
                      name: "username",
                      rules: {
                        required: "Enter user.", // FIXME: traduccion de acuerdo a la configuración del sistema
                      },
                    }}
                    label="User" // FIXME: traduccion de acuerdo a la configuración del sistema
                  />
                </Box>

                <Box marginTop={2}>
                  <TextFieldController
                    controllerProps={{
                      control: formMethods.control,
                      name: "password",
                      rules: {
                        required: "Enter password.", // FIXME: traduccion de acuerdo a la configuración del sistema
                      },
                    }}
                    InputProps={{
                      type: "password",
                    }}
                    label="Password" // FIXME: traduccion de acuerdo a la configuración del sistema
                  />
                </Box>

                <Box marginTop={2} display="flex" justifyContent="center">
                  <StyledButton
                    fullWidth
                    variant="contained"
                    type="submit"
                  >
                    {/* // FIXME: traduccion de acuerdo a la configuración del sistema */}
                    Enter
                  </StyledButton>
                </Box>
              </form>
            </Box>
          </Grid>
        </BodyBackground>
      </BodyWrapper>
    </>
  );
};

export default LoginPage;

const BodyWrapper = styled(Grid)(() => ({
  height: "100vh",
}));

const BodyBackground = styled(Grid)(() => ({
  height: "100%",
  backgroundColor: "#F4F6F9",
}));

const StyledImg = styled("img")({
  height: "80px"
});

const StyledButton = styled(Button)(() => ({
   // FIXME: Tomar color del tema
  backgroundColor: "primary",
  '&:hover': {
    backgroundColor: 'secondary',
  },
}));
