import { useForm } from "react-hook-form";
import { MapToUseFormReturn } from "utils/MapToUseFormReturn";
import { emptyPositionsRow } from "./PositionsForm/template";
import { FormFields } from "./types";

export const useForms = (
  initialValue?: FormFields
): MapToUseFormReturn<FormFields> => {

  const invoice = useForm<FormFields["invoice"]>({
    defaultValues: initialValue?.["invoice"],
  });

  const positions = useForm<FormFields["positions"]>({
    defaultValues: initialValue?.["positions"] ?? {
      positions: [emptyPositionsRow],
    },
  });

  const file = useForm<FormFields["file"]>({
    defaultValues: initialValue?.["file"],
  });

  file.register("file");

  const send = useForm<FormFields["send"]>({
    defaultValues: initialValue?.["send"],
  });
  
  return { invoice, positions, file, send };
};
