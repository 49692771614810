import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ArrowDownward as ArrowDownwardIcon } from "@mui/icons-material";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { PermissionsFormFields } from "../../types";
import { AppDtoForCreateBusinessProfiles } from "../types";
import useAppMessages from 'hooks/useAppMessages/hook';

export type RowProps = {
  readOnly: boolean;
  onEnterAppActions: (appId: string) => void;
  app: AppDtoForCreateBusinessProfiles;
  form: UseFormReturn<PermissionsFormFields>;
};

const Row = ({ readOnly, onEnterAppActions, app, form }: RowProps) => {
  const { formState } = form;

const { appMessage } = useAppMessages("BUSINESS_PROFILES");
  form.register(`apps.${app.id}._virtualField` as "apps.0._virtualField", {
    // FIXME: type safe _virtualField
    validate: () => {
      const value = form.getValues(
        `apps.${app.id}.actions` as "apps.0.actions"
      );

      return value === undefined ||
        Object.keys(value).length === 0 ||
        Object.values(value).every((action) => !action.isAllowed)
        ? appMessage["ONE_PER"] ??"Enter at least one permission"///FIXPRD QUITAR
        : true;
    },
  });

  const error = formState.errors?.apps?.[app.id]?._virtualField?.message;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <FormTableTextField
          value={app.id}
          inputProps={{ disabled: true }}
          error={error !== undefined}
          helperText={error}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <FormTableTextField
          value={app.translation.description}
          inputProps={{ disabled: true }}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip
          title={appMessage["ACTIONS"]}
        >
          <StyledIconButton
            size="small"
            aria-label="acciones"
            onClick={() => onEnterAppActions(app.id)}
          >
            <ArrowDownwardIcon />
          </StyledIconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default Row;

// FIXME: commons
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
