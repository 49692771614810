import { useCallback, useState } from "react";

const useForceUpdate = () => {
  const [, setValue] = useState(0);

  const forceUpdate = useCallback(() => setValue((value) => value + 1), []);

  return { forceUpdate };
};

export default useForceUpdate;
