import { Route, Routes } from "react-router-dom";
import { ImportMigrationPackageRoutes } from "./routes";
import { ViewAllPage } from "./ViewAllPage";
const ImportMigrationsPackagePage = () => {
  return (
    <Routes>
      <Route
        path={ImportMigrationPackageRoutes.VIEW_ALL}
        element={<ViewAllPage />}
      />
    </Routes>
  );
};

export default ImportMigrationsPackagePage;
