import { useSystemLanguage } from "hooks/useSystemLanguage";
import { useCallback, useMemo } from "react";
import { useGetAppSectionsQuery } from "__generated__/graphql/types";

const useAppSectionsTranslations = (appName: string) => {
  const { language } = useSystemLanguage();

  const { data: { apps } = {}, isLoading } = useGetAppSectionsQuery({
    ids: [appName],
    language,
  });

  const appSectionsMap = useMemo(
    () =>
      new Map(
        apps
          ?.find((app) => app.id === appName)
          ?.sections?.map((e) => [e.id, e.translation]) ?? []
      ),
    [appName, apps]
  );

  const appSectionsTranslations = useCallback(
    (sectionKey: string) => ({
      step: appSectionsMap.get(sectionKey)?.step ?? "", // FIXME:
      title: appSectionsMap.get(sectionKey)?.title ?? "", // FIXME:
      subtitle: appSectionsMap.get(sectionKey)?.subtitle ?? "", // FIXME:
    }),
    [appSectionsMap]
  );

  return {
    appSectionsTranslations,
    isLoadingSections: isLoading,
  };
};

export default useAppSectionsTranslations;
