import Fuse from "fuse.js";
import { useDialog } from "hooks/useDialog";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RowDef } from "./MatchcodeTable/types";

type UseMatchcodeOptions<TColKeys extends string> = {
  options: RowDef<TColKeys>[];
  value?: string;
  onChange: (value?: string) => void;
  searchKeys: TColKeys[];
};

export const useMatchcode = <T extends string>({
  options,
  value,
  onChange,
  searchKeys,
}: UseMatchcodeOptions<T>) => {
  const [textFilter, setTextFilter] = useState("");
  const [selectedRowId, setSelectedRowId] = useState<string>();

  const fuse = useMemo(() => new Fuse(options, { keys: searchKeys }), [
    options,
    searchKeys,
  ]);

  const visibleOptions = useMemo(
    () =>
      textFilter === ""
        ? options
        : fuse.search(textFilter).map(({ item }) => item),
    [textFilter, fuse, options]
  );

  const dialog = useDialog();

  const onSubmit = () => {
    onChange(selectedRowId);
    dialog.close();
  };

  const text = options.find((e) => e.id === value);

  const isDeleteButtonVisible = value !== undefined && value !== "";

  const onClearValue = useCallback(() => onChange(""), [onChange]); // FIXME:  onChange(undefined)

  useEffect(() => {
    if (!dialog.isOpen) return;
    const checkedKeys = value;
    setTextFilter("");
    if (checkedKeys === undefined) {
      setSelectedRowId(undefined);
    } else {
      setSelectedRowId(checkedKeys);
    }
  }, [value, setSelectedRowId, dialog.isOpen]);

  return {
    dialog,
    text,
    visibleOptions,
    selectedRowId,
    setSelectedRowId,
    onSubmit,
    textFilter,
    setTextFilter,
    isDeleteButtonVisible,
    onClearValue,
  };
};
