import { Clear as ClearIcon } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { CheckboxController } from "components/inputs/CheckboxController";
import { FormTableTextFieldController } from "components/inputs/FormTableTextFieldController";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import React from "react";
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from "react-hook-form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import { DataElementDtoForCreateTable } from "../../types";
import { generateDataElementDescription } from "../../utils";
import { CharacteristicsColumnsFormFields } from "../types";
import { DataElementMatchcode } from "./DataElementMatchcode";

type RowProps = {
  readOnly: boolean;
  index: number;
  remove: () => void;
  item: UseFieldArrayReturn<CharacteristicsColumnsFormFields>["fields"][number];
  characteristicsDataElementsByIdMap: Map<string, DataElementDtoForCreateTable>;
  form: UseFormReturn<CharacteristicsColumnsFormFields>;
};

const Row = ({
  index,
  remove,
  item,
  readOnly,
  characteristicsDataElementsByIdMap,
  form,
}: RowProps) => {
  const { control, watch, trigger, getValues } = form;

  const dataElementId = watch(
    `characteristics.${index}.dataElementId` as "characteristics.0.dataElementId"
  );

  const dataElements = Array.from(characteristicsDataElementsByIdMap.values());
  const dataElement = characteristicsDataElementsByIdMap.get(dataElementId);

  if (dataElement !== undefined && dataElement.translation === undefined)
    throw Error();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <KeyInputWrapper>
          <FormTableTextFieldController
            inputProps={{ disabled: readOnly }}
            controllerProps={{
              name: `characteristics.${index}.name` as "characteristics.0.name",
              control,
              rules: {
                required: "Ingrese el nombre de la columna.", // FIXME: traduccion
                pattern: {
                  value: keyNameInputRegexPattern,
                  message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                },
                validate: (value) => {
                  const columnsNames = getValues("characteristics")
                    .filter((_, idx) => idx !== index)
                    .map((e) => e.name);
                  return columnsNames.includes(value)
                    ? "El nombre ya ha sido utilizado." // FIXME: traduccion
                    : true;
                },
              },
              defaultValue: item.name,
            }}
          />
        </KeyInputWrapper>
      </TableCell>

      <TableCell component="th" scope="row" align="center">
        <CheckboxController
          controllerProps={{
            control: form.control,
            name: `characteristics.${index}.isPrimaryKey` as "characteristics.0.isPrimaryKey",
            defaultValue: item.isPrimaryKey,
          }}
          CheckboxProps={{
            inputProps: { disabled: readOnly },
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        <Controller
          control={control}
          name={
            `characteristics.${index}.dataElementId` as "characteristics.0.dataElementId"
          }
          rules={{ required: "Seleccione un elemento de dato." }} // FIXME: traduccion
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <DataElementMatchcode
              dataElementsOptions={dataElements}
              value={value}
              onChange={onChange}
              invalid={invalid}
              errorMessage={error?.message}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {dataElement?.translation?.description}
      </TableCell>

      <TableCell component="th" scope="row">
        {dataElement && generateDataElementDescription(dataElement)}
      </TableCell>

      <TableCell component="th" scope="row" align="center">
        <Checkbox
          color="primary"
          checked={dataElement?.isLanguageDependent ?? false}
          disabled
        />
      </TableCell>

      <TableCell align="right">
        {!readOnly && (
          <Tooltip
            title="Eliminar fila" // FIXME: traduccion
          >
            <IconButton
              aria-label="remove"
              size="small"
              onClick={() => {
                remove();
                trigger();
              }}
            >
              <StyledClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;

const StyledClearIcon = styled(ClearIcon)({
  color: grey[500],
});
