import {
  Check as CheckIcon,
  InfoOutlined as InfoOutlinedIcon,
  ListRounded as ListRoundedIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { FormBodyWrapper } from "components/datadisplay/FormBodyWrapper";
import { FormHeader } from "components/datadisplay/FormHeader";
import { AlertUnsavedDataInput } from "components/feedback/AlertUnsavedDataInput";
import { DescriptionFormControl } from "components/inputs/DescriptionFormControl";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { SuccessMessage } from "components/feedback/SuccessMessage";
import { SaveCloseButtons } from "components/inputs/SaveCloseButtons";
import { FormStepper } from "components/navigation/FormStepper";
import { useAppSectionsTranslations } from "hooks/useAppSectionsTranslations";
import { useFormSteps } from "hooks/useFormSteps";
import { StepBehavior } from "hooks/useFormSteps/hook";
import { useSnackbar } from "hooks/useSnackbarV2";
import React from "react";
import { FormProps } from "types/Form";
import { assertUnreachable } from "utils/assert";
import { FormStep } from "utils/FormStep";
import { CostDataForm } from "./CostDataForm";
import { RevenueDataForm } from "./RevenueDataForm";
import { BasicDataForm } from "./BasicDataForm";
import { FormFields, FormStepsKeys, NonFormStepKeys } from "./types";
import { useForms } from "./useForms";

const Form = ({
  initialValue,
  initialStep,
  mode,
  errorMessage = "", // FIXME:
  onSave = assertUnreachable, // FIXME:
  onCancel = assertUnreachable, // FIXME:
  onEdit,
}: FormProps<FormFields>) => {
  const readOnly = mode === "view";

  const errorSnackbar = useSnackbar();

  const forms = useForms(initialValue);
  const type = forms.basicData.watch("type");

  const {
    appSectionsTranslations,
    isLoadingSections,
  } = useAppSectionsTranslations("BUSINESS_UNITS");

  const stepsKeysInOrder: StepBehavior<FormStepsKeys, NonFormStepKeys>[] = [
    { key: "basicData", hasForm: true },
    { key: "descriptions", hasForm: true },
    { key: "revenueData", hasForm: true, isHidden: type === "COST" },
    { key: "costData", hasForm: true, isHidden: type === "REVENUE"},
    { key: "save", isHidden: readOnly },
    { key: "success", isHidden: readOnly },
  ];

  const stepsNavigation = useFormSteps({
    stepsKeysInOrder,
    errorSnackbar,
    forms,
    initialStep,
    errorMessage,
    readOnly,
    onSave,
    onEdit,
  });

  const unorderedSteps: Record<FormStepsKeys | NonFormStepKeys, FormStep> = {
    basicData: {
      ...appSectionsTranslations("BASIC_DATA"),
      icon: <InfoOutlinedIcon />,
      body: (
        <BasicDataForm
          form={forms.basicData}
          mode={mode}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    descriptions: {
      ...appSectionsTranslations("DESCRIPTIONS"), // FIXME: traduccion
      icon: <ListRoundedIcon />,
      subtitle: "Ingrese las descripciones de la unidad comercial", // FIXME: traduccion
      body: (
        <DescriptionFormControl
          form={forms.descriptions}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    revenueData: {
      ...appSectionsTranslations("REVENUE_DATA"),
      icon: <AttachMoneyIcon />,
      body: (
        <RevenueDataForm
          form={forms.revenueData}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    costData: {
      ...appSectionsTranslations("COST_DATA"),
      icon: <MoneyOffIcon />,
      body: (
        <CostDataForm
          form={forms.costData}
          readOnly={readOnly}
          onEdit={stepsNavigation.onEditActiveStep}
        />
      ),
    },
    save: {
      title: "Guardar", // FIXME: traduccion
      subtitle: "¿Desea guardar los valores ingresados?", // FIXME: traduccion
      icon: <SaveIcon />,
      isSaveButtonVisible: true,
    },
    success: {
      title: "Fin", // FIXME: traduccion
      icon: <CheckIcon />,
      body: <SuccessMessage />,
      isHeaderHidden: true,
    },
  };

  const currentStep = unorderedSteps[stepsNavigation.activeStepKey];

  const visibleSteps = stepsNavigation.visibleStepsKeysInOrder.map(
    (key) => unorderedSteps[key]
  );

  if (isLoadingSections) return null; // TODO: loading spinner

  return (
    <>
      <AlertUnsavedDataInput
        isBlocked={stepsNavigation.isRouteBlocked}
        unblock={stepsNavigation.unblockRoute}
      />

      <SnackbarAlert
        open={errorSnackbar.isOpen}
        onClose={errorSnackbar.close}
        severity="error"
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <FormStepper
        steps={visibleSteps}
        activeStepIndex={stepsNavigation.stepper.activeStepIndex}
        isNonLinear={readOnly}
        onChangeActiveStepIndex={stepsNavigation.stepper.setActiveStepIndex}
      />

      {!currentStep.isHeaderHidden && (
        <FormHeader
          title={currentStep.title}
          subtitle={currentStep.subtitle}
          step={currentStep.step}
          leftButtonText={currentStep.leftButtonText}
          isPreviousButtonVisible={stepsNavigation.isPreviousButtonVisible}
          isNextButtonVisible={stepsNavigation.isNextButtonVisible}
          isUpButtonVisible={stepsNavigation.isUpButtonVisible}
          onPrevious={stepsNavigation.onPrevious}
          onNext={stepsNavigation.onNext}
          onUp={stepsNavigation.onUp}
        />
      )}

      <FormBodyWrapper>{currentStep.body}</FormBodyWrapper>

      {!readOnly && (
        <SaveCloseButtons
          isCancelButtonVisible
          isSaveButtonVisible={currentStep.isSaveButtonVisible ?? false} // FIXME:
          onCancel={onCancel}
          onSave={stepsNavigation.onClickSave}
        />
      )}
    </>
  );
};

export default Form;
