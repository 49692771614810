import { urlToFile } from "utils/urlToFile";
import { GetThemeByIdQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreateThemePage/Form/types";

export const mapDtoToForm = async ({
  theme,
}: {
  theme: NonNullable<GetThemeByIdQuery["theme"]>;
}): Promise<FormFields> => {
  return {
    basicData: {
      name: theme.id,
    },
    descriptions: {
      descriptions: theme.translations.map(({ language, description }) => ({
        text: description,
        language,
      })),
    },
    primaryColor: {
      primaryColor: theme.primaryColor,
      primaryColorContrast: theme.primaryColorContrast,
      userColor: theme.userColor,
    },
    backgroundImage: {
      backgroundImage: await urlToFile(
        theme.backgroundImage.url,
        theme.backgroundImage.name
      ),
    },
    appsColor: {
      appsColor: theme.appsColor,
      appsTitleColor: theme.appsTitleColor,
    },
    stepperColor: {
      color1: theme.stepperColor1,
      color2: theme.stepperColor2,
      color3: theme.stepperColor3,
      internalColor: theme.stepperInternalColor,
      textColor: theme.stepperTextColor,
    },
    companyLogo: {
      companyLogo: theme.companyLogo != null 
        ? await urlToFile(
          theme.companyLogo.url,
          theme.companyLogo.name
        )
        : null,
    },
  };
};
