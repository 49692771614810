export enum PurchaseEntityRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":purchaseEntityId/view",
  EDIT = ":purchaseEntityId/edit",
}

export type PurchaseEntityRoutesState = {
  [PurchaseEntityRoutes.EDIT]: { activeStep?: number };
};
