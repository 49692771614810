import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ascend, sortWith } from "ramda";
import { RootState } from "store";
import {
  isColumnVisible,
  isPairColumnHidden,
  isPairColumnVisible,
} from "utils/columnTypesUtils";
import { push } from "utils/push";

type DataElementHiddenColumn = { isVisible: false };

type DataElementVisibleColumn = { isVisible: true; position: number };

export type DataElementColumn =
  | DataElementVisibleColumn
  | DataElementHiddenColumn;

export const DATA_ELEMENTS_COLUMNS_KEYS = [
  "name",
  "description",
  "used",
  "status",
  "dataType",
  "length",
  "decimals",
  "isLanguageDependent",
] as const;

export const dataElementsColumnsLabels: Record<
  DataElementColumnKeys,
  string
> = {
  name: "Nombre", // FIXME: traduccion
  description: "Descripción", // FIXME: traduccion
  used: "Usado", // FIXME: traduccion
  dataType: "Tipo de dato", // FIXME: traduccion
  status: "Estatus", // FIXME: traduccion
  length: "Longitud", // FIXME: traduccion
  decimals: "Decimales", // FIXME: traduccion
  isLanguageDependent: "Dependiente de idioma", // FIXME: traduccion
};

export type DataElementColumnKeys = typeof DATA_ELEMENTS_COLUMNS_KEYS[number];

const themesColumnsInitialValues = DATA_ELEMENTS_COLUMNS_KEYS.reduce(
  (acc, curr, index) => push(acc, curr, { isVisible: true, position: index }),
  {} as Record<DataElementColumnKeys, DataElementColumn>
);

type DataElementsColumnsState = Record<
  DataElementColumnKeys,
  DataElementColumn
>;

const initialState: DataElementsColumnsState = themesColumnsInitialValues;

const viewDataElementsColumnsSlice = createSlice({
  name: "dataElementsVisibleColumnsSlice",
  initialState,
  reducers: {
    hideColumn: (
      state,
      action: PayloadAction<{ id: DataElementColumnKeys }>
    ) => {
      const { id } = action.payload;
      state[id] = { isVisible: false };
    },
    showColumn: (
      state,
      action: PayloadAction<{ id: DataElementColumnKeys }>
    ) => {
      const { id } = action.payload;
      const positions = Object.values(state)
        .filter(isColumnVisible<DataElementColumn, DataElementVisibleColumn>())
        .map((e) => e.position);
      const maxPosition = Math.max(...positions);
      state[id] = { isVisible: true, position: maxPosition + 1 };
    },
    orderColumns: (
      state,
      action: PayloadAction<{ ids: DataElementColumnKeys[] }>
    ) => {
      action.payload.ids.forEach((value, index) => {
        const themeColumn = state[value];
        if (
          !isColumnVisible<DataElementColumn, DataElementVisibleColumn>()(
            themeColumn
          )
        )
          return;
        themeColumn.position = index;
      });
    },
  },
});

export const {
  hideColumn,
  showColumn,
  orderColumns,
} = viewDataElementsColumnsSlice.actions;

export const selectThemeColumnsValues = (state: RootState) =>
  state.dataElements.columns;

const sorter = sortWith<[DataElementColumnKeys, DataElementVisibleColumn]>([
  ascend(([, value]) => value.position),
]);

export const selectDataElementsVisibleColumnsKeysInOrder = (
  state: RootState
): DataElementColumnKeys[] => {
  const visibleColumns = Object.entries(state.dataElements.columns).filter(
    isPairColumnVisible<
      DataElementColumn,
      DataElementVisibleColumn,
      DataElementColumnKeys
    >(DATA_ELEMENTS_COLUMNS_KEYS)
  );

  return sorter(visibleColumns).map(([key]) => key);
};

export const selectDataElementsHiddenColumnsKeys = (
  state: RootState
): DataElementColumnKeys[] => {
  return Object.entries(state.dataElements.columns)
    .filter(
      isPairColumnHidden<
        DataElementColumn,
        DataElementHiddenColumn,
        DataElementColumnKeys
      >(DATA_ELEMENTS_COLUMNS_KEYS)
    )
    .map(([key]) => key);
};

export default viewDataElementsColumnsSlice.reducer;
