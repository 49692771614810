import { MenuItem, styled, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { FieldPath, FieldValues, useController } from "react-hook-form";
import { mapEmptyStringToUndefined } from "utils/mapEmptyStringToUndefined";
import { FilterComponentProps } from "../types";

const SelectFilter = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  controllerProps,
  options,
}: FilterComponentProps<TFieldValues, TName> & { options: SelectOption[] }) => {
  const {
    field: { name, value, onChange, onBlur },
  } = useController<TFieldValues, TName>(controllerProps);

  return (
    <Select
      name={name}
      label={label}
      // @ts-ignore FIXME:
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
    />
  );
};

export default SelectFilter;

type SelectOption = { value: string; text: string };

type SelectFilterProps = {
  value?: string;
  onChange: (value?: string) => void;
} & Omit<TextFieldProps, "value" | "onChange"> & { options: SelectOption[] };

const Select = ({
  name,
  label,
  value,
  onChange,
  options,
  ...rest
}: SelectFilterProps) => {
  return (
    <StyledTextField
      name={name}
      label={label}
      variant="outlined"
      color="primary"
      size="small"
      margin="none"
      fullWidth
      value={value ?? []}
      onChange={(event) => {
        onChange(mapEmptyStringToUndefined(event.target.value));
      }}
      select
      {...rest}
      SelectProps={{
        multiple: true,
      }}
    >
      {options.map(({ text, value }) => (
        <MenuItem value={value}>{text}</MenuItem>
      ))}
    </StyledTextField>
  );
};

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& fieldset": {
    borderColor: theme.palette.primary.dark,
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-root.Mui-focused fieldset": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
    borderColor: theme.palette.primary.main,
  },
}));
