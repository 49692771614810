import {
  AddCircle as AddCircleIcon,
  Edit as EditCircleIcon
} from "@mui/icons-material";
import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";
import useAppMessages from 'hooks/useAppMessages/hook';
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { HeaderProps } from "./types";

const Header = ({ readOnly = false, onEdit, onAppend }: HeaderProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "ADDRESS",
    "IS_FISCAL_ADDRESS",
  ]);
  
  const { appMessage } = useAppMessages("MESSAGES");

  if (dataElementsDescriptions == null) return <BeatLoadingPage/>;

  return (
    <StyledTableHead>
      <TableRow>
        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["ADDRESS"]}
        </StyledHeadTableCell>

        <StyledHeadTableCell align="left">
          {dataElementsDescriptions["IS_FISCAL_ADDRESS"]}
        </StyledHeadTableCell>

        <TableCell align="right">
          {readOnly ? (
            <Tooltip
              title={appMessage["EDIT"]}
            >
              <IconButton aria-label="edit" size="small" onClick={onEdit}>
                <StyledEditCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={appMessage["ADD_ROW"]}
            >
              <IconButton aria-label="add" size="small" onClick={onAppend}>
                <StyledAddCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Header;

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledEditCircleIcon = styled(EditCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
