export enum LanguageRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":languageId/view",
  EDIT = ":languageId/edit",
}

export type LanguageRoutesState = {
  [LanguageRoutes.EDIT]: { activeStep?: number };
};
