import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React, {useEffect, useState} from "react";
import { useGetBusinessProfilesForCreateUserQuery } from "__generated__/graphql/types";
import { BusinessProfileFormProps } from "./types";
import useAppMessages from 'hooks/useAppMessages/hook';

const BusinessProfileForm = ({
  form: { control },
  readOnly = false,
  onEdit,
}: BusinessProfileFormProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "BUSINESS_PROFILE",
    "DESCRIPTION",
  ]);
  const [actualBusinessProfiles, setActualBusinessProfiles] = useState<Array<any>>([]);
  const { language } = useSystemLanguage();
  const { appMessage } = useAppMessages("USERS");
  const {
    data: { businessProfiles } = {},
  } = useGetBusinessProfilesForCreateUserQuery({
    language,
  });
  useEffect(()=>{
    const href = window.location.href;
    let filteredProfiles: any[] = [];
    if(businessProfiles?.length && href.includes('styropek.appliqations') ){
      // eslint-disable-next-line
      filteredProfiles = businessProfiles?.filter((profile:any) => {
        if(profile.id === "ADMIN_BVPV" || profile.id === "VENDOR" || profile.id === "DELIVERY"){
          return profile
        }
      })
    }
    if(filteredProfiles.length > 0){
      setActualBusinessProfiles(filteredProfiles ?? []);
    }else{
      setActualBusinessProfiles(businessProfiles ?? []);
    }
  }, [businessProfiles])
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <MatchcodeInputController
              label={dataElementsDescriptions["BUSINESS_PROFILE"]}
              controllerProps={{
                name: "businessProfileId",
                control,
                rules: { required: appMessage["ENTER_POSTYPE"] ??"Please select the position type" }, ///FIXPRD QUITAR
              }}
              column={[
                {
                  key: "businessProfile",
                  text: dataElementsDescriptions["BUSINESS_PROFILE"],
                },
                {
                  key: "description",
                  text: dataElementsDescriptions["DESCRIPTION"],
                },
              ]}
              displayColumn="businessProfile"
              options={
                actualBusinessProfiles?.map((e) => ({
                  id: e.id,
                  businessProfile: e.id,
                  description: e.translation.description,
                })) ?? []
              }
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BusinessProfileForm;
