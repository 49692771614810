export enum LocationTypeRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":locationTypeId/view",
  EDIT = ":locationTypeId/edit",
}

export type LocationTypeRoutesState = {
  [LocationTypeRoutes.EDIT]: { activeStep?: number };
};
