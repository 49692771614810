import { styled, Table, TableBody, TableContainer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import { useWatch } from "react-hook-form";
import { useGetAppsForCreateBusinessProfilesQuery } from "__generated__/graphql/types";
import { Header } from "./Header";
import { Row } from "./Row";
import { AppsFormProps } from "./types";

const AppsForm = ({
  readOnly = false,
  onEdit,
  onEnterAppActions,
  forms,
}: AppsFormProps) => {
  const { language } = useSystemLanguage();

  const groupsIds = useWatch({
    control: forms.groups.control,
    name: "groups",
  }).map((e) => e.groupId);

  const { data: { apps } = {} } = useGetAppsForCreateBusinessProfilesQuery({
    language,
    groupsIds,
  });

  if (apps === undefined) return null; // TODO: loading spinner

  return (
    <TableContainer>
      <StyledTable aria-label="simple table" size="small">
        <Header onEdit={() => onEdit?.()} readOnly={readOnly} />

        <StyledTableBody>
          {apps.map((app) => (
            <Row
              key={app.id}
              readOnly={readOnly}
              onEnterAppActions={onEnterAppActions}
              app={app}
              form={forms.permissions}
            />
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default AppsForm;

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.wrapperBorderRadius,
  overflow: "hidden",
}));

const StyledTableBody = styled(TableBody)({
  backgroundColor: grey[100],
});
