import noImage from "assets/img/no-image.png";

export const urlToFile = (url: string, filename: string) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => new File([blob], filename, { type: blob.type }))
    .catch((error) => {
      return fetch(noImage)
        .then((res) => res.blob())
        .then((blob) => new File([blob], 'no-image', { type: blob.type }));
    });
};

export const urlToPdfFile = (url: string, filename: string) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => new File([blob], filename, { type: "application/pdf" }));
};
