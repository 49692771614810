import { GetDataElementViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDataElementsDtoToForm = (
  dto: NonNullable<GetDataElementViewPageQuery["dataElement"]>
): FormFields => {
  return {
    basicData: {
      name: dto.id,
      dataType: dto.dataType, // FIXME:
      decimals: dto.decimals as any, // FIXME:
      length: dto.length as any, // FIXME:
      isLanguageDependent: dto.isLanguageDependent as any, // FIXME:
    },
    descriptions: {
      descriptions: dto.translations.map((e) => ({
        language: e.language,
        text: e.description,
      })),
    },
    options: {
      options:
        dto.choices?.map((e) => ({
          name: e.id,
          descriptions: e.translations.map((e) => ({
            language: e.language,
            text: e.description,
          })),
        })) ?? [],
    },
  };
};
