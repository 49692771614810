import { urlToFile } from "utils/urlToFile";
import { GetUserForViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = async (
  dto: NonNullable<GetUserForViewPageQuery["user"]>
): Promise<FormFields> => {
  return {
    basicData: {
      userName: dto.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email,
      phoneNumber: dto.phoneNumber,
      userType: dto.userType,
    },
    configurationData:{
      dateFormat: dto.dateFormat,
      decimalSeparator:dto.decimalSeparator,
      language: dto.language.id,
      numberDecimalsPlaces: dto.numberDecimalsPlaces,
      timeFormat: dto.timeFormat,
    },
    image: {
      image: await urlToFile(dto.image.url, dto.image.name),
    },
    businessProfile: {
      businessProfileId: dto.businessProfile.id,
    },
  };
};
