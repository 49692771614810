import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormMode } from "types/Form";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  ThemeExistsDocument,
  ThemeExistsQuery,
  ThemeExistsQueryVariables,
} from "__generated__/graphql/types";
import useAppMessages from 'hooks/useAppMessages/hook';

export type BasicDataFormFields = {
  name: string;
};

export type BasicDataFormProps = {
  form: UseFormReturn<BasicDataFormFields>;
  mode: FormMode;
  onEdit?: () => void;
};

const BasicDataForm = ({ form, mode, onEdit }: BasicDataFormProps) => {
  const { control } = form;

  const { dataElementsDescriptions } = useDataElementDescription(["THEME"]);
  const { appMessage } = useAppMessages("THEMES");
  const { appMessage: appMsg } = useAppMessages("MESSAGES");

  const readOnly = mode === "view";
  const isIdReadOnly = mode !== "create";

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool control={control} placement="top-left" />

      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <KeyInputWrapper>
            <TextFieldController
              inputProps={{ disabled: isIdReadOnly }}
              label={dataElementsDescriptions["THEME"]}
              controllerProps={{
                name: "name",
                control,
                rules: {
                  required: appMessage["ENTER_NAME"],
                  validate: async (value) => {
                    if (isIdReadOnly) return true;
                    const { themeExists } = await themeExistsFn({
                      themeId: value,
                    });
                    return themeExists ? appMessage["NAME_EXIST"] ?? "The name already exists" : true;
                  },
                  pattern: {
                    value: keyNameInputRegexPattern,
                    message: appMsg["ALLOWED_CHARACTERS"],
                  },
                },
              }}
            />
          </KeyInputWrapper>
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const themeExistsFn = pDebounce(
  (args: ThemeExistsQueryVariables) =>
    fetcher<ThemeExistsQuery, ThemeExistsQueryVariables>(
      ThemeExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
