import { Avatar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserPreferencesDialog } from "components/user/UsePreferences";
import React, { useState } from "react";
import useAppMessages from 'hooks/useAppMessages/hook';

type AvatarWithLabelProps = {
  iconSrc: string;
  userName: string;
};

const AvatarWithLabel = ({ iconSrc, userName }: AvatarWithLabelProps) => {
  const [areUserPreferencesVisible, setAreUserPreferencesVisible] = useState(
    false
  );
  const { appMessage } = useAppMessages("MAIN");

  return (
    <>
      {areUserPreferencesVisible && (
        <UserPreferencesDialog
          isOpen={areUserPreferencesVisible}
          close={() => setAreUserPreferencesVisible(false)}
        />
      )}

      <StyledDiv id="img-avatar">        
        <StyledAvatar
          src={iconSrc}
          onClick={() => {
            setAreUserPreferencesVisible(true);
          }}
          style={{
            cursor: "pointer",
          }}
        />
        <StyledTypography noWrap align="center">
          { appMessage["HELLO"]}, {userName}
        </StyledTypography>
      </StyledDiv>
    </>
  );
};

export default AvatarWithLabel;

const StyledDiv = styled("div")({});

const StyledAvatar = styled(Avatar)({
  width: "60px",
  height: "60px",
  objectFit: "cover",
  borderRadius: "50%",
  marginLeft: "auto",
  marginRight: "auto",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.username,
}));
