export enum CountriesRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":countryId/view",
  EDIT = ":countryId/edit",
}

export type CountriesRoutesState = {
  [CountriesRoutes.EDIT]: { activeStep?: number };
};
