import { DescriptionMigrationFormFields, DescriptionMigrationFormProps } from "./types";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";

export const emptyDescriptionRow: DescriptionMigrationFormFields = {
  text: ""
};

const DescriptionMigrationForm = ({
  form,
  mode,
  isUsed,
  onEdit,
}: DescriptionMigrationFormProps) => {
  const { control } = form;

  const readOnly = mode === "view" ? true : false;
  const disabled = mode === "view" ? true : false;
  
  return (
    // <>
    <FormNonTableBodyWrapper readOnly={disabled} onEdit={onEdit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={10}>
            <TextFieldController
              inputProps={{ disabled: readOnly }}
              label={"Descripción"} // FIXME: Traducción
              controllerProps={{
                name: "text", // FIXME: Traducción
                control,
                rules: {
                  required: "Ingrese la descripción de la migración.", // FIXME: traduccion
                  pattern: {
                    value: new RegExp("^([A-Za-z]|[0-9]|_||\\s)+$"), //FIXME - Testar pattern
                    message: "Los caracteres permitidos son A-Z, 0-9 y _.", // FIXME: traduccion
                  },
                },
              }}
            />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default DescriptionMigrationForm;