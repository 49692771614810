import React from "react";
import { Route, Routes } from "react-router-dom";
import { CreatePage } from "./CreatePage";
import { ChartOfAccountRoutes } from "./routes";
import { EditPage } from "./EditPage";
import { ViewAllPage } from "./ViewAllPage";
import { ViewPage } from "./ViewPage";

const ChartOfAccountPage = () => {
  return (
    <Routes>
      <Route path={ChartOfAccountRoutes.VIEW_ALL} element={<ViewAllPage />} />

      <Route path={ChartOfAccountRoutes.VIEW} element={<ViewPage />} />

      <Route path={ChartOfAccountRoutes.CREATE} element={<CreatePage />} />

      <Route path={ChartOfAccountRoutes.EDIT} element={<EditPage />} />
    </Routes>
  );
};

export default ChartOfAccountPage;
