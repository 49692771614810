import { BusinessPartnerInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): BusinessPartnerInput => {
  return {
    name: form.basicData.name,
    identification: form.basicData.identification,
    lastName: form.basicData.lastname,
    taxName: form.basicData.taxname,
    locations: form.locations.address.map((e) => ({
      address: {
        street: e.descriptions.street,
        number: e.descriptions.number,
        floor: e.descriptions.floor,
        department: e.descriptions.department,
        city: e.descriptions.city,
        postalCode: e.descriptions.postalCode,
        state: e.descriptions.state,
        country: e.descriptions.country,
      },
      isFiscalAddress: e.isFiscalAddress,
    })),
    taxId: {
      countryId: form.idsData.country,
      typeId: form.idsData.countryId,
      id: form.idsData.noId
    },
    businessPartnerType: form.businessType.types.map((e) => ({
      businessPartnerTypeId: e.businessType
    })),
    contact: form.contacts.contact.map((e) => ({
      userId: !e._isUser ? null : e.userId,
      email: e._isUser ? null : e.email,
      name: e._isUser ? null : e.firstName,
      lastName: e._isUser ? null : e.lastName,
      phoneNumber: e._isUser ? null : e.phoneNumber,
      position: e._isUser ? null : e.position,
      birthDate: e._isUser ? null : e.birthDate.toJSON().slice(0, 10),
    })),
    backend: form.backend.backends.map((e) => ({
      backendId: e.idBackend,
      externalId: e.idBackendExt,
    })),
  };
};