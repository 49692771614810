import { DevTool } from "@hookform/devtools";
import React from "react";
import { GatesDescriptionsForm } from "./GatesDescriptionsForm";
import { GatesNamesForm } from "./GatesNamesForm";
import { GatesFormProps } from "./types";

const GatesForm = ({
  areDescriptionsVisible,
  form,
  externalIndex,
  readOnly,
  onEdit,
  onEnterDescriptions,
  onExitDescriptions,
}: GatesFormProps) => {
  return (
    <>
      <DevTool control={form.control} placement="top-left" />

      {areDescriptionsVisible && externalIndex !== undefined ? (
        <GatesDescriptionsForm
          onFinish={onExitDescriptions}
          formMethods={form}
          externalIndex={externalIndex}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      ) : (
        <GatesNamesForm
          form={form}
          onEnterDescriptions={onEnterDescriptions}
          readOnly={readOnly}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default GatesForm;
