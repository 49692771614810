import { createTheme, ThemeOptions } from "@mui/material";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store";
import { fileToBase64 } from "utils/fileToBase64";

type ThemeState = {
  isThemeFromBackend: boolean;
  options?: ThemeOptions;
  backgroundImage?: string;
  companyLogo?: string;
  currentThemeId?: string;
};

export const fixedThemeOptions: ThemeOptions = {
  shape: {
    wrapperBorderRadius: "25px",
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "25px",
        },
      },
    },
  },
};

const initialState: ThemeState = {
  isThemeFromBackend: true,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeOptions: (state, action: PayloadAction<ThemeOptions>) => {
      // @ts-ignore FIXME:
      state.options = { ...action.payload, ...fixedThemeOptions };
    },
    setIsThemeFromBackend: (state, action: PayloadAction<boolean>) => {
      state.isThemeFromBackend = action.payload;
    },
    setBackgroundImage: (state, action: PayloadAction<string>) => {
      state.backgroundImage = action.payload;
    },
    setCompanyLogo: (state, action: PayloadAction<string | undefined>) => {
      state.companyLogo = action.payload;
    },
    setCurrentThemeId: (state, action: PayloadAction<string>) => {
      state.currentThemeId = action.payload;
    },
  },
});

export const {
  setThemeOptions,
  setIsThemeFromBackend,
  setBackgroundImage,
  setCompanyLogo,
  setCurrentThemeId,
} = themeSlice.actions;

export const selectThemeOptions = (state: RootState) => state.theme.options;

export const selectCurrentThemeId = (state: RootState) =>
  state.theme.currentThemeId;

export const setBackgroundImageFile = (
  backgroundImage: File
): AppThunk => async (dispatch) => {
  if (!backgroundImage.type.startsWith("image/")) return;
  const imageUrl = await fileToBase64(backgroundImage);
  if (typeof imageUrl !== "string") return;
  dispatch(setBackgroundImage(imageUrl));
};

export const setCompanyLogoFile = (
  companyLogo: File
): AppThunk => async (dispatch) => {
  if (!companyLogo.type.startsWith("image/")) return;
  const imageUrl = await fileToBase64(companyLogo);
  if (typeof imageUrl !== "string") return;
  dispatch(setCompanyLogo(imageUrl));
};

export const selectBackgroundImage = (state: RootState) =>
  state.theme.backgroundImage;

export const selectCompanyLogo = (state: RootState) =>
  state.theme.companyLogo;

export const selectMuiTheme = createSelector(
  selectThemeOptions,
  (themeOptions) => themeOptions && createTheme(themeOptions)
);

export const selectIsThemeFromBackend = (state: RootState) =>
  state.theme.isThemeFromBackend;

export default themeSlice.reducer;
