import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateThemeMutation, useGetThemeInformationQuery } from "__generated__/graphql/types";
import { mapDtoToForm, mapFormToDto } from "./utils";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { useUploadBackgroundImageToS3 } from "./useUploadBackgroundImageS3";
import { notNullUndefined } from '../../../utils/notNullUndefined';
import useAppMessages from 'hooks/useAppMessages/hook';
import { LoadingLayoutPage } from 'components/layout/LoadingLayoutPage';

const CreateThemePage = () => {

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<FormFields>();

  const { data: { me } = {} } = useGetThemeInformationQuery();

  const { mutateAsync: createTheme } = useCreateThemeMutation();
  const { appMessage } = useAppMessages("THEMES");

  const {
    mutateAsync: uploadBackgroundImageFile,
  } = useUploadBackgroundImageToS3();

  const onSave = async (formFields: FormFields) => {
    
    const backgroundImageId = await uploadBackgroundImageFile(
      formFields.backgroundImage.backgroundImage
    );
    const companyLogoId = notNullUndefined(formFields.companyLogo.companyLogo) 
      ? await uploadBackgroundImageFile(formFields.companyLogo.companyLogo)
      : null;

    const theme = mapFormToDto(formFields, backgroundImageId.fileId, companyLogoId?.fileId);
    await createTheme({ theme });
  };

  // Corregir el bug, no se actualiza el tema si se cambia la configuración del usuario
  useEffect(() => {

    if (me?.effectiveTheme == null) return;
    mapDtoToForm({
      theme: me?.effectiveTheme,
    })
    .then((initValues) => setInitialValues(initValues));

  }, [me]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <Layout>
      <Header
        title={appMessage["NEW_THEME"]}
        toBack={TO_BACK_ROUTER}
      />

      <Form
        initialValue={initialValues as any} // FIXME:
        errorMessage={appMessage["ERROR_CREATE"]}
        mode="create"
        onSave={onSave}
        onCancel={() => navigate(TO_BACK_ROUTER)}
      />
    </Layout>
  );
};

export default CreateThemePage;

const TO_BACK_ROUTER = "../";
