import { BusinessObjectInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): BusinessObjectInput => {
  return {
    dataElementId: form.basicData.dataElementId,
    tablesIds: form.tables.tables.map((e) => ({
      isHeader: e.isHeader,
      tableId: e.tableId,
    })),
  };
};
