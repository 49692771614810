import React from "react";
import { FiltersDialog } from "components/filters/FiltersDialog";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  selectIsFilterVisible,
  setFilterVisibility,
  FiltersKeys,
  FILTERS_KEYS,
} from "../../../reducers/filtersAndPaginationSlice";
import { usePurchaseOrderItemsLabels } from "apps/PurchaseOrdersItemsPage/ViewPage/usePurchaseOrderItemsLabels";

type FiltersVisibilityDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const FiltersVisibilityDialog = ({
  open,
  handleClose,
}: FiltersVisibilityDialogProps) => {

  const dispatch = useAppDispatch();
  const { columnsLabels } = usePurchaseOrderItemsLabels();

  const isFilterVisible = useAppSelector(selectIsFilterVisible);

  const onChangeFilters = (key: FiltersKeys) => (isVisible: boolean) => {
    dispatch(setFilterVisibility({ key, isVisible }));
  };

  const filters = FILTERS_KEYS.map((key) => ({
    key,
    label: columnsLabels[key],
    checked: isFilterVisible[key],
    onChange: onChangeFilters(key),
  }));

  return (
    <FiltersDialog open={open} handleClose={handleClose} filters={filters} />
  );
};

export default FiltersVisibilityDialog;
