import { TableCellProps } from "@mui/material";
import {
  TableColumnType
} from "components/datadisplay/GenericTable/template";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useAppMessages } from "hooks/useAppMessages";
import { useSnackbar } from "hooks/useSnackbar";
import { useCallback, useMemo } from "react";
import { push } from "utils/push";
import {
  checkAllRows,
  checkRow,
  selectAreAllRowsChecked,
  selectIsRowChecked
} from "../../reducers/checkedRowsSlice";
import {
  nextPage,
  prevPage
} from "../../reducers/filtersAndPaginationSlice";
import {
  COLUMNS_KEYS,
  ColumnsKeys,
  selectVisibleColumnsKeysInOrder
} from "../../reducers/visibleColumnsSlice";
import { usePurchaseOrderLabels } from "../../usePurchaseOrderLabels";

export const useTable = () => {

  const dispatch = useAppDispatch();
  const isDataElementsRowChecked = useAppSelector(selectIsRowChecked);
  
  const errorSnackbar = useSnackbar();
  const { columnsLabels } = usePurchaseOrderLabels();
  
  const { appMessage } = useAppMessages("PURCHASE_ORDERS");
  const orderedColumnsKeys = useAppSelector(selectVisibleColumnsKeysInOrder);
  const areAllDataElementsRowsChecked = useAppSelector(selectAreAllRowsChecked);

  const columns: TableColumnType<ColumnsKeys>[] = useMemo(
    () => {
      const header = COLUMNS_KEYS.reduce((acc, curr) => {
        return push(acc, curr, {
          key: curr,
          content: columnsLabels[curr].toUpperCase(),
          align: columnAlign[curr],
        });
      }, {} as Record<ColumnsKeys, TableColumnType<ColumnsKeys>>);
      return orderedColumnsKeys.map((colKey) => header[colKey]);
    },
    [orderedColumnsKeys, columnsLabels]
  );

  const onChangeCheckedRow = useCallback(
    (rowId: string, checked: boolean) => {
      dispatch(checkRow({ id: rowId, checked }));
    },
    [dispatch]
  );

  const onChangeAllRowsChecked = useCallback(
    (checked: boolean) => {
      dispatch(checkAllRows(checked));
    },
    [dispatch]
  );

  const onPrevPage = useCallback(() => {
    dispatch(prevPage());
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  return {
    errorSnackbar,
    appMessage,
    columns,
    isDataElementsRowChecked,
    onChangeCheckedRow,
    areAllDataElementsRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
  };
};

const columnAlign: Partial<Record<ColumnsKeys, TableCellProps["align"]>> = {
  total: "right"
};