import { GetAccountingEntity_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetAccountingEntity_ViewPageQuery["accountingEntity"]>
): FormFields => {
  return {
    basicData: {
      accountingEntity: dto.id,
      name: dto.name,
      currency: dto.currency.id,
      company: dto.company.id,
    },
    addressData: {
      street: dto.address.street,
      number: dto.address.number,
      floor: dto.address.floor ? dto.address.floor : "",
      department: dto.address.department ? dto.address.department : "",
      city: dto.address.city,
      postalCode: dto.address.postalCode,
      state: dto.address.state,
      country: dto.address.country,
    },
  };
};
