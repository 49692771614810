import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { ascend, sortWith } from "ramda";
import React from "react";

export type FilterDialogElement = {
  key: string;
  label: string;
};

type FiltersDialogProps = {
  open: boolean;
  handleClose: () => void;
  filters: FilterDialogElement[];
  isChecked: (key: string) => boolean;
  onChangeChecked: (key: string, checked: boolean) => void;
};

const FiltersDialog = ({
  filters,
  open,
  handleClose,
  isChecked,
  onChangeChecked,
}: FiltersDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {/* // FIXME: traduccion */}
        ¿Qué filtros desea aplicar?
      </DialogTitle>

      <DialogContent>
        <Grid container item wrap="wrap">
          {sortByLabelAsc(filters).map(({ key, label }) => (
            <Grid key={key} item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked(key)}
                    onChange={(event) => {
                      onChangeChecked(key, event.target.checked);
                    }}
                    name={`filter-checkbox-${key}`}
                    color="primary"
                  />
                }
                label={label}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {/* // FIXME: traduccion */}
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FiltersDialog;

const sortByLabelAsc = sortWith<FilterDialogElement>([
  ascend(({ label }) => label),
]);
