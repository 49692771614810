export enum TablesRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":tableId/view",
  MIGRATIONS = ":tableId/migrations",
  EDIT = ":tableId/edit",
}

export type TablesRoutesState = {
  [TablesRoutes.EDIT]: { activeStep?: number };
};
