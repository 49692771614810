import { getMyInformation } from "components/providers/ReduxMyProvider/reducer";
import { useAppSelector } from "hooks/reduxHooks";
import { useGetMyLanguageInformationQuery } from '../../__generated__/graphql/types';

const useSystemLanguage = () => {

  const { me } = useAppSelector(getMyInformation);
  const { 
    data:{ me: myLanguage } = {}, 
  } = useGetMyLanguageInformationQuery(
    {},
    { enabled: me === undefined}
  );

  if (me === undefined && myLanguage !== undefined) {
    return {
      language: myLanguage.language.id
    } 
  } else if(me !== undefined)  {
    return {
      language: me.language.id
    }
  }
  
  return {
    language: "EN"
  };
};

export default useSystemLanguage;