import React from "react";
import { useTable } from "./useTable";
import { SnackbarAlert } from "components/feedback/SnackbarAlert";
import { GenericTable } from "components/datadisplay/GenericTable";
import { BeatLoadingPage } from "components/layout/BeatLoadingPage";

const Table = () => {
  const {
    columns,
    rows,
    items,
    isRowChecked,
    onChangeCheckedRow,
    areAllRowsChecked,
    onChangeAllRowsChecked,
    onPrevPage,
    onNextPage,
    total,
    pagination,
    errorSnackbar,
  } = useTable();

  if (rows === undefined) return <BeatLoadingPage />;
  if (total === undefined) return <BeatLoadingPage />;

  return (
    <>
      <SnackbarAlert
        severity="error"
        onClose={errorSnackbar.close}
        open={errorSnackbar.isOpen}
      >
        {errorSnackbar.message}
      </SnackbarAlert>

      <GenericTable
        columns={columns}
        rows={rows}
        menuItems={items}
        isRowChecked={isRowChecked}
        onChangeCheckedRow={onChangeCheckedRow}
        areAllRowsChecked={areAllRowsChecked}
        onChangeAllRowsChecked={onChangeAllRowsChecked}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        totalRows={total ?? 0}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
      />
    </>
  );
};

export default Table;
