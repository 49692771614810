import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { useAppMessages } from "hooks/useAppMessages";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState } from "utils/typedRoutesUtils";
import { useCreateInvoiceMutation } from '../../../__generated__/graphql/types';
import { InvoicesRoutesState } from '../routes';
import { Form } from "./Form";
import { InvoiceFormFields } from "./Form/InvoiceForm/types";
import { FormFields } from "./Form/types";
import { useUploadInvoiceToS3 } from './useUploadInvoiceToS3';
import { mapFormToCreateDto } from './utils';

export const emptyInvoiceFormFields: InvoiceFormFields = {
  purchaseOrder: "",
  backend: "",
  accountingEntity: "",
  vendorId: "",
  currency: "",
  invoiceDate: new Date(),
  invoiceVendorNumber: "",
  headerText: ""
}

const CreateInvoicePage = () => {

  const state = useLocationState<InvoicesRoutesState>();

  const navigate = useNavigate();
  const { appMessage } = useAppMessages("INVOICES");

  // FIXME: Agregar validacion si el state es null
  const purchaseOrderItemsIds = state!.purchaseOrderItemsIds;
  const toBack = state!.toBack;

  const { mutateAsync: createInvoice } = useCreateInvoiceMutation();
  const { mutateAsync: uploadInvoiceFile } = useUploadInvoiceToS3();

  const onSave = async (form: FormFields) => {
    const uploadedFile =
    form.file.file instanceof File // TODO: refactor
      ? await uploadInvoiceFile(form.file.file)
      : undefined;
    await createInvoice({ invoice: mapFormToCreateDto(form, uploadedFile!.fileId)});
  };

  const onCancel = () => {
    navigate(toBack, {
      state: state
    });
  };
  
  // Flujo para seleccionar varios items al crear una factura
  // Se inicializan lo valores del form, a partir de los ids obtenidos de la lista en la url
  const positions = purchaseOrderItemsIds.map(e => {
    return {
      id: e,
      backend: "",
      purchaseOrderItemId: e,
      productId: "",
      unit: "",
      quantity: 0,
      netUnitPrice: 0,
      netTotalPrice: 0,
      taxesAmount: 0,
      amountToBill: 0,
      itemText: "",
      extraChargesAmount: 0
    }
  })
  
  const [initialValues] = useState<FormFields>({
    invoice: emptyInvoiceFormFields,
    positions: {
      positions: positions ? positions : []
    },
    file: {
      file: (undefined as any)
    },
    send: {
      send: false
    } 
  });

  return (
    <>
      <Layout>
        
        <Header
          title={appMessage["CREATE_INVOICE_TITLE"]}
          toBack={toBack}
          state={state}
        />
        
        {/* Form inicializado desde una lista */}
        <Form
          mode="createList"
          initialValue={initialValues} 
          errorMessage={ appMessage["ERROR"] } //TODO: Especificar mensaje de error
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreateInvoicePage;

