import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateBusinessUnitMutation } from "__generated__/graphql/types";
import { Form } from "./Form";
import { FormFields } from "./Form/types";
import { mapFormToDto } from "./utils";

const CreatePage = () => {
  const navigate = useNavigate();

  const { mutateAsync: createTable } = useCreateBusinessUnitMutation();

  const onSave = async (form: FormFields) => {
    await createTable({ businessUnit: mapFormToDto(form) });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  return (
    <>
      <Layout>
        <Header
          title="Crear unidad de negocio" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          errorMessage="Error al crear la unidad de negocio. Vuelva a intentar." // FIXME: traduccion
          mode="create"
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default CreatePage;

const TO_BACK_ROUTER = "../";
