import { AppInput } from "__generated__/graphql/types";
import { FormFields } from "./Form/types";

export const mapFormToDto = (form: FormFields): AppInput => {
  return {
    id: form.basicData.name,
    businessObjectId: form.basicData.businessObjectId,
    translations: form.descriptions.descriptions.map((e) => ({
      language: e.language,
      description: e.text,
    })),
    sections: form.sections.sections.map((section) => ({
      id: section.name,
      translations: section.descriptions.map((description) => ({
        language: description.language,
        title: description.title,
        subtitle: description.subtitle,
        step: description.step,
      })),
    })),
    actions: form.actions.actions.map((action) => ({
      id: action.name,
      translations: action.descriptions.map((description) => ({
        language: description.language,
        description: description.text,
      })),
    })),
    messages: form.messages.messages.map((message) => ({
      id: message.code,
      translations: message.descriptions.map((description) => ({
        language: description.language,
        description: description.text,
      })),
    })),
  };
};
