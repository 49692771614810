import { ArrowBack, ArrowForward, ArrowUpward } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography
} from "@mui/material";
import { useAppMessages } from "hooks/useAppMessages";

export type StepInfoProps = {
  step?: string;
  title: string;
  subtitle?: string;
  isNextButtonVisible: boolean;
  isPreviousButtonVisible: boolean;
  isUpButtonVisible?: boolean;
  leftButtonText?: string;
  onPrevious: () => void;
  onNext: () => void;
  onUp?: () => void;
};

const StepInfo = ({
  step,
  title,
  subtitle,
  isPreviousButtonVisible,
  isNextButtonVisible,
  isUpButtonVisible,
  leftButtonText,
  onPrevious,
  onNext,
  onUp,
}: StepInfoProps) => {

  const { appMessage } = useAppMessages("MESSAGES");

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            {isPreviousButtonVisible && (
              <Grid item id="step-info-previous">
                <StyledIconButton size="small" onClick={onPrevious}>
                  <Tooltip
                    title={appMessage["PREVIOUS"] ?? "Previous"}
                  >
                    <ArrowBack fontSize="small" />
                  </Tooltip>
                </StyledIconButton>
              </Grid>
            )}

            {isUpButtonVisible && (
              <Grid item>
                <StyledIconButton id="step-info-back" size="small" onClick={() => onUp?.()}>
                  <Tooltip
                    title={appMessage["BACK"] ?? "Back"}
                  >
                    <ArrowUpward fontSize="small" />
                  </Tooltip>
                </StyledIconButton>
              </Grid>
            )}

            {leftButtonText !== undefined &&
              (isPreviousButtonVisible || isUpButtonVisible) && (
                <Grid item>
                  <TextButtons>{leftButtonText}</TextButtons>
                </Grid>
              )}
          </Grid>
        </Grid>

        <Grid item>
          {isNextButtonVisible && (
            <StyledIconButton id="step-info-next" size="small" onClick={onNext}>
              <Tooltip
                title={appMessage["NEXT"] ?? "Next"}
              >
                <ArrowForward fontSize="small" />
              </Tooltip>
            </StyledIconButton>
          )}
        </Grid>
      </Grid>

      {step !== undefined && <PositionText>{step}</PositionText>}

      <TitleText>{title}</TitleText>

      {subtitle !== undefined && <SubtitleText>{subtitle}</SubtitleText>}
    </Box>
  );
};

export default StepInfo;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const PositionText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  textAlign: "center",
  color: theme.palette.primary.dark,
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.primary.dark,
}));

const SubtitleText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "center",
  color: theme.palette.primary.dark,
}));

const TextButtons = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "center",
  color: theme.palette.primary.dark,
}));
