import {
  Radio,
  styled,
  TableCell,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from "@mui/material";
import React from "react";
import { TableBodyRowProps } from "./types";

const TableBodyRow = <TColKeys extends string>({
  selected,
  onChangeSelected,
  values,
}: TableBodyRowProps<TColKeys>) => {
  return (
    <StyledMuiTableRow active={selected}>
      <TableCell scope="row">
        <Radio
          color="primary"
          checked={selected}
          onChange={(value) => onChangeSelected(value.target.checked)}
        />
      </TableCell>

      {values.map(({ key, text }) => (
        <TableCell
          key={key}
          scope="row"
          style={{ whiteSpace: "nowrap" }} // FIXME:
        >
          {text}
        </TableCell>
      ))}
    </StyledMuiTableRow>
  );
};

export default TableBodyRow;

const StyledMuiTableRow = styled(MuiTableRow)<
  { active: boolean } & MuiTableRowProps
>(({ active }) => ({
  backgroundColor: active ? "rgb(245, 245, 245)" : "white",
  "&:focus": {
    backgroundColor: "white",
  },
}));
