export enum BusinessProfileRoutes {
  VIEW_ALL = "/",
  CREATE = "create",
  VIEW = ":businessProfileId/view",
  EDIT = ":businessProfileId/edit",
}

export type BusinessProfileState = {
  [BusinessProfileRoutes.EDIT]: { activeStep?: number };
};
