import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, useRouteParams } from "utils/typedRoutesUtils";
import { useGetUserForViewPageQuery } from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { UsersRoutes } from "../routes";
import { mapDtoToForm } from "./utils";
import useAppMessages from 'hooks/useAppMessages/hook';

const ViewPage = () => {
  const [initialValues, setInitialValues] = useState<FormFields>();

  const navigate = useNavigate();
  const { appMessage } = useAppMessages("USERS");

  const { userId } = useRouteParams<UsersRoutes.VIEW>();

  const {
    data: { user } = {},
    isFetchedAfterMount,
  } = useGetUserForViewPageQuery({
    id: userId,
  });

  const onEdit = (activeStep: number) => {
    navigate(
      `../../${generatePath(UsersRoutes.EDIT, {
        userId,
      })}`,
      { state: { activeStep } }
    );
  };

  useEffect(() => {
    if (user == null || !isFetchedAfterMount) return;
    mapDtoToForm(user).then((e) => {
      setInitialValues(e);
    });
  }, [isFetchedAfterMount, user]);

  if (initialValues === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title={appMessage["USER_TITLE"]}
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="view"
          initialValue={initialValues} // FIXME:
          onEdit={onEdit}
        />
      </Layout>
    </>
  );
};

export default ViewPage;

const TO_BACK_ROUTER = "../../";
