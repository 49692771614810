import { IconButton, InputAdornment } from "@mui/material";
import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import { FormTableTextField } from "components/inputs/FormTableTextFieldController/FormTableField";
import React from "react";

type PositionMatchcodeInputProps = {
  text: string;
  isDeleteButtonVisible: boolean;
  onClearValue: () => void;
  onOpenDialog: () => void;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
};

const PositionMatchcodeInput = ({
  text,
  isDeleteButtonVisible,
  onClearValue,
  onOpenDialog,
  error,
  helperText,
  readOnly,
}: PositionMatchcodeInputProps) => {
  return (
    <FormTableTextField
      variant="outlined"
      color="primary"
      size="small"
      margin="none"
      value={text ?? ""} // FIXME:
      fullWidth
      inputProps={{ disabled: true }}
      InputLabelProps={{ shrink: text !== undefined }}
      InputProps={{
        startAdornment: !readOnly && isDeleteButtonVisible && (
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClearValue}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: !readOnly && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onOpenDialog}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={error}
      helperText={helperText}
    />
  );
};

export default PositionMatchcodeInput;
