import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material";
import { PageProtectedUserLoggedIn } from "components/auth/PageProtectedUserLoggedIn";
import { ReduxMuiThemeProvider } from "components/providers/ReduxMuiThemeProvider";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { HomePage } from "./HomePage";
import { LoginPage } from "./LoginPage";
import { ReportsPage } from "./ReportsPage";

const loginTheme = createTheme({
  palette: {
    primary: {
      main: "#FF5E04",
      contrastText: "#fff",
      secondary: "#e55403"
    },
  } as any, // FIXME: Tomar colores de tema
});

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <PageProtectedUserLoggedIn>
              <ReduxMuiThemeProvider>
                <Routes>
                  <Route path={`${AppRoutes.HOME}/*`} element={<HomePage />} />
                  <Route
                    path={`${AppRoutes.REPORTS}/*`}
                    element={<ReportsPage />}
                  />
                </Routes>
              </ReduxMuiThemeProvider>
            </PageProtectedUserLoggedIn>
          }
        />

        <Route
          path={`${AppRoutes.LOGIN}/*`}
          element={
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={loginTheme}>
                <LoginPage />
              </ThemeProvider>
            </StyledEngineProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
