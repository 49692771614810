import { setInstructions } from "components/Instructor/reducer/instructionsReducer";
import { useAppDispatch } from "hooks/reduxHooks";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { Step } from 'react-joyride';
import { notImplemented } from "utils/assert";
import InstructionsDialogBottom from '../../components/Instructor/dialogs/instructions-dialog-bottom/template';
import InstructionsDialogCenter from '../../components/Instructor/dialogs/instructions-dialog-center/template';
import InstructionsDialogLeft from '../../components/Instructor/dialogs/instructions-dialog-left/template';
import InstructionsDialogRight from '../../components/Instructor/dialogs/instructions-dialog-right/template';
import InstructionsDialogTop from '../../components/Instructor/dialogs/instructions-dialog-top/template';
import { targetToElement } from '../../utils/tutorialsConversors';
import { InstructionStyle, useGetTutorialsQuery } from '../../__generated__/graphql/types';

const getContent = (
  style: InstructionStyle, 
  text: string,
  target: string,
) => {

  const element = targetToElement(target);

  if(!style) return (
    <InstructionsDialogTop 
      text={text} 
      element={element}
    />
  )

  switch(style) {
    case 'TOP':
      return (
        <InstructionsDialogTop 
          text={text}
          element={element}
        />
      )
    case 'LEFT':
      return (
        <InstructionsDialogLeft 
          text={text} 
          element={element} 
        />
      )
    case 'RIGHT':
      return (
        <InstructionsDialogRight 
          text={text} 
          element={element} 
        />
      )
    case 'BOTTOM':
      return (
        <InstructionsDialogBottom 
          text={text} 
          element={element} 
        />
      )
    case 'CENTER':
      return (
        <InstructionsDialogCenter 
          text={text} 
          element={element} 
        />
      )
  }

  notImplemented(style);
}

// FIXME: Buscar tutoriales por APP
const useTutorials = (ids: string[]) => {

  const dispatch = useAppDispatch();
  const { language } = useSystemLanguage();

  // TODO: Ordenar por step number
  const { data: { tutorials } = {} } = useGetTutorialsQuery(
    { 
      filters: {
        ids: ids
      }, 
      language 
    }
  );

  if(tutorials) {
    return tutorials.map(tutorial => {

      // Sort array
      tutorial.instructions.sort((a, b) => a.stepNumber - b.stepNumber);

      const steps: Step[] = tutorial.instructions
        .map((e): Step => ({
          content: getContent(
            e.style, 
            e.translation.description,
            e.target,
          ),
          target: e.target,
          title: e.clickEnabled,
          placement: e.style === "CENTER" 
            ? "center"
            : "auto",
        }));

      return {
        name: tutorial.translation.description,
        navigateInstructions: () => {
          dispatch(
            setInstructions({
              open: true,
              stepIndex: 0,
              steps: steps
            })
          );
        }
      }
    });
  }

  return undefined;
};

export default useTutorials;
