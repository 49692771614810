import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { MatchcodeInputController } from "components/inputs/matchcode/MatchcodeInputController";
import { TextFieldController } from "components/inputs/TextFieldController";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import React from "react";
import {
  GetAppsCreateTileFormQuery,
  useGetAppsCreateTileFormQuery,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";

const BasicDataForm = ({
  form: { control },
  readOnly = false,
  onEdit,
}: BasicDataFormProps) => {
  const { language } = useSystemLanguage();

  const { data: { apps } = {} } = useGetAppsCreateTileFormQuery({
    language,
  });

  const { dataElementsDescriptions } = useDataElementDescription([
    "APP",
    "TILE_PATH",
    "DESCRIPTION",
  ]);

  if (apps == null) return null; // TODO: loading spinner
  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
      <DevTool placement="top-left" control={control} />

      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={3}>
          <MatchcodeInputController
            label={dataElementsDescriptions["APP"]}
            controllerProps={{
              name: "appId",
              control,
              rules: { required: "Seleccione la app." }, // FIXME: traduccion
            }}
            column={[
              { key: "app", text: dataElementsDescriptions["APP"] },
              {
                key: "description",
                text: dataElementsDescriptions["DESCRIPTION"],
              },
            ]}
            displayColumn="app"
            options={apps.map(mapToMatchcodeOptions)}
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFieldController
            label={dataElementsDescriptions["TILE_PATH"]}
            controllerProps={{
              name: "path",
              control,
              rules: {
                required: "Ingrese la ruta del mosaico.", // FIXME: traduccion
                pattern: {
                  value: new RegExp("^(.+)/([^/]+)$"), // TODO: testear regex
                  message: "Ingrese una ruta válida.", // FIXME: traduccion
                },
              },
            }}
            inputProps={{ disabled: readOnly }}
          />
        </Grid>
      </Grid>
    </FormNonTableBodyWrapper>
  );
};

export default BasicDataForm;

const mapToMatchcodeOptions = (
  dto: NonNullable<GetAppsCreateTileFormQuery["apps"]>[number]
) => ({
  id: dto.id,
  app: dto.id,
  description: dto.translation.description,
});
