import { DevTool } from "@hookform/devtools";
import { Box, Grid, Typography } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import React, { useEffect, useState } from "react";
import { ImageFormProps } from "./types";
import { useWatch } from "react-hook-form";
import { fileToBase64 } from "utils/fileToBase64";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { ImagePicker } from "components/inputs/ImagePicker";

const IconForm = ({ forms, readOnly = false, onEdit }: ImageFormProps) => {
  const { control } = forms.icon;

  const [iconBase64, setIconBase64] = useState<string>();

  const descriptions = useWatch({
    control: forms.descriptions.control,
    name: "descriptions",
  });

  const icon = useWatch({
    control: forms.icon.control,
    name: "icon",
  });

  const { language } = useSystemLanguage();

  const description = descriptions.find((e) => e.language === language);

  const { dataElementsDescriptions } = useDataElementDescription(["ICON"]);

  useEffect(() => {
    if (!(icon instanceof File)) return;
    fileToBase64(icon).then((e) => setIconBase64(e as string));
  }, [icon]);

  if (dataElementsDescriptions === undefined) return null; // TODO: loading spinner

  return (
    <>
      <FormNonTableBodyWrapper readOnly={readOnly} onEdit={onEdit}>
        <DevTool placement="top-left" control={control} />

        <Grid container wrap="nowrap" justifyContent="center">
          <Grid item>
            <ImagePicker
              tooltipTitle="Suba una image." // FIXME: traduccion
              label={dataElementsDescriptions["ICON"]}
              controllerProps={{
                name: "icon",
                control,
                rules: { required: "Suba una imagen." }, // FIXME: traduccion
              }}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>

      {iconBase64 && (
        <Box padding={2} marginTop={2}>
          <Grid
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <img
                src={iconBase64}
                height="100px"
                alt=""
                // style={{ objectFit: "cover" }}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="h5"
                style={{ color: "white", textAlign: "center" }}
              >
                {description?.text?.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default IconForm;
