import { GetCompany_ViewPageQuery } from "__generated__/graphql/types";
import { FormFields } from "../CreatePage/Form/types";

export const mapDtoToForm = (
  dto: NonNullable<GetCompany_ViewPageQuery["company"]>
): FormFields => {
  return {
    basicData: {
      company: dto.id,
      name: dto.name,
      currency: dto.currency.id,
      chartOfAccount: dto.chartOfAccount.id,
    },
  };
};
