import { Header } from "components/layout/Header";
import { Layout } from "components/layout/Layout";
import { LoadingLayoutPage } from "components/layout/LoadingLayoutPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocationState, useRouteParams } from "utils/typedRoutesUtils";
import {
  useGetAppViewPageQuery,
  useUpdateAppMutation,
} from "__generated__/graphql/types";
import { Form } from "../CreatePage/Form";
import { FormFields } from "../CreatePage/Form/types";
import { mapFormToDto } from "../CreatePage/utils";
import { AppsRoutes, AppsRoutesState } from "../routes";
import { mapDtoToForm } from "../ViewPage/utils";

const EditPage = () => {
  const { appId } = useRouteParams<AppsRoutes.VIEW>();

  const { activeStep } =
    useLocationState<AppsRoutesState[AppsRoutes.EDIT]>() ?? {};

  const navigate = useNavigate();

  const { mutateAsync: editTable } = useUpdateAppMutation();

  const { data: { app } = {}, isFetchedAfterMount } = useGetAppViewPageQuery({
    id: appId,
  });

  const initialValue =
    app != null && isFetchedAfterMount ? mapDtoToForm(app) : undefined;

  const onSave = async (form: FormFields) => {
    await editTable({ id: appId, app: mapFormToDto(form) });
  };

  const onCancel = () => {
    navigate(TO_BACK_ROUTER);
  };

  if (initialValue === undefined) return <LoadingLayoutPage />;

  return (
    <>
      <Layout>
        <Header
          title="Crear app" // FIXME: traduccion
          toBack={TO_BACK_ROUTER}
        />

        <Form
          mode="edit"
          initialValue={initialValue}
          initialStep={activeStep}
          errorMessage="Error al editar app. Vuelva a intentar." // FIXME: traduccion
          onSave={onSave}
          onCancel={onCancel}
        />
      </Layout>
    </>
  );
};

export default EditPage;

const TO_BACK_ROUTER = "../../";
