import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import { Box, Checkbox, Tooltip, styled } from "@mui/material";
import { useGetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery } from "__generated__/graphql/types";
import { TableRowType } from "components/datadisplay/GenericTable/template";
import { useAppSelector } from "hooks/reduxHooks";
import { useLanguageAndRegionConfig } from "hooks/useLanguageAndRegionConfig";
import { useSnackbar } from "hooks/useSnackbarV2";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ExcelDataRowType, ExcelSheetData } from "types/ExcelDataSet";
import { PicConstants } from "utils/constants";
import { notNullUndefined } from "utils/notNullUndefined";
import useAllowedUserAppActions from '../../../hooks/useAlloweduserAppActions/template';
import useAppMessages from '../../../hooks/useAppMessages/hook';
import { InvoicesFreightRoutes } from '../routes';
import { TrafficLight } from "./components/Table/TrafficLight";
import { TrafficLightStatus } from "./components/Table/TrafficLight/template";
import { selectRowsChecked } from "./reducers/checkedRowsSlice";
import { selectFilters, selectPagination } from "./reducers/filtersAndPaginationSlice";
import { COLUMNS_KEYS, ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const useViewAllPage = () => {
    
  const navigate = useNavigate();

  const { appMessage: appPurchaseOrders } = useAppMessages("FREIGHT_INVOICE");
  const { appMessage: appMessages } = useAppMessages("MESSAGES");
  const { actions } = useAllowedUserAppActions("FREIGHT_INVOICE");
  const { formatConfig } = useLanguageAndRegionConfig();
  
  const errorSnackbar = useSnackbar();
  const filters = useAppSelector(selectFilters);
  const pagination = useAppSelector(selectPagination);
  const rowsChecked = useAppSelector(selectRowsChecked);

  const read = actions.find(e => e.id === "READ");
  const createInvoice = actions.find(e => e.id === "CREATE_INVOICE");

  const {
    data: { deliveryPurchaseOrderItems, deliveryPurchaseOrderItemsCount: total } = {},
  } = useGetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery({
    pagination,
    filters: {
      purchaseOrder: filters.purchaseOrder,
      purchaseOrderItem: filters.purchaseOrderItem,
      productId: filters.productId,
      productDescription: filters.productDescription,
      locationDescription: filters.locationDesc,
      unit: filters.unit,
      poQuantity: filters.poQuantity,
      netUnitPrice: filters.netUnitPrice,
      netTotalPrice: filters.netTotalPrice
    }
  });

  // FIXME: Filtrar por delivery
  const rows = useMemo(() => deliveryPurchaseOrderItems
  ?.map(dto => {
    const res: TableRowType<ColumnsKeys> = {
      // TODO: Tipar rowKey
      rowKey: JSON.stringify({
        purchaseOrderItem: dto.purchaseOrderItem, 
        purchaseOrder: dto.purchaseOrder
      }), 
      purchaseOrder: dto?.purchaseOrder,
      purchaseOrderItem: dto?.purchaseOrderItem,
      productId: dto?.productId,
      productDescription: dto?.productDescription,
      hasInvoice: (
        <Tooltip
          title={dto.hasInvoice.toString()}
          placement="top"
        >
          <Box display="flex" justifyContent="center">
            <StyledCheckbox
              size="small"
              checked={dto.hasInvoice}
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Tooltip>
      ),
      locationDesc: dto?.locationDescription,
      unit: dto.unit,
      poQuantity:  formatConfig.numberMapper(dto.poQuantity),
      netUnitPrice: formatConfig.amountDecimalsMapper(dto.netUnitPrice, PicConstants.NET_UNT_PRICE_DECIMALS),
      netTotalPrice: formatConfig.amountMapper(dto.netTotalPrice),
      grQuantity: formatConfig.numberMapper(dto.grQuantity),
      grAmount: formatConfig.amountMapper(dto.grAmount),
      picInvoicedQuantity: formatConfig.numberMapper(dto.picInvoicedQuantity),
      picInvoicedAmount: formatConfig.amountMapper(dto.picInvoicedAmount),
      invoiceApprovedQuantity: formatConfig.numberMapper(dto.invoiceApprovedQuantity),
      invoiceApprovedAmount: formatConfig.amountMapper(dto.invoiceApprovedAmount),
      needGr: (
        <Tooltip
          title={dto?.needGr?.toString() ?? "false"}
          placement="top"
        >
          <Box display="flex" justifyContent="center">
            <StyledCheckbox
              size="small"
              checked={dto.needGr ?? false} //FIXME: Asegurar en backend siempre true o false
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Tooltip>
      ),
      completeInvoice: <TrafficLight 
        status={dto!.completeInvoice! !== "COMPLETE" ? "INCOMPLETE" : "COMPLETE" as TrafficLightStatus} 
      />
    };
    return res;
  }), [deliveryPurchaseOrderItems, formatConfig]);

  const {
    data: { deliveryPurchaseOrderItems: exportToExcel } = {},
  } = useGetDeliveryPurchaseOrdersItemForInvoiceFreightPageQuery({
    filters: {
      purchaseOrder: filters.purchaseOrder,
      purchaseOrderItem: filters.purchaseOrderItem,
      productId: filters.productId,
      productDescription: filters.productDescription,
      locationDescription: filters.locationDesc,
      unit: filters.unit,
      poQuantity: filters.poQuantity,
      netUnitPrice: filters.netUnitPrice,
      netTotalPrice: filters.netTotalPrice
    }
  });

  const sheet = useMemo(
    () => {
      if(exportToExcel) {
        const sheet:ExcelSheetData<ColumnsKeys> = {
          columns: [...COLUMNS_KEYS],
          data: exportToExcel.map(
            (dto):ExcelDataRowType<ColumnsKeys> => ({
              purchaseOrder: dto?.purchaseOrder,
              purchaseOrderItem: dto?.purchaseOrderItem,
              productId: dto?.productId,
              productDescription: dto?.productDescription,
              hasInvoice: dto.hasInvoice.toString(),
              locationDesc: dto?.locationDescription ?? "",
              unit: dto.unit,
              poQuantity:  formatConfig.numberMapper(dto.poQuantity),
              netUnitPrice: formatConfig.amountDecimalsMapper(dto.netUnitPrice, PicConstants.NET_UNT_PRICE_DECIMALS),
              netTotalPrice: formatConfig.amountMapper(dto.netTotalPrice),
              grQuantity: formatConfig.numberMapper(dto.grQuantity),
              grAmount: formatConfig.amountMapper(dto.grAmount),
              picInvoicedQuantity: formatConfig.numberMapper(dto.picInvoicedQuantity),
              picInvoicedAmount: formatConfig.amountMapper(dto.picInvoicedAmount),
              invoiceApprovedQuantity: formatConfig.numberMapper(dto.invoiceApprovedQuantity),
              invoiceApprovedAmount: formatConfig.amountMapper(dto.invoiceApprovedAmount),
              needGr: dto?.needGr?.toString() ?? "false",
              completeInvoice: dto!.completeInvoice ?? ""
            })
          )
        }
        return sheet;
      }
    },
    [exportToExcel, formatConfig]
  );


  // TODO: Refactor
  const menuItems = useMemo(() => [
    read && {
      key: "display",
      Icon: SearchIcon,
      text: read.translation.description,
      onClick: (rowKey: string) => {

        let purchaseOrder: string;
        let items: string[];

        if(rowKey === "display") {
          if(!rowsChecked || rowsChecked?.length === 0) {
            errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
            return;
          } else if(rowsChecked?.length > 1) {
            errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
            return;
          }
          const { purchaseOrder: po } = JSON.parse(rowKey);
          purchaseOrder = po;
          items = rowsChecked.map((e) => e.purchaseOrderItem);
        } else {
          if(rowsChecked && rowsChecked.length > 1) {
            errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
            return;
          }
          const { purchaseOrderItem, purchaseOrder: po } = JSON.parse(rowKey);
          purchaseOrder = po;
          items = [purchaseOrderItem];
        }
        
        navigate(InvoicesFreightRoutes.PATH_INVOICES, {
          state: {
            toBack: TO_BACK_ROUTER,
            purchaseOrderId: purchaseOrder,
            purchaseOrderItemsIds: items
          }
        })
      },
    },
    createInvoice && {
      key: "createInvoice",
      Icon: AddIcon,
      text: createInvoice.translation.description,
      onClick: (rowKey: string) => {

        let purchaseOrder: string;
        let items: string[];

        if(rowKey === "createInvoice") {
          if(rowsChecked.length === 0) {
            errorSnackbar.open(appMessages["ONLY_ONE_ROW"] ?? "You need to choose at least one row")
            return;
          } else if(rowsChecked?.length > 1) {
            errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
            return;
          }
          purchaseOrder = rowsChecked[0].purchaseOrder;
          items = rowsChecked.map((e) => e.purchaseOrderItem);
        } else {
          if(rowsChecked && rowsChecked.length > 1) {
            errorSnackbar.open(appMessages["MORE_ONE_ROW"] ?? "You can't choose more than one row")
            return;
          }
          const { purchaseOrderItem, purchaseOrder: po } = JSON.parse(rowKey);
          purchaseOrder = po;
          items = [purchaseOrderItem];
        }

        navigate(InvoicesFreightRoutes.PATH_INVOICES_CREATE, {
          state: {
            toBack: TO_BACK_ROUTER,
            purchaseOrderId: purchaseOrder,
            purchaseOrderItemsIds: items
          }
        })
      },
    }
  ].filter(notNullUndefined), 
  [appMessages, errorSnackbar, navigate, read, rowsChecked, createInvoice]);

  return {
    errorSnackbar,
    appPurchaseOrders,
    menuItems,
    rows,
    total,
    pagination,
    sheet
  }
}

const TO_BACK_ROUTER = "../";

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
});