import { DataElementType } from "apps/DataElementsPage/CreatePage/Form/BasicDataForm/template";
import { GetDataElementsForCreateTableQuery } from "__generated__/graphql/types";

export const NUMERIC_DATA_ELEMENT_TYPE: Readonly<
  Partial<Record<DataElementType, true>>
> = {
  INTEGER: true,
  DECIMAL: true,
  AMOUNT: true,
  QUANTITY: true,
};

export const generateDataElementDescription = (
  dto: NonNullable<GetDataElementsForCreateTableQuery["dataElements"]>[number]
) => {
  return dto.length != null
    ? `${dto.dataType} ${dto.length}`
    : dto.decimals != null
    ? `${dto.dataType} ${dto.decimals}`
    : dto.dataType;
};
