import { VisibleColumnsDialog } from "components/datadisplay/VisibleColumnsDialog";
import { useAppSelector } from "hooks/reduxHooks";
import React from "react";
import { useDispatch } from "react-redux";
import {
  ColumnKeys,
  columnsLabels,
  hideColumn,
  orderColumns,
  selectHiddenColumnsKeys,
  selectVisibleColumnsKeysInOrder,
  showColumn,
} from "../../../reducers/visibleColumnsSlice";

type ColumnsVisibilityDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const ColumnsVisibilityDialog = ({
  open,
  handleClose,
}: ColumnsVisibilityDialogProps) => {
  const dispatch = useDispatch();

  const visibleColumns = useAppSelector(selectVisibleColumnsKeysInOrder);

  const hiddenColumns = useAppSelector(selectHiddenColumnsKeys);

  return (
    <VisibleColumnsDialog
      open={open}
      handleClose={handleClose}
      visibleColumns={mapColumnKeyToDialog(visibleColumns)}
      hiddenColumns={mapColumnKeyToDialog(hiddenColumns)}
      hideColumn={(id) => dispatch(hideColumn({ id }))}
      orderColumn={(ids) => dispatch(orderColumns({ ids }))}
      showColumn={(id) => dispatch(showColumn({ id }))}
    />
  );
};

export default ColumnsVisibilityDialog;

const mapColumnKeyToDialog = (keys: ColumnKeys[]) => {
  return keys.map((key) => ({
    id: key,
    label: columnsLabels[key],
  }));
};
