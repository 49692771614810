import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  styled,
} from "@mui/material";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

export type CheckboxControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  FormControlLabelProps?: Omit<FormControlLabelProps, "control">; // FIXME:
  CheckboxProps?: Omit<CheckboxProps, "name" | "checked" | "onChange">; // FIXME:
  controllerProps: UseControllerProps<TFieldValues, TName>;
  transform?: (v: string) => unknown;
  readOnly?: boolean;
};

const CheckboxWithLabelController = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  controllerProps,
  transform = (v) => v,
  FormControlLabelProps,
  CheckboxProps,
  readOnly,
}: CheckboxControllerProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange },
  } = useController(controllerProps);

  // TODO: disabled
  return (
    <StyledFormControlLabel
      readOnly={readOnly}
      control={
        <Checkbox
          name={name}
          // @ts-ignore FIXME:
          checked={value ?? false}
          onChange={onChange}
          color="primary"
          inputProps={{ disabled: readOnly }}
          {...CheckboxProps}
        />
      }
      label={FormControlLabelProps?.label} // FIXME:
      {...FormControlLabelProps}
    />
  );
};

export default CheckboxWithLabelController;

const StyledFormControlLabel = styled(FormControlLabel)<
  FormControlLabelProps & { readOnly?: boolean }
>(({ readOnly }) => ({
  "& .MuiIconButton-root:hover": readOnly
    ? {
        backgroundColor: "transparent",
        cursor: "default",
      }
    : undefined,
  "&:hover": readOnly ? { cursor: "default" } : undefined,
}));
