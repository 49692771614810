import { DevTool } from "@hookform/devtools";
import { Grid } from "@mui/material";
import { FormNonTableBodyWrapper } from "components/datadisplay/FormNonTableBodyWrapper";
import { KeyInputWrapper } from "components/inputs/KeyInputWrapper";
import { TextFieldController } from "components/inputs/TextFieldController";
import { fetcher } from "config/reactQueryGraphQLFetcher";
import { DEBOUNCE_TIME_ID } from "consts/debounceTimeId";
import { useDataElementDescription } from "hooks/useDataElementDescription";
import pDebounce from "p-debounce";
import React from "react";
import { keyNameInputRegexPattern } from "utils/keyInputRegexPattern";
import {
  BusinessProfileExistsQueryVariables,
  BusinessProfileExistsQuery,
  BusinessProfileExistsDocument,
} from "__generated__/graphql/types";
import { BasicDataFormProps } from "./types";
import useAppMessages from 'hooks/useAppMessages/hook';

const BasicDataForm = ({
  form: { control },
  mode,
  onEdit,
}: BasicDataFormProps) => {
  const { dataElementsDescriptions } = useDataElementDescription([
    "BUSINESS_PROFILE",
  ]);
  const { appMessage } = useAppMessages("BUSINESS_PROFILES");
  const {appMessage:appMsg} = useAppMessages("MESSAGES");

  const isIdReadOnly = mode !== "create";

  if (dataElementsDescriptions == null) return null; // TODO: loading spinner

  return (
    <>
      <DevTool placement="top-left" control={control} />

      <FormNonTableBodyWrapper readOnly={isIdReadOnly} onEdit={onEdit}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={3}>
            <KeyInputWrapper>
              <TextFieldController
                label={dataElementsDescriptions["BUSINESS_PROFILE"]}
                controllerProps={{
                  name: "name",
                  control,
                  rules: {
                    required: appMessage["ENTER_BP_TYPE"] ?? "Enter the business profile name",///FIXPRD QUITAR
                    validate: async (value) => {
                      if (isIdReadOnly) return true;
                      const {
                        businessProfileExists,
                      } = await businessProfileExistsFn({
                        businessProfileId: value,
                      });
                      return businessProfileExists
                        ? appMessage["BP_TYPE_EXIST"] ?? "The business profile already exist"///FIXPRD QUITAR
                        : true;
                    },
                    pattern: {
                      value: keyNameInputRegexPattern,
                      message: appMsg["ALLOWED_CHARACTERS"] ??"The allowed characters are A-Z, 0-9 y _.",///FIXPRD QUITAR
                    },
                  },
                }}
                inputProps={{ disabled: isIdReadOnly }}
              />
            </KeyInputWrapper>
          </Grid>
        </Grid>
      </FormNonTableBodyWrapper>
    </>
  );
};

export default BasicDataForm;

const businessProfileExistsFn = pDebounce(
  (args: BusinessProfileExistsQueryVariables) =>
    fetcher<BusinessProfileExistsQuery, BusinessProfileExistsQueryVariables>(
      BusinessProfileExistsDocument,
      args
    )(),
  DEBOUNCE_TIME_ID
);
