import { useDataElementDescription } from "hooks/useDataElementDescription";
import { ColumnsKeys } from "./reducers/visibleColumnsSlice";

export const usePurchaseOrderLabels = () => {

    const id = "PURCHASE_ORDER_ID";
    const accountingEntity = "ACCOUNTING_ENTITY";
    const accountingEntityDesc = "ACCOUNTING_ENTITY_DESC";
    const vendor = "VENDOR";
    const creationDate = "CREATION_DATE";
    const currency = "CURRENCY";
    const vendorId = "VENDOR_ID";
    const total = "TOTAL";
    const purchaseEntity = "PURCHASE_ENTITY";
    const status = "PURCHASE_ORDER_STATUS";
    const extraCharges = "EXTRA_CHARGES";
    
    const dataElementsIds = [
        id, accountingEntity, accountingEntityDesc, vendor, creationDate, currency, vendorId, total, purchaseEntity, status, extraCharges
    ];
    
    type DetailsKeys = typeof dataElementsIds[number];
    const { dataElementsDescriptions } = useDataElementDescription(dataElementsIds);

    // FIXME: Se agregan validaciones así por si un id es incorrecto o no existe la aplicación no falle en los filtros, etc.
    const columnsLabels : NonNullable<Record<ColumnsKeys, string>> = {
        id: dataElementsDescriptions === undefined ? id : (dataElementsDescriptions[id] ?? id),  
        vendorName: dataElementsDescriptions === undefined ? vendor : (dataElementsDescriptions[vendor] ?? vendor),
        accountingEntityDesc: dataElementsDescriptions === undefined ? accountingEntityDesc : (dataElementsDescriptions[accountingEntityDesc] ?? accountingEntityDesc),
        vendorId: dataElementsDescriptions === undefined ? vendorId : (dataElementsDescriptions[vendorId] ?? vendorId),
        total: dataElementsDescriptions === undefined ? total : (dataElementsDescriptions[total] ?? total),
        currency: dataElementsDescriptions === undefined ? currency : (dataElementsDescriptions[currency] ?? currency),
        extraCharges: dataElementsDescriptions === undefined ? extraCharges : (dataElementsDescriptions[extraCharges] ?? extraCharges),
        creationDate: dataElementsDescriptions === undefined ? creationDate : (dataElementsDescriptions[creationDate] ?? creationDate),
        purchaseOrderStatus: dataElementsDescriptions === undefined ? status : (dataElementsDescriptions[status] ?? status),
    };

    const detailsLabels : NonNullable<Record<DetailsKeys, string>> = {
        id: dataElementsDescriptions === undefined ? id : (dataElementsDescriptions[id] ?? id),  
        vendor: dataElementsDescriptions === undefined ? vendor : (dataElementsDescriptions[vendor] ?? vendor),
        accountingEntity: dataElementsDescriptions === undefined ? accountingEntity : (dataElementsDescriptions[accountingEntity] ?? accountingEntity),
        accountingEntityDesc: dataElementsDescriptions === undefined ? accountingEntityDesc : (dataElementsDescriptions[accountingEntityDesc] ?? accountingEntityDesc),
        vendorId: dataElementsDescriptions === undefined ? vendorId : (dataElementsDescriptions[vendorId] ?? vendorId),
        total: dataElementsDescriptions === undefined ? total : (dataElementsDescriptions[total] ?? total),
        currency: dataElementsDescriptions === undefined ? currency : (dataElementsDescriptions[currency] ?? currency),
        extraCharges: dataElementsDescriptions === undefined ? extraCharges : (dataElementsDescriptions[extraCharges] ?? extraCharges),
        creationDate: dataElementsDescriptions === undefined ? creationDate : (dataElementsDescriptions[creationDate] ?? creationDate),  
        purchaseEntity: dataElementsDescriptions === undefined ? purchaseEntity : (dataElementsDescriptions[purchaseEntity] ?? purchaseEntity),
        purchaseOrderStatus: dataElementsDescriptions === undefined ? status : (dataElementsDescriptions[status] ?? status),
    };

    return { columnsLabels, detailsLabels }
}